import { useEffect } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';

//components
import TranslationRoom from '../Room/TranslationRoom';
import CreateRoomLanguages from './CreateRoomLanguages';
import TranslationCreateRoomAuth from './TranslationCreateRoomAuth';
import CreateRoomSpeakers from './CreateRoomSpeakers';
import CreateRoomSpeakersAutocorrect from './CreateRoomSpeakersAutocorrect';
import CreateRoomSpeakersVoiceAndLanguage from './CreateRoomSpeakersVoiceAndLanguage';
import CreateRoomRestoreData from './CreateRoomRestoreData';
import Spinner from '../../../../../components/Spinner/Spinner';

//utility
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import CreateRoomPassword from './CreateRoomPassword';

const TranslationCreateRoom = ({ setPage }) => {
  //hooks
  const {
    isConnecting,
    setRestoreDataStatus,
    createRoomPage,
    closeTranslationRoomSocket,
  } = useTranslationRoomSocket();

  //initialize
  useEffect(() => {
    setRestoreDataStatus();

    return () => {
      closeTranslationRoomSocket();
    };
  }, []);

  //UI
  useEffect(() => {
    ScrollTo('', 'auto', true);
  }, [createRoomPage]);

  return (
    <div className="translations">
      {createRoomPage === 0 && <TranslationCreateRoomAuth setPage={setPage} />}
      {createRoomPage === 1 && <CreateRoomRestoreData setPage={setPage} />}
      {createRoomPage === 2 && <CreateRoomPassword setPage={setPage} />}
      {createRoomPage === 3 && <CreateRoomLanguages />}
      {createRoomPage === 4 && <CreateRoomSpeakers />}
      {createRoomPage === 5 && <CreateRoomSpeakersVoiceAndLanguage />}
      {createRoomPage === 6 && <CreateRoomSpeakersAutocorrect />}
      {createRoomPage === 7 && !isConnecting ? (
        <TranslationRoom isHost={true} setPage={setPage} />
      ) : (
        createRoomPage === 7 && isConnecting && <Spinner minHeight={'100%'} />
      )}
    </div>
  );
};

export default TranslationCreateRoom;
