import { useEffect, useState } from 'react';

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import useSettings from '../../../../context/useSettings';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//Utility
import { folder } from '../../../../assets/icons/index';

const TagsList = () => {
  //Hooks
  const { width } = useSettings();
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { Generic } = useLanguageComponents();
  const { BigTagsOpts } = useLanguageData();
  const {
    accountBigTags: bigTags,
    accountTinyTags: tinyTags,
    accountTinyTagGroups: tinyTagGroups,
    accountFollowing,
    accountFollowingGroups,
  } = useAccountReduxHandlers();

  const {
    //General
    settingsEventSearchView,

    //Big Tags
    settingsSelectedBigTags: selectedBigTags,
    settingsSelectAllBTBtn: selectAllBT,
    handleSettingsRemoveSelectedBigTags,
    handleSettingsAddSelectedBigTags,
    handleSettingsRemoveAllSelectedBigTags,
    handleSettingsSetSelectAllBTBtn,

    //My Events
    settingsMyEventsView: selectMyEvents,
    handleSettingsSetMyEventsView,

    //Tiny Tags
    settingsSelectedTinyTags: selectedTinyTags,
    settingsSelectedTinyTagGroupIds: selectedTinyTagGroupIds,
    settingsSelectAllTTBtn: selectAllTT,
    handleSettingsRemoveSelectedTinyTags,
    handleSettingsAddSelectedTinyTags,
    handleSettingsSetSelectAllTTBtn,
    handleSettingsRemoveAllSelectedTinyTags,
    handleSettingsRemoveSelectedTinyTagGroupIds,
    handleSettingsAddSelectedTinyTagGroupIds,
    handleSettingsRemoveAllSelectedTinyTagGroupIds,

    //Network
    settingsSelectedAccountsFollowingIds: selectedAccountsFollowing,
    settingsSelectedAccountsFollowingGroupIds:
      selectedAccountsFollowingGroupIds,
    settingsSelectAllAccountsFollowingBtn: selectAllAccountsFollowing,
    handleSettingsRemoveAllSelectedAccountsFollowingIds,
    handleSettingsRemoveSelectedAccountsFollowingId,
    handleSettingsAddSelectedAccountsFollowingId,
    handleSettingsSetSelectAllAccountsFollowingBtn,
    handleSettingsRemoveSelectedAccountsFollowingGroupIds,
    handleSettingsAddSelectedAccountsFollowingGroupIds,
    handleSettingsRemoveAllSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();

  //Component state
  const [showAllTags, setShowAllTags] = useState(false);

  //UI functions
  useEffect(() => {
    setShowAllTags(false);
  }, [settingsEventSearchView]);

  //Component funcitons
  function handleMyEventsClick(key) {
    if (key === 'saved' && selectMyEvents !== key) {
      handleSettingsSetMyEventsView(key);
    }

    if (key === 'registered' && selectMyEvents !== key) {
      handleSettingsSetMyEventsView(key);
    }
    if (key === 'archived' && selectMyEvents !== key) {
      handleSettingsSetMyEventsView(key);
    }
  }

  function handleSelectAll(selectAll) {
    if (settingsEventSearchView === 'bigtags') {
      if (selectAll) {
        handleSettingsRemoveAllSelectedBigTags();
      } else if (!selectAll) {
        let newSelections = bigTags.filter(
          (bT) => !selectedBigTags.includes(bT)
        );
        newSelections.map((bT) => handleSettingsAddSelectedBigTags(bT));
      }
      handleSettingsSetSelectAllBTBtn(!selectAll);
    }

    if (settingsEventSearchView === 'tinytags') {
      if (selectAll) {
        handleSettingsRemoveAllSelectedTinyTags();
        handleSettingsRemoveAllSelectedTinyTagGroupIds();
      } else if (!selectAll) {
        let newSelections = tinyTags.filter(
          (tT) => !selectedTinyTags.includes(tT)
        );

        let newSelectionGroups = tinyTagGroups.filter(
          (tG) => !selectedTinyTagGroupIds.includes(tG.id)
        );
        newSelections.map((tT) => handleSettingsAddSelectedTinyTags(tT));
        newSelectionGroups.map((tG) =>
          handleSettingsAddSelectedTinyTagGroupIds(tG.id)
        );
      }

      handleSettingsSetSelectAllTTBtn(!selectAll);
    }

    if (settingsEventSearchView === 'network') {
      if (selectAll) {
        handleSettingsRemoveAllSelectedAccountsFollowingIds();
        handleSettingsRemoveAllSelectedAccountsFollowingGroupIds();
      } else if (!selectAll) {
        let newSelections = accountFollowing.filter(
          (account) => !selectedAccountsFollowing.includes(account._id)
        );

        let newSelectionGroups = accountFollowingGroups.filter(
          (followingGroup) =>
            !selectedAccountsFollowingGroupIds.includes(followingGroup.id)
        );
        newSelections.map((account) =>
          handleSettingsAddSelectedAccountsFollowingId(account._id)
        );
        newSelectionGroups.map((followingGroup) =>
          handleSettingsAddSelectedAccountsFollowingGroupIds(followingGroup.id)
        );
      }
      handleSettingsSetSelectAllAccountsFollowingBtn(!selectAll);
    }
  }

  //Big Tags
  function handleBigTag(bigTag) {
    if (selectedBigTags.includes(bigTag)) {
      handleSettingsRemoveSelectedBigTags(bigTag);
    } else {
      handleSettingsAddSelectedBigTags(bigTag);
    }
  }

  useEffect(() => {
    if (selectedBigTags?.length === 0 && selectAllBT) {
      handleSettingsSetSelectAllBTBtn(!selectAllBT);
    }
    if (selectedBigTags?.length === bigTags?.length && !selectAllBT) {
      handleSettingsSetSelectAllBTBtn(!selectAllBT);
    }
    /*eslint-disable-next-line*/
  }, [selectedBigTags]); //bT select all when selecting or unselecting all tags

  //Tiny Tags
  function handleTinyTag(tinyTag) {
    if (selectedTinyTags.includes(tinyTag)) {
      handleSettingsRemoveSelectedTinyTags(tinyTag);
    } else {
      handleSettingsAddSelectedTinyTags(tinyTag);
    }
  }

  function handleTinyTagGroup(tinyTagGroupId) {
    if (selectedTinyTagGroupIds.includes(tinyTagGroupId)) {
      handleSettingsRemoveSelectedTinyTagGroupIds(tinyTagGroupId);
    } else {
      handleSettingsAddSelectedTinyTagGroupIds(tinyTagGroupId);
    }
  }

  useEffect(() => {
    if (
      selectedTinyTags?.length === 0 &&
      selectedTinyTagGroupIds?.length === 0 &&
      selectAllTT
    ) {
      handleSettingsSetSelectAllTTBtn(!selectAllTT);
    }
    if (
      selectedTinyTags?.length === tinyTags?.length &&
      selectedTinyTagGroupIds?.length === tinyTagGroups?.length &&
      !selectAllTT
    ) {
      handleSettingsSetSelectAllTTBtn(!selectAllTT);
    }
    /*eslint-disable-next-line*/
  }, [selectedTinyTags, selectedTinyTagGroupIds]); //tT select all when selecting or unselecting all tags

  //Network
  function handleNetworkAccount(accountId) {
    if (selectedAccountsFollowing.includes(accountId)) {
      handleSettingsRemoveSelectedAccountsFollowingId(accountId);
    } else {
      handleSettingsAddSelectedAccountsFollowingId(accountId);
    }
  }

  function handleAccountsFollowingGroup(accountsFollowingGroupId) {
    if (selectedAccountsFollowingGroupIds.includes(accountsFollowingGroupId)) {
      handleSettingsRemoveSelectedAccountsFollowingGroupIds(
        accountsFollowingGroupId
      );
    } else {
      handleSettingsAddSelectedAccountsFollowingGroupIds(
        accountsFollowingGroupId
      );
    }
  }

  useEffect(() => {
    if (
      selectedAccountsFollowing?.length === 0 &&
      selectedAccountsFollowingGroupIds?.length === 0 &&
      selectAllAccountsFollowing
    ) {
      handleSettingsSetSelectAllAccountsFollowingBtn(
        !selectAllAccountsFollowing
      );
    }
    if (
      selectedAccountsFollowing?.length === accountFollowing?.length &&
      selectedAccountsFollowingGroupIds?.length ===
        accountFollowingGroups?.length &&
      !selectAllAccountsFollowing
    ) {
      handleSettingsSetSelectAllAccountsFollowingBtn(
        !selectAllAccountsFollowing
      );
    }
    /*eslint-disable-next-line*/
  }, [selectedAccountsFollowing, selectedAccountsFollowingGroupIds]); //network select all when selecting or unselecting all tags

  return (
    <>
      {width <= 1280 && (
        <h2 className="fs16 mrg-b12 color-black">
          {settingsEventSearchView === 'myevents'
            ? SettingsEventsLanguage.myEvents
            : SettingsEventsLanguage.tags}
        </h2>
      )}
      <div
        className={`settings-tags__wrapper  ${width <= 1280 ? '' : 'mrg-t16'}`}
        id="tags__wrapper"
      >
        {settingsEventSearchView === 'myevents' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="settings-tags-container"
          >
            <li key={'registeredEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                  selectMyEvents === 'registered' ? 'selected' : ''
                }`}
                key={'registeredEvents-btn'}
                onClick={() => handleMyEventsClick('registered')}
                aria-pressed={selectMyEvents === 'registered'}
              >
                <p>{SettingsEventsLanguage.registeredEvents}</p>
              </button>
            </li>
            <li key={'savedEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                  selectMyEvents === 'saved' ? 'selected' : ''
                }`}
                key={'savedEvents-btn'}
                onClick={() => handleMyEventsClick('saved')}
                aria-pressed={selectMyEvents === 'saved'}
              >
                <p>{SettingsEventsLanguage.savedEvents}</p>
              </button>
            </li>
            <li key={'archivedEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                  selectMyEvents === 'archived' ? 'selected' : ''
                }`}
                key={'archivedEvents-btn'}
                onClick={() => handleMyEventsClick('archived')}
                aria-pressed={selectMyEvents === 'archived'}
              >
                <p>{SettingsEventsLanguage.archivedEvents}</p>
              </button>
            </li>
          </ul>
        )}
        {settingsEventSearchView === 'bigtags' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="tags-container"
          >
            <li key={'select-all-bigtag-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllBT ? 'selected' : ''
                }`}
                onClick={() => handleSelectAll(selectAllBT)}
              >
                {selectAllBT ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            {settingsEventSearchView === 'bigtags' && bigTags && (
              <>
                {[...bigTags].reverse().map((bT, index) => {
                  if (width > 1280 || index < 5 || showAllTags) {
                    return (
                      <li key={`${bT}-li`}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                            selectedBigTags.includes(bT) ? 'selected' : ''
                          }`}
                          key={bT}
                          onClick={() => handleBigTag(bT)}
                          aria-pressed={selectedBigTags.includes(bT)}
                          aria-label={`${SettingsEventsLanguage.ariaTag} ${BigTagsOpts[bT]}.`}
                        >
                          <p>{BigTagsOpts[bT]}</p>
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}

                {!showAllTags && width < 1280 && bigTags?.length > 5 && (
                  <li key={'show-more-bigtag-li'}>
                    <button
                      className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                        true ? 'selected' : ''
                      }`}
                      onClick={() => setShowAllTags(true)}
                      aria-label={SettingsEventsLanguage.ariaShowRemainingTags}
                    >
                      <p> . . . ({bigTags?.length - 5})</p>
                    </button>
                  </li>
                )}
              </>
            )}
          </ul>
        )}

        {settingsEventSearchView === 'tinytags' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="tags-container"
          >
            <li key={'select-all-tinytag-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllTT ? 'selected' : ''
                }`}
                onClick={() => handleSelectAll(selectAllTT)}
              >
                {selectAllTT ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            {settingsEventSearchView === 'tinytags' && tinyTagGroups && (
              <>
                {[...tinyTagGroups].reverse().map((tG, index) => {
                  if (width > 1280 || index < 5 || showAllTags) {
                    return (
                      <li key={`${tG.label}-li`}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 align-center access-ob access-o6 ${
                            selectedTinyTagGroupIds.includes(tG.id)
                              ? 'selected'
                              : ''
                          }`}
                          key={tG.id}
                          onClick={() => handleTinyTagGroup(tG.id)}
                          aria-pressed={selectedTinyTagGroupIds.includes(tG.id)}
                          aria-label={`${SettingsEventsLanguage.ariaTinyTagGroup} ${tG.label}.`}
                        >
                          <img
                            src={folder}
                            alt={Generic.folder}
                            className="filter-theme folder-img"
                          />
                          <p>{tG.label}</p>
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}

                {tinyTags.map((tT, index) => {
                  if (width > 1280 || index < 5 || showAllTags) {
                    return (
                      <li key={`${tT}-li`}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                            selectedTinyTags.includes(tT) ? 'selected' : ''
                          }`}
                          key={tT}
                          onClick={() => handleTinyTag(tT)}
                          aria-pressed={selectedTinyTags.includes(tT)}
                          aria-label={`${SettingsEventsLanguage.ariaTag} ${tT}.`}
                        >
                          <p>{tT}</p>
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                {!showAllTags && width < 1280 && tinyTags?.length > 5 && (
                  <li key="show-more-tinytag-li">
                    <button
                      className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                        true ? 'selected' : ''
                      }`}
                      onClick={() => setShowAllTags(true)}
                      aria-label={SettingsEventsLanguage.ariaShowRemainingTags}
                    >
                      <p>. . . ({tinyTags?.length - 5})</p>
                    </button>
                  </li>
                )}
              </>
            )}
          </ul>
        )}

        {settingsEventSearchView === 'network' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="tags-container"
          >
            <li key={'select-all-network-li'}>
              <button
                className={`settings-tag settings-tag--large ff2 color-theme mrg-r12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllAccountsFollowing ? 'selected' : ''
                }`}
                onClick={() => handleSelectAll(selectAllAccountsFollowing)}
              >
                {selectAllAccountsFollowing ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            {[...accountFollowingGroups].reverse().map((groupObj, index) => {
              if (width > 1280 || index < 5 || showAllTags) {
                return (
                  <li key={`${groupObj.label}-li`}>
                    <button
                      className={`settings-tag settings-tag--large ff2 color-theme mrg-r12 pad-8 align-center access-ob access-o6 ${
                        selectedAccountsFollowingGroupIds?.includes(groupObj.id)
                          ? 'selected'
                          : ''
                      }`}
                      key={groupObj.id}
                      onClick={() => handleAccountsFollowingGroup(groupObj.id)}
                      aria-pressed={selectedAccountsFollowingGroupIds?.includes(
                        groupObj.id
                      )}
                    >
                      <img
                        src={folder}
                        alt={Generic.folder}
                        className="filter-theme folder-img"
                      />
                      <p>{groupObj.label}</p>
                    </button>
                  </li>
                );
              }
              return null;
            })}
            {settingsEventSearchView === 'network' && accountFollowing && (
              <>
                {[...accountFollowing]
                  .reverse()
                  .map((accountFollowingObj, index) => {
                    if (width > 1280 || index < 5 || showAllTags) {
                      return (
                        <li key={`accountFollowing-li-${index}`}>
                          <button
                            className={`settings-tag settings-tag--large ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                              selectedAccountsFollowing.includes(
                                accountFollowingObj._id
                              )
                                ? 'selected'
                                : ''
                            }`}
                            key={`account-${index}`}
                            onClick={() =>
                              handleNetworkAccount(accountFollowingObj._id)
                            }
                            aria-pressed={selectedAccountsFollowing.includes(
                              accountFollowingObj._id
                            )}
                          >
                            <LoadBucketImage
                              imagePath={
                                accountFollowingObj?.personal?.profileImage
                              }
                              imgClassName="tag-profile-img
                            profile-img mrg-r6"
                              backupPath={'account_img_default.png'}
                              partialPath={'myndfull_account_images'}
                            />
                            <p>
                              {accountFollowingObj?.personal?.firstName}&nbsp;
                              {accountFollowingObj?.personal?.lastName}
                            </p>
                          </button>
                        </li>
                      );
                    }
                    return null;
                  })}
                {!showAllTags &&
                  width < 1280 &&
                  accountFollowing?.length > 5 && (
                    <li key={'show-more-accountfollowing-li'}>
                      <button
                        className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                          true ? 'selected' : ''
                        }`}
                        onClick={() => setShowAllTags(true)}
                        aria-label={
                          SettingsEventsLanguage.ariaShowRemainingTags
                        }
                      >
                        <p> . . . ({accountFollowing?.length - 5})</p>
                      </button>
                    </li>
                  )}
              </>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default TagsList;
