//Hooks

//Components

//Utility
import { new_window } from '../../assets/icons';

const ButtonNewWindow = ({ btnText, ariaText, center, customWrapper }) => {
  return (
    <button
      className={`new-window-button access-ob access-o6 ${
        center ? 'mrg-auto-left mrg-auto-right' : ''
      } ${customWrapper ? customWrapper : ''}`}
      type="button"
      aria-label={ariaText}
    >
      {btnText}
      <img src={new_window} alt="img" className="svg" />
    </button>
  );
};

export default ButtonNewWindow;
