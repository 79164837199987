import { useDispatch, useSelector } from 'react-redux';
import {
  useRemoveEventActiveMutation,
  useRemoveEventScheduledMutation,
  useAcceptPendingApprovalVirtualMutation,
  useRemoveRegistrationVirtualMutation,
  useAcceptPendingApprovalInPersonMutation,
  useRemoveRegistrationInPersonMutation,
  useAcceptPendingApprovalInPersonAllMutation,
  useRemoveArchivedEventOrganizerMutation,
  useRemoveEventActiveUpdateAccountsMutation,
  useRemoveEventScheduledUpdateAccountsMutation,
  useRemoveEventArchivedUpdateAccountsMutation,
} from './organizeApiSlice';

import {
  selectMyOrganizedEvents,
  selectMyScheduledOrganizedEvents,
  selectMyArchivedOrganizedEvents,

  //
  setOrganizedEvent,

  //Sorted
  setSortedMyOrganizedEvents,
  setSortedMyScheduledOrganizedEvents,
  setSortedMyArchivedOrganizedEvents,
  selectSortedMyOrganizedEvents,
  selectSortedMyScheduledOrganizedEvents,
  selectSortedMyArchivedOrganizedEvents,
} from './organizeSlice';

import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import { selectEditEvent } from './edit/editEventSlice';
import { selectCreateEvent } from './create/createEventSlice';

//Hooks
import useSanitize from '../../../hooks/useSanitize';
import useLanguageOrganize from '../../../language/features/useLanguageOrganize';
import useReCaptcha from '../../../hooks/useReCaptcha';
//Components

//Utility
import convertBase64ToJPEG from '../../../utils/images/convertBase64ToJPEG';
import useApiStatus from '../../../context/useApiStatus';
import { useEffect, useMemo } from 'react';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { sortEventsByDateTime } from '../../../utils/dateTime/DateCalc';

export const useOrganizeHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const { accountId } = useAccountReduxHandlers();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleSuccess, handleError } = useApiStatus();

  const {
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    settingsOrganizeOverviewDirection,
  } = useSettingsHandlers();

  const [
    removeEventActiveUpdateAccount,
    {
      isLoading: removeEventActiveUpdateAccountisLoading,
      isFetching: removeEventActiveUpdateAccountisFetching,
    },
  ] = useRemoveEventActiveUpdateAccountsMutation();

  const [
    removeEventScheduledUpdateAccount,
    {
      isLoading: removeEventScheduledUpdateAccountisLoading,
      isFetching: removeEventScheduledUpdateAccountisFetching,
    },
  ] = useRemoveEventScheduledUpdateAccountsMutation();
  const [
    removeEventArchivedUpdateAccount,
    {
      isLoading: removeEventArchivedUpdateAccountisLoading,
      isFetching: removeEventArchivedUpdateAccountisFetching,
    },
  ] = useRemoveEventArchivedUpdateAccountsMutation();

  const [
    removeEventActive,
    {
      isLoading: removeEventActiveisLoading,
      isFetching: removeEventActiveisFetching,
    },
  ] = useRemoveEventActiveMutation();

  const [
    removeEventScheduled,
    {
      isLoading: removeEventScheduledisLoading,
      isFetching: removeEventScheduledisFetching,
    },
  ] = useRemoveEventScheduledMutation();

  //Registrations
  const [acceptPendingApprovalInPerson] =
    useAcceptPendingApprovalInPersonMutation();
  const [removeRegistrationInPerson] = useRemoveRegistrationInPersonMutation();
  const [acceptPendingApprovalInPersonAll] =
    useAcceptPendingApprovalInPersonAllMutation();

  //Registrations Virtual
  const [acceptPendingApprovalVirtual] =
    useAcceptPendingApprovalVirtualMutation();
  const [removeRegistrationVirtual] = useRemoveRegistrationVirtualMutation();

  //Selector Handlers
  const organizeMyOrganizedEvents = useSelector(selectMyOrganizedEvents);
  const organizeMyScheduledOrganizedEvents = useSelector(
    selectMyScheduledOrganizedEvents
  );
  const organizeMyArchivedOrganizedEvents = useSelector(
    selectMyArchivedOrganizedEvents
  );

  //SORTED
  const organizeSortedMyOrganizedEvents = useSelector(
    selectSortedMyOrganizedEvents
  );
  const organizeSortedMyScheduledOrganizedEvents = useSelector(
    selectSortedMyScheduledOrganizedEvents
  );
  const organizeSortedMyArchivedOrganizedEvents = useSelector(
    selectSortedMyArchivedOrganizedEvents
  );

  //SORT FUNCTIONS
  const sortedMyOrganizedEvents = useMemo(() => {
    function sortMyOrganizedEvents() {
      let filteredByOrganizer = [];

      organizeMyOrganizedEvents?.forEach((ev) => {
        let primaryOrganizerId = ev?.organizers?.organizerId[0]?._id;
        if (settingsOrganizeSelectedOrganizers?.includes(primaryOrganizerId)) {
          filteredByOrganizer.push(ev);
        }
      });
      if (
        settingsOrganizeOverviewSortCategory === 'date' &&
        filteredByOrganizer?.length > 0
      ) {
        return sortEventsByDateTime(
          filteredByOrganizer,
          settingsOrganizeOverviewDirection
        );
      }
      return filteredByOrganizer;
    }

    return sortMyOrganizedEvents();
  }, [
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    organizeMyOrganizedEvents,
    settingsOrganizeOverviewDirection,
  ]);

  useEffect(() => {
    dispatch(setSortedMyOrganizedEvents(sortedMyOrganizedEvents));
  }, [dispatch, sortedMyOrganizedEvents]);

  const sortedMyScheduledOrganizedEvents = useMemo(() => {
    function sortMyScheduledOrganizedEvents() {
      let filteredByOrganizer = [];

      organizeMyScheduledOrganizedEvents?.forEach((ev) => {
        let primaryOrganizerId = ev?.organizers?.organizerId[0]?._id;
        if (settingsOrganizeSelectedOrganizers?.includes(primaryOrganizerId)) {
          filteredByOrganizer.push(ev);
        }
      });
      if (
        settingsOrganizeOverviewSortCategory === 'date' &&
        filteredByOrganizer?.length > 0
      ) {
        return sortEventsByDateTime(
          filteredByOrganizer,
          settingsOrganizeOverviewDirection
        );
      }
      return filteredByOrganizer;
    }

    return sortMyScheduledOrganizedEvents();
  }, [
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    organizeMyScheduledOrganizedEvents,
    settingsOrganizeOverviewDirection,
  ]);

  useEffect(() => {
    dispatch(
      setSortedMyScheduledOrganizedEvents(sortedMyScheduledOrganizedEvents)
    );
  }, [dispatch, sortedMyScheduledOrganizedEvents]);

  const sortedMyArchivedOrganizedEvents = useMemo(() => {
    function sortMyArchivedOrganizedEvents() {
      let filteredByOrganizer = [];

      organizeMyArchivedOrganizedEvents?.forEach((ev) => {
        let primaryOrganizerId = ev?.organizers?.organizerId[0]?._id;
        if (settingsOrganizeSelectedOrganizers?.includes(primaryOrganizerId)) {
          filteredByOrganizer.push(ev);
        }
      });
      if (
        settingsOrganizeOverviewSortCategory === 'date' &&
        filteredByOrganizer?.length > 0
      ) {
        return sortEventsByDateTime(
          filteredByOrganizer,
          settingsOrganizeOverviewDirection
        );
      }
      return filteredByOrganizer;
    }

    return sortMyArchivedOrganizedEvents();
  }, [
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectedOrganizers,
    organizeMyArchivedOrganizedEvents,
    settingsOrganizeOverviewDirection,
  ]);

  useEffect(() => {
    dispatch(
      setSortedMyArchivedOrganizedEvents(sortedMyArchivedOrganizedEvents)
    );
  }, [dispatch, sortedMyArchivedOrganizedEvents]);

  //redux handlers
  function handleOrganizeSetOrganizedEvent(obj) {
    dispatch(setOrganizedEvent(obj));
  }

  //API Handlers
  //General
  //Note: errors get caught in reduxApiSlice
  async function handleAcceptPendingApprovalInPerson(
    accountId,
    eventId,
    pendingApprovalInPersonId
  ) {
    await acceptPendingApprovalInPerson({
      accountId,
      eventId,
      pendingApprovalInPersonId,
    });
  }

  async function handleRemoveRegistrationInPerson(
    accountId,
    eventId,
    registeredAttendeeInPersonId
  ) {
    await removeRegistrationInPerson({
      accountId,
      eventId,
      registeredAttendeeInPersonId,
    });
  }

  async function handleApprovePendingApprovalInPersonAll(
    accountId,
    eventId,
    pendingApprovalInPersonAll
  ) {
    await acceptPendingApprovalInPersonAll({
      accountId,
      eventId,
      pendingApprovalInPersonAll,
    });
  }

  //Virtual
  async function handleAcceptPendingApprovalVirtual(
    accountId,
    eventId,
    pendingApprovalVirtualId
  ) {
    await acceptPendingApprovalVirtual({
      accountId,
      eventId,
      pendingApprovalVirtualId,
    });
  }

  async function handleRemoveRegistrationVirtual(
    accountId,
    eventId,
    registeredAttendeeVirtualId
  ) {
    await removeRegistrationVirtual({
      accountId,
      eventId,
      registeredAttendeeVirtualId,
    });
  }

  async function handleOrganizerEventFormData(
    handleType,
    eventData,
    eventImageRef,
    speaker0ImgRef,
    speaker1ImgRef,
    speaker2ImgRef,
    speaker3ImgRef,
    speaker4ImgRef,
    speaker5ImgRef,
    speaker0Img,
    speaker1Img,
    speaker2Img,
    speaker3Img,
    speaker4Img,
    speaker5Img,
    eventImg,
    changeList
  ) {
    const formData = new FormData();

    //dateTime
    if (eventData.dateTime.date) {
      formData.append('date', eventData.dateTime.date);
    }
    if (eventData.dateTime.timeStart) {
      formData.append('timeStart', eventData.dateTime.timeStart);
    }
    if (eventData.dateTime.timeEnd) {
      formData.append('timeEnd', eventData.dateTime.timeEnd);
    }
    if (eventData.dateTime.timeZone) {
      formData.append('timeZone', eventData.dateTime.timeZone);
    }

    //Generic
    if (eventData.generic.agenda?.items?.length > 0) {
      eventData.generic.agenda.items.forEach((item, index) => {
        formData.append(`agenda[${index}][id]`, item.id);
        formData.append(`agenda[${index}][text]`, item.text);
        formData.append(`agenda[${index}][value]`, item.value);
        formData.append(`agenda[${index}][startTime]`, item.startTime);
        formData.append(`agenda[${index}][endTime]`, item.endTime);
      });
      formData.append('agendaFormat', eventData.generic.agenda.format);
    }

    if (eventData.generic.accessibilityNotes) {
      formData.append(
        'accessibilityNotes',
        eventData.generic.accessibilityNotes
      );
    }
    if (eventData.generic.audienceType) {
      formData.append('audienceType', eventData.generic.audienceType);
    }

    formData.append('description', eventData.generic.description);

    if (eventData.generic.eventType) {
      formData.append('eventType', eventData.generic.eventType);
    }
    if (eventData.generic.languages) {
      formData.append('languages', eventData.generic.languages);
    }

    formData.append(
      'realtimeTranslations',
      eventData.generic?.realtimeTranslations
    );

    formData.append(
      'wheelchairAccessibility',
      eventData.generic?.wheelchairAccessibility
    );

    formData.append(
      'signLanguageInterpreter',
      eventData.generic?.signLanguageInterpreter
    );

    formData.append('title', eventData.generic.title);

    //hosting
    if (eventData.hosting.inPerson.location.country) {
      formData.append(
        'location_country',
        eventData.hosting.inPerson.location.country
      );
    }

    if (eventData.hosting.hostingOption) {
      formData.append('hostingOption', eventData.hosting.hostingOption);
    }
    if (
      eventData.hosting.hostingOption === 'inPerson' ||
      eventData.hosting.hostingOption === 'hybrid'
    ) {
      formData.append(
        'location_room',
        eventData.hosting.inPerson.location.room
      );
      formData.append(
        'location_locationName',
        eventData.hosting.inPerson.location.locationName
      );
      formData.append(
        'location_streetAddress',
        eventData.hosting.inPerson.location.streetAddress
      );
      formData.append(
        'location_city',
        eventData.hosting.inPerson.location.city
      );
      formData.append(
        'location_postalCode',
        eventData.hosting.inPerson.location.postalCode
      );
      formData.append(
        'location_placeId',
        eventData.hosting.inPerson.location.placeId
      );
    }
    if (changeList) {
      formData.append('changeList', changeList);
    }

    if (
      eventData.hosting.inPerson.geoPosition.coordinates[0] !== 0 &&
      eventData.hosting.inPerson.geoPosition.coordinates[1] !== 0
    ) {
      if (handleType === 'publish') {
        //reverse geoPosition to save to db for backend functionality
        let backendCoords = [
          eventData.hosting.inPerson.geoPosition.coordinates[1],
          eventData.hosting.inPerson.geoPosition.coordinates[0],
        ];

        formData.append('coordinates', backendCoords);
      } else {
        //keep coordinates as [lat, lng] since there is no backend functionality in draft
        formData.append(
          'coordinates',
          eventData.hosting.inPerson.geoPosition.coordinates
        );
      }
    }

    if (
      eventData.hosting.hostingOption === 'online' ||
      eventData.hosting.hostingOption === 'hybrid'
    ) {
      formData.append('virtualMeetingId', eventData.hosting.virtual.meetingId);
      formData.append('virtualPasscode', eventData.hosting.virtual?.passcode);
      formData.append('virtualPlatform', eventData.hosting.virtual?.platform);
      formData.append('virtualLink', eventData.hosting.virtual?.meetingLink);
    }

    //meta

    formData.append('accountId', accountId);
    formData.append('lastModified', Date.now());

    if (handleType === 'draft') {
      formData.append('saveName', eventData.meta.saveName);
    }
    if (eventData.meta?._id) {
      formData.append('eventId', eventData.meta._id);
    }

    //participants
    formData.append('moderator', eventData.participants.moderator);

    if (handleType === 'publish') {
      if (speaker0ImgRef.current) {
        speaker0Img = speaker0ImgRef.current.handleGetSpeaker();

        if (speaker0Img.croppedImage.croppedImage) {
          const speaker0ImgJPG = await convertBase64ToJPEG(
            speaker0Img.croppedImage.croppedImage,
            'speaker0.jpg'
          );
          if (speaker0ImgJPG) {
            formData.append('speakerImages', speaker0ImgJPG);
          }
        }
      }
    }

    if (eventData.participants.speaker0Data) {
      formData.append(
        'speaker0Name',
        eventData.participants.speaker0Data?.name
      );
      formData.append('speaker0Bio', eventData.participants.speaker0Data?.bio);
      formData.append('speaker0Id', eventData.participants.speaker0Data?.id);
    }

    if (handleType === 'publish') {
      if (speaker1ImgRef.current) {
        speaker1Img = speaker1ImgRef.current.handleGetSpeaker();
        if (speaker1Img.croppedImage.croppedImage) {
          const speaker1ImgJPG = await convertBase64ToJPEG(
            speaker1Img.croppedImage.croppedImage,
            'speaker1.jpg'
          );
          if (speaker1ImgJPG) {
            formData.append('speakerImages', speaker1ImgJPG);
          }
        }
      }
    }

    if (eventData.participants.speaker1Data) {
      formData.append(
        'speaker1Name',
        eventData.participants.speaker1Data?.name
      );
      formData.append('speaker1Bio', eventData.participants.speaker1Data?.bio);
      formData.append('speaker1Id', eventData.participants.speaker1Data?.id);
    }

    if (handleType === 'publish') {
      if (speaker2ImgRef.current) {
        speaker2Img = speaker2ImgRef.current.handleGetSpeaker();
        if (speaker2Img.croppedImage.croppedImage) {
          const speaker2ImgJPG = await convertBase64ToJPEG(
            speaker2Img.croppedImage.croppedImage,
            'speaker2.jpg'
          );
          if (speaker2ImgJPG) {
            formData.append('speakerImages', speaker2ImgJPG);
          }
        }
      }
    }
    if (eventData.participants.speaker2Data) {
      formData.append(
        'speaker2Name',
        eventData.participants.speaker2Data?.name
      );
      formData.append('speaker2Bio', eventData.participants.speaker2Data?.bio);
      formData.append('speaker2Id', eventData.participants.speaker2Data?.id);
    }

    if (handleType === 'publish') {
      if (speaker3ImgRef.current) {
        speaker3Img = speaker3ImgRef.current.handleGetSpeaker();
        if (speaker3Img.croppedImage.croppedImage) {
          const speaker3ImgJPG = await convertBase64ToJPEG(
            speaker3Img.croppedImage.croppedImage,
            'speaker3.jpg'
          );
          if (speaker3ImgJPG) {
            formData.append('speakerImages', speaker3ImgJPG);
          }
        }
      }
    }
    if (eventData.participants.speaker3Data) {
      formData.append('speaker3Name', eventData.participants.speaker3Data.name);

      formData.append('speaker3Bio', eventData.participants.speaker3Data.bio);

      formData.append('speaker3Id', eventData.participants.speaker3Data.id);
    }

    if (handleType === 'publish') {
      if (speaker4ImgRef.current) {
        speaker4Img = speaker4ImgRef.current.handleGetSpeaker();
        if (speaker4Img.croppedImage.croppedImage) {
          const speaker4ImgJPG = await convertBase64ToJPEG(
            speaker4Img.croppedImage.croppedImage,
            'speaker4.jpg'
          );
          if (speaker4ImgJPG) {
            formData.append('speakerImages', speaker4ImgJPG);
          }
        }
      }
    }
    if (eventData.participants.speaker4Data) {
      formData.append('speaker4Name', eventData.participants.speaker4Data.name);

      formData.append('speaker4Bio', eventData.participants.speaker4Data.bio);

      formData.append('speaker4Id', eventData.participants.speaker4Data.id);
    }

    if (handleType === 'publish') {
      if (speaker5ImgRef.current) {
        speaker5Img = speaker5ImgRef.current.handleGetSpeaker();
        if (speaker5Img.croppedImage.croppedImage) {
          const speaker5ImgJPG = await convertBase64ToJPEG(
            speaker5Img.croppedImage.croppedImage,
            'speaker5.jpg'
          );
          if (speaker5ImgJPG) {
            formData.append('speakerImages', speaker5ImgJPG);
          }
        }
      }
    }

    if (eventData.participants.speaker5Data) {
      formData.append('speaker5Name', eventData.participants.speaker5Data.name);
      formData.append('speaker5Bio', eventData.participants.speaker5Data.bio);
      formData.append('speaker5Id', eventData.participants.speaker5Data.id);
    }

    //registrationData

    formData.append(
      'registrationsActive',
      eventData.registrationData.registrationsActive
    );

    formData.append(
      'maxRegistrationsInPersonEnabled',
      eventData.registrationData.maxRegistrationsInPersonEnabled
    );

    formData.append(
      'maxRegistrationsInPersonAmount',
      eventData.registrationData.maxRegistrationsInPersonAmount
    );

    formData.append(
      'maxRegistrationsVirtualEnabled',
      eventData.registrationData.maxRegistrationsVirtualEnabled
    );

    formData.append(
      'maxRegistrationsVirtualAmount',
      eventData.registrationData.maxRegistrationsVirtualAmount
    );

    formData.append(
      'privateDetails_enabled',
      eventData.registrationData.privateDetails.enabled
    );

    formData.append(
      'approveRegistrations',
      eventData.registrationData.approveRegistrations
    );
    formData.append(
      'privateDetails_room',
      eventData.registrationData.privateDetails.room
    );
    formData.append(
      'privateDetails_locationName',
      eventData.registrationData.privateDetails.locationName
    );
    formData.append(
      'privateDetails_streetAddress',
      eventData.registrationData.privateDetails.streetAddress
    );
    formData.append(
      'privateDetails_virtualMeetingID',
      eventData.registrationData.privateDetails.virtualMeetingId
    );
    formData.append(
      'privateDetails_virtualMeetingPasscode',
      eventData.registrationData.privateDetails.virtualMeetingPasscode
    );
    formData.append(
      'privateDetails_virtualMeetingLink',
      eventData.registrationData.privateDetails.virtualMeetingLink
    );

    //schedule
    if (eventData.schedule.enabled) {
      formData.append('schedule_enabled', eventData.schedule.enabled);
      formData.append('schedule_publishDate', eventData.schedule.publishDate);
      formData.append('schedule_publishTime', eventData.schedule.publishTime);
      formData.append(
        'schedule_publishTimeZone',
        eventData.schedule.publishTimeZone
      );
    }

    //tags
    formData.append('bigTags', eventData.tags.bigTags);
    formData.append('tinyTags', eventData.tags.tinyTags);

    if (eventImageRef.current) {
      eventImg = eventImageRef.current.handleGetEventImage();
      if (handleType === 'publish') {
        if (eventImg.croppedImage.croppedImage) {
          const eventImgJPG = await convertBase64ToJPEG(
            eventImg.croppedImage.croppedImage,
            'eventImage.jpg'
          );
          if (eventImgJPG) {
            formData.append('eventImages', eventImgJPG);
          }
        }
      }
    }

    return formData;
  }

  async function handleRemoveOrganizerEvent(eventId, organizerId, eventType) {
    if (
      !removeEventActiveUpdateAccountisLoading ||
      !removeEventActiveUpdateAccountisFetching ||
      !removeEventScheduledUpdateAccountisLoading ||
      !removeEventScheduledUpdateAccountisFetching ||
      !removeEventArchivedUpdateAccountisLoading ||
      !removeEventArchivedUpdateAccountisFetching ||
      !removeEventActiveisLoading ||
      !removeEventActiveisFetching ||
      !removeEventScheduledisLoading ||
      !removeEventScheduledisFetching
    ) {
      const accountId = organizerId;
      const recaptchaVerified = await handleReCaptchaVerify(
        'REMOVE_EVENT',
        false
      );
      if (recaptchaVerified !== true) return false;

      try {
        if (eventType === 'active') {
          await removeEventActiveUpdateAccount({ accountId, eventId });
          await removeEventActive({ accountId, eventId });
        } else if (eventType === 'scheduled') {
          await removeEventScheduledUpdateAccount({ accountId, eventId });
          await removeEventScheduled({ accountId, eventId });
        } else if (eventType === 'archived') {
          await removeEventArchivedUpdateAccount({ accountId, eventId });
        }

        handleSuccess({
          message: 'eventRemoved',
          id: Date.now(),
          origin: 'Organize.js/handleRemoveEvent',
        });
      } catch (error) {
        handleError({
          message: 'error',
          id: Date.now(),
          origin: 'Organize.js/handleRemoveEvent',
        });
      }
    }
  }

  function handleSocketOrganizedEventData(dataString) {
    let parsedData;
    try {
      parsedData = JSON.parse(dataString);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Dev, Error parsing JSON:', error);
      }
    }

    const operationType = parsedData.operationType;

    // not handling delete or insert
    if (operationType) {
      if (parsedData.operationType === 'update') {
        handleOrganizeSetOrganizedEvent(parsedData.updatedEvent);
      }
    }
  }

  return {
    //Selectors
    organizeMyOrganizedEvents,
    organizeMyScheduledOrganizedEvents,
    organizeMyArchivedOrganizedEvents,

    //sorted
    organizeSortedMyOrganizedEvents,
    organizeSortedMyScheduledOrganizedEvents,
    organizeSortedMyArchivedOrganizedEvents,

    //redux handlers
    handleOrganizeSetOrganizedEvent,

    //API
    handleRemoveOrganizerEvent,
    handleAcceptPendingApprovalInPerson,
    handleRemoveRegistrationInPerson,
    handleApprovePendingApprovalInPersonAll,
    handleAcceptPendingApprovalVirtual,
    handleRemoveRegistrationVirtual,

    //WS
    handleSocketOrganizedEventData,

    //Utility
    handleOrganizerEventFormData,
  };
};

export const useEventValidation = () => {
  const { CreateEvent } = useLanguageOrganize();
  const createEventData = useSelector(selectCreateEvent);
  const editEventData = useSelector(selectEditEvent);

  const { stringCheck } = useSanitize();

  let eventData;

  function handleEventValidation(eventSlice) {
    let validationsArrObj = [];

    if (eventSlice === 'create') {
      eventData = createEventData;
    } else if (eventSlice === 'edit') {
      eventData = editEventData;
    }

    //mandatory fields
    if (!eventData.generic.title) {
      validationsArrObj.push({
        generalLabel: `${CreateEvent.validations.eventTitle}`,
      });
    } else if (eventData.generic.title) {
      const check = stringCheck(eventData.generic.title);
      if (check) {
        validationsArrObj.push({
          generalLabel: `${CreateEvent.validations.eventTitle}`,
        });
      }
    }

    if (!eventData.generic.eventType) {
      validationsArrObj.push({
        eventTypeLabel: `${CreateEvent.validations.eventType}`,
      });
    }

    if (!eventData.generic.audienceType) {
      validationsArrObj.push({
        audienceTypeLabel: `${CreateEvent.validations.audienceType}`,
      });
    }

    if (!eventData.dateTime.date) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.date}`,
      });
    }

    if (!eventData.dateTime.timeZone) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.timeZone}`,
      });
    }
    if (!eventData.dateTime.timeStart) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.startTime}`,
      });
    }
    if (!eventData.dateTime.timeEnd) {
      validationsArrObj.push({
        dateTimeLabel: `${CreateEvent.validations.endTime}`,
      });
    }

    if (!eventData.hosting.hostingOption) {
      validationsArrObj.push({
        hostingOptionLabel: `${CreateEvent.validations.hostingOption}`,
      });
    }

    if (
      !eventData.generic.languages ||
      eventData.generic.languages?.length === 0 ||
      eventData.generic?.languages[0] === 'select'
    ) {
      validationsArrObj.push({
        languageLabel: `${CreateEvent.validations.language}`,
      });
    }
    if (eventData.tags.bigTags.length === 0) {
      validationsArrObj.push({
        bigTagsLabel: `${CreateEvent.validations.bigTags}`,
      });
    }
    if (eventData.tags.tinyTags.length === 0) {
      validationsArrObj.push({
        tinyTagsLabel: `${CreateEvent.validations.tinyTags}`,
      });
    }

    //conditional mandatory fields
    if (eventData.generic.agenda?.items?.length > 0) {
      let validation;

      eventData.generic.agenda.items.forEach((item) => {
        if (!item.text) {
          validation = true;
        }
      });
      if (validation) {
        validationsArrObj.push({
          agendaLabel: `${CreateEvent.validations.agendaItem}`,
        });
      }
    }

    if (eventData.participants.speaker0Data) {
      let validation;
      if (!eventData.participants.speaker0Data.name) {
        validation = true;
      }
      if (eventData.participants.speaker0Data.name) {
        const check = stringCheck(eventData.participants.speaker0Data.name);
        if (check) {
          validation = true;
        }
      }
      if (validation) {
        validationsArrObj.push({
          speaker0: `${CreateEvent.validations.speakerName}`,
        });
      }
    }
    if (eventData.participants.speaker1Data) {
      let validation;
      if (!eventData.participants.speaker1Data.name) {
        validation = true;
      }
      if (eventData.participants.speaker1Data.name) {
        const check = stringCheck(eventData.participants.speaker1Data.name);
        if (check) {
          validation = true;
        }
      }
      if (validation) {
        validationsArrObj.push({
          speaker1: `${CreateEvent.validations.speakerName}`,
        });
      }
    }
    if (eventData.participants.speaker2Data) {
      let validation;
      if (!eventData.participants.speaker2Data.name) {
        validation = true;
      }
      if (eventData.participants.speaker2Data.name) {
        const check = stringCheck(eventData.participants.speaker2Data.name);
        if (check) {
          validation = true;
        }
      }
      if (validation) {
        validationsArrObj.push({
          speaker2: `${CreateEvent.validations.speakerName}`,
        });
      }
    }

    if (eventData.participants.speaker3Data) {
      let validation;
      if (!eventData.participants.speaker3Data.name) {
        validation = true;
      }
      if (eventData.participants.speaker3Data.name) {
        const check = stringCheck(eventData.participants.speaker3Data.name);
        if (check) {
          validation = true;
        }
      }
      if (validation) {
        validationsArrObj.push({
          speaker3: `${CreateEvent.validations.speakerName}`,
        });
      }
    }
    if (eventData.participants.speaker4Data) {
      let validation;
      if (!eventData.participants.speaker4Data.name) {
        validation = true;
      }
      if (eventData.participants.speaker4Data.name) {
        const check = stringCheck(eventData.participants.speaker4Data.name);
        if (check) {
          validation = true;
        }
      }
      if (validation) {
        validationsArrObj.push({
          speaker4: `${CreateEvent.validations.speakerName}`,
        });
      }
    }
    if (eventData.participants.speaker5Data) {
      let validation;
      if (!eventData.participants.speaker5Data.name) {
        validation = true;
      }
      if (eventData.participants.speaker5Data.name) {
        const check = stringCheck(eventData.participants.speaker5Data.name);
        if (check) {
          validation = true;
        }
      }
      if (validation) {
        validationsArrObj.push({
          speaker5: `${CreateEvent.validations.speakerName}`,
        });
      }
    }

    if (eventData.hosting.hostingOption) {
      if (
        eventData.hosting.hostingOption === 'inPerson' ||
        eventData.hosting.hostingOption === 'hybrid'
      ) {
        if (!eventData.hosting.inPerson.location.streetAddress) {
          validationsArrObj.push({
            locationLabel: `${CreateEvent.validations.streetAddress}`,
          });
        } else if (eventData.hosting.inPerson.location.streetAddress) {
          const check = stringCheck(
            eventData.hosting.inPerson.location.streetAddress
          );
          if (check) {
            validationsArrObj.push({
              locationLabel: `${CreateEvent.validations.streetAddress}`,
            });
          }
        }
        if (!eventData.hosting.inPerson.location.city) {
          validationsArrObj.push({
            locationLabel: `${CreateEvent.validations.cityTown}`,
          });
        } else if (eventData.hosting.inPerson.location.city) {
          const check = stringCheck(eventData.hosting.inPerson.location.city);
          if (check) {
            validationsArrObj.push({
              locationLabel: `${CreateEvent.validations.cityTown}`,
            });
          }
        }
        if (!eventData.hosting.inPerson.location.country) {
          validationsArrObj.push({
            locationLabel: `${CreateEvent.validations.country}`,
          });
        } else if (eventData.hosting.inPerson.location.country) {
          const check = stringCheck(
            eventData.hosting.inPerson.location.country
          );
          if (check) {
            validationsArrObj.push({
              locationLabel: `${CreateEvent.validations.country}`,
            });
          }
        }
      }
      if (
        eventData.hosting.hostingOption === 'online' ||
        eventData.hosting.hostingOption === 'hybrid'
      ) {
        if (!eventData.hosting.virtual.platform) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.onlinePlatform}`,
          });
        }

        if (!eventData.hosting.virtual.meetingId) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.virtualMeetingId}`,
          });
        } else if (eventData.hosting.virtual.meetingId) {
          const check = stringCheck(eventData.hosting.virtual.meetingId);
          if (check) {
            validationsArrObj.push({
              onlinePlatformLabel: `${CreateEvent.validations.virtualMeetingId}`,
            });
          }
        }

        if (!eventData.hosting.virtual.passcode) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.viritualPasscode}`,
          });
        } else if (eventData.hosting.virtual.passcode) {
          const check = stringCheck(eventData.hosting.virtual.passcode);
          if (check) {
            validationsArrObj.push({
              onlinePlatformLabel: `${CreateEvent.validations.viritualPasscode}`,
            });
          }
        }
      }

      if (eventData.hosting.hostingOption === 'online') {
        if (!eventData.hosting.inPerson.location.country) {
          validationsArrObj.push({
            onlinePlatformLabel: `${CreateEvent.validations.country}`,
          });
        }
      }
    }

    if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
      if (eventData.registrationData.maxRegistrationsInPersonAmount === 0) {
        validationsArrObj.push({
          registrationLabel: `${CreateEvent.validations.maxRegistrationsInPerson}`,
        });
      }
    }

    if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
      if (eventData.registrationData.maxRegistrationsVirtualAmount === 0) {
        validationsArrObj.push({
          registrationLabel: `${CreateEvent.validations.maxRegistrationsVirtual}`,
        });
      }
    }

    if (eventData.schedule.enabled) {
      if (
        !eventData.schedule.publishDate ||
        !eventData.schedule.publishTime ||
        !eventData.schedule.publishTimeZone
      ) {
        validationsArrObj.push({
          scheduleLabel: `${CreateEvent.validations.schedulePublication}`,
        });
      }
    }
    if (validationsArrObj.length === 0) {
      return eventData;
    } else if (validationsArrObj.length > 0) {
      return validationsArrObj;
    }
  }

  return { handleEventValidation };
};
