//hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useTimezoneData from '../../language/useTimezoneData';

//components

//utils
import moment from 'moment-timezone';

const { timezones } = require('../../data/timezones');

function GetDateTimeSummaryForEventPreview(dateTimeObj, accountTimeZone) {
  const { GetDateTimeSummary } = useLanguageComponents();

  const currentUTCDate = new Date();
  //account dateTime
  const accountIanaTz = getTimeZoneIanaString(accountTimeZone);
  const accountCurrentDateTimeUTC = moment
    .utc(currentUTCDate)
    .tz(accountIanaTz)
    .toDate();

  // Parse the event date
  const eventDate = moment(dateTimeObj.date).toDate();

  // Calculate the time difference in milliseconds
  const timeDifference =
    eventDate.getTime() - accountCurrentDateTimeUTC.getTime();

  // Convert the time difference to days
  const daysUntilEvent = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  let timeSummary;

  if (daysUntilEvent === 0) {
    timeSummary = GetDateTimeSummary.today;
  } else {
    timeSummary = `${daysUntilEvent} ${GetDateTimeSummary.days}`;
  }

  return <p>{timeSummary}</p>;
}

//Event is over
//Today - within 1 day
//2+ days
function GetDateTimeSummary(eventDateTime, accountTimeZone, mobileOnly) {
  const { GetDateTimeSummary } = useLanguageComponents();

  if (mobileOnly) return;

  const currentUTCDate = new Date();

  //account dateTime
  const accountIanaTz = getTimeZoneIanaString(accountTimeZone);
  const accountCurrentDateTimeUTC = moment
    .utc(currentUTCDate)
    .tz(accountIanaTz)
    .toDate();

  //calc variables -- event has started and/or ended
  const eventStartDate = new Date(eventDateTime.dateTimeStartUTC);
  const timeDifferenceStart = eventStartDate - accountCurrentDateTimeUTC;
  const timeDifferenceStartInHours = timeDifferenceStart / 3600000;

  const eventDateEnd = new Date(eventDateTime.dateTimeEndUTC);
  const timeDifferenceEnd = eventDateEnd - accountCurrentDateTimeUTC;
  const eventDifferenceEndInHours = timeDifferenceEnd / 3600000;

  let timeSummary;
  //has started, and not over
  if (timeDifferenceStartInHours <= 0 && eventDifferenceEndInHours > 0) {
    timeSummary = GetDateTimeSummary.active;
    return <p>{timeSummary}</p>;
  } else if (
    //has started, and is over
    timeDifferenceStartInHours <= 0 &&
    eventDifferenceEndInHours <= 0
  ) {
    timeSummary = GetDateTimeSummary.finished;
    return <p>{timeSummary}</p>;
  } else if (timeDifferenceStartInHours <= 24) {
    timeSummary = GetDateTimeSummary.today;
    return <p>{timeSummary}</p>;
  } else if (timeDifferenceStartInHours <= 47) {
    timeSummary = `1 ${GetDateTimeSummary.day}`;
    return <p>{timeSummary}</p>;
  } else {
    timeSummary = `${Math.ceil(timeDifferenceStartInHours / 24)} ${
      GetDateTimeSummary.days
    }`;

    return <p>{timeSummary}</p>;
  }
}

function getTimeZoneIanaString(identifier) {
  // Loop through each country in the timezones data
  for (const countryKey in timezones) {
    const country = timezones[countryKey];
    const zones = country.zones;

    // // Loop through each zone in the country
    for (const zone of zones) {
      // Check if the zone has the given identifier
      if (zone.hasOwnProperty(identifier)) {
        return zone[identifier].iana; // Return the IANA time zone string
      }
    }
  }

  return null; // Return null if no matching time zone is found
}

function sortEventsByDateTime(state, direction) {
  // Perform sorting logic based on the `dateTime.dateTimeStartUTC` property
  const sortedArr = state.slice().sort((a, b) => {
    const dateA = new Date(a.dateTime.dateTimeStartUTC);
    const dateB = new Date(b.dateTime.dateTimeStartUTC);

    if (direction === 'ascending') {
      return dateA - dateB;
    } else if (direction === 'descending') {
      return dateB - dateA;
    }

    return 0;
  });

  return sortedArr;
}

function getDateShortMonth(day, locale) {
  const date = new Date(`${day} 00:00`).toLocaleString(
    `${locale ? locale : 'en-US'}`,
    {
      weekday: 'short',
      year: 'numeric',
      day: 'numeric',
      month: 'short',
    }
  );
  const parts = date.split(' ');
  parts[1] += '.';
  const updatedDate = parts.join(' ');

  return updatedDate;
}

function getDateLongMonth(day, locale) {
  const date = new Date(`${day} 00:00`).toLocaleString(
    `${locale ? locale : 'en-US'}`,
    {
      weekday: 'short',
      year: 'numeric',
      day: 'numeric',
      month: 'long',
    }
  );
  return date;
}

function DateFromTimeStamp(date, options, locale) {
  let localeLanguage = locale || 'en-CA';
  const newDate = new Date(date);

  const weekday = newDate.toLocaleString(`${localeLanguage}`, {
    weekday: 'long',
  });
  const month = newDate.toLocaleString(`${localeLanguage}`, {
    month: options?.month || 'long',
  });
  const year = newDate.getFullYear();

  let timeString;

  if (options?.time === 'off') {
    timeString = '';
  } else if (options?.time === '24hr') {
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();

    timeString = `(${hours < 10 ? '0' + hours : hours}:${
      minutes < 10 ? '0' + minutes : minutes
    })`;
  } else {
    let hours = newDate.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = newDate.getMinutes();

    timeString = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
  }
  let dateString;

  if (options?.weekday === 'off') {
    if (localeLanguage === 'fr-CA') {
      dateString = `${newDate.getDate()} ${month}${
        options?.month === 'short' ? '.' : ''
      }, ${year} ${timeString}`;
    } else {
      dateString = `${month}${
        options?.month === 'short' ? '.' : ''
      } ${newDate.getDate()}, ${year} ${timeString}`;
    }
  } else {
    dateString = `${weekday}, ${month}${
      options?.month === 'short' ? '.' : ''
    } ${newDate.getDate()}, ${year} ${timeString}`;
  }

  return dateString;
}

function getTimeFromTimeStamp(date, locale) {
  // let localeLanguage = locale || 'en-CA';
  const newDate = new Date(date);

  let timeString;
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  timeString = `(${hours < 10 ? '0' + hours : hours}:${
    minutes < 10 ? '0' + minutes : minutes
  })`;

  let dateString = ` ${timeString}`;

  return dateString;
}

function formatTime(time) {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  const period = hours < 12 ? 'AM' : 'PM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

function GetTimeRangeFromEventObject(dateTimeObj) {
  const { TimeZoneCountriesOpts } = useTimezoneData();
  let abbreviation = null;
  let timeRangeString;
  let countryName = null; // Variable to store the country name

  // Iterate through each country's timezone data
  for (const countryKey in TimeZoneCountriesOpts) {
    const country = TimeZoneCountriesOpts[countryKey];

    // Iterate through each zone in the country
    for (const zone of country.zones) {
      const zoneKey = Object.keys(zone)[0]; // Get the key name of the zone object
      const zoneObj = zone[zoneKey]; // Access the actual zone object

      if (zoneKey === dateTimeObj.timeZone) {
        abbreviation = zoneObj.abbr;
        countryName = country.countryName;
        break;
      }
    }

    if (abbreviation) break;
  }

  if (abbreviation && countryName) {
    const formattedStartTime = formatTime(dateTimeObj.timeStart);
    const formattedEndTime = formatTime(dateTimeObj.timeEnd);

    // Include country with abbreviation, e.g., "Mexico/ET"
    const formattedAbbreviation = `${countryName}/${abbreviation}`;

    timeRangeString = `${formattedStartTime} — ${formattedEndTime} (${formattedAbbreviation})`;
  } else {
    // console.error('No matching time zone found for:', dateTimeObj.timeZone); // Debugging
    timeRangeString = 'Invalid Time Zone';
  }

  // console.log('timeRangeString', timeRangeString); // Debugging
  return timeRangeString;
}

function GetTimeFromPublishObject(dateTimeObj) {
  const { TimeZoneCountriesOpts } = useTimezoneData();
  let abbreviation = null;
  let timeString;

  for (const countryKey in TimeZoneCountriesOpts) {
    const country = TimeZoneCountriesOpts[countryKey];

    for (const zoneKey in country.zones) {
      const zone = country.zones[zoneKey];

      if (zone[dateTimeObj?.publishTimeZone]) {
        abbreviation = zone[dateTimeObj?.publishTimeZone].abbr;
        break;
      }
    }

    const formattedStartTime = formatTime(dateTimeObj?.publishTime);

    timeString = `${formattedStartTime} (${abbreviation})`;

    return timeString;
  }
}
function checkLoadDateExpiration(loadDate) {
  const date = moment(loadDate).startOf('day');
  const currentDate = moment().startOf('day');

  return date.isBefore(currentDate);
}

function getDateOrTimeFromUTZstring(dateTimeStr, returnType, options) {
  const utcDate = new Date(dateTimeStr);

  if (returnType === 'date') {
    const opts = {
      year: options?.year || 'numeric',
      month: options?.month || 'short',
      day: options?.day || 'numeric',
      timeZone: options?.timeZone || 'UTC',
    };

    return utcDate.toLocaleDateString('en-US', opts);
  }
  if (returnType === 'time') {
    const utcDate = new Date(dateTimeStr);
    const hours = utcDate.getUTCHours().toString().padStart(2, '0');
    const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');

    // Construct the readable time string
    const readableTime = `${hours}:${minutes}`;

    return readableTime;
  }
}

const formatDateAndTimeFromCreatedAt = (isoString) => {
  const date = new Date(isoString);

  const day = date.getDate();
  const year = date.getFullYear();

  // Month names array
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const formattedDate = `${day} ${month} ${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${formattedDate}, ${formattedTime}`;
};

export {
  getDateShortMonth,
  getDateLongMonth,
  GetDateTimeSummary,
  sortEventsByDateTime,
  DateFromTimeStamp,
  GetTimeRangeFromEventObject,
  GetTimeFromPublishObject,
  GetDateTimeSummaryForEventPreview,
  checkLoadDateExpiration,
  getDateOrTimeFromUTZstring,
  getTimeFromTimeStamp,
  formatDateAndTimeFromCreatedAt,
};
