import { createSlice } from '@reduxjs/toolkit';

const adminAccountSlice = createSlice({
  name: 'adminAccount',
  initialState: {
    _id: '',
    appSettings: {
      contentLanguage: 'en-CA',
    },
    meta: {
      adminReference: '',
    },
    company: {
      position: '',
      teams: [],
      tickets: [],
    },
    personal: {
      countryCode: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      prefix: '',
      profileImage: '',
      pronouns: '',
    },
  },
  reducers: {
    //General
    setAdminAccount: (state, action) => {
      const { _id, meta, appSettings, company, personal } = action.payload;
      state._id = _id;
      state.meta = {
        adminReference: meta.adminReference,
      };
      state.appSettings = {
        contentLanguage: appSettings.contentLanguage,
      };
      state.company = {
        position: company.position,
        teams: company.teams,
        tickets: company.tickets,
      };
      state.personal = {
        countryCode: personal.countryCode,
        email: personal.email,
        firstName: personal.firstName,
        lastName: personal.lastName,
        phoneNumber: personal.phoneNumber,
        prefix: personal.prefix,
        profileImage: personal.profileImage,
        pronouns: personal.pronouns,
      };
    },
    emptyAdminAccountData: (state, action) => {
      state._id = null;
      state.meta = {
        adminReference: '',
      };
      state.appSettings = {
        contentLanguage: '',
      };
      state.company = {
        position: '',
        teams: [],
        tickets: [],
      };
      state.personal = {
        countryCode: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        prefix: '',
        profileImage: '',
        pronouns: '',
      };
    },
    //App Settings
    setAdminContentLanguage: (state, action) => {
      state.appSettings.contentLanguage = action.payload;
    },

    //Company
    setAdminPosition: (state, action) => {
      state.company.position = action.payload;
    },
    //Teams
    setAdminTeams: (state, action) => {
      state.company.teams = action.payload;
    },
    setAccountCompanyTickets: (state, action) => {
      state.company.tickets = action.payload;
    },

    //Personal
    setAdminCountryCode: (state, action) => {
      state.personal.countryCode = action.payload;
    },
    setAdminEmail: (state, action) => {
      state.personal.email = action.payload;
    },
    setAdminFirstName: (state, action) => {
      state.personal.firstName = action.payload;
    },
    setAdminLastName: (state, action) => {
      state.personal.lastName = action.payload;
    },
    setAdminPhoneNumber: (state, action) => {
      state.personal.phoneNumber = action.payload;
    },
    setAdminPrefix: (state, action) => {
      state.personal.prefix = action.payload;
    },
    setAdminProfileImage: (state, action) => {
      state.personal.profileImage = action.payload;
    },
    setAdminPronouns: (state, action) => {
      state.personal.pronouns = action.payload;
    },
  },
});

export const {
  setAdminAccount,
  emptyAdminAccountData,
  setAdminContentLanguage,
  setAdminPosition,
  setAdminCountryCode,
  setAdminEmail,
  setAdminFirstName,
  setAdminLastName,
  setAdminPhoneNumber,
  setAdminPrefix,
  setAdminProfileImage,
  setAdminPronouns,
  setAdminTeams,
  setAccountCompanyTickets,
} = adminAccountSlice.actions;

export default adminAccountSlice.reducer;

//General
export const selectAdminAccountId = (state) => state.adminAccount?._id;
export const selectAdminAccount = (state) => state.adminAccount;

//Meta
export const selectAdminAccountAdminReference = (state) =>
  state.adminAccount?.meta?.adminReference;

//AppSettings
export const selectAdminContentLanguage = (state) =>
  state.adminAccount?.appSettings?.contentLanguage;

//Company
export const selectAdminCompanyPosition = (state) =>
  state.adminAccount?.company?.position;
export const selectAdminTeams = (state) => state.adminAccount?.company?.teams;
export const selectAdminTickets = (state) =>
  state.adminAccount?.company?.tickets;

//Personal
export const selectAdminCountryCode = (state) =>
  state.adminAccount?.personal?.countryCode;
export const selectAdminEmail = (state) => state.adminAccount?.personal?.email;
export const selectAdminFirstName = (state) =>
  state.adminAccount?.personal?.firstName;
export const selectAdminLastName = (state) =>
  state.adminAccount?.personal?.lastName;
export const selectAdminProfileImage = (state) =>
  state.adminAccount?.personal?.profileImage;
export const selectAdminPhoneNumber = (state) =>
  state.adminAccount?.personal?.phoneNumber;
export const selectAdminPrefix = (state) =>
  state.adminAccount?.personal?.prefix;
export const selectAdminPronouns = (state) =>
  state.adminAccount?.personal?.pronouns;
