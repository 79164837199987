import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility

const initialState = {
  editEventChangeList: [],
  dateTime: {
    date: null,
    timeStart: null,
    timeEnd: null,
    timeZone: null,
    dateTimeStartLocal: null,
    dateTimeStartUTC: null,
    dateTimeEndLocal: null,
    dateTimeEndUTC: null,
    tzdb: null,
  },
  generic: {
    agenda: {
      format: 'minutes',
      items: [],
    },
    accessibilityNotes: '',
    audienceType: null,
    description: '',
    eventImagePath: null,
    eventType: null,
    languages: ['select'],
    realtimeTranslations: false,
    signLanguageInterpreter: false,
    title: '',
    wheelchairAccessibility: false,
  },
  hosting: {
    hostingOption: null,
    inPerson: {
      geoPosition: {
        type: 'Point',
        coordinates: [0, 0],
      },
      location: {
        city: '',
        country: '',
        locationName: '',
        placeId: '',
        postalCode: '',
        room: '',
        streetAddress: '',
      },
    },
    virtual: {
      meetingId: '',
      passcode: '',
      platform: '',
      meetingLink: '',
    },
  },
  meta: {
    lastModified: '',
    saveName: '',
    _id: '',
  },
  participants: {
    moderator: '',
    speaker0Data: null,
    speaker1Data: null,
    speaker2Data: null,
    speaker3Data: null,
    speaker4Data: null,
    speaker5Data: null,
  },
  registrationData: {
    registrationsActive: false,
    approveRegistrations: false,
    maxRegistrationsInPersonEnabled: false,
    maxRegistrationsInPersonAmount: 0,
    maxRegistrationsVirtualEnabled: false,
    maxRegistrationsVirtualAmount: 0,
    privateDetails: {
      enabled: false,
      room: false,
      locationName: false,
      streetAddress: false,
      virtualMeetingId: false,
      virtualMeetingPasscode: false,
      virtualMeetingLink: false,
    },
  },
  schedule: {
    enabled: false,
    publishDate: null,
    publishTime: null,
    publishTimeZone: null,
    publishDateTimeLocal: null,
    publishDateTimeUTC: null,
    tzdb: null,
  },
  tags: {
    bigTags: [],
    tinyTags: [],
  },
};

const editEventSlice = createSlice({
  name: 'editEvent',
  initialState: initialState,
  reducers: {
    setEditEventChangeList: (state, action) => {
      state.editEventChangeList = action.payload;
    },

    //dateTime
    setEditEventDateTimeDate: (state, action) => {
      state.dateTime.date = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventDateTimeTimeEnd: (state, action) => {
      state.dateTime.timeEnd = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventDateTimeTimeStart: (state, action) => {
      state.dateTime.timeStart = action.payload;
      state.dateTime.timeEnd = null;
      state.meta.lastModified = Date.now();
    },
    setEditEventDateTimeTimeZone: (state, action) => {
      state.dateTime.timeZone = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Generic
    setEditEventGenericAccessibilityNotes: (state, action) => {
      state.generic.accessibilityNotes = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericAgendaItems: (state, action) => {
      //read only Typeerror from strict mode avoided with code below, need to spread nested
      const newItems = [...action.payload.map((item) => ({ ...item }))];
      state.generic.agenda.items = newItems;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericAgendaFormat: (state, action) => {
      state.generic.agenda.format = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericAudienceType: (state, action) => {
      state.generic.audienceType = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericDescription: (state, action) => {
      state.generic.description = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericEventType: (state, action) => {
      state.generic.eventType = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericEventImagePath: (state, action) => {
      state.generic.eventImagePath = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericLanguages: (state, action) => {
      state.generic.languages = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericSignLanguageInterpreter: (state, action) => {
      state.generic.signLanguageInterpreter = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericTitle: (state, action) => {
      state.generic.title = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericWheelchairAccessibility: (state, action) => {
      state.generic.wheelchairAccessibility = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventGenericRealtimeTranslations: (state, action) => {
      state.generic.realtimeTranslations = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Hosting
    setEditEventHostingGeoPositionCoordinates: (state, action) => {
      state.hosting.inPerson.geoPosition.coordinates = action.payload;
      state.meta.lastModified = Date.now();
    },
    reverseEditEventHostingGeoPositionCoordinates: (state, action) => {
      state.hosting.inPerson.geoPosition.coordinates = [
        state.hosting.inPerson.geoPosition.coordinates[1],
        state.hosting.inPerson.geoPosition.coordinates[0],
      ];
      //dont modify save
    },
    setEditEventHostingHostingOption: (state, action) => {
      state.hosting.hostingOption = action.payload;
      if (!action.payload) {
        state.registrationData.maxRegistrationsInPersonEnabled = false;
        state.registrationData.maxRegistrationsVirtualEnabled = false;
        state.registrationData.privateDetails.enabled = false;
      }
      if (action.payload === 'online') {
        state.registrationData.maxRegistrationsInPersonEnabled = false;
      }
      if (action.payload === 'inPerson') {
        state.registrationData.maxRegistrationsVirtualEnabled = false;
      }
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingInPersonLocation: (state, action) => {
      state.hosting.inPerson.location = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualMeetingId: (state, action) => {
      state.hosting.virtual.meetingId = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualPasscode: (state, action) => {
      state.hosting.virtual.passcode = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualPlatform: (state, action) => {
      state.hosting.virtual.platform = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventHostingVirtualLink: (state, action) => {
      state.hosting.virtual.meetingLink = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Meta
    setEditEventMetaSaveName: (state, action) => {
      state.meta.saveName = action.payload;
    },

    //Participants
    setEditEventParticipantsModerator: (state, action) => {
      state.participants.moderator = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventParticipantsSpeaker0Data: (state, action) => {
      state.participants.speaker0Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventParticipantsSpeaker1Data: (state, action) => {
      state.participants.speaker1Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventParticipantsSpeaker2Data: (state, action) => {
      state.participants.speaker2Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventParticipantsSpeaker3Data: (state, action) => {
      state.participants.speaker3Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventParticipantsSpeaker4Data: (state, action) => {
      state.participants.speaker4Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventParticipantsSpeaker5Data: (state, action) => {
      state.participants.speaker5Data = action.payload;
      state.meta.lastModified = Date.now();
    },

    //RegistrationData
    setEditEventRegistrationDataApproveRegistrations: (state, action) => {
      state.registrationData.approveRegistrations = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsInPersonEnabled: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsInPersonEnabled = action.payload;
      if (action.payload === false) {
        state.registrationData.maxRegistrationsInPersonAmount = 0;
      }
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsVirtualEnabled: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsVirtualEnabled = action.payload;
      if (action.payload === false) {
        state.registrationData.maxRegistrationsVirtualAmount = 0;
      }
      state.meta.lastModified = Date.now();
    },

    setEditEventRegistrationDataPrivateDetailsEnabled: (state, action) => {
      state.registrationData.privateDetails.enabled = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsInPersonAmount: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsInPersonAmount = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataMaxRegistrationsVirtualAmount: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsVirtualAmount = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataPrivateDetails: (state, action) => {
      state.registrationData.privateDetails = {
        ...state.registrationData.privateDetails,
        [action.payload.id]: action.payload.val,
      };
      state.meta.lastModified = Date.now();
    },
    setEditEventRegistrationDataRegistrationsActive: (state, action) => {
      state.registrationData.registrationsActive = action.payload;
      if (action.payload === false) {
        state.registrationData.approveRegistrations = false;
        state.registrationData.maxRegistrationsInPersonEnabled = false;
        state.registrationData.maxRegistrationsVirtualEnabled = false;
        state.registrationData.maxRegistrationsInPersonAmount = 0;
        state.registrationData.maxRegistrationsVirtualAmount = 0;
        state.registrationData.privateDetails = {
          enabled: false,
          room: false,
          locationName: false,
          streetAddress: false,
          virtualMeetingId: false,
          virtualMeetingPasscode: false,
          virtualMeetingLink: false,
        };
      }
      state.meta.lastModified = Date.now();
    },

    //SchedulePublish
    setEditEventScheduleEnabled: (state, action) => {
      if (action.payload === false) {
        state.schedule.enabled = false;
        state.schedule.publishDate = null;
        state.schedule.publishTime = null;
        state.schedule.publishTimeZone = null;
        state.schedule.publishDateTimeLocal = null;
        state.schedule.publishDateTimeUTC = null;
        state.schedule.tzdb = null;
      } else {
        state.schedule.enabled = true;
      }
      state.meta.lastModified = Date.now();
    },
    setEditEventSchedulePublishDate: (state, action) => {
      state.schedule.publishDate = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventSchedulePublishTime: (state, action) => {
      state.schedule.publishTime = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventSchedulePublishTimeZone: (state, action) => {
      state.schedule.publishTimeZone = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Tags
    addEditEventTagsBigTag: (state, action) => {
      state.tags.bigTags.push(action.payload);
      state.meta.lastModified = Date.now();
    },
    addEditEventTagsTinyTag: (state, action) => {
      if (state.tags.tinyTags.length < 20) {
        state.tags.tinyTags = state.tags.tinyTags.concat([action.payload]);
        state.meta.lastModified = Date.now();
      }
    },
    removeEditEventTagsBigTag: (state, action) => {
      state.tags.bigTags = state.tags.bigTags.filter(
        (tag) => tag !== action.payload
      );
      state.meta.lastModified = Date.now();
    },
    removeEditEventTagsTinyTag: (state, action) => {
      state.tags.tinyTags = state.tags.tinyTags.filter(
        (tag) => tag !== action.payload
      );
      state.meta.lastModified = Date.now();
    },
    setEditEventTagsBigTags: (state, action) => {
      state.tags.bigTags = action.payload;
      state.meta.lastModified = Date.now();
    },
    setEditEventTagsTinyTags: (state, action) => {
      state.tags.tinyTags = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Set/Reset
    resetEditEvent: (state) => {
      return initialState;
    },
    setEditEvent: (state, action) => {
      return {
        ...state,
        dateTime: {
          date: action.payload.dateTime.date || null,
          timeStart: action.payload.dateTime.timeStart || null,
          timeEnd: action.payload.dateTime.timeEnd || null,
          timeZone: action.payload.dateTime.timeZone || null,
          dateTimeStartLocal:
            action.payload.dateTime.dateTimeStartLocal || null,
          dateTimeStartUTC: action.payload.dateTime.dateTimeStartUTC || null,
          dateTimeEndLocal: action.payload.dateTime.dateTimeEndLocal || null,
          dateTimeEndUTC: action.payload.dateTime.dateTimeEndUTC || null,
          tzdb: action.payload.dateTime.tzdb || null,
        },
        generic: {
          agenda: {
            format: action.payload.generic.agenda.format || 'minutes',
            items:
              action.payload.generic.agenda.items.map((item) => ({
                id: item.id,
                text: item.text || '',
                value: item.value || 0,
                startTime: item.startTime || '00:00',
                endTime: item.endTime || '00:00',
              })) || [],
          },
          accessibilityNotes: action.payload.generic.accessibilityNotes || '',
          audienceType: action.payload.generic.audienceType || null,
          description: action.payload.generic.description || '',
          eventImagePath: action.payload.generic.eventImagePath || null,
          eventType: action.payload.generic.eventType || null,
          languages: action.payload.generic.languages || [],
          realtimeTranslations:
            action.payload.generic.realtimeTranslations || false,
          signLanguageInterpreter:
            action.payload.generic.signLanguageInterpreter || false,
          title: action.payload.generic.title || '',
          wheelchairAccessibility:
            action.payload.generic.wheelchairAccessibility || false,
        },
        hosting: {
          hostingOption: action.payload.hosting.hostingOption || null,
          inPerson: {
            geoPosition: {
              type: 'Point',
              coordinates: action.payload.hosting?.inPerson.geoPosition
                .coordinates || [0, 0],
            },
            location: {
              city: action.payload.hosting?.inPerson?.location?.city || '',
              country:
                action.payload.hosting?.inPerson?.location?.country || '',
              locationName:
                action.payload.hosting?.inPerson?.location?.locationName || '',
              placeId:
                action.payload.hosting?.inPerson?.location?.placeId || '',
              postalCode:
                action.payload.hosting?.inPerson?.location?.postalCode || '',
              room: action.payload.hosting?.inPerson?.location?.room || '',
              streetAddress:
                action.payload.hosting?.inPerson?.location?.streetAddress || '',
            },
          },
          virtual: {
            meetingId: action.payload.hosting?.virtual?.meetingId || '',
            passcode: action.payload.hosting?.virtual?.passcode || '',
            platform: action.payload.hosting?.virtual?.platform || '',
            meetingLink: action.payload.hosting?.virtual?.meetingLink || '',
          },
        },
        meta: {
          _id: action.payload._id,
          lastModified: action.payload.meta.lastModified,
        },
        participants: {
          moderator: action.payload.participants?.moderator || '',
          speaker0Data: action.payload.participants?.speaker0Data || null,
          speaker1Data: action.payload.participants?.speaker1Data || null,
          speaker2Data: action.payload.participants?.speaker2Data || null,
          speaker3Data: action.payload.participants?.speaker3Data || null,
          speaker4Data: action.payload.participants?.speaker4Data || null,
          speaker5Data: action.payload.participants?.speaker5Data || null,
        },
        registrationData: {
          registrationsActive:
            action.payload.registrationData.registrationsActive || false,
          approveRegistrations:
            action.payload.registrationData.approveRegistrations || false,
          maxRegistrationsInPersonEnabled:
            action.payload.registrationData.maxRegistrationsInPersonEnabled ||
            false,
          maxRegistrationsInPersonAmount:
            action.payload.registrationData.maxRegistrationsInPersonAmount || 0,
          maxRegistrationsVirtualEnabled:
            action.payload.registrationData.maxRegistrationsVirtualEnabled ||
            false,
          maxRegistrationsVirtualAmount:
            action.payload.registrationData.maxRegistrationsVirtualAmount || 0,
          privateDetails: {
            enabled:
              action.payload.registrationData.privateDetails.enabled || false,
            room: action.payload.registrationData.privateDetails.room || false,
            locationName:
              action.payload.registrationData.privateDetails.locationName ||
              false,
            streetAddress:
              action.payload.registrationData.privateDetails.streetAddress ||
              false,
            virtualMeetingId:
              action.payload.registrationData.privateDetails.virtualMeetingId ||
              false,
            virtualMeetingPasscode:
              action.payload.registrationData.privateDetails
                .virtualMeetingPasscode || false,
            virtualMeetingLink:
              action.payload.registrationData.privateDetails
                .virtualMeetingLink || false,
          },
        },
        schedule: {
          enabled: action.payload?.schedule?.enabled || false,
          publishDate: action.payload?.schedule?.publishDate || null,
          publishTime: action.payload?.schedule?.publishTime || null,
          publishTimeZone: action.payload?.schedule?.publishTimeZone || null,
          publishDateTimeLocal:
            action.payload?.schedule?.publishDateTimeLocal || null,
          publishDateTimeUTC:
            action.payload?.schedule?.publishDateTimeUTC || null,
          tzdb: action.payload?.schedule?.tzdb || null,
        },
        tags: {
          bigTags: action.payload.tags.bigTags || [],
          tinyTags: action.payload.tags.tinyTags || [],
        },
      };
    },
  },
});

export const {
  setEditEventDateTimeDate,
  setEditEventDateTimeTimeEnd,
  setEditEventDateTimeTimeStart,
  setEditEventDateTimeTimeZone,
  setEditEventGenericAccessibilityNotes,
  setEditEventGenericAgendaItems,
  setEditEventGenericAgendaFormat,
  setEditEventGenericAudienceType,
  setEditEventGenericDescription,
  setEditEventGenericEventType,
  setEditEventGenericLanguages,
  setEditEventGenericSignLanguageInterpreter,
  setEditEventGenericTitle,
  setEditEventGenericWheelchairAccessibility,
  setEditEventGenericRealtimeTranslations,
  setEditEventHostingGeoPositionCoordinates,
  setEditEventHostingHostingOption,
  setEditEventHostingInPersonLocation,
  setEditEventHostingVirtualMeetingId,
  setEditEventHostingVirtualPasscode,
  setEditEventHostingVirtualPlatform,
  setEditEventHostingVirtualLink,
  setEditEventMetaSaveName,
  setEditEventParticipantsModerator,
  setEditEventParticipantsSpeaker0Data,
  setEditEventParticipantsSpeaker1Data,
  setEditEventParticipantsSpeaker2Data,
  setEditEventParticipantsSpeaker3Data,
  setEditEventParticipantsSpeaker4Data,
  setEditEventParticipantsSpeaker5Data,
  setEditEventRegistrationDataApproveRegistrations,
  setEditEventRegistrationDataMaxRegistrationsInPersonEnabled,
  setEditEventRegistrationDataMaxRegistrationsVirtualEnabled,
  setEditEventRegistrationDataPrivateDetailsEnabled,
  setEditEventRegistrationDataMaxRegistrationsInPersonAmount,
  setEditEventRegistrationDataMaxRegistrationsVirtualAmount,
  setEditEventRegistrationDataPrivateDetails,
  setEditEventRegistrationDataRegistrationsActive,
  setEditEventSchedulePublishDate,
  setEditEventSchedulePublishTime,
  setEditEventSchedulePublishTimeZone,
  addEditEventTagsBigTag,
  addEditEventTagsTinyTag,
  removeEditEventTagsBigTag,
  removeEditEventTagsTinyTag,
  setEditEventTagsBigTags,
  setEditEventTagsTinyTags,
  resetEditEvent,
  setEditEvent,
  reverseEditEventHostingGeoPositionCoordinates,
  setEditEventGenericEventImagePath,
  setEditEventScheduleEnabled,
  setEditEventChangeList,
} = editEventSlice.actions;

export default editEventSlice.reducer;

export const selectEditEvent = (state) => state.editEvent;
export const selectEditEventChangeList = (state) =>
  state.editEvent.editEventChangeList;
//dateTime
export const selectEditEventDateTimeDate = (state) =>
  state.editEvent.dateTime.date;
export const selectEditEventDateTimeTimeEnd = (state) =>
  state.editEvent.dateTime.timeEnd;
export const selectEditEventDateTimeTimeStart = (state) =>
  state.editEvent.dateTime.timeStart;
export const selectEditEventDateTimeTimeZone = (state) =>
  state.editEvent.dateTime.timeZone;

//Generic
export const selectEditEventGenericAccessibilityNotes = (state) =>
  state.editEvent?.generic?.accessibilityNotes;
export const selectEditEventGenericAgendaItems = (state) =>
  state.editEvent?.generic?.agenda.items;
export const selectEditEventGenericAgendaFormat = (state) =>
  state.editEvent?.generic?.agenda.format;
export const selectEditEventGenericAudienceType = (state) =>
  state.editEvent?.generic?.audienceType;
export const selectEditEventGenericDescription = (state) =>
  state.editEvent?.generic?.description;
export const selectEditEventGenericEventImagePath = (state) =>
  state.editEvent?.generic?.eventImagePath;
export const selectEditEventGenericEventType = (state) =>
  state.editEvent?.generic?.eventType;
export const selectEditEventGenericLanguages = (state) =>
  state.editEvent?.generic?.languages;
export const selectEditEventGenericRealtimeTranslations = (state) =>
  state.editEvent?.generic?.realtimeTranslations;
export const selectEditEventGenericSignLanguageInterpreter = (state) =>
  state.editEvent?.generic?.signLanguageInterpreter;
export const selectEditEventGenericTitle = (state) =>
  state.editEvent?.generic?.title;
export const selectEditEventGenericWheelchairAccessibility = (state) =>
  state.editEvent?.generic?.wheelchairAccessibility;

//Hosting
export const selectEditEventHostingInPersonGeoPositionCoordinates = (state) =>
  state.editEvent?.hosting?.inPerson?.geoPosition?.coordinates;
export const selectEditEventHostingHostingOption = (state) =>
  state.editEvent?.hosting?.hostingOption;
export const selectEditEventHostingInPersonLocation = (state) =>
  state.editEvent?.hosting?.inPerson?.location;
export const selectEditEventHostingVirtualMeetingId = (state) =>
  state.editEvent?.hosting?.virtual?.meetingId;
export const selectEditEventHostingVirtualPasscode = (state) =>
  state.editEvent?.hosting?.virtual?.passcode;
export const selectEditEventHostingVirtualPlatform = (state) =>
  state.editEvent?.hosting?.virtual?.platform;
export const selectEditEventHostingVirtualLink = (state) =>
  state.editEvent?.hosting?.virtual?.meetingLink;

//Meta
export const selectEditEventMetaLastModified = (state) =>
  state.editEvent?.meta?.lastModified;
export const selectEditEventMetaSaveName = (state) =>
  state.editEvent?.meta?.saveName;

//Participants
export const selectEditEventParticipantsModerator = (state) =>
  state.editEvent?.participants?.moderator;
export const selectEditEventParticipantsSpeaker0Data = (state) =>
  state.editEvent?.participants?.speaker0Data;
export const selectEditEventParticipantsSpeaker1Data = (state) =>
  state.editEvent?.participants?.speaker1Data;
export const selectEditEventParticipantsSpeaker2Data = (state) =>
  state.editEvent?.participants?.speaker2Data;
export const selectEditEventParticipantsSpeaker3Data = (state) =>
  state.editEvent?.participants?.speaker3Data;
export const selectEditEventParticipantsSpeaker4Data = (state) =>
  state.editEvent?.participants?.speaker4Data;
export const selectEditEventParticipantsSpeaker5Data = (state) =>
  state.editEvent?.participants?.speaker5Data;

//RegistrationData
export const selectEditEventRegistrationDataApproveRegistrations = (state) =>
  state.editEvent?.registrationData?.approveRegistrations;

export const selectEditEventRegistrationDataMaxRegistrationsInPersonEnabled = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsInPersonEnabled;

export const selectEditEventRegistrationDataMaxRegistrationsVirtualEnabled = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsVirtualEnabled;
export const selectEditEventRegistrationDataPrivateDetailsEnabled = (state) =>
  state.editEvent?.registrationData?.privateDetails.enabled;
export const selectEditEventRegistrationDataMaxRegistrationsInPersonAmount = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsInPersonAmount;
export const selectEditEventRegistrationDataMaxRegistrationsVirtualAmount = (
  state
) => state.editEvent?.registrationData?.maxRegistrationsVirtualAmount;
export const selectEditEventRegistrationDataPrivateDetails = (state) =>
  state.editEvent?.registrationData?.privateDetails;
export const selectEditEventRegistrationDataRegistrationsActive = (state) =>
  state.editEvent?.registrationData?.registrationsActive;

//SchedulePublish
export const selectEditEventScheduleEnabled = (state) =>
  state.editEvent?.schedule?.enabled;
export const selectEditEventSchedulePublishDate = (state) =>
  state.editEvent?.schedule?.publishDate;
export const selectEditEventSchedulePublishTime = (state) =>
  state.editEvent?.schedule?.publishTime;
export const selectEditEventSchedulePublishTimeZone = (state) =>
  state.editEvent?.schedule?.publishTimeZone;

//Tags
export const selectEditEventTagsBigTags = (state) =>
  state.editEvent?.tags?.bigTags;
export const selectEditEventTagsTinyTags = (state) =>
  state.editEvent?.tags?.tinyTags;
