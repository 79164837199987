import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';
import { useAdminAccountReduxHandlers } from '../../../account/useAdminAccountHandlers';

const InfoCardAssignmentIcon = ({ supportData, ticketData }) => {
  const { SupportStatus } = useLanguageAdmin();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  let assignmentIcon;

  if (ticketData?.status === 'resolved') {
    assignmentIcon = null;
  } else if (supportData?.current?._id === adminAccountId) {
    assignmentIcon = (
      <p className="ticket-status ticket-status--assigned mrg-r12">
        {SupportStatus['myAssignment']}
      </p>
    );
  } else if (supportData?.current?._id) {
    assignmentIcon = (
      <p className="ticket-status ticket-status--assigned mrg-r12">
        {SupportStatus['assigned']}
      </p>
    );
  } else {
    assignmentIcon = (
      <p className="ticket-status ticket-status--unassigned mrg-r12">
        {SupportStatus['unassigned']}
      </p>
    );
  }

  return assignmentIcon;
};

export default InfoCardAssignmentIcon;
