import { useEffect } from 'react';
//Hooks

//Components

//Utils
import ScrollTo from '../utils/UI/ScrollTo';

const BlankScreen = () => {
  //Initialize functions
  useEffect(() => {
    ScrollTo('navbar', 'auto', true);
  }, []);

  return (
    <div className="app">
      <div className="loading-screen" />
    </div>
  );
};

export default BlankScreen;
