//Hooks

import useLanguageLayout from '../language/useLanguageLayout';

const Unauthorized = () => {
  //Hooks
  const { App } = useLanguageLayout();

  return (
    <div className="layout">
      <div className="panel-container bg-blur">
        <div className="panel mrg-auto flex-center">
          <h1>{App.unauthorized}</h1>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
