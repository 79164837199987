import useSettings from '../context/useSettings';

const useLanguageTagSuggestions = () => {
  const { contentLanguage } = useSettings();

  const TagSuggestions = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        anth: {
          anthrozoology: '#anthrozoology',
          appliedAnthropology: '#appliedanthropology',
          archaeology: '#archaeology',
          culturalAthropology: '#culturalathropology',
          ethnobiology: '#ethnobiology',
          ethnobotany: '#ethnobotany',
          ethnography: '#ethnography',
          ethnopoetics: '#ethnopoetics',
          evolutionaryAnthropology: '#evolutionaryanthropology',
          experimentalArchaeology: '#experimentalarchaeology',
          forensicAnthropology: '#forensicanthropology',
          historicalArchaeology: '#historicalarchaeology',
          linguisticAnthropology: '#linguisticanthropology',
          medicalAnthropology: '#medicalanthropology',
          physicalAnthropology: '#physicalanthropology',
          psychologicalAnthropology: '#psychologicalanthropology',
          zooarchaeology: '#zooarchaeology',
        },
        asci: {
          actuarialScience: '#actuarialscience',
          agriculture: '#agriculture',
          astronautics: '#astronautics',
          computing: '#computing',
          cryptography: '#cryptography',
          electronics: '#electronics',
          energy: '#energy',
          environmentalScience: '#environmentalscience',
          forensics: '#forensics',
          gis: '#geographicinformationsystems',
          microtechnology: '#microtechnology',
          militaryScience: '#militaryscience',
          nanotech: '#nanotech',
          nuclear: '#nuclear',
          optics: '#optics',
          photogrammetry: '#photogrammetry',
          spaceExploration: '#spaceexploration',
          spaceScience: '#spacescience',
          spatialScience: '#spatialscience',
          technology: '#technology',
        },
        astr: {
          astrobiology: '#astrobiology',
          astrodynamics: '#astrodynamics',
          astrometry: '#astrometry',
          astrophysics: '#astrophysics',
          cosmology: '#cosmology',
          extragalactic: '#extragalactic',
          galactic: '#galactic',
          planetaryScience: '#planetaryscience',
          stellar: '#stellar',
        },
        astu: {
          africanStudies: '#africanstudies',
          americanStudies: '#americanstudies',
          canadianStudies: '#canadianstudies',
          eastAsianStudies: '#eastasianstudies',
          europeanStudies: '#europeanstudies',
          latinAmericanStudies: '#latinamericanstudies',
          middleEasternStudies: '#middleeasternstudies',
          regionalStudies: '#regionalstudies',
          southAsianStudies: '#southasianstudies',
        },
        bus: {
          accounting: '#accounting',
          commerce: '#commerce',
          finance: '#finance',
          management: '#management',
          marketing: '#marketing',
          operations: '#operations',
          organizationalStudies: '#organizationalstudies',
          strategy: '#strategy',
        },
        chem: {
          analyticalChemistry: '#analyticalchemistry',
          astroChemistry: '#astrochemistry',
          atmosphericChemistry: '#atmosphericchemistry',
          biochemistry: '#biochemistry',
          flowChemistry: '#flowchemistry',
          geochemistry: '#geochemistry',
          inorganicChemistry: '#inorganicchemistry',
          nuclearChemistry: '#nuclearchemistry',
          organic: '#organic',
          organometallicChemistry: '#organometallicchemistry',
          photochemistry: '#photochemistry',
          physicalChemistry: '#physicalchemistry',
          phytochemistry: '#phytochemistry',
          polymerChemistry: '#polymerchemistry',
          solidStateChemistry: '#solidstatechemistry',
        },
        clas: {
          ancient: '#ancient',
          antiquity: '#antiquity',
          archaeology: '#archaeology',
          architecture: '#architecture',
          art: '#art',
          bronzeAge: '#bronzeage',
          greek: '#greek',
          languages: '#languages',
          literature: '#literature',
          numismatics: '#numismatics',
          philology: '#philology',
          philosophy: '#philosophy',
          religion: '#religion',
          roman: '#roman',
          society: '#society',
        },
        csci: {
          artificialIntelligence: '#artificialintelligence',
          algorithms: '#algorithms',
          communications: '#communications',
          computation: '#computation',
          computerArchitecture: '#computerarchitecture',
          databases: '#databases',
          dataStructures: '#datastructures',
          graphics: '#graphics',
          languages: '#languages',
          programming: '#programming',
          softwareEngineering: '#softwareengineering',
          systems: '#systems',
        },
        cula: {
          butchery: '#butchery',
          chef: '#chef',
          cooking: '#cooking',
          cuisine: '#cuisine',
          food: '#food',
          foodPairing: '#foodpairing',
          foodSafety: '#foodsafety',
          gastronomy: '#gastronomy',
          nutrition: '#nutrition',
          visualPresentation: '#visualpresentation',
        },
        easci: {
          atmosphericScience: '#atmosphericscience',
          biogeography: '#biogeography',
          cartography: '#cartography',
          climatology: '#climatology',
          coastalGeography: '#coastalgeography',
          environmentalScience: '#environmentalscience',
          geography: '#geography',
          geoinformatics: '#geoinformatics',
          geology: '#geology',
          geomorphology: '#geomorphology',
          geophysics: '#geophysics',
          geostatistics: '#geostatistics',
          glaciology: '#glaciology',
          hydrogeology: '#hydrogeology',
          hydrology: '#hydrology',
          limnology: '#limnology',
          meteorology: '#meterology',
          mineralogy: '#mineralogy',
          oceanography: '#oceanography',
          paleoclimatology: '#paleoclimatology',
          paleontology: '#paleontology',
          petrology: '#petrology',
          seismology: '#seismology',
          soilScience: '#soilscience',
          topography: '#topography',
          volcanology: '#volcanology',
        },
        econ: {
          behaviouralEconomics: '#behaviouraleconomics',
          bioeconomics: '#bioeconomics',
          comparativeEconomics: '#comparativeeconomics',
          developmentEconomics: '#developmenteconomics',
          ecologicalEconomics: '#ecologicaleconomics',
          econometrics: '#econometrics',
          energyEconomics: '#energyeconomics',
          entrepreneurialEconomics: '#entrepreneurialeconomics',
          environmentalEconomics: '#environmentaleconomics',
          evolutionaryEconomics: '#evolutionaryeconomics',
          financialEconomics: '#financialeconomics',
          greenEconomics: '#greeneconomics',
          heterodoxEconomics: '#heterodoxeconomics',
          industrialOrganization: '#industrialorganization',
          internationalEconomics: '#internationaleconomics',
          labourEconomics: '#laboureconomics',
          macroEconomics: '#macroeconomics',
          managerialEconomics: '#managerialeconomics',
          mathematicalEconomics: '#mathematicaleconomics',
          microEconomics: '#microeconomics',
          monetaryPolicy: '#monetarypolicy',
          neoclassicalEconomics: '#neoclassicaleconomics',
          politicalEconomy: '#politicaleconomy',
          publicEconomics: '#publiceconomics',
          publicFinance: '#publicfinance',
          socioEconomics: '#socioeconomics',
        },
        edu: {
          earlyChildhoodEducation: '#earlychildhoodeducation',
          educationalAssessment: '#educationalassessment',
          educationalPolicy: '#educationalpolicy',
          educationalPsychology: '#educationalpsychology',
          educationalResearch: '#educationalresearch',
          higherEducation: '#highereducation',
          specialEducation: '#specialeducation',
        },
        eng: {
          aerospace: '#aerospace',
          agriculturalEngineering: '#agriculturalengineering',
          appledEngineering: '#appledengineering',
          bioEngineering: '#bioengineering',
          biomedicalEngineering: '#biomedicalengineering',
          chemicalEngineering: '#chemicalengineering',
          civilEngineering: '#civilengineering',
          computerEngineering: '#computerengineering',
          electricalEngineering: '#electricalengineering',
          engineeringPhysics: '#engineeringphysics',
          industrialEngineering: '#industrialengineering',
          marineEngineering: '#marineengineering',
          materialsScience: '#materialsscience',
          mechanicalEngineering: '#mechanicalengineering',
          mining: '#mining',
          nuclearEngineering: '#nuclearengineering',
          softwareEngineering: '#softwareengineering',
          systemsEngineering: '#systemsengineering',
        },
        gend: {
          feminism: '#feminism',
          genderHistory: '#genderhistory',
          genderIdentity: '#genderidentity',
          intersectionality: '#intersectionality',
          mensStudies: '#mensstudies',
          queerStudies: '#queerstudies',
          representation: '#representation',
          transgenderStudies: '#transgenderstudies',
          womensHistory: '#womenshistory',
          womensStudies: '#womensstudies',
        },
        hgeo: {
          behavioralGeography: '#behavioralgeography',
          culturalGeography: '#culturalgeography',
          developmentGeography: '#developmentgeography',
          economicGeography: '#economicgeography',
          environmentalGeography: '#environmentalgeography',
          geopolitics: '#geopolitics',
          historicalGeography: '#historicalgeography',
          politicalGeography: '#politicalgeography',
          populationGeography: '#populationgeography',
          regionalGeography: '#regionalgeography',
          socialGeography: '#socialgeography',
          timeGeography: '#timegeography',
          urbanGeography: '#urbangeography',
        },
        hist: {
          archaeology: '#archaeology',
          artHistory: '#arthistory',
          culturalHistory: '#culturalhistory',
          economicHistory: '#economichistory',
          localHistory: '#localhistory',
          militaryHistory: '#militaryhistory',
          navalHistory: '#navalhistory',
          paleography: '#paleography',
          psychoHistory: '#psychohistory',
          publicHistory: '#publichistory',
          urbanHistory: '#urbanhistory',
          womensHistory: '#womenshistory',
          worldHistory: '#worldhistory',
        },
        hsci: {
          anatomy: '#anatomy',
          dentistry: '#dentistroy',
          medicine: '#medicine',
          nursing: '#nursing',
          nutrition: '#nutrition',
          optometry: '#optometry',
          pharmacology: '#pharmacology',
          pharmacy: '#pharmacy',
          physicalTherapy: '#physicaltherapy',
          physiology: '#physiology',
          veterinary: '#veterinary',
        },
        lang: {
          english: '#english',
          french: '#french',
          indoeuropeanLanguages: '#indoeuropeanlanaguages',
          italian: '#italian',
          japanese: '#japanese',
          latin: '#latin',
          romanceLanguages: '#romancelanguages',
          slavicLanguages: '#slaviclanguages',
          spanish: '#spanish',
        },
        law: {
          administrativeLaw: '#administrativelaw',
          admiraltyLaw: '#admiraltylaw',
          animalLaw: '#animallaw',
          canonLaw: '#canonlaw',
          civilLaw: '#civillaw',
          civilProcedure: '#civilprocedure',
          commercialLaw: '#commerciallaw',
          commonLaw: '#commonlaw',
          comparativeLaw: '#comparativelaw',
          competitionLaw: '#competitionlaw',
          constitutionalLaw: '#constitutionallaw',
          contractLaw: '#contractlaw',
          corporateLaw: '#corporatelaw',
          criminalJustice: '#criminaljustice',
          criminalLaw: '#criminallaw',
          criminalProcedure: '#criminalprocedure',
          environmentalLaw: '#environmentallaw',
          familyLaw: '#familylaw',
          federalLaw: '#federallaw',
          internationalLaw: '#internationallaw',
          jurisprudence: '#jurisprudence',
          labourLaw: '#labourlaw',
          legalManagement: '#legalmanagement',
          proceduralLaw: '#procedurallaw',
          propertyLaw: '#propertylaw',
          substantiveLaw: '#substantivelaw',
          taxLaw: '#taxlaw',
          tortLaw: '#tortlaw',
        },
        ling: {
          anthropologicalLinguistics: '#anthropologicallinguistics',
          appliedLinguistics: '#appliedlinguistics',
          biolinguistics: '#biolinguistics',
          clinicalLinguistics: '#clinicallinguistics',
          cognitiveLinguistics: '#cognitivelinguistics',
          comparativeLinguistics: '#comparativelinguistics',
          computationalLinguistics: '#computationallinguistics',
          developmentalLinguistics: '#developmentallinguistics',
          dialectology: '#dialectology',
          discourseAnalysis: '#discourseanalysis',
          etymology: '#etymology',
          evolutionaryLinguistics: '#evolutionarylinguistics',
          forensicLinguistics: '#forensiclinguistics',
          geolinguistics: '#geolinguistics',
          historicalLinguistics: '#historicallinguistics',
          language: '#language',
          lexis: '#lexis',
          linguisticTypology: '#linguistictypology',
          morphology: '#morphology',
          neurolinguistics: '#neurolinguistics',
          orthography: '#orthography',
          philology: '#philology',
          phonetics: '#phonetics',
          phonology: '#phonology',
          phraseology: '#phraseology',
          pragmatics: '#pragmatics',
          psycholinguistics: '#psycholinguistics',
          semantics: '#semantics',
          sociolinguistics: '#sociolinguistics',
          speechScience: '#speechscience',
          stylistics: '#stylistics',
          syntax: '#syntax',
        },
        lisci: {
          agriculture: '#agriculture',
          anatomy: '#anatomy',
          biochemistry: '#biochemistry',
          bioengineering: '#bioengineering',
          biology: '#biology',
          biomechanics: '#biomechanics',
          biophysics: '#biophysics',
          biotech: '#biotech',
          botany: '#botany',
          cellBiology: '#cellbiology',
          ecology: '#ecology',
          epidemiology: '#epidemiology',
          evolution: '#evolution',
          genetics: '#genetics',
          limnology: '#limnology',
          marineBiology: '#marinebiology',
          microbiology: '#microbiology',
          molecularBiology: '#molecularbiology',
          pathobiology: '#pathobiology',
          physiology: '#physiology',
          zoology: '#zoology',
        },
        lit: {
          comedy: '#comedy',
          drama: '#drama',
          fiction: '#fiction',
          folklore: '#folklore',
          narrative: '#narrative',
          nonFiction: '#non-fiction',
          poetry: '#poetry',
          prose: '#prose',
          satire: '#satire',
          tragedy: '#tragedy',
        },
        math: {
          algebra: '#algebra',
          analysis: '#analysis',
          appliedMath: '#appliedmath',
          geometry: '#geometry',
          logic: '#logic',
          numberTheory: '#numbertheory',
          probabilityTheory: '#probabilitytheory',
          trigonometry: '#trigonometry',
        },
        med: {
          communication: '#communication',
          digital: '#digital',
          entertainment: '#entertainment',
          journalism: '#journalism',
          massMedia: '#massmedia',
          mediaEthics: '#mediaethics',
          newMedia: '#newmedia',
          online: '#online',
          popCulture: '#popculture',
          radio: '#radio',
          technology: '#technology',
          television: '#television',
        },
        perf: {
          ballet: '#ballet',
          choreography: '#choreography',
          dance: '#dance',
          improv: '#improv',
          music: '#music',
          musical: '#musical',
          musicology: '#musicology',
          musicTheory: '#musictheory',
          opera: '#opera',
          orchestra: '#orchestra',
          theatre: '#theatre',
        },
        phil: {
          aesthetics: '#aesthetics',
          epistemology: '#epistemology',
          ethics: '#ethics',
          logic: '#logic',
          metaphilosophy: '#metaphilosophy',
          metaphysics: '#metaphysics',
          philosophyOfMind: '#philosophyofmind',
          philosophyOfScience: '#philosophyofscience',
        },
        phys: {
          acoustics: '#acoustics',
          appliedPhysics: '#appliedphysics',
          atmosphericPhysics: '#atmosphericphysics',
          biophysics: '#biophysics',
          chemicalPhysics: '#chemicalphysics',
          computationalPhysics: '#computationalphysics',
          cryogenics: '#cryogenics',
          electromagnetism: '#electromagnetism',
          geophysics: '#geophysics',
          mathematicalPhysics: '#mathematicalphysics',
          mechanics: '#mechanics',
          nuclearPhysics: '#nuclearphysics',
          optics: '#optics',
          particlePhysics: '#particlephysics',
          quantumPhysics: '#quantumphysics',
          relativity: '#relativity',
          statics: '#statics',
        },
        poli: {
          civics: '#civics',
          comparativePolitics: '#comparativepolitics',
          geopolitics: '#geopolitics',
          ideology: '#ideology',
          industrialRelations: '#industrialrelations',
          internationalPolitics: '#internationalpolitics',
          internationalStudies: '#internationalstudies',
          localGovernmentStudies: '#localgovernmentstudies',
          politicalEconomy: '#politicaleconomy',
          politicalPsychology: '#politicalpsychology',
          psephology: '#psephology',
          publicAdministration: '#publicadministration',
          publicHealth: '#publichealth',
          publicPolicy: '#publicpolicy',
          votingSystems: '#votingsystems',
        },
        psy: {
          appliedPsychology: '#appliedpsychology',
          behaviorAnalysis: '#behavioranalysis',
          clinicalPsychology: '#clinicalpsychology',
          cognitivePsychology: '#cognitivepsychology',
          communityPsychology: '#communitypsychology',
          consumerBehaviour: '#consumerbehaviour',
          counselingPsychology: '#counselingpsychology',
          developmentalPsychology: '#developmentalpsychology',
          educationalPsychology: '#educationalpsychology',
          evolutionaryPsychology: '#evolutionarypsychology',
          experimentalPsychology: '#experimentalpsychology',
          forensicPsychology: '#forensicpsychology',
          healthPsychology: '#healthpsychology',
          militaryPsychology: '#militarypsychology',
          neuropsychology: '#neuropsychology',
          occupationalHealthPsychology: '#occupationalhealthpsychology',
          personalityPsychology: '#personalitypsychology',
          politicalPsychology: '#politicalpsychology',
          psychometrics: '#psychometrics',
          sportPsychology: '#sportpsychology',
          systemsPsychology: '#systemspsychology',
        },
        rel: {
          apologetics: '#apologetics',
          atheism: '#atheism',
          buddhism: '#buddhism',
          christianity: '#christianity',
          eschatology: '#eschatology',
          gnosticism: '#gnosticism',
          islam: '#islam',
          judaism: '#judaisim',
          religiousStudies: '#religiousstudies',
          theism: '#theism',
          theology: '#theology',
        },
        soci: {
          criminology: '#criminology',
          demography: '#demography',
          economicSociology: '#economicsociology',
          environmentalSociology: '#envrionmentalsociology',
          genderAndSexuality: '#genderandsexuality',
          inequality: '#inequality',
          politicalSociology: '#politicalsociology',
          populationStudies: '#populationstudies',
          raceAndEthnicity: '#raceandethnicity',
          ruralSociology: '#ruralsociology',
          socialChange: '#socialchange',
          socialNetworks: '#socialnetworks',
          socialPsychology: '#socialspsychology',
          socialTheory: '#socialtheory',
          urbanAndRuralSociology: '#urbanandruralsociology',
          urbanSociology: '#urbansociology',
        },
        stat: {
          appliedStatistics: '#appliedstatistics',
          computationalStatistics: '#computationalstatistics',
          dataScience: '#datascience',
          engineeringStatistics: '#engineeringstatistics',
          informationScience: '#informationscience',
          socialStatistics: '#socialstatistics',
          spatialStatistics: '#spacialstatistics',
          statisticalModelling: '#statisticalmodelling',
          statisticalTheory: '#statisticaltheory',
        },
        visa: {
          architecture: '#architecture',
          ceramics: '#ceramics',
          crafts: '#crafts',
          design: '#design',
          drawing: '#drawing',
          film: '#film',
          painting: '#painting',
          photography: '#photography',
          print: '#print',
          sculpture: '#sculpture',
          video: '#video',
        },
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        anth: {
          anthrozoology: '#anthrozoologie',
          appliedAnthropology: '#anthropologieappliquée',
          archaeology: '#archéologie',
          culturalAthropology: '#anthropologieculturelle',
          ethnobiology: '#ethnobiologie',
          ethnobotany: '#ethnobotanique',
          ethnography: '#ethnographie',
          ethnopoetics: '#ethnopoesie',
          evolutionaryAnthropology: '#anthropologieévolutive',
          experimentalArchaeology: '#archéologieexpérimentale',
          forensicAnthropology: '#anthropologiejudiciaire',
          historicalArchaeology: '#archéologiehistorique',
          linguisticAnthropology: '#anthropologielinguistique',
          medicalAnthropology: '#anthropologiemedicale',
          physicalAnthropology: '#anthropologiephysique',
          psychologicalAnthropology: '#anthropologiepsychologique',
          zooarchaeology: '#zooarchéologie',
        },
        asci: {
          actuarialScience: '#actuariat',
          agriculture: '#agriculture',
          astronautics: '#astronautique',
          computing: '#informatique',
          cryptography: '#cryptographie',
          electronics: '#électronique',
          energy: '#énergie',
          environmentalScience: '#sciencesdelenvironnement',
          forensics: '#médecinelégale',
          gis: '#systèmesdinformationgéographique',
          microtechnology: '#microtechnologie',
          militaryScience: '#sciencemilitaire',
          nanotech: '#nanotechnologie',
          nuclear: '#nucléaire',
          optics: '#optique',
          photogrammetry: '#photogrammétrie',
          spaceExploration: '#explorationspatiale',
          spaceScience: '#sciencespatiale',
          spatialScience: '#sciencespatiale',
          technology: '#technologie',
        },
        astr: {
          astrobiology: '#astrobiologie',
          astrodynamics: '#astrodynamique',
          astrometry: '#astrométrie',
          astrophysics: '#astrophysique',
          cosmology: '#cosmologie',
          extragalactic: '#extragalactique',
          galactic: '#galactique',
          planetaryScience: '#sciencesplanétaires',
          stellar: '#stellaire',
        },
        astu: {
          africanStudies: '#étudesafricaines',
          americanStudies: '#étudesaméricaines',
          canadianStudies: '#étudescanadiennes',
          eastAsianStudies: '#étudesasiatiquesorientales',
          europeanStudies: '#étudeseuropéennes',
          latinAmericanStudies: '#étudeslatinoaméricaines',
          middleEasternStudies: '#étudesmoyenorientales',
          regionalStudies: '#étudesrégionales',
          southAsianStudies: '#étudesasiatiqueduSud',
        },
        bus: {
          accounting: '#comptabilité',
          commerce: '#commerce',
          finance: '#finance',
          management: '#gestion',
          marketing: '#marketing',
          operations: '#opérations',
          organizationalStudies: '#étudesorganisationnelles',
          strategy: '#stratégie',
        },
        chem: {
          analyticalChemistry: '#chimieanalytique',
          astroChemistry: '#astrochimie',
          atmosphericChemistry: '#chimieatmosphérique',
          biochemistry: '#biochimie',
          flowChemistry: '#chimiedeflux',
          geochemistry: '#géochimie',
          inorganicChemistry: '#chimieinorganique',
          nuclearChemistry: '#chimienucléaire',
          organic: '#chimieorganique',
          organometallicChemistry: '#chimieorganométallique',
          photochemistry: '#photochimie',
          physicalChemistry: '#chimiephysique',
          phytochemistry: '#phytochimie',
          polymerChemistry: '#chimiedespolymères',
          solidStateChemistry: '#chimiedelétatsolide',
        },
        clas: {
          ancient: '#antique',
          antiquity: '#antiquité',
          archaeology: '#archéologie',
          architecture: '#architecture',
          art: '#art',
          bronzeAge: '#âgedubronze',
          greek: '#grec',
          history: '#histoire',
          languages: '#langues',
          literature: '#littérature',
          numismatics: '#numismatique',
          philology: '#philologie',
          philosophy: '#philosophie',
          religion: '#religion',
          roman: '#romain',
          society: '#société',
        },
        csci: {
          artificialIntelligence: '#intelligenceartificielle',
          algorithms: '#algorithmes',
          communications: '#communications',
          computation: '#calcul',
          computerArchitecture: '#architectureinformatique',
          databases: '#basesdedonnées',
          dataStructures: '#structuresdedonnées',
          graphics: '#graphiques',
          languages: '#langages',
          programming: '#programmation',
          softwareEngineering: '#géniedeslogiciels',
          systems: '#systèmes',
        },
        cula: {
          butchery: '#boucherie',
          chef: '#chef',
          cooking: '#cuisine',
          cuisine: '#cuisine',
          food: '#alimentation',
          foodPairing: '#accordmetsvins',
          foodSafety: '#sécuritéalimentaire',
          gastronomy: '#gastronomie',
          nutrition: '#nutrition',
          visualPresentation: '#présentationvisuelle',
        },
        easci: {
          atmosphericScience: '#sciencesdelatmosphère',
          biogeography: '#biogéographie',
          cartography: '#cartographie',
          climatology: '#climatologie',
          coastalGeography: '#géographiecôtière',
          environmentalScience: '#sciencesdelenvironnement',
          geography: '#géographie',
          geoinformatics: '#géoinformatique',
          geology: '#géologie',
          geomorphology: '#géomorphologie',
          geophysics: '#géophysique',
          geostatistics: '#géostatistique',
          glaciology: '#glaciologie',
          hydrogeology: '#hydrogéologie',
          hydrology: '#hydrologie',
          limnology: '#limnologie',
          meteorology: '#météorologie',
          mineralogy: '#minéralogie',
          oceanography: '#océanographie',
          paleoclimatology: '#paléoclimatologie',
          paleontology: '#paléontologie',
          petrology: '#pétrologie',
          seismology: '#sismologie',
          soilScience: '#sciencedusol',
          topography: '#topographie',
          volcanology: '#volcanologie',
        },
        econ: {
          behaviouralEconomics: '#économiecomportementale',
          bioeconomics: '#bioéconomie',
          comparativeEconomics: '#économiecomparée',
          developmentEconomics: '#économiedudéveloppement',
          ecologicalEconomics: '#économieécologique',
          econometrics: '#économétrie',
          energyEconomics: '#économiedelénergie',
          entrepreneurialEconomics: '#économieentrepreneuriale',
          environmentalEconomics: '#économieenvironnementale',
          evolutionaryEconomics: '#économieévolutionnaire',
          financialEconomics: '#économiefinancière',
          greenEconomics: '#économieverte',
          heterodoxEconomics: '#économiehétérodoxe',
          industrialOrganization: '#organisationindustrielle',
          internationalEconomics: '#économieinternationale',
          labourEconomics: '#économiedutravail',
          macroEconomics: '#macroéconomie',
          managerialEconomics: '#économiedelamanagement',
          mathematicalEconomics: '#économiemathématique',
          microEconomics: '#microéconomie',
          monetaryPolicy: '#politiquemonétaire',
          neoclassicalEconomics: '#économiénéoclassique',
          politicalEconomy: '#économiepolitique',
          publicEconomics: '#économiepublique',
          publicFinance: '#financespubliques',
          socioEconomics: '#socioéconomie',
        },
        edu: {
          earlyChildhoodEducation: '#éducationprécoceenfance',
          educationalAssessment: '#évaluationéducationnelle',
          educationalPolicy: '#politiqueéducative',
          educationalPsychology: '#psychologieéducative',
          educationalResearch: '#recherchéducationnelle',
          higherEducation: '#enseignementsupérieur',
          specialEducation: '#éducationspécialisée',
        },
        eng: {
          aerospace: '#aérospatiale',
          agriculturalEngineering: '#génieagricole',
          appledEngineering: '#géniedesapplications',
          bioEngineering: '#géniebiologique',
          biomedicalEngineering: '#géniebiomédical',
          chemicalEngineering: '#géniechimique',
          civilEngineering: '#géniecivil',
          computerEngineering: '#génieinformatique',
          electricalEngineering: '#génieélectrique',
          engineeringPhysics: '#physiqueappliquée',
          industrialEngineering: '#génieindustriel',
          marineEngineering: '#génienavale',
          materialsScience: '#sciencedesmatériaux',
          mechanicalEngineering: '#géniemécanique',
          mining: '#exploitationminière',
          nuclearEngineering: '#génienucléaire',
          softwareEngineering: '#géniedeslogiciels',
          systemsEngineering: '#géniedessystèmes',
        },
        gend: {
          feminism: '#féminisme',
          genderHistory: '#histoiredugenre',
          genderIdentity: '#identitédegenre',
          intersectionality: '#intersectionnalité',
          mensStudies: '#étudeshommes',
          queerStudies: '#étudesqueer',
          representation: '#représentation',
          transgenderStudies: '#étudestransgenres',
          womensHistory: '#histoiredesfemmes',
          womensStudies: '#étudesféminines',
        },
        hgeo: {
          behavioralGeography: '#géographiecomportementale',
          culturalGeography: '#géographieculturelle',
          developmentGeography: '#géographiedudéveloppement',
          economicGeography: '#géographieéconomique',
          environmentalGeography: '#géographieenvironnementale',
          geopolitics: '#géopolitique',
          historicalGeography: '#géographiehistorique',
          politicalGeography: '#géographiepolitique',
          populationGeography: '#géographiedelapopulation',
          regionalGeography: '#géographierégionale',
          socialGeography: '#géographiesociale',
          timeGeography: '#géographietemporelle',
          urbanGeography: '#géographieurbaine',
        },
        hist: {
          archaeology: '#archéologie',
          artHistory: '#histoiredelart',
          culturalHistory: '#histoireculturelle',
          economicHistory: '#histoireéconomique',
          localHistory: '#histoirelocale',
          militaryHistory: '#histoiremilitaire',
          navalHistory: '#histoirenavale',
          paleography: '#paléographie',
          psychoHistory: '#psychohistoire',
          publicHistory: '#histoirepublique',
          urbanHistory: '#histoireurbaine',
          womensHistory: '#histoiredesfemmes',
          worldHistory: '#histoiremondiale',
        },
        hsci: {
          anatomy: '#anatomie',
          dentistry: '#dentisterie',
          medicine: '#médecine',
          nursing: '#soinsinfirmiers',
          nutrition: '#nutrition',
          optometry: '#optométrie',
          pharmacology: '#pharmacologie',
          pharmacy: '#pharmacie',
          physicalTherapy: '#physiothérapie',
          physiology: '#physiologie',
          veterinary: '#vétérinaire',
        },
        lang: {
          english: '#anglais',
          french: '#français',
          indoeuropeanLanguages: '#languesindoeuropéennes',
          italian: '#italien',
          japanese: '#japonais',
          latin: '#latin',
          romanceLanguages: '#languesromanes',
          slavicLanguages: '#languesslaves',
          spanish: '#espagnol',
        },
        law: {
          administrativeLaw: '#droitadministratif',
          admiraltyLaw: '#droitdAmirauté',
          animalLaw: '#droitsurlesanimaux',
          canonLaw: '#droitcanonique',
          civilLaw: '#droitcivil',
          civilProcedure: '#procédurecivile',
          commercialLaw: '#droitcommercial',
          commonLaw: '#commonlaw(loicoutumière)',
          comparativeLaw: '#droitcomparé',
          competitionLaw: '#droitdelaconcurrence',
          constitutionalLaw: '#droitconstitutionnel',
          contractLaw: '#droitdescontrats',
          corporateLaw: '#droitdesociétés',
          criminalJustice: '#justicerépressive',
          criminalLaw: '#droitpénal',
          criminalProcedure: '#procédurepénale',
          environmentalLaw: '#droitdelenvironnement',
          familyLaw: '#droitdefamille',
          federalLaw: '#droitfédéral',
          internationalLaw: '#droitinternational',
          jurisprudence: '#jurisprudence',
          labourLaw: '#droitdutravail',
          legalManagement: '#gestionjuridique',
          proceduralLaw: '#droitprocédural',
          propertyLaw: '#droitimmobilier',
          substantiveLaw: '#droitmatériel',
          taxLaw: '#droitfiscal',
          tortLaw: '#droitdélit',
        },
        ling: {
          anthropologicalLinguistics: '#linguistiqueanthropologique',
          appliedLinguistics: '#linguistiqueappliquée',
          biolinguistics: '#biolinguistique',
          clinicalLinguistics: '#linguistiqueclinique',
          cognitiveLinguistics: '#linguistiquecognitive',
          comparativeLinguistics: '#linguistiquecomparée',
          computationalLinguistics: '#linguistiqueinformatique',
          developmentalLinguistics: '#linguistiquedudéveloppement',
          dialectology: '#dialectologie',
          discourseAnalysis: '#analysedudiscours',
          etymology: '#étymologie',
          evolutionaryLinguistics: '#linguistiqueévolutionnaire',
          forensicLinguistics: '#linguistiquejudiciaire',
          geolinguistics: '#géolinguistique',
          historicalLinguistics: '#linguistiquehistorique',
          language: '#langage',
          lexis: '#lexique',
          linguisticTypology: '#typologielinguistique',
          morphology: '#morphologie',
          neurolinguistics: '#neurolinguistique',
          orthography: '#orthographe',
          philology: '#philologie',
          phonetics: '#phonétique',
          phonology: '#phonologie',
          phraseology: '#phraséologie',
          pragmatics: '#pragmatique',
          psycholinguistics: '#psycholinguistique',
          semantics: '#sémantique',
          sociolinguistics: '#sociolinguistique',
          speechScience: '#sciencesdelaparole',
          stylistics: '#stylistique',
          syntax: '#syntaxe',
        },
        lisci: {
          agriculture: '#agriculture',
          anatomy: '#anatomie',
          biochemistry: '#biochimie',
          bioengineering: '#bioingénierie',
          biology: '#biologie',
          biomechanics: '#biomécanique',
          biophysics: '#biophysique',
          biotech: '#biotechnologie',
          botany: '#botanique',
          cellBiology: '#biologiecellulaire',
          ecology: '#écologie',
          epidemiology: '#épidémiologie',
          evolution: '#évolution',
          genetics: '#génétique',
          limnology: '#limnologie',
          marineBiology: '#biologiemarine',
          microbiology: '#microbiologie',
          molecularBiology: '#biologiemoléculaire',
          pathobiology: '#pathobiologie',
          physiology: '#physiologie',
          zoology: '#zoologie',
        },
        lit: {
          comedy: '#comédie',
          drama: '#drame',
          fiction: '#fiction',
          folklore: '#folklore',
          narrative: '#récit',
          nonFiction: '#nonfiction',
          poetry: '#poésie',
          prose: '#prose',
          satire: '#satire',
          tragedy: '#tragédie',
        },
        math: {
          algebra: '#algèbre',
          analysis: '#analyse',
          appliedMath: '#mathématiquesappliquées',
          geometry: '#géométrie',
          logic: '#logique',
          numberTheory: '#théoriedesnombres',
          probabilityTheory: '#théoriedesprobabilités',
          trigonometry: '#trigonométrie',
        },
        med: {
          communication: '#communication',
          digital: '#numérique',
          entertainment: '#divertissement',
          journalism: '#journalisme',
          massMedia: '#médiasdemasse',
          mediaEthics: '#éthiquedesmédias',
          newMedia: '#nouveauxmédias',
          online: '#enligne',
          popCulture: '#culturepopulaire',
          radio: '#radio',
          technology: '#technologie',
          television: '#télévision',
        },
        perf: {
          ballet: '#ballet',
          choreography: '#chorégraphie',
          dance: '#danse',
          improv: '#improvisation',
          music: '#musique',
          musical: '#comédiemusicale',
          musicology: '#musicologie',
          musicTheory: '#théoriemusicale',
          opera: '#opéra',
          orchestra: '#orchestre',
          theatre: '#théâtre',
        },
        phil: {
          aesthetics: '#esthétique',
          epistemology: '#épistémologie',
          ethics: '#éthique',
          logic: '#logique',
          metaphilosophy: '#métaphilosophie',
          metaphysics: '#métaphysique',
          philosophyOfMind: '#philosophiedelesprit',
          philosophyOfScience: '#philosophiedelascience',
        },
        phys: {
          acoustics: '#acoustique',
          appliedPhysics: '#physiqueappliquée',
          atmosphericPhysics: '#physiqueatmosphérique',
          biophysics: '#biophysique',
          chemicalPhysics: '#physiquechimique',
          computationalPhysics: '#physiquecomputationnelle',
          cryogenics: '#cryogénie',
          electromagnetism: '#électromagnétisme',
          geophysics: '#géophysique',
          mathematicalPhysics: '#physiquemathématique',
          mechanics: '#mécanique',
          nuclearPhysics: '#physiquenucléaire',
          optics: '#optique',
          particlePhysics: '#physiquedesparticules',
          quantumPhysics: '#physiquequantique',
          relativity: '#relativité',
          statics: '#statique',
        },
        poli: {
          civics: '#éducationcivique',
          comparativePolitics: '#politiquecomparée',
          geopolitics: '#géopolitique',
          ideology: '#idéologie',
          industrialRelations: '#relationsindustrielles',
          internationalPolitics: '#politiqueinternationale',
          internationalStudies: '#étudesinternationales',
          localGovernmentStudies: '#étudessurlegouvernementlocal',
          politicalEconomy: '#économiepolitique',
          politicalPsychology: '#psychologiepolitique',
          psephology: '#sémiologieélectorale',
          publicAdministration: '#administrationpublique',
          publicHealth: '#santépublique',
          publicPolicy: '#politiquepublique',
          votingSystems: '#systèmesdevote',
        },
        psy: {
          appliedPsychology: '#psychologieappliquée',
          behaviorAnalysis: '#analyseducomportement',
          clinicalPsychology: '#psychologieclinique',
          cognitivePsychology: '#psychologiecognitive',
          communityPsychology: '#psychologiecommunautaire',
          consumerBehaviour: '#comportementduconsommateur',
          counselingPsychology: '#psychologieduconseil',
          developmentalPsychology: '#psychologiedudéveloppement',
          educationalPsychology: '#psychologiedeléducation',
          evolutionaryPsychology: '#psychologieévolutionniste',
          experimentalPsychology: '#psychologieexpérimentale',
          forensicPsychology: '#psychologielégale',
          healthPsychology: '#psychologiedelasanté',
          militaryPsychology: '#psychologiemilitaire',
          neuropsychology: '#neuropsychologie',
          occupationalHealthPsychology: '#psychologiedelasantéautravail',
          personalityPsychology: '#psychologiedelapersonnalité',
          politicalPsychology: '#psychologiepolitique',
          psychometrics: '#psychométrie',
          sportPsychology: '#psychologiedusport',
          systemsPsychology: '#psychologiedessystèmes',
        },
        rel: {
          apologetics: '#apologétique',
          atheism: '#athéisme',
          buddhism: '#bouddhisme',
          christianity: '#christianisme',
          eschatology: '#eschatologie',
          gnosticism: '#gnosticisme',
          islam: '#islam',
          judaism: '#judaïsme',
          religiousStudies: '#étudesreligieuses',
          theism: '#théisme',
          theology: '#théologie',
        },
        soci: {
          criminology: '#criminologie',
          demography: '#démographie',
          economicSociology: '#sociologieéconomique',
          environmentalSociology: '#sociologiedelenvironnement',
          genderAndSexuality: '#genreetsexualité',
          inequality: '#inégalité',
          politicalSociology: '#sociologiepolitique',
          populationStudies: '#étudesdepopulation',
          raceAndEthnicity: '#raceetethnicité',
          ruralSociology: '#sociologierurale',
          socialChange: '#changementsocial',
          socialNetworks: '#réseauxsociaux',
          socialPsychology: '#psychologiesociale',
          socialTheory: '#théoriesociale',
          urbanAndRuralSociology: '#sociologieurbaineetrurale',
          urbanSociology: '#sociologieurbaine',
        },
        stat: {
          appliedStatistics: '#statistiquesappliquées',
          computationalStatistics: '#statistiquescomputationnelles',
          dataScience: '#sciencesdesdonnées',
          engineeringStatistics: '#statistiquesgénie',
          informationScience: '#sciencedelinformation',
          socialStatistics: '#statistiquessociales',
          spatialStatistics: '#statistiquesspatiales',
          statisticalModelling: '#modélisationstatistique',
          statisticalTheory: '#théoriestatistique',
        },
        visa: {
          architecture: '#architecture',
          ceramics: '#céramique',
          crafts: '#artisanat',
          design: '#design',
          drawing: '#dessin',
          film: '#cinéma',
          painting: '#peinture',
          photography: '#photographie',
          print: '#estampe',
          sculpture: '#sculpture',
          video: '#vidéo',
        },
      };
    }

    return translation;
  };

  return {
    TagSuggestions: TagSuggestions(),
  };
};

export default useLanguageTagSuggestions;
