import { useEffect, useRef, useState } from 'react';
//Hooks
import useSettings from '../../../../context/useSettings';
import { useEventHandlers } from '../useEventHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useLanguageEvents from '../../../../language/features/useLanguageEvents';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import EventStatusBar from '../../../../components/EventStatusBar/EventStatusBar';
import EventStatusBarSwitch from '../../../../components/EventStatusBar/EventStatusBarSwitch';
import AddressContent from './AddressContent';
import { EventCardSkeleton } from '../EventSkeletons';
//Utility
import {
  building2,
  calendar,
  eventcard_follow,
  eventcard_ignore,
  eventcard_star,
  eventcard_accessibility,
  globe2,
  hybrid2,
  organize,
  star,
  trash,
  view_ignore,
  bell,
} from '../../../../assets/icons';
import {
  getDateLongMonth,
  getDateShortMonth,
  GetDateTimeSummary,
} from '../../../../utils/dateTime/DateCalc';
import { getDistance } from '../../../../utils/spatial/DistanceCalc';

const EventCard = ({ eventData, setViewEvent }) => {
  //Hooks
  const { EventCard } = useLanguageEvents();
  const {
    accountContentLanguage,
    accountTimeZone,
    accountDistanceFilter,
    accountDistanceMeasurement,
    accountId,
    accountGeoPosition,
    accountSavedEvents,
    accountRegistrationsInPerson,
    accountPendingApprovalInPerson,
    accountIgnoredEvents,
    accountFollowing,
    accountFollowingGroups,
    accountRegistrationsVirtual,
    accountPendingApprovalVirtual,
  } = useAccountReduxHandlers();

  const { width, isTouchDevice, handleMobileTap } = useSettings();
  const {
    handleRemoveArchivedEvent,
    handleUserSaveEvent,
    handleUserIgnoreEvent,
  } = useEventHandlers();
  const { settingsMyEventsView, settingsEventSearchView } =
    useSettingsHandlers();

  //Component state
  const [eventStatus, setEventStatus] = useState('neutral');

  const [img1Loaded, setImg1Loaded] = useState();
  const [img2Loaded, setImg2Loaded] = useState();
  const [img3Loaded, setImg3Loaded] = useState();
  const [img4Loaded, setImg4Loaded] = useState();

  //UI state
  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [tapHighlightSave, setTapHighlightSave] = useState(false);
  const [tapHighlightIgnore, setTapHighlightIgnore] = useState(false);
  const [tapHighlightRemoveArchived, setTapHighlightRemoveArchived] =
    useState(false);
  const [tapHighlightAnnouncements, setTapHighlightAnnouncements] =
    useState(false);
  const [tapCardScaleDown, setTapCardScaleDown] = useState(false);
  const [imageLoadConfirmation, setImageLoadConfirmation] = useState(false);

  //Component variables
  const imageContainerRef = useRef(null);
  const timerRef = useRef(null);
  const temporaryOrg =
    'Undergraduate Association of History,York University Students';

  let distance = '';
  let accountDistanceMaxInKm;
  let accountDistanceMaxInMi;
  let cardActions;

  //Initialize functions
  if (accountGeoPosition?.coordinates && eventData?.geoPosition?.coordinates) {
    distance = getDistance(
      accountGeoPosition.coordinates[0],
      accountGeoPosition.coordinates[1],
      eventData.hosting.inPerson.geoPosition.coordinates[0],
      eventData.hosting.inPerson.geoPosition.coordinates[1],
      accountDistanceMeasurement
    );
    if (accountDistanceMeasurement === 'km') {
      accountDistanceMaxInKm = accountDistanceFilter / 1000;
      accountDistanceMaxInKm = accountDistanceMaxInKm.toFixed(1);
      if (distance > accountDistanceMaxInKm) {
        distance = '';
      }
    } else {
      accountDistanceMaxInMi = accountDistanceFilter / 1609.34;
      accountDistanceMaxInMi = accountDistanceMaxInMi.toFixed(1);
      if (distance > accountDistanceMaxInMi) {
        distance = '';
      }
    }
  } ////distance calc

  //Component functions
  useEffect(() => {
    //Generic status
    if (accountSavedEvents?.includes(eventData._id)) {
      return setEventStatus('saved');
    }
    if (accountIgnoredEvents?.includes(eventData._id)) {
      return setEventStatus('ignored');
    }

    //InPerson
    if (accountPendingApprovalInPerson?.includes(eventData._id)) {
      if (accountRegistrationsVirtual.includes(eventData._id)) {
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        return setEventStatus('pendingApprovalInPerson');
      }
    }

    if (accountRegistrationsInPerson.includes(eventData._id)) {
      if (accountPendingApprovalVirtual.includes(eventData._id)) {
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        return setEventStatus('registeredInPerson');
      }
    }

    //Virtual
    if (accountPendingApprovalVirtual?.includes(eventData._id)) {
      if (accountRegistrationsInPerson.includes(eventData._id)) {
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        return setEventStatus('pendingApprovalVirtual');
      }
    }

    if (accountRegistrationsVirtual.includes(eventData._id)) {
      if (accountPendingApprovalInPerson.includes(eventData._id)) {
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        return setEventStatus('registeredVirtual');
      }
    }

    /*eslint-disable-next-line*/
  }, [
    accountSavedEvents,
    accountIgnoredEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]);

  function handleActionOverlayClick(selection) {
    if (!isTouchDevice || overlayDisplay) {
      if (selection === 'removeArchived') {
        handleMobileTap(
          [
            () => setTapHighlightRemoveArchived(true),
            () => setTapHighlightRemoveArchived(false),
          ],
          [
            () =>
              handleRemoveArchivedEvent(
                eventData._id,
                accountId,
                eventData.meta.eventReference
              ),
            () => setOverlayDisplay(false),
          ],
          true
        );
      } else if (selection === 'save') {
        handleMobileTap(
          [() => setTapHighlightSave(true), () => setTapHighlightSave(false)],
          [
            () =>
              handleUserSaveEvent(
                eventData._id,
                accountId,
                eventStatus,
                setEventStatus
              ),
            () => setOverlayDisplay(false),
          ],
          true
        );
      } else if (selection === 'ignore') {
        handleMobileTap(
          [
            () => setTapHighlightIgnore(true),
            () => setTapHighlightIgnore(false),
          ],
          [
            () =>
              handleUserIgnoreEvent(
                eventData._id,
                accountId,
                eventStatus,
                setEventStatus
              ),
            () => setOverlayDisplay(false),
          ],
          true
        );
      } else if (selection === 'announcements') {
        handleMobileTap(
          [
            () => setTapHighlightAnnouncements(true),
            () => setTapHighlightAnnouncements(false),
          ],
          [
            () => console.log('Open announcements'),
            () => setOverlayDisplay(false),
          ],
          true
        );
      }
    }
  }

  function handleOverlayClick() {
    if (isTouchDevice) {
      let timeout = null;
      timeout = setTimeout(() => {
        setOverlayDisplay(true);
      }, 250);
      return () => {
        if (timeout !== null) {
          clearTimeout(timeout);
        }
      };
    }
  }

  function handleBlur() {
    // Set a timer to check if the focus has moved outside the container or its children
    if (document?.activeElement) {
      timerRef.current = setTimeout(() => {
        if (!imageContainerRef.current?.contains(document.activeElement)) {
          setOverlayDisplay(false);
        }
      }, 500);

      return () => {
        clearTimeout(timerRef.current);
      };
    }
  }

  //JSX Conditional
  if (
    eventStatus === 'registeredInPerson' ||
    eventStatus === 'pendingApprovalInPerson' ||
    eventStatus === 'registeredVirtual' ||
    eventStatus === 'pendingApprovalVirtual' ||
    eventStatus === 'pendingApprovalInPerson-registeredVirtual' ||
    eventStatus === 'pendingApprovalVirtual-registeredInPerson'
  ) {
    //card overlay for archived events
    cardActions = (
      <div
        className={`actions-overlay  ${
          overlayDisplay ? 'opacity-1 ' : 'opacity-0'
        }`}
      >
        <button
          type="button"
          className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
            tapHighlightAnnouncements
              ? 'highlight-bg-theme-light--tap click-scaledown-90'
              : ''
          }`}
          style={img4Loaded ? { display: 'flex' } : { display: 'none' }}
          onClick={() => handleActionOverlayClick('announcements')}
          tabIndex={overlayDisplay ? '0' : '-1'}
          aria-label={EventCard.clickToOpenAnnouncements}
          id="alerts-btn"
        >
          <img
            src={bell}
            alt={EventCard.openAnnouncements}
            title={EventCard.openAnnouncements}
            onLoad={() => setImg4Loaded(true)}
            style={{ padding: '8px' }}
            className="filter-white"
          />
        </button>
      </div>
    );
  } else if (
    settingsMyEventsView !== 'archived' ||
    settingsEventSearchView !== 'myevents'
  ) {
    cardActions = (
      <div
        className={`actions-overlay  ${
          overlayDisplay ? 'opacity-1 ' : 'opacity-0'
        }`}
      >
        <button
          type="button"
          className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
            tapHighlightSave
              ? 'highlight-bg-theme-light--tap click-scaledown-90'
              : ''
          }`}
          style={img1Loaded ? { display: 'flex' } : { display: 'none' }}
          onClick={() => handleActionOverlayClick('save')}
          tabIndex={overlayDisplay ? '0' : '-1'}
          aria-pressed={eventStatus === 'saved'}
          id="save-btn"
        >
          <img
            src={star}
            alt={EventCard.saveButton}
            id="status-save"
            title={
              eventStatus === 'saved'
                ? `${EventCard.clickToUnsave}`
                : `${EventCard.clickToSave}`
            }
            onLoad={() => setImg1Loaded(true)}
            style={{ padding: '8px' }}
            className="filter-white"
          />
        </button>

        <button
          type="button"
          className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
            tapHighlightIgnore
              ? 'highlight-bg-theme-light--tap click-scaledown-90'
              : ''
          }`}
          style={img2Loaded ? { display: 'flex' } : { display: 'none' }}
          tabIndex={overlayDisplay ? '0' : '-1'}
          onClick={() => handleActionOverlayClick('ignore')}
          aria-pressed={eventStatus === 'ignored'}
          id="ignore-btn"
        >
          <img
            src={view_ignore}
            alt={EventCard.ignoreButton}
            id="status-ignore"
            title={
              eventStatus === 'ignored'
                ? `${EventCard.clickToUnignore}`
                : `${EventCard.clickToIgnore}`
            }
            onLoad={() => setImg2Loaded(true)}
            style={{ padding: '8px' }}
            className="filter-white"
          />
        </button>
      </div>
    );
  } else {
    //card overlay for archived events
    cardActions = (
      <div
        className={`actions-overlay  ${
          overlayDisplay ? 'opacity-1' : 'opacity-0'
        }`}
      >
        <button
          type="button"
          className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
            tapHighlightRemoveArchived
              ? 'highlight-bg-theme-light--tap highlight-click-scaledown-90'
              : ''
          }`}
          style={img3Loaded ? { display: 'flex' } : { display: 'none' }}
          onClick={() => handleActionOverlayClick('removeArchived')}
          tabIndex={overlayDisplay ? '0' : '-1'}
          id="removeArchived-btn"
        >
          <img
            src={trash}
            alt={EventCard.removeFromArchive}
            id="removeArchived"
            title={EventCard.removeFromArchive}
            onLoad={() => setImg3Loaded(true)}
            style={{ padding: '12px' }}
            className="filter-white"
          />
        </button>
      </div>
    );
  }

  return (
    <>
      {(!eventData || !imageLoadConfirmation) && <EventCardSkeleton />}
      <article
        className={`ec__wrapper no-select access-ob access-o6 ${
          tapCardScaleDown ? 'click-scaledown-98' : ''
        } ${!imageLoadConfirmation ? 'remove' : ''}`}
        tabIndex="0"
        id={`${eventData.meta.eventReference}`}
        onFocus={(e) => {
          e.stopPropagation();
          handleOverlayClick();
        }}
        onMouseLeave={() => setOverlayDisplay(false)}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Event Image */}
        <div
          className="ec__image-container access-ob"
          onClick={(e) => setOverlayDisplay(true)}
          tabIndex="0"
          ref={imageContainerRef}
          onBlur={handleBlur} //problem area for overlay UI
        >
          <div className="ec__click-overlay" />
          <LoadBucketImage
            imagePath={eventData.generic.eventImagePath}
            imgClassName="ec-display-image"
            backupPath={'event_img_default.png'}
            partialPath={'myndfull_event_images'}
            setImageLoadConfirmation={setImageLoadConfirmation}
          />
          {cardActions}
        </div>

        <div
          className="ec__info-container access-ob"
          tabIndex="0"
          onMouseDown={() => setTapCardScaleDown(true)}
          onMouseUp={() => setTapCardScaleDown(false)}
          onMouseLeave={() => setTapCardScaleDown(false)}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapCardScaleDown(true),
                () => setTapCardScaleDown(false),
              ],
              [() => setViewEvent(eventData)],
              true
            )
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleMobileTap(
                [
                  () => setTapCardScaleDown(true),
                  () => setTapCardScaleDown(false),
                ],
                [() => setViewEvent(eventData)],
                true
              );
            }
          }}
        >
          {/* Event Title */}
          <div className="ec__header-section">
            <h2
              className={`${
                eventData.generic.title?.length <= 30 ? 'clamp-1' : 'clamp-2'
              }`}
            >
              {eventData.generic.title}
            </h2>
            <div className="dividing-line" />
          </div>
          <div className="flex-column min-full-width pad-lr14 fs16 fwsb fs14--phn mrg-t12">
            <div className="ec__row mrg-b12">
              <img
                src={calendar}
                alt={EventCard.calendarIcon}
                className="icon-container calendar"
              />
              <div className="ec__row-info ">
                {width > 480 ? (
                  <p>
                    {getDateLongMonth(
                      eventData.dateTime.date,
                      accountContentLanguage
                    )}
                  </p>
                ) : (
                  <p>
                    {getDateShortMonth(
                      eventData.dateTime.date,
                      accountContentLanguage
                    )}
                  </p>
                )}
                <span className="text-right">
                  {GetDateTimeSummary(eventData.dateTime, accountTimeZone)}
                </span>
              </div>
            </div>

            <div className="ec__row mrg-b12">
              {/*hostingOption icon */}
              {eventData.hosting.hostingOption === 'inPerson' && (
                <img
                  src={building2}
                  alt={EventCard.locationIcon}
                  className="icon-container pad-3"
                />
              )}
              {eventData.hosting.hostingOption === 'online' && (
                <img
                  src={globe2}
                  alt={EventCard.locationIcon}
                  className="icon-container filter-theme"
                />
              )}
              {eventData.hosting.hostingOption === 'hybrid' && (
                <img
                  src={hybrid2}
                  alt={EventCard.locationIcon}
                  className="icon-container"
                />
              )}
              <div className="ec__row-info address-content">
                <p>
                  <AddressContent
                    eventData={eventData}
                    accountRegistrationsInPerson={accountRegistrationsInPerson}
                    accountRegistrationsVirtual={accountRegistrationsVirtual}
                  />
                </p>
              </div>
            </div>

            <div className="ec__row mrg-b12">
              <img
                src={organize}
                alt={EventCard.organizerIcon}
                className="icon-container filter-theme"
              />
              <div className="ec__row-info">
                <div className="flex-column">
                  <p>
                    {width > 480
                      ? temporaryOrg.substring(0, 54)
                      : temporaryOrg.substring(0, 32)}
                    {width <= 480 && temporaryOrg?.length > 54 && '...'}
                    {width > 480 && temporaryOrg?.length > 32 && '...'}
                  </p>
                </div>
              </div>
            </div>
            <div className="ec__row-footer">
              <div className="info-icons-row">
                {eventData.generic.wheelchairAccessibility && (
                  <img
                    className="mrg-r6"
                    alt={EventCard.wheelchairAccessibility}
                    title={EventCard.wheelchairAccessibility}
                    src={eventcard_accessibility}
                  />
                )}

                {(accountFollowing.some(
                  (account) =>
                    account._id === eventData.organizers.organizerId[0]
                ) ||
                  accountFollowingGroups?.some((group) =>
                    group.following.some(
                      (following) =>
                        following._id === eventData.organizers.organizerId[0]
                    )
                  )) && (
                  <img
                    className="mrg-r6 filter-green-light"
                    alt={EventCard.followingSpeaker}
                    title={EventCard.followingSpeaker}
                    src={eventcard_follow}
                  />
                )}
                {eventStatus === 'saved' && (
                  <img
                    className="mrg-r6"
                    alt={EventCard.savedEvent}
                    title={EventCard.savedEvent}
                    src={eventcard_star}
                  />
                )}
                {eventStatus === 'ignored' && (
                  <img
                    className="mrg-r6"
                    alt={EventCard.ignoredEvent}
                    title={EventCard.ignoredEvent}
                    src={eventcard_ignore}
                  />
                )}
              </div>
              <div className="flex-center flex-row">
                {eventStatus !== 'pendingApprovalInPerson' &&
                  eventStatus !== 'registeredInPerson' &&
                  eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
                  eventStatus !==
                    'pendingApprovalVirtual-registeredInPerson' && (
                    <EventStatusBar
                      eventData={eventData}
                      virtual={
                        eventData?.hosting?.hostingOption === 'hybrid' ||
                        eventData?.hosting?.hostingOption === 'online'
                      }
                    />
                  )}

                {eventStatus !== 'pendingApprovalVirtual' &&
                  eventStatus !== 'registeredVirtual' &&
                  eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
                  eventStatus !==
                    'pendingApprovalVirtual-registeredInPerson' && (
                    <EventStatusBar
                      eventData={eventData}
                      inPerson={
                        eventData?.hosting?.hostingOption === 'hybrid' ||
                        eventData?.hosting?.hostingOption === 'inPerson'
                      }
                    />
                  )}

                {eventStatus ===
                  'pendingApprovalInPerson-registeredVirtual' && (
                  <EventStatusBarSwitch />
                )}
                {eventStatus ===
                  'pendingApprovalVirtual-registeredInPerson' && (
                  <EventStatusBarSwitch inPersonToVirtual={true} />
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default EventCard;

// <p className="no-wrap">
// {distance > 500 ? '500+' : distance}&nbsp;
// {eventData.hosting.inPerson.location?.city &&
//   distance &&
//   accountDistanceMeasurement}
// </p>
