import useSettings from '../../context/useSettings';

const useLanguageRegistration = () => {
  const { contentLanguage } = useSettings();

  const AccessCode = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        heading: 'Access Code',
        instructions: 'Please provide your pre-launch access code.',
        noAccessCode: `Don't have an access code?`,
        character1: 'Character 1 of 6.',
        character2: 'Character 2 of 6.',
        character3: 'Character 3 of 6.',
        character4: 'Character 4 of 6.',
        character5: 'Character 5 of 6.',
        character6: 'Character 6 of 6.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        heading: `Code d'accès`,
        instructions: `Veuillez fournir votre code d'accès de pré-lancement.`,
        noAccessCode: `Vous n'avez pas de code d'accès ?`,
        character1: 'Caractère 1 sur 6.',
        character2: 'Caractère 2 sur 6.',
        character3: 'Caractère 3 sur 6.',
        character4: 'Caractère 4 sur 6.',
        character5: 'Caractère 5 sur 6.',
        character6: 'Caractère 6 sur 6.',
      };
    }

    return translation;
  };

  const AccountRegistrationType = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        heading: 'Select your New Account',
        personalAccountType: {
          title: 'Personal',
          persoanlDescription: '',
          exploreBtn: 'Explore Personal Accounts',
          legend: {
            personal:
              'Personal Account includes features for finding events, becoming a speaker, and organizing single-day events.',
            explorePersonalAccounts:
              'Open a new tab to explore more features for Personal Accounts. ',
            personalAccountSelected:
              'Personal Account selected. Please press the continue button below to proceed with your registration.',
          },
        },
        institutionalAccountType: {
          title: 'Institutional',
          exploreBtn: 'Explore Institutional Accounts',
        },
        continueBtn: 'Continue',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        heading: 'Veuillez sélectionner votre nouveau compte',
        personalAccountType: {
          title: 'Personnel',
          exploreBtn: 'Explorer les comptes personnels',
        },
        institutionalAccountType: {
          title: 'Institutionnel',
          exploreBtn: 'Explorer les comptes institutionnels',
          legend: {
            exploreInstitutionalAccounts:
              'Ouvrez un nouvel onglet pour explorer plus de fonctionnalités pour les comptes institutionnels.',
          },
        },
        continueBtn: 'Continuer',
      };
    }

    return translation;
  };

  const InitializeRegistrationPersonal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        heading: 'Select account features',
        heading2:
          'Features can be added, removed or adjusted after registration.',
        translationRoomCodes: 'Host Translation Rooms',
        translationRoomCodesDescription1:
          'Reserve room codes for hosting translation rooms.',
        attendEvents: 'Attend Events',
        attendEventsDescription1:
          'Find, register and attend eductional events.',
        attendEventsDescription2: `Identify broad interests for browsing events and specific interests for generating a curated event list.`,
        attendEventsDescription3:
          'Search events by proximity and online location.',
        speakerEvents: 'Speak at Events',
        speakerEventsDescription1:
          'Create a speaker profile promoting your skills and expertise.',
        speakerEventsDescription2:
          'Allow event organizers to offer you speaking opportunities.',
        organizeEvents: 'Organize Events',
        organizeEventsDescription1: 'Create and manage educational events.',
        organizeEventsDescription2: `Access MyndFull's speaker database to find speakers.`,
        continueBtn: 'Continue',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        heading: 'Sélectionnez les fonctionnalités du compte',
        heading2: `Des fonctionnalités peuvent être ajoutées, supprimées ou ajustées après l'enregistrement.`,
        translationRoomCodes: 'Héberger des salles de traduction',
        translationRoomCodesDescription1:
          'Réservez des codes de salle pour héberger des salles de traduction.',
        attendEvents: 'Participer à des événements',
        attendEventsDescription1:
          'Trouvez, inscrivez-vous et assistez à des événements éducatifs.',
        attendEventsDescription2: `Identifiez les intérêts généraux pour parcourir les événements et les intérêts spécifiques pour générer une liste d'événements organisée.`,
        attendEventsDescription3:
          'Recherchez des événements par proximité et emplacement en ligne.',
        speakerEvents: `Prendre la parole lors d'événements`,
        speakerEventsDescription1:
          'Créez un profil de conférencier faisant la promotion de vos compétences et de votre expertise.',
        speakerEventsDescription2: `Permettez aux organisateurs d'événements de vous offrir des opportunités de prise de parole.`,
        organizeEvents: 'Organiser des événements',
        organizeEventsDescription1:
          'Créer et gérer des événements pédagogiques.',
        organizeEventsDescription2: `Accédez à la base de données des conférenciers de MyndFull pour trouver des conférenciers.`,
        continueBtn: 'Continuer',
      };
    }

    return translation;
  };

  const InitializeRegistrationInstitutional = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        heading: 'Select account features',
        heading2:
          'Features can be added, removed or adjusted after registration.',
        translationRoomCodes: 'Translation Room Codes',
        attendEvents: 'Attend Events (Coming soon)',
        speakerEvents: 'Speak at Events (Coming soon)',
        organizeEvents: 'Organize Events (Coming soon)',
        continueBtn: 'Continue',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        heading: 'Sélectionnez les fonctionnalités du compte',
        heading2: `Des fonctionnalités peuvent être ajoutées, supprimées ou ajustées après l'enregistrement.`,
        translationRoomCodes: 'Codes des salles de traduction',
        attendEvents: 'Participer à des événements (à venir)',
        speakerEvents: `Prendre la parole lors d'événements (à venir)`,
        organizeEvents: 'Organiser des événements (à venir)',
        continueBtn: 'Continuer',
      };
    }

    return translation;
  };

  const PersonalAccountInfo = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        accountLabel: 'Account',
        nameLabel: 'Name',
        pronouns: 'Pronouns',
        prefix: 'Prefix',
        firstName: 'First Name',
        lastName: 'Last Name',
        contactLabel: 'Contact',
        appSettingsLabel: 'App Settings',
        profilePictureLabel: 'Profile Picture',
        appLanguage: 'App Language',
        ariaAccount: 'Form sub-section: Account information.',
        ariaName: 'Form sub-section: Account name.',
        ariaContact: 'Form sub-section: Contact.',
        ariaAppSettings: 'Form sub-section: App Settings.',
        ariaProfilePicture: 'Form sub-section: Profile Picture.',
        ariaImageCropperDescription: 'Profile picture.',
        requiredFieldsIncomplete: 'Incomplete required fields:',
        emailAddress: 'Email address',
        password: 'Password',
        timeZone: 'Time Zone',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        accountLabel: 'Compte',
        nameLabel: 'Nom',
        pronouns: 'Pronoms',
        prefix: 'Préfixe',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        contactLabel: 'Contact',
        appSettingsLabel: `Paramètres de l'application`,
        profilePictureLabel: 'Photo de profil',
        appLanguage: `Langue de l'application`,
        ariaAccount: 'Sous-section du formulaire: Information sur le compte.',
        ariaName: 'Sous-section du formulaire: Nom du compte.',
        ariaContact: 'Sous-section du formulaire: Contact.',
        ariaAppSettings: `Sous-section du formulaire: Paramètres de l'application.`,
        ariaProfilePicture: 'Sous-section du formulaire: Image de profil.',
        ariaImageCropperDescription: 'Image de profil.',
        requiredFieldsIncomplete: 'Champs obligatoires incomplets:',
        emailAddress: 'Adresse email',
        password: 'Mot de passe',
        timeZone: 'Fuseau horaire',
      };
    }

    return translation;
  };

  const PersonalSearchSettings = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        bigTagsLabel: 'Big Tags',
        bigTagsDescription:
          'Choose scholastic fields / disciplines to find corresponding events.',
        tinyTagsLabel: 'Tiny Tags',
        tinyTagsDescription: `Create tags that match your specific interests. These will be used for populating a list of curated events with matching tags. Only use letters and numbers. At least one tag is required.`,
        eventSearchInPerson: 'Event Search (In Person)',
        eventSearchInPersonDescription:
          'In-Person event searches will be populated according to your search location radius.',
        eventSearchOnlineLabel: 'Event Search (Online)',
        eventSearchOnlineDescription:
          'Limit your online event searches by adding one or more countries.',
        searchLanguagesLabel: 'Search Languages',
        searchLangaugesDescription:
          'Events must include at least one of your selected search languages.',
        country: 'Country',
        languageBtn: 'Language',
        ariaBigTags: 'Form sub-section: Big Tags. Required field.',
        ariaBigTagsInputInstructions:
          'Choose scholastic fields / disciplines to find corresponding events. For the best accessibility, use the See Full List button located below the input field to directly add big tags from a list. Alternatively, use this autocomplete field to find tags from the Big Tags list. Use the arrow up and arrow down keys to scroll through the generated list. Press enter to add the highlighted tag or backspace to remove characters from the input field.',
        ariaTinyTags: 'Form sub-section: Tiny Tags. Required field.',
        ariaEventSearchInPerson:
          'Form sub-section: Event Search (In Person). Required field.',
        ariaEventSearchOnline: 'Form sub-section: Event Search (Online).',
        ariaSearchLanguages:
          'Form sub-section: Search Languages. Required field.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        bigTagsLabel: 'Grand Balises',
        bigTagsDescription: `Sélectionnez les domaines/disciplines scolaires pour trouver les événements correspondant.`,
        tinyTagsLabel: 'Petit Balises',
        tinyTagsDescription: `Créez des balise qui correspondent à vos intérêts spécifiques. Ceux-ci seront utilisés pour remplir une liste d'événements organisés avec des balise correspondantes. Utilisez uniquement des lettres et des chiffres. Au moins une étiquette est nécessaire.`,
        eventSearchInPerson: `Recherche d'événement (en personne)`,
        eventSearchInPersonDescription: `Les recherches d'événements en personne seront remplies en fonction du rayon de votre lieu de recherche.`,
        eventSearchOnlineLabel: `Recherche d'événement (en ligne)`,
        eventSearchOnlineDescription: `Limitez vos recherches d'événements en ligne en ajoutant un ou plusieurs pays.`,
        searchLanguagesLabel: 'Langues de recherche',
        searchLangaugesDescription:
          'Les événements doivent inclure au moins une des langues de recherche que vous avez sélectionnées.',
        country: 'un pays',
        languageBtn: 'une langue',
        ariaBigTags:
          'Sous-section du formulaire: Grand Balises. Champs requis.',
        ariaBigTagsInputInstructions: `Choisissez les domaines scolaires / disciplines pour trouver les événements correspondants. Pour une meilleure accessibilité, utilisez le bouton Voir la liste complète situé sous le champ de saisie pour ajouter directement de grandes balises à partir d'une liste. Vous pouvez également utiliser ce champ de saisie semi-automatique pour rechercher des balises dans la liste Grande Tags. Community Verified icon Utilisez les flèches vers le haut et vers le bas pour faire défiler la liste générée. Appuyez sur Entrée pour ajouter la balise en surbrillance ou sur Retour arrière pour supprimer des caractères du champ de saisie.`,
        ariaTinyTags:
          'Sous-section du formulaire: Petit Balises. Champs requis.',
        ariaEventSearchInPerson: `Sous-section du formulaire: Recherche d'événement (en personne). Champs requis.`,
        ariaEventSearchOnline: `Sous-section du formulaire: Recherche d'événement (en ligne).`,
        ariaSearchLanguages:
          'Sous-section du formulaire: Langues de recherche. Champs requis.',
      };
    }

    return translation;
  };

  const PersonalAccountCompletionPage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        heading: 'Congratulations',
        part1: 'Your Account is created!',
        part2:
          'To complete your registration, we sent a link to verify your email,',
        part3: 'Please click the link before its 24 hour expiry.',
        resendEmail: 'Resend Email',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        heading: 'Toutes nos félicitations',
        part1: `Votre compte est créé !`,
        part2: `Pour finaliser votre inscription, nous avons envoyé un lien pour vérifier votre email,`,
        part3: `Veuillez cliquer sur le lien avant son expiration de 24 heures.`,
        resendEmail: `Ré-envoyer l'email`,
      };
    }

    return translation;
  };

  const TranslationRoomCodeRegistration = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        heading: 'Translation Room Codes',
        roomCode: 'Room Code',
        addRoomCode: 'Add Room Code',
        continueBtn: 'Continue',
        learnMore: 'Learn More',
        roomCodeList: 'Room Code List',
        description1:
          'Add Room Codes to your account for hosting translation rooms. Attendees use this room code to join your room and receive text and audio translations in real-time.',
        description2: 'Add or remove room codes anytime.',

        description3: `Room code monthly subscription: $${process.env.REACT_APP_TRANSLATION_ROOM_PRICE_IN_CAD_FORMATTED} / month.`,
        description4: 'Transcription usage charge: $0.01 / word.',
        description5:
          'Add custom passwords to your room codes after registration to share hosting access.',
        validCharLength: 'Between 4 and 12 characters.',
        validCharTypes: 'Only letters, numbers, and hyphens.',
        ariaValidLengthSuccess:
          'Success. Your room code has the correct character types.',
        ariaValidLengthFail:
          'Warning. Your room code does not have the correct character types. Include only letters, numbers and hyphens.',
        ariaCharsSuccess:
          'Success. Your room code has the correct number of characters.',
        ariaCharsFail:
          'Warning. Your room code does not have the correct number of characters.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        heading: 'Sélectionnez les fonctionnalités du compte',
        roomCode: 'Code de la salle',
        addRoomCode: 'Ajouter un code de la salle',
        continueBtn: 'Continuer',
        learnMore: 'Apprendre encore plus',
        roomCodeList: 'Liste des codes de la salle',
        description1:
          'Réservez des codes de salle pour héberger des salles de traduction.',
        description2:
          'Ajoutez ou supprimez des codes de la salle à tout moment.',
        description3: 'Abonnement mensuel avec code de la salle : 7,99 $/mois.',
        description4: `Frais d'utilisation de la transcription : 0,01 $/mot.`,
        description5: `Ajoutez des mots de passe personnalisés à vos codes de la salle après l'inscription pour partager l'accès à l'hébergement.`,
        validCharLength: 'Entre 4 et 12 caractères.',
        validCharTypes: 'Uniquement des lettres, des chiffres et des tirets.',
        ariaValidLengthSuccess:
          'Succès. Le code de votre salle comporte les types de caractères corrects.',
        ariaValidLengthFail:
          'Avertissement. Le code de votre salle ne contient pas les types de caractères corrects. Incluez uniquement des lettres, des chiffres et des tirets.',
        ariaCharsSuccess:
          'Succès. Votre code de la salle comporte le nombre correct de caractères.',
        ariaCharsFail:
          'Avertissement. Le code de votre salle ne comporte pas le nombre correct de caractères.',
      };
    }

    return translation;
  };
  const CompletionTracker = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        account: 'Account',
        billing: 'Billing',
        checkmark: 'Checkmark',
        emailPreferences: 'Email Preferences',
        eventSearches: 'Event Searches',
        translationRoom: 'Translation Room',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        account: 'Compte',
        billing: 'Facturation',
        checkmark: 'Coche',
        emailPreferences: 'Préférences de courrier électronique',
        eventSearches: ` recherche d'événements`,
        translationRoom: 'Salle de traduction',
      };
    }

    return translation;
  };

  const PersonalBilling = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        total: 'Total',
        month: 'Month',
        roomCodeSubscriptions: 'Room Code Subscriptions',
        paymentsSecuredByStripe: 'Payments secured by Stripe',
        cart: 'cart',
        subTotal: 'Subtotal',
        requiresAddress: 'Requires address',
        monthlySubscriptionTotal: 'Monthly Subscription Total',
        taxes: 'Taxes',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        total: 'Total',
        subscriptionTotal: `Total de l'abonnement`,
        month: 'Mois',
        roomCodeSubscriptions: 'Abonnements pour codes de salle',
        paymentsSecuredByStripe: 'Paiements sécurisés par Stripe',
        cart: 'panier',
        subTotal: 'Sous-total',
        requiresAddress: 'Nécessite une adresse',
        monthlySubscriptionTotal: `Total de l'abonnement mensuel`,
        taxes: 'Taxes',
      };
    }

    return translation;
  };

  const PaymentCard = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        creditCardInformation: 'Credit Card Information',
        billingAddress: 'Billing Address',
        billingConfirmed: 'Billing Confirmed',
        verifyBilling: 'Verify Billing',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        creditCardInformation: 'Informations sur la carte de crédit',
        billingAddress: 'Adresse de facturation',
        billingConfirmed: 'Facturation confirmée',
        verifyBilling: 'Vérifier la facturation',
      };
    }

    return translation;
  };

  const EmailPreferences = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        emailPreferences: 'Email Preferences',
        emailPreferencesDescription:
          'We respect your mailbox and only want to provide you with the mail you want.',
        myndfullNewsletter: 'MyndFull Newsletter',
        myndfullNewsletterDescription:
          'Stay up-to-date on our major developments.',
        promotions: 'Promotions',
        promotionsDescription: 'Receive announcements of special offers.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        emailPreferences: 'Préférences de messagerie',
        emailPreferencesDescription:
          'Nous respectons votre boîte mail et souhaitons uniquement vous fournir le courrier que vous souhaitez.',
        myndfullNewsletter: 'Bulletin MyndFull',
        myndfullNewsletterDescription:
          'Restez au courant de nos développements majeurs.',
        promotions: 'Promotions',
        promotionsDescription: `Recevez des annonces d'offres spéciales.`,
      };
    }

    return translation;
  };

  return {
    AccessCode: AccessCode(),
    AccountRegistrationType: AccountRegistrationType(),
    PersonalAccountInfo: PersonalAccountInfo(),
    PersonalSearchSettings: PersonalSearchSettings(),
    PersonalAccountCompletionPage: PersonalAccountCompletionPage(),
    InitializeRegistrationPersonal: InitializeRegistrationPersonal(),
    InitializeRegistrationInstitutional: InitializeRegistrationInstitutional(),
    TranslationRoomCodeRegistration: TranslationRoomCodeRegistration(),
    CompletionTracker: CompletionTracker(),
    PersonalBilling: PersonalBilling(),
    PaymentCard: PaymentCard(),
    EmailPreferences: EmailPreferences(),
  };
};

export default useLanguageRegistration;
