import { useSelector, useDispatch } from 'react-redux';
import {
  emptyAccountData,
  selectAccount,
  selectAccountAppSettings,
  selectAccountMeta,
  selectAccountNetwork,
  selectAccountPersonal,
  selectAccountSpeaker,
  selectAccountSearches,
  selectAccountTags,
  selectAccountReference,
  selectArchivedEvents,
  selectAffiliations,
  selectBigTags,
  selectContentLanguage,
  selectCountryCode,
  selectDistanceFilter,
  selectDistanceMeasurement,
  selectEmail,
  selectEventSearchOnlineCountries,
  selectFollowing,
  selectFollowingGroups,
  selectFollowedBy,
  selectFirstName,
  selectGeoPosition,
  selectGeoPositionCoordinates,
  selectId,
  selectIgnoredEvents,
  selectLastName,
  selectMyEventsAll,
  selectOrganizedEvents,
  selectPendingApprovalInPerson,
  selectPendingApprovalVirtual,
  selectPhoneNumber,
  selectPrefix,
  selectProfileImage,
  selectPronouns,
  selectRegistrationsInPerson,
  selectRegistrationsVirtual,
  selectSavedEvents,
  selectSearchLanguages,
  selectSoundEffects,
  selectLinkFacebook,
  selectLinkInstagram,
  selectLinkLinkedIn,
  selectLinkPersonalWebsite,
  selectLinkX,
  selectTimeZone,
  selectTinyTags,
  selectTinyTagGroups,
  setArchivedEvents,
  setBigTags,
  setContentLanguage,
  setCountryCode,
  setDistanceFilter,
  setDistanceMeasurement,
  setEmail,
  setEventSearchOnlineCountries,
  setFollowing,
  setFollowingGroups,
  setFollowedBy,
  setFirstName,
  setIgnoredEvents,
  setLastName,
  setNetwork,
  setOrganizedEvents,
  setPendingApprovalInPerson,
  setPendingApprovalVirtual,
  setPhoneNumber,
  setPrefix,
  setProfileImage,
  setPronouns,
  setRegistrationsInPerson,
  setRegistrationsVirtual,
  setSavedEvents,
  setSearchLanguages,
  setSoundEffects,
  setLinkFacebook,
  setLinkInstagram,
  setLinkLinkedIn,
  setLinkPersonalWebsite,
  setLinkX,
  setTimeZone,
  setTinyTags,
  setTinyTagGroups,
  selectCustomerId,
  selectPaymentMethodIds,
  selectRoomCodeSubscriptions,
  selectTranslationRoomsRoomCodes,
  setCustomerId,
  setPaymentMethodIds,
  setRoomCodeSubscriptions,
  setTranslationRoomsRoomCodes,
  setEmailPreferencesMyndFullNewsletter,
  setEmailPreferencesPromotions,
  selectEmailPreferencesMyndFullNewsletter,
  selectEmailPreferencesPromotions,
  setTranslationRoomsRoomCode,
  selectAccountBilling,
  selectMyInvoices,
  setMyInvoices,
  setFailedInvoices,
  selectFailedInvoices,
} from './accountSlice';
import {
  useLazyRetrieveAccountCustomerDetailsQuery,
  useUpdateCustomerMutation,
} from './accountApiSlice';
import useApiStatus from '../../../context/useApiStatus';

export const useAccountReduxHandlers = () => {
  //hooks
  const dispatch = useDispatch();
  const { handleError } = useApiStatus();
  //API
  const [
    updateCustomer,
    {
      isLoading: isLoadingUpdateCustomer,
      isFetching: isFetchingUpdateCustomer,
    },
  ] = useUpdateCustomerMutation();
  const [getCustomerDetails] = useLazyRetrieveAccountCustomerDetailsQuery();

  //Categories
  const accountId = useSelector(selectId);
  const account = useSelector(selectAccount);
  const accountAppSettings = useSelector(selectAccountAppSettings);
  const accountPersonal = useSelector(selectAccountPersonal);
  const accountBilling = useSelector(selectAccountBilling);
  const accountMeta = useSelector(selectAccountMeta);
  const accountSpeaker = useSelector(selectAccountSpeaker);
  const accountSearches = useSelector(selectAccountSearches);
  const accountTags = useSelector(selectAccountTags);
  const accountNetwork = useSelector(selectAccountNetwork);

  //App Settings
  const accountContentLanguage = useSelector(selectContentLanguage);
  const accountTimeZone = useSelector(selectTimeZone);
  const accountSoundEffects = useSelector(selectSoundEffects);

  ///Account Events
  const accountArchivedEvents = useSelector(selectArchivedEvents);
  const accountMyEventsAll = useSelector(selectMyEventsAll);
  const accountOrganizedEvents = useSelector(selectOrganizedEvents);
  const accountPendingApprovalInPerson = useSelector(
    selectPendingApprovalInPerson
  );
  const accountPendingApprovalVirtual = useSelector(
    selectPendingApprovalVirtual
  );
  const accountRegistrationsInPerson = useSelector(selectRegistrationsInPerson);
  const accountRegistrationsVirtual = useSelector(selectRegistrationsVirtual);
  const accountSavedEvents = useSelector(selectSavedEvents);

  //Affiliations
  const accountAffiliations = useSelector(selectAffiliations);

  //Billing
  const accountBillingCustomerId = useSelector(selectCustomerId);
  const accountBillingPaymentMethodIds = useSelector(selectPaymentMethodIds);
  const accountBillingRoomCodeSubscriptions = useSelector(
    selectRoomCodeSubscriptions
  );
  const accountBillingMyInvoices = useSelector(selectMyInvoices);
  const accountFailedInvoices = useSelector(selectFailedInvoices);

  //Email Preferences
  const accountEmailPreferencesMyndFullNewsletter = useSelector(
    selectEmailPreferencesMyndFullNewsletter
  );
  const accountEmailPreferencesPromotions = useSelector(
    selectEmailPreferencesPromotions
  );

  //Meta
  const accountAccountReference = useSelector(selectAccountReference);
  const accountIgnoredEvents = useSelector(selectIgnoredEvents);

  //Network
  const accountFollowing = useSelector(selectFollowing);
  const accountFollowingGroups = useSelector(selectFollowingGroups);
  const accountFollowedBy = useSelector(selectFollowedBy);

  //Personal
  const accountCountryCode = useSelector(selectCountryCode);
  const accountEmail = useSelector(selectEmail);
  const accountFirstName = useSelector(selectFirstName);
  const accountLastName = useSelector(selectLastName);
  const accountLinkFacebook = useSelector(selectLinkFacebook);
  const accountLinkInstagram = useSelector(selectLinkInstagram);
  const accountLinkLinkedIn = useSelector(selectLinkLinkedIn);
  const accountLinkPersonalWebsite = useSelector(selectLinkPersonalWebsite);
  const accountLinkX = useSelector(selectLinkX);
  const accountPhoneNumber = useSelector(selectPhoneNumber);
  const accountPrefix = useSelector(selectPrefix);
  const accountProfileImage = useSelector(selectProfileImage);
  const accountPronouns = useSelector(selectPronouns);

  //Speaker

  //Searches
  const accountGeoPosition = useSelector(selectGeoPosition);
  const accountGeoPositionCoordinates = useSelector(
    selectGeoPositionCoordinates
  );
  const accountDistanceFilter = useSelector(selectDistanceFilter);
  const accountDistanceMeasurement = useSelector(selectDistanceMeasurement);
  const accountEventSearchOnlineCountries = useSelector(
    selectEventSearchOnlineCountries
  );
  const accountSearchLanguages = useSelector(selectSearchLanguages);

  //Tags
  const accountBigTags = useSelector(selectBigTags);
  const accountTinyTags = useSelector(selectTinyTags);
  const accountTinyTagGroups = useSelector(selectTinyTagGroups);

  //Translation Rooms
  const accountTranslationRoomsRoomCodes = useSelector(
    selectTranslationRoomsRoomCodes
  );

  //Handler Functions
  //General
  function handleAccountEmptyAccountData() {
    dispatch(emptyAccountData());
  }

  //App Settings
  function handleAccountSetContentLanguage(str) {
    dispatch(setContentLanguage(str));
  }
  function handleAccountSetSoundEffects(boolean) {
    dispatch(setSoundEffects(boolean));
  }
  function handleAccountSetTimeZone(str) {
    dispatch(setTimeZone(str));
  }

  //Billing
  function handleAccountSetBillingCustomerId(str) {
    dispatch(setCustomerId(str));
  }
  function handleAccountSetMyInvoices(arr) {
    dispatch(setMyInvoices(arr));
  }
  function handleAccountSetBillingPaymentMethodIds(arr) {
    dispatch(setPaymentMethodIds(arr));
  }
  function handleAccountSetBillingRoomCodeSubscriptions(arr) {
    dispatch(setRoomCodeSubscriptions(arr));
  }
  function handleAccountSetFailedInvoices(arr) {
    dispatch(setFailedInvoices(arr));
  }

  //Account Events
  function handleAccountSetArchivedEvents(arr) {
    dispatch(setArchivedEvents(arr));
  }
  function handleAccountSetIgnoredEvents(arr) {
    dispatch(setIgnoredEvents(arr));
  }
  function handleAccountSetOrganizedEvents(arr) {
    dispatch(setOrganizedEvents(arr));
  }
  function handleAccountSetPendingApprovalInPerson(arr) {
    dispatch(setPendingApprovalInPerson(arr));
  }
  function handleAccountSetPendingApprovalVirtual(arr) {
    dispatch(setPendingApprovalVirtual(arr));
  }
  function handleAccountRegistrationsInPerson(arr) {
    dispatch(setRegistrationsInPerson(arr));
  }
  function handleAccountRegistrationsVirtual(arr) {
    dispatch(setRegistrationsVirtual(arr));
  }
  function handleAccountSetSavedEvents(arr) {
    dispatch(setSavedEvents(arr));
  }

  //Email Preferences
  function handleAccountSetEmailPreferencesMyndFullNewsletter(boolean) {
    dispatch(setEmailPreferencesMyndFullNewsletter(boolean));
  }
  function handleAccountSetEmailPreferencesPromotions(boolean) {
    dispatch(setEmailPreferencesPromotions(boolean));
  }

  //Meta

  //Network
  function handleAccountSetNetwork(obj) {
    dispatch(setFollowing(obj));
  }
  function handleAccountSetFollowing(arr) {
    dispatch(setFollowing(arr));
  }
  function handleAccountSetFollowingGroups(arr) {
    dispatch(setFollowingGroups(arr));
  }
  function handleAccountSetFollowedBy(arr) {
    dispatch(setFollowedBy(arr));
  }

  //Personal
  function handleAccountSetCountryCode(str) {
    dispatch(setCountryCode(str));
  }
  function handleAccountSetFirstName(str) {
    dispatch(setFirstName(str));
  }
  function handleAccountSetLastName(str) {
    dispatch(setLastName(str));
  }
  function handleAccountSetPrefix(str) {
    dispatch(setPrefix(str));
  }
  function handleAccountSetProfileImage(str) {
    dispatch(setProfileImage(str));
  }
  function handleAccountSetPronouns(str) {
    dispatch(setPronouns(str));
  }
  function handleAccountSetPhoneNumber(num) {
    dispatch(setPhoneNumber(num));
  }
  function handleAccountSetEmail(str) {
    dispatch(setEmail(str));
  }
  function handleAccountSetLinkFacebook(str) {
    dispatch(setLinkFacebook(str));
  }
  function handleAccountSetLinkInstagram(str) {
    dispatch(setLinkInstagram(str));
  }
  function handleAccountSetLinkLinkedIn(str) {
    dispatch(setLinkLinkedIn(str));
  }
  function handleAccountSetLinkPersonalWebsite(str) {
    dispatch(setLinkPersonalWebsite(str));
  }
  function handleAccountSetLinkX(str) {
    dispatch(setLinkX(str));
  }

  //Speaker

  //Searches
  function handleAccountSetEventSearchOnlineCountries(arr) {
    dispatch(setEventSearchOnlineCountries(arr));
  }
  function handleAccountSetDistanceFilter(num) {
    dispatch(setDistanceFilter(num));
  }
  function handleAccountSetDistanceMeasurement(str) {
    dispatch(setDistanceMeasurement(str));
  }
  function handleAccountSetSearchLanguages(arr) {
    dispatch(setSearchLanguages(arr));
  }

  //Tags
  function handleAccountSetBigTags(str) {
    dispatch(setBigTags(str));
  }
  function handleAccountSetTinyTags(arr) {
    dispatch(setTinyTags(arr));
  }
  function handleAccountSetTinyTagGroups(arr) {
    dispatch(setTinyTagGroups(arr));
  }

  //TranslationRooms
  function handleAccountSetTranslationRoomsRoomCodes(arr) {
    dispatch(setTranslationRoomsRoomCodes(arr));
  }

  function handleAccountSetTranslationRoomsRoomCode(obj) {
    dispatch(setTranslationRoomsRoomCode(obj));
  }

  async function handleUpdateCustomerStripeData(newData, updateCategory) {
    if (
      !accountBillingCustomerId ||
      isLoadingUpdateCustomer ||
      isFetchingUpdateCustomer
    )
      return;

    if (updateCategory === 'name') {
      const oldName = `${accountFirstName} ${accountLastName}`;
      const newName = `${newData?.personal?.firstName} ${newData?.personal?.lastName}`;

      if (oldName !== newName) {
        const { error } = await updateCustomer({ accountId, newName });
        if (error) return error;
      }
    }

    if (updateCategory === 'email') {
      const oldEmail = accountEmail;
      const newEmail = newData?.email;

      if (oldEmail !== newEmail) {
        const { error } = await updateCustomer({ accountId, newEmail });
        if (error) return error;
      }
    }

    if (updateCategory === 'phone') {
      const oldPhone = accountPhoneNumber;
      const newPhone = newData?.personal?.phoneNumber;

      if (oldPhone !== newPhone) {
        const { error } = await updateCustomer({
          accountId,
          newPhone,
        });
        if (error) return error;
      }
    }

    if (updateCategory === 'appSettings') {
      const oldContentLanguage = accountContentLanguage;
      const newContentLanguage = newData?.appSettings?.contentLanguage;

      if (oldContentLanguage !== newContentLanguage) {
        const { error } = await updateCustomer({
          accountId,
          newContentLanguage,
        });
        if (error) return error;
      }
    }
  }

  const handleAccountFetchCardDetails = async (
    isLoadingCardDetails,
    setIsLoadingCardDetails,
    setCardDetails
  ) => {
    if (!isLoadingCardDetails) {
      setIsLoadingCardDetails(true);
      try {
        const receivedCardDetails = await getCustomerDetails({
          accountId,
        });

        const cardDetailsArr = receivedCardDetails?.data?.paymentMethodData;

        if (cardDetailsArr?.length > 0) {
          const primaryCardSetToFirstInArray = [...cardDetailsArr]?.sort(
            (a, b) => {
              if (a.isDefault) return 1;
              if (b.isDefault) return -1;
              return 0;
            }
          );
          setCardDetails(primaryCardSetToFirstInArray);
        }
      } catch (error) {
        handleError(error);
      }
      setIsLoadingCardDetails(false);
    }
  };

  return {
    account,
    accountAccountReference,
    accountAffiliations,
    accountAppSettings,
    accountArchivedEvents,
    accountBigTags,
    accountContentLanguage,
    accountCountryCode,
    accountDistanceFilter,
    accountDistanceMeasurement,
    accountEmail,
    accountEventSearchOnlineCountries,
    accountFirstName,
    accountFollowedBy,
    accountFollowing,
    accountFollowingGroups,
    accountGeoPosition,
    accountGeoPositionCoordinates,
    accountId,
    accountIgnoredEvents,
    accountLastName,
    accountLinkFacebook,
    accountLinkInstagram,
    accountLinkLinkedIn,
    accountLinkPersonalWebsite,
    accountLinkX,
    accountMeta,
    accountMyEventsAll,
    accountNetwork,
    accountOrganizedEvents,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
    accountPersonal,
    accountPhoneNumber,
    accountPrefix,
    accountProfileImage,
    accountPronouns,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountSavedEvents,
    accountSearches,
    accountSearchLanguages,
    accountSoundEffects,
    accountSpeaker,
    accountTags,
    accountTimeZone,
    accountTinyTagGroups,
    accountTinyTags,
    handleAccountEmptyAccountData,
    handleAccountRegistrationsInPerson,
    handleAccountRegistrationsVirtual,
    handleAccountSetArchivedEvents,
    handleAccountSetBigTags,
    handleAccountSetContentLanguage,
    handleAccountSetCountryCode,
    handleAccountSetDistanceFilter,
    handleAccountSetDistanceMeasurement,
    handleAccountSetEmail,
    handleAccountSetEventSearchOnlineCountries,
    handleAccountSetFirstName,
    handleAccountSetFollowedBy,
    handleAccountSetFollowing,
    handleAccountSetFollowingGroups,
    handleAccountSetIgnoredEvents,
    handleAccountSetLastName,
    handleAccountSetLinkFacebook,
    handleAccountSetLinkInstagram,
    handleAccountSetLinkLinkedIn,
    handleAccountSetLinkPersonalWebsite,
    handleAccountSetLinkX,
    handleAccountSetNetwork,
    handleAccountSetOrganizedEvents,
    handleAccountSetPendingApprovalInPerson,
    handleAccountSetPendingApprovalVirtual,
    handleAccountSetPhoneNumber,
    handleAccountSetPrefix,
    handleAccountSetProfileImage,
    handleAccountSetPronouns,
    handleAccountSetSavedEvents,
    handleAccountSetSearchLanguages,
    handleAccountSetSoundEffects,
    handleAccountSetTimeZone,
    handleAccountSetTinyTagGroups,
    handleAccountSetTinyTags,
    accountBillingCustomerId,
    accountBillingPaymentMethodIds,
    accountBillingRoomCodeSubscriptions,
    accountTranslationRoomsRoomCodes,
    handleAccountSetTranslationRoomsRoomCode,
    handleAccountSetTranslationRoomsRoomCodes,
    handleAccountSetBillingCustomerId,
    handleAccountSetBillingPaymentMethodIds,
    handleAccountSetBillingRoomCodeSubscriptions,
    accountEmailPreferencesMyndFullNewsletter,
    accountEmailPreferencesPromotions,
    handleAccountSetEmailPreferencesMyndFullNewsletter,
    handleAccountSetEmailPreferencesPromotions,
    accountBilling,
    handleUpdateCustomerStripeData,
    handleAccountFetchCardDetails,
    accountBillingMyInvoices,
    handleAccountSetMyInvoices,
    accountFailedInvoices,
    handleAccountSetFailedInvoices,
  };
};
