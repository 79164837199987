const InfoCardContainerTrace = ({ traceData }) => {
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Trace</p>
      <div className="mrg-t6">Ip Address: {traceData?.ipAddress}</div>
      <div className="mrg-t6">User Agent: {traceData?.userAgent}</div>

      <div className="mrg-t6">Client Version: {traceData?.clientVersion}</div>
      <div className="mrg-t6">Server Id: {traceData?.serverId}</div>
      <div className="mrg-t6">Function Id: {traceData?.fnId}</div>
      <div className="mrg-t6">Last Success Stage: {traceData?.stage}</div>

      <div className="h12" />
      <div className="divider-dotted mrg-tb12" />

      <div className="mrg-t6">Stack Trace: {traceData?.stackTrace}</div>
    </div>
  );
};

export default InfoCardContainerTrace;
