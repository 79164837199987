import { useEffect, useState } from 'react';

//hooks
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom.js';
import useSettings from '../../../../../context/useSettings.js';
import useLanguageData from '../../../../../language/useLanguageData.js';

//components
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utility
import {
  clock,
  icon_profile,
  img_checklist,
  img_refresh_two_circular,
  return_curved_arrow,
  speaker_dark,
  checkmark_green,
  img_examine_page,
  cancel,
  img_microphone_on,
} from '../../../../../assets/icons';

const RoomContentDesktop = ({
  changeSpeaker,
  currentSpeaker,
  Generic,
  handleExit,
  handleGuide,
  handleOpenSpeakerDetails,
  isHost,
  isHostPresent,
  isListening,
  microphoneAccess,
  occupantsNum,
  promptMicrophonePermission,
  roomCode,
  roomLanguageOpts,
  setAudioContentQueue,
  setTranslationLang,
  speakersData,
  toggleListening,
  translationLang,
  translationRoomSocketStatus,
}) => {
  //hooks
  const { TranslationRoom } = useLanguageTranslationRoom();
  const { contentLanguage } = useSettings();
  const { TranslationLanguagesSingular } = useLanguageData();

  //state
  const [speakersOpts, setSpeakersOpts] = useState();
  const [filteredHostTextLanguageOpts, setFilteredHostTextLanguageOpts] =
    useState();

  //UI
  useEffect(() => {
    if (roomLanguageOpts) {
      const newRoomLanguageOpts = Object.entries(roomLanguageOpts).reduce(
        (acc, [key, value]) => {
          if (key !== translationLang) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      setFilteredHostTextLanguageOpts(newRoomLanguageOpts);
    }
  }, [roomLanguageOpts, contentLanguage, translationLang]); //create filtered list to remove selection

  useEffect(() => {
    let newSpeakerOpts = {};
    speakersData?.map((speaker) => {
      return (newSpeakerOpts = {
        ...newSpeakerOpts,
        [speaker.id]: `${speaker.name}`,
      });
    });
    setSpeakersOpts(newSpeakerOpts);
  }, [speakersData, currentSpeaker]); //create filtered list to remove selection

  return (
    <>
      <div
        className="translations-room__container access-ob access-o6 mrg-t48"
        tabIndex="0"
        id={'translationRoomHeader'}
      >
        <div className="flex-row space-between align-center">
          <ItemButton
            text={TranslationRoom.exit}
            handler={() => {
              setAudioContentQueue();
              handleExit();
            }}
            icon={return_curved_arrow}
            iconOn={true}
            fullWidth={true}
            customWrapper={'action-btns'}
          />

          <div className="flex-row">
            <div
              className={`translations-room-code access-ob access-o6`}
              tabIndex="0"
            >
              {roomCode && (
                <div className="border-thin full-width fwb">
                  <div className="space-around pad-12 ff2">
                    <span>{roomCode[0]}</span>
                    <span className="mrg-l6">{roomCode[1]}</span>
                    <span className="mrg-l6">{roomCode[2]}</span>
                    <span className="mrg-l6">{roomCode[3]}</span>
                    {roomCode[4] && (
                      <span className="mrg-l6">{roomCode[4]}</span>
                    )}
                    {roomCode[5] && (
                      <span className="mrg-l6">{roomCode[5]}</span>
                    )}
                    {roomCode[6] && (
                      <span className="mrg-l6">{roomCode[6]}</span>
                    )}
                    {roomCode[7] && (
                      <span className="mrg-l6">{roomCode[7]}</span>
                    )}
                    {roomCode[8] && (
                      <span className="mrg-l6">{roomCode[8]}</span>
                    )}
                    {roomCode[9] && (
                      <span className="mrg-l6">{roomCode[9]}</span>
                    )}
                    {roomCode[10] && (
                      <span className="mrg-l6">{roomCode[10]}</span>
                    )}
                    {roomCode[11] && (
                      <span className="mrg-l6">{roomCode[11]}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="live-connection-icon mrg-l12">
              <img
                src={img_refresh_two_circular}
                alt={Generic.icon}
                className={`svg  ${
                  translationRoomSocketStatus !== 'connected'
                    ? 'live-connection-status--pending'
                    : 'remove'
                }`}
                title={Generic.attemptingToReconnect}
                style={{ top: '0px', bottom: '0px' }}
              />
            </div>
          </div>

          {isHost && (
            <ItemButton
              text={TranslationRoom.quickGuide}
              handler={handleGuide}
              icon={img_checklist}
              iconOn={true}
              customWrapper={'action-btns'}
            />
          )}
        </div>

        <div className="flex-row space-between mrg-t24 align-center">
          {isHost && speakersOpts && (
            <div className="flex-row align-center">
              <div className="flex-column">
                <SelectDropdown
                  id={'selectSpeaker'}
                  list={speakersOpts}
                  handleSelection={changeSpeaker}
                  internalLabel={TranslationRoom.currentSpeaker}
                  asterisk={true}
                  fullWidth={true}
                  tempDisable={isListening}
                  temporaryDisableMessage={'cannotSelectWhileRecording'}
                  defaultKey={
                    currentSpeaker?.id ? currentSpeaker?.id : 'select'
                  }
                  secondButtonToggle={currentSpeaker ? true : false}
                  secondButtonId={'openSpeakerDetailsBtn'}
                  secondButtonClass={'openSpeakerDetails highlight-i-lgt'}
                  secondButtonTitle={TranslationRoom.openSpeakerDetails}
                  secondButtonImg={img_examine_page}
                  secondButtonFunction={handleOpenSpeakerDetails}
                  secondButtonPadding={'9px'}
                />
              </div>
            </div>
          )}

          {!isHost && !currentSpeaker ? (
            <div className="flex-column">
              <p className="fs18 fwn mrg-b12">
                {TranslationRoom.noActiveSpeaker}
              </p>
              <div className="divider mrg-b12" />
              <p className="fs18 fwn">
                {TranslationRoom.yourLanguage}:{' '}
                {TranslationLanguagesSingular[translationLang]}
              </p>
            </div>
          ) : (
            !isHost &&
            currentSpeaker && (
              <div className="flex-column">
                <p className="fs18 fwn mrg-b6">
                  {TranslationRoom.speaker}: {currentSpeaker.name}
                </p>
                <p className="fs18 fwn mrg-b12">
                  {TranslationRoom.speakersLanguage}:{' '}
                  {TranslationLanguagesSingular[currentSpeaker.languageOpt]}
                </p>
                <div className="divider mrg-b12" />
                <p className="fs18 fwn">
                  {TranslationRoom.yourLanguage}:{' '}
                  {TranslationLanguagesSingular[translationLang]}
                </p>
              </div>
            )
          )}

          <div className="flex-column">
            {isHost ? (
              <div className="flex-column  access-ob access-o6" tabIndex="0">
                <div
                  className="flex-row flex-end mrg-t12"
                  title={
                    microphoneAccess
                      ? `${TranslationRoom.microphonePermission} ${TranslationRoom.granted}`
                      : `${TranslationRoom.microphonePermission} ${TranslationRoom.denied}`
                  }
                >
                  {!microphoneAccess ? (
                    <button
                      className={`border-call-to-action pad-12 flex-center fs18 fwsb highlight-it-theme access-ob access-o6`}
                      onClick={promptMicrophonePermission}
                    >
                      {TranslationRoom.grantAccess}
                    </button>
                  ) : (
                    <p className="fs18 fwsb flex-end align-center text-center">
                      {TranslationRoom.microphonePermission}
                    </p>
                  )}

                  <div
                    className="flex-center mrg-l6"
                    style={{ minWidth: '36px' }}
                  >
                    {microphoneAccess ? (
                      <img
                        src={checkmark_green}
                        alt={TranslationRoom.checkmark}
                        className="mrg-auto-tb"
                        style={{ height: '18px' }}
                      />
                    ) : (
                      <img
                        src={cancel}
                        alt={'X'}
                        className="mrg-auto-tb filter-red"
                        style={{ height: '18px' }}
                      />
                    )}
                  </div>
                  <img
                    src={img_microphone_on}
                    alt="img"
                    style={{ minHeight: '36px' }}
                    className="svg filter-gray flex-center mrg-l6"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex-row align-center flex-end mrg-t12">
                  <p className="mrg-r12 fs18 fwsb">
                    {TranslationRoom.attendance}
                  </p>
                  <p className="fs18 fwsb no-select">{occupantsNum || 0}</p>
                  <img
                    src={icon_profile}
                    alt="img"
                    className="svg filter-gray mrg-l12"
                    aria-hidden="true"
                  />
                </div>
              </div>
            ) : (
              <>
                {isHostPresent ? (
                  <div
                    className={`flex-row flex-end align-center access-ob access-o6 ${
                      isHost ? 'mrg-b12' : ''
                    }`}
                    tabIndex="0"
                  >
                    <p className="mrg-r12 fs18 fwsb">
                      {TranslationRoom.hostIsReady}
                    </p>
                    <img
                      src={speaker_dark}
                      alt="img"
                      style={{ minHeight: '36px' }}
                      className="svg filter-gray"
                      aria-hidden="true"
                    />
                    <img
                      src={checkmark_green}
                      alt="img"
                      className="svg mrg-l12"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <div
                    className="flex-row flex-end mrg-b12 align-center access-ob access-o6"
                    tabIndex="0"
                  >
                    <p className="mrg-r12 fs18 fwsb">
                      {TranslationRoom.waitingForHost}
                    </p>
                    <img
                      src={speaker_dark}
                      alt="img"
                      style={{ minHeight: '36px' }}
                      className="svg filter-gray"
                      aria-hidden="true"
                    />
                    <img
                      src={clock}
                      className="svg mrg-l12 filter-red"
                      alt="img"
                      style={{ minHeight: '18px' }}
                      aria-hidden="true"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {isHost && <div className="divider full-width mrg-t24" />}

      <div className="flex-row align-center justify-center mrg-t12 full-width">
        {isHost && filteredHostTextLanguageOpts && (
          <div className="space-between full-width">
            <div className="flex-row">
              <SelectDropdown
                id={'textLanguage'}
                list={roomLanguageOpts}
                filteredList={filteredHostTextLanguageOpts}
                handleSelection={setTranslationLang}
                internalLabel={TranslationRoom.textLanguage}
                defaultKey={
                  translationLang && translationLang !== 'select'
                    ? translationLang
                    : 'select'
                }
                asterisk={true}
                tempDisable={isListening}
                temporaryDisableMessage={'cannotSelectWhileRecording'}
              />
            </div>

            <button
              className={`translations-btn__container border-reg highlight-b-theme ${
                !currentSpeaker ||
                translationLang === 'select' ||
                !translationLang ||
                translationRoomSocketStatus !== 'connected'
                  ? 'opacity-3'
                  : ''
              }`}
              onClick={toggleListening}
              aria-label={
                isListening
                  ? TranslationRoom.ariaSpeechRecognitionActive
                  : TranslationRoom.ariaSpeechRecognitionInactive
              }
            >
              <div
                id="activate-translations-btn"
                className={`activate-translations-btn color-black ${
                  isListening ? 'translations-btn-active' : 'bg-color-lightgray'
                }`}
              />
              <p className="fs16 fwsb mrg-l12">
                {TranslationRoom.activateSpeechRecognition}
              </p>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default RoomContentDesktop;
