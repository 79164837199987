//Hooks

//Components

//Utility
import clockConversion from '../../../../utils/dateTime/clockConversion';

const AgendaContent = ({ agendaData, EventPage }) => {
  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="agendaLabel"
      >
        {EventPage.agenda}
      </label>
      <div className="agenda__container">
        {agendaData.items.map((item) => {
          return (
            <div
              className="flex-row mrg-b12 access-ob access-o6"
              tabIndex="0"
              key={item.id}
            >
              <div className="agenda-bar" />
              <div className="agenda-item">
                <div className="flex-column">
                  <p>{item.text},</p>
                  {agendaData.format === 'minutes' ? (
                    <p className="fs16 mrg-t6">
                      {item.value} {EventPage.minutes}
                    </p>
                  ) : (
                    <p className="fs16 mrg-t6 flex-row">
                      {clockConversion(item.startTime)}
                      &nbsp;&#8211;&nbsp; {clockConversion(item.endTime)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="h24" />
    </>
  );
};

export default AgendaContent;
