//Hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//Components
import AppSettings from './AppSettings';

//Utility

// import InstallWebApp from './AccountPersonalContent/InstallWebApp';

const GeneralContent = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { Account } = useLanguageAccount();

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      <AppSettings
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />

      <div className="h48" />
    </div>
  );
};

export default GeneralContent;
