const useTabNavigationHeading = () => {
  function setTabNavigationHeading(
    contentLanguage,
    accountContentLanguage,
    path,
    windowLocationHost
  ) {
    if (windowLocationHost === 'admin') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'Admin | MyndFull');
      }

      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'Admin | MyndFull');
      }
    }

    if (path === '/') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'MyndFull');
      }
      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'MyndFull');
      }

      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'MyndFull');
      }
      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'MyndFull');
      }
    }

    if (path === '/login') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'Sign In | MyndFull');
      }

      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'Se connecter | MyndFull');
      }
    }

    if (path === '/register') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'Registration | MyndFull');
      }

      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'Inscription | MyndFull');
      }
    }

    if (path === '/events') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'Events | MyndFull');
      }

      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'Événements | MyndFull');
      }
    }

    if (path === '/organize') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'Organize | MyndFull');
      }

      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'Organiser | MyndFull');
      }
    }

    if (path === '/account') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'Account | MyndFull');
      }

      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'Compte | MyndFull');
      }
    }

    if (path === '/tr') {
      if (
        (contentLanguage === 'en-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'en-CA')
      ) {
        return (document.title = 'Translation Rooms | MyndFull');
      }

      if (
        (contentLanguage === 'fr-CA' && !accountContentLanguage) ||
        (accountContentLanguage && accountContentLanguage === 'fr-CA')
      ) {
        return (document.title = 'Salles de traduction | MyndFull');
      }
    }

    ///////
  }

  return {
    setTabNavigationHeading,
  };
};

export default useTabNavigationHeading;
