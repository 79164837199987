import { useState } from 'react';

const MinorActionBtn = ({
  text,
  fontSize,
  fontWeight,
  customWrapper,
  onClickHandler,
}) => {
  const [highlight, setHighlight] = useState(false);

  return (
    <button
      className={`flex-column flex-center access-ob access-o6 ${
        customWrapper ? customWrapper : ''
      }`}
      onTouchStart={() => setHighlight(true)}
      onTouchEnd={() => setHighlight(false)}
      tabIndex="0"
      onClick={
        onClickHandler
          ? onClickHandler
          : () => {
              return null;
            }
      }
    >
      <p
        className={`highlight-b-theme highlight-it-theme ${
          fontSize ? fontSize : 'fs16'
        } ${
          fontWeight ? fontWeight : 'fwsb'
        } border-reg br--standard pad-lr12 pad-tb6 ${
          highlight ? 'color-theme highlight-b-theme--tap' : 'color-gray'
        }`}
      >
        {text}
      </p>
    </button>
  );
};

export default MinorActionBtn;
