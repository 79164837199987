import { ca, us, mx } from '../../assets/icons/flags/index';

const GetFlag = (country) => {
  let flagIcon;

  if (country === 'canada') {
    flagIcon = <img src={ca} alt="flag" className="flag" />;
  }
  if (country === 'unitedStates') {
    flagIcon = <img src={us} alt="flag" className="flag" />;
  }
  if (country === 'mexico') {
    flagIcon = <img src={mx} alt="flag" className="flag" />;
  }
  return flagIcon;
};

export default GetFlag;
