import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import { useAdminAuthReduxHandlers } from '../../features/admin/auth/useAdminAuthHandlers';
import useSettings from '../../context/useSettings';
import useLanguageLayout from '../../language/useLanguageLayout';

//Components
import AdminNavLoggedIn from './AdminNavLoggedIn';

//Utils
import { img_hamburger } from '../../assets/icons';

const AdminNavbar = ({ layoutIsMounting }) => {
  //Hooks
  const navigate = useNavigate();
  const { setOpenSlideMenu, openSlideMenu, width } = useSettings();
  const { adminAuthCurrentRoles } = useAdminAuthReduxHandlers();
  const { NavbarLoggedOut } = useLanguageLayout();
  const [adminAuthRoles, setAdminAuthRoles] = useState([]);
  const [componentIsMounting, setComponentIsMounting] = useState(true);

  useEffect(() => {
    setAdminAuthRoles(adminAuthCurrentRoles);
  }, [adminAuthCurrentRoles]);

  //UI Transitions
  useEffect(() => {
    if (!layoutIsMounting) {
      const pageAnimationTimer = setTimeout(() => {
        setComponentIsMounting(false);
      }, 100);
      return () => clearTimeout(pageAnimationTimer);
    }
  }, [layoutIsMounting]);

  return (
    <header
      className={`${
        componentIsMounting ? 'offscreen' : 'navbar onscreen-fade-in-100ms'
      }`}
      id="navbar"
      aria-label={NavbarLoggedOut.header}
    >
      <div className="navbar__container">
        <Link
          to={'/'}
          className="logo__link access-ob access-o12"
          onClick={() => navigate('/')}
          tabIndex="0"
        >
          <div className="logo__color1 disable-select">Mynd</div>
          <div className="logo__color2 disable-select">Full</div>
        </Link>
        {width <= 1280 ? (
          <button
            className="hamburger access-ob access-o6"
            onClick={() => setOpenSlideMenu(!openSlideMenu)}
            tabIndex="0"
            aria-label={NavbarLoggedOut.navigationMenu}
            aria-expanded={openSlideMenu}
            id="hamburger"
          >
            <img
              src={img_hamburger}
              alt={NavbarLoggedOut.menuIcon}
              aria-hidden="true"
              className="filter-white"
            />
          </button>
        ) : (
          adminAuthRoles && <AdminNavLoggedIn currentRoles={adminAuthRoles} />
        )}
      </div>
    </header>
  );
};

export default AdminNavbar;
