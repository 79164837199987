import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility
const adminTechnicalSlice = createSlice({
  name: 'adminTechnical',
  initialState: {
    technicalLogs: [],
    technicalLogsArchive: [],
  },
  reducers: {
    //Technical Logs
    setAdminTechnicalLogs: (state, action) => {
      state.technicalLogs = action.payload;
    },
    setAdminTechnicalLog: (state, action) => {
      const updatedLog = action.payload;
      const index = state.technicalLogs.findIndex(
        (log) => log._id === updatedLog._id
      );
      if (index !== -1) {
        state.technicalLogs[index] = updatedLog;
      } else {
        state.technicalLogs.push(updatedLog);
      }
    },
    removeAdminTechnicalLogById: (state, action) => {
      state.technicalLogs = state.technicalLogs.filter(
        (log) => log._id !== action.payload
      );
    },
    //Technical Logs Archive
    setAdminTechnicalLogsArchive: (state, action) => {
      state.technicalLogsArchive = action.payload;
    },
    setAdminTechnicalLogArchive: (state, action) => {
      const updatedLog = action.payload;
      const index = state.technicalLogsArchive.findIndex(
        (log) => log._id === updatedLog._id
      );
      if (index !== -1) {
        state.technicalLogsArchive[index] = updatedLog;
      } else {
        state.technicalLogsArchive.push(updatedLog);
      }
    },
    removeAdminTechnicalLogArchiveById: (state, action) => {
      state.technicalLogsArchive = state.technicalLogsArchive.filter(
        (log) => log._id !== action.payload
      );
    },
  },
});

export const {
  //Technical Logs
  setAdminTechnicalLogs,
  setAdminTechnicalLog,
  removeAdminTechnicalLogById,

  //Technical Logs Archive
  setAdminTechnicalLogsArchive,
  setAdminTechnicalLogArchive,
  removeAdminTechnicalLogArchiveById,
} = adminTechnicalSlice.actions;

export default adminTechnicalSlice.reducer;

//Technical Logs
export const selectAdminTechnicalLogs = (state) =>
  state.adminTechnical?.technicalLogs;

//Technical Logs Archive
export const selectAdminTechnicalLogsArchive = (state) =>
  state.adminTechnical?.technicalLogsArchive;
