const tinyTagsArrayValidator = (arr) => {
  if (!arr) {
    return true;
  } //required in case users tags are in tag groups

  if (!Array.isArray(arr)) {
    return false;
  }
  for (let i = 0; i < arr.length; i++) {
    if (
      typeof arr[i] !== 'string' ||
      !/^#[a-zA-Z0-9]+$/.test(arr[i]) ||
      arr[i].length > 61
    ) {
      return false;
    }
  }

  if (arr.length >= 200) {
    return false;
  }
  return true;
};

const tinyTagStringValidator = (string) => {
  if (!/^[a-zA-Z0-9]+$/.test(string)) {
    return false;
  }
  return true;
};

export { tinyTagsArrayValidator, tinyTagStringValidator };
