import { useEffect, useState } from 'react';

//hooks
import { useRegistrationHandlers } from '../../useRegistrationHandlers';
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useApiStatus from '../../../../../context/useApiStatus';

//components
import NavigationArrows from '../../../../../components/NavigationArrows/NavigationArrows';
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import MinorActionBtn from '../../../../../components/MinorActionBtn/MinorActionBtn';
import AddTranslationRoomInput from '../../../../../components/AddTranslationRoomInput/AddTranslationRoomInput';

//Utils
import { image_room_key } from '../../../../../assets/images';
import { cancel, checkmark_green, img_plus } from '../../../../../assets/icons';

//utils
const TranslationRoomCodeRegistration = () => {
  const {
    handleRegistrationPageDecrease,
    handleRegistrationPageIncrease,
    registrationPage,
    registrationTranslationRoomsRoomCodes,
    handleRegistrationSetTranslationRoomsRoomCodes,
    roomCodeRegistrationSearch,
    handleRegistrationPageCompletionStatus,
  } = useRegistrationHandlers();
  const { Generic } = useLanguageComponents();
  const { TranslationRoomCodeRegistration } = useLanguageRegistration();
  const { handleWarning } = useApiStatus();

  //state
  const [roomCodeInputValue, setRoomCodeInputValue] = useState();
  const [roomCodeSearchIsLoading, setRoomCodeSearchIsLoading] = useState();

  //validity checks
  const [roomCodeValid, setRoomCodeValid] = useState(false);
  const [resetRoomCode, setResetRoomCode] = useState(false);

  //UI
  const [continueBtnCheck, setContinueBtnCheck] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  //Validations
  useEffect(() => {
    if (registrationTranslationRoomsRoomCodes?.length > 0) {
      !continueBtnCheck && setContinueBtnCheck(true);
    } else {
      continueBtnCheck && setContinueBtnCheck(false);
    }
  }, [registrationTranslationRoomsRoomCodes]);

  async function handleAddTranslationRoomCode(newRoomCode) {
    //Not valid, sets unmet requirements as red with checkRan
    if (!roomCodeValid || newRoomCode?.length <= 3) {
      setRoomCodeSearchIsLoading(false);
      return handleWarning({
        message: 'invalidRoomCode',
        origin:
          'TranslationRoomCodeRegistration.js/handleAddTranslationRoomCode',
        id: Date.now(),
      });
    }

    //make sure not already loading
    if (!roomCodeSearchIsLoading && newRoomCode?.length > 0) {
      setRoomCodeSearchIsLoading(true);

      let formattedRoomCode = newRoomCode.toUpperCase();

      //check is code is already reserved
      const roomCodeExists = await roomCodeRegistrationSearch(
        formattedRoomCode
      );
      setRoomCodeSearchIsLoading(false);

      if (roomCodeExists) {
        handleWarning({
          message: 'roomCodeRegistered',
          origin:
            'TranslationRoomCodeRegistration.js/handleAddTranslationRoomCode',
          id: Date.now(),
        });

        return setResetRoomCode(!resetRoomCode);
      } else if (roomCodeExists !== undefined) {
        //already included in list
        if (registrationTranslationRoomsRoomCodes.includes(formattedRoomCode)) {
          setResetRoomCode(!resetRoomCode);
          return handleWarning({
            message: 'roomCodeAlreadyAdded',
            origin:
              'TranslationRoomCodeRegistration.js/handleAddTranslationRoomCode',
            id: Date.now(),
          });
        }

        //doesnt exist
        let newRoomCodes = [];
        if (registrationTranslationRoomsRoomCodes?.length > 0) {
          newRoomCodes = [
            ...registrationTranslationRoomsRoomCodes,
            formattedRoomCode,
          ];
        } else {
          newRoomCodes = [formattedRoomCode];
        }
        handleRegistrationSetTranslationRoomsRoomCodes(newRoomCodes);
        setResetRoomCode(!resetRoomCode);
        setRoomCodeSearchIsLoading(false);
      }
    }
  }

  function handleRemoveRoomCode(trCode) {
    const newRoomCodes = registrationTranslationRoomsRoomCodes.filter(
      (code) => code !== trCode
    );
    handleRegistrationSetTranslationRoomsRoomCodes(newRoomCodes);
  }

  return (
    <>
      <div className="flex-column">
        <label className="content-label flex-row" tabIndex="0">
          {TranslationRoomCodeRegistration.heading}
        </label>
        <p className="fs18 fwn mrg-tb12">
          {TranslationRoomCodeRegistration.description1}
        </p>

        <div className="flex-row flex-column--ph">
          <ul className="tr-description-list mrg-t12 fs18">
            <li>{TranslationRoomCodeRegistration.description2}</li>
            <li>{TranslationRoomCodeRegistration.description3}</li>
            <li>{TranslationRoomCodeRegistration.description4}</li>
            <li>{TranslationRoomCodeRegistration.description5}</li>
          </ul>
          <div className="flex-column ">
            <img
              src={image_room_key}
              alt="img"
              className="room-key-img svg"
              aria-hidden={true}
              onLoad={() => setImgLoaded(true)}
            />
            <MinorActionBtn
              text={TranslationRoomCodeRegistration.learnMore}
              customWrapper={'mrg-t12'}
              // onClickHandler={() => {
              //   return null;
              // }}
            />
          </div>
        </div>
      </div>

      <div className="h36" />
      <label
        className="content-label flex-row align-center min-full-width mrg-b24"
        id="addRoomCodeLabel"
        tabIndex="0"
      >
        {TranslationRoomCodeRegistration.addRoomCode}
        <p className="color-theme disable-select" aria-hidden="true">
          &nbsp;*
        </p>

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
          {registrationTranslationRoomsRoomCodes?.length > 0 && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>

      <AddTranslationRoomInput
        handleEnter={handleAddTranslationRoomCode}
        setRoomCode={setRoomCodeInputValue}
        roomCode={roomCodeInputValue}
        roomCodeLabelText={TranslationRoomCodeRegistration.addRoomCode}
        setValid={setRoomCodeValid}
        reset={resetRoomCode}
      />

      <ButtonMeta
        handleClick={() => handleAddTranslationRoomCode(roomCodeInputValue)}
        btnText={TranslationRoomCodeRegistration.addRoomCode}
        icon={img_plus}
        customClassName={`full-width mrg-t24 mrg-b48 ${
          roomCodeValid ? 'highlight-i-theme' : ''
        }`}
        iconAlt={'+'}
        fullWidth={true}
        isLoading={roomCodeSearchIsLoading}
        unavailable={!roomCodeValid}
        availabilityToggle={!roomCodeValid}
      />
      <label
        className="content-label flex-row"
        id="roomCodeListLabel"
        tabIndex="0"
      >
        {TranslationRoomCodeRegistration.roomCodeList}
      </label>

      <div className="flex-column full-width mrg-t24 mrg-b48 fs21 fs18--ph fs16--phn">
        {registrationTranslationRoomsRoomCodes?.length === 0 && (
          <div className="flex-center">[No Room Codes Img]</div>
        )}
        <ul className="flex-column list-style-none mrg-b24 full-width align-center">
          {registrationTranslationRoomsRoomCodes?.map((trCode, index) => {
            return (
              <li
                className="pad-l24 pad-r24 pad-t6 pad-b6 pad-lr12--ph br--standard flex-row full-width align-center space-between mrg-tb6 highlight-bthin-theme cursor-pointer fs18 no-select"
                key={`trCode-${index}`}
                style={{ minHeight: '44px' }}
                tabIndex="0"
                title={Generic.remove}
                role="button"
                onClick={() => handleRemoveRoomCode(trCode)}
              >
                <span className="no-wrap">{trCode}</span>
                <img
                  src={cancel}
                  className="filter-red"
                  alt="X"
                  style={{ height: '16px' }}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <NavigationArrows
        handlePageDecrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageDecrease();
        }}
        handlePageIncrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageIncrease(registrationPage);
        }}
        continueBtnCheck={continueBtnCheck}
      />
    </>
  );
};

export default TranslationRoomCodeRegistration;
