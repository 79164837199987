import { useEffect, useState } from 'react';

//Hooks
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import {
  useGetAdminAccountsQuery,
  useGetAdminLogsQuery,
} from './adminManagementApiSlice';
import useSettings from '../../../context/useSettings';
import useApiStatus from '../../../context/useApiStatus';
import { useAdminManagementHandlers } from './useAdminManagementHandlers';
import { useAdminSupportReduxHandlers } from '../support/useAdminSupportHandlers';

//Components
import AdminManagementNav from './AdminManagementNav';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import AdminRegistration from './AdminRegistration';
import AdminPermissions from './AdminPermissions';
import AdminLogs from './AdminLogs';
import AdminTickets from '../utils/AdminTickets';
import AdminSearch from '../utils/AdminSearch';
import AdminCardsRowSupport from '../utils/AdminCardsRowSupport';

//Utils

const AdminManagement = () => {
  //hooks
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { setNavbar } = useSettings();
  const { handleWarning } = useApiStatus();
  const { adminManagementTickets, handleAdminManagementTicketsSorting } =
    useAdminManagementHandlers();

  const { adminSupportSearchQueries } = useAdminSupportReduxHandlers();

  //API
  const { data: adminAccountsData } = useGetAdminAccountsQuery({
    accountId: adminAccountId,
  });
  const { data: adminLogsData } = useGetAdminLogsQuery({
    accountId: adminAccountId,
  });

  //component state
  const [pageLoadComplete, setPageLoadComplete] = useState();
  const [view, setView] = useState('logs');
  const [cardsRowIds, setCardsRowIds] = useState([]);

  //UI
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);

  //Initialize
  useEffect(() => {
    setNavbar('management');
  }, []);

  function handleCardRowSelection(selectionId) {
    if (cardsRowIds.includes(selectionId)) {
      const newWarning = {
        message: 'alreadySelected',
        id: Date.now(),
        origin: 'AdminSupport.js/handleCardRowSelection',
      };
      return handleWarning(newWarning);
    }

    //No duplicate
    let newArr = [...cardsRowIds, selectionId];
    setCardsRowIds(newArr);
  }

  return (
    <>
      {pageLoadComplete ? (
        <div className="admin">
          <AdminManagementNav setView={setView} view={view} />
          {view === 'logs' && (
            <AdminLogs adminLogsData={adminLogsData?.adminLogs} />
          )}
          {view === 'permissions' && (
            <AdminPermissions
              adminAccountsData={adminAccountsData?.adminAccounts}
            />
          )}
          {view === 'register' && <AdminRegistration />}
          {view === 'search' && (
            <div className="admin-tickets__wrapper">
              <AdminSearch
                cardsRowIds={cardsRowIds}
                setCardsRowIds={setCardsRowIds}
                managementMode={true}
              />
              <AdminCardsRowSupport
                currentSearchIndex={currentSearchIndex}
                setCurrentSearchIndex={setCurrentSearchIndex}
                cardsRowIds={cardsRowIds}
                setCardsRowIds={setCardsRowIds}
                adminTickets={adminManagementTickets}
                adminSearchQueries={adminSupportSearchQueries}
              />
              <AdminTickets
                ticketsData={adminManagementTickets}
                handleCardRowSelection={handleCardRowSelection}
                managementMode={true}
                handleTicketsSorting={handleAdminManagementTicketsSorting}
                currentViewDetailsSelections={cardsRowIds}
              />
            </div>
          )}
        </div>
      ) : (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          setLoadComplete={() => setPageLoadComplete(true)}
          loadCheck1={adminAccountId ? true : false}
          customWrapper={'min-full-height'}
        />
      )}
    </>
  );
};

export default AdminManagement;
