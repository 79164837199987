import useSettings from '../context/useSettings';

const useLanguageComponents = () => {
  const { contentLanguage } = useSettings();

  const ButtonSwitch = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        yes: 'Yes',
        no: 'No',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        yes: 'Oui',
        no: 'Non',
      };
    }
    return translation;
  };

  const EmailInput = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        emailAddress: 'Email Address',
        emailAlreadyRegistered: 'Email is already registered.',
        invalidEmailAddress: 'Invalid email address.',
        validEmailAddress: 'Valid email address.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        emailAddress: 'Adresse courriel',
        emailAlreadyRegistered: 'E-mail est déjà enregistrée.',
        invalidEmailAddress: 'E-mail invalide.',
        validEmailAddress: 'Adresse e-mail valable.',
      };
    }

    return translation;
  };

  const PhoneNumberInput = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        phoneNumber: 'Phone Number',
        isRequired: 'Phone Number is required.',
        countryCodes: 'Country Codes',
        requirementInstructions: 'Country code and phone number',
        ariaInstructions:
          'Phone number. Please enter your 10 digit phone number. Numbers will be automatically formatted.',
        phoneNumberInvalid: 'Your phone number is invalid.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        phoneNumber: `Numéro de téléphone`,
        isRequired: `Numéro de téléphone est requis.`,
        countryCodes: 'Indicatifs de pays',
        requirementInstructions: 'Indicatif du pays et numéro de téléphone',
        ariaInstructions:
          'Numéro de téléphone. Veuillez entrer votre numéro de téléphone à 10 chiffres. Les nombres seront automatiquement formatés.',
        phoneNumberInvalid: `Votre numéro de téléphone n'est pas valide.`,
      };
    }
    return translation;
  };

  const PasswordInputs = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        password: 'Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        passwordConfirmation: 'Password Confirmation',
        currentPassword: 'Current Password',
        passwordsMatch: 'Passwords Match.',
        showPasswordOn: 'Show password on.',
        showPasswordOff: 'Show password off.',
        passwordMustBeStronger: 'Your password must be stronger.',
        veryWeak: 'Very weak',
        weak: 'Weak',
        medium: 'Medium',
        acceptable: 'Acceptable',
        veryStrong: 'Very Strong',
        passwordStrength: 'Password Strength',
        passwordsDoNotMatch: 'Your passwords do not match.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        password: 'Mot de passe',
        newPassword: 'Nouveau mot de passe',
        confirmNewPassword: 'Confirmez le nouveau mot de passe',
        passwordConfirmation: 'Mot de passe actuel',
        currentPassword: 'Confirmation du mot de passe',
        passwordsMatch: 'Les mots de passe correspondent.',
        showPasswordOn: 'Afficher le mot de passe activé.',
        showPasswordOff: 'Afficher le mot de passe désactivé.',
        passwordMustBeStronger: 'Votre mot de passe doit être plus fort.',
        veryWeak: 'Très faible',
        weak: 'Faible',
        medium: 'Moyen',
        acceptable: 'Acceptable',
        veryStrong: 'Très Fort',
        passwordStrength: 'Force du mot de passe',
        passwordsDoNotMatch: 'Vos mots de passe ne correspondent pas.',
      };
    }
    return translation;
  };

  const SelectDropdown = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        ariaLabelIntro: 'Drop down selection menu for',
        selectionRequired: 'Selection is required.',
        currentSelection: 'Current selection is',
        remove: 'Remove',
        arrow: 'arrow',
        timezone: 'Time Zone',
        searchByCountry: 'Search by Country',
        searchIcon: 'Search icon',
        ariaTimezoneInput:
          'Filter the timezone dropdown menu below by country.',
        noMoreSelections: 'There are no more selections available.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        ariaLabelIntro: 'Menu déroulant de sélection pour',
        selectionRequired: 'La sélection est obligatoire.',
        currentSelection: 'La sélection actuelle est',
        remove: 'Retirer',
        arrow: 'flèche',
        timezone: 'Fuseau Horaire',
        searchByCountry: 'Recherche par pays',
        searchIcon: 'Icône de recherche',
        ariaTimezoneInput:
          'Filtrez le menu déroulant du fuseau horaire ci-dessous par pays.',
        noMoreSelections: `Il n'y a plus de sélections disponibles.`,
      };
    }
    return translation;
  };

  const SelectDropdownModal = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        noMoreSelections: 'There are no more selections available.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        noMoreSelections: `Il n'y a plus de sélections disponibles.`,
      };
    }
    return translation;
  };

  const SelectDropdownMultiple = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        noMoreSelections: 'No more selections available.',
        pleaseCompleteYourSelection: 'Please complete your selection',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        noMoreSelections: 'Plus aucune sélection disponible',
        pleaseCompleteYourSelection: `Veuillez compléter votre sélection`,
      };
    }
    return translation;
  };

  const NavigationArrows = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        continue: 'Continue',
        previous: 'Previous',
        finish: 'Finish',
        minimumRequirementsNotMet:
          'Minimum requirements to continue are not met. Please complete all required fields and options.',
        skip: 'Skip',
        exit: 'Exit',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        continue: 'Continuer',
        previous: 'Précédente',
        finish: 'Finir',
        minimumRequirementsNotMet: `Les exigences minimales pour continuer ne sont pas remplies. Veuillez remplir tous les champs et options obligatoires.`,
        skip: 'Sauter',
        exit: 'Sortie',
      };
    }
    return translation;
  };

  const AutocompletePlaces = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        goToLocation: 'Go to Location',
        autocompleteLocationLong: 'Autocomplete Location',
        autocompleteLocationShort: 'Autocomplete',
        eventSearchInputInstructions:
          'Enter the address for your event search location in this field. A list of matching addresses will appear below the input. Use the arrow up and arrow down keys to navigate the list. Press enter on the address to select it. Based on the address location, a marker will appear on Google maps along with a search radius, which can be increased or decreased below.',
        genericInputInstructions:
          'To automatically fill the address fields, enter the desired address  in this input. A list of matching addresses will appear in a list below. Use the arrow up and arrow down keys to navigate the list. Press enter on the address to select it.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        goToLocation: `Aller à l'emplacement`,
        autocompleteLocationLong: `Saisie semi-automatique de l'emplacement`,
        autocompleteLocationShort: `Autocomplétion`,
        eventSearchInputInstructions: `Saisissez l'adresse du lieu de recherche de votre événement dans ce champ. Une liste d'adresses correspondantes apparaîtra sous l'entrée. Utilisez les flèches vers le haut et vers le bas pour naviguer dans la liste. Appuyez sur Entrée sur l'adresse pour la sélectionner. En fonction de l'emplacement de l'adresse, un marqueur apparaîtra sur Google Maps avec un rayon de recherche, qui peut être augmenté ou diminué ci-dessous.`,
        genericInputInstructions: `Pour remplir automatiquement les champs d'adresse, entrez l'adresse souhaitée dans cette entrée. Une liste d'adresses correspondantes apparaîtra dans une liste ci-dessous. Utilisez les flèches vers le haut et vers le bas pour naviguer dans la liste. Appuyez sur Entrée sur l'adresse pour la sélectionner.`,
      };
    }
    return translation;
  };

  const BackToTop = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        backToTop: 'Back to Top',
        ariaBackToTop: 'Scroll back to the top of the page.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        backToTop: 'Retour au sommet',
        ariaBackToTop: 'Faites défiler vers le haut de la page.',
      };
    }

    return translation;
  };

  const SelectionList = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        sort: 'Sort',
        delete: 'Delete',
        ariaOrderDescending: 'order descending.',
        ariaOrderAsecending: 'order ascending.',
        ariaColumnHeading: 'column heading.',
        lastModified: 'Last Modified',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        sort: 'Trier',
        delete: 'Supprimer',
        ariaOrderDescending: 'ordre décroissant.',
        ariaOrderAsecending: 'ordre croissant.',
        ariaColumnHeading: 'en-tête de colonne.',
        lastModified: 'Dernière modification',
      };
    }

    return translation;
  };

  const WhatsThis = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        whatsThis: `What's this`,
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        whatsThis: `Qu'est-ce que c'est ça`,
      };
    }

    return translation;
  };

  const DragDropList = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        switchDurationToTime: 'Switch to Start/End Times',
        switchTimeToDuration: 'Switch to Duration',
        count: 'Count',
        maximumReached: 'You have reached the maximum number of agenda items.',
        addItem: 'Add Item',
        minutes: 'Minutes',
        startTime: 'Start Time',
        endTime: 'End Time',
        remove: 'Remove',
        moveUp: 'Move up',
        moveDown: 'Move down',
        agendaItemLong: 'Agenda Item . . .',
        agendaItemShort: 'Agenda Item...',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        switchDurationToTime: `Passer  à l'heure de début/de fin`,
        switchTimeToDuration: `Passer à la durée`,
        count: 'Compter',
        maximumReached: `Vous avez atteint le nombre maximum de points à l'ordre du jour.`,
        addItem: 'Ajouter un item',
        minutes: 'Minutes',
        start: 'Début',
        end: 'Fin',
        remove: 'supprimer',
        moveUp: 'Monter',
        moveDown: 'Descendre',
        agendaItemLong: `Point de l'ordre du jour . . .`,
        agendaItemShort: 'Article...',
      };
    }

    return translation;
  };

  const ImageCropper = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        sorry: 'Sorry!',
        screenSizeIncompatible:
          'This screen size is incompatible with the image cropper.',
        ariaDeleteImage: 'Delete image.',
        ariaInstructions:
          'Use the arrow keys to re-position your image. Use the plus and minus keys to adjust the zoom level.',
        ariaUploadImageInstructions: 'Upload an image. Image type:',
        convertingImageType: 'Converting image type...',
        remove: 'Remove',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        sorry: 'Désolé!',
        screenSizeIncompatible: `Cette taille d'écran est incompatible avec l'outil de recadrage d'image.`,
        ariaDeleteImage: `Supprimer l'image.`,
        ariaUploadImageInstructions: `Téléchargez une image. Type d'image:`,
        convertingImageType: `Conversion du type d'image`,
        remove: 'Retirer',
      };
    }
    return translation;
  };

  const Generic = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        accounts: 'Accounts',
        add: 'Add',
        addSelectedTags: 'Add Selected Tags',
        arrow: 'arrow',
        ascending: 'Ascending',
        bigTag: 'Big Tag',
        bigTags: 'Big Tags',
        cancel: 'Cancel',
        changeSortOrder: 'Change sort order',
        characterLimit: 'Character limit for this field is',
        checkmark: 'Checkmark',
        clearSelection: 'Clear selection',
        close: 'Close',
        closeList: 'Close list',
        currentTextCharacters: 'Current text characters is',
        descending: 'Descending',
        examine: 'Examine',
        fieldIsRequired: 'Field is required.',
        flag: 'flag',
        folder: 'folder',
        goTo: 'Go to',
        hours: 'hours',
        hour: 'hour',
        icon: 'icon',
        iconCreate: 'Create icon',
        iconInPerson: 'In Person icon',
        iconLoad: 'Load icon',
        iconReset: 'Reset icon',
        iconRestore: 'Restore icon',
        iconSave: 'Save icon',
        iconTrash: 'Trash icon',
        iconVirtual: 'Virtual icon',
        invalid: 'X',
        limitReached: 'Limit reached',
        minutes: 'minutes',
        minute: 'minute',
        network: 'Network',
        networkGroup: 'Network Group',
        nextPage: 'Next page',
        overLimit: 'You are over the tag limit',
        page: 'Page',
        prevPage: 'Previous page',
        remove: 'Remove',
        return: 'Return',
        search: 'Search',
        select: 'Select',
        send: 'Send',
        sortOrder: 'Sort order',
        subtract: 'Subtract',
        suggestionPrompt: 'Submit a Suggestion',
        tags: 'Tags',
        tinyTag: 'Tiny Tag',
        tinyTagGroup: 'Tiny Tag Group',
        tinyTags: 'Tiny Tags',
        valid: 'checkmark.',
        warning: 'Warning',
        refresh: 'Refresh',
        required: 'Required',
        someRequired: 'Some required',
        allRequired: 'All required',
        resetTableSize: 'Reset Table Size',
        attemptingToReconnect: 'Attempting to reconnect',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        accounts: 'Comptes',
        add: 'Ajouter ',
        addSelectedTags: 'Ajouter les balises sélectionnées',
        arrow: 'flèche',
        ascending: 'ascendant',
        bigTag: 'Grand Balise',
        bigTags: 'Grand Balises',
        cancel: 'Annuler',
        changeSortOrder: `Modifier l'ordre de tri`,
        characterLimit: 'La limite de caractères pour ce champ est',
        checkmark: 'Coche',
        clearSelection: 'Effacer la sélection',
        close: 'Fermer',
        closeList: 'Fermer la liste',
        currentTextCharacters: 'Les caractères de texte actuels sont',
        descending: 'descendant',
        examine: 'Examiner',
        fieldIsRequired: 'Champs requis.',
        flag: 'drapeau',
        folder: 'dossier',
        hours: 'heures',
        hour: 'heure',
        icon: 'Icône',
        iconCreate: 'Icône créer',
        iconInPerson: 'Icône En personne',
        iconLoad: 'Icône charger',
        iconReset: 'Icône réinitialiser',
        iconRestore: 'Icône restaurer',
        iconSave: 'Icône sauvegarder',
        iconTrash: 'Icône poubelle',
        iconVirtual: 'Icône virtuelle',
        invalid: 'X',
        limitReached: 'Limite atteinte',
        minutes: 'minutes',
        minute: 'minute',
        network: 'Réseau',
        networkGroup: 'Groupe de Réseau',
        nextPage: 'Page suivante',
        overLimit: 'Vous avez dépassé la limite de balises',
        page: 'Page',
        prevPage: 'Page précédente',
        remove: 'Retirer',
        return: 'Retour',
        search: 'Recherche',
        select: 'Sélectionner',
        send: 'Envoyer',
        sortOrder: 'Ordre de tri',
        subtract: 'Soustraire',
        suggestionPrompt: 'Soumettre une suggestion',
        tags: 'Balises',
        tinyTag: 'Petit Balise',
        tinyTagGroup: 'Petit groupe de balise',
        tinyTags: 'Petit Balises',
        valid: 'coche.',
        warning: 'Avertissement',
        refresh: 'Rafraîchir',
        required: 'Requis',
        someRequired: 'Certains requis',
        allRequired: 'Tout est requis',
        resetTableSize: 'Réinitialiser la taille du tableau',
        attemptingToReconnect: 'Tentative de reconnexion',
      };
    }
    return translation;
  };

  const TinyTagsModal = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        suggestionsWillImprove:
          'Suggestions will improve alongside user trends.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        suggestionsWillImprove: `Les suggestions s'amélioreront parallèlement aux tendances des utilisateurs.`,
      };
    }
    return translation;
  };

  const CustomTagsList = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        inputInstructions:
          'Tiny Tags input. Each entry will automatically be assigned a hashtag at the beginning. Tag limit is',
        tagGroup: 'Tag Group',
        tinyTagGroup: 'Tiny Tag Group',
        modifyTinyTagGroup: 'Modify Tiny Tag Group',
        removeTinyTagGroup: 'Remove Tiny Tag Group',
        addTagsFromYourList: 'Add Tags from your list',
        finish: 'Finish',
        ariaAddTag: 'Add tag.',
        seeSuggestionsList: 'See Suggestions List',
        suggestedTinyTags: 'Suggested Tiny Tags',
        skipToTinyTagGroups: 'Skip to Tiny Tag Groups',
        needTagsToGenerateSuggestions:
          'Please add Big Tags to generate suggestions',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        inputInstructions:
          'Entrée de Petit balises. Chaque entrée se verra automatiquement attribuer un hashtag au début. La limite de balises est',
        tagGroup: 'Groupe de balise',
        tinyTagGroup: 'Petit groupe de balise',
        modifyTinyTagGroup: 'Modifier le petit groupe de balises',
        removeTinyTagGroup: 'Supprimer le petit groupe de balises',
        addTagsFromYourList: 'Ajouter des balises de votre liste',
        finish: 'Finir',
        ariaAddTag: 'Ajouter une balise.',
        seeSuggestionsList: 'Voir la liste des suggestions',
        suggestedTinyTags: 'Petites balises suggérées',

        skipToTinyTagGroups: 'Passer aux petits groupes de balises',
        needTagsToGenerateSuggestions:
          'Veuillez ajouter des Big Tags pour générer des suggestions',
      };
    }
    return translation;
  };

  const NetworkGroups = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        networkGroup: 'Network Group',
        modifyNetworkGroup: 'Modify Network Group',
        removeNetworkGroup: 'Remove Network Group',
        addAccountFromYourList: 'Add account from your list',
        finish: 'Finish',
        ariaAddAccount: 'Add Account',
        skipToNetworkGroups: 'Skip to Network Groups',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        networkGroup: 'Groupe réseau',
        modifyNetworkGroup: 'Modifier le groupe de réseaux',
        removeNetworkGroup: 'Supprimer le groupe de réseaux',
        addAccountFromYourList: 'Ajouter un compte à partir de votre liste',
        finish: 'Finir',
        ariaAddAccount: 'Ajouter un compte',
        skipToNetworkGroups: 'Passer aux groupes réseau',
      };
    }
    return translation;
  };

  const GetDateTimeSummary = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        active: 'Active',
        finished: 'Finished',
        today: 'Today',
        day: 'Day',
        days: 'Days',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        active: 'Actif',
        finished: 'Fini',
        today: `Aujourd'hui`,
        day: 'Jour',
        days: 'Jours',
      };
    }
    return translation;
  };

  const FocusSkip = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        skip: 'Skip',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        skip: 'Passer',
      };
    }
    return translation;
  };

  const CalendarSimple = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        calendarSelectDay: 'Calendar date not selected',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        calendarSelectDay: 'Date du calendrier non sélectionnée',
      };
    }
    return translation;
  };

  const ApiStatusModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        somethingWentWrong: 'Something went wrong!',
        error: 'Error',
        success: 'Success',
        warning: 'Warning',
        automaticallyDismissed: 'This message will be automatically dismissed.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        somethingWentWrong: `Quelque chose s'est mal passé!`,
        error: 'Erreur',
        success: 'Succès',
        warning: 'Avertissement',
        automaticallyDismissed: `Ce message sera automatiquement supprimé.`,
      };
    }

    return translation;
  };

  const Spinner = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        loading: 'loading',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        loading: 'chargement',
      };
    }

    return translation;
  };

  const BigTagsComponent = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        addBigTags: 'Add Big Tags',
        inputInstructions: '',
        tagSelectionLimit: 'Big Tag selection limit is',
        add: 'Add',
        remove: 'Remove',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        addBigTags: 'Adjoutor de Grand Balises',
        inputInstructions: '',
        tagSelectionLimit: 'La limite de sélection des Grand Balises est',
        add: 'Ajouter',
        remove: 'Retirer',
      };
    }

    return translation;
  };

  const DistanceToggle = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        switchToMiles: 'Switch to Miles',
        switchToKilometers: 'Switch to Kilometers',
        switch: 'Switch',
        ariaDecrease: 'Decrease.',
        ariaIncrease: 'Increase.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        switchToMiles: 'Changer en Miles',
        switchToKilometers: 'Changer to Kilomètres',
        switch: 'Changer',
        ariaDecrease: 'Diminuer.',
        ariaIncrease: 'Augmenter.',
      };
    }

    return translation;
  };

  const LoadingScreen = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        verifying: 'Verifying',
        loading: 'Loading',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        verifying: 'Vérification',
        loading: 'Chargement',
      };
    }

    return translation;
  };

  const FollowButton = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        clickToFollow: 'Click to Follow',
        clickToUnfollow: 'Click to Unfollow',
        follow: 'Follow',
        checkmark: 'Checkmark',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        clickToFollow: 'Cliquez pour suivre',
        clickToUnfollow: 'Cliquez pour ne plus suivre',
        follow: 'Suivre',
        checkmark: 'Coche',
      };
    }

    return translation;
  };

  const SearchNetworkInput = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        noMatches: 'No matches',
        ariaInstructions: `Enter a MyndFull organizer's first and or last name, then select the organizer in the list below the input field.`,
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        noMatches: 'Pas de correspondance',
        ariaInstructions: `Entrez le prénom et ou le nom d'un organisateur MyndFull, puis sélectionnez l'organisateur dans la liste sous le champ de saisie.`,
      };
    }

    return translation;
  };

  const EventStatusBarSwitch = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        inPersonToVirtual:
          'Requesting to transfer attendance from in-person to virtual',
        virtualToInPerson:
          'Requesting to transfer attendance from virtual to in-person',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        inPersonToVirtual:
          'Demander le transfert de la participation du présentiel au virtuel',
        virtualToInPerson:
          'Demander le transfert de la participation du virtuel au présentiel',
      };
    }
    return translation;
  };

  const SaveButton = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        save: 'Save',
        ariaSaveUnavailable:
          'Save Button. No changed data to save, or requirements are not met.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        save: 'Sauvegarder',
        ariaSaveUnavailable:
          'Bouton Sauvegarder. Aucune donnée modifiée à enregistrer, ou les exigences ne sont pas remplies.',
      };
    }
    return translation;
  };

  const ReportModal = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        close: 'Close',
        reportEvent: 'Report Event',
        otherViolationTitle: 'Violation Title',
        selectViolation: 'Select Violation:',
        selectBrowser: 'Select Browser:',
        describeViolation:
          'Please provide a detailed description of the violation:',
        describeProblem:
          'Please provide a detailed description of the problem:',
        description: 'Description',
        contactInformation: 'Contact Information',
        name: 'Name',
        email: 'Email',
        submitReport: 'Submit Report',
        reportTechnicalProblem: 'Report Technical Problem',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        close: 'Fermer',
        reportEvent: 'Signaler un événement',
        otherViolationTitle: `Titre de la violation`,
        selectViolation: 'Sélectionnez une infraction:',
        selectBrowser: 'Sélectionnez le navigateur:',
        describeViolation:
          'Veuillez fournir une description détaillée de la violation:',
        describeProblem:
          'Veuillez fournir une description détaillée du problème:',
        description: 'Description',
        contactInformation: 'Coordonnées de contact',
        name: 'Nom',
        email: 'Email',
        submitReport: 'Soumettre le rapport',
        reportTechnicalProblem: 'Signaler un Problème Technique',
      };
    }
    return translation;
  };

  const AutocorrectKeywords = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        keywords: 'Keywords',
        uploadToAutodetect: 'Upload .docx / .pdf to Autodetect',
        upload: 'Upload',
        toAutodetect: 'to Autodetect',
        maximumSize: 'Maximum Size 5 MB',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        keywords: 'Mots clés',
        uploadToAutodetect:
          'Télécharger .docx / .pdf vers la détection automatique',
        upload: 'Télécharger',
        toAutodetect: 'détecter automatiquement',
        maximumSize: 'Taille maximale 5 Mo',
      };
    }
    return translation;
  };

  return {
    Generic: Generic(),
    TinyTagsModal: TinyTagsModal(),
    ButtonSwitch: ButtonSwitch(),
    BigTagsComponent: BigTagsComponent(),
    EmailInput: EmailInput(),
    PhoneNumberInput: PhoneNumberInput(),
    PasswordInputs: PasswordInputs(),
    SelectDropdown: SelectDropdown(),
    NavigationArrows: NavigationArrows(),
    AutocompletePlaces: AutocompletePlaces(),
    BackToTop: BackToTop(),
    SelectionList: SelectionList(),
    WhatsThis: WhatsThis(),
    DragDropList: DragDropList(),
    ImageCropper: ImageCropper(),
    GetDateTimeSummary: GetDateTimeSummary(),
    ApiStatusModal: ApiStatusModal(),
    Spinner: Spinner(),
    CustomTagsList: CustomTagsList(),
    DistanceToggle: DistanceToggle(),
    FocusSkip: FocusSkip(),
    SelectDropdownModal: SelectDropdownModal(),
    SelectDropdownMultiple: SelectDropdownMultiple(),
    LoadingScreen: LoadingScreen(),
    FollowButton: FollowButton(),
    NetworkGroups: NetworkGroups(),
    SearchNetworkInput: SearchNetworkInput(),
    EventStatusBarSwitch: EventStatusBarSwitch(),
    SaveButton: SaveButton(),
    ReportModal: ReportModal(),
    CalendarSimple: CalendarSimple(),
    AutocorrectKeywords: AutocorrectKeywords(),
  };
};

export default useLanguageComponents;
