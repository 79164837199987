import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
//Components
import PhoneInput from '../../../../../components/TextArea/PhoneInput';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//Utility

const Phone = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const {
    accountPhoneNumber: loadPhoneNumber,
    accountCountryCode: loadCountryCode,
    accountPersonal,
  } = useAccountReduxHandlers();

  //Component state
  const [phoneNumber, setPhoneNumber] = useState(loadPhoneNumber);
  const [countryCode, setCountryCode] = useState(loadCountryCode);
  const [phoneNumberSaveValid, setPhoneNumberSaveValid] = useState(false);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="phoneLabel"
      >
        {Account.account.phone}
      </label>

      <div className="h24" />
      <PhoneInput
        id={'accountPhoneInput'}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        saveValid={phoneNumberSaveValid}
        setSaveValid={setPhoneNumberSaveValid}
        loadValue={loadPhoneNumber}
        loadCountryCode={loadCountryCode}
      />
      <div className="h24" />
      <SaveButton
        saveValid={phoneNumberSaveValid}
        handler={() =>
          saveAccountChange(
            {
              personal: {
                ...accountPersonal,
                countryCode,
                phoneNumber,
              },
            },
            'phone'
          )
        }
        saveItemLanguage={Account.account.phone}
        isLoading={loadingSaveBtnUI === 'phone'}
      />
      <div className="h36" />
    </div>
  );
};

export default Phone;
