import { createContext, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';
import useAdminSupportEventVerificationHandlers from '../../features/admin/support/EventVerification/useAdminSupportEventVerificationHandlers';
import { useAdminAccountReduxHandlers } from '../../features/admin/account/useAdminAccountHandlers';
import useApiStatus from '../useApiStatus';

const EventVerificationsSocketContext = createContext({});

export const EventVerificationsSocketProvider = ({ children }) => {
  //Hooks
  const { handleSocketEventVerificationsData } =
    useAdminSupportEventVerificationHandlers();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleError } = useApiStatus();

  const [eventVerificationSocketStatus, setEventVerificationSocketStatus] =
    useState('closed');

  //variables
  const socketRef = useRef(null);

  const connectEventVerificationsSocket = () => {
    if (!socketRef.current && eventVerificationSocketStatus !== 'connected') {
      let newSocket;

      if (process.env.REACT_APP_ENV === 'development') {
        newSocket = io('http://localhost:5000/ws/eventVerification', {
          query: { adminAccountId },
        });
      } else {
        newSocket = io('https://myndfull.com/ws/eventVerification', {
          withCredentials: true,
          query: { adminAccountId },
        });
      }

      newSocket.on('connect', () => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(
            `Dev: You connected with the EventVerifications Socket, id: ${newSocket.id}`
          );
        }

        setEventVerificationSocketStatus('connected');
      });

      newSocket.on('eventVerificationData', (eventVerificationData) => {
        handleSocketEventVerificationsData(eventVerificationData);
      });

      newSocket.on('connect_error', (error) => {
        setEventVerificationSocketStatus('pending');
        if (process.env.NODE_ENV === 'development') {
          console.error(
            'Dev: Error connecting to EventVerifications Socket:',
            error
          );
        }
      });

      newSocket.on('error', (error) => {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TranslationRoomSocketProvider.js',
        });
      });

      newSocket.on('disconnect', (error) => {
        console.log('Event Verification socket disconnected.');
        setEventVerificationSocketStatus('pending');
      });

      return (socketRef.current = newSocket);
    }
  };

  const getEventVerificationsSocket = () => {
    return socketRef.current;
  };

  const closeEventVerificationsSocket = () => {
    const socket = socketRef.current;
    if (socket) {
      socket.close();
      socketRef.current = null;
      setEventVerificationSocketStatus('closed');

      if (process.env.NODE_ENV === 'development') {
        console.error('Dev: Closed EventVerifications Socket:');
      }
    }
  };

  return (
    <EventVerificationsSocketContext.Provider
      value={{
        connectEventVerificationsSocket,
        closeEventVerificationsSocket,
        getEventVerificationsSocket,
        eventVerificationSocketStatus,
      }}
    >
      {children}
    </EventVerificationsSocketContext.Provider>
  );
};

export const useEventVerificationsSocket = () => {
  return useContext(EventVerificationsSocketContext);
};
