export const priceLocaleConversion = (
  price,
  contentLanguage,
  currency,
  currencyLettersOn
) => {
  if (!contentLanguage || !currency) return;
  let formattedPrice;

  formattedPrice = new Intl.NumberFormat(contentLanguage, {
    style: 'currency',
    currency: currency,
  }).format(price / 100);

  if (currencyLettersOn) {
    if (currency === 'cad') {
      if (contentLanguage === 'en-CA') {
        formattedPrice = `C${formattedPrice}`;
      }
      if (contentLanguage === 'fr-CA') {
        formattedPrice = `${formattedPrice} CA`;
      }
    }
  }
  return formattedPrice;
};
