import { useState, useEffect } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';
//Components

//Utility

const ButtonSwitchHOrder = ({
  id,
  title,
  handler,
  stateHO,
  ariaDescriptiveLabel,
  horizontal,
}) => {
  //Hooks
  const { ButtonSwitch } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //Component state

  //UI state
  const [active, setActive] = useState(false);
  const [tapHighlightText, setTapHighlightText] = useState(false);
  const [animationsOn, setAnimationsOn] = useState(false);

  //UI functions
  useEffect(() => {
    const animationTimer = setTimeout(() => {
      if (!animationsOn) {
        setAnimationsOn(true);
      }
    }, 500);

    return () => clearTimeout(animationTimer);
  }, []); //prevent the button's animations on load

  //Component functions
  useEffect(() => {
    if (stateHO) {
      setActive(true);
    }
    if (!stateHO) {
      setActive(false);
    }
  }, [stateHO]);

  function handleClickFunction() {
    if (!active) {
      setActive(true);
      handler(true);
    }
    if (active) {
      setActive(false);
      handler(false);
    }
  }

  function handleClick() {
    handleMobileTap(
      [() => setTapHighlightText(true), () => setTapHighlightText(false)],
      [() => handleClickFunction()]
    );
  }

  return (
    <div
      className={`btn-switch__wrapper no-select ${
        horizontal ? 'btn-switch-horizontal' : ''
      }`}
    >
      <button
        id={id}
        name={id}
        type="checkbox"
        className={`btn-switch access-ob access-o6 ${
          active ? ' btn-switch--active' : ''
        }`}
        onClick={() => handleClick()}
        aria-label={ariaDescriptiveLabel ? ariaDescriptiveLabel : title}
        role="switch"
        aria-checked={active}
      >
        <div
          className={`btn-switch__circle ${
            active ? 'btn-switch__circle--active' : ''
          }`}
          style={{ transition: `${animationsOn ? '0.4s' : '0s'}` }}
        />
        {active ? (
          <p className="btn-switch__text-btn">{ButtonSwitch.yes}</p>
        ) : (
          <p className="btn-switch__text-btn">{ButtonSwitch.no}</p>
        )}
      </button>
      <p
        className={`btn-switch__description highlight-it-theme no-select ${
          tapHighlightText ? 'highlight-it-theme--tap' : ''
        }`}
        onClick={() => handleClick()}
      >
        {title}
      </p>
    </div>
  );
};

export default ButtonSwitchHOrder;
