import { useEffect, useState } from 'react';

//hooks
import useSettings from '../../../../../context/useSettings.js';
import useLanguageData from '../../../../../language/useLanguageData';
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//componets
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import Spinner from '../../../../../components/Spinner/Spinner';
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utility
import { fr_voiceBob } from '../../audio/fr_voiceBob.js';
import { fr_voiceJudy } from '../../audio/fr_voiceJudy.js';
import { en_voiceBob } from '../../audio/en_voiceBob.js';
import { en_voiceJudy } from '../../audio/en_voiceJudy.js';
import { es_voiceBob } from '../../audio/es_voiceBob.js';
import { es_voiceJudy } from '../../audio/es_voiceJudy.js';

import { image_create_list } from '../../../../../assets/images';
import {
  arrow_long_h,
  checkmark_green,
  img_play,
  return_curved_arrow,
} from '../../../../../assets/icons';

const CreateRoomSpeakersVoiceAndLanguage = () => {
  //hooks
  const {
    speakersData,
    setSpeakersData,
    roomLanguages,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
  } = useTranslationRoomSocket();
  const { CreateRoomSpeakersVoiceAndLanguage } = useLanguageTranslationRoom();
  const { VoiceOpts, TranslationLanguageOpts } = useLanguageData();
  const { handleMobileTap, contentLanguage } = useSettings();

  //state
  const [currentSpeakerIndex, setCurrentSpeakerIndex] = useState(0);
  const [speakerContentIsLoaded, setSpeakerContentIsLoaded] = useState(false);
  const [roomLanguageOpts, setRoomLanguageOpts] = useState([]);
  const [speakerCompletions, setSpeakerCompletions] = useState([]);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);
  const [tapHighlightPlayBtn, setTapHighlightPlayBtn] = useState();
  const [testAudioIsPlaying, setTestAudioIsPlaying] = useState(false);

  //Initialize
  useEffect(() => {
    const translationOpts = TranslationLanguageOpts;
    const filteredOpts = Object.keys(translationOpts)
      .filter((key) => roomLanguages.includes(key))
      .reduce((obj, key) => {
        obj[key] = translationOpts[key];
        return obj;
      }, {});
    setRoomLanguageOpts(filteredOpts);
  }, [contentLanguage, speakersData]);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSpeakerContentIsLoaded(true);
    }, 250);

    return () => clearTimeout(timerId);
  }, [speakerContentIsLoaded]);

  useEffect(() => {
    if (testAudioIsPlaying) {
      const timer = setTimeout(() => {
        setTestAudioIsPlaying(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [testAudioIsPlaying]);

  //functions
  useEffect(() => {
    if (speakersData) {
      let newSpeakerCompletions = [...speakerCompletions];
      speakersData?.forEach((speaker, index) => {
        if (
          speaker?.languageOpt &&
          speaker?.languageOpt !== 'select' &&
          speaker?.voiceOpt &&
          speaker?.voiceOpt !== 'select'
        ) {
          newSpeakerCompletions[index] = true;
        } else {
          newSpeakerCompletions[index] = false;
        }
        return setSpeakerCompletions(newSpeakerCompletions);
      });
    }
  }, [speakersData]);

  function handleNext() {
    if (currentSpeakerIndex < 9) {
      setSpeakerContentIsLoaded(false);
      let newSpeakerIndex = currentSpeakerIndex + 1;
      return setCurrentSpeakerIndex(newSpeakerIndex);
    }
  }
  function handlePrevious() {
    if (currentSpeakerIndex > 0) {
      setSpeakerContentIsLoaded(false);
      let newSpeakerIndex = currentSpeakerIndex - 1;
      return setCurrentSpeakerIndex(newSpeakerIndex);
    }
  }

  function handleTestPlayBtn(speechLang, voiceOption) {
    if (!testAudioIsPlaying) {
      setTestAudioIsPlaying(true);

      if (speechLang === 'en-CA' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${en_voiceBob}`;
        audio.play();
      }

      if (speechLang === 'en-CA' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${en_voiceJudy}`;
        audio.play();
      }

      if (speechLang === 'fr-CA' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${fr_voiceBob}`;
        audio.play();
      }

      if (speechLang === 'fr-CA' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${fr_voiceJudy}`;
        audio.play();
      }
      if (speechLang === 'es' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${es_voiceBob}`;
        audio.play();
      }
      if (speechLang === 'es' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${es_voiceJudy}`;
        audio.play();
      }
    }
  }

  function handleLanguageOpt(key) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[currentSpeakerIndex].languageOpt = key || 'select';
    setSpeakersData(newSpeakersData);
  }

  function handleVoiceOpt(key) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[currentSpeakerIndex].voiceOpt = key || 'select';
    setSpeakersData(newSpeakersData);
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations-selection__container ${
          renderSpinner ? 'opacity-0' : ''
        } `}
      >
        <img
          src={image_create_list}
          alt="img"
          style={{ height: '200px' }}
          className="svg mrg-t24"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p
            className="fs21 fwsb mrg-t24 text-center access-ob access-o6"
            tabIndex="0"
            aria-label={
              CreateRoomSpeakersVoiceAndLanguage.speakerLanguageAndVoice
            }
          >
            {CreateRoomSpeakersVoiceAndLanguage.speakerLanguageAndVoice}
          </p>

          <div className="translations-speaker-btn__container">
            <button
              className={`translations-speaker-btn mrg-auto-right highlight-i-lgt ${
                currentSpeakerIndex === 0
                  ? 'opacity-0 pointer-default disable-select'
                  : ''
              }`}
              onClick={handlePrevious}
              aria-hidden={currentSpeakerIndex === 0 ? true : false}
              tabIndex={currentSpeakerIndex === 0 ? '-1' : '0'}
            >
              <img
                src={arrow_long_h}
                alt={CreateRoomSpeakersVoiceAndLanguage.next}
                className="reverse"
              />
            </button>
            <p
              className="fs21 fwsb mrg-l24 mrg-r24 text-center access-ob access-o6"
              tabIndex="0"
            >
              {speakersData?.map((speaker, index) => {
                if (index === currentSpeakerIndex) {
                  return `${
                    speaker?.name
                      ? speaker?.name
                      : `${CreateRoomSpeakersVoiceAndLanguage.speaker} ${
                          index + 1
                        }`
                  }`;
                }
              })}
            </p>
            <button
              className={`translations-speaker-btn  mrg-auto-left highlight-i-lgt ${
                currentSpeakerIndex === speakersData?.length - 1
                  ? 'opacity-0 pointer-default disable-select'
                  : ''
              }`}
              onClick={handleNext}
              aria-hidden={
                currentSpeakerIndex === speakersData?.length - 1 ? true : false
              }
              tabIndex={
                currentSpeakerIndex === speakersData?.length - 1 ? '-1' : '0'
              }
            >
              <img
                src={arrow_long_h}
                alt={CreateRoomSpeakersVoiceAndLanguage.next}
              />
            </button>
          </div>

          <div className="flex-row full-width flex-center mrg-t12">
            {speakersData?.map((speaker, index) =>
              speakerCompletions[index] === true ? (
                <img
                  alt={CreateRoomSpeakersVoiceAndLanguage.checkmark}
                  src={checkmark_green}
                  className={`access-ob access-o6 ${
                    index !== 0 ? 'mrg-l6' : ''
                  }`}
                  key={`checkmark-${index}`}
                />
              ) : (
                <div
                  className={`dot ${
                    currentSpeakerIndex === index
                      ? 'bg-color-theme'
                      : 'bg-color-lightgray'
                  } ${index !== 0 && 'mrg-l6'} access-ob access-o6`}
                  key={`dot-${index}`}
                />
              )
            )}
          </div>

          <div className="divider full-width mrg-t24 mrg-b12" />

          <div className="h24" />
          {speakersData &&
            speakersData?.map((speakerData, index) => {
              if (currentSpeakerIndex === index) {
                if (!speakerContentIsLoaded) {
                  return (
                    <Spinner minHeight={'274px'} key={`Spinner-${index}`} />
                  );
                } else {
                  return (
                    <div
                      key={`speaker-info-${index}`}
                      className={`full-width ${
                        !speakerContentIsLoaded ? 'opacity-0' : ''
                      }`}
                      style={{ minHeight: '274px' }}
                    >
                      <div className={`flex-column full-width `}>
                        <SelectDropdown
                          id={'speakerLanguage'}
                          list={roomLanguageOpts}
                          handleSelection={handleLanguageOpt}
                          internalLabel={
                            CreateRoomSpeakersVoiceAndLanguage.speakersLanguage
                          }
                          asterisk={true}
                          defaultKey={
                            speakerData?.languageOpt !== 'select'
                              ? speakerData?.languageOpt
                              : 'select'
                          }
                        />

                        <SelectDropdown
                          autoUpdate={true}
                          id={'voiceOptions'}
                          list={VoiceOpts}
                          handleSelection={handleVoiceOpt}
                          internalLabel={
                            CreateRoomSpeakersVoiceAndLanguage.voiceSelection
                          }
                          defaultKey={
                            speakerData?.voiceOpt !== 'select'
                              ? speakerData?.voiceOpt
                              : 'select'
                          }
                          asterisk={true}
                          tempDisable={
                            !speakerData?.languageOpt ||
                            speakerData?.languageOpt === 'select'
                          }
                          temporaryDisableMessage={
                            'speakerLanguageSelectionRequired'
                          }
                          customWrapper={`mrg-t24`}
                        />

                        <button
                          className={`play-btn  mrg-t24 access-ob access-o6 pad-12 highlight-bthin-theme ${
                            tapHighlightPlayBtn === true
                              ? 'highlight-b-theme--tap'
                              : ''
                          }`}
                          onClick={
                            !speakerData?.voiceOpt ||
                            speakerData?.voiceOpt === 'select'
                              ? null
                              : () =>
                                  handleMobileTap(
                                    [
                                      () => setTapHighlightPlayBtn(true),
                                      () => setTapHighlightPlayBtn(false),
                                    ],
                                    [
                                      () =>
                                        handleTestPlayBtn(
                                          speakerData?.languageOpt,
                                          speakerData?.voiceOpt
                                        ),
                                    ]
                                  )
                          }
                        >
                          {!testAudioIsPlaying ? (
                            <p className="fwn fs18 mrg-b3">
                              {CreateRoomSpeakersVoiceAndLanguage.testVoice}
                            </p>
                          ) : (
                            <p className="fwn fs18 mrg-b3">
                              {CreateRoomSpeakersVoiceAndLanguage.playing}
                            </p>
                          )}
                          <img
                            src={img_play}
                            alt="img"
                            className="svg filter-gray mrg-l6"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  );
                }
              }
            })}

          <ItemButton
            text={CreateRoomSpeakersVoiceAndLanguage.continue}
            handler={() => handleCreatePageChange(6)}
            customWrapper={'mrg-t24 full-width'}
            iconOn={true}
            icon={return_curved_arrow}
            iconCustomClass={'reverse'}
            unavailable={speakerCompletions.includes(false)}
          />

          <div className="divider full-width mrg-t36 mrg-b12" />
          <div className="h24" />

          <ItemButton
            text={CreateRoomSpeakersVoiceAndLanguage.back}
            handler={() => handleCreatePageChange(4)}
            customWrapper={'full-width'}
          />
          <div className="h72" />
        </div>
      </div>
    </>
  );
};

export default CreateRoomSpeakersVoiceAndLanguage;
