import { useState, useEffect } from 'react';

//Hooks
import useLanguageComponents from '../../../language/useLanguageComponents';
import useGenericModal from '../../../context/useGenericModal';
import useSettings from '../../../context/useSettings';
//Components

//Utility
import { cancel } from '../../../assets/icons';

const ConfirmationModal = ({
  handler,
  headingText,
  buttonPosText,
  buttonNegText,
  focusId,
  buttonNegRed,
  buttonPosGreen,
  reverseColors,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap, width } = useSettings();

  //UI state
  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);
  const [tapHighlightPosBtn, setTapHighlightPosBtn] = useState(false);
  const [tapHighlightNegBtn, setTapHighlightNegBtn] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (focusId) {
      setCloseModalFocusId(`${focusId}`);
    }
  }, []); //set id for focus when modal closes

  useEffect(() => {
    const floatModalEl = document.getElementById(`float-modal`);
    if (floatModalEl) {
      floatModalEl.focus();
    }
  }, []);

  return (
    <div
      className="float-modal access-ow access-o12"
      tabIndex="0"
      id="float-modal"
    >
      <button
        className={`close-btn highlight-i-lgr access-ob access-o6 mrg-auto-left full-width 
        ${tapHighlightCloseBtn ? 'highlight-i-lgr--tap' : ''}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightCloseBtn(true),
              () => setTapHighlightCloseBtn(false),
            ],
            [() => handleCloseModal()]
          )
        }
        type="button"
        title={Generic.close}
      >
        <img src={cancel} alt={Generic.close} className="svg" />
      </button>

      <p className="fs21 fwn pad-t12 pad-b24 text-center">{headingText}</p>

      <ul
        className="flex-row flex-column--ph list-style-none space-evenly mrg-b24 mrg-auto-lr"
        id="list__container"
      >
        <li>
          <button
            className={`confirmation-btn br--small access-ob access-o6
            ${width <= 480 ? 'mrg-b24' : ''} 
            ${
              buttonNegRed && !reverseColors
                ? 'highlight-t-white highlight-bg-red--hover confirmation--neg'
                : ''
            }
            ${
              buttonPosGreen && reverseColors
                ? 'confirmation--pos highlight-t-white highlight-bg-green--hover'
                : 'highlight-bthin-theme'
            }
            ${
              tapHighlightNegBtn && buttonNegRed && !reverseColors
                ? 'highlight-bg-red--tap'
                : tapHighlightNegBtn
                ? 'highlight-b-theme--tap'
                : ''
            }
            ${
              tapHighlightPosBtn && buttonPosGreen && reverseColors
                ? 'highlight-bg-green--tap'
                : tapHighlightPosBtn
                ? 'highlight-b-theme--tap'
                : ''
            }
            `}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightNegBtn(true),
                  () => setTapHighlightNegBtn(false),
                ],
                [() => handleCloseModal()]
              )
            }
          >
            {buttonNegText}
          </button>
        </li>
        <li>
          <button
            className={`confirmation-btn access-ob access-o6 
            ${width <= 480 ? '' : 'mrg-l24'} 
            ${
              buttonPosGreen && !reverseColors
                ? 'confirmation--pos highlight-t-white highlight-bg-green--hover'
                : 'highlight-bthin-theme'
            }
            ${
              buttonNegRed && reverseColors
                ? 'highlight-t-white highlight-bg-red--hover confirmation--neg'
                : ''
            }
            ${
              tapHighlightPosBtn && buttonPosGreen && !reverseColors
                ? 'highlight-bg-green--tap'
                : tapHighlightPosBtn
                ? 'highlight-b-theme--tap'
                : ''
            }
            ${
              tapHighlightNegBtn && buttonNegRed && reverseColors
                ? 'highlight-bg-red--tap'
                : tapHighlightNegBtn
                ? 'highlight-b-theme--tap'
                : ''
            }`}
            onClick={(e) =>
              handleMobileTap(
                [
                  () => setTapHighlightPosBtn(true),
                  () => setTapHighlightPosBtn(false),
                ],
                [() => handler(), () => handleCloseModal()]
              )
            }
          >
            {buttonPosText}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ConfirmationModal;
