import React, { useState, useEffect } from 'react';

//Hooks
import useGenericModal from '../../context/useGenericModal';
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';

//Components
import SelectDropdownModal from '../Modal/ModalComponents/SelectDropdownModal';

//Utility
import { dropdown_arrow } from '../../assets/icons/index';
import { trash } from '../../assets/icons';
import useApiStatus from '../../context/useApiStatus';
//notable behavior: with mutliple dropdown, there is no need for selection text, and when switching selections the processing of the lists at parent level causes text to flicker as it changes to selection default text and back to selection. multiDropdown prop is used to avoid rendering 'select' text and provides empty string instead.
const SelectDropdown = React.memo(
  ({
    ariaTitle,
    asterisk,
    autoUpdate,
    defaultKey,
    filteredList,
    fullWidth,
    handleSelection,
    handleSelection2,
    id,
    internalLabel,
    list,
    modalLabel,
    multiDropdownRemoveButton,
    multiDropdown,
    mutliDropdownLabel,
    multiDropdownIndex,
    multiDropdownInitialized,
    title,
    titleAsterisk,
    tempDisable,
    temporaryDisableMessage,
    customWrapper,
    secondButtonToggle,
    secondButtonId,
    secondButtonClass,
    secondButtonTitle,
    secondButtonImg,
    secondButtonFunction,
    secondButtonPadding,
  }) => {
    //Hooks
    const {
      openedDropdownMenuEl,
      setOpenedDropdownMenuEl,
      width,
      handleMobileTap,
    } = useSettings();
    const { accountContentLanguage } = useAccountReduxHandlers();
    const { SelectDropdown } = useLanguageComponents();
    const { setGenericModal, genericModal } = useGenericModal();
    const { handleWarning } = useApiStatus();

    //Component state
    const [listOpened, setListOpened] = useState(false);
    const [modifiedList, setModifiedList] = useState();
    const [selectionKey, setSelectionKey] = useState(defaultKey);
    const [selectionValue, setSelectionValue] = useState();
    const [selectionIndex, setSelectionIndex] = useState();

    //UI state
    const [showOpts, setShowOpts] = useState(false);
    const [showRemoveBtn, setShowRemoveBtn] = useState(false);
    const [tapHighlightMainBtn, setTapHighlightMainBtn] = useState(false);

    //Component variables
    const mainButton = document.getElementById(`${id}-dropdown-main`);
    const showoptsEl = document.getElementById(`showopts-${id}`);
    let dropdownBtn;

    //Initialize functions
    useEffect(() => {
      if (defaultKey === null && selectionKey && selectionValue) {
        setSelectionKey(null);
        setSelectionValue(null);
      }
      /*eslint-disable-next-line*/
    }, [defaultKey]);

    ////UI functions
    useEffect(() => {
      if (showOpts) {
        setOpenedDropdownMenuEl(`${id}`);
      }
      /*eslint-disable-next-line*/
    }, [showOpts]); //two useEffects connected to useSettings, ensures that only one dropdown menu is opened at a time

    useEffect(() => {
      if (openedDropdownMenuEl !== `${id}`) {
        handleOverlayClick();
      }
      /*eslint-disable-next-line*/
    }, [openedDropdownMenuEl]);

    useEffect(() => {
      if (showoptsEl) {
        showoptsEl.scrollTo({
          top: 0,
          behavior: 'instant',
        });
      }
    }, [showoptsEl]); //resets component scroll

    useEffect(() => {
      if (!genericModal && listOpened) {
        mainButton.focus();
        setListOpened(false);
      }
    }, [genericModal]); //sets focus back on See Full List btn after full list modal closes

    useEffect(() => {
      if (
        selectionKey === 'select' &&
        mainButton &&
        multiDropdown &&
        multiDropdownInitialized
      ) {
        mainButton.focus();
      }
    }, [mainButton, selectionKey]); //sets focus on main button when selectionKey is select (new dropdown is created) for multiDropdown

    //Component functions
    useEffect(() => {
      if (autoUpdate) {
        if (selectionKey !== defaultKey) {
          setSelectionKey(defaultKey);
        }
      }
      /*eslint-disable-next-line*/
    }, [autoUpdate, defaultKey]); //specific data edge cases, auto updates with changing values; used for autocomplete on address forms.

    useEffect(() => {
      const value = list[selectionKey];
      setSelectionValue(value);
      /*eslint-disable-next-line*/
    }, [accountContentLanguage]); //handle user changes language

    //central data logic
    useEffect(() => {
      if (selectionKey && list) {
        const newSelectionValue = list[selectionKey];
        setSelectionValue(newSelectionValue);
      }

      if (!selectionKey && list) {
        setSelectionValue();
      }

      /*eslint-disable-next-line*/
    }, [selectionKey, list]); //sets selection value based on selectionKey

    useEffect(() => {
      if (filteredList) {
        setModifiedList(filteredList); //multipleDD
      } else if (selectionKey) {
        let newList = Object.keys(list)
          .filter((key) => key !== selectionKey)
          .reduce((acc, key) => {
            acc[key] = list[key];
            return acc;
          }, {});
        setModifiedList(newList);
      } else {
        setModifiedList(list);
      }
      /*eslint-disable-next-line*/
    }, [list, filteredList]); //remove selection from list (for multiple SDD)

    function handleOverlayClick() {
      setSelectionIndex();
      setShowOpts(false);
    }

    function handleKeyDown(e) {
      let listLength = Object.entries(modifiedList).length;

      if (e.key === 'ArrowDown') {
        e.preventDefault(); //keep separate to prevent scroll move

        if (selectionIndex < listLength - 1) {
          setSelectionIndex(selectionIndex + 1);
          let btn = document.getElementById(`btn-${id}-${selectionIndex + 1}`);
          //need to check btn because focus to input will cause error
          if (btn) {
            btn.focus();
          }
        }
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault(); //keep separate to prevent scroll move
        if (selectionIndex > 0) {
          setSelectionIndex(selectionIndex - 1);
          let btn = document.getElementById(`btn-${id}-${selectionIndex - 1}`);
          //need to check btn because focus to input will cause error
          if (btn) {
            btn.focus();
          }
        }
      }

      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
        if (selectionIndex === 0 || !selectionIndex) {
          setSelectionIndex(0);
          let btn = document.getElementById(`btn-${id}-0`);
          if (btn) {
            btn.focus();
          }
        }
      }
    }

    function handleOnMouseEnter(index) {
      setSelectionIndex(index);
      const btnElement = document.getElementById(`${`li-btn-${id}-${index}`}`);
      btnElement.focus();
      btnElement.scrollTo({
        top: 0,
        behavior: 'instant',
      }); //resets component scroll
    }

    function handleRemove(removeKey) {
      if (multiDropdownRemoveButton) {
        multiDropdownRemoveButton(removeKey);
      } else {
        handleDropdown();
      }
    }

    function handleDropdown(key) {
      handleSelection(key, selectionKey, multiDropdownIndex);
      setSelectionKey(key);
      setShowOpts(false);
      setSelectionIndex();

      if (handleSelection2) {
        handleSelection2(key);
      }
    }

    function handleMainBtnClick(e) {
      e.preventDefault();
      handleMobileTap(
        [
          () => setTapHighlightMainBtn(true),
          () => setTapHighlightMainBtn(false),
        ],
        [() => handleMainBtn()]
      );
    }

    function handleMainBtn() {
      if (width > 480) {
        setShowOpts(!showOpts);
        setSelectionIndex();
      } else {
        setListOpened(true);
        setSelectionIndex();
        setGenericModal(
          <SelectDropdownModal
            id={id}
            selectionKey={selectionKey}
            selectionValue={selectionValue}
            handleModalSelection={handleDropdown}
            SelectionOpts={modifiedList}
            modalLabel={internalLabel || modalLabel}
            multiDropdownRemove={handleRemove}
            multiDropdown={multiDropdown}
          />
        );
      }
    } //renders dropdown list or modal

    //JSX conditional
    if (modifiedList) {
      dropdownBtn = (
        <ul>
          {Object.entries(modifiedList).map(([key, value], index) => {
            if (key !== 'select') {
              return (
                <li key={`${value}-${index}`}>
                  <button
                    name={id}
                    id={`li-btn-${id}-${index}`}
                    key={`${key}-button`}
                    type="button"
                    onClick={() => handleDropdown(key)}
                    onMouseUp={() => handleDropdown(key)}
                    tabIndex="0"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onFocus={() => setSelectionIndex(index)}
                    onMouseEnter={() => handleOnMouseEnter(index)}
                    className={`${
                      index === selectionIndex ? 'highlight' : ''
                    } `}
                  >
                    {value}
                  </button>
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
    } //must remove select from dropdown list
    return (
      <>
        <div
          className={`dropdown__wrapper ${tempDisable ? 'opacity-3' : ''} ${
            customWrapper ? customWrapper : ''
          }`}
          onMouseEnter={!tempDisable ? () => setShowRemoveBtn(true) : null}
          onMouseLeave={!tempDisable ? () => setShowRemoveBtn(false) : null}
          id={`${id}-dropdown`}
          onClick={(e) => e.stopPropagation()}
          aria-label={`${internalLabel}. ${
            asterisk ? `${SelectDropdown.selectionRequired}` : null
          }.`}
          style={{ zIndex: `${showOpts ? '8' : '1'}`, minWidth: '100%' }}
        >
          <div
            className={`dropdown__container ${fullWidth ? 'full-width' : ''}`}
          >
            {title && (
              <div>
                <label className="flex-row " htmlFor={`${id}`}>
                  {title}
                  {titleAsterisk && <p className="color-theme">&nbsp;*</p>}
                </label>
                <div className="h12" />
              </div>
            )}

            <div className="flex-row pos-rel">
              <button
                id={`${id}-dropdown-main`}
                aria-label={`${SelectDropdown.ariaLabelIntro} ${
                  internalLabel ? internalLabel : ''
                } ${
                  mutliDropdownLabel
                    ? mutliDropdownLabel
                    : ariaTitle
                    ? ariaTitle
                    : ''
                }. ${asterisk ? SelectDropdown.selectionRequired : ''}. ${
                  selectionValue ? SelectDropdown.currentSelection : ''
                } ${selectionValue ? selectionValue : ''}`}
                aria-expanded={showOpts}
                tabIndex="0"
                className={`dropdown__button access-ob access-o6 br--small fs18 fwn highlight-bthin-theme ${
                  showOpts ? 'dropdown__button-enabled' : ''
                }  ${fullWidth ? 'dropdown__button--fw' : ''} ${
                  tapHighlightMainBtn ? 'highlight-b-theme--tap' : ''
                } `}
                style={
                  showOpts
                    ? {
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                      }
                    : null
                }
                onClick={
                  tempDisable
                    ? () =>
                        handleWarning(
                          {
                            message: temporaryDisableMessage,
                            statusCode: 500,
                          },
                          `SelectDropdown.js/${id}`
                        )
                    : (e) => handleMainBtnClick(e)
                }
              >
                {internalLabel && !asterisk}
                {internalLabel && asterisk && (
                  <div className="flex-row no-wrap">
                    {internalLabel}
                    <p className="fs16 fwsb color-theme" aria-hidden="true">
                      &nbsp;*
                    </p>
                  </div>
                )}
                <div className="flex-row">
                  <div className="flex-row flex-center disable-select">
                    {!selectionValue && !internalLabel && asterisk && (
                      <p className="color-theme">*&nbsp;</p>
                    )}
                    {selectionValue
                      ? selectionValue
                      : multiDropdown
                      ? ''
                      : `${SelectDropdown.select}`}
                    {(!selectionValue || selectionKey === 'select') && (
                      <img
                        src={dropdown_arrow}
                        alt={SelectDropdown.arrow}
                        className={`arrow ${
                          showOpts ? 'reverse-vertical' : ''
                        }`}
                      />
                    )}
                  </div>
                </div>
              </button>
              {secondButtonToggle &&
                (showOpts || showRemoveBtn) &&
                width > 480 && (
                  <button
                    className={`${secondButtonClass} access-ob`}
                    title={secondButtonTitle}
                    id={secondButtonId ? secondButtonId : `${id}-secondary-btn`}
                    onClick={!tempDisable ? secondButtonFunction : null}
                    tabIndex="0"
                  >
                    <img
                      src={secondButtonImg}
                      alt={secondButtonTitle ? secondButtonTitle : ''}
                      style={{
                        height: '44px',
                        padding: `${
                          secondButtonPadding ? secondButtonPadding : ''
                        }`,
                      }}
                    />
                  </button>
                )}

              {(showOpts || showRemoveBtn) && selectionValue && width > 480 && (
                <button
                  className={`remove-btn access-ob ${
                    multiDropdown
                      ? 'remove-btn--dropdown-multiple'
                      : 'remove-btn--standalone'
                  }`}
                  onClick={
                    !tempDisable ? () => handleRemove(selectionKey) : null
                  }
                  title={SelectDropdown.remove}
                  tabIndex="0"
                >
                  <img src={trash} alt={SelectDropdown.remove} />
                </button>
              )}
            </div>

            <div className="showopts-wrapper">
              <div
                className={`no-select ${showOpts ? 'showopts' : 'remove'}`}
                tabIndex="-1"
                style={{ zIndex: `${showOpts ? '8' : '1'}` }}
                id={`showopts-${id}`}
              >
                {dropdownBtn}
              </div>
            </div>
          </div>
        </div>
        {showOpts && (
          <div
            className="dropdown-overlay"
            onClick={() => handleOverlayClick()}
          />
        )}
      </>
    );
  }
);

SelectDropdown.displayName = 'SelectDropdown';

export default SelectDropdown;
