import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

//main
import { apiSlice } from './api/apiSlice';
import authReducer from '../features/main/auth/authSlice';
import settingsReducer from '../features/main/settings/settingsSlice';
import accountReducer from '../features/main/account/accountSlice';
import createEventReducer from '../features/main/organize/create/createEventSlice';
import apiStatusReducer from '../features/main/apiStatus/apiStatusSlice';
import editEventReducer from '../features/main/organize/edit/editEventSlice';
import organizeReducer from '../features/main/organize/organizeSlice';
import registrationReducer from '../features/main/registration/registrationSlice';

//admin
import adminAuthReducer from '../features/admin/auth/adminAuthSlice';
import adminAccountReducer from '../features/admin/account/adminAccountSlice';
import adminSettingsReducer from '../features/admin/settings/adminSettingsSlice';
import adminSupportReducer from '../features/admin/support/adminSupportSlice';
import adminManagementReducer from '../features/admin/management/adminManagementSlice';
import adminTechnicalReducer from '../features/admin/technical/adminTechnicalSlice';

const isProduction = process.env.NODE_ENV === 'production';

const persistConfig = {
  key: 'root',
  storage,
  migrate: (state) => {
    const savedVersion = state?._persist?.version || 0;
    const newVersion = parseFloat(process.env.REACT_APP_REDUX_V);

    // Define migration strategies here
    if (savedVersion !== newVersion) {
      console.log('store.js/redux version update required.');
      return Promise.resolve(undefined); // This resets the state
    } else {
      console.log('store.js/redux version update is not required.');
      return Promise.resolve(state);
    }
  },
  whitelist: ['settings', 'createEvent', 'adminSettings'],
};

// //if state needs to be migrated but not wiped
// ex. if (version === 2) {
//   const modifiedState = {
//     ...state,
//     someNewField: state?.someOldField || 'defaultValue',
//   };
//   return Promise.resolve(modifiedState);
// }

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  account: accountReducer,
  settings: settingsReducer,
  createEvent: createEventReducer,
  editEvent: editEventReducer,
  apiStatus: apiStatusReducer,
  organize: organizeReducer,
  registration: registrationReducer,

  //admin
  adminAuth: adminAuthReducer,
  adminAccount: adminAccountReducer,
  adminSettings: adminSettingsReducer,
  adminSupport: adminSupportReducer,
  adminManagement: adminManagementReducer,
  adminTechnical: adminTechnicalReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(apiSlice.middleware),
  devTools: !isProduction,
});
// devTools: { trace: true },  //RTKQuery middleware to cache results

export const persistor = persistStore(store);
