import { useState, useEffect } from 'react';
//Hooks
import { useUpdatePhotoMutation } from '../../accountApiSlice';
import useApiStatus from '../../../../../context/useApiStatus';
import useSettings from '../../../../../context/useSettings';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//Components
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';

//Utility
import convertBase64ToJPEG from '../../../../../utils/images/convertBase64ToJPEG';
import getImageBlob from '../../../../../utils/images/getImageBlob';

const ProfilePhoto = ({ Account }) => {
  //Hooks
  const { handleError } = useApiStatus();
  const { handleMobileTap } = useSettings();
  const { accountId, accountProfileImage } = useAccountReduxHandlers();
  const [
    updateAccountPhoto,
    {
      isLoading: updateAccountPhotoIsLoading,
      isFetching: updateAccountPhotoIsFetching,
    },
  ] = useUpdatePhotoMutation();

  //Component state
  const [croppedImg, setCroppedImg] = useState({
    croppedImage: '',
    fileName: '',
    imagePreview: '',
  });
  const [convertingImage, setConvertingImage] = useState(false);
  const [changeProfileImage, setChangeProfileImage] = useState(false);

  //UI state
  const [tapHighlightChangeBtn, setTapHighlightChangeBtn] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (
      accountProfileImage !== 'account_img_default.png' &&
      changeProfileImage
    ) {
      getImageBlob(accountProfileImage, 'account', setCroppedImg);
    }
  }, [accountProfileImage, changeProfileImage]); //gets new blob for new profileImage or if user cancels change

  useEffect(() => {
    return () => {
      handleCancelSaveImage();
    };
  }, []);

  function handleCancelSaveImage() {
    if (croppedImg.imagePreview) {
      URL.revokeObjectURL(croppedImg.imagePreview);
    }
    setCroppedImg({
      croppedImage: '',
      fileName: '',
      imagePreview: '',
    });
    setChangeProfileImage(false);
  }

  async function saveAccountProfilePhotoChange(e) {
    e.preventDefault();

    if (!updateAccountPhotoIsFetching && !updateAccountPhotoIsLoading) {
      const formData = new FormData();
      const profileImage = croppedImg;
      let profileImgJPG;

      if (profileImage.croppedImage.croppedImage) {
        profileImgJPG = await convertBase64ToJPEG(
          profileImage.croppedImage.croppedImage,
          'profileImage.jpg'
        );
      }

      //5 mb max.
      if (profileImgJPG?.size >= 5242880) {
        return handleError({
          message: 'imageSizeTooLarge',
          id: Date.now(),
          origin: 'ProfilePhoto.js/saveAccountProfilePhotoChange',
        });
      }

      formData.append('profileImage', profileImgJPG);
      formData.append('accountId', accountId);

      await updateAccountPhoto(formData);
      setChangeProfileImage(false);
    }
  }

  //Component functions
  function handleProfileImg(img) {
    let newImg = {};
    newImg = {
      croppedImage: img.croppedImage,
      fileName: img.fileName,
      imagePreview: img.imagePreview,
    };
    setCroppedImg(newImg);
  }

  return (
    <section className="section">
      <div className="h24" />
      <label
        className="content-heading-styled text-gray "
        tabIndex="0"
        id="profilePhotoLabel"
      >
        {Account.account.profilePhoto}
      </label>
      {changeProfileImage && (
        <>
          <div className="h24" />
          <ImageCropper
            id={'profile'}
            width={264}
            height={264}
            center={true}
            defaultImageClassName={'profileImgDefault'}
            cover={'horizontal-cover'}
            handleImage={handleProfileImg}
            imagePreview={croppedImg.imagePreview}
            profileFrame={true}
            ariaImageDescription={Account.account.profilePhoto}
            setHOActionBtnsUnavailable={setConvertingImage}
          />
        </>
      )}
      <div className="h24" />
      {changeProfileImage && !convertingImage && (
        <ConfirmSelection
          btn1Text={Account.generic.cancel}
          btn1Color={'neutral'}
          btn1Handler={handleCancelSaveImage}
          btn2Text={Account.generic.save}
          btn2Color={'pos'}
          btn2Handler={saveAccountProfilePhotoChange}
          center={true}
          customWrapper={'mrg-auto-lr'}
        />
      )}
      <div className="flex-center flex-column">
        {!changeProfileImage && (
          <>
            <LoadBucketImage
              imagePath={accountProfileImage}
              imgClassName={'profile-image'}
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
            />
            <div className="h24" />
            <div className="photo-save-btn">
              <button
                className={`access-ob access-o6 button-save ${
                  !changeProfileImage ? 'button-save--active' : ''
                } ${
                  tapHighlightChangeBtn
                    ? 'highlight-bg-theme--tap'
                    : 'color-theme'
                }`}
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setTapHighlightChangeBtn(true),
                      () => setTapHighlightChangeBtn(false),
                    ],
                    [() => setChangeProfileImage(true)]
                  )
                }
              >
                <p className="pad-t2">{Account.generic.change}</p>
              </button>
            </div>
          </>
        )}
      </div>
      <div className="h36" />
    </section>
  );
};

export default ProfilePhoto;
