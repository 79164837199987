import useSettings from '../../context/useSettings';

const useLanguageEvents = () => {
  const { contentLanguage } = useSettings();

  const EventsGrid = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        searchCompleted: 'Search Completed',
        loadMore: 'Load More',
        noEvents: 'No Events',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        searchCompleted: 'Recherche terminée',
        loadMore: 'Charger plus',
        noEvents: `Pas d'événements`,
      };
    }

    return translation;
  };

  const EventCard = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        displayImage: 'display image',
        ignoreButton: 'ignore button',
        clickToUnignore: 'Click to Unignore',
        clickToIgnore: 'Click to Ignore',
        saveButton: 'save button',
        clickToSave: 'Click to Save',
        clickToUnsave: 'Click to Unsave',
        dateIcon: 'date icon',
        locationIcon: 'location icon',
        organizerIcon: 'organizer logo',
        paidEvent: 'Paid Event',
        accessibility: 'Accessibility',
        followingSpeaker: 'Includes Followed Speaker',
        savedEvent: 'Saved Event',
        ignoredEvent: 'Ignored Event',
        registration: 'Registration',
        remove: 'Remove',
        removeFromArchive: 'Remove from Archive',
        platform: 'Platform',
        pendingApproval: 'Pending',
        registered: 'Registered',
        clickToOpenAnnouncements: 'Click to open event announcements',
        openAnnouncements: 'Open Announcements',
        full: 'Full',
        almostFull: 'Almost Full',
        open: 'Open',
        inPersonIcon: 'In Person Icon',
        virtualIcon: 'Virtual Icon',
        organizer: 'Organizer',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        displayImage: `afficher l'image`,
        ignoreButton: `bouton d'ignorer`,
        clickToUnignore: `Cliquez pour annuler l'ignorance`,
        clickToIgnore: 'Cliquez pour ignorer',
        saveButton: `bouton d'enregistrement`,
        clickToSave: 'Cliquez pour vous inscrire',
        clickToUnsave: `Cliquez pour supprimer l'enregistrement`,
        dateIcon: 'icône de date',
        locationIcon: 'icône de localisation',
        organizerIcon: `logo de l'organisateur`,
        paidEvent: 'Événement payant',
        accessibility: 'Accessibilité',
        followingSpeaker: 'Inclut le conférencier suivi',
        savedEvent: 'Événements sauvegardés',
        ignoredEvent: 'Événement ignoré',
        registration: 'Inscription',
        remove: 'Supprimer',
        removeFromArchive: 'Supprimer des archives',
        platform: 'Plate-forme',
        pendingApproval: 'En validation',
        registered: 'Enregistré',
        clickToOpenAnnouncements: `Cliquez pour ouvrir les annonces d'événements`,
        openAnnouncements: 'Annonces ouvertes',
        full: 'Plein',
        almostFull: 'Presque plein',
        open: 'Ouverte',
        inPersonIcon: 'Icône En personne',
        virtualIcon: 'Icône virtuelle',
        organizer: 'Organisateur',
      };
    }

    return translation;
  };

  const RemoveArchivedEventModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        yes: 'Yes',
        no: 'No',
        removeArchivedEventWarning:
          'Are you sure you want to delete this archived event?',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        yes: 'Oui',
        no: 'Non',
        removeArchivedEventWarning:
          'Êtes-vous sûr de vouloir supprimer cet événement archivé ?',
      };
    }

    return translation;
  };

  const EventPage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        accessibility: 'Accessibility',
        calendarIcon: 'calendar icon',
        clickToFollow: 'Click to Follow',
        clickToIgnore: 'Click to Ignore',
        clickToOpenAnnouncements: 'Click to open event announcements',
        clickToSave: 'Click to Save',
        clickToShare: 'Click to Share',
        clickToUnfollow: 'Click to Unfollow',
        clickToUnignore: 'Click to Unignore',
        clickToUnsave: 'Click to Unsave',
        displayImage: 'display image',
        follow: 'Follow',
        followingSpeaker: 'Includes Followed Speaker',
        ignoreButton: 'ignore button',
        ignoredEvent: 'Ignored Event',
        locationIcon: 'location icon',
        networkIcon: 'network icon',
        openAnnouncements: 'Open Announcements',
        organizerIcon: 'organizer icon',
        paidEvent: 'Paid Event',
        pendingApproval: 'Pending...',
        pendingApprovalInPerson: 'Pending In Person',
        pendingApprovalVirtual: 'Pending Virtual',
        platform: 'Platform',

        register: 'Register',
        registered: 'Registered',
        registration: 'Registration',
        inPerson: '(In Person)',
        virtual: '(Virtual)',
        almostFull: '(Almost Full)',
        full: '(Full)',
        open: '(Open)',

        registerVirtual: 'Register Virtual',
        registerInPerson: 'Register In Person',

        remove: 'Remove',
        removeFromArchive: 'Remove from Archive',
        report: 'Report',
        return: 'Return',
        saveButton: 'save button',
        savedEvent: 'Saved Event',
        shareButton: 'Share Button',
        //headings
        aboutThisEvent: 'About this event',
        description: 'Description',
        speakers: 'Speakers',
        agenda: 'Agenda',
        tags: 'Tags',
        audience: 'Audience',
        eventType: 'Event Type',
        languages: 'Language(s)',
        format: 'Format',
        moderator: 'Moderator',
        duration: 'Duration',
        location: 'Location',
        room: 'Room',
        locationName: 'Location Name',
        streetAddress: 'Street address',
        showMap: 'Show Map',
        virtualAttendance: 'Virtual Attendance',
        meetingId: 'Meeting Id',
        passcode: 'Passcode',
        link: 'Link',
        participants: 'Participants',
        minutes: 'Minutes',
        startTime: 'Start time',
        endTime: 'End time',
        wheelchairAccessible: 'Wheelchair accessible',
        signLanguageInterpreter: 'Sign language interpreter',
        realtimeTranslations: 'Real-time translations',
        notes: 'Notes',
        addTagToAccount: 'Add tag to your account',
        removeTagFromAccount: 'Remove tag from your account',
        inPersonAttendance: 'In-Person Attendance',
        organizer: 'Organizer',
        registrationIcon: 'registration icon',

        registrationOpen: 'Registration (Open)',
        registrationAlmostFull: 'Registration (Almost Full)',
        registrationFull: 'Registration (Full)',
        joinWaitingList: 'Join Wait List',
        clickToUnregister: 'Click to Unregister',
        clickToRegister: 'Click to Register',
        clickToSwitch: 'Click to Switch',
        clock: 'Clock',
        checkmark: 'Checkmark',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        accessibility: 'Accessibilité',
        calendarIcon: 'icône de calendrier',
        clickToFollow: 'Click to Follow',
        clickToUnfollow: 'Click to Unfollow',
        clickToIgnore: 'Cliquez pour ignorer',
        clickToOpenAnnouncements: `Cliquez pour ouvrir les annonces d'événements`,
        clickToSave: 'Cliquez pour vous inscrire',
        clickToShare: 'Cliquez pour partager',
        clickToUnignore: `Cliquez pour annuler l'ignorance`,
        clickToUnsave: `Cliquez pour supprimer l'enregistrement`,
        displayImage: `afficher l'image`,
        follow: 'Suivre',
        followingSpeaker: 'Inclut le conférencier suivi',
        ignoreButton: `bouton d'ignorer`,
        ignoredEvent: 'Événement ignoré',
        locationIcon: 'icône de localisation',
        networkIcon: 'icône de réseau',
        openAnnouncements: 'Annonces ouvertes',
        organizerIcon: `icône de l'organisateur`,
        paidEvent: 'Événement payant',
        pendingApproval: 'En validation',
        pendingApprovalInPerson: 'En validation (en personne)',
        pendingApprovalVirtual: 'En validation (virtuellement)',
        platform: 'Plate-forme',

        register: 'Registre',
        registered: 'Enregistré',
        registration: 'Inscription',
        inPerson: '(en personne)',
        virtual: '(virtuellement)',
        almostFull: '(Presque plein)',
        full: '(Plein)',
        open: '(Ouverte)',

        registerVirtual: 'Inscription virtuelle',
        registerInPerson: 'Inscription en personne',

        remove: 'Supprimer',
        removeFromArchive: 'Supprimer des archives',
        report: 'Rapport',
        return: 'Retour',
        saveButton: `bouton d'enregistrement`,
        savedEvent: 'Événements sauvegardés',
        shareButton: 'Bouton partager',
        //headings
        aboutThisEvent: 'À propos de cet événement',
        description: 'Description',
        speakers: 'Conférenciers',
        agenda: `Ordre du jour de l'événement`,
        tags: 'Tags',
        audience: 'Public cible',
        eventType: `Type d'événement`,
        languages: 'Language(s)',
        format: 'Format',
        moderator: 'Modérateur',
        duration: 'Durée',
        location: 'Emplacement',
        room: 'Salle',
        locationName: 'Nom de la localisation',
        streetAddress: 'Adresse',
        showMap: 'Afficher la carte',
        virtualAttendance: 'Participation virtuelle',
        meetingId: 'ID de réunion',
        passcode: `Code d'accès`,
        link: 'Lien',
        participants: 'Participants',
        minutes: 'Minutes',
        startTime: 'Heure de début',
        endTime: 'Heure de fin',
        wheelchairAccessible: 'Accessibilité en fauteuil roulant',
        signLanguageInterpreter: 'Interprète en langue des signes',
        realtimeTranslations: 'Traductions en temps réel',
        notes: 'Notes',
        addTagToAccount: 'Ajoutez cette balise à votre compte',
        removeTagFromAccount: 'Supprimez cette balise de votre compte',
        inPersonAttendance: 'Présence en personne',
        organizer: 'Organisateur',
        registrationIcon: 'icône de inscription',
        registrationOpen: 'Inscription (Ouverte)',
        registrationAlmostFull: 'Inscription (Presque plein)',
        registrationFull: 'Inscription (Plein)',
        joinWaitingList: `Rejoindre la liste d'attente`,

        clickToUnregister: 'Cliquez pour vous désinscrire',
        clickToRegister: 'Cliquez pour vous inscrire',
        clickToSwitch: 'Cliquez pour changer',
        clock: 'Horloge',

        checkmark: 'Coche',
      };
    }

    return translation;
  };

  return {
    EventPage: EventPage(),
    EventsGrid: EventsGrid(),
    EventCard: EventCard(),
    RemoveArchivedEventModal: RemoveArchivedEventModal(),
  };
};

export default useLanguageEvents;
