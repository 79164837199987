import { useEffect, useState } from 'react';

//Hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//Components
import TextArea from '../../../../../components/TextArea/TextArea';
import Counter from '../../../../../components/Counter/Counter';
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//Utility
import { return_curved_arrow } from '../../../../../assets/icons';
import { image_speaker } from '../../../../../assets/images';
import Spinner from '../../../../../components/Spinner/Spinner';

const CreateRoomSpeakers = () => {
  //hooks
  const { TranslationRoomCreateRoomSpeakers } = useLanguageTranslationRoom();
  const {
    speakerCount,
    setSpeakerCount,
    speakersData,
    setSpeakersData,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
  } = useTranslationRoomSocket();

  //UI
  const [speakerNamesValidation, setSpeakerNamesValidation] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  //functions
  useEffect(() => {
    let speakerNameValidation = [];
    speakersData?.forEach((speaker) => {
      let formattedSpeakerName = speaker.name.trim();
      if (formattedSpeakerName?.length > 0 && formattedSpeakerName !== '') {
        speakerNameValidation.push(true);
      } else {
        speakerNameValidation.push(false);
      }
    });

    if (speakerNameValidation.includes(false)) {
      if (speakerNamesValidation) {
        setSpeakerNamesValidation(false);
      }
    } else {
      setSpeakerNamesValidation(true);
    }
  }, [speakersData]);

  function handleSpeakersData(value, index) {
    let newSpeakersData = [...speakersData];
    if (index >= 0 && index < speakersData.length) {
      newSpeakersData[index].name = value || '';
      setSpeakersData(newSpeakersData);
    }
  }

  function handleSpeakerCounter(value) {
    if (value > speakerCount) {
      if (value > 10) {
        return null;
      } else {
        const newSpeakerCounter = speakerCount + 1;
        setSpeakerCount(newSpeakerCounter);

        setSpeakersData([
          ...speakersData,
          {
            name: '',
            discussionContext: '',
            keywordsList: [],
            id: Date.now(),
          },
        ]);
      }
    } else {
      if (speakerCount === 1) {
        return null;
      } else {
        const newSpeakerCounter = speakerCount - 1;
        setSpeakerCount(newSpeakerCounter);
        const newSpeakersData = [...speakersData];
        newSpeakersData.pop();
        setSpeakersData(newSpeakersData);
      }
    }
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations-selection__container ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_speaker}
          alt="img"
          style={{ height: '200px' }}
          className="svg mrg-t24"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p
            className="fs21 fwsb mrg-tb24 text-center access-ob access-o6"
            tabIndex="0"
            aria-label={TranslationRoomCreateRoomSpeakers.selectSpeakers}
            id={'createRoom'}
          >
            {TranslationRoomCreateRoomSpeakers.selectSpeakers}
          </p>

          <Counter
            loadValue={speakerCount}
            handleCounter={handleSpeakerCounter}
            increment={1}
            id={'speakerCount'}
            minValue={1}
            maxValue={10}
          />

          <div className="flex-column full-width">
            {speakersData?.map((speakerData, index) => {
              return (
                <TextArea
                  key={`speaker-${index}`}
                  handleChange={handleSpeakersData}
                  limit={100}
                  id={`${index}`}
                  labelText={`${
                    TranslationRoomCreateRoomSpeakers.speakerName
                  } ${index + 1}`}
                  disableEnter={true}
                  loadValue={speakerData['name']}
                  loadTrigger={true}
                  customWrapper={'mrg-t24'}
                  onBlurHandlerOff={true}
                />
              );
            })}
          </div>

          <ItemButton
            text={TranslationRoomCreateRoomSpeakers.continue}
            handler={() => handleCreatePageChange(5)}
            customWrapper={'mrg-t24 full-width'}
            iconOn={true}
            icon={return_curved_arrow}
            iconCustomClass={'reverse'}
            unavailable={!speakerNamesValidation}
          />

          <div className="divider full-width mrg-t36 mrg-b12" />

          <ItemButton
            text={TranslationRoomCreateRoomSpeakers.back}
            handler={() => handleCreatePageChange(3)}
            customWrapper={'full-width mrg-t24'}
          />
          <div className="h72" />
        </div>
      </div>
    </>
  );
};

export default CreateRoomSpeakers;
