import { useEffect, useState } from 'react';

//hooks
import useGenericModal from '../../../../../context/useGenericModal';
import useSettings from '../../../../../context/useSettings';
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//components
import ReportModal from '../../../../../components/Modal/ModalComponents/ReportModal/ReportModal';
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utility
import {
  cancel,
  flag,
  img_download,
  img_expand,
  img_moon,
  img_sun,
  img_volume_off,
  img_volume_on,
  myndfull_icon,
  return_curved_arrow,
  warning,
} from '../../../../../assets/icons';
import { handleDownloadTranscriptForHost } from '../../functions/transcripts';

const TranslationTextBox = ({
  translationTextBoxRef,
  isHost,
  roomCode,
  width,
  volumeOn,
  setVolumeOn,
  translationLang,
  isListening,
  audioElementRef,
  handleAudioEnded,
  setAudioContentQueue,
  handleExit,
  hostTranslationData,
  userSessionIdTranslations,
  speakersData,
}) => {
  //hooks
  const { setGenericModal } = useGenericModal();
  const { handleMobileTap } = useSettings();
  const { TranslationRoom } = useLanguageTranslationRoom();

  //state
  const [maximizeSpeechBox, setMaximizeSpeechBox] = useState(false);
  const [lightDarkMode, setLightDarkMode] = useState('light');

  //UI
  const [tapHighlightReport, setTapHighlightReport] = useState(false);
  const [tapHighlightDownload, setTapHighlightDownload] = useState(false);
  const [tapHighlightWindowModeBtn, setTapHighlightWindowModeBtn] =
    useState(false);
  const [tapHighlightLightDarkModeBtn, setTapHighlightLightDarkModeBtn] =
    useState(false);

  const [tapHighlightVolumeBtn, setTapHighlightVolumeBtn] = useState(false);
  const [removeF11Hint, setRemoveF11Hint] = useState(false);

  //UI
  useEffect(() => {
    if (maximizeSpeechBox) {
      setRemoveF11Hint(true);
      document.getElementById('expandedTranslationTextBox').focus();
      let timeout = null;

      timeout = setTimeout(() => {
        setRemoveF11Hint(false);
      }, 2000);

      return () => {
        if (timeout !== null) {
          clearTimeout(timeout);
        }
      };
    }
  }, [maximizeSpeechBox]); //temporarily show fade

  //functions
  function handleLightDarkMode() {
    if (lightDarkMode === 'light') {
      setLightDarkMode('dark');
    } else {
      setLightDarkMode('light');
    }
  }

  return (
    <>
      <div className={`pos-rel full-width`}>
        {isHost && !maximizeSpeechBox && (
          <button
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightWindowModeBtn(true),
                  () => setTapHighlightWindowModeBtn(false),
                ],
                [() => setMaximizeSpeechBox(true)]
              )
            }
            className={`translation-box-btn highlight-i-theme ${
              tapHighlightWindowModeBtn ? 'highlight-i-theme--tap' : ''
            }`}
            title={TranslationRoom.expandToFullscreen}
            aria-label={TranslationRoom.ariaExpandTextAreaToFullScreen}
          >
            <img
              src={img_expand}
              className="svg filter-gray"
              alt="img"
              aria-hidden="true"
            />
          </button>
        )}
        {!isHost && translationLang && translationLang !== 'select' && (
          <button
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightVolumeBtn(true),
                  () => setTapHighlightVolumeBtn(false),
                ],
                [() => setVolumeOn(!volumeOn)]
              )
            }
            className={`translation-box-btn highlight-i-theme ${
              tapHighlightVolumeBtn ? 'highlight-i-theme--tap' : ''
            }`}
            aria-pressed={volumeOn}
            aria-label={TranslationRoom.ariaVolumeToggle}
          >
            {volumeOn ? (
              <img
                src={img_volume_on}
                className="svg filter-gray"
                alt="img"
                aria-hidden="true"
              />
            ) : (
              <img
                src={img_volume_off}
                className="svg filter-gray"
                alt="img"
                aria-hidden="true"
              />
            )}
          </button>
        )}
        <ul
          ref={translationTextBoxRef}
          className={`translation-text-box ${
            maximizeSpeechBox ? 'translation-text-box--maximized' : ''
          } ${
            lightDarkMode === 'light'
              ? 'translation-text-box--light'
              : 'translation-text-box--dark'
          }`}
          tabIndex="0"
          id={'expandedTranslationTextBox'}
        >
          <audio onEnded={handleAudioEnded} ref={audioElementRef} />
          {isHost && maximizeSpeechBox && (
            <div
              className={`translation-text-box-maximized-header ${
                lightDarkMode === 'light'
                  ? 'translation-text-box--light'
                  : 'translation-text-box--dark'
              }`}
              aria-hidden="true"
              tabIndex="-1"
            >
              {width > 1280 && (
                <p
                  className={`hint fwb fs24 ${
                    !removeF11Hint ? 'fade-hint' : ''
                  }`}
                >
                  {TranslationRoom.pressF11ToMaximizeFullScreen}
                </p>
              )}

              <div className="translation-text-box-logo-container">
                <img src={myndfull_icon} alt="img" className="svg" />
                <div className="flex-column">
                  <p className="mrg-l12">myndfull.com/tr</p>
                  <div className="flex-row align-center mrg-t6">
                    <div
                      className={`activate-translations-btn--small  ${
                        isListening ? 'bg-color-red' : 'bg-color-lightgray'
                      }`}
                      title={
                        isListening
                          ? TranslationRoom.ariaSpeechRecognitionActive
                          : TranslationRoom.ariaSpeechRecognitionInactive
                      }
                    />
                    <p>
                      {TranslationRoom.roomCode}: {roomCode}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex-row flex-column--phn col-reverse--phn">
                <button
                  onClick={() =>
                    handleMobileTap(
                      [
                        () => setTapHighlightLightDarkModeBtn(true),
                        () => setTapHighlightLightDarkModeBtn(false),
                      ],
                      [() => handleLightDarkMode()]
                    )
                  }
                  className={`translation-text-box-btn highlight-i-theme ${
                    tapHighlightLightDarkModeBtn ? 'highlight-i-theme--tap' : ''
                  }`}
                  title={
                    lightDarkMode === 'light'
                      ? `${TranslationRoom.lightMode}`
                      : `${TranslationRoom.darkMode}`
                  }
                  aria-label={
                    lightDarkMode === 'light'
                      ? TranslationRoom.ariaLightModeActive
                      : TranslationRoom.ariaDarkModeActive
                  }
                  id={'colorModeButton'}
                >
                  <img
                    src={lightDarkMode === 'light' ? img_sun : img_moon}
                    className={`svg ${
                      lightDarkMode === 'light' ? 'filter-gray' : 'filter-white'
                    }`}
                    alt="img"
                  />
                </button>
                <button
                  onClick={() =>
                    handleMobileTap(
                      [
                        () => setTapHighlightWindowModeBtn(true),
                        () => setTapHighlightWindowModeBtn(false),
                      ],
                      [
                        () => setMaximizeSpeechBox(false),
                        () => setLightDarkMode('light'),
                      ]
                    )
                  }
                  className={`translation-text-box-btn highlight-i-theme ${
                    tapHighlightWindowModeBtn ? 'highlight-i-theme--tap' : ''
                  }`}
                  title={
                    maximizeSpeechBox
                      ? `${TranslationRoom.exit}`
                      : `${TranslationRoom.expandedMode}`
                  }
                  id={'exitButton'}
                >
                  <img
                    src={cancel}
                    className={`svg ${
                      lightDarkMode === 'light' ? 'filter-gray' : 'filter-white'
                    }`}
                    alt="img"
                  />
                </button>
              </div>
            </div>
          )}
          {!isHost && (!translationLang || translationLang === 'select') && (
            <div
              className="translation-text-box-warning access-ob access-o6"
              tabIndex="0"
            >
              <img src={warning} alt="img" aria-hidden={true} />
              <p>{TranslationRoom.selectLanguage}</p>
            </div>
          )}
        </ul>
      </div>
      <div
        className={`flex-row flex-column--phn full-width space-between align-center mrg-t12 ${
          width <= 480 ? 'mrg-b12' : 'mrg-b48'
        }`}
      >
        {isHost && (
          <button
            id="downloadTranscript"
            className={`align-center highlight-i-lgt highlight-it-theme color-gray ${
              width <= 320 ? 'mrg-tb12' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightDownload(true),
                  () => setTapHighlightDownload(false),
                ],
                [
                  () =>
                    handleDownloadTranscriptForHost(
                      true,
                      userSessionIdTranslations,
                      roomCode,
                      hostTranslationData,
                      speakersData
                    ),
                ]
              )
            }
            style={{ height: '44px' }}
          >
            <p className="fwsb fs16 mrg-r12">
              {TranslationRoom.downloadTranscript}
            </p>
            <img src={img_download} alt="img" style={{ height: '24px' }} />
          </button>
        )}

        <button
          id="report"
          className={`align-center highlight-i-lgr color-gray ${
            !isHost ? 'mrg-auto-left' : ''
          } `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightReport(true),
                () => setTapHighlightReport(false),
              ],
              [() => setGenericModal(<ReportModal type={'translationRoom'} />)]
            )
          }
          style={{ height: '44px' }}
        >
          <p className={`fwsb fs16 mrg-r12 `}>{TranslationRoom.report}</p>
          <img src={flag} alt="img" style={{ height: '24px' }} />
        </button>
      </div>

      {((width <= 1280 && isHost) || (!isHost && width <= 1280)) && (
        <ItemButton
          text={TranslationRoom.exit}
          handler={() => {
            setAudioContentQueue();
            handleExit();
          }}
          icon={return_curved_arrow}
          iconOn={true}
          fullWidth={true}
          customWrapper={`action-btns mrg-auto-right mrg-auto-left mrg-b48 mrg-t24--ph translations-max-width`}
        />
      )}
    </>
  );
};

export default TranslationTextBox;
