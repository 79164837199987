import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
//Components
import BigTagsComponent from '../../../../../components/BigTagsComponent/BigTagsComponent';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
//Utility
import { bigtag } from '../../../../../assets/icons';

const BigTags = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { accountBigTags: loadBigTags, accountTags } =
    useAccountReduxHandlers();

  //Component state
  const [bigTags, setBigTags] = useState(loadBigTags);
  const [bigTagsSaveValid, setBigTagsSaveValid] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (loadBigTags) {
      setBigTags(loadBigTags);
    }
  }, [loadBigTags]);

  //Component functions
  useEffect(() => {
    const sameContents =
      bigTags?.length === loadBigTags?.length &&
      bigTags?.every((value, index) => value === loadBigTags[index]);
    if (!sameContents && bigTags?.length > 0) {
      setBigTagsSaveValid(true);
    } else {
      setBigTagsSaveValid(false);
    }
  }, [bigTags, loadBigTags]);

  function addBigTag(addedTag) {
    let newArr = [...bigTags];
    newArr.push(addedTag);
    setBigTags(newArr);
  }

  function removeBigTag(removedTag) {
    let newArr = bigTags.filter((tag) => tag !== removedTag);
    setBigTags(newArr);
  }

  return (
    <>
      <div className="section">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="bigTagsLabel"
        >
          {Account.attendee.bigTags}
          <p className="color-theme">&nbsp;*</p>
        </label>

        <div className="h12" />
        <p
          className="description-text"
          tabIndex="0"
          aria-label={Account.attendee.ariaBigTagsInputInstructions}
        >
          {Account.attendee.bigTagsDescription}
        </p>
        <div className="h24" />
        <BigTagsComponent
          bigTags={bigTags}
          handleAdd={addBigTag}
          handleRemove={removeBigTag}
          icon={bigtag}
          id={'bigTags'}
          limit={20}
          setBigTags={setBigTags}
          twoTagRows={true}
          fullWidth={true}
        />

        <div className="h24" />
        <SaveButton
          saveValid={bigTagsSaveValid}
          handler={() =>
            saveAccountChange(
              {
                tags: {
                  ...accountTags,
                  bigTags,
                },
              },
              'bigTags'
            )
          }
          saveItemLanguage={Account.attendee.bigTags}
          isLoading={loadingSaveBtnUI === 'bigTags'}
        />
        <div className="h36" />
      </div>
    </>
  );
};

export default BigTags;
