import { useEffect } from 'react';

//Hooks
import useSettings from '../../../context/useSettings';
import { useRegistrationHandlers } from './useRegistrationHandlers';

//Components
import AccountRegistrationType from './Content/AccountRegistrationType';
import PersonalRegistrationMain from './Content/Personal/PersonalRegistrationMain';
import PersonalAccessCode from './Content/Personal/PersonalAccessCode';
import RegistrationSelections from './Content/RegistrationSelections';
import CompletionTracker from './Content/CompletionTracker';

//Utility
import ScrollTo from '../../../utils/UI/ScrollTo';

const Registration = () => {
  //Hooks
  const { setNavbar } = useSettings();
  const {
    registrationAccountType,
    registrationPage,
    handleRegistrationResetRegistration,
    registrationIsSuccess,
    handleRegistrationSoftResetRegistration,
  } = useRegistrationHandlers();
  //State

  //Component variables
  let contents;
  const formContainer = document.getElementById('form-template');

  //UI & Initialize functions
  useEffect(() => {
    setNavbar('register');

    return () => {
      handleRegistrationResetRegistration();
    };
  }, []); //set navbar

  useEffect(() => {
    if (registrationPage === 1) {
      handleRegistrationSoftResetRegistration();
    }
  }, [registrationPage]);

  useEffect(() => {
    ScrollTo('form-template', 'instant', true);
    if (formContainer) {
      formContainer.focus();
    }
  }, [registrationPage, formContainer]);

  //JSX CONDITIONAL
  //Main Registration pages
  if (registrationPage === 0) {
    contents = <PersonalAccessCode />;
  }

  if (registrationPage === 1) {
    contents = <AccountRegistrationType />;
  }

  //PERSONAL
  if (registrationPage === 2) {
    contents = <RegistrationSelections />;
  }

  if (registrationPage > 2 && registrationAccountType === 'personal') {
    contents = <PersonalRegistrationMain />;
  }

  return (
    <div className="layout">
      <div className="register bg-blur">
        <div
          className="form-template mrg-t48 mrg-b48"
          id="form-template"
          tabIndex="-1"
        >
          {registrationPage > 2 && !registrationIsSuccess && (
            <CompletionTracker />
          )}
          {contents}
        </div>
      </div>
    </div>
  );
};

export default Registration;

//Institutional
// import InitializeRegistrationInstitutional from './Content/Institutional/InitializeRegistrationInstitutional';

// if (registrationPage === 2 && registrationAccountType === 'institutional') {
//   contents = (
//     <InitializeRegistrationInstitutional

//       handleRegistrationPageIncrease={handleRegistrationPageIncrease}
//       handleRegistrationPageDecrease={handleRegistrationPageDecrease}
//       translationRoomCodesOptIn={translationRoomCodesOptIn}
//       setTranslationRoomCodesOptIn={setTranslationRoomCodesOptIn}
//       attendEventsOptIn={attendEventsOptIn}
//       setAttendEventsOptIn={setAttendEventsOptIn}
//       speakerEventsOptIn={speakerEventsOptIn}
//       setSpeakerEventsOptIn={setSpeakerEventsOptIn}
//       organizeEventsOptIn={organizeEventsOptIn}
//       setOrganizeEventsOptIn={setOrganizeEventsOptIn}
//     />
//   );
// }
