export function getRandomColor() {
  const preSelectedHexColors = [
    '#0AD591',
    '#016D73',
    '#FF5714',
    '#071E22',
    '#7E4E60',
    '#0E0E52',
    '#DB2955',
    '#011936',
    '#592E83',
  ];

  const randomIndex = Math.floor(Math.random() * 9);

  return preSelectedHexColors[randomIndex];
}
