import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { axiosLimited } from '../axios/axios';
import useApiStatus from '../context/useApiStatus';

//example usage:
// const recaptchaVerified = await handleReCaptchaVerify('LOGIN', true, 0.7);
// if (recaptchaVerified !== true) return false;

const useReCaptcha = () => {
  //Hooks
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { handleError } = useApiStatus();

  const handleReCaptchaVerify = useCallback(
    async (action, backendVerification, scoreThreshold) => {
      if (!executeRecaptcha) {
        return handleError({
          message: 'tryAgain',
        });
      }
      const recaptchaToken = await executeRecaptcha(action);
      if (!backendVerification) return true;

      try {
        const response = await axiosLimited.post('/api/verifyRecaptcha', {
          recaptchaToken,
          action,
          scoreThreshold,
        });

        if (response?.data?.success === true) {
          return true;
        } else {
          throw new Error();
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Error:', error);
        }

        const newError = {
          error,
          id: Date.now(),
          origin: 'useReCaptcha.js/handleReCaptchaVerify',
        };
        return handleError(newError);
      }
    },
    [executeRecaptcha]
  );

  return { handleReCaptchaVerify };
};

export default useReCaptcha;
