module.exports = {
  timezones: {
    canada: {
      iana: '2023c',
      countryName: 'Canada',
      zones: [
        {
          canadaAtlanticTime: {
            abbr: 'AT',
            country_code: 'CA',
            identifier: 'Atlantic Time',
            iana: 'Canada/Atlantic',
          },
        },
        {
          canadaCentralTime: {
            abbr: 'CT',
            country_code: 'CA',
            identifier: 'Central Time',
            iana: 'Canada/Central',
          },
        },
        {
          canadaEasternTime: {
            abbr: 'ET',
            country_code: 'CA',
            identifier: 'Eastern Time',
            iana: 'Canada/Eastern',
          },
        },
        {
          canadaMountainTime: {
            abbr: 'MT',
            country_code: 'CA',
            identifier: 'Mountain Time',
            iana: 'Canada/Mountain',
          },
        },
        {
          canadaNewfoundlandTime: {
            abbr: 'NT',
            country_code: 'CA',
            identifier: 'Newfoundland Time',
            iana: 'Canada/Newfoundland',
          },
        },
        {
          canadaPacificTime: {
            abbr: 'PT',
            country_code: 'CA',
            identifier: 'Pacific Time',
            iana: 'Canada/Pacific',
          },
        },
        {
          canadaYukonTime: {
            abbr: 'YT',
            country_code: 'CA',
            identifier: 'Yukon Time',
            iana: 'Canada/Yukon',
          },
        },
      ],
    },
    mexico: {
      iana: '2023c',
      countryName: 'Mexico',
      zones: [
        {
          mexicoCentralTime: {
            abbr: 'CT',
            country_code: 'MX',
            identifier: 'Central Time',
            iana: 'America/Mexico_City',
          },
        },
        {
          mexicoEasternTime: {
            abbr: 'ET',
            country_code: 'MX',
            identifier: 'Eastern Time',
            iana: 'America/Cancun',
          },
        },
        {
          mexicoMountainTime: {
            abbr: 'MT',
            country_code: 'MX',
            identifier: 'Mountain Time',
            iana: 'America/Hermosillo',
          },
        },
        {
          mexicoPacificTime: {
            abbr: 'PT',
            country_code: 'MX',
            identifier: 'Pacific Time',
            iana: 'America/Tijuana',
          },
        },
      ],
    },
    unitedStates: {
      iana: '2023c',
      countryName: 'United States',
      zones: [
        {
          unitedStatesAlaskaTime: {
            abbr: 'AK',
            country_code: 'US',
            identifier: 'Alaska Time',
            iana: 'US/Alaska',
          },
        },
        {
          unitedStatesCentralTime: {
            abbr: 'CT',
            country_code: 'US',
            identifier: 'Central Time',
            iana: 'US/Central',
          },
        },
        {
          unitedStatesEasternTime: {
            abbr: 'ET',
            country_code: 'US',
            identifier: 'Eastern Time',
            iana: 'US/Eastern',
          },
        },
        {
          unitedStatesHawaiiAleutianTime: {
            abbr: 'HAT',
            country_code: 'US',
            identifier: 'Hawaii-Aleutian Time',
            iana: 'US/Hawaii',
          },
        },
        {
          unitedStatesMountainTime: {
            abbr: 'MT',
            country_code: 'US',
            identifier: 'Mountain Time',
            iana: 'US/Mountain',
          },
        },
        {
          unitedStatesPacificTime: {
            abbr: 'PT',
            country_code: 'US',
            identifier: 'Pacific Time',
            iana: 'US/Pacific',
          },
        },
      ],
    },
  },
};
