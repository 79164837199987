import { useEffect, useState } from 'react';

//Hooks
import { useEventHandlers } from '../useEventHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//Components

//Utility
import {
  checkmark_green,
  clock,
  img_building3,
  img_virtual,
} from '../../../../assets/icons';

const RegistrationButtons = ({
  eventData,
  eventStatus,
  EventPage,
  handleMobileTap,
  setEventStatus,
  organizerViewOn,
}) => {
  //Hooks
  const { handleUserInPersonRegistration, handleUserVirtualRegistration } =
    useEventHandlers();

  const {
    accountId,
    accountRegistrationsInPerson,
    accountPendingApprovalInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalVirtual,
  } = useAccountReduxHandlers();

  //UI state
  const [inPersonStatusUI, setInPersonStatusUI] = useState();
  const [virtualStatusUI, setVirtualStatusUI] = useState();
  const [tapHighlightInPersonRegister, setTapHighlightInPersonRegister] =
    useState(false);
  const [tapHighlightVirtualRegister, setTapHighlightVirtualRegister] =
    useState(false);

  useEffect(() => {
    handleInPersonEventStatusUI();
    handleVirtualEventStatusUI();
    /*eslint-disable-next-line*/
  }, [
    eventData.registrationData,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]);

  //Button UI In Person
  function handleInPersonEventStatusUI() {
    if (accountRegistrationsInPerson.includes(eventData._id)) {
      return setInPersonStatusUI('registered');
    }

    if (accountPendingApprovalInPerson.includes(eventData._id)) {
      return setInPersonStatusUI('pendingApproval');
    }

    if (
      eventData.registrationData?.registeredAttendeesInPerson?.length >=
        eventData.registrationData?.maxRegistrationsInPersonAmount &&
      eventData.registrationData.maxRegistrationsInPersonEnabled
    ) {
      return setInPersonStatusUI('full');
    }

    if (
      eventData.registrationData?.maxRegistrationsInPersonAmount / 2 <=
        eventData.registrationData?.registeredAttendeesInPerson?.length &&
      eventData.registrationData.maxRegistrationsInPersonEnabled
    ) {
      return setInPersonStatusUI('almostFull');
    }

    return setInPersonStatusUI('open');
  } //order of checks matters

  function handleVirtualEventStatusUI() {
    if (accountRegistrationsVirtual.includes(eventData._id)) {
      return setVirtualStatusUI('registered');
    }

    if (accountPendingApprovalVirtual.includes(eventData._id)) {
      return setVirtualStatusUI('pendingApproval');
    }

    if (
      eventData.registrationData?.registeredAttendeesVirtual?.length >=
        eventData.registrationData?.maxRegistrationsVirtualAmount &&
      eventData.registrationData.maxRegistrationsVirtualEnabled
    ) {
      return setVirtualStatusUI('full');
    }

    if (
      eventData.registrationData?.maxRegistrationsVirtualAmount / 2 <=
        eventData.registrationData?.registeredAttendeesVirtual?.length &&
      eventData.registrationData.maxRegistrationsVirtualEnabled
    ) {
      return setVirtualStatusUI('almostFull');
    }

    return setVirtualStatusUI('open');
  } //order of checks matters

  function handleVirtualRegistrationBtn() {
    handleMobileTap(
      [
        () => setTapHighlightVirtualRegister(true),
        () => setTapHighlightVirtualRegister(false),
      ],
      [
        () =>
          handleUserVirtualRegistration(
            eventData,
            accountId,
            eventStatus,
            setEventStatus
          ),
      ],
      true
    );
  }

  function handleInPersonRegistrationBtn() {
    handleMobileTap(
      [
        () => setTapHighlightInPersonRegister(true),
        () => setTapHighlightInPersonRegister(false),
      ],
      [
        () =>
          handleUserInPersonRegistration(
            eventData,
            accountId,
            eventStatus,
            setEventStatus
          ),
      ],
      true
    );
  }

  //Button UI Virtual
  return (
    <div
      className={`registration-action-row  ${
        eventData.hosting.hostingOption === 'hybrid'
          ? 'space-evenly'
          : 'flex-center'
      }`}
    >
      {(eventData.hosting.hostingOption === 'hybrid' ||
        eventData.hosting.hostingOption === 'online') && (
        <button
          className={`rar-btn flex-column highlight-i-theme ${
            tapHighlightVirtualRegister ? ` click-scaledown-95` : ''
          } ${organizerViewOn ? 'opacity-3' : ''}`}
          onClick={!organizerViewOn ? handleVirtualRegistrationBtn : null}
        >
          <div className="flex-row full-width">
            <img
              className="filter-lightgray"
              src={img_virtual}
              alt={EventPage.registrationIcon}
            />
            <div className="flex-column flex-center full-width">
              <div className="flex-row">
                {virtualStatusUI === 'open' && (
                  <div className="flex-column">
                    <p className="fs18">{EventPage.registration}</p>
                    <p className="fs14">{EventPage.open}</p>
                  </div>
                )}
                {virtualStatusUI === 'almostFull' && (
                  <div className="flex-column">
                    <p className="fs18">{EventPage.registration}</p>
                    <p className="fs14">{EventPage.almostFull}</p>
                  </div>
                )}
                {virtualStatusUI === 'full' && (
                  <div className="flex-column">
                    <p className="fs18">{EventPage.registration}</p>
                    <p className="fs14">{EventPage.full}</p>
                  </div>
                )}
                {virtualStatusUI === 'registered' && (
                  <div className="flex-row">
                    <p>{EventPage.registered}</p>
                    <img
                      src={checkmark_green}
                      alt={EventPage.checkmark}
                      className="checkmark"
                    />
                  </div>
                )}
                {virtualStatusUI === 'pendingApproval' && (
                  <>
                    <p>{EventPage.pendingApproval}</p>
                    <img src={clock} alt={EventPage.clock} className="clock" />
                  </>
                )}
              </div>
              <div className="flex-row fs14">
                {(virtualStatusUI === 'pendingApproval' ||
                  virtualStatusUI === 'registered') && (
                  <p className="mrg-t6">{EventPage.clickToUnregister}</p>
                )}

                {virtualStatusUI === 'full' && (
                  <p className="mrg-t6">{EventPage.joinWaitingList}</p>
                )}

                {(inPersonStatusUI === 'pendingApproval' ||
                  inPersonStatusUI === 'registered') &&
                  virtualStatusUI !== 'full' &&
                  virtualStatusUI !== 'pendingApproval' &&
                  virtualStatusUI !== 'registered' && (
                    <p className="mrg-t6">{EventPage.clickToSwitch}</p>
                  )}

                {(virtualStatusUI === 'open' ||
                  virtualStatusUI === 'almostFull') &&
                  inPersonStatusUI !== 'pendingApproval' &&
                  inPersonStatusUI !== 'registered' && (
                    <p className="mrg-t6">{EventPage.clickToRegister}</p>
                  )}
              </div>
            </div>
          </div>
        </button>
      )}

      {(eventData.hosting.hostingOption === 'hybrid' ||
        eventData.hosting.hostingOption === 'inPerson') && (
        <button
          className={`rar-btn flex-column highlight-i-theme ${
            tapHighlightInPersonRegister ? `click-scaledown-95` : ''
          } ${organizerViewOn ? 'opacity-3' : ''}`}
          onClick={!organizerViewOn ? handleInPersonRegistrationBtn : null}
        >
          <div className="flex-row full-width ">
            <img
              className="filter-lightgray"
              src={img_building3}
              alt={EventPage.registrationIcon}
            />
            <div className="flex-column flex-center full-width">
              <div className="flex-row">
                {inPersonStatusUI === 'open' && (
                  <div className="flex-column">
                    <p className="fs18">{EventPage.registration}</p>
                    <p className="fs14">{EventPage.open}</p>
                  </div>
                )}
                {inPersonStatusUI === 'almostFull' && (
                  <div className="flex-column">
                    <p className="fs18">{EventPage.registration}</p>
                    <p className="fs14">{EventPage.almostFull}</p>
                  </div>
                )}
                {inPersonStatusUI === 'full' && (
                  <div className="flex-column">
                    <p className="fs18">{EventPage.registration}</p>
                    <p className="fs14">{EventPage.full}</p>
                  </div>
                )}
                {inPersonStatusUI === 'registered' && (
                  <div className="flex-row">
                    <p>{EventPage.registered}</p>
                    <img
                      src={checkmark_green}
                      alt={EventPage.checkmark}
                      className="checkmark"
                    />
                  </div>
                )}
                {inPersonStatusUI === 'pendingApproval' && (
                  <>
                    <p>{EventPage.pendingApproval}</p>
                    <img src={clock} alt={EventPage.clock} className="clock" />
                  </>
                )}
              </div>

              <div className="flex-row fs14">
                {(inPersonStatusUI === 'pendingApproval' ||
                  inPersonStatusUI === 'registered') && (
                  <p className="mrg-t6">{EventPage.clickToUnregister}</p>
                )}

                {inPersonStatusUI === 'full' && (
                  <p className="mrg-t6">{EventPage.joinWaitingList}</p>
                )}

                {(virtualStatusUI === 'pendingApproval' ||
                  virtualStatusUI === 'registered') &&
                  inPersonStatusUI !== 'full' &&
                  inPersonStatusUI !== 'pendingApproval' &&
                  inPersonStatusUI !== 'registered' && (
                    <p className="mrg-t6">{EventPage.clickToSwitch}</p>
                  )}

                {(inPersonStatusUI === 'open' ||
                  inPersonStatusUI === 'almostFull') &&
                  virtualStatusUI !== 'pendingApproval' &&
                  virtualStatusUI !== 'registered' && (
                    <p className="mrg-t6">{EventPage.clickToRegister}</p>
                  )}
              </div>
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default RegistrationButtons;
