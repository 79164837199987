//hooks
import { useDispatch, useSelector } from 'react-redux';
import { useAdminAccountReduxHandlers } from '../../account/useAdminAccountHandlers';
import { useAdminSettingsHandlers } from '../../settings/useAdminSettingsHandlers';
import { useAdminCreateTicketMutation } from '../adminSupportApiSlice';
import {
  useApproveEventVerificationMutation,
  useCreateInactiveEventVerificationMutation,
  useDisapproveEventVerificationMutation,
  useRemoveEventVerificationMutation,
} from './adminSupportEventVerificationApiSlice';
import {
  selectAdminSupportEventVerifications,
  setAdminSupportEventVerifications,
  setAdminSupportEventVerification,
  removeAdminSupportEventVerificationById,
} from '../adminSupportSlice';

const useAdminSupportEventVerificationHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleAdminSettingsEventVerificationsSortingDate } =
    useAdminSettingsHandlers();

  //API
  const [
    approveEventVerification,
    { isLoading: approveEventVerificationisLoading },
  ] = useApproveEventVerificationMutation();

  const [
    disapproveEventVerification,
    { isLoading: disapproveEventVerificationisLoading },
  ] = useDisapproveEventVerificationMutation();

  const [
    removeAdminEventVerification,
    { isLoading: removeAdminEventVerificationisLoading },
  ] = useRemoveEventVerificationMutation();

  const [
    createInactiveEventVerification,
    { isLoading: createInactiveEventVerificationisLoading },
  ] = useCreateInactiveEventVerificationMutation();

  const [adminCreateTicket, { isLoading: adminCreateTicketisLoading }] =
    useAdminCreateTicketMutation();

  //SELECTORS
  const adminSupportEventVerifications = useSelector(
    selectAdminSupportEventVerifications
  );

  //Redux Handlers
  function handleAdminSupportSetEventVerifications(arr) {
    dispatch(setAdminSupportEventVerifications(arr));
  }

  function handleAdminSupportSetEventVerification(obj) {
    dispatch(setAdminSupportEventVerification(obj));
  }

  function handleAdminSupportRemoveEventVerificationById(str) {
    dispatch(removeAdminSupportEventVerificationById(str));
  }

  //Event Verifications
  async function handleApproveEventVerification(
    eventVerificationId,
    notes,
    adminReference
  ) {
    if (
      !approveEventVerificationisLoading &&
      !createInactiveEventVerificationisLoading &&
      !removeAdminEventVerificationisLoading
    ) {
      const approveEventVerificationResponse = await approveEventVerification({
        adminAccountId,
        eventVerificationId,
        notes,
        adminReference,
      }); //patch

      if (
        approveEventVerificationResponse.data.data.requiresDeletion === true
      ) {
        const createInactiveEventVerificationResponse =
          await createInactiveEventVerification({
            adminAccountId,
            eventVerificationId,
          }); //post

        if (createInactiveEventVerificationResponse.data.status === 'success') {
          await removeAdminEventVerification({
            adminAccountId,
            eventVerificationId,
          }); //delete
        }
      }
    }
  }

  async function handleDisapproveEventVerification(
    eventVerificationId,
    adminReference,
    ticketType,
    ticketReference,
    ticketKey,
    otherTicketDescription,
    ticketDetailedDescription,
    claimAssignment
  ) {
    if (
      !disapproveEventVerificationisLoading &&
      !createInactiveEventVerificationisLoading &&
      !adminCreateTicketisLoading &&
      !removeAdminEventVerificationisLoading
    ) {
      const disapproveEventVerificationResponse =
        await disapproveEventVerification({
          adminAccountId,
          eventVerificationId,
          adminReference,
          ticketDetailedDescription,
        }); //patch

      if (disapproveEventVerificationResponse.data.status === 'success') {
        //create ticket
        const createTicketResponse = adminCreateTicket({
          adminAccountId,
          ticketType,
          ticketReference,
          ticketKey,
          otherTicketDescription,
          ticketDetailedDescription,
          claimAssignment,
        });

        if (createTicketResponse?.data?.status === 'success') {
          const createInactiveEventVerificationResponse =
            await createInactiveEventVerification({
              adminAccountId,
              eventVerificationId,
            }); //post

          if (
            createInactiveEventVerificationResponse.data.status === 'success'
          ) {
            await removeAdminEventVerification({
              adminAccountId,
              eventVerificationId,
            }); //delete
          }
        }
      }
    }
  }

  function handleAdminSupportEventVerificationsDateSorting(sortingBoolean) {
    const copyArr = [...adminSupportEventVerifications];

    let newSortingOrder;
    if (sortingBoolean === true) {
      newSortingOrder = 'descending';
    } else {
      newSortingOrder = 'ascending';
    }
    const sortedEventVerifications = copyArr.sort((a, b) => {
      const dateA = a?.event?.eventId?.meta?.lastModified;
      const dateB = b?.event?.eventId?.meta?.lastModified;

      if (newSortingOrder === 'descending') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    handleAdminSupportSetEventVerifications(sortedEventVerifications);
    handleAdminSettingsEventVerificationsSortingDate(newSortingOrder);
  }

  function handleSocketEventVerificationsData(dataString) {
    let parsedData;
    try {
      parsedData = JSON.parse(dataString);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Dev, Error parsing JSON:', error);
      }
    }

    const docId = parsedData?.id;
    const operationType = parsedData.operationType;

    if (operationType) {
      if (operationType === 'delete' && docId) {
        handleAdminSupportRemoveEventVerificationById(docId);
      } else if (
        parsedData.operationType === 'update' ||
        parsedData.operationType === 'insert'
      ) {
        if (parsedData?.eventVerification) {
          handleAdminSupportSetEventVerification(parsedData.eventVerification);
        }
      }
    }
  }

  return {
    adminSupportEventVerifications,
    handleAdminSupportEventVerificationsDateSorting,
    handleAdminSupportSetEventVerification,
    handleAdminSupportSetEventVerifications,
    handleApproveEventVerification,
    handleDisapproveEventVerification,
    handleAdminSupportRemoveEventVerificationById,
    handleSocketEventVerificationsData,

    //loading
    approveEventVerificationisLoading,
    disapproveEventVerificationisLoading,
    removeAdminEventVerificationisLoading,
    createInactiveEventVerificationisLoading,
    adminCreateTicketisLoading,
  };
};

export default useAdminSupportEventVerificationHandlers;
