import { apiSlice } from '../../../app/api/apiSlice';
import { setAdminAccount } from './adminAccountSlice';

export const adminAccountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myAdminAccount: builder.query({
      query: () => '/api/admin/account/myAccount',
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { _id, meta, appSettings, company, personal, teams } =
            data.myAdminAccount;
          dispatch(
            setAdminAccount({
              _id,
              meta,
              appSettings,
              company,
              personal,
              teams,
            })
          );
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('accountApiSlice, myAccount error:', error);
          }
        }
      },
      providesTags: ['AdminAccount'],
    }),
  }),
});

export const { useLazyMyAdminAccountQuery } = adminAccountApiSlice;
