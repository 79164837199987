import { useDispatch, useSelector } from 'react-redux';
import {
  setAdminSettingsTablesTicketHeadings,
  setAdminSettingsTablesTicketSubHeadings,
  selectAdminSettingsSelectTablesTicketHeadings,
  selectAdminSettingsSelectTablesTicketSubHeadings,
  selectAdminSettingsManagementTicketsTableSupportUserInput,
  selectAdminSettingsManagementTicketsTableDateEnd,
  selectAdminSettingsManagementTicketsTableDateStart,
  selectAdminSettingsManagementTicketsTableHasMore,
  selectAdminSettingsManagementTicketsTableIssue,
  selectAdminSettingsManagementTicketsTableStatus,
  selectAdminSettingsManagementTicketsTableType,
  setAdminSettingsManagementTicketsTableHasMore,
  setAdminSettingsManagementTicketsTableStatus,
  setAdminSettingsManagementTicketsTableIssue,
  setAdminSettingsManagementTicketsTableType,
  setAdminSettingsManagementTicketsTableDateStart,
  setAdminSettingsManagementTicketsTableDateEnd,
  setAdminSettingsManagementTicketsTableSupportUserInput,
  setAdminSettingsManagementTicketsTableSupportUser,
  selectAdminSettingsManagementTicketsTableSupportUser,
  selectAdminSettingsManagementTicketsTableSupportUserSearchType,
  setAdminSettingsManagementTicketsTableSupportUserSearchType,
  setAdminSettingsManagementTicketsTableSubmitUser,
  setAdminSettingsManagementTicketsTableSubmitUserSearchType,
  setAdminSettingsManagementTicketsTableSubmitUserInput,
  selectAdminSettingsManagementTicketsTableSubmitUser,
  selectAdminSettingsManagementTicketsTableSubmitUserSearchType,
  selectAdminSettingsManagementTicketsTableSubmitUserInput,
  setAdminSettingsManagementTicketsTableSubmitUserFilter,
  selectAdminSettingsManagementTicketsTableSubmitUserFilter,
  setAdminSettingsManagementTicketsTablePage,
  selectAdminSettingsManagementTicketsTablePage,
  setAdminSettingsManagementTicketsTableResultsPerPage,
  selectAdminSettingsManagementTicketsTableResultsPerPage,
  resetAdminSettingsTablesTicketHeadings,
  resetAdminSettingsTablesTicketSubHeadings,
  setAdminSettingsTablesTicketSortingDate,
  selectAdminSettingsTablesTicketSortingDate,
  resetAdminSettingsEventVerificationsHeadingsSize,
  resetAdminSettingsEventVerificationsSubHeadingsSize,
  setAdminSettingsEventVerificationsHeadingsSize,
  selectAdminSettingsEventVerificationsHeadingsSize,
  setAdminSettingsEventVerificationsSubHeadingsSize,
  selectAdminSettingsEventVerificationsSubHeadingsSize,
  setAdminSettingsEventVerificationsSortingDate,
  selectAdminSettingsEventVerificationsSortingDate,
  setAdminSettingsTechnicalHeadingsSize,
  resetAdminSettingsTechnicalHeadingsSize,
  setAdminSettingsTechnicalSortingDate,
  selectAdminSettingsTechnicalHeadingsSize,
  selectAdminSettingsTechnicalSortingDate,
  resetAdminSettingsTechnicalSubHeadingsSize,
  selectAdminSettingsTechnicalSubHeadingsSize,
  setAdminSettingsTechnicalSubHeadingsSize,
} from './adminSettingsSlice';

export const useAdminSettingsHandlers = () => {
  //Hooks
  const dispatch = useDispatch();

  //Tickets Table
  const adminSettingsTablesTicketHeadings = useSelector(
    selectAdminSettingsSelectTablesTicketHeadings
  );
  const adminSettingsTablesTicketSubHeadings = useSelector(
    selectAdminSettingsSelectTablesTicketSubHeadings
  );
  const adminSettingsTablesTicketSortingDate = useSelector(
    selectAdminSettingsTablesTicketSortingDate
  );

  //Admin Management - Tickets Table Query
  const adminSettingsManagementTicketsTableHasMore = useSelector(
    selectAdminSettingsManagementTicketsTableHasMore
  );
  const adminSettingsManagementTicketsTableStatus = useSelector(
    selectAdminSettingsManagementTicketsTableStatus
  );
  const adminSettingsManagementTicketsTableIssue = useSelector(
    selectAdminSettingsManagementTicketsTableIssue
  );
  const adminSettingsManagementTicketsTableType = useSelector(
    selectAdminSettingsManagementTicketsTableType
  );
  const adminSettingsManagementTicketsTableDateStart = useSelector(
    selectAdminSettingsManagementTicketsTableDateStart
  );
  const adminSettingsManagementTicketsTableDateEnd = useSelector(
    selectAdminSettingsManagementTicketsTableDateEnd
  );
  const adminSettingsManagementTicketsTableSupportUserInput = useSelector(
    selectAdminSettingsManagementTicketsTableSupportUserInput
  );
  const adminSettingsManagementTicketsTableSupportUser = useSelector(
    selectAdminSettingsManagementTicketsTableSupportUser
  );
  const adminSettingsManagementTicketsTableSupportUserSearchType = useSelector(
    selectAdminSettingsManagementTicketsTableSupportUserSearchType
  );

  const adminSettingsManagementTicketsTableSubmitUser = useSelector(
    selectAdminSettingsManagementTicketsTableSubmitUser
  );
  const adminSettingsManagementTicketsTableSubmitUserSearchType = useSelector(
    selectAdminSettingsManagementTicketsTableSubmitUserSearchType
  );
  const adminSettingsManagementTicketsTableSubmitUserInput = useSelector(
    selectAdminSettingsManagementTicketsTableSubmitUserInput
  );

  const adminSettingsManagementTicketsTableSubmitUserFilter = useSelector(
    selectAdminSettingsManagementTicketsTableSubmitUserFilter
  );

  const adminSettingsManagementTicketsTablePage = useSelector(
    selectAdminSettingsManagementTicketsTablePage
  );

  const adminSettingsManagementTicketsTableResultsPerPage = useSelector(
    selectAdminSettingsManagementTicketsTableResultsPerPage
  );

  //Event Review
  const adminSettingsEventVerificationsHeadingsSize = useSelector(
    selectAdminSettingsEventVerificationsHeadingsSize
  );
  const adminSettingsEventVerificationsSubHeadingsSize = useSelector(
    selectAdminSettingsEventVerificationsSubHeadingsSize
  );
  const adminSettingsEventVerificationsSortingDate = useSelector(
    selectAdminSettingsEventVerificationsSortingDate
  );

  //Technical
  const adminSettingsTechnicalSortingDate = useSelector(
    selectAdminSettingsTechnicalSortingDate
  );
  const adminSettingsTechnicalHeadingsSize = useSelector(
    selectAdminSettingsTechnicalHeadingsSize
  );

  const adminSettingsTechnicalSubHeadingsSize = useSelector(
    selectAdminSettingsTechnicalSubHeadingsSize
  );

  //Tickets table
  function handleSetAdminSettingsTablesTicketHeadings(arr) {
    dispatch(setAdminSettingsTablesTicketHeadings(arr));
  }

  function handleSetAdminSettingsTablesTicketSubHeadings(arr) {
    dispatch(setAdminSettingsTablesTicketSubHeadings(arr));
  }

  function handleAdminSettingsResetTablesTicketHeadings() {
    dispatch(resetAdminSettingsTablesTicketHeadings());
  }

  function handleAdminSettingsResetTablesTicketSubHeadings() {
    dispatch(resetAdminSettingsTablesTicketSubHeadings());
  }

  function handleAdminSettingsTablesTicketSortingDate(str) {
    dispatch(setAdminSettingsTablesTicketSortingDate(str));
  }

  //Admin Management - Tickets Table Query
  function handleAdminSettingsManagementTicketsTableHasMore(boolean) {
    dispatch(setAdminSettingsManagementTicketsTableHasMore(boolean));
  }
  function handleAdminSettingsManagementTicketsTableStatus(arr) {
    dispatch(setAdminSettingsManagementTicketsTableStatus(arr));
  }
  function handleAdminSettingsManagementTicketsTableIssue(arr) {
    dispatch(setAdminSettingsManagementTicketsTableIssue(arr));
  }
  function handleAdminSettingsManagementTicketsTableType(arr) {
    dispatch(setAdminSettingsManagementTicketsTableType(arr));
  }
  function handleAdminSettingsManagementTicketsTableDateStart(str) {
    dispatch(setAdminSettingsManagementTicketsTableDateStart(str));
  }
  function handleAdminSettingsManagementTicketsTableDateEnd(str) {
    dispatch(setAdminSettingsManagementTicketsTableDateEnd(str));
  }

  //CURRENT SUPPORT
  function handleAdminSettingsManagementTicketsTableSupportUser(boolean) {
    dispatch(setAdminSettingsManagementTicketsTableSupportUser(boolean));

    if (boolean === false) {
      dispatch(setAdminSettingsManagementTicketsTableSupportUserInput(''));
      dispatch(setAdminSettingsManagementTicketsTableSupportUserSearchType(''));
    }
  }

  function handleAdminSettingsManagementTicketsTableSupportUserSearchType(str) {
    dispatch(setAdminSettingsManagementTicketsTableSupportUserSearchType(str));
  }

  function handleAdminSettingsManagementTicketsTableSupportUserInput(str) {
    dispatch(setAdminSettingsManagementTicketsTableSupportUserInput(str));
  }

  //SUBMIT USER
  function handleAdminSettingsManagementTicketsTableSubmitUser(boolean) {
    dispatch(setAdminSettingsManagementTicketsTableSubmitUser(boolean));

    if (boolean === false) {
      dispatch(setAdminSettingsManagementTicketsTableSubmitUserInput(''));
      dispatch(setAdminSettingsManagementTicketsTableSubmitUserSearchType(''));
    }
  }

  function handleAdminSettingsManagementTicketsTableSubmitUserSearchType(str) {
    dispatch(setAdminSettingsManagementTicketsTableSubmitUserSearchType(str));
  }

  function handleAdminSettingsManagementTicketsTableSubmitUserInput(str) {
    dispatch(setAdminSettingsManagementTicketsTableSubmitUserInput(str));
  }

  function handleAdminSettingsManagementTicketsTableSubmitUserFilter(str) {
    if (str === adminSettingsManagementTicketsTableSubmitUserFilter) {
      dispatch(setAdminSettingsManagementTicketsTableSubmitUserFilter(''));
    } else {
      dispatch(setAdminSettingsManagementTicketsTableSubmitUserFilter(str));
    }
  }

  function handleAdminSettingsManagementTicketsTablePage(num) {
    dispatch(setAdminSettingsManagementTicketsTablePage(num));
  }
  function handleAdminSettingsManagementTicketsTableResultsPerPage(num) {
    dispatch(setAdminSettingsManagementTicketsTableResultsPerPage(num));
  }

  //Event Review
  function handleAdminSettingsEventVerificationsHeadingsSize(arr) {
    dispatch(setAdminSettingsEventVerificationsHeadingsSize(arr));
  }

  function handleAdminSettingsEventVerificationsSubHeadingsSize(arr) {
    dispatch(setAdminSettingsEventVerificationsSubHeadingsSize(arr));
  }

  function handleAdminSettingsResetEventVerificationsHeadingsSize() {
    dispatch(resetAdminSettingsEventVerificationsHeadingsSize());
  }

  function handleAdminSettingsResetEventVerificationsSubHeadingsSize() {
    dispatch(resetAdminSettingsEventVerificationsSubHeadingsSize());
  }

  function handleAdminSettingsEventVerificationsSortingDate(str) {
    dispatch(setAdminSettingsEventVerificationsSortingDate(str));
  }

  //Technical
  function handleAdminSettingsTechnicalSetHeadingsSize(arr) {
    dispatch(setAdminSettingsTechnicalHeadingsSize(arr));
  }

  function handleAdminSettingsTechnicalSubHeadingsSize(arr) {
    dispatch(setAdminSettingsTechnicalSubHeadingsSize(arr));
  }

  function handleAdminSettingsResetTechnicalHeadingsSize() {
    dispatch(resetAdminSettingsTechnicalHeadingsSize());
  }

  function handleAdminSettingsResetTechnicalSubHeadingsSize() {
    dispatch(resetAdminSettingsTechnicalSubHeadingsSize());
  }

  function handleAdminSettingsTechnicalSortingDate(str) {
    dispatch(setAdminSettingsTechnicalSortingDate(str));
  }

  return {
    adminSettingsTablesTicketHeadings,
    adminSettingsTablesTicketSubHeadings,
    handleSetAdminSettingsTablesTicketHeadings,
    handleSetAdminSettingsTablesTicketSubHeadings,
    handleAdminSettingsResetTablesTicketHeadings,
    handleAdminSettingsResetTablesTicketSubHeadings,

    //Admin Management - Tickets Table Query
    adminSettingsManagementTicketsTableSupportUserInput,
    adminSettingsManagementTicketsTableDateEnd,
    adminSettingsManagementTicketsTableDateStart,
    adminSettingsManagementTicketsTableHasMore,
    adminSettingsManagementTicketsTableIssue,
    adminSettingsManagementTicketsTableStatus,
    adminSettingsManagementTicketsTableType,
    adminSettingsManagementTicketsTableSupportUser,
    adminSettingsManagementTicketsTableSubmitUser,
    adminSettingsManagementTicketsTableSubmitUserSearchType,
    adminSettingsManagementTicketsTableSubmitUserInput,
    adminSettingsManagementTicketsTableSubmitUserFilter,
    handleAdminSettingsManagementTicketsTableSupportUserInput,
    handleAdminSettingsManagementTicketsTableDateEnd,
    handleAdminSettingsManagementTicketsTableDateStart,
    handleAdminSettingsManagementTicketsTableHasMore,
    handleAdminSettingsManagementTicketsTableIssue,
    handleAdminSettingsManagementTicketsTableStatus,
    handleAdminSettingsManagementTicketsTableType,
    handleAdminSettingsManagementTicketsTableSupportUser,
    adminSettingsManagementTicketsTableSupportUserSearchType,
    handleAdminSettingsManagementTicketsTableSupportUserSearchType,
    handleAdminSettingsManagementTicketsTableSubmitUser,
    handleAdminSettingsManagementTicketsTableSubmitUserSearchType,
    handleAdminSettingsManagementTicketsTableSubmitUserInput,
    handleAdminSettingsManagementTicketsTableSubmitUserFilter,
    adminSettingsManagementTicketsTablePage,
    handleAdminSettingsManagementTicketsTablePage,
    adminSettingsManagementTicketsTableResultsPerPage,
    handleAdminSettingsManagementTicketsTableResultsPerPage,
    adminSettingsTablesTicketSortingDate,
    handleAdminSettingsTablesTicketSortingDate,

    //event review
    adminSettingsEventVerificationsHeadingsSize,
    handleAdminSettingsEventVerificationsHeadingsSize,
    adminSettingsEventVerificationsSubHeadingsSize,
    handleAdminSettingsEventVerificationsSubHeadingsSize,
    adminSettingsEventVerificationsSortingDate,
    handleAdminSettingsEventVerificationsSortingDate,
    handleAdminSettingsResetEventVerificationsHeadingsSize,
    handleAdminSettingsResetEventVerificationsSubHeadingsSize,

    //technical
    adminSettingsTechnicalSortingDate,
    adminSettingsTechnicalHeadingsSize,
    handleAdminSettingsTechnicalSetHeadingsSize,
    handleAdminSettingsResetTechnicalHeadingsSize,
    handleAdminSettingsTechnicalSortingDate,

    adminSettingsTechnicalSubHeadingsSize,
    handleAdminSettingsResetTechnicalSubHeadingsSize,
    handleAdminSettingsTechnicalSubHeadingsSize,
  };
};
