import { useEffect, useState } from 'react';

//Hooks
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import useSettings from '../../../context/useSettings';
import { useGetTicketsSupportQuery } from './adminSupportApiSlice';
import { useGetEventVerificationsQuery } from './EventVerification/adminSupportEventVerificationApiSlice';
import { useAdminSupportReduxHandlers } from './useAdminSupportHandlers';
import useApiStatus from '../../../context/useApiStatus';
import useAdminSupportEventVerificationHandlers from './EventVerification/useAdminSupportEventVerificationHandlers';
import { useTicketsSocket } from '../../../context/sockets/TicketsSocketProvider';

//Components
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import AdminSupportNav from './AdminSupportNav';
import AdminTickets from '../utils/AdminTickets';
import AdminSearch from '../utils/AdminSearch';
import AdminCardsRowSupport from '../utils/AdminCardsRowSupport';
import EventVerification from './EventVerification/EventVerification';
import AdminCreateTicket from '../utils/AdminCreateTicket';

//Utils
const AdminSupport = () => {
  //hooks
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleWarning } = useApiStatus();
  const { setNavbar } = useSettings();
  const {
    adminSupportTickets,
    adminSupportSearchQueries,
    handleAdminSupportTicketsSorting,
  } = useAdminSupportReduxHandlers();

  const { adminSupportEventVerifications } =
    useAdminSupportEventVerificationHandlers();

  //Sockets
  const { connectTicketsSocket, closeTicketsSocket, ticketsSocketStatus } =
    useTicketsSocket();

  //API
  const {
    isLoading: getTicketsSupportisLoading,
    isFetching: getTicketsSupportisFetching,
  } = useGetTicketsSupportQuery({
    adminAccountId,
  });
  const {
    isLoading: getEventVerificationsisLoading,
    isFetching: getEventVerificationsisFetching,
  } = useGetEventVerificationsQuery({
    adminAccountId,
  });

  //component state
  const [view, setView] = useState('tickets');
  const [cardsRowIds, setCardsRowIds] = useState([]);

  //UI
  const [pageLoadComplete, setPageLoadComplete] = useState();
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);

  //Initialize
  useEffect(() => {
    setNavbar('support');
  }, []);

  useEffect(() => {
    connectTicketsSocket();

    return () => {
      closeTicketsSocket();
    };
  }, []); //web socket for tickets

  function handleCardRowSelection(selectionId) {
    if (cardsRowIds.includes(selectionId)) {
      const newWarning = {
        message: 'alreadySelected',
        id: Date.now(),
        origin: 'AdminSupport.js/handleCardRowSelection',
      };
      return handleWarning(newWarning);
    }

    //No duplicate
    let newArr = [...cardsRowIds, selectionId];
    setCardsRowIds(newArr);
  }

  return (
    <>
      {pageLoadComplete ? (
        <div className="admin">
          <AdminSupportNav setView={setView} view={view} />
          <div className="admin-tickets__wrapper">
            <div className="flex-row">
              <AdminSearch
                cardsRowIds={cardsRowIds}
                setCardsRowIds={setCardsRowIds}
                managementMode={false}
              />
              <AdminCreateTicket />
            </div>
            <AdminCardsRowSupport
              currentSearchIndex={currentSearchIndex}
              setCurrentSearchIndex={setCurrentSearchIndex}
              cardsRowIds={cardsRowIds}
              setCardsRowIds={setCardsRowIds}
              adminTickets={adminSupportTickets}
              adminSupportEventVerifications={adminSupportEventVerifications}
              adminSearchQueries={adminSupportSearchQueries}
            />

            <AdminTickets
              ticketsData={adminSupportTickets}
              handleCardRowSelection={handleCardRowSelection}
              managementMode={false}
              isLoading={getTicketsSupportisLoading}
              isFetching={getTicketsSupportisFetching}
              handleTicketsSorting={handleAdminSupportTicketsSorting}
              currentViewDetailsSelections={cardsRowIds}
              connectionStatusTrackerOn={true}
              connectionStatus={ticketsSocketStatus}
            />

            <EventVerification
              eventVerificationsData={adminSupportEventVerifications}
              handleCardRowSelection={handleCardRowSelection}
              isLoading={getEventVerificationsisLoading}
              isFetching={getEventVerificationsisFetching}
              currentViewDetailsSelections={cardsRowIds}
            />
            <div className="h72" />
            <div className="h72" />
          </div>
        </div>
      ) : (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          setLoadComplete={() => setPageLoadComplete(true)}
          loadCheck1={adminAccountId ? true : false}
          customWrapper={'min-full-height'}
        />
      )}
    </>
  );
};

export default AdminSupport;
