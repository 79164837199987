const LogTargetAdminDetails = ({ logData }) => {
  return (
    <div className="flex-column">
      <div className="flex-row fs18 fwn">
        <p>Name:</p>&nbsp;
        <p>
          {logData?.personal?.prefix} {logData?.personal?.firstName}{' '}
          {logData?.personal?.lastName}
        </p>
        {logData?.personal?.pronouns && (
          <p>
            &nbsp;(
            {logData?.personal?.pronouns})
          </p>
        )}
      </div>
      <div className="flex-row fs18 fwn">
        <p>Position:</p>&nbsp;
        <p>{logData?.company?.position}</p>
      </div>
      <div className="flex-row fs18 fwn">
        <p>Authorizations:</p>&nbsp;
        {logData?.authorizations?.roles.includes(
          process.env.REACT_APP_ADMIN_USER
        ) && <p>User </p>}
        {logData?.authorizations?.roles.includes(
          process.env.REACT_APP_ADMIN_SUPPORT
        ) && <p>&nbsp;;&nbsp;Support</p>}
        {logData?.authorizations?.roles.includes(
          process.env.REACT_APP_ADMIN_ANALYST
        ) && <p>&nbsp;;&nbsp;Analyst </p>}
        {logData?.authorizations?.roles.includes(
          process.env.REACT_APP_ADMIN_MANAGEMENT
        ) && <p>&nbsp;;&nbsp;Management</p>}
      </div>
      <div className="flex-row fs18 fwn">
        <p>Email:</p>&nbsp;
        <p>{logData?.personal?.email}</p>
      </div>
    </div>
  );
};

export default LogTargetAdminDetails;
