import { useEffect, useState } from 'react';

//hooks
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';
import useSettings from '../../../../../context/useSettings';

//components
import TextArea from '../../../../../components/TextArea/TextArea';
import AutocorrectKeywords from '../../../../../components/AutocorrectKeywords/AutocorrectKeywords';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../components/Spinner/Spinner';

//utility
import { image_create_list } from '../../../../../assets/images';
import {
  arrow_long_h,
  checkmark_green,
  img_enter,
  warning,
} from '../../../../../assets/icons';

const CreateRoomSpeakersAutocorrect = () => {
  //hooks
  const {
    speakersData,
    setSpeakersData,
    keywordsListLengthMaximum,
    hostCreateRoom,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
  } = useTranslationRoomSocket();
  const { CreateRoomSpeakersAutocorrect } = useLanguageTranslationRoom();
  const { isMobileDevice } = useSettings();
  //state
  const [currentSpeakerIndex, setCurrentSpeakerIndex] = useState(0);
  const [speakerContentIsLoaded, setSpeakerContentIsLoaded] = useState(false);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSpeakerContentIsLoaded(true);
    }, 250);

    return () => clearTimeout(timerId);
  }, [speakerContentIsLoaded]); //changing speaker loading ui

  //functions
  function handleNext() {
    if (currentSpeakerIndex < 9) {
      setSpeakerContentIsLoaded(false);
      let newSpeakerIndex = currentSpeakerIndex + 1;
      return setCurrentSpeakerIndex(newSpeakerIndex);
    }
  }
  function handlePrevious() {
    if (currentSpeakerIndex > 0) {
      setSpeakerContentIsLoaded(false);
      let newSpeakerIndex = currentSpeakerIndex - 1;
      return setCurrentSpeakerIndex(newSpeakerIndex);
    }
  }

  function handleDiscussionContext(value, index) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[index].discussionContext = value;
    setSpeakersData(newSpeakersData);
  }

  function handleKeywordList(newKeywordList, index) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[index].keywordsList = newKeywordList;
    setSpeakersData(newSpeakersData);
  }

  return (
    <>
      {renderSpinner && (
        <Spinner minHeight={'100%'} customClassName={'mrg-t24'} />
      )}
      <div
        className={`translations-selection__container ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_create_list}
          alt="img"
          style={{ height: '200px' }}
          className="svg mrg-t24"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p
            className="fs21 fwsb mrg-t24 text-center access-ob access-o6"
            tabIndex="0"
            aria-label={CreateRoomSpeakersAutocorrect.autocorrectionForSpeakers}
          >
            {CreateRoomSpeakersAutocorrect.autocorrectionForSpeakers}
          </p>

          <div className="translations-speaker-btn__container">
            <button
              className={`translations-speaker-btn mrg-auto-right highlight-i-lgt ${
                currentSpeakerIndex === 0
                  ? 'opacity-0 pointer-default disable-select'
                  : ''
              }`}
              onClick={handlePrevious}
              aria-hidden={currentSpeakerIndex === 0 ? true : false}
              tabIndex={currentSpeakerIndex === 0 ? '-1' : '0'}
            >
              <img
                src={arrow_long_h}
                alt={CreateRoomSpeakersAutocorrect.next}
                className="reverse"
              />
            </button>
            <p
              className="fs21 fwsb mrg-l24 mrg-r24 text-center access-ob access-o6"
              tabIndex="0"
            >
              {speakersData?.map((speaker, index) => {
                if (index === currentSpeakerIndex) {
                  return `${
                    speaker.name
                      ? speaker.name
                      : `${CreateRoomSpeakersAutocorrect.speaker} ${index + 1}`
                  }`;
                }
              })}
            </p>
            <button
              className={`translations-speaker-btn  mrg-auto-left highlight-i-lgt ${
                currentSpeakerIndex === speakersData?.length - 1
                  ? 'opacity-0 pointer-default disable-select'
                  : ''
              }`}
              onClick={handleNext}
              aria-hidden={
                currentSpeakerIndex === speakersData?.length ? true : false
              }
              tabIndex={
                currentSpeakerIndex === speakersData?.length ? '-1' : '0'
              }
            >
              <img
                src={arrow_long_h}
                alt={CreateRoomSpeakersAutocorrect.next}
              />
            </button>
          </div>

          <div className="flex-row full-width flex-center mrg-t12">
            {speakersData?.map((speaker, index) =>
              speaker?.discussionContext?.length > 24 ? (
                <img
                  alt={CreateRoomSpeakersAutocorrect.checkmark}
                  src={checkmark_green}
                  className={`access-ob access-o6 ${
                    index !== 0 ? 'mrg-l6' : ''
                  }`}
                  key={`checkmark-${index}`}
                />
              ) : (
                <div
                  className={`dot ${
                    currentSpeakerIndex === index
                      ? 'bg-color-theme'
                      : 'bg-color-lightgray'
                  } ${index !== 0 ? 'mrg-l6' : ''} access-ob access-o6`}
                  key={`dot-${index}`}
                />
              )
            )}
          </div>

          <div className="divider full-width mrg-t24 mrg-b12" />

          {speakersData?.map((speakerData, index) => {
            if (currentSpeakerIndex === index) {
              return (
                <div key={`speaker-info-${index}`}>
                  {!speakerContentIsLoaded && (
                    <Spinner customClassName={'mrg-t24'} />
                  )}
                  <div
                    className={`flex-column full-width ${
                      !speakerContentIsLoaded ? 'opacity-0' : ''
                    }`}
                  >
                    <p
                      className="fs21 fwsb mrg-tb24 text-center access-ob access-o6"
                      tabIndex="0"
                      aria-label={
                        CreateRoomSpeakersAutocorrect.discussionContext
                      }
                      id={'createRoom'}
                    >
                      {
                        CreateRoomSpeakersAutocorrect.aiAutocorrectionDescription
                      }
                    </p>

                    <TextArea
                      handleChange={handleDiscussionContext}
                      id={`${index}`}
                      labelText={
                        CreateRoomSpeakersAutocorrect.discussionContext
                      }
                      large={true}
                      limit={250}
                      loadTrigger={true}
                      loadValue={
                        speakerData?.discussionContext
                          ? speakerData?.discussionContext
                          : process.env.REACT_APP_ENV === 'development'
                          ? 'A history lecture on ww1.'
                          : ''
                      }
                      asterisk={true}
                      onBlurHandlerOff={true}
                      disableEnter={true}
                    />
                    {speakerData?.discussionContext?.length < 25 && (
                      <div className="flex-row flex-center mrg-t12">
                        <img
                          src={warning}
                          alt="img"
                          style={{ height: '18px' }}
                          className="mrg-r12"
                        />
                        <p className="fs18">
                          {
                            CreateRoomSpeakersAutocorrect.minimumCharacterRequirements
                          }
                        </p>
                      </div>
                    )}
                    <div className="h24" />

                    <p
                      className="fs21 fwsb mrg-tb24 access-ob access-o6"
                      tabIndex="0"
                      aria-label={
                        CreateRoomSpeakersAutocorrect.autoCorrectKeywords
                      }
                      id={'createRoom'}
                    >
                      {CreateRoomSpeakersAutocorrect.autoCorrectKeywords}
                    </p>
                    <AutocorrectKeywords
                      limit={keywordsListLengthMaximum}
                      index={index}
                      speakersKeywordsList={speakerData.keywordsList}
                      setSpeakersKeywordsList={handleKeywordList}
                    />
                  </div>
                </div>
              );
            }
          })}

          <ItemButton
            text={CreateRoomSpeakersAutocorrect.createRoom}
            handler={() => {
              handleCreatePageChange(7);
              hostCreateRoom(false);
            }}
            customWrapper={'mrg-t24 full-width'}
            iconOn={true}
            icon={img_enter}
            unavailable={
              speakersData.some(
                (speaker) => speaker.discussionContext?.length < 25
              ) ||
              speakersData.some(
                (speaker) => speaker.keywordsList?.length > 50
              ) ||
              isMobileDevice
            }
          />
          {isMobileDevice && (
            <div className="flex-row flex-center mrg-t12">
              <img
                src={warning}
                alt="img"
                style={{ height: '18px' }}
                className="mrg-r12"
              />
              <p className="fs18">
                {CreateRoomSpeakersAutocorrect.mobileDevicesNotPermittedToHost}
              </p>
            </div>
          )}

          <div className="divider full-width mrg-t36 mrg-b12" />
          <div className="h24" />

          <ItemButton
            text={CreateRoomSpeakersAutocorrect.back}
            handler={() => handleCreatePageChange(5)}
            customWrapper={'full-width'}
          />
          <div className="h72" />
        </div>
      </div>
    </>
  );
};

export default CreateRoomSpeakersAutocorrect;
