//Hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
//Components
import FollowButton from '../../../../components/FollowButton/FollowButton';
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//Utility
import { calendar, clock } from '../../../../assets/icons';
import {
  GetDateTimeSummary,
  getDateLongMonth,
  getDateShortMonth,
  GetTimeRangeFromEventObject,
  GetDateTimeSummaryForEventPreview,
} from '../../../../utils/dateTime/DateCalc';

const HeadingSummary = ({
  eventData,
  EventPage,
  width,
  getNewNetworkEventsList,
  organizerViewOn,
  previewMode,
}) => {
  //Hooks
  const {
    accountContentLanguage,
    accountTimeZone,
    accountProfileImage,
    accountFirstName,
    accountLastName,
  } = useAccountReduxHandlers();

  return (
    <div className="ev-heading-summary ">
      <div className="heading-row fwn fs18">
        <div className="flex-row align-center">
          <img
            className="mrg-r12 heading-icon"
            src={calendar}
            alt={EventPage.calendarIcon}
          />
          <div className="flex-column access-o6 access-ob" tabIndex="0">
            {width > 480 ? (
              <>
                {getDateLongMonth(
                  eventData.dateTime.date,
                  accountContentLanguage
                )}
              </>
            ) : (
              <>
                {getDateShortMonth(
                  eventData.dateTime.date,
                  accountContentLanguage
                )}
              </>
            )}
            {width <= 320 && (
              <div
                className="access-o6 access-ob color-theme fwsb"
                tabIndex="0"
              >
                {!previewMode
                  ? GetDateTimeSummary(eventData.dateTime, accountTimeZone)
                  : GetDateTimeSummaryForEventPreview(
                      eventData.dateTime,
                      accountTimeZone
                    )}
              </div>
            )}
          </div>
        </div>

        <div className="flex-row color-theme fwsb mrg-l12">
          {width > 320 && (
            <div className="access-o6 access-ob" tabIndex="0">
              {!previewMode
                ? GetDateTimeSummary(eventData.dateTime, accountTimeZone)
                : GetDateTimeSummaryForEventPreview(
                    eventData.dateTime,
                    accountTimeZone
                  )}
            </div>
          )}
        </div>
      </div>
      <div className="flex-row align-center mrg-t12">
        <img
          className="mrg-r12 heading-icon filter-theme"
          src={clock}
          alt={EventPage.calendarIcon}
        />
        <p className="fwn fs18">
          {GetTimeRangeFromEventObject(eventData.dateTime)}
        </p>
      </div>
      <div className="heading-row fwn fs18 mrg-t12">
        <div className="flex-row align-center ">
          {previewMode ? (
            <LoadBucketImage
              imagePath={accountProfileImage}
              imgClassName="tag-profile-img profile-img mrg-r12"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
            />
          ) : (
            <LoadBucketImage
              imagePath={
                organizerViewOn
                  ? eventData.organizers?.organizerId[0]?.personal.profileImage
                  : eventData?.organizer?.[0]?.personal.profileImage
              }
              imgClassName="tag-profile-img profile-img mrg-r12"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
            />
          )}

          <div className="flex-column access-ob access-o6" tabIndex="0">
            <p>{EventPage.organizer}</p>
            {previewMode ? (
              <p>
                {accountFirstName} {accountLastName}
              </p>
            ) : (
              <p>
                {organizerViewOn
                  ? eventData.organizers?.organizerId[0]?.personal.firstName
                  : eventData?.organizer?.[0]?.personal.firstName}
                &nbsp;
                {organizerViewOn
                  ? eventData.organizers?.organizerId[0]?.personal.lastName
                  : eventData?.organizer?.[0]?.personal.lastName}
              </p>
            )}
          </div>
        </div>
        <FollowButton
          followableAccountId={eventData?.organizer?.[0]?._id}
          getNewNetworkEventsList={getNewNetworkEventsList}
        />
      </div>
    </div>
  );
};

export default HeadingSummary;
