//hooks
//components
//utils

const InfoCardContainerVerifications = ({ verificationsData }) => {
  //hooks
  //state
  //ui
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Verifications</p>

      <p className="mrg-t6">
        Current Verifications (Admin Refs):{' '}
        {verificationsData?.currentVerifications?.map(
          (verificationRef, index) =>
            `[${verificationRef}] ${
              index !== verificationsData?.currentVerifications?.length - 1
                ? ', '
                : ''
            }`
        )}
      </p>
      <p className="mrg-t6">
        Required Verifications: {verificationsData?.requiredVerifications}
      </p>
    </div>
  );
};

export default InfoCardContainerVerifications;
