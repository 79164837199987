import { useEffect, useState } from 'react';

//Hooks
import {
  useChangePermissionMutation,
  useCreateAdminLogPermissionChangeMutation,
} from './adminManagementApiSlice';
import useReCaptcha from '../../../hooks/useReCaptcha';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
//Components
import Spinner from '../../../components/Spinner/Spinner';
import CheckBox from '../../../components/Selections/CheckBox';

//Utility
import { img_arrow_order } from '../../../assets/icons';

const AdminPermissions = ({ adminAccountsData }) => {
  //hooks
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleReCaptchaVerify } = useReCaptcha();
  const [changePermission, { isError: changePermissionisError }] =
    useChangePermissionMutation();
  const [
    createAdminLogPermissionChange,
    { isError: createAdminLogPermissionChangeisError },
  ] = useCreateAdminLogPermissionChangeMutation();

  //component state
  const [isLoading, setIsLoading] = useState(false);
  const [orderedList, setOrderedList] = useState(adminAccountsData);
  const [nameOrder, setNameOrder] = useState('desc');

  //UI
  const [reload, setReload] = useState(false);

  //variables

  //UI
  useEffect(() => {
    setReload(true);
    const timer = setTimeout(() => {
      setReload(false);
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [changePermissionisError]);

  async function handleChangePermission(
    permissionLevel,
    permissionChange,
    accountId
  ) {
    if (!isLoading) {
      setIsLoading(true);
      const recaptchaVerified = await handleReCaptchaVerify(
        'MANAGEMENT_PERMISSION',
        false
      );

      if (recaptchaVerified !== true) return false;
      try {
        const res = await changePermission({
          adminAccountId,
          targetedAccountId: accountId,
          permissionLevel: permissionLevel[0],
          permissionChange,
        });

        if (!res?.error) {
          await createAdminLogPermissionChange({
            adminAccountId,
            targetAccountId: accountId,
            permissionLevel: permissionLevel[0],
            permissionChange,
          });
        }
      } catch (error) {}

      setIsLoading(false);
    }
  }

  useEffect(() => {
    let sortedData = [];
    if (adminAccountsData?.length > 0) {
      sortedData = [...adminAccountsData]?.sort((a, b) => {
        const nameA = `${a?.personal?.firstName} ${a?.personal?.lastName}`;
        const nameB = `${b?.personal?.firstName} ${b?.personal?.lastName}`;
        return nameOrder === 'asc'
          ? nameB.localeCompare(nameA)
          : nameA.localeCompare(nameB);
      });
    }

    setOrderedList(sortedData);
  }, [adminAccountsData, nameOrder]);

  function changeNameOrder() {
    if (nameOrder === 'asc') {
      setNameOrder('desc');
    } else {
      setNameOrder('asc');
    }
  }

  return !reload ? (
    <div className="flew-column">
      <table className="admin-table">
        <thead>
          <tr className="text-center">
            <th colSpan="1">Personal</th>
            <th colSpan="1">Company</th>
            <th colSpan="4">Permissions</th>
          </tr>

          <tr className="text-left">
            <th className="">
              <button
                className={`pad-r12 ${
                  nameOrder === 'asc' ? 'reverse-vertical' : ''
                }`}
                onClick={changeNameOrder}
              >
                <img src={img_arrow_order} alt="order-arrow" />
              </button>
              Name
            </th>
            <th>Position</th>
            <th>Management</th>
            <th>Analyst</th>
            <th>Support</th>
            <th>Technical</th>
          </tr>
        </thead>
        <tbody>
          {orderedList && (
            <>
              {[...orderedList]?.map((account) => (
                <tr key={account?._id}>
                  <td>
                    {account?.personal?.firstName}
                    &nbsp;
                    {account?.personal?.lastName}
                  </td>

                  <td>{account?.company?.position}</td>
                  <td>
                    <CheckBox
                      visible={true}
                      handler={handleChangePermission}
                      loadValue={
                        account?.authorizations?.roles?.includes(
                          process.env.REACT_APP_ADMIN_MANAGEMENT
                        )
                          ? true
                          : false
                      }
                      selectionObj={{
                        management: '',
                      }}
                      loadTrigger={true}
                      mrgCenter={true}
                      accountId={account?._id}
                      handleAccountId={true}
                    />
                  </td>
                  <td>
                    <CheckBox
                      visible={true}
                      handler={handleChangePermission}
                      loadValue={
                        account?.authorizations?.roles?.includes(
                          process.env.REACT_APP_ADMIN_ANALYST
                        )
                          ? true
                          : false
                      }
                      selectionObj={{
                        analyst: '',
                      }}
                      loadTrigger={true}
                      mrgCenter={true}
                      accountId={account?._id}
                      handleAccountId={true}
                    />
                  </td>
                  <td>
                    <CheckBox
                      visible={true}
                      handler={handleChangePermission}
                      loadValue={
                        account?.authorizations?.roles?.includes(
                          process.env.REACT_APP_ADMIN_SUPPORT
                        )
                          ? true
                          : false
                      }
                      selectionObj={{
                        support: '',
                      }}
                      loadTrigger={true}
                      mrgCenter={true}
                      accountId={account?._id}
                      handleAccountId={true}
                    />
                  </td>
                  <td>
                    <CheckBox
                      visible={true}
                      handler={handleChangePermission}
                      loadValue={
                        account?.authorizations?.roles?.includes(
                          process.env.REACT_APP_ADMIN_TECHNICAL
                        )
                          ? true
                          : false
                      }
                      selectionObj={{
                        technical: '',
                      }}
                      loadTrigger={true}
                      mrgCenter={true}
                      accountId={account?._id}
                      handleAccountId={true}
                    />
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  ) : (
    <Spinner minHeight={'240px'} minWidth={'1280px'} />
  );
};

export default AdminPermissions;
