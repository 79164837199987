import { createContext, useContext, useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

//Hooks
import useApiStatus from '../useApiStatus';
import useLocalStorage from '../../hooks/useLocalStorage';

//utility
import { axiosLimited } from '../../axios/axios';

//Reminder: any code inside the socket becomes permanent upon initialization; cannot use conditional variables outside the socket code or even conditional variables inside functions called from inside the socket code.

const TranslationRoomSocketContext = createContext({});

export const TranslationRoomSocketProvider = ({ children }) => {
  //Hooks
  const { handleWarning, handleError } = useApiStatus();

  //connection state
  const [translationRoomSocketStatus, setTranslationRoomSocketStatus] =
    useState('closed');
  const [isConnecting, setIsConnecting] = useState(false);
  const [userSessionIdTranslations, setUserSessionIdTranslations] =
    useLocalStorage('userSessionIdTranslations', '');

  //Nav
  const [createRoomPage, setCreateRoomPage] = useState(0);
  const [isLoadingCreatePage, setIsLoadingCreatePage] = useState(false);
  const [subPage, setSubPage] = useState(0);

  //Host
  const [roomCode, setRoomCode] = useState();
  const [password, setPassword] = useState(); //password required by host
  const [restoreData, setRestoreData] = useState();
  const [restoreDataStatus, setRestoreDataStatus] = useState(false);
  const [restoreFromReconnect, setRestoreFromReconnect] = useState(false);
  const [joinRoomPasswordToggle, setJoinRoomPasswordToggle] = useState(false);
  const [defaultAttendeePassword, setDefaultAttendeePassword] = useState();
  const [attendeeJoinRoomPassword, setAttendeeJoinRoomPassword] = useState();

  //host queue
  const [hostActionQueue, setHostActionQueue] = useState([]);
  const [isDispatchingFromQueue, setIsDispatchingFromQueue] = useState(false);

  //attendee password settings
  const [attendeeAuthToken, setAttendeeAuthToken] = useLocalStorage(
    'attendeeAuthToken',
    ''
  );
  const [attendeeRequiresPassword, setAttendeeRequiresPassword] =
    useState(false);
  const [attendeePasswordInput, setAttendeePasswordInput] = useState();

  //Translations Room
  const [roomLanguages, setRoomLanguages] = useState([]);
  const [isHostPresent, setIsHostPresent] = useState(false);
  const [occupantsNum, setOccupantsNum] = useState();
  const [translationLang, setTranslationLang] = useState('select');
  const [translationData, setTranslationData] = useState([]);
  const [hostTranslationData, setHostTranslationData] = useState([]);
  const [reportTranslationData, setReportTranslationData] = useState([]);
  const [currentSpeaker, setCurrentSpeaker] = useState();
  const [speakerCount, setSpeakerCount] = useState(1);
  const [speakersData, setSpeakersData] = useState([
    {
      name: '',
      discussionContext: '',
      keywordsList: [],
      languageOpt: 'select',
      voiceOpt: 'select',
      id: Date.now(),
    },
  ]);

  //variables
  let socketRef = useRef(null);
  let hostActionQueueInterval = useRef(null);

  let logsOn = false;

  let keywordsListLengthMaximum = 50;
  let HOST_LEFT_KICK_TO_STAGING_ROOM_DELAY = 30000;
  let hostLeftTimer;

  let ioTranslationRoomURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/translation'
      : 'https://www.myndfull.com/ws/translation';

  //initialize
  useEffect(() => {
    if (!userSessionIdTranslations) {
      const sessionId = uuidv4();
      setUserSessionIdTranslations(sessionId);
    }

    return () => {
      closeTranslationRoomSocket();
    };
  }, []); //gerenate sessionId + close socket.

  useEffect(() => {
    if (restoreFromReconnect && restoreData) {
      handleRestoreData('reconnect');
    }
  }, [restoreFromReconnect, restoreData]);

  function handleCreatePageChange(page) {
    setIsLoadingCreatePage(true);
    setCreateRoomPage(page);
  }

  const connectTranslationRoomSocket = (
    isHost,
    newRoomCode,
    userSessionId,
    translationsAuth
  ) => {
    //ensure socket is disconnected if it already exists
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    let isHostPresentRef = false;
    let hostReconnectRef = false;
    let attendeeReconnectRef = false;
    let attendeeAuthTokenRef = '';
    let retryRef = 0;
    let ackRetryRef = 0;

    //need to pass roomcode directly as argument to make sure its formatted
    if (
      !socketRef.current &&
      translationRoomSocketStatus !== 'connected' &&
      !isConnecting &&
      newRoomCode?.length > 3 &&
      newRoomCode?.length <= 12 &&
      userSessionId
    ) {
      setIsConnecting(true);
      setRoomCode(newRoomCode);

      socketRef.current = io(ioTranslationRoomURL, {
        query: {
          roomCode: newRoomCode,
          userSessionIdTranslations: userSessionId,
          isHost,
        },
        auth: {
          token: translationsAuth,
        },
        reconnectionAttempts: 10,
      });

      //Host
      socketRef.current.on('hostRestoreData', ({ hostRestoreObj }) => {
        logsOn && console.log('hostRestoreData');
        setTranslationRoomSocketStatus('connected');
        let restoreObj;

        if (process.env.REACT_APP_REDIS === 'true') {
          if (hostRestoreObj) {
            try {
              restoreObj = JSON.parse(hostRestoreObj);
            } catch (error) {
              if (process.env.ENV === 'development')
                console.error('DEV: hostRestoreData parsing failed.', error);
            }
          }
        } else {
          restoreObj = hostRestoreObj;
        }

        if (restoreObj?.sessionInitialized) {
          setRestoreData(restoreObj);
          setRestoreDataStatus('restoreDataFound');
        } else {
          setRestoreDataStatus('restoreDataNotFound');
        }
      });

      socketRef.current.on('connect', () => {
        logsOn && console.log('connect');

        setIsDispatchingFromQueue(false);

        const handleReconnect = () => {
          logsOn &&
            console.warn('Acknowledgment not received. Reconnecting...');

          ackRetryRef++;

          socketRef.current.disconnect();

          if (ackRetryRef > 5) {
            ackRetryRef = 0;

            handleWarning({
              message: 'tryAgain',
              origin: 'TranslationRoomSocketProvider/connect/handleReconnect',
              id: Date.now(),
            });
          } else {
            setTimeout(() => {
              connectTranslationRoomSocket(
                isHost,
                newRoomCode,
                userSessionId,
                translationsAuth
              );
            }, 1000);
          }
        };

        if (isHost) {
          if (!hostReconnectRef) {
            const ackTimeout = setTimeout(handleReconnect, 3000);

            socketRef.current.emit('sendHostRestoreData', {}, (ack) => {
              console.log('Emit acknowledged:', ack);
              retryRef = 0;
              ackRetryRef = 0;
              clearTimeout(ackTimeout);
            });
          } else {
            const ackTimeout = setTimeout(handleReconnect, 3000);

            socketRef.current.emit('hostReconnect', {}, (ack) => {
              console.log('Emit acknowledged:', ack);
              retryRef = 0;
              ackRetryRef = 0;
              clearTimeout(ackTimeout);
            });
          }
        } else {
          const ackTimeout = setTimeout(handleReconnect, 3000);

          socketRef.current.emit(
            'attendeeJoinRoom',
            {
              reconnect: attendeeReconnectRef,
              authToken: attendeeAuthTokenRef,
            },
            (ack) => {
              console.log('Emit acknowledged:', ack);
              retryRef = 0;
              ackRetryRef = 0;
              clearTimeout(ackTimeout);
            }
          );
        }
      });

      socketRef.current.on('skipHostRestoreConnection', () => {
        logsOn && console.log('skipHostRestoreConnection');
        setTranslationRoomSocketStatus('connected');
        handleCreatePageChange(2);
      });

      socketRef.current.on('hostReconnect', ({ hostRestoreObj }) => {
        logsOn && console.log('hostReconnect');
        setTranslationRoomSocketStatus('connected');
        let restoreObj;

        if (process.env.REACT_APP_REDIS === 'true') {
          if (hostRestoreObj) {
            try {
              restoreObj = JSON.parse(hostRestoreObj);
            } catch (error) {
              if (process.env.ENV === 'development') {
                console.error('DEV: hostReconnect parsing failed.', error);
              }
            }
          }
        } else {
          restoreObj = hostRestoreObj;
        }

        if (restoreObj?.hostSocketId) {
          setRestoreData(restoreObj);
          setRestoreFromReconnect(true);
        } else {
          closeTranslationRoomSocket();
          handleError({
            message: 'connectionError',
            id: Date.now(),
            origin: 'TranslationRoomSocketProvider.js',
          });
        }
      });

      socketRef.current.on(
        'hostJoined',
        ({ currentRoomLanguages, currentPasswordRequired }) => {
          logsOn && console.log('hostJoined');
          if (hostLeftTimer) {
            clearTimeout(hostLeftTimer);
          }

          setAttendeeRequiresPassword(currentPasswordRequired);
          setRoomLanguages(currentRoomLanguages);
          setIsHostPresent(true);
          isHostPresentRef = true;
        }
      );

      socketRef.current.on('roomHasNewHost', () => {
        logsOn && console.log('roomHasNewHost');
        handleWarning(
          {
            message: 'roomHasNewHost',
            statusCode: 500,
          },
          'TranslationRoomSocketProvider.js'
        );
        translationRoomSocketCleanUp();
      });

      socketRef.current.on('hostLeft', () => {
        logsOn && console.log('hostLeft');
        setIsHostPresent(false);
        isHostPresentRef = false;
        if (!isHost) {
          hostLeftTimer = setTimeout(() => {
            setSubPage(0);
            setRoomLanguages([]);
            setTranslationLang();
            socketRef.current.emit('removeAttendeeAuth');
          }, HOST_LEFT_KICK_TO_STAGING_ROOM_DELAY);
        }
      });

      socketRef.current.on('hostSuccess', () => {
        logsOn && console.log('hostSuccess');
        setTranslationRoomSocketStatus('connected');
        setIsConnecting(false);
      });

      socketRef.current.on('reauthAttendees', () => {
        logsOn && console.log('reauthAttendees');
        if (!isHost) {
          setCurrentSpeaker();
          setTranslationLang();
          setSubPage(0);
        }
      });

      socketRef.current.on('roomIsHosted', () => {
        logsOn && console.log('roomIsHosted');
        handleWarning({
          message: 'roomCodeAlreadyHosted',
          origin: 'TranslationRoomSocketProvider/roomIsHosted',
          id: Date.now(),
        });
        closeTranslationRoomSocket();
      });

      socketRef.current.on('hostTranslationData', (newHostTranslationData) => {
        logsOn && console.log('hostTranslationData');
        setHostTranslationData((currentHostTranslationData) => [
          ...currentHostTranslationData,
          newHostTranslationData,
        ]);
      });

      socketRef.current.on('speakerUpdated', (newCurrentRoomSpeaker) => {
        logsOn && console.log('speakerUpdated');
        const currentSpeakerData = Object.values(newCurrentRoomSpeaker);
        setCurrentSpeaker(currentSpeakerData[0]);
      });

      socketRef.current.on(
        'hostConfirmationTimestamp',
        (confirmationTimestamp) => {
          logsOn && console.log('hostActionConfirmation');
          hostActionQueueConfirmation(confirmationTimestamp);
        }
      );

      socketRef.current.on(
        'roomSuccess',
        ({
          roomIsHosted,
          currentRoomLanguages,
          currentRoomSpeaker,
          passwordRequired,
          authToken,
          reconnect,
        }) => {
          logsOn && console.log('roomSuccess');
          if (roomIsHosted) {
            setIsHostPresent(true);
            isHostPresentRef = true;
          }
          if (currentRoomLanguages) {
            setRoomLanguages(currentRoomLanguages);
          }
          if (currentRoomSpeaker) {
            setCurrentSpeaker(currentRoomSpeaker);
          }

          if (passwordRequired) {
            setAttendeeRequiresPassword(true);
          }

          setIsConnecting(false);
          setTranslationRoomSocketStatus('connected');

          if (reconnect && authToken) {
            attendeeEnterRoom(authToken, reconnect);
          }
        }
      ); //roomSuccess

      /////////////
      socketRef.current.on('occupantsCountUpdate', (occupantsCount) => {
        logsOn && console.log('occupantsCountUpdate');
        setOccupantsNum(occupantsCount);
      });

      socketRef.current.on(
        'attendeeAuthenticated',
        ({ confirmedAttendeeAuthToken, confirmedTranslationLang }) => {
          logsOn && console.log('attendeeAuthenticated');
          attendeeReconnectRef = false;
          attendeeAuthTokenRef = confirmedAttendeeAuthToken;

          if (isHostPresentRef && confirmedTranslationLang) {
            if (!translationLang) setTranslationLang(confirmedTranslationLang);
            setSubPage(1);
          }
        }
      );

      socketRef.current.on(
        'attendeeTranslationData',
        (attendeeTranslationData) => {
          logsOn && console.log('attendeeTranslationData');
          if (!isHost) {
            setTranslationData(attendeeTranslationData);

            //set data for possible reporting; keep data separate from translationData which gets wiped in TranslationTextBox.js
            let newObj = {
              timeStamp: attendeeTranslationData.timeStamp,
              translatedText: attendeeTranslationData.translatedText,
            };
            let newArr;
            if (reportTranslationData?.length > 0) {
              newArr = [...reportTranslationData, newObj];
            } else {
              newArr = [newObj];
            }
            setReportTranslationData(newArr);
          }
        }
      );

      //Error + Reconnection handlers
      socketRef.current.on('error', (error) => {
        logsOn && console.log('error');
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(`Dev Socket: error ${error}.`);
        }
      });

      socketRef.current.on('serverError', () => {
        logsOn && console.log('serverError');
        setTranslationRoomSocketStatus('connected');
        setIsConnecting(false);
        closeTranslationRoomSocket();
        handleError({
          id: Date.now(),
          origin:
            'TranslationRoomSocketProvider/connectTranslationRoomSocket/serverError',
          message: 'internalServerError',
        });
      });

      socketRef.current.on('reconnect', (attempt) => {
        logsOn && console.log('reconnect');

        if (isHost) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(`Dev Socket: reconnect host/${attempt}`);
          }
          socketRef.current.emit('hostReconnect');
        } else {
          if (attendeeAuthToken)
            socketRef.current.emit('attendeeJoinRoom', {
              reconnect: true,
              authToken: attendeeAuthTokenRef,
            });
        }
      });

      socketRef.current.on('reconnect_failed', () => {
        logsOn && console.log('reconnect_failed');
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(`Dev Socket: reconnect_failed`);
        }
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TranslationRoomSocketProvider.js',
        });
        closeTranslationRoomSocket();
      });

      socketRef.current.on('authenticationError', () => {
        logsOn && console.log('authenticationError');
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(`Dev Socket: Authentication Error.`);
        }
        closeTranslationRoomSocket();
        handleError({
          message: 'authenticationError',
          id: Date.now(),
          origin:
            'TranslationRoomSocketProvider/connectTranslationRoomSocket/authenticationError',
        });
      });

      socketRef.current.on('server_not_ready', () => {
        logsOn && console.log('server_not_ready');
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(`Dev Socket: server_not_ready`);
        }
        retryRef++;

        if (retryRef <= 2) {
          setTimeout(() => {
            connectTranslationRoomSocket(
              isHost,
              newRoomCode,
              userSessionId,
              translationsAuth
            );
          }, 2000);
        } else {
          closeTranslationRoomSocket();
          handleError({
            message: 'connectionError',
            id: Date.now(),
            origin: 'TranslationRoomSocketProvider.js',
          });
        }
      });

      socketRef.current.on('disconnect', (reason, details) => {
        logsOn &&
          console.log(`disconnect, Reason: ${reason}, Details: ${details}`);

        setTranslationRoomSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn && console.log('no automatic reconnect');
          if (ackRetryRef === 0) closeTranslationRoomSocket();
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn && console.log('automatic reconnect');
          if (isHost) {
            hostReconnectRef = true;
          } else {
            attendeeReconnectRef = true;
          }
        } else {
          logsOn && console.error(`Unexpected disconnect reason: ${reason}`);
        }
      });
    }
  };

  function handleRestoreData(selection) {
    logsOn && console.log('handleRestoreData ran.');

    if (selection === 'ignore') {
      setRestoreData();
      setRestoreDataStatus(false);
      setRestoreFromReconnect(false);
      setRoomLanguages([]);
      setIsHostPresent(false);
      setOccupantsNum();
      setTranslationLang('select');
      setTranslationData([]);
      setHostTranslationData([]);
      setReportTranslationData([]);
      setCurrentSpeaker();
      setSpeakerCount(1);
      setSpeakersData([
        {
          name: '',
          discussionContext: '',
          keywordsList: [],
          languageOpt: 'select',
          voiceOpt: 'select',
          id: Date.now(),
        },
      ]);
    } else if (selection === 'restore' || selection === 'reconnect') {
      if (restoreFromReconnect) {
        setRestoreFromReconnect(false);
      }

      setRoomLanguages(restoreData.currentRoomLanguages);
      setSpeakersData(restoreData.speakersData);
      setHostTranslationData(restoreData.savedHostTranslationData);
      setCurrentSpeaker(restoreData.currentRoomSpeaker);
      setTranslationLang(restoreData.hostTextTranslationLang);

      if (selection === 'restore') {
        hostCreateRoom(true);
      }
    }
  }

  const hostCreateRoom = async (restoreSession) => {
    const socket = socketRef.current;
    if (socket) {
      if (restoreSession) {
        try {
          socket.emit('hostCreateRoom', {
            isHost: true,
            roomLanguages: restoreData.currentRoomLanguages,
            speakersData: restoreData.speakersData,
            restoreSession: true,
          });
        } catch (error) {
          handleError({
            id: Date.now(),
            origin: 'TranslationRoomSocketProvider/hostCreateRoom',
            error: error?.message,
          });
        }
      } else {
        try {
          const response = await axiosLimited.post(
            '/api/main/auth/translations/hostRoom',
            {
              roomCode,
              attendeeJoinRoomPassword,
              joinRoomPasswordToggle,
            }
          );

          const sessionToken = response?.data?.sessionToken;
          if (sessionToken) {
            socket.emit('hostCreateRoom', {
              roomLanguages,
              speakersData,
              restoreSession: false,
              passwordRequired: joinRoomPasswordToggle,
              sessionToken,
            });
          } else {
            throw new Error('noSessionToken');
          }
        } catch (error) {
          handleError({
            id: Date.now(),
            origin: 'TranslationRoomSocketProvider/hostCreateRoom',
            error: error?.message,
          });
        }
      }
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.error('Dev WS hostCreateRoom: Socket is not connected.');
      }
    }
  };

  const getAttendeeAuthentication = async () => {
    try {
      const response = await axiosLimited.post(
        '/api/main/auth/translations/attendeeAuth',
        {
          translationLang,
          roomCode,
          attendeePasswordInput,
        }
      );
      if (response?.data?.attendeeAuthToken) {
        setAttendeeAuthToken(response?.data?.attendeeAuthToken);
        attendeeEnterRoom(response?.data?.attendeeAuthToken);
      }
    } catch (error) {
      setAttendeePasswordInput();
      const roomCodeInput = document.getElementById('roomCodePasswordInput');
      if (roomCodeInput) roomCodeInput.value = '';
      handleError({
        id: Date.now(),
        error: 'wrongPassword',
        origin: 'TranslationRoomSocketProvider.js/getAttendeeAuthentication',
      });
    }
  };

  const handleEjectAttendees = () => {
    const socket = socketRef.current;
    if (socket && translationRoomSocketStatus === 'connected') {
      socket.emit('ejectAttendees');
    }
  };

  const attendeeEnterRoom = (authToken, reconnect) => {
    const socket = socketRef.current;

    if (socket && (translationRoomSocketStatus === 'connected' || reconnect)) {
      socket.emit('authenticatedAttendeeEnterRoom', {
        attendeeAuthToken: authToken,
      });
    }
  };

  //host action queue functions
  const addToHostActionQueue = (action, actionData) => {
    const confirmationTimestamp = Date.now();
    let newHostActionQueue = [
      ...hostActionQueue,
      { confirmationTimestamp, action, actionData },
    ];
    setHostActionQueue(newHostActionQueue);
  };

  const hostActionQueueConfirmation = (confirmationTimestamp) => {
    const index = hostActionQueue.findIndex(
      (action) => action.confirmationTimestamp === confirmationTimestamp
    );

    let newHostActionQueue = [...hostActionQueue];
    newHostActionQueue.splice(index, 1);
    setHostActionQueue(newHostActionQueue);

    setIsDispatchingFromQueue(false);
  };

  const hostActionQueueProcessing = (
    translationRoomSocketStatus,
    hostActionQueue,
    isDispatchingFromQueue
  ) => {
    const socket = socketRef.current;

    if (
      socket &&
      translationRoomSocketStatus === 'connected' &&
      !isDispatchingFromQueue
    ) {
      const nextDispatch = hostActionQueue[0];

      if (!nextDispatch) return setIsDispatchingFromQueue(false);

      setIsDispatchingFromQueue(true);

      if (nextDispatch.action === 'changeHostTextLanguage') {
        socket.emit('changeHostTextLanguage', {
          translationLang: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        });
      }

      if (nextDispatch.action === 'changeSpeaker') {
        socket.emit('changeSpeaker', {
          speakerId: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        });
      }

      if (nextDispatch.action === 'translationData') {
        socket.emit('translationData', {
          speechText: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        });
      }
    }
  };

  useEffect(() => {
    const socket = socketRef.current;

    if (socket) {
      if (hostActionQueueInterval.current) {
        clearInterval(hostActionQueueInterval.current);
      }

      hostActionQueueInterval.current = setInterval(() => {
        hostActionQueueProcessing(
          translationRoomSocketStatus,
          hostActionQueue,
          isDispatchingFromQueue
        );
      }, 500);

      return () => clearInterval(hostActionQueueInterval.current);
    }
  }, [
    socketRef.current,
    translationRoomSocketStatus,
    hostActionQueue,
    isDispatchingFromQueue,
  ]);

  const changeHostTextLanguage = (translationLang) => {
    logsOn && console.log('changeHostTextLanguage func ran.');
    const socket = socketRef.current;
    if (socket) {
      addToHostActionQueue('changeHostTextLanguage', translationLang);
    }
  };

  const changeSpeaker = (speakerId) => {
    logsOn && console.log('changeSpeaker func ran.');
    const socket = socketRef.current;
    if (socket) {
      addToHostActionQueue('changeSpeaker', speakerId);
    }
  };

  const sendTranslationData = (speechText) => {
    logsOn && console.log('sendTranslationData func ran.');
    const socket = socketRef.current;
    if (socket) {
      addToHostActionQueue('translationData', speechText);
    }
  };

  const closeTranslationRoomSocket = () => {
    logsOn && console.log('closeTranslationRoomSocket func ran.');
    setIsConnecting(false);

    const socket = socketRef.current;

    if (socket) {
      socket.disconnect();
      socketRef.current = null;
    }
    translationRoomSocketCleanUp();
    setTranslationRoomSocketStatus('closed');

    if (hostLeftTimer) {
      clearTimeout(hostLeftTimer);
    }
  };

  function translationRoomSocketCleanUp() {
    logsOn && console.log('translationRoomSocketCleanUp ran.');
    //Nav
    setSubPage(0);
    setCreateRoomPage(0);

    //attendee
    setAttendeeAuthToken();

    //Host
    setRoomCode();
    setPassword();
    setRestoreData();
    setRestoreDataStatus(false);
    setRestoreFromReconnect(false);
    setJoinRoomPasswordToggle(false);
    setDefaultAttendeePassword();
    setAttendeeJoinRoomPassword();

    setHostActionQueue([]);
    setIsDispatchingFromQueue(false);

    //attendee password settings
    setAttendeeRequiresPassword(false);
    setAttendeePasswordInput();

    //Translations Room
    setRoomLanguages([]);
    setIsHostPresent(false);
    setOccupantsNum();
    setTranslationLang('select');
    setTranslationData([]);
    setHostTranslationData([]);
    setReportTranslationData([]);
    setCurrentSpeaker();
    setSpeakerCount(1);
    setSpeakersData([
      {
        name: '',
        discussionContext: '',
        keywordsList: [],
        languageOpt: 'select',
        voiceOpt: 'select',
        id: Date.now(),
      },
    ]);
  }

  const getTranslationRoomSocket = () => {
    return socketRef.current;
  };

  return (
    <TranslationRoomSocketContext.Provider
      value={{
        changeHostTextLanguage,
        changeSpeaker,
        translationRoomSocketCleanUp,
        connectTranslationRoomSocket,
        closeTranslationRoomSocket,
        currentSpeaker,
        getTranslationRoomSocket,
        handleRestoreData,
        hostCreateRoom,
        hostTranslationData,
        isConnecting,
        isHostPresent,
        keywordsListLengthMaximum,
        occupantsNum,
        password,
        restoreData,
        restoreDataStatus,
        roomCode,
        roomLanguages,
        sendTranslationData,
        setCurrentSpeaker,
        setPassword,
        setRestoreData,
        setRestoreDataStatus,
        setRoomCode,
        setRoomLanguages,
        setSpeakerCount,
        setSpeakersData,
        setSubPage,
        setTranslationData,
        setTranslationLang,
        speakerCount,
        speakersData,
        subPage,
        translationData,
        translationLang,
        translationRoomSocketStatus,
        userSessionIdTranslations,
        reportTranslationData,
        setReportTranslationData,
        joinRoomPasswordToggle,
        setJoinRoomPasswordToggle,
        defaultAttendeePassword,
        setDefaultAttendeePassword,
        attendeeJoinRoomPassword,
        setAttendeeJoinRoomPassword,
        attendeeRequiresPassword,
        setAttendeeRequiresPassword,
        attendeePasswordInput,
        setAttendeePasswordInput,
        getAttendeeAuthentication,
        attendeeAuthToken,
        setAttendeeAuthToken,
        handleCreatePageChange,
        createRoomPage,
        setCreateRoomPage,
        isLoadingCreatePage,
        setIsLoadingCreatePage,
        handleEjectAttendees,
        addToHostActionQueue,
      }}
    >
      {children}
    </TranslationRoomSocketContext.Provider>
  );
};

export const useTranslationRoomSocket = () => {
  return useContext(TranslationRoomSocketContext);
};
