// Function to calculate distance between two points using Haversine formula
//note that user geoPosition coords are reversed from their backend save for google maps functionlaity; event geoPosition coords remain same array as backend

export function getDistance(lng1, lat1, lng2, lat2, measurement) {
  let R;
  if (measurement === 'km') {
    R = 6371;
  } else if (measurement === 'mi') {
    R = 3958.8;
  }

  const dLat = toRadians(lat2 - lng1);
  const dLon = toRadians(lng2 - lat1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lng1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers

  return Math.round(distance);
}

// Helper function to convert degrees to radians
function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function sortEventsByDistance(
  state,
  direction,
  accountGeoPosition,
  measurement
) {
  if (!accountGeoPosition || !state) {
    return null;
  }

  const [accountLat, accountLon] = accountGeoPosition?.coordinates;

  const sortedEvents = [...state].sort((a, b) => {
    const [latitudeA, longitudeA] = a.hosting.inPerson.geoPosition?.coordinates;
    const [latitudeB, longitudeB] = b.hosting.inPerson.geoPosition?.coordinates;

    const distanceA = getDistance(
      accountLon,
      accountLat,
      longitudeA,
      latitudeA,
      measurement
    );
    const distanceB = getDistance(
      accountLon,
      accountLat,
      longitudeB,
      latitudeB,
      measurement
    );

    return direction === 'ascending'
      ? distanceA - distanceB
      : distanceB - distanceA;
  });

  return sortedEvents;
}

export function convertDistanceToKmOrMi(distanceMeasurement, distanceInMeters) {
  if (distanceMeasurement === 'km') {
    let newDistance = distanceInMeters / 1609.344;
    newDistance = Number(newDistance.toFixed(1));
    return newDistance;
  } else {
    let newDistance = distanceInMeters / 1000;
    return newDistance;
  }
}
