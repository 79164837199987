import { useState, useEffect } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useLanguageAccount from '../../../../language/features/useLanguageAccount';

//Components
import { SubAccountMenus } from './SubAccountMenus';

//Utility
import {
  dropdown_arrow,
  exchange,
  help,
  icon_profile,
  img_messages,
  img_money,
  organize,
  search_dark,
  settings,
  speaker_dark,
} from '../../../../assets/icons';
import ScrollTo from '../../../../utils/UI/ScrollTo';

const AccountNavMobile = () => {
  //Hooks
  const { Account } = useLanguageAccount();
  const { openSlideMenu, handleMobileTap } = useSettings();
  const { handleSettingsSetAccountSettingsView, settingsAccountSettingsView } =
    useSettingsHandlers();

  //UI state
  const [openSettingsDropdown, setOpenSettingsDropdown] = useState(false);
  const [tapHighlightPrimaryNavBtn, setTapHighlightPrimaryNavBtn] =
    useState('');

  //Component variables
  let navigationButtonHeader;
  let primaryNavigationButtons;

  //Initialize & UI functions
  useEffect(() => {
    ScrollTo('', 'instant', true);
  }, [settingsAccountSettingsView]);

  useEffect(() => {
    if (openSlideMenu && openSettingsDropdown) {
      setOpenSettingsDropdown(false);
    }
    /*eslint-disable-next-line*/
  }, [openSlideMenu]);

  function handleCloseAccountNavigationMenu() {
    setOpenSettingsDropdown(false);
  } //sets focus after closing

  //JSX conditional
  if (settingsAccountSettingsView === 'general') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 ${
          openSettingsDropdown ? 'heading heading--active' : 'heading'
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.generic.ariaAccountNavigationMenu} ${Account.general.general} ${Account.generic.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={settings} alt={Account.general.general} />
        {Account.general.general}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'account') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 ${
          openSettingsDropdown ? 'heading heading--active' : 'heading'
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.generic.ariaAccountNavigationMenu} ${Account.account.account} ${Account.generic.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={icon_profile} alt={Account.account.account} />
        {Account.account.account}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'attendee') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 heading ${
          openSettingsDropdown ? 'heading--active' : ''
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.generic.ariaAccountNavigationMenu} ${Account.attendee.attendee} ${Account.generic.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={search_dark} alt={Account.attendee.attendee} />
        {Account.attendee.attendee}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'billing') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 heading ${
          openSettingsDropdown ? 'heading--active' : ''
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.billing.ariaAccountNavigationMenu} ${Account.billing.billing} ${Account.billing.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={img_money} alt={Account.billing.billing} />
        {Account.billing.billing}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'emailPreferences') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 heading ${
          openSettingsDropdown ? 'heading--active' : ''
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.emailPreferences.ariaAccountNavigationMenu} ${Account.emailPreferences.email} ${Account.emailPreferences.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={img_messages} alt={Account.emailPreferences.email} />
        {Account.emailPreferences.email}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'speaker') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 heading  ${
          openSettingsDropdown ? 'heading--active' : ''
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.generic.ariaAccountNavigationMenu} ${Account.speaker.speaker} ${Account.generic.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={speaker_dark} alt={Account.speaker.speaker} />
        {Account.speaker.speaker}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'translationRooms') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 heading ${
          openSettingsDropdown ? 'heading--active' : ''
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.generic.ariaAccountNavigationMenu} ${Account.translationRooms.translations} ${Account.generic.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={organize} alt={Account.translationRooms.translations} />
        {Account.translationRooms.translations}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'organizer') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 heading ${
          openSettingsDropdown ? 'heading--active' : ''
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.generic.ariaAccountNavigationMenu} ${Account.organizer.organizer} ${Account.generic.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={organize} alt={Account.organizer.organizer} />
        {Account.organizer.organizer}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  } else if (settingsAccountSettingsView === 'help') {
    navigationButtonHeader = (
      <button
        className={`access-ob access-o-6 heading ${
          openSettingsDropdown ? 'heading--active' : ''
        }`}
        onClick={() => setOpenSettingsDropdown(!openSettingsDropdown)}
        id="account-navigation-header"
        aria-label={`${Account.generic.ariaAccountNavigationMenu} ${Account.help.help} ${Account.generic.ariaAccountNavigationMenuSelected}`}
        aria-expanded={openSettingsDropdown}
      >
        <img src={help} alt={Account.help.help} />
        {Account.help.help}
        <img
          src={dropdown_arrow}
          alt={Account.generic.arrow}
          className={openSettingsDropdown ? 'arrow reverse-vertical' : 'arrow'}
        />
      </button>
    );
  }

  primaryNavigationButtons = (
    <ul
      className="nav-menu__container--mobile access-ob"
      tabIndex="0"
      aria-label={Account.generic.ariaPrimaryAccountNavigationButtonsMenu}
    >
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'general'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('general'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('general')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'general'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.general.general}`}
          tabIndex="0"
        >
          <img
            src={settings}
            alt={Account.general.general}
            aria-hidden="true"
          />
          {Account.general.general}
          {settingsAccountSettingsView === 'general' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'account'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('account'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('account')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'account'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.account.account}`}
          tabIndex="0"
        >
          <img
            src={icon_profile}
            alt={Account.account.account}
            aria-hidden="true"
          />
          {Account.account.account}
          {settingsAccountSettingsView === 'account' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'billing'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('billing'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('billing')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'billing'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.billing.billing}`}
          tabIndex="0"
        >
          <img
            src={img_money}
            alt={Account.billing.billing}
            aria-hidden="true"
          />
          {Account.billing.billing}
          {settingsAccountSettingsView === 'billing' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'emailPreferences'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('emailPreferences'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('emailPreferences')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'emailPreferences'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.emailPreferences.email}`}
          tabIndex="0"
        >
          <img
            src={img_messages}
            alt={Account.emailPreferences.email}
            aria-hidden="true"
          />
          {Account.emailPreferences.email}
          {settingsAccountSettingsView === 'emailPreferences' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'attendee'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('attendee'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('attendee')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'attendee'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.attendee.attendee}`}
          tabIndex="0"
        >
          <img
            src={search_dark}
            alt={Account.attendee.attendee}
            aria-hidden="true"
          />
          {Account.attendee.attendee}
          {settingsAccountSettingsView === 'attendee' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'speaker'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('speaker'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('speaker')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'speaker'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.speaker.speaker}`}
          tabIndex="0"
        >
          <img
            src={speaker_dark}
            alt={Account.speaker.speaker}
            aria-hidden="true"
          />
          {Account.speaker.speaker}
          {settingsAccountSettingsView === 'speaker' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'organizer'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('organizer'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('organizer')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'organizer'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.organizer.organizer}`}
          tabIndex="0"
        >
          <img
            src={organize}
            alt={Account.organizer.organizer}
            aria-hidden="true"
          />
          {Account.organizer.organizer}
          {settingsAccountSettingsView === 'organizer' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'translationRooms'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('translationRooms'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('translationRooms')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'translationRooms'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.translationRooms.translations}`}
          tabIndex="0"
        >
          <img
            src={exchange}
            alt={Account.translationRooms.translations}
            aria-hidden="true"
          />
          {Account.translationRooms.translations}
          {settingsAccountSettingsView === 'translationRooms' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
      <li>
        <button
          className={`flex-row fs16 fwb access-ob ${
            tapHighlightPrimaryNavBtn === 'help'
              ? 'highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightPrimaryNavBtn('help'),
                () => setTapHighlightPrimaryNavBtn(),
              ],
              [() => handleSettingsSetAccountSettingsView('help')]
            )
          }
          aria-label={`${
            settingsAccountSettingsView === 'help'
              ? Account.generic.ariaCurrentSelection
              : ''
          } ${Account.help.help}`}
          tabIndex="0"
        >
          <img src={help} alt={Account.help.help} aria-hidden="true" />
          {Account.help.help}
          {settingsAccountSettingsView === 'help' && (
            <div className="highlight-bar" />
          )}
        </button>
      </li>
    </ul>
  );

  return (
    <>
      <div className="nav-menu--mobile">
        {navigationButtonHeader}

        {openSettingsDropdown && (
          <div className="flex-row flex-column--phn space-evenly pad-12">
            {primaryNavigationButtons}
            <div className="nav-menu-mobile-divider" />
            <SubAccountMenus
              setOpenSettingsDropdown={setOpenSettingsDropdown}
            />
          </div>
        )}
      </div>

      <button
        className={`access-ow access-o-12 ${
          openSettingsDropdown ? 'overlay' : 'remove'
        }`}
        onClick={handleCloseAccountNavigationMenu}
        tabIndex="0"
        aria-label={Account.generic.ariaCloseAccountNavigationMenu}
      />
    </>
  );
};

export default AccountNavMobile;
