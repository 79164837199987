import { createContext, useContext, useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';

//hooks
import { useAdminAccountReduxHandlers } from '../../features/admin/account/useAdminAccountHandlers';
import useApiStatus from '../useApiStatus';
import useAdminTechnicalHandlers from '../../features/admin/technical/useAdminTechnicalHandlers';

const TechnicalLogsSocketContext = createContext({});

export const TechnicalLogsSocketProvider = ({ children }) => {
  //Hooks
  const { handleSocketTechnicalLogData, handleSocketTechnicalLogArchiveData } =
    useAdminTechnicalHandlers();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleError } = useApiStatus();

  //state
  const [technicalLogsSocketStatus, setTechnicalLogsSocketStatus] =
    useState('closed');
  const [
    technicalLogsArchiveSocketStatus,
    setTechnicalLogsArchiveSocketStatus,
  ] = useState('closed');

  //variables
  const socketTechnicalLogsRef = useRef(null);
  const socketTechnicalLogsArchiveRef = useRef(null);

  const connectTechnicalLogsSocket = () => {
    if (
      !socketTechnicalLogsRef.current &&
      technicalLogsSocketStatus !== 'connected'
    ) {
      let newSocket;

      if (process.env.REACT_APP_ENV === 'development') {
        newSocket = io('http://localhost:5000/ws/technicalLogs', {
          query: { adminAccountId },
        });
      } else {
        newSocket = io('https://myndfull.com/ws/technicalLogs', {
          withCredentials: true,
          query: { adminAccountId },
        });
      }

      newSocket.on('connect', () => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(
            `Dev: You connected with the TechnicalLogs Socket, id: ${newSocket.id}`
          );
        }

        setTechnicalLogsSocketStatus('connected');
      });

      newSocket.on('technicalLogData', (technicalLogData) => {
        handleSocketTechnicalLogData(technicalLogData);
      });

      newSocket.on('connect_error', (error) => {
        setTechnicalLogsSocketStatus('pending');
        if (process.env.NODE_ENV === 'development') {
          console.error(
            'Dev: Error connecting to TechnicalLogs Socket:',
            error
          );
        }
      });

      newSocket.on('error', (error) => {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TechnicalLogsSocketProvider.js',
        });
      });

      newSocket.on('disconnect', (error) => {
        setTechnicalLogsSocketStatus('pending');
        console.log('Technical Log socket disconnected.');
      });

      return (socketTechnicalLogsRef.current = newSocket);
    }
  };

  const getTechnicalLogsSocket = () => {
    return socketTechnicalLogsRef.current;
  };

  const closeTechnicalLogsSocket = () => {
    const socket = socketTechnicalLogsRef.current;

    if (socket) {
      socket.close();
      socketTechnicalLogsRef.current = null;
      setTechnicalLogsSocketStatus('closed');

      if (process.env.NODE_ENV === 'development') {
        console.error('Dev: Closed TechnicalLogs Socket:');
      }
    }
  };

  ////TECHNICAL LOGS ARCHIVE
  const connectTechnicalLogsArchiveSocket = () => {
    if (
      !socketTechnicalLogsArchiveRef.current &&
      technicalLogsArchiveSocketStatus !== 'connected'
    ) {
      let newSocket;

      if (process.env.REACT_APP_ENV === 'development') {
        newSocket = io('http://localhost:5000/ws/technicalLogsArchive', {
          query: { adminAccountId },
        });
      } else {
        newSocket = io('https://myndfull.com/ws/technicalLogsArchive', {
          withCredentials: true,
          query: { adminAccountId },
        });
      }

      newSocket.on('connect', () => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(
            `Dev: You connected with the TechnicalLogsArchive Socket, id: ${newSocket.id}`
          );
        }

        setTechnicalLogsArchiveSocketStatus('connected');
      });

      newSocket.on('technicalLogsArchiveData', (technicalLogsArchiveData) => {
        handleSocketTechnicalLogArchiveData(technicalLogsArchiveData);
      });

      newSocket.on('connect_error', (error) => {
        setTechnicalLogsArchiveSocketStatus('pending');
        if (process.env.NODE_ENV === 'development') {
          console.error(
            'Dev: Error connecting to TechnicalLogsArchive Socket:',
            error
          );
        }
      });

      newSocket.on('error', (error) => {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TechnicalLogsArchiveSocketProvider.js',
        });
      });

      newSocket.on('disconnect', (error) => {
        setTechnicalLogsArchiveSocketStatus('pending');
        console.log('Technical Log Archive socket disconnected.');
      });

      return (socketTechnicalLogsArchiveRef.current = newSocket);
    }
  };

  const getTechnicalLogsArchiveSocket = () => {
    return socketTechnicalLogsArchiveRef.current;
  };

  const closeTechnicalLogsArchiveSocket = () => {
    const socket = socketTechnicalLogsArchiveRef.current;

    if (socket) {
      socket.close();
      socketTechnicalLogsArchiveRef.current = null;
      setTechnicalLogsArchiveSocketStatus('closed');

      if (process.env.NODE_ENV === 'development') {
        console.error('Dev: Closed TechnicalLogsArchive Socket:');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeTechnicalLogsSocket);
    window.addEventListener('beforeunload', closeTechnicalLogsArchiveSocket);
    return () => {
      window.removeEventListener('beforeunload', closeTechnicalLogsSocket);
      window.removeEventListener(
        'beforeunload',
        closeTechnicalLogsArchiveSocket
      );
    };
  }, []);

  return (
    <TechnicalLogsSocketContext.Provider
      value={{
        //technical logs
        connectTechnicalLogsSocket,
        closeTechnicalLogsSocket,
        getTechnicalLogsSocket,
        technicalLogsSocketStatus,

        //technical logs archive
        connectTechnicalLogsArchiveSocket,
        getTechnicalLogsArchiveSocket,
        closeTechnicalLogsArchiveSocket,
        technicalLogsArchiveSocketStatus,
      }}
    >
      {children}
    </TechnicalLogsSocketContext.Provider>
  );
};

export const useTechnicalLogsSocket = () => {
  return useContext(TechnicalLogsSocketContext);
};
