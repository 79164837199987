import { useEffect, useRef, useState } from 'react';

//Hooks
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import { useOrganizeHandlers } from '../useOrganizeHandlers';
import { useEditEventReduxHandlers } from './useEditEventHandlers';
import useReCaptcha from '../../../../hooks/useReCaptcha';
import useApiStatus from '../../../../context/useApiStatus';

//Components
import General from '../eventSections/General';
import Participants from '../eventSections/Participants';
import Access from '../eventSections/Access';
import Registration from '../eventSections/Registration';
import Promotion from '../eventSections/Promotion';
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import EditActions from '../eventSections/EditActions';
import Schedule from '../eventSections/Schedule';
import Spinner from '../../../../components/Spinner/Spinner';
import LoadingScreenContent from '../../../../components/LoadingScreenContent/LoadingScreenContent';
import EventPage from '../../events/EventPage/EventPage';

//Utility
import { checkImagePath } from '../../../../utils/images/checkImagePath';

const EditEvent = ({
  eventData,
  setEditEvent,
  handleRemoveEvent,
  removeEventisLoading,
  setEventPreview,
  eventPreview,
  updateEvent,
  updateEventisLoading,
  setEventPageLoadComplete,
}) => {
  //Hooks
  const {
    //handlers
    handleEditEventReset,
    handleEditEventSetEvent,
    handleEditEventChangeList,

    //DateTime
    handleEditEventSetDate,
    handleEditEventSetTimeZone,
    handleEditEventSetTimeStart,
    handleEditEventSetTimeEnd,

    //General
    handleEditEventSetTitle,
    handleEditEventSetDescription,
    handleEditEventSetEventType,
    handleEditEventSetAudienceType,
    handleEditEventSetAgendaFormat,
    handleEditEventSetAgendaItems,
    handleEditEventSetLanguages,
    handleEditEventSetEventImagePath,

    //Access
    handleReverseEditEventHostingGeoPositionCoordinates,
    handleEditEventClearLocation,
    handleEditEventSetCoordinates,
    handleEditEventSetHostingOption,
    handleEditEventSetLocation,
    handleEditEventSetAccessibilityNotes,
    handleEditEventSetSignLanguageInterpreter,
    handleEditEventSetWheelchairAccessibility,
    handleEditEventSetRealtimeTranslations,

    //Schedule
    handleEditEventSetSchedulePublishDate,
    handleEditEventSetSchedulePublishTime,
    handleEditEventSetSchedulePublishTimeZone,
    handleEditEventSetScheduleEnabled,

    //Participants
    handleEditEventSetModerator,
    handleEditEventSetSpeaker0Data,
    handleEditEventSetSpeaker1Data,
    handleEditEventSetSpeaker2Data,
    handleEditEventSetSpeaker3Data,
    handleEditEventSetSpeaker4Data,
    handleEditEventSetSpeaker5Data,

    //Hosting
    handleEditEventSetVirtualMeetingId,
    handleEditEventSetVirtualPasscode,
    handleEditEventSetVirtualPlatform,
    handleEditEventSetVirtualMeetingLink,

    //Registrations
    handleEditEventSetApproveRegistrations,
    handleEditEventSetMaxRegistrationsInPersonEnabled,
    handleEditEventSetMaxRegistrationsVirtualEnabled,
    handleEditEventSetEnablePrivateDetails,
    handleEditEventSetMaxRegistrationsInPersonAmount,
    handleEditEventSetMaxRegistrationsVirtualAmount,
    handleEditEventSetPrivateDetails,
    handleEditEventSetRegistrations,

    //Tags
    handleEditEventAddTinyTag,
    handleEditEventRemoveTinyTag,
    handleEditEventAddBigTag,
    handleEditEventRemoveBigTag,
    handleEditEventSetBigTags,
    handleEditEventSetTinyTags,

    //edit
    lastModified,
    editEventData: editEventDataSlice,
    editEventChangeList: changeList,

    //Generic
    title: loadTitle,
    description: loadDescription,
    eventType: loadEventType,
    audienceType: loadAudienceType,
    date: loadDate,
    languages: loadLanguages,
    timeZone: loadTimeZone,
    timeStart: loadTimeStart,
    timeEnd: loadTimeEnd,
    agendaItems: loadAgendaItems,
    agendaFormat: loadAgendaFormat,
    realtimeTranslations: loadRealtimeTranslations,
    signLanguageInterpreter: loadSignLanguageInterpreter,
    wheelchairAccessibility: loadWheelchairAccessibility,
    accessibilityNotes: loadAccessibilityNotes,
    eventImagePath: loadEventImagePath,

    //Hosting
    hostingOption: loadHostingOption,
    coordinates: loadCoordinates,
    virtualMeetingId: loadVirtualMeetingId,
    virtualPasscode: loadVirtualPasscode,
    virtualPlatform: loadVirtualPlatform,
    virtualLink: loadVirtualLink,
    location: loadLocation,

    //Participants
    moderator: loadModerator,
    speaker0Data: loadSpeaker0Data,
    speaker1Data: loadSpeaker1Data,
    speaker2Data: loadSpeaker2Data,
    speaker3Data: loadSpeaker3Data,
    speaker4Data: loadSpeaker4Data,
    speaker5Data: loadSpeaker5Data,

    //Registrations
    approveRegistrations: loadApproveRegistrations,
    maxRegistrationsInPersonEnabled: loadMaxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled: loadMaxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount: loadMaxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount: loadMaxRegistrationsVirtualAmount,
    registrationsActive: loadRegistrationsActive,
    privateDetails: loadPrivateDetails,
    enablePrivateDetails: loadEnablePrivateDetails,

    //Schedule
    scheduleEnabled: loadScheduleEnabled,
    schedulePublishDate: loadSchedulePublishDate,
    schedulePublishTime: loadSchedulePublishTime,
    schedulePublishTimeZone: loadSchedulePublishTimeZone,

    //Tags
    bigTags: loadBigTags,
    tinyTags: loadTinyTags,
  } = useEditEventReduxHandlers();
  const { handleOrganizerEventFormData } = useOrganizeHandlers();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleError, handleSuccess } = useApiStatus();
  const {
    handleSettingsSetOrganizeView,
    handleSettingsSetOrganizeOverviewContent,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
  } = useSettingsHandlers();

  //Component state
  const [reset, setReset] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [activatePreviewMode, setActivatePreviewMode] = useState(false);
  const [imageChangeTracker, setImageChangeTracker] = useState({
    eventImage: false,
    speakerImage: false,
  });

  //Component variables
  const effectRan = useRef(false);

  const eventImageRef = useRef(null);
  const speaker0ImgRef = useRef(null);
  const speaker1ImgRef = useRef(null);
  const speaker2ImgRef = useRef(null);
  const speaker3ImgRef = useRef(null);
  const speaker4ImgRef = useRef(null);
  const speaker5ImgRef = useRef(null);

  let speaker0Img;
  let speaker1Img;
  let speaker2Img;
  let speaker3Img;
  let speaker4Img;
  let speaker5Img;

  let eventImg;

  // console.log(changeList);

  //Initialize Functions
  useEffect(() => {
    if (eventData && !initialize) {
      if (effectRan.current === false) {
        handleReset();
        setInitialize(true);
        effectRan.current = true;
      }
    }
  }, [eventData]);

  useEffect(() => {
    if (reset) {
      const timerId = setTimeout(() => {
        setReset(false);
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [reset]);

  //api functions
  async function handleUpdateEvent(eventData, handleType) {
    if (!updateEventisLoading) {
      const recaptchaVerified = await handleReCaptchaVerify(
        'UPDATE_EVENT',
        false
      );

      if (recaptchaVerified !== true) return false;

      try {
        const formData = await handleOrganizerEventFormData(
          handleType,
          eventData,
          eventImageRef,
          speaker0ImgRef,
          speaker1ImgRef,
          speaker2ImgRef,
          speaker3ImgRef,
          speaker4ImgRef,
          speaker5ImgRef,
          speaker0Img,
          speaker1Img,
          speaker2Img,
          speaker3Img,
          speaker4Img,
          speaker5Img,
          eventImg,
          changeList
        );

        const res = await updateEvent(formData);

        if (!res.error) {
          handleSuccess({
            message: 'eventUpdated',
            id: Date.now(),
            origin: 'EditEvent.js/handleUpdateEvent',
          });

          if (eventImg) {
            URL.revokeObjectURL(eventImg.imagePreview);
          }
          if (speaker0Img) {
            URL.revokeObjectURL(speaker0Img.imagePreview);
          }
          if (speaker1Img) {
            URL.revokeObjectURL(speaker1Img.imagePreview);
          }
          if (speaker2Img) {
            URL.revokeObjectURL(speaker2Img.imagePreview);
          }
          if (speaker3Img) {
            URL.revokeObjectURL(speaker3Img.imagePreview);
          }
          if (speaker4Img) {
            URL.revokeObjectURL(speaker4Img.imagePreview);
          }
          if (speaker5Img) {
            URL.revokeObjectURL(speaker5Img.imagePreview);
          }

          //need to check for image path
          if (
            res.data.data.updatedEvent?.generic?.eventImagePath &&
            res.data.data.updatedEvent?.generic?.eventImagePath !==
              'event_img_default.png'
          ) {
            await checkImagePath(
              res.data.data.updatedEvent.generic.eventImagePath,
              'event'
            );
          }

          handleEditEventReset();
          setEventPageLoadComplete(false);

          if (eventData?.schedule?.enabled) {
            handleSettingsSetOrganizeOverviewStandaloneGridEvents('scheduled');
          } else {
            handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
          }

          handleSettingsSetOrganizeOverviewContent('standalone');
          handleSettingsSetOrganizeView('overview');
          return setEditEvent();
        }
      } catch (error) {
        return handleError(
          {
            message: 'tryAgain',
            statusCode: 500,
          },
          'EditEvent/handleUpdateEvent'
        );
      }
    }
  }

  //Component functions
  function handleReset() {
    handleEditEventReset();
    handleEditEventSetEvent(eventData);
    handleReverseEditEventHostingGeoPositionCoordinates();
    handleEditEventChangeList([]);
    setReset(true);
  }

  function handleReturn() {
    setActivatePreviewMode(false);
    setEventPreview();
  }

  return (
    <>
      <div className={`organize ${eventPreview ? 'remove' : ''}`}>
        <div className="create-event__wrapper">
          <div className="create-event">
            <div className="h24" />
            {initialize && (
              <>
                <EditActions
                  eventId={eventData._id}
                  setEditEvent={setEditEvent}
                  handleReset={handleReset}
                  handleEditEventReset={handleEditEventReset}
                  reset={reset}
                  handleRemoveEvent={handleRemoveEvent}
                  handleUpdateEvent={handleUpdateEvent}
                  setEventPreview={setEventPreview}
                  imageChangeTracker={imageChangeTracker}
                  editEventData={editEventDataSlice}
                  lastModified={lastModified}
                  changeList={changeList}
                  handleEditEventChangeList={handleEditEventChangeList}
                  loadTitle={loadTitle}
                  loadDescription={loadDescription}
                  loadEventType={loadEventType}
                  loadAudienceType={loadAudienceType}
                  loadDate={loadDate}
                  loadLanguages={loadLanguages}
                  loadTimeZone={loadTimeZone}
                  loadTimeStart={loadTimeStart}
                  loadTimeEnd={loadTimeEnd}
                  loadAgendaItems={loadAgendaItems}
                  loadRealtimeTranslations={loadRealtimeTranslations}
                  loadSignLanguageInterpreter={loadSignLanguageInterpreter}
                  loadWheelchairAccessibility={loadWheelchairAccessibility}
                  loadAccessibilityNotes={loadAccessibilityNotes}
                  loadHostingOption={loadHostingOption}
                  loadCoordinates={loadCoordinates}
                  loadVirtualMeetingId={loadVirtualMeetingId}
                  loadVirtualPasscode={loadVirtualPasscode}
                  loadVirtualPlatform={loadVirtualPlatform}
                  loadVirtualLink={loadVirtualLink}
                  loadLocation={loadLocation}
                  loadModerator={loadModerator}
                  loadSpeaker0Data={loadSpeaker0Data}
                  loadSpeaker1Data={loadSpeaker1Data}
                  loadSpeaker2Data={loadSpeaker2Data}
                  loadSpeaker3Data={loadSpeaker3Data}
                  loadSpeaker4Data={loadSpeaker4Data}
                  loadSpeaker5Data={loadSpeaker5Data}
                  loadApproveRegistrations={loadApproveRegistrations}
                  loadMaxRegistrationsInPersonEnabled={
                    loadMaxRegistrationsInPersonEnabled
                  }
                  loadMaxRegistrationsVirtualEnabled={
                    loadMaxRegistrationsVirtualEnabled
                  }
                  loadMaxRegistrationsInPersonAmount={
                    loadMaxRegistrationsInPersonAmount
                  }
                  loadMaxRegistrationsVirtualAmount={
                    loadMaxRegistrationsVirtualAmount
                  }
                  loadRegistrationsActive={loadRegistrationsActive}
                  loadPrivateDetails={loadPrivateDetails}
                  loadSchedulePublishDate={loadSchedulePublishDate}
                  loadSchedulePublishTime={loadSchedulePublishTime}
                  loadSchedulePublishTimeZone={loadSchedulePublishTimeZone}
                  loadBigTags={loadBigTags}
                  loadTinyTags={loadTinyTags}
                />

                {reset || removeEventisLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {eventData?.schedule?.enabled && (
                      <Schedule
                        initialize={initialize}
                        editEventMode={true}
                        handleSetSchedulePublishDate={
                          handleEditEventSetSchedulePublishDate
                        }
                        handleSetSchedulePublishTime={
                          handleEditEventSetSchedulePublishTime
                        }
                        handleSetSchedulePublishTimeZone={
                          handleEditEventSetSchedulePublishTimeZone
                        }
                        handleSetScheduleEnabled={
                          handleEditEventSetScheduleEnabled
                        }
                        loadSchedulePublishDate={loadSchedulePublishDate}
                        loadSchedulePublishTime={loadSchedulePublishTime}
                        loadSchedulePublishTimeZone={
                          loadSchedulePublishTimeZone
                        }
                        loadScheduleEnabled={loadScheduleEnabled}
                      />
                    )}
                    <General
                      initialize={initialize}
                      handleSetTitle={handleEditEventSetTitle}
                      handleSetDescription={handleEditEventSetDescription}
                      handleSetEventType={handleEditEventSetEventType}
                      handleSetAudienceType={handleEditEventSetAudienceType}
                      handleSetDate={handleEditEventSetDate}
                      handleSetTimeZone={handleEditEventSetTimeZone}
                      handleSetTimeStart={handleEditEventSetTimeStart}
                      handleSetTimeEnd={handleEditEventSetTimeEnd}
                      handleSetAgendaFormat={handleEditEventSetAgendaFormat}
                      handleSetAgendaItems={handleEditEventSetAgendaItems}
                      handleSetLanguages={handleEditEventSetLanguages}
                      loadTitle={loadTitle}
                      loadDescription={loadDescription}
                      loadEventType={loadEventType}
                      loadAudienceType={loadAudienceType}
                      loadDate={loadDate}
                      loadLanguages={loadLanguages}
                      loadTimeZone={loadTimeZone}
                      loadTimeStart={loadTimeStart}
                      loadTimeEnd={loadTimeEnd}
                      loadAgendaItems={loadAgendaItems}
                      loadAgendaFormat={loadAgendaFormat}
                    />
                    <Participants
                      speaker0ImgRef={speaker0ImgRef}
                      speaker1ImgRef={speaker1ImgRef}
                      speaker2ImgRef={speaker2ImgRef}
                      speaker3ImgRef={speaker3ImgRef}
                      speaker4ImgRef={speaker4ImgRef}
                      speaker5ImgRef={speaker5ImgRef}
                      initialize={initialize}
                      editEventMode={true}
                      pauseCrop={eventPreview ? true : false}
                      imageChangeTracker={imageChangeTracker}
                      setImageChangeTracker={setImageChangeTracker}
                      handleSetModerator={handleEditEventSetModerator}
                      loadModerator={loadModerator}
                      loadSpeaker0Data={loadSpeaker0Data}
                      loadSpeaker1Data={loadSpeaker1Data}
                      loadSpeaker2Data={loadSpeaker2Data}
                      loadSpeaker3Data={loadSpeaker3Data}
                      loadSpeaker4Data={loadSpeaker4Data}
                      loadSpeaker5Data={loadSpeaker5Data}
                      handleSetSpeaker0Data={handleEditEventSetSpeaker0Data}
                      handleSetSpeaker1Data={handleEditEventSetSpeaker1Data}
                      handleSetSpeaker2Data={handleEditEventSetSpeaker2Data}
                      handleSetSpeaker3Data={handleEditEventSetSpeaker3Data}
                      handleSetSpeaker4Data={handleEditEventSetSpeaker4Data}
                      handleSetSpeaker5Data={handleEditEventSetSpeaker5Data}
                    />
                    <Access
                      initialize={initialize}
                      edit={true}
                      handleSetAccessibilityNotes={
                        handleEditEventSetAccessibilityNotes
                      }
                      handleClearLocation={handleEditEventClearLocation}
                      handleSetCoordinates={handleEditEventSetCoordinates}
                      handleSetHostingOption={handleEditEventSetHostingOption}
                      handleSetLocation={handleEditEventSetLocation}
                      handleSetSignLanguageInterpreter={
                        handleEditEventSetSignLanguageInterpreter
                      }
                      handleSetVirtualMeetingId={
                        handleEditEventSetVirtualMeetingId
                      }
                      handleSetVirtualPasscode={
                        handleEditEventSetVirtualPasscode
                      }
                      handleSetVirtualPlatform={
                        handleEditEventSetVirtualPlatform
                      }
                      handleSetVirtualMeetingLink={
                        handleEditEventSetVirtualMeetingLink
                      }
                      handleSetWheelchairAccessibility={
                        handleEditEventSetWheelchairAccessibility
                      }
                      handleSetRealtimeTranslations={
                        handleEditEventSetRealtimeTranslations
                      }
                      loadHostingOption={loadHostingOption}
                      loadVirtualPlatform={loadVirtualPlatform}
                      loadVirtualMeetingId={loadVirtualMeetingId}
                      loadVirtualPasscode={loadVirtualPasscode}
                      loadVirtualLink={loadVirtualLink}
                      loadLocation={loadLocation}
                      loadCoordinates={loadCoordinates}
                      loadWheelchairAccessibility={loadWheelchairAccessibility}
                      loadSignLanguageInterpreter={loadSignLanguageInterpreter}
                      loadAccessibilityNotes={loadAccessibilityNotes}
                      loadRealtimeTranslations={loadRealtimeTranslations}
                    />
                    <Registration
                      initialize={initialize}
                      handleSetApproveRegistrations={
                        handleEditEventSetApproveRegistrations
                      }
                      handleSetMaxRegistrationsInPersonEnabled={
                        handleEditEventSetMaxRegistrationsInPersonEnabled
                      }
                      handleSetMaxRegistrationsVirtualEnabled={
                        handleEditEventSetMaxRegistrationsVirtualEnabled
                      }
                      handleSetEnablePrivateDetails={
                        handleEditEventSetEnablePrivateDetails
                      }
                      handleSetMaxRegistrationsInPersonAmount={
                        handleEditEventSetMaxRegistrationsInPersonAmount
                      }
                      handleSetMaxRegistrationsVirtualAmount={
                        handleEditEventSetMaxRegistrationsVirtualAmount
                      }
                      handleSetPrivateDetails={handleEditEventSetPrivateDetails}
                      handleSetRegistrations={handleEditEventSetRegistrations}
                      loadHostingOption={loadHostingOption}
                      loadMaxRegistrationsInPersonEnabled={
                        loadMaxRegistrationsInPersonEnabled
                      }
                      loadMaxRegistrationsVirtualEnabled={
                        loadMaxRegistrationsVirtualEnabled
                      }
                      loadEnablePrivateDetails={loadEnablePrivateDetails}
                      loadRegistrationsActive={loadRegistrationsActive}
                      loadApproveRegistrations={loadApproveRegistrations}
                      loadMaxRegistrationsInPersonAmount={
                        loadMaxRegistrationsInPersonAmount
                      }
                      loadMaxRegistrationsVirtualAmount={
                        loadMaxRegistrationsVirtualAmount
                      }
                      loadPrivateDetails={loadPrivateDetails}
                    />

                    <Promotion
                      ref={eventImageRef}
                      pauseCrop={eventPreview ? true : false}
                      imageChangeTracker={imageChangeTracker}
                      setImageChangeTracker={setImageChangeTracker}
                      editEventMode={true}
                      handleAddTinyTag={handleEditEventAddTinyTag}
                      handleRemoveTinyTag={handleEditEventRemoveTinyTag}
                      handleAddBigTag={handleEditEventAddBigTag}
                      handleRemoveBigTag={handleEditEventRemoveBigTag}
                      handleSetBigTags={handleEditEventSetBigTags}
                      handleSetTinyTags={handleEditEventSetTinyTags}
                      handleSetEventImagePath={handleEditEventSetEventImagePath}
                      loadTinyTags={loadTinyTags}
                      loadBigTags={loadBigTags}
                      loadEventImagePath={loadEventImagePath}
                    />
                    <ScrollToTop focusId={'initializeLabel'} />
                  </>
                )}
              </>
            )}
            <div className="h48" />
          </div>
        </div>
      </div>
      {!activatePreviewMode && eventPreview && (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          setLoadComplete={() => setActivatePreviewMode(true)}
          loadCheck1={eventPreview ? true : false}
          customWrapper={'min-full-height'}
        />
      )}
      {eventPreview && (
        <div className={`${activatePreviewMode ? 'organize' : 'remove'}`}>
          <EventPage
            eventData={eventPreview}
            setViewEvent={setEventPreview}
            organizerViewOn={true}
            eventImageRef={eventImageRef.current}
            previewMode={true}
            handleReturn={handleReturn}
            speaker0ImgRef={speaker0ImgRef.current}
            speaker1ImgRef={speaker1ImgRef.current}
            speaker2ImgRef={speaker2ImgRef.current}
            speaker3ImgRef={speaker3ImgRef.current}
            speaker4ImgRef={speaker4ImgRef.current}
            speaker5ImgRef={speaker5ImgRef.current}
          />
        </div>
      )}
    </>
  );
};

export default EditEvent;
