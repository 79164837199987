import { createContext, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';

import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useOrganizeHandlers } from '../../features/main/organize/useOrganizeHandlers';
import useApiStatus from '../useApiStatus';

const OrganizeEventsSocketContext = createContext({});

export const OrganizeEventsSocketProvider = ({ children }) => {
  //Hooks
  const { accountId } = useAccountReduxHandlers();
  const { handleSocketOrganizedEventData } = useOrganizeHandlers();
  const { handleError } = useApiStatus();

  const [organizeEventsSocketStatus, setOrganizeEventsSocketStatus] =
    useState('closed');

  //variables
  const socketRef = useRef(null);

  const connectOrganizeEventsSocket = () => {
    if (!socketRef.current && organizeEventsSocketStatus !== 'connected') {
      let newSocket;

      if (process.env.REACT_APP_ENV === 'development') {
        newSocket = io('http://localhost:5000/ws/organizeEvents', {
          query: { accountId },
        });
      } else {
        newSocket = io('https://myndfull.com/ws/organizeEvents', {
          withCredentials: true,
          query: { accountId },
        });
      }

      newSocket.on('connect', () => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(
            `Dev: You connected with the OrganizeEvents Web Socket, id: ${newSocket.id}`
          );
        }

        setOrganizeEventsSocketStatus('connected');
      });

      newSocket.on('organizeEventsData', (organizeEventsData) => {
        handleSocketOrganizedEventData(organizeEventsData);
      });

      newSocket.on('connect_error', (error) => {
        setOrganizeEventsSocketStatus('pending');
        if (process.env.NODE_ENV === 'development') {
          console.error(
            'Dev: Error connecting to organizeEvents Socket:',
            error
          );
        }
      });

      newSocket.on('error', (error) => {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TranslationRoomSocketProvider.js',
        });
      });

      newSocket.on('reconnect', (attempt) => {
        if (process.env.NODE_ENV === 'development') {
          console.error('Dev: Reconnecting to organizeEvents Socket:');
        }
      });

      return (socketRef.current = newSocket);
    }
  };

  const getOrganizeEventsSocket = () => {
    return socketRef.current;
  };

  const closeOrganizeEventsSocket = () => {
    const socket = socketRef.current;

    if (socket) {
      socket.close();
      socketRef.current = null;
      setOrganizeEventsSocketStatus('closed');

      if (process.env.NODE_ENV === 'development') {
        console.error('Dev: Closed Organize Events Socket:');
      }
    }
  };

  return (
    <OrganizeEventsSocketContext.Provider
      value={{
        connectOrganizeEventsSocket,
        closeOrganizeEventsSocket,
        getOrganizeEventsSocket,
        organizeEventsSocketStatus,
      }}
    >
      {children}
    </OrganizeEventsSocketContext.Provider>
  );
};

export const useOrganizeEventsSocket = () => {
  return useContext(OrganizeEventsSocketContext);
};
