import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useApiStatus from '../../../../../context/useApiStatus';
import useSettings from '../../../../../context/useSettings';

//components
import BillingPaymentNewCard from './BillingPaymentNewCard';

//utils
import { axiosLimited } from '../../../../../axios/axios';
import { retryWrapper } from '../../../../../utils/logic/retryFns';
import { appearance } from '../../../../../data/stripeAppearance';

const BillingPaymentNewCardStripeWrapper = ({
  saveAccountChange,
  handleCancelNewCard,
  reset,
  hasPrimaryCard,
  setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices,
}) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { contentLanguage } = useSettings();
  const { handleError } = useApiStatus();

  //stripe
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState(null);
  const [key, setKey] = useState(0);

  //ui
  const [setupIntentIsLoading, setSetupIntentIsLoading] = useState(false);

  //variables
  const loadStripeRan = useRef(false);

  //initialize
  useEffect(() => {
    if (loadStripeRan.current === false && accountId) {
      loadStripeRan.current = true;
      handleLoadStripePromise();
    }
  }, [accountId]);

  async function handleLoadStripePromise() {
    try {
      if (process.env.REACT_APP_ENV === 'development') {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)
        );
        await retryWrapper(createSetupIntent);
      } else {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        );
        await retryWrapper(createSetupIntent);
      }
    } catch (error) {
      handleError({
        message: 'networkError',
        id: Date.now(),
        origin: 'BillingPaymentNewCard.js/handleLoadStripePromise',
        error,
      });
    }
  }

  async function createSetupIntent() {
    if (!setupIntentIsLoading) {
      setSetupIntentIsLoading(true);
      try {
        const response = await axiosLimited.post(
          '/api/stripe/createSetupIntent'
        );

        if (!response.data.clientSecret || !response.data.setupIntentId) {
          throw new Error();
        }

        setClientSecret(response.data.clientSecret);
        setKey((prevKey) => prevKey + 1);
        setSetupIntentIsLoading(false);
      } catch (error) {
        setSetupIntentIsLoading(false);
        throw error;
      }
    }
  }

  function resetStripeWrapperThenBillingPaymentMethods() {
    setClientSecret('');
    setStripePromise(null);
    setKey(0);
    setSetupIntentIsLoading(false);
    handleLoadStripePromise();

    //reset Billing Payment Methods
    reset();
  }
  return (
    <>
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance,
            locale: contentLanguage,
          }}
          key={key}
        >
          <BillingPaymentNewCard
            saveAccountChange={saveAccountChange}
            handleCancelNewCard={handleCancelNewCard}
            reset={resetStripeWrapperThenBillingPaymentMethods}
            hasPrimaryCard={hasPrimaryCard}
            setupIntentIsLoading={setupIntentIsLoading}
            setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices={
              setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices
            }
          />
        </Elements>
      )}
    </>
  );
};

export default BillingPaymentNewCardStripeWrapper;
