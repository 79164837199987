import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
//Components
import CustomTagsList from '../../../../../components/CustomTagsList/CustomTagsList';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
//Utility
import { tinytag } from '../../../../../assets/icons';
import { deepEqual } from '../../../../../utils/logic/deepEqual';

const TinyTags = ({
  Account,
  saveAccountChange,
  updateAccountIsSuccess,
  loadingSaveBtnUI,
}) => {
  //Hooks
  const {
    accountTinyTags: loadTinyTags,
    accountTinyTagGroups: loadTinyTagGroups,
    accountBigTags,
    accountTags,
  } = useAccountReduxHandlers();

  //Component state
  const [tinyTags, setTinyTags] = useState(loadTinyTags || []);
  const [tinyTagGroups, setTinyTagGroups] = useState(loadTinyTagGroups || []);
  const [tinyTagsSaveValid, setTinyTagsSaveValid] = useState(false);
  const [tinyTagGroupsSaveValid, setTinyTagGroupsSaveValid] = useState(false);
  const [tinyTagGroupsModifyActive, setTinyTagGroupsModifyActive] =
    useState(false);

  //Initialize functions
  useEffect(() => {
    if (loadTinyTags) {
      setTinyTags(loadTinyTags);
    }
    if (loadTinyTagGroups) {
      setTinyTagGroups(loadTinyTagGroups);
    }
  }, [loadTinyTagGroups, loadTinyTags]);

  //Component functions
  useEffect(() => {
    const sameContentsTinyTags =
      tinyTags?.length === loadTinyTags?.length &&
      tinyTags.every((value) => loadTinyTags.includes(value));

    if (!sameContentsTinyTags && !tinyTagGroupsModifyActive) {
      setTinyTagsSaveValid(true);
    } else {
      setTinyTagsSaveValid(false);
    }
  }, [tinyTags, loadTinyTags, tinyTagGroupsModifyActive]); //checks if tinyTags save is valid

  useEffect(() => {
    const sameContentsTinyTagGroups =
      tinyTagGroups?.length === loadTinyTagGroups?.length &&
      tinyTagGroups.every((value, index) =>
        deepEqual(value, loadTinyTagGroups[index])
      );

    if (!sameContentsTinyTagGroups && !tinyTagGroupsModifyActive) {
      setTinyTagGroupsSaveValid(true);
    } else {
      setTinyTagGroupsSaveValid(false);
    }
  }, [tinyTagGroups, loadTinyTagGroups, tinyTagGroupsModifyActive]); //checks if tinyTagGroup save is valid

  useEffect(() => {
    let tagsCount = 0;
    tinyTagGroups?.forEach((tG) => {
      tagsCount += tG.tags.length;
    });
    tagsCount = tagsCount + tinyTags.length;

    if (tagsCount === 0) {
      setTinyTagsSaveValid(false);
    }
  }, [tinyTags, loadTinyTags, tinyTagGroups, loadTinyTagGroups]); //prevent save if there is less than one tag

  function removeTinyTag(removedTag) {
    let newArr = tinyTags.filter((tag) => tag !== removedTag);
    setTinyTags(newArr);
  }

  function addTinyTag(addedTag) {
    let newArr = [...tinyTags];
    newArr.push(addedTag);
    setTinyTags(newArr);
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="tinyTagsLabel"
      >
        {Account.attendee.tinyTags}
        <p className="color-theme">&nbsp;*</p>
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.tinyTagsDescription}
      </p>
      <div className="h24" />
      <CustomTagsList
        handleAdd={addTinyTag}
        handleRemove={removeTinyTag}
        limit={200}
        icon={tinytag}
        id={'tinyTags'}
        tinyTags={tinyTags}
        setTinyTags={setTinyTags}
        twoTagRows={true}
        tinyTagGroups={tinyTagGroups}
        setTinyTagGroups={setTinyTagGroups}
        setTinyTagGroupsModifyActive={setTinyTagGroupsModifyActive}
        bigTags={accountBigTags}
        updateAccountIsSuccess={updateAccountIsSuccess}
      />

      <div className="h24" />
      <SaveButton
        saveValid={tinyTagsSaveValid || tinyTagGroupsSaveValid}
        handler={() =>
          saveAccountChange(
            {
              tags: {
                ...accountTags,
                tinyTags,
                tinyTagGroups,
              },
            },
            'tinyTags'
          )
        }
        saveItemLanguage={Account.attendee.tinyTags}
        isLoading={loadingSaveBtnUI === 'tinyTags'}
      />
      <div className="h48" />
    </div>
  );
};

export default TinyTags;
