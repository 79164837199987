import { useState } from 'react';

//Hooks
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useGenericModal from '../../../../context/useGenericModal';

//Components
import ActionContainer from './ActionContainer';
import InfoContainer from './InfoContainer';
import ScrollTo from '../../../../utils/UI/ScrollTo';
import ConfirmationModal from '../../../../components/Modal/ModalComponents/ConfirmationModal';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';

//Utility

const OrganizeEventCard = ({
  eventData,
  setEditEvent,
  organizeType,
  handleRemoveEvent,
  index,
}) => {
  //Hooks
  const { OrganizerEventCard: OrganizerEventCardLanguage } =
    useLanguageOrganize();
  const { handleSettingsSetViewEventDetails } = useSettingsHandlers();
  const { setGenericModalMobileFloat } = useGenericModal();
  const { accountId } = useAccountReduxHandlers();
  //Component state
  const [actionPortView, setActionPortView] = useState();

  //UI
  const [imageLoadConfirmation, setImageLoadConfirmation] = useState(false);

  //UI Functions
  function handleActionClick(id) {
    if (id === 'virtualRegistrations') {
      if (actionPortView === 'virtualRegistrations') {
        setActionPortView();
      } else {
        setActionPortView('virtualRegistrations');
      }
    }
    if (id === 'inPersonRegistrations') {
      if (actionPortView === 'inPersonRegistrations') {
        setActionPortView();
      } else {
        setActionPortView('inPersonRegistrations');
      }
    }
    if (id === 'messages') {
      if (actionPortView === 'messages') {
        setActionPortView();
      } else {
        setActionPortView('messages');
      }
    }
    if (id === 'announcements') {
      if (actionPortView === 'announcements') {
        setActionPortView();
      } else {
        setActionPortView('announcements');
      }
    }
    if (id === 'viewEvent') {
      handleSettingsSetViewEventDetails(eventData);
      setActionPortView();
      handleSettingsSetViewEventDetails(eventData);
    }
    if (id === 'editEvent') {
      setActionPortView();
      setEditEvent(eventData);
    }
    if (id === 'eventDashboard') {
      if (actionPortView === 'eventDashboard') {
        setActionPortView();
      } else {
        setActionPortView('eventDashboard');
      }
    }

    if (id === 'removeArchivedEvent') {
      setActionPortView('removeArchivedEvent');

      setGenericModalMobileFloat(
        <ConfirmationModal
          headingText={OrganizerEventCardLanguage.confirmArchivedEventRemoval}
          buttonPosText={OrganizerEventCardLanguage.remove}
          buttonNegText={OrganizerEventCardLanguage.cancel}
          buttonNegRed={true}
          buttonPosGreen={false}
          reverseColors={true}
          focusId={'organizeGrid'}
          handler={() => {
            setActionPortView();
            handleRemoveEvent(eventData?._id, accountId, 'archived');
          }}
        />
      );
    }
  }

  function handleMobileViewCloseBtn(eventId) {
    const elementId = eventId.substring(0, 8);
    setActionPortView();
    ScrollTo(elementId, 'smooth', false, elementId);
  }

  return (
    <>
      {eventData && (
        <div
          className={`organize-event-card__wrapper mrg-b48 access-ob access-o6 ${
            !imageLoadConfirmation ? 'remove' : ''
          }`}
          tabIndex="0"
          id={`${eventData?._id.substring(0, 8)}`}
        >
          <InfoContainer
            eventData={eventData}
            organizeType={organizeType}
            index={index}
            setImageLoadConfirmation={setImageLoadConfirmation}
          />
          <ActionContainer
            eventData={eventData}
            handleActionClick={handleActionClick}
            actionPortView={actionPortView}
            OrganizerEventCardLanguage={OrganizerEventCardLanguage}
            handleMobileViewCloseBtn={handleMobileViewCloseBtn}
            organizeType={organizeType}
          />
        </div>
      )}
    </>
  );
};

export default OrganizeEventCard;
