import { useState } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useEventHandlers } from '../useEventHandlers';
//Components

//Utility
import {
  return_curved_arrow,
  share,
  star,
  view_ignore,
} from '../../../../assets/icons';

const ActionButtons = ({
  eventData,
  eventStatus,
  handleMobileTap,
  EventPage,
  handleReturn,
  setEventStatus,
  organizerViewOn,
  registrationsComponentOn,
}) => {
  //Hooks
  const { handleUserSaveEvent, handleUserIgnoreEvent } = useEventHandlers();
  const { accountId } = useAccountReduxHandlers();

  //UI state
  const [tapHighlightReturn, setTapHighlightReturn] = useState(false);
  const [tapHighlightIgnore, setTapHighlightIgnore] = useState(false);
  const [tapHighlightShare, setTapHighlightShare] = useState(false);
  const [tapHighlightSave, setTapHighlightSave] = useState(false);

  return (
    <ul
      className={`ev__action-btns access-ob access-o6  ${
        eventStatus !== 'neutral' ||
        eventStatus !== 'saved' ||
        eventStatus !== 'ignored'
          ? 'action-btn-space-evenly'
          : ''
      }
      ${
        registrationsComponentOn
          ? 'ev__action-btns--reg'
          : 'ev__action-btns--no-reg'
      }
      `}
      id="actionBtns"
      tabIndex="0"
    >
      <li>
        <button
          className={`action-btn highlight-i-theme
         ${
           tapHighlightReturn ? 'highlight-i-theme--tap click-scaledown-90' : ''
         }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightReturn(true),
                () => setTapHighlightReturn(false),
              ],
              [() => handleReturn()],
              true
            )
          }
          title={EventPage.return}
        >
          <img src={return_curved_arrow} alt="" className="filter-lightgray" />
        </button>
      </li>
      <li>
        <button
          className={`action-btn highlight-i-theme ${
            organizerViewOn ? 'opacity-3' : ''
          } ${
            tapHighlightShare ? 'highlight-i-theme--tap click-scaledown-90' : ''
          }`}
          onClick={
            !organizerViewOn
              ? () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightShare(true),
                      () => setTapHighlightShare(false),
                    ],
                    [() => console.log('Share Click')],
                    true
                  )
              : null
          }
          title={EventPage.clickToShare}
        >
          <img
            src={share}
            alt={EventPage.shareButton}
            className="filter-lightgray"
          />
        </button>
      </li>
      {(eventStatus === 'neutral' ||
        eventStatus === 'saved' ||
        eventStatus === 'ignored') && (
        <>
          <li>
            <button
              id="status-ignore"
              className={`action-btn highlight-i-theme ${
                organizerViewOn ? 'opacity-3' : ''
              } ${tapHighlightIgnore ? 'click-scaledown-90' : ''}`}
              onClick={
                !organizerViewOn
                  ? () =>
                      handleMobileTap(
                        [
                          () => setTapHighlightIgnore(true),
                          () => setTapHighlightIgnore(false),
                        ],
                        [
                          () =>
                            handleUserIgnoreEvent(
                              eventData._id,
                              accountId,
                              eventStatus,
                              setEventStatus
                            ),
                        ],
                        true
                      )
                  : null
              }
              title={`${
                eventStatus === 'ignored'
                  ? EventPage.clickToUnignore
                  : EventPage.clickToIgnore
              }`}
            >
              <img
                src={view_ignore}
                alt={EventPage.ignoreButton}
                className={`${
                  eventStatus === 'ignored'
                    ? 'filter-theme'
                    : 'filter-lightgray'
                }`}
              />
            </button>
          </li>
          <li>
            <button
              id="status-save"
              className={`action-btn highlight-i-theme  ${
                organizerViewOn ? 'opacity-3' : ''
              } ${tapHighlightSave ? 'click-scaledown-90' : ''}`}
              onClick={
                !organizerViewOn
                  ? () =>
                      handleMobileTap(
                        [
                          () => setTapHighlightSave(true),
                          () => setTapHighlightSave(false),
                        ],
                        [
                          () =>
                            handleUserSaveEvent(
                              eventData._id,
                              accountId,
                              eventStatus,
                              setEventStatus
                            ),
                        ],
                        true
                      )
                  : null
              }
              title={
                eventStatus === 'saved'
                  ? EventPage.clickToUnsave
                  : EventPage.clickToSave
              }
            >
              <img
                src={star}
                alt={EventPage.saveButton}
                className={`${
                  eventStatus === 'saved' ? 'filter-theme' : 'filter-lightgray'
                }`}
                style={{ height: '38px' }}
              />
            </button>
          </li>
        </>
      )}
    </ul>
  );
};

export default ActionButtons;
