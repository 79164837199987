//hooks

//components
//utils

const InfoCardContainerCompany = ({ companyData }) => {
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Company</p>
      <p className="mrg-t6">Position: {companyData?.position}</p>
    </div>
  );
};

export default InfoCardContainerCompany;
