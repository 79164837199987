import { useState } from 'react';
import { Link } from 'react-router-dom';

//Hooks
import useSettings from '../../context/useSettings';
import useLanguageLayout from '../../language/useLanguageLayout';
import useLanguageData from '../../language/useLanguageData';
//Components

//Utils
import { dropdown_arrow, exchange, login, register } from '../../assets/icons';

const NavLoggedOut = () => {
  //Hooks
  const { navbar, setNavbar, contentLanguage, setContentLanguage } =
    useSettings();
  const { NavbarLoggedOut } = useLanguageLayout();
  const { ContentLanguage, LanguageOpts } = useLanguageData();

  //Component state
  const [showLanguageOpts, setShowLanguageOpts] = useState(false);

  //Initialize functions
  document.addEventListener('click', function (e) {
    if (
      showLanguageOpts &&
      e.target.id !== 'modal-dropdown' &&
      e.target.id !== 'modal-text' &&
      e.target.id !== 'modal-img'
    ) {
      setShowLanguageOpts(false);
    }
  }); //closes language selection menu if opened

  //Component functions
  function handleClick(selection) {
    if (selection) {
      setNavbar(selection);
    } else {
      setNavbar('');
    }
  }

  function handleLanguage(selection) {
    setContentLanguage(selection);
  }

  return (
    <div className="nav__links no-select">
      <button
        className="modal-dropdown fs18 fwb color-white access-ob access-o6"
        onClick={() => setShowLanguageOpts(!showLanguageOpts)}
        id="modal-dropdown"
        tabIndex="0"
        aria-expanded={showLanguageOpts}
        aria-label={`${NavbarLoggedOut.selectSiteLanguage} ${ContentLanguage}`}
        aria-controls="modal-dropdown__button"
      >
        <img
          id="modal-img"
          src={dropdown_arrow}
          alt="arrow"
          className={`${showLanguageOpts ? 'reverse-vertical' : ''}`}
          aria-hidden="true"
        />
        {ContentLanguage}
      </button>
      {showLanguageOpts && (
        <ul className="modal-dropdown__button modal-dropdown__button--header">
          <li>
            <button
              onClick={() => handleLanguage('en-CA')}
              className={`fs16 fwsb access-ob access-o-6 ${
                contentLanguage === 'en-CA' ? 'selected' : ''
              } `}
              tabIndex="0"
            >
              {LanguageOpts['en-CA']}
            </button>
          </li>
          <li>
            <button
              onClick={() => handleLanguage('fr-CA')}
              className={`fs16 fwsb access-ob access-o-6 ${
                contentLanguage === 'fr-CA' ? 'selected' : ''
              }`}
              tabIndex="0"
            >
              {LanguageOpts['fr-CA']}
            </button>
          </li>
        </ul>
      )}
      <Link
        to={{ pathname: '/tr' }}
        onClick={() => setNavbar('translations')}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <img
            src={exchange}
            alt={'img'}
            aria-hidden="true"
            className="filter-white"
          />
          <span
            className={`${
              navbar === 'translations' ? 'active fadeIn' : 'inactive'
            }`}
          />
        </div>
        {NavbarLoggedOut.translations}
      </Link>

      <Link
        to={{ pathname: '/register' }}
        onClick={() => handleClick('register')}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <img
            src={register}
            alt={NavbarLoggedOut.register}
            className="filter-white"
            aria-hidden="true"
          />
          <span
            className={`${
              navbar === 'register' ? 'active fadeIn' : 'inactive'
            }`}
          />
        </div>
        {NavbarLoggedOut.register}
      </Link>

      <Link
        to={{ pathname: '/login' }}
        onClick={() => handleClick('login')}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <span
            className={`${navbar === 'login' ? 'active fadeIn' : 'inactive'}`}
          />
          <img
            src={login}
            alt={NavbarLoggedOut.decorativeEnterIcon}
            aria-hidden="true"
          />
        </div>
        {NavbarLoggedOut.signIn}
      </Link>
    </div>
  );
};

export default NavLoggedOut;
