//hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import { useAuthReduxHandlers } from '../../../auth/useAuthHandlers';

//components
import ButtonSwitch from '../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//utils

const AttendeeRoleToggle = ({
  handleSaveAccountNewRoles,
  setRoles,
  roles,
  rolesChangeValid,
  loadingSaveBtnUI,
}) => {
  //hooks
  const { authCurrentRoles: loadRoles } = useAuthReduxHandlers();
  const { Account } = useLanguageAccount();

  //Component functions
  function handleAttendeeRoleToggle() {
    let newRoles = [...roles];

    if (newRoles.includes(process.env.REACT_APP_ATTENDEE)) {
      newRoles = newRoles.filter(
        (number) => number !== process.env.REACT_APP_ATTENDEE
      );
    } else {
      newRoles = [...newRoles, process.env.REACT_APP_ATTENDEE];
    }
    newRoles.sort();
    return setRoles(newRoles);
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="attendeeRoleToggleLabel"
      >
        {Account.account.activateAttendeeSection}
      </label>
      <div className="h12" />

      <ButtonSwitch
        id={'attendeeToggle'}
        loadValue={loadRoles.includes(process.env.REACT_APP_ATTENDEE)}
        secondaryTrigger={roles}
        loadTrigger={true}
        handleSwitch={handleAttendeeRoleToggle}
      />
      <div className="h24" />
      <SaveButton
        saveValid={rolesChangeValid}
        // saveItemLanguage={Account.account.activateAttendeeSection}
        handler={handleSaveAccountNewRoles}
        isLoading={loadingSaveBtnUI === 'role'}
      />
      <div className="h36" />
    </div>
  );
};

export default AttendeeRoleToggle;
