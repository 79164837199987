import { useState, useEffect, useRef } from 'react';

//Hooks
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';
import useGenericModal from '../../context/useGenericModal';
import useLanguageData from '../../language/useLanguageData';

//Components
import CountryCodesModal from '../Modal/ModalComponents/CountryCodesModal';

//Utility
import { unknown_flag } from '../../assets/icons';
import GetFlag from '../../utils/images/GetFlag';
import handleInputClick from './handleInputClick';

const PhoneInput = ({
  asterisk,
  countryCode,
  customWrapper,
  customWidth,
  id,
  phoneNumber,
  saveValid,
  setSaveValid,
  setCountryCode,
  setPhoneNumber,
  loadValue,
  loadCountryCode,
}) => {
  //Hooks
  const { dismissKeypad, handleMobileTap } = useSettings();
  const { setGenericModal, genericModal } = useGenericModal();
  const { PhoneNumberInput, Generic } = useLanguageComponents();
  const { CountryCodeOpts } = useLanguageData();

  //Component state
  const [checkRan, setCheckRan] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);

  //UI state
  const [flag, setFlag] = useState();
  const [listOpened, setListOpened] = useState(false);

  const [activeWarning, setActiveWarning] = useState(false);
  const [focusHighlightInput, setFocusHighlightInput] = useState(false);
  const [borderHighlightInput, setBorderHighlightInput] = useState(false);
  const [focusHighlightBtn, setFocusHighlightBtn] = useState(false);

  //Variables
  const phoneInput = useRef();

  //Initialie & UI
  useEffect(() => {
    setFlag(GetFlag(countryCode));
  }, [countryCode]);

  useEffect(() => {
    if (genericModal) {
      setListOpened(true);
    }
    if (!genericModal && listOpened) {
      setListOpened(false);
    }
  }, [genericModal]); //country code, sets focus back on See Full List btn after full list modal closes

  function handleSeeFullList(e) {
    e.preventDefault();
    setListOpened(true);
    setGenericModal(
      <CountryCodesModal
        CountryCodeOpts={CountryCodeOpts}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    );
  }

  function handleOpenCountryCodeModal(e) {
    e.preventDefault();
    handleMobileTap(
      [() => setFocusHighlightBtn(true), () => setFocusHighlightBtn(false)],
      [() => handleSeeFullList(e)]
    );
  }

  //Component functions
  useEffect(() => {
    validation(phoneNumber);
  }, [countryCode, loadValue, loadCountryCode]);

  //Component functions
  function validation(value) {
    if (value === loadValue && countryCode === loadCountryCode) {
      setInvalidPhoneNumber(false);
      return setSaveValid(false);
    } //same as load value

    const check = value?.length === 14 || value?.length === 0 ? true : false;

    if (!check || (!countryCode && value?.length > 0)) {
      setInvalidPhoneNumber(true);
      setSaveValid(false);
    } else if (check && countryCode) {
      setInvalidPhoneNumber(false);
      setSaveValid(true);
    } else {
      //empty phone number to save
      setInvalidPhoneNumber(false);
      setSaveValid(true);
    }
  }

  function handlePhoneInput(phoneNumber) {
    let num = phoneNumber;
    let filteredNum = num.replace(/\D/g, '');
    let size = filteredNum.length;
    if (size > 0) {
      filteredNum = '(' + filteredNum;
    }
    if (size > 3) {
      filteredNum = filteredNum.slice(0, 4) + ') ' + filteredNum.slice(4, 11);
    }
    if (size > 6) {
      filteredNum = filteredNum.slice(0, 9) + '-' + filteredNum.slice(9);
    }
    validation(filteredNum);
    setPhoneNumber(filteredNum);
  }

  useEffect(() => {
    if (checkRan) {
      if (!invalidPhoneNumber && activeWarning) {
        const timer = setTimeout(() => {
          setActiveWarning(false);
        }, 500);
        return () => clearTimeout(timer);
      }
      //this timer creates a short delay to give the user a chance to provide valid email and not render the warning text
      let invalidEmailAddressTimer;

      if (!invalidPhoneNumber) clearTimeout(invalidEmailAddressTimer);

      if (invalidPhoneNumber) {
        invalidEmailAddressTimer = setTimeout(() => {
          setActiveWarning(true);
        }, 1000);

        return () => clearTimeout(invalidEmailAddressTimer);
      }
    }
  }, [invalidPhoneNumber, checkRan]);

  return (
    <div className="input-error__wrapper">
      <div
        className={`textarea__wrapper ${customWrapper ? customWrapper : ''} `}
        style={
          customWidth
            ? { width: `${customWidth}`, maxWidth: `${customWidth}` }
            : null
        }
      >
        <div className="flex-row">
          <button
            className={`textarea-button mrg-auto-top mrg-auto-bottom highlight-o-theme fs16 fwn mrg-r12 ${
              focusHighlightBtn
                ? 'highlight-ob-theme--active'
                : 'highlight-ob-theme'
            }
            ${
              invalidPhoneNumber && activeWarning && checkRan
                ? 'textarea-warning'
                : ''
            }
            `}
            onClick={(e) => handleOpenCountryCodeModal(e)}
            id="countryCodeOpenListBtn"
          >
            <div className="flex-column flex-center">
              {countryCode ? (
                <>{flag}</>
              ) : (
                <img alt={Generic.flag} src={unknown_flag} />
              )}
            </div>
          </button>
          <div
            className="textarea__wrapper"
            onMouseEnter={() => setBorderHighlightInput(true)}
            onMouseLeave={() => setBorderHighlightInput(false)}
          >
            <label
              id={`${id}-label`}
              className="textarea-label no-select"
              onClick={() => handleInputClick(phoneInput.current)}
            >
              <p>
                {PhoneNumberInput.phoneNumber}&nbsp;
                {asterisk && <span className="highlight">*</span>}
              </p>
            </label>

            <textarea
              id={id}
              type="text"
              ref={phoneInput}
              inputMode="numeric"
              placeholder="(xxx) xxx-xxxx"
              aria-label={PhoneNumberInput.ariaInstructions}
              enterKeyHint="done"
              maxLength="14"
              value={phoneNumber || ''}
              className={`textarea  ${
                borderHighlightInput ? 'highlight-o-theme--tap' : ''
              }
              ${
                focusHighlightInput
                  ? 'highlight-ob-theme--active'
                  : 'highlight-ob-theme'
              }  
              ${
                invalidPhoneNumber && activeWarning && checkRan
                  ? 'textarea-warning'
                  : ''
              }  `}
              onBlur={(e) => {
                if (e.target.value?.length > 0 || countryCode) {
                  validation(e.target.value);
                }
                setCheckRan(true);
                setFocusHighlightInput(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFocusHighlightInput(false);
                  dismissKeypad(e);
                }
              }}
              onChange={(e) => {
                handlePhoneInput(e.target.value);
              }}
              onFocus={() => setFocusHighlightInput(true)}
            />
          </div>
        </div>
      </div>
      <p
        className={`input-error access-ob ${
          invalidPhoneNumber && activeWarning && checkRan
            ? 'input-error-animation'
            : activeWarning && checkRan
            ? 'input-error-hide'
            : ''
        }`}
        tabIndex={!saveValid ? '0' : '-1'}
      >
        {PhoneNumberInput.phoneNumberInvalid}
      </p>
    </div>
  );
};

export default PhoneInput;
