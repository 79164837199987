import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';

const InfoCardStatusIcon = ({ ticketData }) => {
  const { SupportStatus } = useLanguageAdmin();
  let statusIcon;

  if (ticketData?.status === 'open') {
    statusIcon = (
      <p className="ticket-status ticket-status--open mrg-r12">
        {SupportStatus[ticketData?.status]}
      </p>
    );
  } else if (ticketData?.status === 'pendingUserAction') {
    statusIcon = (
      <p className="ticket-status ticket-status--pendingUserAction mrg-r12">
        {SupportStatus[ticketData?.status]}
      </p>
    );
  } else if (ticketData?.status === 'resolved') {
    statusIcon = (
      <p className="ticket-status ticket-status--resolved mrg-r12">
        {SupportStatus[ticketData?.status]}
      </p>
    );
  }

  return statusIcon;
};

export default InfoCardStatusIcon;
