import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//Hooks
import useSettings from '../../../context/useSettings';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import {
  useLazyBigTagEventsQuery,
  useLazyTinyTagEventsQuery,
  useLazyMyEventsSavedQuery,
  useLazyMyEventsRegisteredQuery,
  useMyEventsSavedSelectors,
  useMyEventsRegisteredSelectors,
  useMyEventsArchivedSelectors,
  useLazyMyEventsArchivedQuery,
  useLazyNetworkEventsQuery,
} from './eventsApiSlice';

//Components
import SettingsEvents from '../settings/Events/SettingsEvents';
import EventsGrid from './EventsGrid';
import EventPage from './EventPage/EventPage';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';

//Utility

const Events = () => {
  //Hooks
  const { cards, setNavbar } = useSettings();
  const {
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchCategory: searchCategory,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
    settingsViewEventDetails,

    //BT
    handleSettingsSetSelectedBigTags,
    handleSettingsSetSelectAllBTBtn,
    handleSettingsSetBTQueryPage,
    handleSettingsSetBTQueryHasMore,
    settingsBTQueryPage: bTQueryPage,
    settingsBTQueryHasMore: bTQueryHasMore,
    settingsSelectedBigTags: searchBTs,

    //MyEvents
    settingsMyEventsView: myEventsSelection,

    //TT
    handleSettingsSetSelectAllTTBtn,
    handleSettingsSetSelectedTinyTags,
    handleSettingsSetTTQueryPage,
    handleSettingsSetTTQueryHasMore,
    handleSettingsSetSelectedTinyTagGroupIds,
    settingsTTQueryPage: tTQueryPage,
    settingsTTQueryHasMore: tTQueryHasMore,
    settingsSelectedTinyTags: searchTTs,
    settingsSelectedTinyTagGroupIds: searchTGIds,

    //Network
    handleSettingsSetNetworkQueryHasMore,
    handleSettingsSetNetworkQueryPage,
    handleSettingsSetSelectAllAccountsFollowingBtn,
    handleSettingsSetSelectedAccountsFollowingIds,
    handleSettingsSetSelectedAccountsFollowingGroupIds,
    settingsNetworkQueryHasMore: networkQueryHasMore,
    settingsNetworkQueryPage: networkQueryPage,
    settingsSelectedAccountsFollowingGroupIds: searchAccountsFollowingGroupIds,
    settingsSelectedAccountsFollowingIds: searchAccountsFollowingIds,
  } = useSettingsHandlers();

  const {
    accountId,
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountGeoPositionCoordinates,
    accountFollowing,
    accountFollowingGroups,
  } = useAccountReduxHandlers();

  const { sortedMyEventsSaved } = useMyEventsSavedSelectors();
  const { sortedMyEventsRegistered } = useMyEventsRegisteredSelectors();
  const { sortedMyEventsArchived } = useMyEventsArchivedSelectors();
  const myEventsSavedSorted = useSelector(sortedMyEventsSaved);
  const myEventsRegisteredSorted = useSelector(sortedMyEventsRegistered);
  const myEventsArchivedSorted = useSelector(sortedMyEventsArchived);

  const [
    getBigTagEvents,
    {
      data: bTData,
      isFetching: bTisFetching,
      isLoading: bTisLoading,
      isSuccess: bTisSuccess,
      isUninitialized: bTisUninitialized,
    },
  ] = useLazyBigTagEventsQuery();
  const [
    getTinyTagEvents,
    {
      data: tTData,
      isFetching: tTisFetching,
      isLoading: tTisLoading,
      isSuccess: tTisSuccess,
      isUninitialized: tTisUninitialized,
    },
  ] = useLazyTinyTagEventsQuery();

  const [
    getNetworkEvents,
    {
      data: networkData,
      isFetching: networkisFetching,
      isLoading: networkisLoading,
      isSuccess: networkisSuccess,
      isUninitialized: networkisUninitialized,
    },
  ] = useLazyNetworkEventsQuery();

  const [
    getMyEventsSaved,
    {
      isFetching: myEventsSavedisFetching,
      isLoading: myEventsSavedisLoading,
      isSuccess: myEventsSavedisSuccess,
      isUninitialized: myEventsSavedisUninitialized,
    },
  ] = useLazyMyEventsSavedQuery();
  const [
    getMyEventsRegistered,
    {
      isFetching: myEventsRegisteredisFetching,
      isLoading: myEventsRegisteredisLoading,
      isSuccess: myEventsRegisteredisSuccess,
      isUninitialized: myEventsRegisteredisUninitialized,
    },
  ] = useLazyMyEventsRegisteredQuery();
  const [
    getMyEventsArchived,
    {
      isFetching: myEventsArchivedisFetching,
      isLoading: myEventsArchivedisLoading,
      isSuccess: myEventsArchivedisSuccess,
      isUninitialized: myEventsArchivedisUninitialized,
    },
  ] = useLazyMyEventsArchivedQuery();

  //Component state
  // const [viewEvent, setViewEvent] = useState(null);
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);

  //bT
  const [filteredBTEvents, setFilteredBTEvents] = useState([]);
  const [BTCardsIsFetchingToFillRow, setBTCardsIsFetchingToFillRow] =
    useState(false);

  //tT
  const [filteredTTEvents, setFilteredTTEvents] = useState([]);
  const [TTCardsIsFetchingToFillRow, setTTCardsIsFetchingToFillRow] =
    useState(false);

  //Network
  const [filteredNetworkEvents, setFilteredNetworkEvents] = useState([]);
  const [networkCardsIsFetchingToFillRow, setNetworkCardsIsFetchingToFillRow] =
    useState(false);

  //Component variables
  let filteredEvents = [];
  let seenBTIds = new Set();
  let seenTTIds = new Set();
  let seenNetworkIds = new Set();
  let events;
  let content;

  //Initialize functions
  useEffect(() => {
    setNavbar('events');
    /*eslint-disable-next-line*/
  }, []); //sets navbar

  useEffect(() => {
    if (searchBTs?.length === 0) {
      handleSettingsSetSelectAllBTBtn(true);
      handleSettingsSetSelectedBigTags(accountBigTags);
    }

    if (searchTTs?.length === 0 && searchTGIds?.length === 0) {
      handleSettingsSetSelectAllTTBtn(true);
      handleSettingsSetSelectedTinyTags(accountTinyTags);

      let tGIds = [];

      accountTinyTagGroups.forEach((tG) => tGIds.push(tG.id));
      handleSettingsSetSelectedTinyTagGroupIds(tGIds);
    }

    if (
      searchAccountsFollowingIds?.length === 0 &&
      searchAccountsFollowingGroupIds?.length === 0
    ) {
      let accountsFollowingIdsArr = [];
      let accountsFollowingGroupIdsArr = [];

      accountFollowing.map((account) =>
        accountsFollowingIdsArr.push(account._id)
      );
      handleSettingsSetSelectedAccountsFollowingIds(accountsFollowingIdsArr);

      accountFollowingGroups.forEach((accountFollowingGroup) =>
        accountsFollowingGroupIdsArr.push(accountFollowingGroup.id)
      );

      handleSettingsSetSelectedAccountsFollowingGroupIds(
        accountsFollowingGroupIdsArr
      );

      handleSettingsSetSelectAllAccountsFollowingBtn(true);
    }

    /*eslint-disable-next-line*/
  }, []); //selects all tags if loaded empty

  //Component functions
  useEffect(() => {
    //initial load
    if (accountId) {
      if (bTisUninitialized && view === 'bigtags' && searchBTs) {
        if (
          accountId &&
          searchBTs?.length > 0 &&
          searchCategory &&
          searchDirection &&
          searchFormat &&
          cards
        ) {
          handleSettingsSetBTQueryPage(0);
          handleSettingsSetBTQueryHasMore(true);

          getBigTagEvents({
            accountId,
            searchBTs,
            searchCategory,
            searchDirection,
            searchFormat,
            queryCardsPerPage: cards,
            bTQueryPage: 0,
          });
        }
      } else if (
        tTisUninitialized &&
        view === 'tinytags' &&
        searchTTs &&
        searchTGIds
      ) {
        if (
          accountId &&
          (searchTTs?.length > 0 || searchTGIds?.length > 0) &&
          searchCategory &&
          searchDirection &&
          searchFormat &&
          cards
        ) {
          handleSettingsSetTTQueryPage(0);
          handleSettingsSetTTQueryHasMore(true);

          getTinyTagEvents({
            accountId,
            searchTTs,
            searchTGIds,
            searchCategory,
            searchDirection,
            searchFormat,
            queryCardsPerPage: cards,
            tTQueryPage: 0,
          });
        }
      } else if (
        networkisUninitialized &&
        view === 'network' &&
        searchAccountsFollowingIds &&
        searchAccountsFollowingGroupIds
      ) {
        if (
          accountId &&
          (searchAccountsFollowingIds?.length > 0 ||
            searchAccountsFollowingGroupIds?.length > 0) &&
          searchCategory &&
          searchDirection &&
          searchFormat &&
          cards
        ) {
          handleSettingsSetNetworkQueryPage(0);
          handleSettingsSetNetworkQueryHasMore(true);
          getNetworkEvents({
            accountId,
            searchAccountsFollowingIds,
            searchAccountsFollowingGroupIds,
            searchCategory,
            searchDirection,
            searchFormat,
            queryCardsPerPage: cards,
            networkQueryPage: 0,
          });
        }
      } else if (view === 'myevents') {
        if (accountId) {
          getMyEventsSaved(accountId);
          getMyEventsRegistered(accountId);
          if (myEventsSelection === 'archived') {
            getMyEventsArchived(accountId);
          }
        } //myevents registered events are retrieved in full not pages; archived only retrieved if selected
      }
    }
    /*eslint-disable-next-line*/
  }, [
    view,
    cards,
    accountId,
    searchBTs,
    searchTTs,
    searchTGIds,
    searchAccountsFollowingIds,
    searchAccountsFollowingGroupIds,
    searchDirection,
    searchFormat,
    myEventsSelection,
    bTisUninitialized,
    tTisUninitialized,
    networkisUninitialized,
  ]);

  //BIG TAG FUNCTIONS
  useEffect(() => {
    if (bTData?.data) {
      bTData.data.forEach((item) => {
        if (!seenBTIds.has(item._id)) {
          filteredEvents.push(item);
          seenBTIds.add(item._id);
        } else {
          // console.log(item._id, 'DUPLICATE');
        } // use to identify duplicates
      });
      setFilteredBTEvents(filteredEvents);
      filteredEvents = [];
    }
    return () => {
      seenBTIds.clear();
    };
  }, [bTData]); //removal of duplicate events

  useEffect(() => {
    if (view === 'bigtags') {
      if (!bTisFetching && !bTisLoading) {
        getNewBTEventsList();
      }
    } else if (view === 'tinytags') {
      if (!tTisFetching && !tTisLoading) {
        getNewTTEventsList();
      }
    } else if (view === 'network') {
      if (!networkisFetching && !networkisLoading) {
        getNewNetworkEventsList();
      }
    }
    /*eslint-disable-next-line*/
  }, [
    searchCategory,
    searchDirection,
    searchFormat,
    searchBTs,
    searchTTs,
    searchTGIds,
    searchAccountsFollowingIds,
    searchAccountsFollowingGroupIds,
  ]);

  useEffect(() => {
    //check to see if full row; if not need to continue loading state
    if (filteredBTEvents) {
      if (
        filteredBTEvents.length % cards !== 0 &&
        bTQueryHasMore &&
        !BTCardsIsFetchingToFillRow
      ) {
        getNextBTEventsPage();
      } else {
        let timeout1 = null;

        timeout1 = setTimeout(() => {
          setBTCardsIsFetchingToFillRow(false);
        }, 500); // need time out as precaution against rapid queries

        return () => {
          if (timeout1 !== null) {
            clearTimeout(timeout1);
          }
        };
      }
    }
  }, [filteredBTEvents]);

  function getNextBTEventsPage() {
    if (!bTisFetching && !bTisLoading && accountId && searchBTs?.length > 0) {
      setBTCardsIsFetchingToFillRow(true);
      const newPage = bTQueryPage + 1;
      handleSettingsSetBTQueryPage(newPage);
      getBigTagEvents({
        accountId,
        searchBTs,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage: cards,
        bTQueryPage: newPage,
      });
    }
  }

  function getNewBTEventsList() {
    if (!bTisFetching && !bTisLoading && accountId) {
      setBTCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetBTQueryPage(newPage);
      getBigTagEvents({
        accountId,
        searchBTs,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage: cards,
        bTQueryPage: newPage,
      });
    }
  }

  //TINY TAG FUNCTIONS
  useEffect(() => {
    if (tTData?.data) {
      tTData.data.forEach((item) => {
        if (!seenTTIds.has(item._id)) {
          filteredEvents.push(item);
          seenTTIds.add(item._id);
        } else {
          // console.log(item._id, 'DUPLICATE');
        } // use to identify duplicates
      });
      setFilteredTTEvents(filteredEvents);
      filteredEvents = [];
    }
    return () => {
      seenTTIds.clear();
    };
  }, [tTData]); //removal of duplicate events

  function getNextTTEventsPage() {
    if (
      !tTisFetching &&
      !tTisLoading &&
      accountId &&
      (searchTTs?.length > 0 || searchTGIds?.length > 0)
    ) {
      setTTCardsIsFetchingToFillRow(true);
      const newPage = tTQueryPage + 1;
      handleSettingsSetTTQueryPage(newPage);
      getTinyTagEvents({
        accountId,
        searchTTs,
        searchTGIds,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage: cards,
        tTQueryPage: newPage,
      });
    }
  }

  useEffect(() => {
    //check to see if full row; if not need to continue loading state
    if (filteredTTEvents) {
      if (
        filteredTTEvents.length % cards !== 0 &&
        tTQueryHasMore &&
        !TTCardsIsFetchingToFillRow
      ) {
        getNextTTEventsPage();
      } else {
        let timeout1 = null;

        timeout1 = setTimeout(() => {
          setTTCardsIsFetchingToFillRow(false);
        }, 500); // need time out as precaution against rapid queries

        return () => {
          if (timeout1 !== null) {
            clearTimeout(timeout1);
          }
        };
      }
    }
  }, [filteredTTEvents]);

  function getNewTTEventsList() {
    if (!tTisFetching && !tTisLoading && accountId) {
      setTTCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetTTQueryPage(newPage);
      getTinyTagEvents({
        accountId,
        searchTTs,
        searchTGIds,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage: cards,
        tTQueryPage: newPage,
      });
    }
  }

  //NETWORK EVENTS FUNCTIONS
  useEffect(() => {
    if (networkData?.data) {
      networkData.data.forEach((item) => {
        if (!seenNetworkIds.has(item._id)) {
          filteredEvents.push(item);
          seenNetworkIds.add(item._id);
        } else {
          // console.log(item._id, 'DUPLICATE');
        } // use to identify duplicates
      });
      setFilteredNetworkEvents(filteredEvents);
      filteredEvents = [];
    }
    return () => {
      seenNetworkIds.clear();
    };
  }, [networkData]); //removal of duplicate events

  function getNextNetworkEventsPage() {
    if (
      !networkisFetching &&
      !networkisLoading &&
      accountId &&
      (searchAccountsFollowingIds?.length > 0 ||
        searchAccountsFollowingGroupIds?.length > 0)
    ) {
      setNetworkCardsIsFetchingToFillRow(true);

      const newPage = networkQueryPage + 1;
      handleSettingsSetNetworkQueryPage(newPage);
      getNetworkEvents({
        accountId,
        searchAccountsFollowingIds,
        searchAccountsFollowingGroupIds,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage: cards,
        networkQueryPage: newPage,
      });
    }
  }

  useEffect(() => {
    //check to see if full row; if not need to continue loading state
    if (filteredNetworkEvents) {
      if (
        filteredNetworkEvents?.length % cards !== 0 &&
        networkQueryHasMore &&
        !networkCardsIsFetchingToFillRow
      ) {
        getNextNetworkEventsPage();
      } else {
        let timeout1 = null;

        timeout1 = setTimeout(() => {
          setNetworkCardsIsFetchingToFillRow(false);
        }, 500); // need time out as precaution against rapid queries

        return () => {
          if (timeout1 !== null) {
            clearTimeout(timeout1);
          }
        };
      }
    }
  }, [filteredNetworkEvents]);

  function getNewNetworkEventsList(
    followButtonSearchAccountsFollowingIds,
    followButtonSearchAccountsFollowingGroupIds
  ) {
    if (!networkisFetching && !networkisLoading && accountId) {
      setNetworkCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetNetworkQueryPage(newPage);
      getNetworkEvents({
        accountId,
        searchAccountsFollowingIds:
          followButtonSearchAccountsFollowingIds || searchAccountsFollowingIds,
        searchAccountsFollowingGroupIds:
          followButtonSearchAccountsFollowingGroupIds ||
          searchAccountsFollowingGroupIds,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage: cards,
        networkQueryPage: newPage,
      });
    }
  }

  //JSX conditional
  if (view === 'bigtags' && !settingsViewEventDetails) {
    events = (
      <EventsGrid
        cards={cards}
        events={filteredBTEvents}
        isFetching={BTCardsIsFetchingToFillRow}
        isLoading={BTCardsIsFetchingToFillRow}
        isSuccess={bTisSuccess}
        isUninitialized={bTisUninitialized}
        nextPageFunction={getNextBTEventsPage}
        hasMore={bTQueryHasMore}
        tagsCheck={searchBTs?.length}
      />
    );
  } else if (view === 'tinytags' && !settingsViewEventDetails) {
    events = (
      <EventsGrid
        cards={cards}
        events={filteredTTEvents}
        isFetching={TTCardsIsFetchingToFillRow}
        isLoading={TTCardsIsFetchingToFillRow}
        isSuccess={tTisSuccess}
        isUninitialized={tTisUninitialized}
        nextPageFunction={getNextTTEventsPage}
        hasMore={tTQueryHasMore}
        tagsCheck={searchTTs?.length || searchTGIds?.length}
      />
    );
  } else if (view === 'network' && !settingsViewEventDetails) {
    events = (
      <EventsGrid
        cards={cards}
        events={filteredNetworkEvents}
        isFetching={networkCardsIsFetchingToFillRow}
        isLoading={networkCardsIsFetchingToFillRow}
        isSuccess={
          networkisSuccess ||
          (searchAccountsFollowingIds?.length === 0 &&
            searchAccountsFollowingGroupIds?.length === 0)
        }
        isUninitialized={networkisUninitialized}
        nextPageFunction={getNextNetworkEventsPage}
        hasMore={networkQueryHasMore}
        tagsCheck={
          searchAccountsFollowingIds?.length ||
          searchAccountsFollowingGroupIds?.length
        }
      />
    );
  } else if (
    view === 'myevents' &&
    myEventsSelection === 'saved' &&
    !settingsViewEventDetails
  ) {
    events = (
      <EventsGrid
        cards={cards}
        events={myEventsSavedSorted}
        isFetching={myEventsSavedisFetching}
        isLoading={myEventsSavedisLoading}
        isSuccess={myEventsSavedisSuccess}
        isUninitialized={myEventsSavedisUninitialized}
      />
    );
  } else if (
    view === 'myevents' &&
    myEventsSelection === 'registered' &&
    !settingsViewEventDetails
  ) {
    events = (
      <EventsGrid
        cards={cards}
        events={myEventsRegisteredSorted}
        isFetching={myEventsRegisteredisFetching}
        isLoading={myEventsRegisteredisLoading}
        isSuccess={myEventsRegisteredisSuccess}
        isUninitialized={myEventsRegisteredisUninitialized}
      />
    );
  } else if (
    view === 'myevents' &&
    myEventsSelection === 'archived' &&
    !settingsViewEventDetails
  ) {
    events = (
      <EventsGrid
        cards={cards}
        events={myEventsArchivedSorted}
        isFetching={myEventsArchivedisFetching}
        isLoading={myEventsArchivedisLoading}
        isSuccess={myEventsArchivedisSuccess}
        isUninitialized={myEventsArchivedisUninitialized}
      />
    );
  }

  if (eventPageLoadComplete && !settingsViewEventDetails) {
    content = (
      <div className="events">
        <SettingsEvents />
        {accountGeoPositionCoordinates && <>{events}</>}
      </div>
    );
  } else if (eventPageLoadComplete && settingsViewEventDetails) {
    content = (
      <div className="events">
        <EventPage getNewNetworkEventsList={getNewNetworkEventsList} />
      </div>
    );
  } else {
    content = (
      <LoadingScreenContent
        numberOfLoadChecks={3}
        setLoadComplete={() => setEventPageLoadComplete(true)}
        loadCheck1={
          accountId &&
          accountBigTags &&
          accountTinyTags &&
          accountTinyTagGroups &&
          accountGeoPositionCoordinates
            ? true
            : false
        }
        loadCheck2={cards ? true : false}
        loadCheck3={
          searchDirection &&
          searchCategory &&
          searchFormat &&
          searchBTs &&
          searchTTs &&
          searchTGIds &&
          view &&
          myEventsSelection
            ? true
            : false
        }
        customWrapper={'min-full-height'}
      />
    );
  }

  return content;
};

export default Events;
