import { useState } from 'react';
//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';

//Components

//Utility
import ScrollTo from '../../utils/UI/ScrollTo';

const FocusSkip = ({ skipToId, skipToLabel, topPos, leftPos }) => {
  //Hooks
  const { FocusSkip } = useLanguageComponents();

  //UI state
  const [onFocus, setOnFocus] = useState(false);

  //Component functions
  function handleClick(e) {
    if (!onFocus) {
      return null;
    }
    if (e.key === 'Enter') {
      ScrollTo(skipToId, 'smooth', false, skipToId);
    }
  }

  return (
    <button
      className="focus-skip fs18 fwb access-ob access-o6 disable-select"
      tabIndex="0"
      onKeyDown={(e) => handleClick(e)}
      style={{ top: `${topPos}`, left: `${leftPos}` }}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
    >
      {skipToLabel ? skipToLabel : FocusSkip.skip}
    </button>
  );
};

export default FocusSkip;
