import { Suspense, useState } from 'react';

//Hooks
import useLanguageLayout from '../language/useLanguageLayout';
import useSettings from '../context/useSettings';

//Components
import Spinner from '../components/Spinner/Spinner';
import Navbar from './main/Navbar';
import Footer from './main/Footer';
import SlideMenu from './main/SlideMenu';
//Utils

const ErrorPage = () => {
  //Hooks
  const { ErrorPage } = useLanguageLayout();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightBtn, setTapHighlightBtn] = useState(false);

  //Component functions
  function reloadPage() {
    window.location.reload();
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Navbar />
      <div className="layout">
        <div className="panel-container bg-blur">
          <div
            className="panel mrg-auto flex-center flex-column"
            style={{ maxWidth: '450px' }}
            tabIndex="0"
          >
            <h1>{ErrorPage.errorHasOccurred}</h1>
            <div className="h24" />
            <h2>{ErrorPage.apologizeAndSupportLink}</h2>
            <div className="h36" />
            <button
              tabIndex="0"
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightBtn(true),
                    () => setTapHighlightBtn(false),
                  ],
                  [() => reloadPage()]
                )
              }
              className={`btn-action highlight-tb-theme access-ob access-o6 ${
                tapHighlightBtn ? 'highlight-tb-theme--tap' : ''
              }`}
            >
              {ErrorPage.refreshButton}
            </button>
          </div>
        </div>
      </div>
      <Footer />
      <SlideMenu />
    </Suspense>
  );
};

export default ErrorPage;
