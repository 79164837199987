import React, { useState, useEffect } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useTimezoneData from '../../language/useTimezoneData';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import useSettings from '../../context/useSettings';
import useGenericModal from '../../context/useGenericModal';
//Components
import SelectDropdownTimezonesModal from '../Modal/ModalComponents/SelectDropdownTimezonesModal';

//Utility
import { dropdown_arrow, search_dark } from '../../assets/icons/index';

const SelectDropdownTimezones = React.memo(
  ({ id, defaultValue, handleSelection, fullWidth }) => {
    //Hooks
    const {
      width,
      openedDropdownMenuEl,
      setOpenedDropdownMenuEl,
      contentLanguage,
      handleMobileTap,
    } = useSettings();
    const { TimeZoneCountriesOpts } = useTimezoneData();
    const { SelectDropdown } = useLanguageComponents();
    const { accountContentLanguage } = useAccountReduxHandlers();
    const { setGenericModal, genericModal } = useGenericModal();

    //Component state
    const [listOpened, setListOpened] = useState(false);
    const [timezoneObjList, setTimezoneObjList] = useState(
      TimeZoneCountriesOpts
    );
    const [selectionKey, setSelectionKey] = useState(defaultValue);
    const [selectionValue, setSelectionValue] = useState();
    const [selectionIndex, setSelectionIndex] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [searchFilteredData, setSearchFilteredData] = useState(null);

    //UI state
    const [showOpts, setShowOpts] = useState(false);
    const [prevOpts, setPrevOpts] = useState();
    const [tapHighlightMainBtn, setTapHighlightMainBtn] = useState(false);
    const [listItemsCount, setListItemsCount] = useState(0);
    const showoptsTz = document.getElementById(`showopts-tz-${id}`);

    //Component variables
    const mainButton = document.getElementById(`${id}-button`);
    let buttonIndex = 0;
    let dropdownBtn;

    //Initialize & UI functions

    useEffect(() => {
      if (showOpts) {
        setOpenedDropdownMenuEl(`${id}`);
      }
      /*eslint-disable-next-line*/
    }, [showOpts]); //two useEffects connected to useSettings, ensures that only one dropdown menu is opened at a time

    useEffect(() => {
      if (openedDropdownMenuEl !== `${id}`) {
        handleOverlayClick();
      }
      /*eslint-disable-next-line*/
    }, [openedDropdownMenuEl]);

    useEffect(() => {
      if (!genericModal && listOpened) {
        mainButton.focus();
        setListOpened(false);
      }
    }, [genericModal]); //sets focus back on See Full List btn after full list modal closes
    ////

    //Component functions
    useEffect(() => {
      setTimezoneObjList(TimeZoneCountriesOpts);
      setSearchText('');
      setSearchFilteredData(null);
      /*eslint-disable-next-line*/
    }, [accountContentLanguage, contentLanguage]); //handle user changes language

    useEffect(() => {
      if (showOpts !== prevOpts) {
        if (document.activeElement.id.includes(`${id}`)) {
          mainButton.focus();
          setPrevOpts(showOpts);
        }
      } //checks to see if the button dropdown was opened and will set focus on main button; also checks to see if activeElement has id so that this won't trigger from other components.

      if (showOpts && showoptsTz && width > 1280) {
        document.getElementById('searchByCountry-input').focus();
      }
      /*eslint-disable-next-line*/
    }, [showOpts]);

    useEffect(() => {
      if (selectionKey && timezoneObjList) {
        let foundValue = null;
        Object.values(timezoneObjList).forEach((obj) => {
          const foundZone = obj.zones.find((zoneObj) => {
            const [zoneKey] = Object.keys(zoneObj);
            return zoneKey === selectionKey;
          });
          if (foundZone) {
            foundValue = foundZone[selectionKey];
          }
        });

        setSelectionValue(foundValue);
        setSearchText('');
        setSearchFilteredData(null);
      }
    }, [selectionKey, timezoneObjList]); //sets the time zone value based on the key

    const handleTextSearchChange = (event) => {
      const searchText = event.target.value;
      setSearchText(searchText);

      const newSearchFilteredList = Object.values(timezoneObjList).filter(
        (obj) =>
          obj.countryName.toLowerCase().includes(searchText.toLowerCase())
      );

      setSearchFilteredData(newSearchFilteredList);
    }; // Filters the timezoneObjList based on the countryName

    function handleDropdownTzClick() {
      if (width > 480) {
        setShowOpts(!showOpts);
        setSelectionIndex(0);
        buttonIndex = 0;
      } else {
        setListOpened(true);
        setSelectionIndex(0);
        buttonIndex = 0;
        setGenericModal(
          <SelectDropdownTimezonesModal
            id={id}
            selectionKey={selectionKey}
            selectionValue={selectionValue}
            handleModalSelection={handleDropdown}
            SelectionOpts={timezoneObjList}
            modalLabel={SelectDropdown.timezone}
            SelectDropdown={SelectDropdown}
            timezoneObjList={timezoneObjList}
          />
        );
      }
    } // opens dropdown

    function handleDropdown(zoneKey) {
      handleSelection(zoneKey);
      setSelectionKey(zoneKey);
      handleOverlayClick();
      mainButton.focus();
    } //handles key selection

    function handleOverlayClick() {
      if (showoptsTz) {
        showoptsTz.scrollTo({
          top: 0,
          behavior: 'instant',
        }); //resets component scroll
      }
      //clears input and relevant state (also used for onClick selection too)
      setSelectionIndex(0);
      setSearchText('');
      setSearchFilteredData(null);
      buttonIndex = 0;
      setShowOpts(false);
    }

    function handleKeyDown(e, inputEl) {
      if (e.key === 'Enter' && inputEl) {
        e.preventDefault();
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault(); //keep separate to prevent scroll move
        if (
          selectionIndex < listItemsCount &&
          (selectionIndex !== listItemsCount || selectionIndex === 0)
        ) {
          setSelectionIndex(selectionIndex + 1);
          let btn = document.getElementById(`btn-${selectionIndex + 1}`);

          //need to check btn because focus to input will cause error
          if (btn) {
            btn.focus();
          }
        }
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault(); //keep separate to prevent scroll move
        if (selectionIndex > 1) {
          setSelectionIndex(selectionIndex - 1);
          let btn = document.getElementById(`btn-${selectionIndex - 1}`);

          //need to check btn because focus to input will cause error
          if (btn) {
            btn.focus();
          }
        }
      }
    } //selection interface to use arrow keys; if input e.preventDefault invoked, otherwise this function is used to make selections for tab/arrow enter

    useEffect(() => {
      if (searchFilteredData) {
        [searchFilteredData].forEach((x) => {
          let totalCount = 0;

          Object.entries(x).forEach((y) => {
            return (totalCount = totalCount + y[1].zones.length);
          });
          return setListItemsCount(totalCount);
        });
      } else if (!searchFilteredData) {
        [timezoneObjList].forEach((x) => {
          let totalCount = 0;

          Object.entries(x).forEach((y) => {
            return (totalCount = totalCount + y[1].zones.length);
          });
          return setListItemsCount(totalCount);
        });
      }
    }, [searchFilteredData]); //sets the total number of visible entries to use for the selection interface

    //JSX conditional
    if (timezoneObjList && !searchFilteredData) {
      dropdownBtn = (
        <ul>
          {Object.values(timezoneObjList).map((obj) => {
            return obj.zones.map((zoneObj) => {
              const [zoneKey, zoneData] = Object.entries(zoneObj)[0];
              const { abbr, identifier, country_code } = zoneData;
              buttonIndex = buttonIndex + 1;
              let btnIndex = buttonIndex;
              return (
                <li key={`key-${abbr}-${buttonIndex}`}>
                  <button
                    type="button"
                    tabIndex="0"
                    className={`fs18 fs16--phn fwn ${
                      buttonIndex === selectionIndex ? 'highlight' : ''
                    } `}
                    id={`btn-${buttonIndex}`}
                    onFocus={() => setSelectionIndex(btnIndex)}
                    onMouseEnter={() => setSelectionIndex(btnIndex)}
                    onKeyDown={(e) => handleKeyDown(e, false)}
                    onClick={() => handleDropdown(zoneKey)}
                  >
                    {width > 480 ? obj.countryName : country_code}/{identifier}
                    <br />
                    <p className="fs14 fwn">{abbr}</p>
                  </button>
                </li>
              );
            });
          })}
        </ul>
      );
    } else {
      dropdownBtn = (
        <ul>
          {searchFilteredData.map((obj, index) => {
            return obj.zones.map((zoneObj) => {
              const [zoneKey, zoneData] = Object.entries(zoneObj)[0];
              const { abbr, identifier, country_code } = zoneData;
              buttonIndex = buttonIndex + 1;
              let btnIndex = buttonIndex;
              return (
                <li key={`key-${abbr}-${buttonIndex}`}>
                  <button
                    type="button"
                    tabIndex="0"
                    onClick={() => handleDropdown(zoneKey)}
                    className={`fs18 fs16--phn fwn ${
                      buttonIndex === selectionIndex ? 'highlight' : ''
                    } `}
                    id={`btn-${buttonIndex}`}
                    onFocus={() => setSelectionIndex(btnIndex)}
                    onMouseEnter={() => setSelectionIndex(btnIndex)}
                    onKeyDown={(e) => handleKeyDown(e, false)}
                  >
                    {width > 480 ? obj.countryName : country_code}/{identifier}
                    <br />
                    <p className="fs14 fwn">{abbr}</p>
                  </button>
                </li>
              );
            });
          })}
        </ul>
      );
    }

    return (
      <>
        <div
          className={`dropdown-tz__wrapper ${
            fullWidth ? 'dropdown-tz__wrapper--fw' : ''
          }`}
          id={`${id}-dropdown`}
          onClick={(e) => e.stopPropagation()}
          style={{ zIndex: `${showOpts ? '8' : '1'}` }}
        >
          <div
            className={`dropdown-tz__container ${
              fullWidth ? 'dropdown-tz__container--fw' : ''
            }`}
          >
            <button
              type="button"
              className={`dropdown-tz__button highlight-bthin-theme br--small flex-column fs18 fwn  ${
                showOpts ? 'dropdown-tz__button-enabled' : ''
              }  ${fullWidth ? 'dropdown-tz__button--fw' : ''} ${
                tapHighlightMainBtn ? 'highlight-b-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightMainBtn(true),
                    () => setTapHighlightMainBtn(false),
                  ],
                  [() => handleDropdownTzClick()]
                )
              }
              id={`${id}-button`}
              aria-label={`${SelectDropdown.ariaLabelIntro} ${
                SelectDropdown.timezone
              }. ${SelectDropdown.selectionRequired}. ${
                selectionKey ? SelectDropdown.currentSelection : ''
              } ${selectionValue?.identifier}. ${selectionValue?.abbr}.`}
              aria-expanded={showOpts}
            >
              <div className="flex-row no-wrap">
                {SelectDropdown.timezone}
                <p className="fs18 fwsb color-theme" aria-hidden="true">
                  *
                </p>
              </div>
              <div className="h6" />
              <div className="flex-row flex-center disable-select">
                {!selectionKey ? (
                  <div className="flex-row flex-center">
                    {SelectDropdown.select}
                    <img
                      src={dropdown_arrow}
                      alt={SelectDropdown.arrow}
                      className={`arrow ${showOpts ? 'reverse-vertical' : ''}`}
                    />
                  </div>
                ) : (
                  <div className="flex-row">
                    {width > 480
                      ? selectionValue?.countryName
                      : selectionValue?.country_code}
                    /{selectionValue?.identifier} ({selectionValue?.abbr})
                  </div>
                )}
              </div>
            </button>
            <div className="pos-rel">
              <div
                className={`search-tz-wrapper ${!showOpts ? 'remove' : ''}`}
                style={{ zIndex: `${showOpts ? '8' : '1'}` }}
              >
                <div className={`search-tz`}>
                  <img
                    src={search_dark}
                    alt={SelectDropdown.searchIcon}
                    className="filter-gray svg"
                  />
                  <input
                    type="text"
                    className="fs18 fs16--phn fwn"
                    placeholder={SelectDropdown.searchByCountry}
                    id="searchByCountry-input"
                    onChange={(e) => handleTextSearchChange(e)}
                    value={searchText}
                    aria-label={SelectDropdown.ariaTimezoneInput}
                    onKeyDown={(e) => handleKeyDown(e, true)}
                    onClick={() => setSelectionIndex(0)}
                    tabIndex="0"
                    onFocus={() => setSelectionIndex(0)}
                    enterKeyHint="done"
                  />
                </div>
                <div className="showopts-tz-wrapper ">
                  <div
                    className="no-select showopts-tz"
                    tabIndex="-1"
                    id={`showopts-tz-${id}`}
                  >
                    {dropdownBtn}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showOpts && (
          <div
            className="dropdown-overlay"
            onClick={() => handleOverlayClick()}
          />
        )}
      </>
    );
  }
);

export default SelectDropdownTimezones;
