const useSanitize = () => {
  function stringCheck(string) {
    let check = string.replaceAll(' ', '');
    if (!check) {
      return true;
    }
  }

  return { stringCheck };
};

export default useSanitize;
