import { useState } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useOrganizeHandlers } from '../useOrganizeHandlers';
//Components
import RegistrationList from './RegistrationList';
//Utility
import { cancel, checkmark } from '../../../../assets/icons';

const OrganizeEventCardRegistrations = ({
  eventData,
  actionPortView,
  handleMobileViewCloseBtn,
  OrganizerEventCardLanguage,
  organizeType,
}) => {
  const { accountId } = useAccountReduxHandlers();
  const {
    handleAcceptPendingApprovalInPerson,
    handleRemoveRegistrationInPerson,
    handleAcceptPendingApprovalVirtual,
    handleRemoveRegistrationVirtual,
  } = useOrganizeHandlers();

  //Component State
  const [isLoading, setIsLoading] = useState(false);

  //API Functions
  async function handleAcceptPendingApprovalInPersonClick(
    eventId,
    pendingApprovalInPersonId
  ) {
    if (isLoading) {
      return null;
    }
    setIsLoading(true);
    await handleAcceptPendingApprovalInPerson(
      accountId,
      eventId,
      pendingApprovalInPersonId
    );
    setIsLoading(false);
  }

  async function handleRemoveRegistrationInPersonClick(
    eventId,
    registeredAttendeeInPersonId
  ) {
    if (isLoading) {
      return null;
    }
    setIsLoading(true);
    await handleRemoveRegistrationInPerson(
      accountId,
      eventId,
      registeredAttendeeInPersonId
    );
    setIsLoading(false);
  }

  async function handleAcceptPendingApprovalVirtualClick(
    eventId,
    pendingApprovalId
  ) {
    if (isLoading) {
      return null;
    }
    setIsLoading(true);
    await handleAcceptPendingApprovalVirtual(
      accountId,
      eventId,
      pendingApprovalId
    );
    setIsLoading(false);
  }

  async function handleRemoveRegistrationVirtualClick(
    eventId,
    pendingApprovalId
  ) {
    if (isLoading) {
      return null;
    }
    setIsLoading(true);
    await handleRemoveRegistrationVirtual(
      accountId,
      eventId,
      pendingApprovalId
    );
    setIsLoading(false);
  }

  return (
    <div className="organizer-eventcard-registrations__wrapper">
      <div className="organizer-eventcard-registrations__container">
        {actionPortView === 'inPersonRegistrations' &&
        eventData.registrationData.approveRegistrations ? (
          <>
            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={
                OrganizerEventCardLanguage.pendingInPersonRegistrations
              }
              listData={eventData?.registrationData?.pendingApprovalInPerson}
              image={checkmark}
              buttonClassName={'approve-btn'}
              imageClassName={'approve-img'}
              buttonClickClassName={'filter-img-green'}
              actionBtnTitle={OrganizerEventCardLanguage.approveRegisteree}
              actionHandler={handleAcceptPendingApprovalInPersonClick}
              eventId={eventData._id}
              id={'inpersonRegistrations-pending'}
              preventAction={
                (eventData.registrationData?.registeredAttendeesInPerson
                  ?.length >=
                  eventData.registrationData?.maxRegistrationsInPersonAmount &&
                  eventData.registrationData.maxRegistrationsInPersonEnabled) ||
                organizeType === 'archived'
              }
              preventActionText={
                organizeType !== 'archived'
                  ? OrganizerEventCardLanguage.fullCapacity
                  : ''
              }
              transferListData={
                eventData.registrationData.registeredAttendeesVirtual
              }
              transferInPersonToVirtual={false}
              eventStatusBar={false}
              inPersonEvent={true}
            />
            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={
                OrganizerEventCardLanguage.approvedInPersonRegistrations
              }
              listData={
                eventData?.registrationData?.registeredAttendeesInPerson
              }
              image={cancel}
              buttonClassName={'remove-btn'}
              imageClassName={'remove-img'}
              buttonClickClassName={'filter-img-red'}
              actionBtnTitle={OrganizerEventCardLanguage.removeRegisteree}
              actionHandler={handleRemoveRegistrationInPersonClick}
              eventId={eventData._id}
              id={'inpersonRegistrations-approved'}
              eventStatusBar={true}
              mobileCloseBtn={true}
              handleMobileViewCloseBtn={handleMobileViewCloseBtn}
              inPersonEvent={true}
              preventAction={organizeType === 'archived'}
              maxRegistrationsAmount={
                eventData.registrationData.maxRegistrationsInPersonEnabled
                  ? eventData.registrationData.maxRegistrationsInPersonAmount
                  : null
              }
            />
          </>
        ) : actionPortView === 'inPersonRegistrations' &&
          eventData.registrationData.maxRegistrationsInPersonEnabled ? (
          <>
            {/* Wait List */}
            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={OrganizerEventCardLanguage.waitingListInPerson}
              listData={eventData?.registrationData?.pendingApprovalInPerson}
              image={checkmark}
              eventId={eventData._id}
              id={'inpersonRegistrations-waiting'}
              preventAction={true}
              eventStatusBar={false}
              inPersonEvent={true}
              transferListData={
                eventData.registrationData.registeredAttendeesVirtual
              }
            />

            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={OrganizerEventCardLanguage.inpersonRegistrations}
              listData={
                eventData?.registrationData?.registeredAttendeesInPerson
              }
              image={cancel}
              buttonClassName={'remove-btn'}
              imageClassName={'remove-img'}
              eventId={eventData._id}
              id={'inpersonRegistrations-approved'}
              eventStatusBar={true}
              mobileCloseBtn={true}
              handleMobileViewCloseBtn={handleMobileViewCloseBtn}
              inPersonEvent={true}
              preventAction={organizeType === 'archived'}
              maxRegistrationsAmount={
                eventData.registrationData.maxRegistrationsInPersonEnabled
                  ? eventData.registrationData.maxRegistrationsInPersonAmount
                  : null
              }
            />
          </>
        ) : actionPortView === 'inPersonRegistrations' ? (
          <RegistrationList
            registrationData={eventData.registrationData}
            listTitle={OrganizerEventCardLanguage.inpersonRegistrations}
            listData={eventData?.registrationData?.registeredAttendeesInPerson}
            image={cancel}
            buttonClassName={'remove-btn'}
            imageClassName={'remove-img'}
            eventId={eventData._id}
            id={'inpersonRegistrations-approved'}
            eventStatusBar={true}
            mobileCloseBtn={true}
            handleMobileViewCloseBtn={handleMobileViewCloseBtn}
            inPersonEvent={true}
            preventAction={organizeType === 'archived'}
            maxRegistrationsAmount={
              eventData.registrationData.maxRegistrationsInPersonEnabled
                ? eventData.registrationData.maxRegistrationsInPersonAmount
                : null
            }
          />
        ) : null}

        {actionPortView === 'virtualRegistrations' &&
        eventData.registrationData.approveRegistrations ? (
          <>
            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={OrganizerEventCardLanguage.pendingVirtualRegistrations}
              listData={eventData.registrationData?.pendingApprovalVirtual}
              image={checkmark}
              buttonClassName={'approve-btn'}
              buttonClickClassName={'filter-img-green'}
              actionHandler={handleAcceptPendingApprovalVirtualClick}
              eventId={eventData._id}
              imageClassName={'approve-img'}
              actionBtnTitle={OrganizerEventCardLanguage.approveRegisteree}
              id={'virtualRegistrations-pending'}
              preventAction={
                (eventData.registrationData?.registeredAttendeesVirtual
                  ?.length >=
                  eventData.registrationData?.maxRegistrationsVirtualAmount &&
                  eventData.registrationData.maxRegistrationsVirtualEnabled) ||
                organizeType === 'archived'
              }
              preventActionText={OrganizerEventCardLanguage.fullCapacity}
              transferListData={
                eventData.registrationData.registeredAttendeesInPerson
              }
              transferInPersonToVirtual={true}
              eventStatusBar={false}
              virtualEvent={true}
            />
            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={
                OrganizerEventCardLanguage.approvedVirtualRegistrations
              }
              listData={eventData?.registrationData?.registeredAttendeesVirtual}
              image={cancel}
              buttonClassName={'remove-btn'}
              buttonClickClassName={'filter-img-red'}
              imageClassName={'remove-img'}
              actionBtnTitle={OrganizerEventCardLanguage.removeRegisteree}
              actionHandler={handleRemoveRegistrationVirtualClick}
              eventId={eventData._id}
              id={'virtualRegistrations-approved'}
              eventStatusBar={true}
              mobileCloseBtn={true}
              handleMobileViewCloseBtn={handleMobileViewCloseBtn}
              virtualEvent={true}
              preventAction={organizeType === 'archived'}
              maxRegistrationsAmount={
                eventData.registrationData.maxRegistrationsVirtualEnabled
                  ? eventData.registrationData.maxRegistrationsVirtualAmount
                  : null
              }
            />
          </>
        ) : actionPortView === 'virtualRegistrations' &&
          eventData.registrationData.maxRegistrationsVirtualEnabled ? (
          <>
            {/* Wait List */}
            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={OrganizerEventCardLanguage.waitingListVirtual}
              listData={eventData?.registrationData?.pendingApprovalVirtual}
              image={checkmark}
              eventId={eventData._id}
              id={'virtualRegistrations-waiting'}
              preventAction={true}
              eventStatusBar={false}
              virtualEvent={true}
              transferListData={
                eventData.registrationData.registeredAttendeesInPerson
              }
              transferInPersonToVirtual={true}
              maxRegistrationsAmount={
                eventData.registrationData.maxRegistrationsVirtualEnabled
                  ? eventData.registrationData.maxRegistrationsVirtualAmount
                  : null
              }
            />
            <RegistrationList
              registrationData={eventData.registrationData}
              listTitle={OrganizerEventCardLanguage.virtualRegistrations}
              listData={eventData?.registrationData?.registeredAttendeesVirtual}
              image={cancel}
              buttonClassName={'remove-btn'}
              imageClassName={'remove-img'}
              eventId={eventData._id}
              id={'virtualRegistrations-approved'}
              eventStatusBar={true}
              mobileCloseBtn={true}
              handleMobileViewCloseBtn={handleMobileViewCloseBtn}
              virtualEvent={true}
              preventAction={organizeType === 'archived'}
              maxRegistrationsAmount={
                eventData.registrationData.maxRegistrationsVirtualEnabled
                  ? eventData.registrationData.maxRegistrationsVirtualAmount
                  : null
              }
            />
          </>
        ) : //NO PENDING APPROVAL LIST
        actionPortView === 'virtualRegistrations' ? (
          <RegistrationList
            registrationData={eventData.registrationData}
            listTitle={OrganizerEventCardLanguage.approvedVirtualRegistrations}
            listData={eventData?.registrationData?.virtualRegistrations}
            image={cancel}
            buttonClassName={'remove-btn'}
            imageClassName={'remove-img'}
            eventId={eventData._id}
            id={'virtualRegistrations-approved'}
            eventStatusBar={true}
            mobileCloseBtn={true}
            virtualEvent={true}
            preventAction={organizeType === 'archived'}
            maxRegistrationsAmount={
              eventData.registrationData.maxRegistrationsVirtualEnabled
                ? eventData.registrationData.maxRegistrationsVirtualAmount
                : null
            }
          />
        ) : null}
      </div>
    </div>
  );
};

export default OrganizeEventCardRegistrations;
