import { useEffect, useState } from 'react';

//hooks
import useLanguageData from '../../../../../../language/useLanguageData';

//components
//utils
import LoadBucketImage from '../../../../../../components/LoadBucketImage/LoadBucketImage';
import GetFlag from '../../../../../../utils/images/GetFlag';

const InfoCardContainerAccountPersonal = ({
  accountPersonalData,
  accountType,
}) => {
  const [links, setLinks] = useState(false);
  const { CountryCodeOpts } = useLanguageData();
  const [countryCodeObj, setCountryCodeObj] = useState();

  useEffect(() => {
    const accountLinks = accountPersonalData?.personal?.links;
    if (
      accountLinks?.linkFacebook?.length > 0 ||
      accountLinks?.linkInstagram?.length > 0 ||
      accountLinks?.linkLinkedIn?.length > 0 ||
      accountLinks?.linkPersonalWebsite?.length > 0 ||
      accountLinks?.linkX?.length > 0
    ) {
      setLinks(true);
    }
  }, [accountPersonalData?.personal?.links]);

  useEffect(() => {
    if (accountPersonalData?.personal?.countryCode in CountryCodeOpts) {
      const value = CountryCodeOpts[accountPersonalData?.personal?.countryCode];
      setCountryCodeObj(value);
    }
  }, [accountPersonalData?.personal?.countryCode]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">{accountType}</p>

      <div className="flex-row mrg-tb12">
        <LoadBucketImage
          imagePath={accountPersonalData?.personal?.profileImage}
          imgClassName="support-profile-image"
          backupPath={'account_img_default.png'}
          partialPath={'myndfull_account_images'}
        />

        <div className="flex-column mrg-l12">
          <p className="mrg-t6">
            Name:{' '}
            {accountPersonalData?.personal?.prefix
              ? `${accountPersonalData?.personal?.prefix} `
              : ''}
            {accountPersonalData?.personal?.firstName}{' '}
            {accountPersonalData?.personal?.lastName}
            {accountPersonalData?.personal?.pronouns
              ? ` (${accountPersonalData?.personal?.pronouns})`
              : ''}
          </p>
          <p className="mrg-t6">
            Email: {accountPersonalData?.personal?.email}
          </p>
          <p className="mrg-t6 flex-row ">
            Phone Number:
            <span className="mrg-lr6 align-center">
              {GetFlag(accountPersonalData?.personal?.countryCode)}
            </span>
            {accountPersonalData?.personal?.phoneNumber
              ? `${countryCodeObj?.code} ${accountPersonalData?.personal?.phoneNumber}`
              : 'N/A'}
          </p>

          {accountPersonalData?.meta?.accountReference && (
            <p className="mrg-t6">
              Account Ref: [{accountPersonalData?.meta.accountReference}]
            </p>
          )}
        </div>
      </div>

      {links && accountPersonalData?.personal?.links ? (
        <ul className="mrg-t6">
          Links:
          {accountPersonalData.personal.links.linkFacebook && (
            <li className="mrg-l24">
              Facebook: {accountPersonalData.personal.links.linkFacebook}
            </li>
          )}
          {accountPersonalData.personal.links.linkInstagram && (
            <li className="mrg-l24">
              Instagram: {accountPersonalData.personal.links.linkInstagram}
            </li>
          )}
          {accountPersonalData.personal.links.linkLinkedIn && (
            <li className="mrg-l24">
              LinkedIn: {accountPersonalData.personal.links.linkLinkedIn}
            </li>
          )}
          {accountPersonalData.personal.links.linkPersonalWebsite && (
            <li className="mrg-l24">
              Personal Website:{' '}
              {accountPersonalData.personal.links.linkPersonalWebsite}
            </li>
          )}
          {accountPersonalData.personal.links.linkX && (
            <li className="mrg-l24">
              X: {accountPersonalData.personal.links.linkX}
            </li>
          )}
        </ul>
      ) : accountPersonalData.personal.links ? (
        <p className="mrg-t6">Links: N/A</p>
      ) : null}

      {accountPersonalData?.personal?.badges && (
        <div className="mrg-t6">
          Badges:{' '}
          {accountPersonalData?.personal?.badges?.length > 0
            ? accountPersonalData?.personal?.badges
            : 'N/A'}
        </div>
      )}
    </div>
  );
};

export default InfoCardContainerAccountPersonal;
