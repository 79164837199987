import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    _id: '',
    appSettings: {
      contentLanguage: '',
      soundEffects: true,
      timeZone: '',
    },
    accountEvents: {
      archivedEvents: [],
      draftEvents: [],
      ignoredEvents: [],
      organizedArchivedEvents: [],
      organizedEvents: [],
      pendingApprovalInPerson: [],
      pendingApprovalVirtual: [],
      registrationsInPerson: [],
      registrationsVirtual: [],
      savedEvents: [],
    },
    affiliations: [],
    billing: {
      customerId: '',
      paymentMethodIds: [],
      roomCodeSubscriptions: [],
      myInvoices: [],
      failedInvoices: [],
    },
    emailPreferences: {
      myndFullNewsletter: false,
      promotions: false,
    },
    meta: {
      accountReference: '',
    },
    network: {
      following: [],
      followingGroups: [],
      followedBy: [],
    },
    personal: {
      badges: [],
      countryCode: '',
      email: '',
      firstName: '',
      lastName: '',
      links: {
        linkFacebook: '',
        linkInstagram: '',
        linkLinkedIn: '',
        linkPersonalWebsite: '',
        linkX: '',
      },
      phoneNumber: '',
      prefix: '',
      profileImage: '',
      pronouns: '',
    },
    speaker: {
      bio: '',
      tags: {
        bigTagsSpeaker: [],
        tinyTagsSpeaker: [],
      },
    },
    searches: {
      eventSearchOnlineCountries: [],
      distanceFilter: null,
      distanceMeasurement: '',
      geoPosition: {},
      searchLanguages: [],
    },
    tags: {
      bigTags: [],
      tinyTags: [],
      tinyTagGroups: [],
    },
    translationRooms: {
      roomCodes: [],
    },
  },
  reducers: {
    //General
    setAccount: (state, action) => {
      const {
        _id,
        accountEvents,
        affiliations,
        appSettings,
        billing,
        emailPreferences,
        meta,
        network,
        personal,
        searches,
        speaker,
        tags,
        translationRooms,
      } = action.payload;
      state._id = _id;
      state.appSettings = {
        contentLanguage: appSettings.contentLanguage,
        soundEffects: appSettings.soundEffects,
        timeZone: appSettings.timeZone,
      };
      state.accountEvents = {
        archivedEvents: accountEvents.archivedEvents,
        draftEvents: accountEvents.draftEvents,
        ignoredEvents: accountEvents.ignoredEvents,
        organizedEvents: accountEvents.organizedEvents,
        organizedArchivedEvents: accountEvents.organizedArchivedEvents,
        pendingApprovalInPerson: accountEvents.pendingApprovalInPerson,
        pendingApprovalVirtual: accountEvents.pendingApprovalVirtual,
        savedEvents: accountEvents.savedEvents,
        registrationsInPerson: accountEvents.registrationsInPerson,
        registrationsVirtual: accountEvents.registrationsVirtual,
      };
      state.affiliations = affiliations;
      state.billing = {
        customerId: billing.customerId,
        paymentMethodIds: billing.paymentMethodIds,
        roomCodeSubscriptions: billing.roomCodeSubscriptions,
        failedInvoices: billing.failedInvoices,
      };
      state.emailPreferences = {
        myndFullNewsletter: emailPreferences.myndFullNewsletter,
        promotions: emailPreferences.promotions,
      };
      state.meta = {
        accountReference: meta.accountReference,
      };
      state.network = {
        following: network.following,
        followingGroups: network.followingGroups,
        followedBy: network.followedBy,
      };
      state.personal = {
        badges: personal.badges,
        countryCode: personal.countryCode,
        email: personal.email,
        firstName: personal.firstName,
        lastName: personal.lastName,
        links: {
          linkFacebook: personal.links.linkFacebook,
          linkInstagram: personal.links.linkInstagram,
          linkLinkedIn: personal.links.linkLinkedIn,
          linkPersonalWebsite: personal.links.linkPersonalWebsite,
          linkX: personal.links.linkX,
        },
        phoneNumber: personal.phoneNumber,
        prefix: personal.prefix,
        profileImage: personal.profileImage,
        pronouns: personal.pronouns,
      };
      state.speaker = {
        bio: speaker.bio,
        tags: {
          bigTagsSpeaker: speaker.tags.bigTagsSpeaker,
          tinyTagsSpeaker: speaker.tags.tinyTagsSpeaker,
        },
      };
      state.searches = {
        eventSearchOnlineCountries: searches.eventSearchOnlineCountries,
        distanceFilter: searches.distanceFilter,
        distanceMeasurement: searches.distanceMeasurement,
        geoPosition: {
          type: 'Point',
          coordinates: [
            searches.geoPosition.coordinates[1],
            searches.geoPosition.coordinates[0],
          ],
        },
        searchLanguages: searches.searchLanguages,
      };
      state.tags = {
        bigTags: tags.bigTags,
        tinyTags: tags.tinyTags,
        tinyTagGroups: tags.tinyTagGroups,
      };
      state.translationRooms = {
        roomCodes: translationRooms.roomCodes,
      };
    },
    emptyAccountData: (state, action) => {
      state._id = null;
      state.appSettings = {
        contentLanguage: '',
        soundEffecs: true,
        timeZone: '',
      };
      state.accountEvents = {
        archivedEvents: [],
        draftEvents: [],
        ignoredEvents: [],
        organizedEvents: [],
        organizedArchivedEvents: [],
        pendingApprovalInPerson: [],
        pendingApprovalVirtual: [],
        savedEvents: [],
        registrationsInPerson: [],
        registrationsVirtual: [],
      };
      state.billing = {
        customerId: '',
        paymentMethodIds: [],
        roomCodeSubscriptions: [],
        myInvoices: [],
        failedInvoices: [],
      };
      state.affiliations = [];
      state.emailPreferences = {
        myndFullNewsletter: false,
        promotions: false,
      };
      state.meta = {
        accountReference: '',
      };
      state.network = {
        following: [],
        followingGroups: [],
        followedBy: [],
      };
      state.personal = {
        badges: [],
        countryCode: '',
        email: '',
        firstName: '',
        lastName: '',
        links: {
          linkFacebook: '',
          linkInstagram: '',
          linkLinkedIn: '',
          linkPersonalWebsite: '',
          linkX: '',
        },
        phoneNumber: '',
        prefix: '',
        profileImage: '',
        pronouns: '',
      };
      state.speaker = {
        bio: '',
        tags: {
          bigTagsSpeaker: [],
          tinyTagsSpeaker: [],
        },
      };
      state.searches = {
        eventSearchOnlineCountries: [],
        distanceFilter: null,
        distanceMeasurement: '',
        geoPosition: {},
        searchLanguages: [],
      };
      state.tags = {
        bigTags: [],
        tinyTags: [],
        tinyTagGroups: [],
      };
      state.translationRooms = {
        roomCodes: [],
      };
    },
    //App Settings
    setContentLanguage: (state, action) => {
      state.appSettings.contentLanguage = action.payload;
    },
    setSoundEffects: (state, action) => {
      state.appSettings.soundEffects = action.payload;
    },
    setTimeZone: (state, action) => {
      state.appSettings.timeZone = action.payload;
    },

    //AccountEvents
    setArchivedEvents: (state, action) => {
      state.accountEvents.archivedEvents = action.payload || [];
    },
    setIgnoredEvents: (state, action) => {
      state.accountEvents.ignoredEvents = action.payload || [];
    },
    setOrganizedEvents: (state, action) => {
      state.accountEvents.organizedEvents = action.payload || [];
    },
    setPendingApprovalInPerson: (state, action) => {
      state.accountEvents.pendingApprovalInPerson = action.payload || [];
    },
    setPendingApprovalVirtual: (state, action) => {
      state.accountEvents.pendingApprovalVirtual = action.payload || [];
    },
    setRegistrationsInPerson: (state, action) => {
      state.accountEvents.registrationsInPerson = action.payload || [];
    },
    setRegistrationsVirtual: (state, action) => {
      state.accountEvents.registrationsVirtual = action.payload || [];
    },
    setSavedEvents: (state, action) => {
      state.accountEvents.savedEvents = action.payload || [];
    },

    //Billing
    setCustomerId: (state, action) => {
      state.billing.customerId = action.payload || '';
    },
    setMyInvoices: (state, action) => {
      state.billing.myInvoices = action.payload || [];
    },
    setPaymentMethodIds: (state, action) => {
      state.billing.paymentMethodIds = action.payload || [];
    },
    setRoomCodeSubscriptions: (state, action) => {
      state.billing.roomCodeSubscriptions = action.payload || [];
    },
    setFailedInvoices: (state, action) => {
      state.billing.failedInvoices = action.payload || [];
    },

    //Affiliations

    //Email Preferences
    setEmailPreferencesMyndFullNewsletter: (state, action) => {
      state.emailPreferences.myndFullNewsletter = action.payload;
    },
    setEmailPreferencesPromotions: (state, action) => {
      state.emailPreferences.promotions = action.payload;
    },
    //Network
    setNetwork: (state, action) => {
      state.network = action.payload || [];
    },
    setFollowing: (state, action) => {
      state.network.following = action.payload || [];
    },
    setFollowingGroups: (state, action) => {
      state.network.followingGroups = action.payload || [];
    },
    setFollowedBy: (state, action) => {
      state.network.followedBy = action.payload || [];
    },

    //Personal
    setCountryCode: (state, action) => {
      state.personal.countryCode = action.payload;
    },
    setEmail: (state, action) => {
      state.personal.email = action.payload;
    },
    setFirstName: (state, action) => {
      state.personal.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.personal.lastName = action.payload;
    },
    setLinkFacebook: (state, action) => {
      state.personal.links.linkFacebook = action.payload;
    },
    setLinkInstagram: (state, action) => {
      state.personal.links.linkInstagram = action.payload;
    },
    setLinkLinkedIn: (state, action) => {
      state.personal.links.linkLinkedIn = action.payload;
    },
    setLinkPersonalWebsite: (state, action) => {
      state.personal.links.linkPersonalWebsite = action.payload;
    },
    setLinkX: (state, action) => {
      state.personal.links.linkX = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.personal.phoneNumber = action.payload;
    },
    setPrefix: (state, action) => {
      state.personal.prefix = action.payload;
    },
    setProfileImage: (state, action) => {
      state.personal.profileImage = action.payload;
    },
    setPronouns: (state, action) => {
      state.personal.pronouns = action.payload;
    },
    //Speaker

    //Searches
    setEventSearchOnlineCountries: (state, action) => {
      state.searches.eventSearchOnlineCountries = action.payload;
    },
    setDistanceFilter: (state, action) => {
      state.searches.distanceFilter = action.payload;
    },
    setDistanceMeasurement: (state, action) => {
      state.searches.distanceMeasurement = action.payload;
    },
    setSearchLanguages: (state, action) => {
      state.searches.searchLanguages = action.payload || [];
    },

    //Tags
    removeAllBigTags: (state, action) => {
      state.searches.bigTags = [];
    },
    removeBigTags: (state, action) => {
      state.searches.bigTags.splice(
        state.searches.bigTags.indexOf(action.payload),
        1
      );
    },
    setBigTags: (state, action) => {
      state.tags.bigTags = action.payload || [];
    },
    setTinyTags: (state, action) => {
      state.tags.tinyTags = action.payload || [];
    },
    setTinyTagGroups: (state, action) => {
      state.tags.tinyTagGroups = action.payload || [];
    },
    removeAllTinyTags: (state, action) => {
      state.searches.tinyTags = [];
    },
    removeTinyTags: (state, action) => {
      state.searches.tinyTags.splice(
        state.searches.tinyTags.indexOf(action.payload),
        1
      );
    },
    setTranslationRoomsRoomCodes: (state, action) => {
      state.translationRooms.roomCodes = action.payload || [];
    },
    setTranslationRoomsRoomCode: (state, action) => {
      const newRoomCode = action.payload;
      const index = state.translationRooms.roomCodes.findIndex(
        (roomCode) => roomCode.roomCode === newRoomCode.roomCode
      );
      if (index !== -1) {
        state.translationRooms.roomCodes[index] = newRoomCode;
      }
    },
  },
});

export const {
  emptyAccountData,
  setAccount,
  setArchivedEvents,
  setBigTags,
  setContentLanguage,
  setCountryCode,
  setDistanceFilter,
  setDistanceMeasurement,
  setEmail,
  setEventSearchOnlineCountries,
  setFollowedBy,
  setFollowing,
  setFollowingGroups,
  setFirstName,
  setIgnoredEvents,
  setLastName,
  setNetwork,
  setOrganizedEvents,
  setPendingApprovalInPerson,
  setPendingApprovalVirtual,
  setPhoneNumber,
  setPrefix,
  setProfileImage,
  setPronouns,
  setRegistrationsInPerson,
  setRegistrationsVirtual,
  setSavedEvents,
  setSearchLanguages,
  setSoundEffects,
  setLinkFacebook,
  setLinkInstagram,
  setLinkLinkedIn,
  setLinkPersonalWebsite,
  setLinkX,
  setTimeZone,
  setTinyTags,
  setTinyTagGroups,
  setCustomerId,
  setPaymentMethodIds,
  setRoomCodeSubscriptions,
  setTranslationRoomsRoomCodes,
  setEmailPreferencesMyndFullNewsletter,
  setEmailPreferencesPromotions,
  setTranslationRoomsRoomCode,
  setMyInvoices,
  setFailedInvoices,
} = accountSlice.actions;

export default accountSlice.reducer;

//General
export const selectId = (state) => state.account?._id;
export const selectAccount = (state) => state.account;
export const selectAccountAppSettings = (state) => state.account?.appSettings;
export const selectAccountBilling = (state) => state.account?.billing;
export const selectAccountMeta = (state) => state.account?.meta;
export const selectAccountPersonal = (state) => state.account?.personal;
export const selectAccountSpeaker = (state) => state.account?.speaker;
export const selectAccountSearches = (state) => state.account?.searches;
export const selectAccountTags = (state) => state.account?.tags;
export const selectAccountNetwork = (state) => state.account?.network;

//AppSettings
export const selectSoundEffects = (state) =>
  state.account?.appSettings?.soundEffects;
export const selectContentLanguage = (state) =>
  state.account?.appSettings?.contentLanguage;

//Account Events
export const selectArchivedEvents = (state) =>
  state.account?.accountEvents?.archivedEvents;
export const selectIgnoredEvents = (state) =>
  state.account?.accountEvents?.ignoredEvents;
export const selectPendingApprovalInPerson = (state) =>
  state.account?.accountEvents?.pendingApprovalInPerson;
export const selectPendingApprovalVirtual = (state) =>
  state.account?.accountEvents?.pendingApprovalVirtual;
export const selectRegistrationsInPerson = (state) =>
  state.account?.accountEvents?.registrationsInPerson;
export const selectRegistrationsVirtual = (state) =>
  state.account?.accountEvents?.registrationsVirtual;
export const selectSavedEvents = (state) =>
  state.account?.accountEvents?.savedEvents;
export const selectOrganizedEvents = (state) =>
  state.account?.accountEvents?.organizedEvents;

//Affiliations
export const selectAffiliations = (state) => state.account?.affiliations;

//Email Preferences
export const selectEmailPreferencesMyndFullNewsletter = (state) =>
  state.account?.emailPreferences?.myndFullNewsletter;
export const selectEmailPreferencesPromotions = (state) =>
  state.account?.emailPreferences?.promotions;

//Billing
export const selectCustomerId = (state) => state.account?.billing?.customerId;
export const selectMyInvoices = (state) => state.account?.billing?.myInvoices;
export const selectPaymentMethodIds = (state) =>
  state.account?.billing?.paymentMethodIds;
export const selectRoomCodeSubscriptions = (state) =>
  state.account?.billing?.roomCodeSubscriptions;
export const selectFailedInvoices = (state) =>
  state.account?.billing?.failedInvoices;

//Meta
export const selectAccountReference = (state) =>
  state.account?.meta?.accountReference;

//Network
export const selectFollowing = (state) => state.account?.network?.following;
export const selectFollowingGroups = (state) =>
  state.account?.network?.followingGroups;
export const selectFollowedBy = (state) => state.account?.network?.followedBy;

//Personal
export const selectCountryCode = (state) =>
  state.account?.personal?.countryCode;
export const selectEmail = (state) => state.account?.personal?.email;
export const selectFirstName = (state) => state.account?.personal?.firstName;
export const selectLastName = (state) => state.account?.personal?.lastName;
export const selectProfileImage = (state) =>
  state.account?.personal?.profileImage;
export const selectPhoneNumber = (state) =>
  state.account?.personal?.phoneNumber;
export const selectPrefix = (state) => state.account?.personal?.prefix;
export const selectPronouns = (state) => state.account?.personal?.pronouns;
export const selectLinkFacebook = (state) =>
  state.account?.personal.links?.linkFacebook;
export const selectLinkInstagram = (state) =>
  state.account?.personal?.links?.linkInstagram;
export const selectLinkLinkedIn = (state) =>
  state.account?.personal.links?.linkLinkedIn;
export const selectLinkPersonalWebsite = (state) =>
  state.account?.personal.links?.linkPersonalWebsite;
export const selectLinkX = (state) => state.account?.personal?.links?.linkX;

//Speaker

//Searches
export const selectDistanceFilter = (state) =>
  state.account?.searches?.distanceFilter;
export const selectDistanceMeasurement = (state) =>
  state.account?.searches?.distanceMeasurement;
export const selectEventSearchOnlineCountries = (state) =>
  state.account?.searches?.eventSearchOnlineCountries;
export const selectGeoPosition = (state) =>
  state.account?.searches?.geoPosition;
export const selectGeoPositionCoordinates = (state) =>
  state.account?.searches?.geoPosition?.coordinates;
export const selectSearchLanguages = (state) =>
  state.account?.searches?.searchLanguages;

//Tags
export const selectBigTags = (state) => state.account?.tags?.bigTags;
export const selectTinyTags = (state) => state.account?.tags?.tinyTags;
export const selectTimeZone = (state) => state.account?.appSettings?.timeZone;
export const selectTinyTagGroups = (state) =>
  state.account?.tags?.tinyTagGroups;

//Translation Rooms
export const selectTranslationRoomsRoomCodes = (state) =>
  state.account?.translationRooms?.roomCodes;

export const selectMyEventsAll = (state) => {
  let myEvents = [];
  if (state.account?.accountEvents?.savedEvents) {
    myEvents = myEvents.concat(state.account.accountEvents.savedEvents);
  }
  if (state.account?.accountEvents?.registrationsInPerson) {
    myEvents = myEvents.concat(
      state.account.accountEvents.registrationsInPerson
    );
  }

  if (state.account?.accountEvents?.registrationsVirtual) {
    myEvents = myEvents.concat(
      state.account.accountEvents.registrationsVirtual
    );
  }

  if (state.account?.accountEvents?.pendingApprovalInPerson) {
    myEvents = myEvents.concat(
      state.account.accountEvents.pendingApprovalInPerson
    );
  }

  if (state.account?.accountEvents?.pendingApprovalVirtual) {
    myEvents = myEvents.concat(
      state.account.accountEvents.pendingApprovalVirtual
    );
  }

  if (state.account?.accountEvents?.archivedEvents) {
    myEvents = myEvents.concat(state.account.accountEvents.archivedEvents);
  }

  return myEvents;
};
