import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
//Components
import FollowTagButton from '../../../../../components/FollowTagButton/FollowTagButton';
import SearchNetworkInput from '../../../../../components/SearchNetworkInput/SearchNetworkInput';
import NetworkGroups from '../../../../../components/NetworkGroups/NetworkGroups';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//Utility
import { deepEqual } from '../../../../../utils/logic/deepEqual';

const Network = ({
  Account,
  saveAccountChange,
  updateAccountIsSuccess,
  loadingSaveBtnUI,
}) => {
  //Hooks
  const {
    accountFollowing: loadAccountFollowing,
    accountFollowingGroups: loadAccountFollowingGroups,
    accountNetwork,
    accountId,
  } = useAccountReduxHandlers();
  const { NetworkGroups: NetworkGroupsLanguage } = useLanguageComponents();

  //UI state

  //Component state
  const [accountFollowing, setAccountFollowing] = useState(
    loadAccountFollowing || []
  );
  const [accountFollowingGroups, setAccountFollowingGroups] = useState(
    loadAccountFollowingGroups || []
  );

  //group
  const [modifyGroup, setModifyGroup] = useState();
  const [networkSaveValid, setNetworkSaveValid] = useState(false);
  const [networkGroupsSaveValid, setNetworkGroupsSaveValid] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (loadAccountFollowing) {
      setAccountFollowing(loadAccountFollowing);
    }
    if (loadAccountFollowingGroups) {
      setAccountFollowingGroups(loadAccountFollowingGroups);
    }
  }, [loadAccountFollowing, loadAccountFollowingGroups]);

  //Component funcitons
  useEffect(() => {
    const sameContentsAccountFollowing =
      accountFollowing?.length === loadAccountFollowing?.length &&
      accountFollowing.every((value) => loadAccountFollowing.includes(value));

    if (!sameContentsAccountFollowing && !modifyGroup) {
      setNetworkSaveValid(true);
    } else {
      setNetworkSaveValid(false);
    }
  }, [accountFollowing, loadAccountFollowing, modifyGroup]); //checks valid save

  useEffect(() => {
    const sameContentsAccountFollowingGroups =
      accountFollowingGroups?.length === loadAccountFollowingGroups?.length &&
      accountFollowingGroups.every((value, index) =>
        deepEqual(value, loadAccountFollowingGroups[index])
      );

    if (!sameContentsAccountFollowingGroups && !modifyGroup) {
      setNetworkGroupsSaveValid(true);
    } else {
      setNetworkGroupsSaveValid(false);
    }
  }, [accountFollowingGroups, loadAccountFollowingGroups, modifyGroup]); //checks valid save

  function handleTagClick(accountObj) {
    handleRemoveAccount(accountObj);
    if (modifyGroup) {
      handleNetworkGroupAdd(accountObj);
    }
  }

  function handleAddAccount(accountObj) {
    let newArr = [...accountFollowing, accountObj];

    setAccountFollowing(newArr);
  }

  function handleRemoveAccount(accountObj) {
    let newArr = [...accountFollowing];
    const newArrWithRemoved = newArr.filter(
      (obj) => obj._id !== accountObj._id
    );
    setAccountFollowing(newArrWithRemoved);
  }

  function handleNetworkGroupAdd(accountObj) {
    let newArr = [...accountFollowingGroups];

    const adjustedAccountFollowingGroups = newArr.map((networkGroup) => {
      if (modifyGroup === networkGroup.id) {
        return {
          ...networkGroup,
          following: [...networkGroup.following, accountObj],
        };
      } else {
        return networkGroup;
      }
    });
    setAccountFollowingGroups(adjustedAccountFollowingGroups);
  }

  function handleTransferAccountFollowing(accountObj) {
    handleAddAccount(accountObj);
  }

  function handleSaveAccountFollowing() {
    let accountFollowingIds = [];
    let networkGroups = [];

    accountFollowing.map((accountObj) =>
      accountFollowingIds.push(accountObj._id)
    );

    accountFollowingGroups.map((networkGroup) => {
      let filteredGroupWithIds = {
        id: networkGroup.id,
        label: networkGroup.label,
        following: networkGroup.following.map((following) => following._id),
      };
      networkGroups.push(filteredGroupWithIds);
    });

    saveAccountChange(
      {
        network: {
          ...accountNetwork,
          followingGroups: networkGroups,
          following: accountFollowingIds,
        },
      },
      'network'
    );
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="networkLabel"
      >
        {Account.attendee.network}
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.networkDescription}
      </p>
      <div className="h24" />
      <SearchNetworkInput
        accountId={accountId}
        handleSelection={handleAddAccount}
        accountFollowing={accountFollowing}
        accountFollowingGroups={accountFollowingGroups}
      />

      <div className="dropdown-tags__wrapper">
        <ul className="tags__container">
          {accountFollowing.map((accountObj, index) => (
            <li key={`follow-tag-btn-${accountObj._id.slice(0, 6)}`}>
              <FollowTagButton
                accountObj={accountObj}
                modifyGroup={modifyGroup}
                handleTagClick={handleTagClick}
                CustomLanguage={NetworkGroupsLanguage}
                index={index}
                key={`random-${index}`}
              />
            </li>
          ))}
        </ul>

        <NetworkGroups
          accountFollowing={accountFollowing}
          setAccountFollowing={setAccountFollowing}
          accountFollowingGroups={accountFollowingGroups}
          setAccountFollowingGroups={setAccountFollowingGroups}
          modifyGroup={modifyGroup}
          setModifyGroup={setModifyGroup}
          handleTransferAccountFollowing={handleTransferAccountFollowing}
          updateAccountIsSuccess={updateAccountIsSuccess}
        />
      </div>

      <div className="h24" />
      <SaveButton
        saveValid={networkSaveValid || networkGroupsSaveValid}
        handler={() => handleSaveAccountFollowing()}
        saveItemLanguage={Account.attendee.network}
        isLoading={loadingSaveBtnUI === 'network'}
      />
      <div className="h48" />
    </div>
  );
};

export default Network;
