//Hooks

//Components
// import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import SpeakerRoleToggle from './SpeakerRoleToggle';

//Utility

const SpeakerContent = () => {
  //Hooks

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      <SpeakerRoleToggle />
      <div className="h48" />
    </div>
  );
};

export default SpeakerContent;
