import { useState, useEffect } from 'react';

//Hooks
import useGenericModal from '../../context/useGenericModal';
import useSettings from '../../context/useSettings';
import useLanguageComponents from '../../language/useLanguageComponents';
import useApiStatus from '../../context/useApiStatus';

//Components
import TinyTagGroups from './TinyTagGroups';
import TinyTagSuggestionsModal from '../Modal/ModalComponents/TinyTagsSuggestionModal';

//Utility
import { tinyTagStringValidator } from '../../utils/misc/tinyTagsValidator';
import { cancel } from '../../assets/icons';

const CustomTagsList = ({
  handleAdd,
  handleRemove,
  limit,
  icon,
  id,
  bigTags,
  tinyTags,
  setTinyTags,
  tinyTagGroups,
  setTinyTagGroups,
  setTinyTagGroupsSaveValid,
  tagGroupsOff,
  setTinyTagGroupsModifyActive,
  updateAccountIsSuccess,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const { Generic, CustomTagsList } = useLanguageComponents();
  const { setGenericModal } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //Component state
  const [modifyTagGroup, setModifyTagGroup] = useState();
  const [tagsCount, setTagsCount] = useState(0);

  //UI state
  const [limitShake, setLimitShake] = useState(false);
  const [inputField, setInputField] = useState();
  const [tapHighlightSeeSuggestions, setTapHighlightSeeSuggestions] =
    useState(false);
  const [tapHighlightTag, setTapHighlightTag] = useState();

  //Initialize functions
  useEffect(() => {
    setInputField(document.getElementById(`${id}-input`));
  }, []); //if draft is loaded in createEvent and user uses input as first action, will not find value.

  //UI functions
  function handleTagsClick(e, tinyTags) {
    if (!modifyTagGroup) {
      handleMobileTap(
        [() => setTapHighlightTag(e.target.id), () => setTapHighlightTag()],
        [() => handleRemoveItem(e, tinyTags)]
      );
    } else {
      handleMobileTap(
        [() => setTapHighlightTag(e.target.id), () => setTapHighlightTag()],
        [
          () => handleRemoveItem(e, tinyTags),
          () => handleTagGroupTagAdd(e.target.id),
        ]
      );
    }
  }

  //Component functions
  useEffect(() => {
    let tags = 0;

    if (!tagGroupsOff) {
      tinyTagGroups?.map((tG) => {
        tags += tG.tags.length;
      });
    }

    if (tags === 0 || tagsCount !== tags) {
      let tagsNum = tags + tinyTags.length;
      setTagsCount(tagsNum);
    }

    if (tagsCount === limit) {
      setLimitShake(true);
    }
    if (tagsCount !== limit) {
      setLimitShake(false);
    }
    /*eslint-disable-next-line*/
  }, [tinyTags, tinyTagGroups]);

  function addTag(tag) {
    const validString = tinyTagStringValidator(tag);

    if (!validString) {
      inputField.value = '';
      return handleWarning(
        {
          message: 'invalidTinyTag',
          statusCode: 500,
        },
        'TinyTags.js'
      );
    }

    //tag argument from tinyTagGroups
    let newTag = ('#' + inputField.value).trim().toLocaleLowerCase();

    if (newTag === '#') {
      return null;
    }

    let tagExistsCheck = false;

    if (tinyTags.includes(newTag)) {
      tagExistsCheck = true;
    }

    if (!tagGroupsOff) {
      if (tinyTagGroups.length > 0) {
        tinyTagGroups.map((tG) => {
          if (tG.tags?.length > 0) {
            if (tG.tags.includes(newTag)) {
              return (tagExistsCheck = true);
            }
          }
          return null;
        });
      }
    }

    if (tagExistsCheck) {
      inputField.value = '';
      handleWarning({
        message: 'tagAlreadySelected',
        origin: 'CustomTagsList.js/addTag',
        id: Date.now(),
      });
    } else {
      if (!limit || tinyTags.length < limit) {
        setTinyTags([...tinyTags, newTag]);
        inputField.value = '';
      } else {
        return handleWarning({
          message: 'tagLimitReached',
          origin: 'CustomTagsList.js/addTag',
          id: Date.now(),
        });
      }
    }
  }

  function transferTag(tag) {
    setTinyTags([...tinyTags, tag]);
    handleAdd(tag);
  }

  function handleKeyDown(e) {
    if (
      e.code === 'Enter' ||
      e.code === 'NumpadEnter' ||
      e.key === 'Enter' ||
      e.key === 'Return' ||
      e.code === 'Return'
    ) {
      e.preventDefault();
      addTag(e.target.value);
    } else {
      const key = e.key;
      const regex = /[a-zA-Z0-9]/;
      if (!regex.test(key)) {
        e.preventDefault();
      }
    }
  }

  function handleRemoveItem(e, tinyTags) {
    let newArr = [...tinyTags];
    let valueToRemove = [newArr[e.target.name]];
    newArr = newArr.filter((element) => !valueToRemove.includes(element));
    if (setTinyTags) {
      setTinyTags(newArr);
    }
    handleRemove(e.target.id);
  }

  //////////Tag Groups
  function handleTagGroupTagAdd(tag) {
    const newTagGroup = [...tinyTagGroups];
    const adjustedTagGroup = newTagGroup.map((tG) => {
      if (modifyTagGroup === tG.id) {
        return {
          ...tG,
          tags: [...tG.tags, tag],
        };
      } else {
        return tG;
      }
    });
    setTinyTagGroups(adjustedTagGroup);
  }

  //suggestions list
  function handleSeeSuggestionsList() {
    setGenericModal(
      <TinyTagSuggestionsModal
        tinyTags={tinyTags}
        tinyTagGroups={tinyTagGroups}
        handleAddTags={handleAddMultipleTags}
        limit={limit}
        bigTags={bigTags}
        CustomTagsList={CustomTagsList}
      />
    );
  }

  function handleAddMultipleTags(tags) {
    inputField.value = '';
    let prevTagsArr = [...tinyTags];
    let newTagsArr = [];

    tags?.map((tag) => {
      if (!prevTagsArr.includes(tag)) {
        return newTagsArr.push(tag);
      }
    });
    if (!setTinyTags) {
      newTagsArr.map((tag) => handleAdd(tag));
    } else {
      newTagsArr = [...newTagsArr, ...prevTagsArr];
      newTagsArr.sort().reverse();
      setTinyTags(newTagsArr);
    }
  }

  return (
    <div className="custom-tags-list__wrapper no-select">
      <textarea
        autoComplete="off"
        name={id}
        id={`${id}-input`}
        className="custom-tags-input fs18 fwn"
        maxLength="60"
        type="text"
        onKeyDown={(e) => handleKeyDown(e)}
        aria-label={CustomTagsList.inputInstructions + limit}
        enterKeyHint="enter"
      />
      <img className="input-label" src={icon} alt={id} />
      <p className="dropdown__hash" aria-hidden="true">
        #
      </p>

      {/* Input Tags (No group) */}
      <div className="dropdown-tags__wrapper">
        <div className="flex-row space-between full-width flex-center mrg-t12">
          {limit && (
            <p
              className={`access-ob access-o6 no-select ${
                limitShake ? 'fs16 fwn shake' : 'fs16 fwn'
              }`}
              tabIndex="0"
            >
              {Generic.tags} {tagsCount} / {limit}
            </p>
          )}
          <button
            className={`color-black highlight-t-content-additional access-ob access-o6 ${
              tapHighlightSeeSuggestions ? 'highlight-it-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightSeeSuggestions(true),
                  () => setTapHighlightSeeSuggestions(false),
                ],
                [() => handleSeeSuggestionsList()]
              )
            }
            id={'suggestionsListBtn'}
          >
            {CustomTagsList.seeSuggestionsList}
          </button>
        </div>
        {tinyTags?.length > 0 && <div className="h12" />}
        <ul className="tags__container mrg-t12">
          {tinyTags
            .map((tag, index) => (
              <li key={`${tag}-${index}`}>
                <button
                  className={`tag__list-item highlight-bthin-theme fwsb fs16 fs14-phn no-select access-ob access-o6 ${
                    modifyTagGroup
                      ? 'tag__list-item--add highlight-i-lgg'
                      : 'highlight-i-lgr'
                  } ${tapHighlightTag === tag ? 'highlight-b-theme--tap' : ''}`}
                  tabIndex="0"
                  aria-label={`${tag} ${
                    modifyTagGroup ? CustomTagsList.ariaAddTag : Generic.remove
                  } `}
                  name={index} //keep for removal
                  id={tag}
                  onClick={(e) => handleTagsClick(e, tinyTags)}
                >
                  <p
                    name={index} //keep for removal & p tag needed for css text wrap
                    id={tag}
                    onClick={(e) => handleTagsClick(e, tinyTags)}
                  >
                    {tag}
                  </p>
                  <img
                    alt={'X'}
                    className={`disable-select tag-remove-btn-img ${
                      modifyTagGroup ? 'add-tag rotate-cw45' : ''
                    }`}
                    src={cancel}
                  />
                </button>
              </li>
            ))
            .reverse()}
        </ul>
        {!tagGroupsOff && (
          <TinyTagGroups
            tinyTags={tinyTags}
            setTinyTags={setTinyTags}
            tinyTagGroups={tinyTagGroups}
            setTinyTagGroups={setTinyTagGroups}
            modifyTagGroup={modifyTagGroup}
            setModifyTagGroup={setModifyTagGroup}
            setTinyTagGroupsSaveValid={setTinyTagGroupsSaveValid}
            transferTag={transferTag}
            setTinyTagGroupsModifyActive={setTinyTagGroupsModifyActive}
            updateAccountIsSuccess={updateAccountIsSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default CustomTagsList;
