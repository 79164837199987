export const timesData = {
  500: '5:00 AM',
  530: '5:30 AM',
  600: '6:00 AM',
  630: '6:30 AM',
  700: '7:00 AM',
  730: '7:30 AM',
  800: '8:00 AM',
  830: '8:30 AM',
  900: '9:00 AM',
  930: '9:30 AM',
  1000: '10:00 AM',
  1030: '10:30 AM',
  1100: '11:00 AM',
  1130: '11:30 AM',
  1200: '12:00 PM',
  1230: '12:30 PM',
  1300: '1:00 PM',
  1330: '1:30 PM',
  1400: '2:00 PM',
  1430: '2:30 PM',
  1500: '3:00 PM',
  1530: '3:30 PM',
  1600: '4:00 PM',
  1630: '4:30 PM',
  1700: '5:00 PM',
  1730: '5:30 PM',
  1800: '6:00 PM',
  1830: '6:30 PM',
  1900: '7:00 PM',
  1930: '7:30 PM',
  2000: '8:00 PM',
  2030: '8:30 PM',
  2100: '9:00 PM',
  2130: '9:30 PM',
  2200: '10:00 PM',
  2230: '10:30 PM',
  2300: '11:00 PM',
  2330: '11:30 PM',
};
