import { createContext, useContext, useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';

//hooks
import { useAdminSupportReduxHandlers } from '../../features/admin/support/useAdminSupportHandlers';
import { useAdminAccountReduxHandlers } from '../../features/admin/account/useAdminAccountHandlers';
import useApiStatus from '../useApiStatus';

const TicketsSocketContext = createContext({});

export const TicketsSocketProvider = ({ children }) => {
  //Hooks
  const { handleSocketTicketsData } = useAdminSupportReduxHandlers();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleError } = useApiStatus();

  //state
  const [ticketsSocketStatus, setTicketsSocketStatus] = useState('closed');

  //variables
  const socketRef = useRef(null);

  const connectTicketsSocket = () => {
    if (!socketRef.current && ticketsSocketStatus !== 'connected') {
      let newSocket;

      if (process.env.REACT_APP_ENV === 'development') {
        newSocket = io('http://localhost:5000/ws/tickets', {
          query: { adminAccountId },
        });
      } else {
        newSocket = io('https://myndfull.com/ws/tickets', {
          withCredentials: true,
          query: { adminAccountId },
        });
      }

      newSocket.on('connect', () => {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(
            `Dev: You connected with the Tickets Socket, id: ${newSocket.id}`
          );
        }

        setTicketsSocketStatus('connected');
      });

      newSocket.on('ticketData', (ticketData) => {
        handleSocketTicketsData(ticketData);
      });

      newSocket.on('connect_error', (error) => {
        setTicketsSocketStatus('pending');
        if (process.env.NODE_ENV === 'development') {
          console.error('Dev: Error connecting to Tickets Socket:', error);
        }
      });

      newSocket.on('error', (error) => {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TicketsSocketProvider.js',
        });
      });

      newSocket.on('disconnect', (error) => {
        console.log('Tickets socket disconnected.');
        setTicketsSocketStatus('pending');
      });

      return (socketRef.current = newSocket);
    }
  };

  const getTicketsSocket = () => {
    return socketRef.current;
  };

  const closeTicketsSocket = () => {
    const socket = socketRef.current;

    if (socket) {
      socket.close();
      socketRef.current = null;
      setTicketsSocketStatus('closed');

      if (process.env.NODE_ENV === 'development') {
        console.error('Dev: Closed Tickets Socket:');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeTicketsSocket);
    return () => {
      window.removeEventListener('beforeunload', closeTicketsSocket);
    };
  }, []);

  return (
    <TicketsSocketContext.Provider
      value={{
        connectTicketsSocket,
        closeTicketsSocket,
        getTicketsSocket,
        ticketsSocketStatus,
      }}
    >
      {children}
    </TicketsSocketContext.Provider>
  );
};

export const useTicketsSocket = () => {
  return useContext(TicketsSocketContext);
};
