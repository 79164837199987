import { useEffect, useState } from 'react';

//Hooks
import useLanguageData from '../../../../language/useLanguageData';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useApiStatus from '../../../../context/useApiStatus';
import { useUpdateAccountMutation } from '../../account/accountApiSlice';

//Utility
import {
  bigtag_nobg,
  cancel,
  checkmark_green,
  tinytag_nobg,
} from '../../../../assets/icons';

const TagsContent = ({ tagsData, EventPage, handleMobileTap }) => {
  //Hooks
  const { handleError, handleWarning } = useApiStatus();
  const { BigTagsOpts } = useLanguageData();
  const {
    handleSettingsSetSelectedBigTags,
    handleSettingsSetSelectedTinyTags,
  } = useSettingsHandlers();
  const {
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountId,
    accountTags,
  } = useAccountReduxHandlers();
  const [
    updateAccount,
    { isLoading: updateAccountIsLoading, isFetching: updateAccountIsFetching },
  ] = useUpdateAccountMutation();

  //Component state
  const [initialBigTags, setInitialBigTags] = useState([]);
  const [initialTinyTags, setInitialTinyTags] = useState([]);

  //Initialize functions
  useEffect(() => {
    if (accountBigTags) {
      setInitialBigTags(accountBigTags);
    }

    if (accountTinyTags || accountTinyTagGroups) {
      let arr = [];

      arr = accountTinyTags;
      accountTinyTagGroups.forEach((tG) => {
        arr = arr.concat(tG.tags);
      });
      setInitialTinyTags(arr);
    }
  }, []);

  //API functions
  async function handleAddTagToAccount(tagType, tag) {
    if (!updateAccountIsLoading && !updateAccountIsFetching) {
      try {
        let res;
        let newData;
        let filteredTagsList;

        if (tagType === 'bigTag') {
          if (accountBigTags?.length >= 20) {
            return handleWarning({
              id: Date.now(),
              origin: 'TagsContent.js/handleAddTagToAccount',
              message: 'tagLimitReached',
            });
          }

          if (accountBigTags?.includes(tag)) {
            //bT exists in account
            filteredTagsList = accountBigTags.filter((bT) => bT !== tag);

            newData = {
              tags: {
                ...accountTags,
                bigTags: filteredTagsList,
              },
            };
          } else {
            //bT doesnt exist in account
            newData = {
              tags: {
                ...accountTags,
                bigTags: [...accountBigTags, tag],
              },
            };
          }

          res = await updateAccount({
            accountId,
            newData,
          });
        } else if (tagType === 'tinyTag') {
          if (
            accountTinyTags?.includes(tag) ||
            accountTinyTagGroups.some((tG) => tG.tags.includes(tag))
          ) {
            //tinyTag already exists in account
            filteredTagsList = accountTinyTags.filter((tT) => tT !== tag);
            newData = {
              tags: {
                ...accountTags,
                tinyTags: filteredTagsList,
              },
            };
          } else {
            //tiny tag doesnt exist in account
            newData = {
              tags: {
                ...accountTags,
                tinyTags: [...accountTinyTags, tag],
              },
            };
          }

          res = await updateAccount({
            accountId,
            newData,
          });
        }

        if (res?.data?.tags.bigTags) {
          handleSettingsSetSelectedBigTags(res.data.tags.bigTags);
        }

        if (res?.data?.tags.tinyTags) {
          handleSettingsSetSelectedTinyTags(res.data.tags.tinyTags);
        }
      } catch (error) {
        handleError({
          error,
          id: Date.now(),
          origin: 'TagsContent.js/saveAccountChange',
        });
      }
    }
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="tagsLabel"
      >
        {EventPage.tags}
      </label>
      <div className="tags-wrapper">
        <img src={bigtag_nobg} alt="" className="bT mrg-b12" />
        <ul className="tags-container">
          {tagsData.bigTags.map((bT) => (
            <li key={bT}>
              <button
                className={`tag fwsb fs16 fs14-phn no-select access-ob access-o6 
                ${
                  !accountBigTags.includes(bT)
                    ? 'add-tag highlight-i-lg highlight-b-green'
                    : 'border-thin br--standard'
                } ${initialBigTags.includes(bT) ? 'cursor-auto' : ''}  
                ${
                  !initialBigTags.includes(bT) && accountBigTags.includes(bT)
                    ? 'highlight-b-red'
                    : ''
                }
                `}
                title={
                  initialBigTags.includes(bT)
                    ? ''
                    : !accountBigTags.includes(bT)
                    ? EventPage.addTagToAccount
                    : EventPage.removeTagFromAccount
                }
                onClick={
                  !initialBigTags.includes(bT)
                    ? () => handleAddTagToAccount('bigTag', bT)
                    : null
                }
              >
                {BigTagsOpts[bT]}
                {!accountBigTags.includes(bT) && (
                  <img
                    alt="+"
                    className={`disable-select rotate-cw45`}
                    src={cancel}
                  />
                )}
                {accountBigTags.includes(bT) &&
                  !initialBigTags.includes(bT) && (
                    <img src={checkmark_green} alt="+" />
                  )}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="tags-wrapper">
        <img src={tinytag_nobg} alt="" className="tT mrg-b12" />
        <ul className="tags-container">
          {tagsData.tinyTags.map((tT) => (
            <li key={tT}>
              <button
                className={`tag fwsb fs16 fs14-phn no-select access-ob access-o6 ${
                  !accountTinyTags.includes(tT) &&
                  accountTinyTagGroups.every((tG) => !tG.tags.includes(tT))
                    ? 'add-tag highlight-i-lg highlight-b-green'
                    : 'border-thin br--standard'
                } ${initialTinyTags.includes(tT) ? 'cursor-auto' : ''}
                
                ${
                  !initialTinyTags.includes(tT) && accountTinyTags.includes(tT)
                    ? 'highlight-b-red'
                    : ''
                }
                `}
                title={
                  initialTinyTags.includes(tT)
                    ? ''
                    : !accountTinyTags.includes(tT) &&
                      accountTinyTagGroups.every((tG) => !tG.tags.includes(tT))
                    ? EventPage.addTagToAccount
                    : EventPage.removeTagFromAccount
                }
                onClick={
                  !initialTinyTags.includes(tT)
                    ? () => handleAddTagToAccount('tinyTag', tT)
                    : null
                }
              >
                {tT}
                {!accountTinyTags.includes(tT) &&
                  accountTinyTagGroups.every((tG) => !tG.tags.includes(tT)) && (
                    <img
                      alt="+"
                      className={`disable-select rotate-cw45`}
                      src={cancel}
                    />
                  )}
                {accountTinyTags.includes(tT) &&
                  !initialTinyTags.includes(tT) && (
                    <img src={checkmark_green} alt="+" />
                  )}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="h72" />
    </>
  );
};

export default TagsContent;
