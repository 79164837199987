import { useState } from 'react';

//hooks

import useLanguageData from '../../../../../../language/useLanguageData';

//components
//utils
import { dropdown_arrow } from '../../../../../../assets/icons';
import { MapContainerStatic } from '../../../../../../components/MapContainer/MapContainerStatic';
import { getDateOrTimeFromUTZstring } from '../../../../../../utils/dateTime/DateCalc';

const InfoCardContainerEventHosting = ({
  eventHostingData,
  eventDateTimeData,
}) => {
  //hooks
  const { HostingOptions, CountriesOpts, OnlinePlatformOptions, TimeZoneOpts } =
    useLanguageData();

  //state
  const [showMap, setShowMap] = useState(false);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Hosting</p>
      <div>Option: {HostingOptions[eventHostingData.hostingOption]}</div>

      {(eventHostingData?.hostingOption === 'inPerson' ||
        eventHostingData?.hostingOption === 'hybrid') && (
        <>
          <p className="fs18 fwsb mrg-t24">In Person: </p>
          <div className="mrg-t6">
            Room: {eventHostingData?.inPerson?.location?.room}
          </div>
          <div className="mrg-t6">
            Location Name: {eventHostingData?.inPerson?.location?.locationName}
          </div>
          <div className="mrg-t6">
            Street Address:{' '}
            {eventHostingData?.inPerson?.location?.streetAddress}
          </div>
          <div className="mrg-t6">
            City: {eventHostingData?.inPerson?.location?.city}
          </div>
          <div className="mrg-t6">
            Country:{' '}
            {CountriesOpts[eventHostingData?.inPerson?.location?.country]}
          </div>
          <div className="mrg-t6">
            Google Place Id: {eventHostingData?.inPerson?.location?.placeId}
          </div>

          <div className="mrg-t6">
            Coords:{' '}
            {eventHostingData?.inPerson?.geoPosition.coordinates[1].toFixed(6)},
            &nbsp;
            {eventHostingData?.inPerson?.geoPosition.coordinates[0].toFixed(6)}
          </div>

          <div className="flex-row">
            <button
              className={
                'flex-center pad-tb6 pad-lr12 mrg-t24 br--standard highlight-btreg-theme highlight-i-gt'
              }
              onClick={() => setShowMap(!showMap)}
            >
              <p className="fs16 fwsb mrg-r12">Show Map</p>
              <img
                src={dropdown_arrow}
                alt="arrow"
                className={
                  showMap ? 'dropdown-arrow' : 'dropdown-arrow reverse-vertical'
                }
                style={{ height: '10px' }}
              />
            </button>
          </div>
          {showMap && (
            <>
              <div className="h24" />
              <MapContainerStatic
                loadValue={eventHostingData.inPerson?.geoPosition?.coordinates}
              />
            </>
          )}
        </>
      )}

      {(eventHostingData?.hostingOption === 'online' ||
        eventHostingData?.hostingOption === 'hybrid') && (
        <>
          <p className="fs18 fwsb mrg-t24">Virtual: </p>
          <div className="mrg-t6">
            Platform:{' '}
            {OnlinePlatformOptions[eventHostingData?.virtual?.platform]}
          </div>
          <div className="mrg-t6">
            Meeting Id: {eventHostingData?.virtual?.meetingId}
          </div>
          <div className="mrg-t6">
            Passcode: {eventHostingData?.virtual?.passcode}
          </div>
          <div className="mrg-t6">
            Meeting Link: {eventHostingData?.virtual?.meetingLink}
          </div>
        </>
      )}

      <p className="fs18 fwsb mrg-t24">Date: </p>
      <div className="mrg-t6">
        Day:&nbsp;
        {getDateOrTimeFromUTZstring(
          eventDateTimeData?.dateTimeStartUTC,
          'date'
        )}
      </div>
      <div className="mrg-t6">
        Time Zone: {TimeZoneOpts[eventDateTimeData?.timeZone]}
      </div>
      <div className="mrg-t6">Time Start: {eventDateTimeData?.timeStart}</div>
      <div className="mrg-t6">Time End: {eventDateTimeData?.timeEnd}</div>
    </div>
  );
};

export default InfoCardContainerEventHosting;
