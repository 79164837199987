import { apiSlice } from '../../../app/api/apiSlice';
import { addReduxApiStatus } from '../../main/apiStatus/apiStatusSlice';
import {
  setAdminTechnicalLogs,
  setAdminTechnicalLogsArchive,
} from './adminTechnicalSlice';

export const adminTechnicalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTechnicalLogs: builder.query({
      query: ({ adminAccountId }) => {
        return {
          url: '/api/admin/technical/technicalLogs',
          params: {
            adminAccountId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setAdminTechnicalLogs(data?.technicalLogs));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('adminTechnicalApiSlice/getTechnicalLogs:', error);
          }
        }
      },
    }),
    removeTechnicalLog: builder.mutation({
      query: ({ adminAccountId, technicalLogId }) => ({
        url: `/api/admin/technical/technicalLogs/${technicalLogId}`,
        method: 'DELETE',
        body: {
          adminAccountId,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.error) {
            throw data.error;
          }
          const success = {
            id: Date.now(),
            status: 'success',
            origin: 'adminTechnicalApiSlice/removeTechnicalLog',
          };
          return dispatch(addReduxApiStatus(success));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminTechnicalApiSlice/removeTechnicalLog',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    createTechnicalLogArchive: builder.mutation({
      query: (body) => {
        return {
          url: '/api/admin/technical/technicalLogsArchive',
          method: 'POST',
          body,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          return 'success';
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminTechnicalApiSlice/createTechnicalArchiveLog',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    changeTechnicalLogArchive: builder.mutation({
      query: (body) => {
        return {
          url: '/api/admin/technical/technicalLogsArchive',
          method: 'PATCH',
          body,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const success = {
            id: Date.now(),
            status: 'success',
            origin: 'adminTechnicalApiSlice/removeTechnicalLog',
          };
          return dispatch(addReduxApiStatus(success));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminTechnicalApiSlice/changeTechnicalLogArchive',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeTechnicalLogArchive: builder.mutation({
      query: ({ adminAccountId, technicalLogArchiveId }) => ({
        url: `/api/admin/technical/technicalLogsArchive/${technicalLogArchiveId}`,
        method: 'DELETE',
        body: {
          adminAccountId,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.error) {
            throw data.error;
          }
          const success = {
            id: Date.now(),
            status: 'success',
            origin: 'adminTechnicalApiSlice/removeTechnicalLogArchive',
          };
          return dispatch(addReduxApiStatus(success));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminTechnicalApiSlice/removeTechnicalLogArchive',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    getTechnicalLogsArchive: builder.query({
      query: ({ adminAccountId }) => {
        return {
          url: '/api/admin/technical/technicalLogsArchive',
          params: {
            adminAccountId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setAdminTechnicalLogsArchive(data?.technicalLogsArchive));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'adminTechnicalApiSlice/getTechnicalLogsArchive:',
              error
            );
          }
        }
      },
    }),
  }),
});

export const {
  useGetTechnicalLogsQuery,
  useRemoveTechnicalLogMutation,
  useCreateTechnicalLogArchiveMutation,
  useGetTechnicalLogsArchiveQuery,
  useChangeTechnicalLogArchiveMutation,
  useRemoveTechnicalLogArchiveMutation,
} = adminTechnicalApiSlice;
