import { useState, useEffect, useRef } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useLanguageData from '../../../../../language/useLanguageData';
//Components
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import SelectDropdownMultiple from '../../../../../components/SelectDropdown/SelectDropdownMultiple';
//Utility

const SearchLanguages = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { accountSearchLanguages: loadSearchLanguages, accountSearches } =
    useAccountReduxHandlers();
  const { SearchLanguageOpts } = useLanguageData();

  //Component state
  const [searchLanguages, setSearchLanguages] = useState(loadSearchLanguages);
  const [searchLanguagesSaveValid, setSearchLangaugesSaveValid] =
    useState(false);

  //Component variables
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (effectRan.current === false) {
      setSearchLanguages(loadSearchLanguages);

      effectRan.current = true;
    }
  }, [loadSearchLanguages]);

  //Component functions
  useEffect(() => {
    const sameContents =
      searchLanguages.length === loadSearchLanguages.length &&
      searchLanguages.every(
        (value, index) => value === loadSearchLanguages[index]
      );
    if (
      !sameContents &&
      !searchLanguages.includes('select') &&
      searchLanguages?.length > 0
    ) {
      setSearchLangaugesSaveValid(true);
    } else {
      setSearchLangaugesSaveValid(false);
    }
  }, [searchLanguages, loadSearchLanguages]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="searchLanguagesLabel"
      >
        {Account.attendee.searchLanguages}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.searchLanguagesDescription}
      </p>

      <div className="h24" />
      <SelectDropdownMultiple
        list={SearchLanguageOpts}
        id={'searchLanguage'}
        handleSelectionChange={setSearchLanguages}
        selections={searchLanguages}
        addButtonLabel={Account.attendee.languageBtn}
        modalLabel={Account.attendee.searchLanguages}
        customWrapper={'full-width'}
      />

      <div className="h24" />
      <SaveButton
        saveValid={searchLanguagesSaveValid}
        handler={() =>
          saveAccountChange(
            {
              searches: {
                ...accountSearches,
                searchLanguages,
              },
            },
            'searchLanguages'
          )
        }
        saveItemLanguage={Account.attendee.searchLanguages}
        isLoading={loadingSaveBtnUI === 'searchLanguages'}
      />
      <div className="h36" />
    </div>
  );
};

export default SearchLanguages;
