import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';

//components
import JoinRoom from './JoinRoom';
import TranslationRoom from '../Room/TranslationRoom';

//utility

const TranslationJoinRoom = ({ setPage }) => {
  //hooks
  const { translationRoomSocketStatus, isConnecting } =
    useTranslationRoomSocket();

  //state
  const [joinRoom, setJoinRoom] = useState(false);

  useEffect(() => {
    if (translationRoomSocketStatus === 'connected') {
      setJoinRoom(true);
    }
  }, [translationRoomSocketStatus]);

  return (
    <div className="translations">
      {!joinRoom ? (
        <JoinRoom setPage={setPage} isConnecting={isConnecting} />
      ) : (
        <TranslationRoom setPage={setPage} isHost={false} />
      )}
    </div>
  );
};

export default TranslationJoinRoom;
