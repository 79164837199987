import { useState, useEffect, useRef } from 'react';

//Hooks
import { useRegistrationHandlers } from '../../useRegistrationHandlers';
import useApiStatus from '../../../../../context/useApiStatus';

//Components
import PersonalAccountInfo from './PersonalAccountInfo';
import PersonalSearchSettings from './PersonalSearchSettings';
import PersonalCompletionPage from './PersonalCompletionPage';
import TranslationRoomCodeRegistration from '../Hybrid/TranslationRoomCodeRegistration';
import PersonalBilling from './PersonalBilling';
import EmailPreferences from '../Hybrid/EmailPreferences';

//Utility
import { retryWrapper } from '../../../../../utils/logic/retryFns';

const PersonalRegistrationMain = () => {
  //Hooks
  const {
    handleCreateMyndFullAccountPersonal,
    handleRegistrationSetGenericIsLoadingCompleteRegistration,
    registrationComponentArr,
    registrationIsSuccess,
    registrationPage,
    registrationTranslationRoomCodesOptIn,
    handleAddPaymentMethodIdToUnverifiedAccount,
    handleSendWelcomeEmail,
    handleRemoveUnverifiedAccount,
  } = useRegistrationHandlers();
  const { handleError } = useApiStatus();

  //State Personal
  const [temporaryPMId, setTemporaryPMId] = useState();
  const [zoom, setZoom] = useState(1);
  const [croppedImg, setCroppedImg] = useState({
    id: '',
    croppedImage: '',
    imagePreview: '',
  });
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 264,
    height: 264,
  });

  //Stripe
  const [clientSecret, setClientSecret] = useState('');

  //UI
  const [showAccountInfo, setShowAccountInfo] = useState();
  const [showBilling, setShowBilling] = useState(false);
  const [showTranslationRoomCodes, setShowTranslationRoomCodes] =
    useState(false);
  const [showSearchSettings, setShowSearchSettings] = useState(false);
  const [showEmailPreferences, setShowEmailPreferences] = useState(false);

  const [componentIsMounted, setComponentIsMounted] = useState(false);
  const [componentIsMounting, setComponentIsMounting] = useState(false);
  const [componentIsUnmounting, setComponentIsUnmounting] = useState(false);

  //Initialize & UI functions
  const [lastLoadedPage, setLastLoadedPage] = useState();

  //variables
  const paymentCardRef = useRef();
  const formContainerRef = useRef();
  const previousHeightRef = useRef(0);

  //UI / Pagination
  useEffect(() => {
    if (
      (lastLoadedPage !== undefined && lastLoadedPage !== 2) ||
      (registrationPage > 3 && lastLoadedPage !== 2)
    ) {
      setComponentIsUnmounting(true);
      setLastLoadedPage(registrationPage);
    }
  }, [registrationPage]); //1-reg page changes, unmount component

  useEffect(() => {
    let heightCheckInterval;
    let heightChangeTimeout;

    if (
      formContainerRef?.current &&
      componentIsUnmounting &&
      componentIsMounted
    ) {
      const checkHeightChange = () => {
        const currentHeight = formContainerRef.current.offsetHeight;

        if (previousHeightRef.current !== currentHeight) {
          previousHeightRef.current = currentHeight;
          clearTimeout(heightChangeTimeout);

          heightChangeTimeout = setTimeout(() => {
            setComponentIsMounted(false);
            setComponentIsUnmounting(false);
          }, 800);
        }
      };

      heightCheckInterval = setInterval(checkHeightChange, 100);

      return () => {
        clearInterval(heightCheckInterval);
        clearTimeout(heightChangeTimeout);
      };
    }
  }, [componentIsUnmounting]); //2-track when containers height has stopped changing and then declare component is unmounted

  useEffect(() => {
    if (componentIsMounting && !componentIsUnmounting) {
      if (
        registrationComponentArr[registrationPage - 3] === 'personalAccountInfo'
      ) {
        setShowAccountInfo(true);
      } else {
        setShowAccountInfo(false);
      }

      if (
        registrationComponentArr[registrationPage - 3] ===
        'personalSearchSettings'
      ) {
        setShowSearchSettings(true);
      } else {
        setShowSearchSettings(false);
      }

      if (
        registrationComponentArr[registrationPage - 3] ===
        'translationRoomCodeRegistration'
      ) {
        setShowTranslationRoomCodes(true);
      } else {
        setShowTranslationRoomCodes(false);
      }

      if (registrationComponentArr[registrationPage - 3] === 'billing') {
        setShowBilling(true);
      } else {
        setShowBilling(false);
      }

      if (
        registrationComponentArr[registrationPage - 3] === 'emailPreferences'
      ) {
        setShowEmailPreferences(true);
      } else {
        setShowEmailPreferences(false);
      }
    }
  }, [componentIsMounting]);

  useEffect(() => {
    if (!componentIsMounted && !componentIsUnmounting) {
      setComponentIsMounting(true);
    }
  }, [componentIsUnmounting, componentIsMounted]); //3 - set mounting state

  useEffect(() => {
    let heightCheckInterval;
    let heightChangeTimeout;

    if (
      formContainerRef?.current &&
      componentIsMounting &&
      !componentIsMounted
    ) {
      const checkHeightChange = () => {
        const currentHeight = formContainerRef.current.offsetHeight;

        if (previousHeightRef.current !== currentHeight) {
          previousHeightRef.current = currentHeight;

          clearTimeout(heightChangeTimeout);

          heightChangeTimeout = setTimeout(() => {
            setComponentIsMounting(false);
            setComponentIsMounted(true);
            previousHeightRef.current = 99999;
          }, 500);
        }
      };

      heightCheckInterval = setInterval(checkHeightChange, 100);

      return () => {
        clearInterval(heightCheckInterval);
        clearTimeout(heightChangeTimeout);
      };
    }
  }, [componentIsMounting]); //4-track when containers height has stopped changing and then declare component is mounted

  //Complete registration func.
  async function handleCompleteRegistration() {
    handleRegistrationSetGenericIsLoadingCompleteRegistration(true);
    let newAccountIdToRemoveIfError;

    try {
      //add any billing-related opt-in conditions here -- currently billing only realted to translation rooms.
      if (!registrationTranslationRoomCodesOptIn) {
        await retryWrapper(handleCreateMyndFullAccountPersonal, [
          false,
          croppedImg,
        ]);
      } else {
        const { unverifiedAccountId, accountVerificationURL } =
          await retryWrapper(handleCreateMyndFullAccountPersonal, [
            true,
            croppedImg,
          ]);
        newAccountIdToRemoveIfError = unverifiedAccountId;

        //add paymentMethod
        if (unverifiedAccountId && accountVerificationURL) {
          let newPaymentMethodId;

          if (!temporaryPMId) {
            newPaymentMethodId =
              await paymentCardRef.current.handleFinalizeRegistrationCustomerAndSubscriptions();
            setTemporaryPMId(newPaymentMethodId);
          } //cant refetch same pmId so need temp storage for retries

          if (!newPaymentMethodId && !temporaryPMId) {
            if (process.env.REACT_APP_ENV === 'development') {
              console.log('DEV: no payment method id.');
            }
            throw new Error();
          }

          await handleAddPaymentMethodIdToUnverifiedAccount({
            unverifiedAccountId,
            paymentMethodId: newPaymentMethodId || temporaryPMId,
          });

          //registration complete send verification email.
          await handleSendWelcomeEmail(
            unverifiedAccountId,
            accountVerificationURL
          );
        }
      }
    } catch (error) {
      //remove unverifiedAccount so user can retry
      if (newAccountIdToRemoveIfError) {
        await handleRemoveUnverifiedAccount(newAccountIdToRemoveIfError, true);
      }

      if (error?.message !== 'stripe') {
        handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'PersonalRegistrationMain.js/handleCompleteRegistration',
        });
      }

      handleRegistrationSetGenericIsLoadingCompleteRegistration(false);
    }
  }

  return (
    <div className="form-container" ref={formContainerRef}>
      {!registrationIsSuccess ? (
        <div
          className={`registration-container ${
            componentIsUnmounting
              ? 'registration-container--retracted '
              : componentIsMounting
              ? 'registration-container--expanded'
              : componentIsMounted
              ? 'registration-container--mounted registration-overflow-off'
              : ''
          }`}
        >
          <div
            className={`${
              !showAccountInfo
                ? 'registration-offscreen visibility-hidden'
                : 'mrg-l12 mrg-r12'
            }`}
            aria-hidden={!showAccountInfo}
          >
            <PersonalAccountInfo
              croppedImg={croppedImg}
              setCroppedImg={setCroppedImg}
              HOCrop={crop}
              HOZoom={zoom}
              setHOCrop={setCrop}
              setHOZoom={setZoom}
            />
          </div>

          {registrationComponentArr.includes('personalSearchSettings') && (
            <div
              className={
                !showSearchSettings
                  ? 'registration-offscreen  visibility-hidden'
                  : 'mrg-l12 mrg-r12'
              }
              aria-hidden={!showSearchSettings}
            >
              <PersonalSearchSettings />
            </div>
          )}

          {registrationComponentArr.includes(
            'translationRoomCodeRegistration'
          ) && (
            <div
              className={
                !showTranslationRoomCodes
                  ? 'registration-offscreen  visibility-hidden'
                  : 'mrg-l12 mrg-r12'
              }
              aria-hidden={!showTranslationRoomCodes}
            >
              <TranslationRoomCodeRegistration />
            </div>
          )}

          {registrationComponentArr.includes('billing') && (
            <div
              className={
                !showBilling
                  ? 'registration-offscreen visibility-hidden'
                  : 'mrg-l12 mrg-r12'
              }
              aria-hidden={!showBilling}
            >
              <PersonalBilling
                paymentCardRef={paymentCardRef}
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                activePage={showBilling}
              />
            </div>
          )}

          <div
            className={
              !showEmailPreferences
                ? 'registration-offscreen  visibility-hidden'
                : 'mrg-l12 mrg-r12'
            }
            aria-hidden={!showEmailPreferences}
          >
            <EmailPreferences
              handleCompleteRegistration={handleCompleteRegistration}
            />
          </div>
        </div>
      ) : (
        <PersonalCompletionPage />
      )}
    </div>
  );
};

export default PersonalRegistrationMain;
