export default function handleRoomCodeSubscriptionValidityCheck(
  roomCodeInputValue
) {
  let roomCodeValidLength = false;
  let roomCodeValidChar = false;

  if (roomCodeInputValue?.length > 3) {
    roomCodeValidLength = true;
  }

  if (roomCodeInputValue?.length > 0) {
    function isValidString(s) {
      const regex = /^[A-Za-z0-9-]+$/;
      return regex.test(s);
    }
    roomCodeValidChar = isValidString(roomCodeInputValue);
  }

  return {
    roomCodeValidLength,
    roomCodeValidChar,
  };
}
