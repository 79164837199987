import { useRef, useState } from 'react';

//Hooks
//Components
import Spinner from '../Spinner/Spinner';

//Utility

const CodeInput = ({
  handleCodeInput,
  isLoadingExternal,
  codeLength,
  ariaDescription,
  toUpperCase,
}) => {
  //hooks

  //component state
  const [isLoading, setIsLoading] = useState(false);
  const [charCount, setCharCount] = useState(0);

  //component variables
  const codeRef = useRef();

  //component functions
  function handleSetCode(e) {
    setCharCount(e.target.value?.length);
    if (e.target.value?.length === codeLength) {
      let code;
      if (toUpperCase) {
        code = e.target.value.toUpperCase();
      } else {
        code = e.target.value;
      }

      if (!isLoading) {
        setIsLoading(true);

        const delayTimerToRenderLastCharacter = setTimeout(() => {
          handleCodeInput(code);
          setIsLoading(false);
        }, 250);

        return () => clearTimeout(delayTimerToRenderLastCharacter);
      }
    }
  }

  return (
    <div id="code" ref={codeRef} className="code-input-wrapper">
      {isLoading || isLoadingExternal ? (
        <Spinner minWidth={'100%'} minHeight={'100%'} />
      ) : (
        <>
          <p className="character-count" aria-hidden="true">
            {charCount}/{codeLength}
          </p>
          <input
            aria-label={ariaDescription && ariaDescription}
            id="code-input"
            className="code-input"
            maxLength={codeLength}
            onKeyUp={(e) => handleSetCode(e)}
            autoCapitalize="off"
            enterKeyHint="enter"
            onBlur={(e) => handleSetCode(e)}
            spellCheck="false"
          />
        </>
      )}
    </div>
  );
};

export default CodeInput;
