import { useState } from 'react';
//Hooks
import useSettings from '../../context/useSettings';

//Components

//Utility
import { return_curved_arrow } from '../../assets/icons';
import ScrollTo from '../../utils/UI/ScrollTo';

const ScrollToComponent = ({ text, elementId, key }) => {
  //Hooks
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlight, setTapHighlight] = useState(false);

  return (
    <li key={key}>
      <button
        type="button"
        className={`scrollToComponent highlight-bthin-theme br--small highlight-i-lgt fs16 access-ob access-o6 ${
          tapHighlight ? '' : ''
        }`}
        onClick={() =>
          handleMobileTap(
            [() => setTapHighlight(true), () => setTapHighlight(false)],
            [() => ScrollTo(elementId, 'smooth', false, elementId)]
          )
        }
        aria-label={text}
      >
        {text}
        <img src={return_curved_arrow} alt="arrow" />
      </button>
    </li>
  );
};

export default ScrollToComponent;
