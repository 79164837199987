import { useEffect, useState } from 'react';

//hooks
import useApiStatus from '../../../context/useApiStatus';
import { useAdminSupportReduxHandlers } from '../support/useAdminSupportHandlers';

//components
import ItemButton from '../../../components/ItemButton/ItemButton';
import TextArea from '../../../components/TextArea/TextArea';

//utils
import { dropdown_arrow, search } from '../../../assets/icons';

const AdminSearch = ({ cardsRowIds, setCardsRowIds, managementMode }) => {
  const { handleWarning } = useApiStatus();

  //API
  const { handleSearchReference } = useAdminSupportReduxHandlers();

  //state
  const [searchType, setSearchType] = useState('');
  const [searchReferenceInput, setSearchReferenceInput] = useState('');
  const [searchReferenceisLoading, setSearchReferenceisLoading] =
    useState(false);
  const [searchToolOpen, setSearchToolOpen] = useState(false);

  //Search functions
  async function searchReference() {
    setSearchReferenceisLoading(true);
    const res = await handleSearchReference(searchType, searchReferenceInput);
    setSearchReferenceisLoading(false);

    if (res?.[0]) {
      if (cardsRowIds.includes(res[0]?._id)) {
        const newWarning = {
          message: 'alreadySelected',
          id: Date.now(),
          origin: 'AdminSearch.js/searchReference',
        };
        handleWarning(newWarning);
      } else {
        setCardsRowIds([...cardsRowIds, res[0]?._id]);
      }
    }

    // clean up -- ensures that changing isLoading state has enough time to trigger reload in textarea

    let timeout = null;
    timeout = setTimeout(() => {
      setSearchReferenceisLoading(false);
      setSearchReferenceInput('');
      setSearchType('');
    }, 300);
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }

  useEffect(() => {
    if (!searchToolOpen) {
      handleSearchType('');
      setSearchReferenceInput('');
    }
  }, [searchToolOpen]);

  function handleSearchType(selection) {
    if (searchType === selection) {
      setSearchType('');
    } else {
      setSearchType(selection);
    }
  }

  return (
    <div className="admin-search-tool mrg-b24 mrg-t12">
      <button
        className="search-btn flex-row align-center  full-width highlight-bthin-theme mrg-b12"
        onClick={() => setSearchToolOpen(!searchToolOpen)}
      >
        <p className="align-center fs21 fwsb mrg-r24 ">Search Tool</p>
        <img
          src={dropdown_arrow}
          alt="arrow"
          className={`pad-12 ${searchToolOpen ? 'reverse-vertical' : ''}`}
        />
      </button>

      {searchToolOpen && (
        <div className="admin-search-tool__container">
          <div className="flex-row mrg-b24 space-evenly">
            <button
              type="button"
              className={`fs16 fwsb mrg-r12 access-ob access-o6 ${
                searchType === 'account' ? 'selection item-active' : 'selection'
              }`}
              onClick={() => handleSearchType('account')}
            >
              Account
            </button>
            <button
              type="button"
              className={`fs16 fwsb mrg-r12 access-ob access-o6 ${
                searchType === 'event' ? 'selection item-active' : 'selection'
              }`}
              onClick={() => handleSearchType('event')}
            >
              Event
            </button>
            <button
              type="button"
              className={`fs16 fwsb mrg-r12 access-ob access-o6 ${
                searchType === 'ticket' ? 'selection item-active' : 'selection'
              }`}
              onClick={() => handleSearchType('ticket')}
            >
              Ticket
            </button>
            {managementMode && (
              <>
                <button
                  type="button"
                  className={`fs16 fwsb mrg-r12 access-ob access-o6 ${
                    searchType === 'technicalLog'
                      ? 'selection item-active'
                      : 'selection'
                  }`}
                  onClick={() => handleSearchType('technicalLog')}
                >
                  Technical Log
                </button>
                <button
                  type="button"
                  className={`fs16 fwsb mrg-r12 access-ob access-o6 ${
                    searchType === 'admin'
                      ? 'selection item-active'
                      : 'selection'
                  }`}
                  onClick={() => handleSearchType('admin')}
                >
                  Admin
                </button>
              </>
            )}
          </div>
          <TextArea
            id={'searchReferenceInput'}
            limit={14}
            hideWordCount={true}
            labelText={
              searchType === 'account'
                ? 'Account Reference'
                : searchType === 'event'
                ? 'Event Reference'
                : searchType === 'ticket'
                ? 'Ticket Reference'
                : searchType === 'admin'
                ? 'Admin Reference'
                : searchType === 'technical'
                ? 'Technical Reference'
                : 'Select Search'
            }
            handleChange={setSearchReferenceInput}
            disableEnter={true}
            onBlurHandlerOff={true}
            reload={searchReferenceisLoading}
          />
          <div className="h24" />
          <ItemButton
            icon={search}
            iconOn={true}
            text={'Search'}
            handler={searchReference}
            fullWidth={true}
            isLoading={searchReferenceisLoading}
            unavailable={!searchType || !searchReferenceInput}
          />
        </div>
      )}
    </div>
  );
};

export default AdminSearch;
