import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';
import useApiStatus from '../../../../../context/useApiStatus';
import useSettings from '../../../../../context/useSettings';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utility
import { image_find } from '../../../../../assets/images';
import {
  img_lightning,
  return_curved_arrow,
} from '../../../../../assets/icons';
import ScrollTo from '../../../../../utils/UI/ScrollTo';

const JoinRoom = ({ setPage, isConnecting }) => {
  //hooks
  const { settingsLastRoomCode } = useSettingsHandlers();
  const { TranslationJoinRoom } = useLanguageTranslationRoom();
  const {
    connectTranslationRoomSocket,
    translationRoomSocketStatus,
    userSessionIdTranslations,
  } = useTranslationRoomSocket();
  const { handleSettingsSetLastRoomCode } = useSettingsHandlers();
  const { handleWarning } = useApiStatus();
  const { dismissKeypad } = useSettings();

  //state
  const [joinRoomCode, setJoinRoomCode] = useState();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [borderHighlight, setBorderHighlight] = useState(false);

  const roomCodeInput = useRef();

  //initialize
  useEffect(() => {
    document.getElementById('enterRoom').focus();
    ScrollTo('', 'auto', true);
  }, []); //accessibility

  function moveCarotToRoomCodeEnd() {
    roomCodeInput.current.focus();
    roomCodeInput.current.setSelectionRange(
      roomCodeInput.current.value.length,
      roomCodeInput.current.value.length
    );
  }

  function handleKeyDown(e, submitId) {
    if ((e.key === 'Enter' || submitId) && joinRoomCode?.length > 3) {
      dismissKeypad(e);
      setFocusHighlight(false);
      handleConnectToRoom(joinRoomCode);
    }
  }

  function handleConnectToRoom(roomCode) {
    if (isConnecting) {
      handleWarning({
        message: 'tryAgain',
        origin: 'TranslationJoinRoom/handleConnectToRoom',
        id: Date.now(),
      });
    }

    if (roomCode?.length === 0) throw new Error('tryAgain');
    let formattedRoomCode = roomCode.toUpperCase().trim();

    if (translationRoomSocketStatus !== 'connected') {
      handleSettingsSetLastRoomCode(formattedRoomCode);
      connectTranslationRoomSocket(
        false,
        formattedRoomCode,
        userSessionIdTranslations
      );
    }
  }

  return (
    <div
      className={`translations-selection__container ${
        !imgLoaded ? 'remove' : ''
      } `}
    >
      <img
        src={image_find}
        alt="img"
        className="translations-find-room-img svg"
        onLoad={() => setImgLoaded(true)}
      />
      <div className="translations-selections">
        <p
          className="fs21 fwsb mrg-tb24 mrg-t12--ph mrg-b12--ph access-ob access-o6"
          aria-label={TranslationJoinRoom.findTranslationRoom}
          tabIndex="0"
          id="enterRoom"
        >
          {TranslationJoinRoom.findTranslationRoom}
        </p>

        <div
          className="textarea__wrapper"
          onMouseEnter={() => setBorderHighlight(true)}
          onMouseLeave={() => setBorderHighlight(false)}
        >
          <label
            id={`password-label`}
            className="textarea-label no-select"
            onClick={moveCarotToRoomCodeEnd}
          >
            <p>
              {TranslationJoinRoom.roomCode}&nbsp;
              <span className="highlight">*</span>
            </p>
          </label>
          <input
            id="roomCode"
            name="roomCode"
            type="text"
            className={`textarea textarea-password ${
              borderHighlight ? 'highlight-o-theme--tap' : ''
            } ${
              focusHighlight
                ? 'highlight-ob-theme--active'
                : 'highlight-ob-theme'
            }`}
            ref={roomCodeInput}
            defaultValue=""
            onChange={(e) => setJoinRoomCode(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            aria-label={TranslationJoinRoom.roomCode}
            maxLength="12"
            tabIndex="0"
            enterKeyHint="enter"
            autoComplete="off"
            onBlur={() => setFocusHighlight(false)}
            onFocus={() => setFocusHighlight(true)}
          />
        </div>

        <ItemButton
          text={TranslationJoinRoom.submit}
          handler={(e) => handleKeyDown(e, true)}
          icon={return_curved_arrow}
          iconOn={true}
          customWrapper={'full-width  mrg-tb24'}
          iconCustomClass={'reverse'}
          isLoading={isConnecting}
        />

        {settingsLastRoomCode?.length > 0 && (
          <ItemButton
            text={`${TranslationJoinRoom.usePrevious} "${settingsLastRoomCode}"`}
            handler={() => handleConnectToRoom(settingsLastRoomCode)}
            icon={img_lightning}
            iconOn={true}
            customWrapper={'full-width mrg-b24'}
            unavailable={isConnecting}
          />
        )}

        <div className="divider full-width mrg-tb12 " />
        <ItemButton
          text={TranslationJoinRoom.exit}
          handler={() => setPage(1)}
          customWrapper={'full-width mrg-t24'}
          unavailable={isConnecting}
        />
        <div className="h72" />
      </div>
    </div>
  );
};

export default JoinRoom;
