import { useEffect, useState } from 'react';

//hooks
import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';

//components

//utils
import { getRandomColor } from '../../../../../utils/UI/randomColorCodes';
import { dropdown_arrow } from '../../../../../assets/icons';
import { getDateOrTimeFromUTZstring } from '../../../../../utils/dateTime/DateCalc';

const InfoCardContainerSupport = ({ supportData }) => {
  const { SupportHistoryStatus } = useLanguageAdmin();
  const [historyEntryNotesOpen, setHistoryEntryNotesOpen] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);

  //UI
  const [handleColors, setHandleColors] = useState([]);

  //initialize
  useEffect(() => {
    let colorArr = [];
    supportData?.history?.forEach(() => colorArr.push(getRandomColor()));
    setHandleColors(colorArr);
  }, [supportData?.history]);

  //functions
  const toggleHistoryEntryNotes = (index) => {
    const newNotesOpen = [...historyEntryNotesOpen];
    newNotesOpen[index] = !newNotesOpen[index];
    setHistoryEntryNotesOpen(newNotesOpen);
  };
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Support</p>
      <p className="mrg-t6">
        Current Assignment:{' '}
        {supportData?.current?.personal
          ? `${supportData?.current?.personal?.firstName}
             ${supportData?.current?.personal?.lastName}`
          : 'N/A'}
      </p>
      <p className="mrg-t6">
        {supportData?.current?.company &&
          `Position: ${supportData?.current?.company?.position}`}
      </p>
      <p className="mrg-t6">
        {supportData?.current?.meta &&
          `Admin Ref: [${supportData?.current?.meta?.adminReference}]`}
      </p>

      <button
        className="flex-row align-center mrg-t24 highlight-bthin-theme fit-content pad-12 br--standard"
        onClick={() => setOpenHistory(!openHistory)}
      >
        <p className="fs18 fwsb mrg-r12 ">
          Ticket History ({supportData?.history?.length})
        </p>
        <img
          src={dropdown_arrow}
          alt="arrow"
          style={{ height: '12px' }}
          className={`${openHistory ? 'reverse-vertical' : ''}`}
          title={openHistory ? 'Close History' : 'Open History'}
        />
      </button>

      {openHistory && (
        <ul className="info-card-list">
          {supportData?.history
            ?.map((historyEntry, index) => {
              let colorArr = [];
              supportData?.history?.forEach(() =>
                colorArr.push(getRandomColor())
              );
              // console.log(historyEntry.action);
              return (
                <li key={`historyEntry-${index}`}>
                  <div
                    className="list-handle"
                    style={{ backgroundColor: handleColors[index] }}
                  />
                  <p>{SupportHistoryStatus[historyEntry.action]}</p>
                  <p>
                    {historyEntry._id?.personal?.firstName}{' '}
                    {historyEntry._id?.personal?.lastName}, Admin Ref: [
                    {historyEntry?.adminReference}]
                  </p>
                  <p>
                    {`${getDateOrTimeFromUTZstring(
                      historyEntry?.timestamp,
                      'date'
                    )} (${getDateOrTimeFromUTZstring(
                      historyEntry?.timestamp,
                      'time'
                    )})`}
                  </p>
                  {historyEntry?.notes && (
                    <div className="notes mrg-t6">
                      {historyEntry?.notes.length > 200 && (
                        <button
                          className="pad-6 hover-i-filter-theme"
                          onClick={() => toggleHistoryEntryNotes(index)}
                        >
                          <img
                            src={dropdown_arrow}
                            alt="arrow"
                            className={`filter-gray ${
                              historyEntryNotesOpen[index] === true
                                ? 'reverse-vertical'
                                : ''
                            }`}
                            title={
                              historyEntryNotesOpen[index] === true
                                ? 'Close Notes'
                                : 'Open Notes'
                            }
                          />
                        </button>
                      )}

                      {historyEntryNotesOpen[index] === true ? (
                        <p>Notes:"&nbsp;{historyEntry?.notes}"</p>
                      ) : (
                        <p className="notes-limited">
                          Notes:"&nbsp;{historyEntry?.notes}"
                        </p>
                      )}
                    </div>
                  )}
                </li>
              );
            })
            .reverse()}
        </ul>
      )}
    </div>
  );
};

export default InfoCardContainerSupport;

//hooks
//components
//utils
