import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
//language of map is set by the user's browser settings

const MapContainerStatic = ({ loadValue }) => {
  //Component state
  const [zoomLevel, setZoomLevel] = useState(14);
  const [coords, setCoords] = useState({
    lat: 0,
    lng: 0,
  });

  //Component variables
  const options = {
    styles: [
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
  const containerStyle = {
    width: '100%',
    height: '340px',
    border: '1px solid rgba(0, 0, 0, 0.4)',
    margin: '0 0 0 0',
  };

  //Initialize functions
  useEffect(() => {
    if (loadValue) {
      setCoords({
        lat: loadValue[1],
        lng: loadValue[0],
      });
    }
  }, [loadValue]);

  return (
    <GoogleMap
      id={'gmap'}
      mapContainerStyle={containerStyle}
      options={options}
      zoom={zoomLevel}
      center={coords}
    >
      {coords.lat && coords.lng ? (
        <Marker position={coords} draggable={false} />
      ) : null}
    </GoogleMap>
  );
};

export { MapContainerStatic };
