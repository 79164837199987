import { useEffect, useState } from 'react';

//hooks

//components

//utils
import { dropdown_arrow } from '../../../../../../assets/icons';
import { getRandomColor } from '../../../../../../utils/UI/randomColorCodes';

const InfoCardContainerEventRegistrations = ({ eventRegistrationsData }) => {
  //hooks
  //state
  const [showPendingApprovalInPersonList, setShowPendingApprovalInPersonList] =
    useState(false);
  const [showPendingApprovalVirtualList, setShowPendingApprovalVirtualList] =
    useState(false);
  const [
    showRegisteredAttendeesInPersonList,
    setShowRegisteredAttendeesInPersonList,
  ] = useState(false);
  const [
    showRegisteredAttendeesVirtualList,
    setShowRegisteredAttendeesVirtualList,
  ] = useState(false);

  //ui

  const [
    handleColorsPendingApprovalInPerson,
    setHandleColorsPendingApprovalInPerson,
  ] = useState([]);
  const [
    handleColorsPendingApprovalVirtual,
    setHandleColorsPendingApprovalVirtual,
  ] = useState([]);
  const [handleColorsRegisteredInPerson, setHandleColorsRegisteredInPerson] =
    useState([]);
  const [handleColorsRegisteredVirtual, setHandleColorsRegisteredVirtual] =
    useState([]);

  //initialize
  useEffect(() => {
    if (eventRegistrationsData.pendingApprovalInPerson) {
      let colorArr = [];
      eventRegistrationsData.pendingApprovalInPerson.map((_) =>
        colorArr.push(getRandomColor())
      );
      setHandleColorsPendingApprovalInPerson(colorArr);
    }
  }, [eventRegistrationsData.pendingApprovalInPerson]);

  useEffect(() => {
    if (eventRegistrationsData.pendingApprovalVirtual) {
      let colorArr = [];
      eventRegistrationsData.pendingApprovalVirtual.map((_) =>
        colorArr.push(getRandomColor())
      );
      setHandleColorsPendingApprovalVirtual(colorArr);
    }
  }, [eventRegistrationsData.pendingApprovalVirtual]);

  useEffect(() => {
    if (eventRegistrationsData.registeredAttendeesInPerson) {
      let colorArr = [];
      eventRegistrationsData.registeredAttendeesInPerson.map((_) =>
        colorArr.push(getRandomColor())
      );
      setHandleColorsRegisteredInPerson(colorArr);
    }
  }, [eventRegistrationsData.registeredAttendeesInPerson]);

  useEffect(() => {
    if (eventRegistrationsData.registeredAttendeesVirtual) {
      let colorArr = [];
      eventRegistrationsData.registeredAttendeesVirtual.map((_) =>
        colorArr.push(getRandomColor())
      );
      setHandleColorsRegisteredVirtual(colorArr);
    }
  }, [eventRegistrationsData.registeredAttendeesVirtual]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Registrations</p>
      <div className="mrg-t6">
        Registrations Active:{' '}
        {eventRegistrationsData.registrationsActive ? 'True' : 'False'}
      </div>
      <div className="mrg-t6">
        Approve Registrations:{' '}
        {eventRegistrationsData.approveRegistrations ? 'True' : 'False'}
      </div>
      <div className="mrg-t6">
        Max Registrations (In Person):{' '}
        {eventRegistrationsData?.maxRegistrationsInPersonEnabled
          ? `True - Amount: ${eventRegistrationsData?.maxRegistrationsInPersonAmount}`
          : 'False'}
      </div>
      <div className="mrg-t6">
        Max Registrations (Virtual):{' '}
        {eventRegistrationsData?.maxRegistrationsVirtualEnabled
          ? `True - Amount: ${eventRegistrationsData?.maxRegistrationsVirtualAmount}`
          : 'False'}
      </div>
      <div className="mrg-t24">
        Private Details:
        {eventRegistrationsData.privateDetails.enabled ? ' True' : ' False'}
        {eventRegistrationsData.privateDetails.enabled && (
          <ul className="flex-column mrg-l24">
            <li>
              Room:{' '}
              {eventRegistrationsData?.privateDetails.room ? 'True' : 'False'}
            </li>
            <li>
              Location Name:{' '}
              {eventRegistrationsData?.privateDetails.locationName
                ? 'True'
                : 'False'}
            </li>
            <li>
              Street Address:{' '}
              {eventRegistrationsData?.privateDetails.streetAddress
                ? 'True'
                : 'False'}
            </li>
            <li>
              Virtual Meeting Id:{' '}
              {eventRegistrationsData?.privateDetails.virtualMeetingId
                ? 'True'
                : 'False'}
            </li>
            <li>
              Virutal Meeting Link:{' '}
              {eventRegistrationsData?.privateDetails.virtualMeetingLink
                ? 'True'
                : 'False'}
            </li>
            <li>
              Virutal Meeting Passcode:{' '}
              {eventRegistrationsData?.privateDetails.virtualMeetingPasscode
                ? 'True'
                : 'False'}
            </li>
          </ul>
        )}
      </div>

      <p className="fs18 fwsb mrg-t24">Attendees:</p>
      <div className="flex-column mrg-t6">
        <div className="flex-row">
          Pending Approval (In Person):{' '}
          {eventRegistrationsData.pendingApprovalInPerson?.length}
          {eventRegistrationsData.pendingApprovalInPerson?.length > 0 && (
            <button
              className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
              style={{ height: '24px' }}
              onClick={() =>
                setShowPendingApprovalInPersonList(
                  !showPendingApprovalInPersonList
                )
              }
            >
              <p className="fs16 fwsb mrg-r12">List</p>
              <img
                src={dropdown_arrow}
                alt="arrow"
                style={{ height: '10px' }}
                className={`${
                  showPendingApprovalInPersonList ? '' : 'reverse-vertical'
                }`}
              />
            </button>
          )}
        </div>
        {showPendingApprovalInPersonList && (
          <ul className="info-card-list">
            {eventRegistrationsData.pendingApprovalInPerson.map(
              (acct, index) => {
                return (
                  <li key={`pendingApprovalInPerson-${index}`}>
                    <div
                      className="list-handle"
                      style={{
                        backgroundColor:
                          handleColorsPendingApprovalInPerson[index],
                      }}
                    />
                    <div className="flex-column">
                      <p>
                        {acct.personal.firstName} {acct.personal.lastName}
                      </p>
                      <p>
                        Account Reference: &nbsp;
                        {acct.meta.accountReference}
                      </p>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </div>
      <div className="flex-column mrg-t6">
        <div className="flex-row">
          Pending Approval (In Virtual):{' '}
          {eventRegistrationsData.pendingApprovalVirtual?.length}
          {eventRegistrationsData.pendingApprovalVirtual?.length > 0 && (
            <button
              className="flex-center pad-tb6 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
              style={{ height: '24px' }}
              onClick={() =>
                setShowPendingApprovalVirtualList(
                  !showPendingApprovalVirtualList
                )
              }
            >
              <p className="fs16 fwsb mrg-r12">List</p>
              <img
                src={dropdown_arrow}
                alt="arrow"
                style={{ height: '10px' }}
                className={`${
                  showPendingApprovalVirtualList ? '' : 'reverse-vertical'
                }`}
              />
            </button>
          )}
        </div>
        {showPendingApprovalVirtualList && (
          <ul className="info-card-list">
            {eventRegistrationsData.pendingApprovalVirtual.map(
              (acct, index) => {
                return (
                  <li key={`pendingApprovalVirtual-${index}`}>
                    <div
                      className="list-handle"
                      style={{
                        backgroundColor:
                          handleColorsPendingApprovalVirtual[index],
                      }}
                    />
                    <div className="flex-column">
                      <p>
                        {acct.personal.firstName} {acct.personal.lastName}
                      </p>
                      <p>
                        Account Reference: &nbsp;
                        {acct.meta.accountReference}
                      </p>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </div>
      <div className="flex-column mrg-t6">
        <div className="flex-row">
          Registered Attendees (In Person):{' '}
          {eventRegistrationsData.registeredAttendeesInPerson?.length}
          {eventRegistrationsData.registeredAttendeesInPerson?.length > 0 && (
            <button
              className="flex-center pad-tb6 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
              style={{ height: '24px' }}
              onClick={() =>
                setShowRegisteredAttendeesInPersonList(
                  !showRegisteredAttendeesInPersonList
                )
              }
            >
              <p className="fs16 fwsb mrg-r12">List</p>
              <img
                src={dropdown_arrow}
                alt="arrow"
                style={{ height: '10px' }}
                className={`${
                  showRegisteredAttendeesInPersonList ? '' : 'reverse-vertical'
                }`}
              />
            </button>
          )}
        </div>
        {showRegisteredAttendeesInPersonList && (
          <ul className="info-card-list">
            {eventRegistrationsData.registeredAttendeesInPerson.map(
              (acct, index) => {
                return (
                  <li key={`registeredAttendeesInPerson-${index}`}>
                    <div
                      className="list-handle"
                      style={{
                        backgroundColor: handleColorsRegisteredInPerson[index],
                      }}
                    />
                    <div className="flex-column">
                      <p>
                        {acct.personal.firstName} {acct.personal.lastName}
                      </p>
                      <p>
                        Account Reference: &nbsp;
                        {acct.meta.accountReference}
                      </p>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </div>
      <div className="flex-column mrg-t6">
        <div className="flex-row">
          Registered Attendees (Virtual):{' '}
          {eventRegistrationsData.registeredAttendeesVirtual?.length}
          {eventRegistrationsData.registeredAttendeesVirtual?.length > 0 && (
            <button
              className="flex-center pad-tb6 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
              style={{ height: '24px' }}
              onClick={() =>
                setShowRegisteredAttendeesVirtualList(
                  !showRegisteredAttendeesVirtualList
                )
              }
            >
              <p className="fs16 fwsb mrg-r12">List</p>
              <img
                src={dropdown_arrow}
                alt="arrow"
                style={{ height: '10px' }}
                className={`${
                  showRegisteredAttendeesVirtualList ? '' : 'reverse-vertical'
                }`}
              />
            </button>
          )}
        </div>
        {showRegisteredAttendeesVirtualList && (
          <ul className="info-card-list">
            {eventRegistrationsData.registeredAttendeesVirtual.map(
              (acct, index) => {
                return (
                  <li key={`registeredAttendeesVirtual-${index}`}>
                    <div
                      className="list-handle"
                      style={{
                        backgroundColor: handleColorsRegisteredVirtual[index],
                      }}
                    />
                    <div className="flex-column">
                      <p>
                        {acct.personal.firstName} {acct.personal.lastName}
                      </p>
                      <p>
                        Account Reference: &nbsp;
                        {acct.meta.accountReference}
                      </p>
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default InfoCardContainerEventRegistrations;
