import { useState } from 'react';

//hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//components

//utils
import { arrow_long } from '../../assets/icons';

const ButtonOrder = ({ sortOrder, setSortOrder }) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleMobileTap } = useSettings();
  //state

  //UI
  const [tapHighlightOrderBtn, setTapHighlightOrderBtn] = useState(false);

  function handleOrderBtn() {
    handleMobileTap(
      [
        () => setTapHighlightOrderBtn(true),
        () => setTapHighlightOrderBtn(false),
      ],
      [() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')],
      true
    );
  }

  return (
    <button
      className={`order-btn access-ob access-o6 ${
        tapHighlightOrderBtn
          ? 'highlight-b-theme--tap'
          : 'border-reg-1 outline-reg-1'
      }`}
      onClick={handleOrderBtn}
      aria-label={`${Generic.sortOrder} ${
        sortOrder === 'asc' ? Generic.ascending : Generic.descending
      }.`}
    >
      {sortOrder === 'asc' ? (
        <p
          className={` ${
            tapHighlightOrderBtn ? 'highlight-it-theme--tap' : ''
          }`}
        >
          A-Z
        </p>
      ) : (
        <p
          className={` ${
            tapHighlightOrderBtn ? 'highlight-it-theme--tap' : ''
          }`}
        >
          Z-A
        </p>
      )}
      <img
        src={arrow_long}
        alt={Generic.arrow}
        className={`flex-center ${
          sortOrder !== 'asc' ? 'reverse-vertical' : ''
        }  ${tapHighlightOrderBtn ? 'filter-theme' : 'filter-gray'}`}
      />
    </button>
  );
};

export default ButtonOrder;
