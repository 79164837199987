import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from '@stripe/react-stripe-js';

//hooks
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import { useRegistrationHandlers } from '../../useRegistrationHandlers';
import useApiStatus from '../../../../../context/useApiStatus';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//utilities
import { axiosLimited } from '../../../../../axios/axios';
import { retryWrapper } from '../../../../../utils/logic/retryFns';
import { checkmark_green } from '../../../../../assets/icons';

//dont hold state of sensitive data.
let RegistrationPaymentCard = (
  {
    clientSecret,
    setContinueBtnCheck,
    caclulateBilling,
    activePage,
    resetTaxData,
  },
  ref
) => {
  //Hooks
  const stripe = useStripe();
  const elements = useElements();
  const { PaymentCard } = useLanguageRegistration();
  const { Generic } = useLanguageComponents();
  const { handleError } = useApiStatus();
  const {
    registrationTranslationRoomsRoomCodes,
    registrationPersonalEmail,
    registrationUnverifiedAccountId,
    registrationGenericAccountVerificationURL,
  } = useRegistrationHandlers();

  //State
  const [paymentInformationComplete, setPaymentInformationComplete] =
    useState(false);
  const [addressInformationComplete, setAddressInformationComplete] =
    useState(false);
  const [
    lastTranslationRoomAmountChecked,
    setLastTranslationRoomAmountChecked,
  ] = useState(0);

  //UI
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (paymentInformationComplete && addressInformationComplete) {
      setContinueBtnCheck(true);
    } else if (!paymentInformationComplete || !addressInformationComplete) {
      setContinueBtnCheck(false);
    }
  }, [paymentInformationComplete, addressInformationComplete]); //info complete check button render

  useEffect(() => {
    if (!elements) return;
    const paymentElement = elements.getElement(PaymentElement);
    const addressElement = elements.getElement(AddressElement);

    const handlePaymentChange = (event) => {
      setPaymentInformationComplete(event.complete);
    };

    const handleAddressChange = (event) => {
      setAddressInformationComplete(event.complete); //boolean
      if (event.complete) {
        caclulateBilling(event.value, registrationTranslationRoomsRoomCodes);
      } else {
        resetTaxData();
      }
    };

    paymentElement.on('change', handlePaymentChange);
    addressElement.on('change', handleAddressChange);

    return () => {
      paymentElement.off('change', handlePaymentChange);
      addressElement.off('change', handleAddressChange);
    };
  }, [elements, registrationTranslationRoomsRoomCodes, activePage]); // checks for elements data completion and billing calc

  useEffect(() => {
    //causing red field outlines
    if (!elements || !addressInformationComplete) return;
    const addressElement = elements.getElement(AddressElement);

    addressElement
      .getValue()
      .then((addressObj) => {
        if (
          addressObj?.value?.address &&
          registrationTranslationRoomsRoomCodes?.length !==
            lastTranslationRoomAmountChecked
        ) {
          setLastTranslationRoomAmountChecked(
            registrationTranslationRoomsRoomCodes?.length
          );
          if (
            addressObj?.value?.address?.city &&
            addressObj?.value?.address?.country
          ) {
            caclulateBilling(
              addressObj.value,
              registrationTranslationRoomsRoomCodes
            );
          }
        }
      })
      .catch((err) => {
        handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'PaymentCard.js/useEffect, calcBilling',
        });
      });
  }, [activePage]); //second check for billing calc based on translationRoomCode changes when user loads back to this page

  async function finalizeRegistrationCustomerAndSubscriptions() {
    const fnId = 'finalizeRegistrationCustomerAndSubscriptions';

    if (!isProcessing) {
      if (
        !stripe ||
        !elements ||
        !clientSecret ||
        registrationTranslationRoomsRoomCodes?.length === 0
      ) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error('DEV', fnId, 'Not Initialized.');
        }
        throw new Error();
      }

      //keep at higher scope to access in catch block for errors
      let confirmSetupResponse;

      try {
        setIsProcessing(true);

        const addressElement = elements.getElement(AddressElement);
        const addressObj = await addressElement.getValue();
        const billing_details = {
          name: addressObj.value.name,
          address: {
            line1: addressObj.value.address.line1,
            line2: addressObj.value.address.line2,
            city: addressObj.value.address.city,
            state: addressObj.value.address.state,
            country: addressObj.value.address.country,
            postal_code: addressObj.value.address.postal_code,
          },
        };

        //create database obj to hold user information then delete
        async function createRedirectObjId() {
          try {
            const { data } = await axiosLimited.post(
              '/api/stripe/redirectObj',
              {
                email: registrationPersonalEmail,
                unverifiedAccountId: registrationUnverifiedAccountId,
                accountVerificationURL:
                  registrationGenericAccountVerificationURL,
              }
            );
            return data;
          } catch (error) {
            if (process.env.REACT_APP_ENV === 'development') {
              console.error('DEV', fnId, '/createRedirectObjId', error);
            }
            throw error;
          }
        }

        const { redirectObjId, return_url } = await retryWrapper(
          createRedirectObjId
        );

        //Stripe Confirm Setup
        async function retrieveConfirmSetupResponse() {
          try {
            const confirmSetupResponse = await stripe.confirmSetup({
              elements,
              redirect: 'if_required',
              confirmParams: {
                payment_method_data: {
                  billing_details,
                },
                return_url,
              },
            });
            return confirmSetupResponse;
          } catch (error) {
            throw error;
          }
        }

        confirmSetupResponse = await retrieveConfirmSetupResponse();

        //redirect obj no longer needed if registration continuing here
        async function removeRedirectObj() {
          try {
            await axiosLimited.delete(
              `/api/stripe/redirectObj/${redirectObjId}`
            );
          } catch (error) {
            if (process.env.REACT_APP_ENV === 'development') {
              console.error('DEV', fnId, '/removeRedirectObj', error);
            }
            throw error;
          }
        }
        await retryWrapper(removeRedirectObj);

        //throw error after removing redirect obj from db.
        if (confirmSetupResponse?.error) {
          throw new Error('stripe');
        }

        //keep return payment method id at the end so any failure for removeRedirectobj will throw error.
        const paymentMethodId =
          confirmSetupResponse?.setupIntent?.payment_method;

        setIsProcessing(false);
        return paymentMethodId;
      } catch (error) {
        if (confirmSetupResponse?.error) {
          handleError({
            message: `${confirmSetupResponse?.error?.code}`,
            id: Date.now(),
            origin: 'PersonalRegistrationMain.js/handleCompleteRegistration',
          });
        }

        //handle failure at higher scope to remove uneverifiedAccount
        setIsProcessing(false);
        throw error;
      }
    }
  }

  useImperativeHandle(ref, () => ({
    handleFinalizeRegistrationCustomerAndSubscriptions() {
      return finalizeRegistrationCustomerAndSubscriptions();
    },
  }));

  return (
    <form id="payment-form">
      <label
        className="content-label flex-row mrg-b24 mrg-b24 align-center  min-full-width"
        tabIndex="0"
      >
        {PaymentCard.creditCardInformation}
        <p className="color-theme disable-select" aria-hidden="true">
          &nbsp;*
        </p>

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">{Generic.required}</p>
          {paymentInformationComplete && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>

      <PaymentElement />

      <label
        className="content-label flex-row mrg-tb24 align-center  min-full-width"
        tabIndex="0"
      >
        {PaymentCard.billingAddress}
        <p className="color-theme disable-select" aria-hidden="true">
          &nbsp;*
        </p>

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">{Generic.required}</p>
          {addressInformationComplete && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>
      <AddressElement
        options={{
          mode: 'billing',
          allowedCountries: ['CA'],
          autocomplete: {
            mode: 'google_maps_api',
            apiKey: '{AIzaSyBo7CkkuI8rLc8D9zzIzYIV2NCFP_E-JM8}',
          },
        }}
      />
    </form>
  );
};

RegistrationPaymentCard = forwardRef(RegistrationPaymentCard);

export default RegistrationPaymentCard;
