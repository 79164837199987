import {
  american_express,
  discover,
  mastercard,
  visa_blue,
} from '../../assets/icons/credit_card_logos/index';

export const paymentLogosObj = {
  visa: visa_blue,
  mastercard: mastercard,
  amex: american_express,
  discover: discover,
};

export default paymentLogosObj;
