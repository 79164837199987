import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
//language of map is set by the user's browser settings
const MapContainer = ({
  placeCoordinates,
  loadValue,
  loadTrigger,
  handler,
}) => {
  //Component state
  const [currentPosition, setCurrentPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [zoomLevel, setZoomLevel] = useState(14);

  //Component variables
  const effectRan = useRef(false);
  const options = {
    styles: [
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };
  const containerStyle = {
    width: '100%',
    height: '340px',
    border: '1px solid rgba(0, 0, 0, 0.4)',
    margin: '0 0 0 0',
  };

  //Initialize functions
  useEffect(() => {
    if (placeCoordinates?.lat !== 0 && placeCoordinates?.lng !== 0) {
      setCurrentPosition({
        lat: placeCoordinates?.lat,
        lng: placeCoordinates?.lng,
      });
      setZoomLevel(17);
    }
  }, [placeCoordinates]);

  useEffect(() => {
    if (
      loadValue[0] === 0 &&
      loadValue[1] === 0 &&
      navigator?.geolocation &&
      currentPosition.lat === 0 &&
      currentPosition.lng === 0
    ) {
      const successCallback = (position) => {
        setCurrentPos(position);
      };

      navigator.geolocation.getCurrentPosition(successCallback);
    }
  }, []);

  useEffect(() => {
    if (loadTrigger) {
      if (effectRan.current === false) {
        if (loadValue) {
          if (loadValue[0] !== 0 && loadValue[1] !== 0) {
            let coords = {
              lat: loadValue[0],
              lng: loadValue[1],
            };
            setCurrentPosition(coords);
            effectRan.current = true;
          }
        }
      }
    }
  }, [loadValue, loadTrigger]);

  //Component functions

  const setCurrentPos = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setCurrentPosition(currentPosition);
    handler([currentPosition.lat, currentPosition.lng]);
  }; ///set default position to user's location

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng });
    let coords = [lat, lng];
    handler(coords);
  };

  return (
    <GoogleMap
      id={'gmap'}
      mapContainerStyle={containerStyle}
      options={options}
      zoom={zoomLevel}
      center={
        loadValue && loadValue[0] !== 0 && loadValue[1] !== 0
          ? { lat: loadValue[0], lng: loadValue[1] }
          : currentPosition
      }
    >
      {currentPosition.lat ? (
        <Marker
          position={
            loadValue && loadValue[0] !== 0 && loadValue[1] !== 0
              ? { lat: loadValue[0], lng: loadValue[1] }
              : currentPosition
          }
          onDragEnd={(e) => onMarkerDragEnd(e)}
          draggable={true}
        />
      ) : null}
    </GoogleMap>
  );
};

export { MapContainer };
