import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';

//Hooks
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useSettings from '../../../../../context/useSettings';

//Components
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import TextArea from '../../../../../components/TextArea/TextArea';

//Utility
import { trash } from '../../../../../assets/icons';
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import getImageBlob from '../../../../../utils/images/getImageBlob';

let Speaker1 = (
  {
    removeSpeaker,
    id,
    loadValue,
    initialize,
    speakerNameFieldText,
    speakerBioFieldText,
    ariaImageType,
    editEventMode,
    pauseCrop,
    imageChangeTracker,
    setImageChangeTracker,
    handleSetSpeaker1Data,
  },
  ref
) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleMobileTap, isTouchDevice } = useSettings();

  //Component state
  const [speakerData1, setSpeakerData1] = useState({
    id: id,
    name: '',
    bio: '',
  });
  const [croppedImg1, setCroppedImg1] = useState({
    id: id,
    croppedImage: '',
    imagePreview: '',
  });
  const [trackImgChangesActive, setTrackImgChangesActive] = useState(false);

  //UI state
  const [highlightDeleteWarning, setHighlightDeleteWarning] = useState(false);
  const [tapHighlightRemove, setTapHighlightRemove] = useState(false);

  //Component variables
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    let timeout = null;

    timeout = setTimeout(() => {
      setTrackImgChangesActive(true);
    }, 2000);

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, []); //prevents initializing components to trigger adding speaker image change to change list for edit event

  useEffect(() => {
    if (
      editEventMode &&
      loadValue &&
      loadValue?.imagePath !== 'account_img_default.png'
    ) {
      getImageBlob(loadValue?.imagePath, 'event', setCroppedImg1);
    }
  }, [editEventMode, loadValue]);

  useEffect(() => {
    if (initialize) {
      if (effectRan.current === false) {
        if (loadValue) {
          setSpeakerData1({
            id: loadValue.id,
            name: loadValue.name,
            bio: loadValue.bio,
          });
        }
        effectRan.current = true;
      }
    }
  }, [initialize]);

  //Component functions
  function handleSpeakerImageChange(e) {
    let newImg = {
      id: croppedImg1.id,
      croppedImage: e.croppedImage,
      imagePreview: e.imagePreview,
    };
    setCroppedImg1(newImg);
    if (imageChangeTracker && trackImgChangesActive) {
      if (imageChangeTracker?.speakerImage !== true) {
        setImageChangeTracker({
          ...imageChangeTracker,
          speakerImage: true,
        });
      }
    } //used for edit event change list
  }

  function handleSpeakerNameChange(text) {
    let newData = {};
    newData = {
      id: speakerData1.id,
      name: text,
      bio: speakerData1.bio,
    };
    setSpeakerData1(newData);
    handleSetSpeaker1Data(newData);
  }

  function handleSpeakerBioChange(text) {
    let newData = {};

    newData = {
      id: speakerData1.id,
      name: speakerData1.name,
      bio: text,
    };
    setSpeakerData1(newData);
    handleSetSpeaker1Data(newData);
  }

  function handleRemoveSpeaker(id, preview) {
    handleSetSpeaker1Data(null);
    removeSpeaker(id, preview);
  }

  useImperativeHandle(ref, () => ({
    handleGetSpeaker() {
      return croppedImg1;
    },
  }));

  function handleMouseEnter() {
    if (!isTouchDevice) {
      setHighlightDeleteWarning(true);
    }
  }

  function handleMouseLeave() {
    if (!isTouchDevice) {
      setHighlightDeleteWarning(false);
    }
  }

  return (
    <div
      className={`create-speaker  ${
        tapHighlightRemove ? 'border-1px--warning outline-1px--warning' : ''
      }
    ${highlightDeleteWarning ? 'border-1px--warning outline-1px--warning' : ''}
    `}
      id={id}
    >
      <ImageCropper
        ariaImageDescription={ariaImageType}
        center={true}
        cover={'horizontal-cover'}
        defaultImageClassName={'speakerImgDefault'}
        handleImage={handleSpeakerImageChange}
        height={264}
        id={id}
        imagePreview={croppedImg1.imagePreview}
        pauseCrop={pauseCrop}
        profileFrame={true}
        width={264}
      />
      <div className="h24" />
      <TextArea
        key={`name-${id}`}
        handleChange={handleSpeakerNameChange}
        limit={100}
        id={`name-${id}`}
        labelText={speakerNameFieldText}
        disableEnter={true}
        noMrg={true}
        asterisk={true}
        loadValue={speakerData1.name}
        loadTrigger={initialize}
      />
      <div className="h24" />
      <div key={`speakers-container-${id}`}>
        <div className="full-width">
          <TextArea
            key={`bio-${id}`}
            handleChange={handleSpeakerBioChange}
            limit={600}
            id={`bio-${id}`}
            labelText={speakerBioFieldText}
            disableEnter={false}
            noMrg={true}
            large={true}
            loadValue={speakerData1.bio}
            loadTrigger={initialize}
          />
        </div>
      </div>
      <div className="h12" />
      <button
        className="remove-button access-ob mrg-auto-left mrg-auto-right"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label={Generic.remove}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightRemove(true),
              () => setTapHighlightRemove(false),
            ],
            [
              () => ScrollTo('speakersLabel', 'smooth', false, 'speakersLabel'),
              () => handleRemoveSpeaker(id, croppedImg1.imagePreview),
            ]
          )
        }
      >
        <img
          src={trash}
          alt={Generic.remove}
          className={`trashbin svg ${
            tapHighlightRemove ? 'filter-red' : 'filter-lightgray'
          }`}
        />
      </button>
    </div>
  );
};

Speaker1 = forwardRef(Speaker1);

export default Speaker1;
