import { apiSlice } from '../../../app/api/apiSlice';
import {
  setAdminCredentials,
  setAdminAuthIsLoggingOut,
} from './adminAuthSlice';
import { emptyAdminAccountData } from '../account/adminAccountSlice';
import { addReduxApiStatus } from '../../main/apiStatus/apiStatusSlice';
import { setCredentials } from '../../main/auth/authSlice';
import { emptyAccountData } from '../../main/account/accountSlice';
import {
  setAdminSupportTickets,
  setAdminSupportEventVerifications,
} from '../support/adminSupportSlice';
import { setAdminManagementTickets } from '../management/adminManagementSlice';

export const adminAuthApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    adminLogin: builder.mutation({
      query: (credentials) => ({
        url: '/api/admin/auth',
        method: 'POST',
        body: { ...credentials },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { email, roles, accessToken } = data;
          //clear regular account & credentials
          dispatch(setCredentials({ email: '', accessToken: '', roles: '' }));
          dispatch(emptyAccountData());

          //set admin credentials
          return dispatch(setAdminCredentials({ email, accessToken, roles }));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AdminAuthApiSlice/login',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['AdminAuth'],
    }),
    adminRefresh: builder.query({
      query: () => '/refresh',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { email, roles, accessToken } = data;
          dispatch(setAdminCredentials({ email, accessToken, roles }));
          return data;
        } catch (error) {
          return null;
        }
      },
      providesTags: ['AdminAuth'],
    }),
    adminLogout: builder.query({
      query: () => '/api/admin/logout',
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setAdminAuthIsLoggingOut(true));
          await queryFulfilled;
          dispatch(
            setAdminCredentials({ email: '', accessToken: '', roles: '' })
          );
          dispatch(emptyAdminAccountData());
          dispatch(setAdminAuthIsLoggingOut(false));
          dispatch(setAdminSupportTickets([]));
          dispatch(setAdminSupportEventVerifications([]));
          dispatch(setAdminManagementTickets([]));
        } catch (error) {
          return dispatch(setAdminAuthIsLoggingOut(false));
        }
      },
    }),
  }),
});

export const {
  useAdminLoginMutation,
  useLazyAdminRefreshQuery,
  useLazyAdminLogoutQuery,
} = adminAuthApiSlice;
