import { apiSlice } from '../../../app/api/apiSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';
import {
  setMyOrganizedEvents,
  setMyScheduledOrganizedEvents,
  setMyArchivedOrganizedEvents,
} from './organizeSlice';

export const organizedEventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myOrganizedEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/event/myEvents',
          params: { accountId },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      transformResponse: (response) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(setMyOrganizedEvents(data));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error?.error,
            origin: 'OrganizedEventApiSlice/myOrganizedEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['OrganizerEvent'],
    }),
    myOrganizedScheduledEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/event/myScheduledEvents',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setMyScheduledOrganizedEvents(data));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error?.error,
            origin: 'OrganizedEventApiSlice/myOrganizedScheduledEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['OrganizerEvent'],
    }),
    myOrganizedArchivedEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/event/myArchivedEvents',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(setMyArchivedOrganizedEvents(data));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error?.error,
            origin: 'OrganizedEventApiSlice/myOrganizedArchivedEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['OrganizerEvent'],
    }),
    createEvent: builder.mutation({
      query: (body) => {
        return {
          url: '/api/organize/event/create',
          method: 'POST',
          body,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/createEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    updateEvent: builder.mutation({
      query: (body) => ({
        url: `/api/organize/event/update`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/updateEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    removeEventActiveUpdateAccounts: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: `/api/organize/event/remove/active`,
        method: 'PATCH',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventActiveUpdateAccounts',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeEventScheduledUpdateAccounts: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: `/api/organize/event/remove/scheduled`,
        method: 'PATCH',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventUpdateAccounts',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeEventArchivedUpdateAccounts: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: `/api/organize/event/remove/archived`,
        method: 'PATCH',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventUpdateAccounts',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent'],
    }),
    removeEventActive: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: '/api/organize/event/remove/active',
        method: 'DELETE',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventActive',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    removeEventScheduled: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: '/api/organize/event/remove/scheduled',
        method: 'DELETE',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    acceptPendingApprovalInPerson: builder.mutation({
      query: ({ accountId, eventId, pendingApprovalInPersonId }) => ({
        url: '/api/organize/acceptPendingApprovalInPerson',
        method: 'PATCH',
        body: { accountId, eventId, pendingApprovalInPersonId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/acceptPendingApprovalInPerson',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    removeRegistrationInPerson: builder.mutation({
      query: ({ accountId, eventId, registeredAttendeeInPersonId }) => ({
        url: '/api/organize/removeRegistrationInPerson',
        method: 'PATCH',
        body: { accountId, eventId, registeredAttendeeInPersonId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeRegistrationInPerson',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    acceptPendingApprovalInPersonAll: builder.mutation({
      query: ({ accountId, eventId, pendingApprovalInPersonAll }) => ({
        url: '/api/organize/acceptPendingApprovalInPersonAll',
        method: 'PATCH',
        body: { accountId, eventId, pendingApprovalInPersonAll },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/acceptPendingApprovalInPersonAll',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    acceptPendingApprovalVirtual: builder.mutation({
      query: ({ accountId, eventId, pendingApprovalVirtualId }) => ({
        url: '/api/organize/acceptPendingApprovalVirtual',
        method: 'PATCH',
        body: { accountId, eventId, pendingApprovalVirtualId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/acceptPendingApprovalVirtual',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    removeRegistrationVirtual: builder.mutation({
      query: ({ accountId, eventId, registeredAttendeeVirtualId }) => ({
        url: '/api/organize/removeRegistrationVirtual',
        method: 'PATCH',
        body: { accountId, eventId, registeredAttendeeVirtualId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeRegistrationVirtual',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
  }),
});

export const {
  useMyOrganizedEventsQuery,
  useLazyMyOrganizedEventsQuery,
  useMyOrganizedScheduledEventsQuery,
  useLazyMyOrganizedScheduledEventsQuery,
  useMyOrganizedArchivedEventsQuery,
  useLazyMyOrganizedArchivedEventsQuery,
  useAcceptPendingApprovalInPersonMutation,

  useRemoveRegistrationInPersonMutation,
  useAcceptPendingApprovalInPersonAllMutation,
  useCreateEventMutation,
  useUpdateEventMutation,

  useRemoveEventActiveMutation,
  useRemoveEventScheduledMutation,
  useAcceptPendingApprovalVirtualMutation,
  useRemoveRegistrationVirtualMutation,
  useRemoveArchivedEventOrganizerMutation,

  useRemoveEventActiveUpdateAccountsMutation,
  useRemoveEventScheduledUpdateAccountsMutation,
  useRemoveEventArchivedUpdateAccountsMutation,
} = organizedEventsApiSlice;
