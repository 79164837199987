import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  //called upon error; updates state
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  //called upon error, runs code; can log error to server here
  componentDidCatch(error, info) {
    console.log('ErrorBoundary caught an error', error, info);
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
