import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useUpdateAccountMutation } from '../../accountApiSlice';
import useApiStatus from '../../../../../context/useApiStatus';

//components
import LoadingScreenContent from '../../../../../components/LoadingScreenContent/LoadingScreenContent';

//utils
import { axiosLimited } from '../../../../../axios/axios';
import { retryWrapper } from '../../../../../utils/logic/retryFns';

const BillingStripeConfirmation = () => {
  //hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { handleError } = useApiStatus();
  const {
    accountBillingPaymentMethodIds,
    accountBilling,
    accountBillingCustomerId: customerId,
    accountId,
  } = useAccountReduxHandlers();

  //api
  const [updateAccount] = useUpdateAccountMutation();

  //state
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //variables
  const initializeStripeEffectRan = useRef(false);
  const initializeCompleteConfirmation = useRef(false);

  //initialize
  useEffect(() => {
    if (initializeStripeEffectRan.current === false) {
      initializeStripeEffectRan.current = true;
      if (process.env.REACT_APP_ENV === 'development') {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)
        );
      } else {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        );
      }
    }
  }, []); //load stripe promise

  useEffect(() => {
    if (stripePromise) {
      if (initializeCompleteConfirmation.current === false) {
        initializeCompleteConfirmation.current = true;

        const queryParams = new URLSearchParams(location.search);

        const setup_intent = queryParams.get('setup_intent');
        const setupIntentClientSecret = queryParams.get(
          'setup_intent_client_secret'
        );
        const redirectStatus = queryParams.get('redirect_status');
        const saveAsPrimaryCard = queryParams.get('saveAsPrimaryCard');

        if (
          setup_intent &&
          setupIntentClientSecret &&
          redirectStatus === 'succeeded'
        ) {
          handlePaymentCompletion(setup_intent, saveAsPrimaryCard);
        } else {
          navigate('/account');
        }
      }
    }
  }, [location.search, stripePromise]);

  async function handlePaymentCompletion(setup_intent, saveAsPrimaryCard) {
    try {
      //verification of the setup intent
      async function createVerifySetupIntent() {
        try {
          const verifySetupIntent = await axiosLimited.post(
            `/api/stripe/verifySetupIntent`,
            { setup_intent }
          );
          return verifySetupIntent;
        } catch (error) {
          throw error;
        }
      }

      const verifySetupIntent = await retryWrapper(createVerifySetupIntent);

      const { setupIntent } = verifySetupIntent.data;
      if (!setupIntent) throw new Error();

      //Payment method
      const paymentMethodId = setupIntent?.payment_method;
      if (!paymentMethodId) throw new Error('No Payment Method');

      if (!customerId) {
        let newCustomerId;

        async function createCustomer() {
          try {
            const createCustomerResponse = await axiosLimited.post(
              '/api/stripe/createCustomer',
              {
                accountId,
                paymentMethodId,
              }
            );
            return createCustomerResponse;
          } catch (error) {
            throw error;
          }
        }

        const createCustomerResponse = await retryWrapper(createCustomer);

        newCustomerId = createCustomerResponse?.data?.customerId;
        if (!newCustomerId) throw new Error('No customer Id.');

        await updateAccount({
          accountId,
          newData: {
            billing: {
              ...accountBilling,
              customerId: newCustomerId,
              paymentMethodIds: [paymentMethodId],
            },
          },
          successMessage: true,
        });
      } else {
        //save payment method id to account
        async function attachPaymentMethod() {
          try {
            await axiosLimited.post('/api/stripe/attachPaymentMethod', {
              accountId,
              paymentMethodId: paymentMethodId,
            });
          } catch (error) {
            throw error;
          }
        }
        await retryWrapper(attachPaymentMethod);

        await updateAccount({
          accountId,
          newData: {
            billing: {
              ...accountBilling,
              paymentMethodIds: [
                ...accountBillingPaymentMethodIds,
                paymentMethodId,
              ],
            },
          },
          successMessage: true,
        });

        if (saveAsPrimaryCard === 'true') {
          async function setPrimaryPaymentMethod() {
            try {
              await axiosLimited.post('/api/stripe/setPrimaryPaymentMethod', {
                accountId,
                newPrimaryPaymentMethodId: paymentMethodId,
              });
            } catch (error) {
              throw error;
            }
          }
          await retryWrapper(setPrimaryPaymentMethod);
        }
      }
      setIsLoading(false);
      navigate('/account');
    } catch (error) {
      handleError({
        message: error,
        id: Date.now(),
        origin: 'BillingStripeConfirmation.js/handlePaymentCompletion',
      });
      navigate('/account');
    }
  }

  return (
    <LoadingScreenContent
      numberOfLoadChecks={1}
      loadCheck1={!isLoading}
      customWrapper={'min-full-height'}
    />
  );
};

export default BillingStripeConfirmation;
