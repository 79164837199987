import { useEffect, useState } from 'react';

//Hooks
import useSettings from '../../../../../context/useSettings';
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import useLanguageData from '../../../../../language/useLanguageData';
import { useRegistrationHandlers } from '../../useRegistrationHandlers';

//Components
import SelectDropdownTimezones from '../../../../../components/SelectDropdown/SelectDropdownTimezones';
import TextArea from '../../../../../components/TextArea/TextArea';
import PhoneInput from '../../../../../components/TextArea/PhoneInput';
import PasswordConfirmInput from '../../../../../components/TextArea/PasswordConfirmInput';
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import NavigationArrows from '../../../../../components/NavigationArrows/NavigationArrows';
import EmailInput from '../../../../../components/TextArea/EmailInput';
import CreatePasswordInput from '../../../../../components/TextArea/CreatePasswordInput';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import { checkmark_green } from '../../../../../assets/icons';

//Utility
const PersonalAccountInfo = ({
  croppedImg,
  setCroppedImg,
  HOCrop,
  HOZoom,
  setHOCrop,
  setHOZoom,
}) => {
  //Hooks
  const { PersonalAccountInfo } = useLanguageRegistration();
  const { LanguageOpts } = useLanguageData();
  const { Generic } = useLanguageComponents();
  const { width } = useSettings();
  const {
    registrationPersonalContentLanguage: contentLanguage,
    registrationPersonalCountryCode: countryCode,
    registrationPersonalEmail: email,
    registrationPersonalFirstName: firstName,
    registrationPersonalLastName: lastName,
    registrationPersonalPassword: password,
    registrationPersonalPasswordConfirm: passwordConfirm,
    registrationPersonalPhoneNumber: phoneNumber,
    registrationPersonalPrefix: prefix,
    registrationPersonalPronouns: pronouns,
    registrationPersonalTimeZone: timeZone,
    handleRegistrationSetPersonalContentLanguage: setContentLanguage,
    handleRegistrationSetPersonalCountryCode: setCountryCode,
    handleRegistrationSetPersonalEmail: setEmail,
    handleRegistrationSetPersonalFirstName: setFirstName,
    handleRegistrationSetPersonalLastName: setLastName,
    handleRegistrationSetPersonalPassword: setPassword,
    handleRegistrationSetPersonalPasswordConfirm: setPasswordConfirm,
    handleRegistrationSetPersonalPhoneNumber: setPhoneNumber,
    handleRegistrationSetPersonalPrefix: setPrefix,
    handleRegistrationSetPersonalPronouns: setPronouns,
    handleRegistrationSetPersonalTimeZone: setTimeZone,
    handleRegistrationPageDecrease,
    handleRegistrationPageIncrease,
    registrationPage,
    handleRegistrationPageCompletionStatus,
  } = useRegistrationHandlers();

  //state
  const [continueBtnCheck, setContinueBtnCheck] = useState(false);
  const [phoneNumberSaveValid, setPhoneNumberSaveValid] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [newPasswordValid, setNewPasswordValid] = useState(false);

  useEffect(() => {
    if (
      email &&
      validEmail &&
      newPasswordValid &&
      passwordsMatch &&
      firstName &&
      lastName &&
      (phoneNumberSaveValid || (!countryCode && !phoneNumber)) &&
      contentLanguage &&
      timeZone
    ) {
      setContinueBtnCheck(true);
    } else {
      setContinueBtnCheck(false);
    }
    /*eslint-disable-next-line*/
  }, [
    email,
    validEmail,
    newPasswordValid,
    passwordsMatch,
    firstName,
    lastName,
    contentLanguage,
    timeZone,
    countryCode,
    phoneNumber,
    phoneNumberSaveValid,
    registrationPage,
  ]); //page continue check

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'development') {
      let randomNumber = Math.floor(100 + Math.random() * 900);
      setEmail(`rt${randomNumber}@test.ca`);
      setFirstName('Rye');
      setLastName('Tar');
      setPassword('12345ABCabc!');
      setPasswordConfirm('12345ABCabc!');
      setContentLanguage('en-CA');
      setTimeZone('canadaEasternTime');
      setValidEmail(true);
      setPasswordsMatch(true);
      setNewPasswordValid(true);
    }
  }, []);

  function handleProfileImg(img) {
    let newImg = {};
    newImg = {
      croppedImage: img.croppedImage,
      fileName: img.fileName,
      imagePreview: img.imagePreview,
    };
    setCroppedImg(newImg);
  }

  return (
    <>
      <label
        aria-label={PersonalAccountInfo.ariaAccount}
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="accountLabel"
        tabIndex="0"
      >
        {PersonalAccountInfo.accountLabel}

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">{Generic.allRequired}</p>
          {email &&
            validEmail &&
            password &&
            passwordConfirm &&
            passwordsMatch &&
            newPasswordValid && (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            )}
        </div>
      </label>

      <EmailInput
        asterisk={true}
        handleInput={setEmail}
        id={'email'}
        emailCollectionToCheck={'Account'}
        setSaveValid={setValidEmail}
        checkRanAfterBlurToggle={true}
        allowSaveIfMatchingLoadValue={false}
        customWrapper={'mrg-t24'}
        loadValue={email}
      />
      <CreatePasswordInput
        id={'newPassword'}
        asterisk={true}
        handleInput={setPassword}
        setValidPassword={setNewPasswordValid}
        autocompleteOff={true}
        customWrapper={'mrg-t24'}
        loadValue={password}
      />
      <div className="h24" />
      <PasswordConfirmInput
        asterisk={true}
        handleInput={setPasswordConfirm}
        id={'confirmNewPassword'}
        newPassword={password}
        passwordsMatch={passwordsMatch}
        passwordConfirm={passwordConfirm}
        setPasswordsMatch={setPasswordsMatch}
        loadValue={passwordConfirm}
      />
      <div className="h36" />

      <label
        aria-label={PersonalAccountInfo.ariaName}
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="nameLabel"
        tabIndex="0"
      >
        {PersonalAccountInfo.nameLabel}

        <div className="flex-row mrg-auto-left">
          <p className="fs14">{Generic.someRequired}</p>
          {firstName && lastName && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>
      <div className="flex-row flex-column--ph mrg-t24">
        <TextArea
          customWidth={'108px'}
          disableEnter={true}
          handleChange={setPrefix}
          hideWordCount={true}
          id={'prefix'}
          labelText={PersonalAccountInfo.prefix}
          limit={8}
          loadTrigger={true}
          loadValue={prefix}
          customWrapper={width >= 480 ? 'mrg-r24 mrg-b24' : 'mrg-b24'}
        />
        <TextArea
          customWidth={width > 480 ? '240px' : '100%'}
          disableEnter={true}
          handleChange={setPronouns}
          hideWordCount={true}
          id={'pronouns'}
          labelText={PersonalAccountInfo.pronouns}
          limit={20}
          loadTrigger={true}
          loadValue={pronouns}
          customWrapper={'mrg-b24'}
        />
      </div>

      <TextArea
        asterisk={true}
        disableEnter={true}
        handleChange={setFirstName}
        hideWordCount={true}
        id={'firstName'}
        labelText={PersonalAccountInfo.firstName}
        limit={50}
        loadTrigger={true}
        loadValue={firstName}
        onBlurHandlerOff={true}
      />
      <TextArea
        asterisk={true}
        disableEnter={true}
        handleChange={setLastName}
        hideWordCount={true}
        id={'lastName'}
        labelText={PersonalAccountInfo.lastName}
        limit={50}
        loadTrigger={true}
        loadValue={lastName}
        onBlurHandlerOff={true}
        customWrapper={'mrg-t24'}
      />
      <div className="h36" />

      <label
        aria-label={PersonalAccountInfo.ariaContact}
        className="content-label"
        id="contactLabel"
        tabIndex="0"
      >
        {PersonalAccountInfo.contactLabel}
      </label>
      <div className="h24" />

      <PhoneInput
        id={'accountPhoneInput'}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        saveValid={phoneNumberSaveValid}
        setSaveValid={setPhoneNumberSaveValid}
      />
      <div className="h36" />
      <label
        aria-label={PersonalAccountInfo.ariaAppSettings}
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="appSettingsLabel"
        tabIndex="0"
      >
        {PersonalAccountInfo.appSettingsLabel}
        <div className="flex-row mrg-auto-left">
          <p className="fs14">{Generic.allRequired}</p>
          {contentLanguage && timeZone && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>
      <div className="h24" />

      <SelectDropdown
        asterisk={true}
        defaultKey={contentLanguage ? contentLanguage : null}
        handleSelection={setContentLanguage}
        id={'contentLanguage'}
        internalLabel={PersonalAccountInfo.appLanguage}
        list={LanguageOpts}
        fullWidth={true}
      />
      <div className="h24" />
      <SelectDropdownTimezones
        id={'timezone'}
        handleSelection={setTimeZone}
        defaultValue={timeZone ? timeZone : null}
        fullWidth={true}
      />

      <div className="h36" />

      <label
        aria-label={PersonalAccountInfo.ariaProfilePicture}
        className="content-label"
        id="profilePictureLabel"
        tabIndex="0"
      >
        {PersonalAccountInfo.profilePictureLabel}
      </label>
      <div className="h24" />
      <div className="flex-center">
        <ImageCropper
          ariaImageDescription={PersonalAccountInfo.ariaImageCropperDescription}
          cover={'horizontal-cover'}
          defaultImageClassName={'speakerImgDefault'}
          handleImage={handleProfileImg}
          height={264}
          HOCrop={HOCrop}
          HOZoom={HOZoom}
          id={'profile'}
          imagePreview={croppedImg.imagePreview}
          profileFrame={true}
          setHOCrop={setHOCrop}
          setHOZoom={setHOZoom}
          width={264}
        />
      </div>

      <div className="h24" />

      <NavigationArrows
        continueBtnCheck={continueBtnCheck}
        handlePageDecrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageDecrease();
        }}
        handlePageIncrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageIncrease(registrationPage);
        }}
      />
    </>
  );
};

export default PersonalAccountInfo;
