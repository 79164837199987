import { useEffect, useState } from 'react';

//Hooks
import useLanguageEvents from '../../language/features/useLanguageEvents';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';

//Components

//Utility
import { img_building3, img_virtual } from '../../assets/icons';

const EventStatusBar = ({ eventData, inPerson, virtual }) => {
  //Hooks
  const {
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  } = useAccountReduxHandlers();
  const { EventCard } = useLanguageEvents();

  //Component State
  const [inPersonStatus, setInPersonStatus] = useState();
  const [virtualStatus, setVirtualStatus] = useState();

  //Component Variables
  let content;

  useEffect(() => {
    handleInPersonEventStatus();
    handleVirtualEventStatus();
  }, [
    eventData.registrationData,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]);

  function handleInPersonEventStatus() {
    if (eventData.registrationData.registrationsActive && inPerson) {
      if (accountRegistrationsInPerson.includes(eventData._id)) {
        return setInPersonStatus('registered');
      }

      if (accountPendingApprovalInPerson.includes(eventData._id)) {
        return setInPersonStatus('pendingApproval');
      }

      if (
        eventData.registrationData?.registeredAttendeesInPerson?.length >=
          eventData.registrationData?.maxRegistrationsInPersonAmount &&
        eventData.registrationData?.maxRegistrationsInPersonEnabled
      ) {
        return setInPersonStatus('full');
      }

      if (
        eventData.registrationData?.maxRegistrationsInPersonAmount / 2 <=
          eventData.registrationData?.registeredAttendeesInPerson?.length &&
        eventData.registrationData?.maxRegistrationsInPersonEnabled
      ) {
        return setInPersonStatus('almostFull');
      }

      return setInPersonStatus('open');
    }
  }

  function handleVirtualEventStatus() {
    if (eventData.registrationData.registrationsActive && virtual) {
      if (accountRegistrationsVirtual.includes(eventData._id)) {
        return setVirtualStatus('registered');
      }

      if (accountPendingApprovalVirtual.includes(eventData._id)) {
        return setVirtualStatus('pendingApproval');
      }

      if (
        eventData.registrationData?.registeredAttendeesVirtual?.length >=
          eventData.registrationData?.maxRegistrationsVirtualAmount &&
        eventData.registrationData?.maxRegistrationsVirtualEnabled
      ) {
        return setVirtualStatus('full');
      }

      if (
        eventData.registrationData?.maxRegistrationsVirtualAmount / 2 <=
          eventData.registrationData?.registeredAttendeesVirtual?.length &&
        eventData.registrationData?.maxRegistrationsVirtualEnabled
      ) {
        return setVirtualStatus('almostFull');
      }

      return setVirtualStatus('open');
    }
  }

  if (
    inPersonStatus === 'pendingApproval' ||
    virtualStatus === 'pendingApproval'
  ) {
    content = (
      <div
        className="status-bar status-color-pending access-ob access-o6"
        tabIndex="0"
      >
        <img
          src={inPerson ? img_building3 : img_virtual}
          className="filter-registration-pending mrg-r4"
          alt={inPerson ? EventCard.inPersonIcon : EventCard.virtualIcon}
        />
        {EventCard.pendingApproval}
      </div>
    );
  }

  if (inPersonStatus === 'registered' || virtualStatus === 'registered') {
    content = (
      <div
        className="status-bar status-color-registered access-ob access-o6"
        tabIndex="0"
      >
        <img
          src={inPerson ? img_building3 : img_virtual}
          className="filter-registration-registered mrg-r4"
          alt={inPerson ? EventCard.inPersonIcon : EventCard.virtualIcon}
        />
        {EventCard.registered}
      </div>
    );
  }

  if (inPersonStatus === 'almostFull' || virtualStatus === 'almostFull') {
    content = (
      <div
        className="status-bar status-color-almost-full access-ob access-o6"
        tabIndex="0"
      >
        <img
          src={inPerson ? img_building3 : img_virtual}
          className="filter-registration-almost-full mrg-r4"
          alt={inPerson ? EventCard.inPersonIcon : EventCard.virtualIcon}
        />
        {EventCard.almostFull}
      </div>
    );
  }

  if (inPersonStatus === 'full' || virtualStatus === 'full') {
    content = (
      <div
        className="status-bar status-color-full access-ob access-o6"
        tabIndex="0"
      >
        <img
          src={inPerson ? img_building3 : img_virtual}
          className="filter-registration-full mrg-r4"
          alt={inPerson ? EventCard.inPersonIcon : EventCard.virtualIcon}
        />
        {EventCard.full}
      </div>
    );
  }

  if (inPersonStatus === 'open' || virtualStatus === 'open') {
    content = (
      <div
        className="status-bar status-color-registration-open access-ob access-o6"
        tabIndex="0"
      >
        <img
          src={inPerson ? img_building3 : img_virtual}
          className="filter-registration-open mrg-r4"
          alt={inPerson ? EventCard.inPersonIcon : EventCard.virtualIcon}
        />
        {EventCard.open}
      </div>
    );
  }

  return content;
};

export default EventStatusBar;
