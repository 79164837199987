import { useEffect, useState } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useUpdateAccountFollowingMutation } from '../../features/main/account/accountApiSlice';
import useApiStatus from '../../context/useApiStatus';
import { useSettingsHandlers } from '../../features/main/settings/useSettingsHandlers';

//Components

//Utility
import {
  checkmark_green,
  eventcard_follow,
  eventcard_follow_green,
} from '../../assets/icons';

const FollowButton = ({ followableAccountId, getNewNetworkEventsList }) => {
  //Hooks
  const { handleError } = useApiStatus();
  const { accountFollowing, accountFollowingGroups, accountId } =
    useAccountReduxHandlers();
  const {
    handleSettingsSetSelectedAccountsFollowingIds,
    handleSettingsSetSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();
  const { handleMobileTap, isTouchDevice } = useSettings();
  const { FollowButton } = useLanguageComponents();
  const [
    updateAccountFollowing,
    {
      isLoading: updateAccountFollowingIsLoading,
      isFetching: updateAccountFollowingIsFetching,
    },
  ] = useUpdateAccountFollowingMutation();

  //Component state
  const [following, setFollowing] = useState(false);

  //UI state
  const [hoverHighlightFollow, setHoverHighlightFollow] = useState(false);
  const [tapHighlightFollow, setTapHighlightFollow] = useState(false);

  //Component variables
  let followBtn;

  //Initialize functions
  useEffect(() => {
    if (accountFollowing?.length > 0 || accountFollowingGroups?.length > 0) {
      if (
        accountFollowing?.some(
          (account) => account._id === followableAccountId
        ) ||
        accountFollowingGroups?.some((group) =>
          group.following.some(
            (following) => following._id === followableAccountId
          )
        )
      ) {
        setFollowing(true);
      } else {
        setFollowing(false);
      }
    } else {
      setFollowing(false);
    }
  }, [accountFollowing, accountFollowingGroups]);

  //API functions
  async function handleAccountFollow() {
    if (!updateAccountFollowingIsLoading && !updateAccountFollowingIsFetching) {
      try {
        const res = await updateAccountFollowing({
          accountId,
          followingId: followableAccountId,
        });

        let updatedAccountsFollowingIds = [];
        let updatedAccountsFollowingGroupIds = [];

        res.data.network.following.map((following) =>
          updatedAccountsFollowingIds.push(following?._id)
        );

        handleSettingsSetSelectedAccountsFollowingIds(
          updatedAccountsFollowingIds
        );

        res.data.network.followingGroups.map((group) =>
          updatedAccountsFollowingGroupIds.push(group?.id)
        );

        handleSettingsSetSelectedAccountsFollowingGroupIds(
          updatedAccountsFollowingGroupIds
        );

        if (getNewNetworkEventsList) {
          getNewNetworkEventsList(
            updatedAccountsFollowingIds,
            updatedAccountsFollowingGroupIds
          );
        }
      } catch (error) {
        const newError = {
          error,
          id: Date.now(),
          origin: 'FollowButton.js/handleAccountFollow',
        };
        handleError(newError);
      }
    }
  }

  //component functions
  function handleFollowClickWithDelay() {
    let timeout = null;
    timeout = setTimeout(() => {
      handleAccountFollow();
    }, 300);
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }

  //JSX Conditional
  if (accountId !== followableAccountId && followableAccountId) {
    followBtn = (
      <button
        className={`follow-btn highlight-tb-theme border-reg ${
          tapHighlightFollow ? 'highlight-tb-theme--tap click-scaledown-95' : ''
        } ${following ? 'border-green' : ''}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightFollow(true),
              () => setTapHighlightFollow(false),
            ],
            [() => handleFollowClickWithDelay()],
            true
          )
        }
        title={
          following ? FollowButton.clickToUnfollow : FollowButton.clickToFollow
        }
        onFocus={!isTouchDevice ? () => setHoverHighlightFollow(true) : null}
        onBlur={!isTouchDevice ? () => setHoverHighlightFollow(false) : null}
        onMouseEnter={
          !isTouchDevice ? () => setHoverHighlightFollow(true) : null
        }
        onMouseLeave={
          !isTouchDevice ? () => setHoverHighlightFollow(false) : null
        }
      >
        {following ? (
          <>
            <img
              src={eventcard_follow_green}
              alt=""
              className="follow-icon mrg-r6"
            />
            <img src={checkmark_green} alt={FollowButton.checkmark} />
          </>
        ) : (
          <>
            <img
              src={eventcard_follow}
              alt=""
              className={`follow-icon  mrg-r6 ${
                following ? 'filter-green-light' : 'filter-gray'
              } ${
                !following && (hoverHighlightFollow || tapHighlightFollow)
                  ? 'filter-theme'
                  : ''
              }`}
            />
            <p>{FollowButton.follow}</p>
          </>
        )}
      </button>
    );
  }

  return followBtn;
};

export default FollowButton;
