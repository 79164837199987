export const appearance = {
  variables: {
    fontSizeBase: '20px',
    fontFamily: 'Open Sans, sans-serif',
    borderRadius: '4px',
    colorText: '#333',
  },
  rules: {
    '.Input': {
      boxShadow: '',
      border: '1px solid rgba(0,0,0,0.35)',
    },
    '.Input:hover': {
      border: '1px solid #f67512',
      boxShadow: '',
    },
    '.Input:focus': {
      border: '1px solid #f67512',
      boxShadow: '',
      outline: '1px solid #f67512',
    },
  },
};
