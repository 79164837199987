//Hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//Components
import ProfilePhoto from './ProfilePhoto';
import Name from './Name';
import Password from './Password';
import Email from './Email';
import Phone from './Phone';
// import Badges from './Badges';
// import SocialLinks from './SocialLinks';

//Utility
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';

const AccountContent = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { Account } = useLanguageAccount();
  const { accountAccountReference } = useAccountReduxHandlers();

  return (
    <div className="account-content" id="account-content">
      <label className="account-code access-ob access-o6" tabIndex="0">
        {Account.account.accountCode} #{accountAccountReference}
      </label>

      <ProfilePhoto Account={Account} />

      <Email Account={Account} />
      <Name
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />
      <Password Account={Account} saveAccountChange={saveAccountChange} />

      <Phone
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />

      <ScrollToTop focusId={'profilePhotoLabel'} />
      <div className="h48" />
    </div>
  );
};

export default AccountContent;
