import { useState, useEffect, useRef } from 'react';
//Hooks
import useSettings from '../../context/useSettings';

//Components
import WhatsThis from '../WhatsThis/WhatsThis';

//Utility

const Selections = ({
  id,
  options,
  singleColumn,
  handleSelection,
  whatsThis,
  whatsThisType,
  whatsThisDescriptions,
  loadTrigger,
  loadValue,
  customWrapper,
}) => {
  //Component state
  const [selectedOption, setSelectedOption] = useState(null);

  //UI state
  const { handleMobileTap } = useSettings();
  const [tapHighlight, setTapHighlight] = useState();

  //Component variables
  const effectRan = useRef(false);
  let whatsThisSelectedDescription;

  //Initialize functions
  useEffect(() => {
    if (loadTrigger) {
      if (effectRan.current === false) {
        if (loadValue) {
          setSelectedOption(loadValue);
        }
        effectRan.current = true;
      }
    }
    /*eslint-disable-next-line*/
  }, [loadTrigger]);

  if (whatsThisDescriptions) {
    Object.entries(whatsThisDescriptions).map(([key, value]) => {
      if (key === selectedOption) {
        return (whatsThisSelectedDescription = value);
      }
    });
  }

  //Component functions
  function setOption(selectionKey) {
    if (selectionKey === selectedOption) {
      setSelectedOption();
      handleSelection();
    } else {
      setSelectedOption(selectionKey);
      handleSelection(selectionKey);
    }
  }

  const selections = Object.entries(options).map(([key, value], index) => (
    <li className="selections__container" key={`${id}-${key}`}>
      <button
        id={key}
        type="button"
        className={`fs16 fwsb mrg-b24 access-ob access-o6 ${
          selectedOption === key ? 'selection item-active' : 'selection'
        } ${tapHighlight === index ? 'highlight-tb-theme--tap' : ''}`}
        key={index}
        onClick={() =>
          handleMobileTap(
            [() => setTapHighlight(index), () => setTapHighlight()],
            [() => setOption(key)]
          )
        }
        aria-pressed={selectedOption === key}
      >
        {value}
      </button>
    </li>
  ));

  return (
    <div id={`Selections-${id}`}>
      <ul
        className={`selection__wrapper
          ${singleColumn ? 'single-column' : ''} ${
          customWrapper ? customWrapper : ''
        }
        `}
      >
        {selections}
      </ul>
      {whatsThis && selectedOption && (
        <WhatsThis
          type={whatsThisType}
          description={whatsThisSelectedDescription}
        />
      )}
    </div>
  );
};

export default Selections;
