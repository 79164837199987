const InfoCardContainerArchive = ({ archiveData }) => {
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Archive</p>
      <div className="mrg-t6">Path: {archiveData?.archivePath}</div>
      <div className="mrg-t6">
        Admin Reference: [{archiveData?.adminReference}]
      </div>
      <div className="mrg-t6">
        Notes:
        {archiveData?.notes?.length > 0 ? (
          <ul className="mrg-l24">
            {archiveData?.notes?.map((n, index) => {
              if (n?.length > 0) {
                return (
                  <li className="mrg-t6" key={`archiveData-notes-${index}`}>
                    {' '}
                    {n}
                  </li>
                );
              }
            })}
          </ul>
        ) : (
          ' N/A'
        )}
      </div>

      <div className="h12" />
    </div>
  );
};

export default InfoCardContainerArchive;
