import { useEffect, useState } from 'react';

//Hooks
import useGenericModal from '../../../context/useGenericModal';
import useSettings from '../../../context/useSettings';
import useLanguageComponents from '../../../language/useLanguageComponents';
//Components

//Utility
import { cancel } from '../../../assets/icons';

const AttendeeAccountViewModal = ({ accountObj, focusId }) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (focusId) {
      setCloseModalFocusId(`${focusId}`);
    }
  }, []); //set id for focus when modal closes

  useEffect(() => {
    const floatModalEl = document.getElementById(`float-modal`);
    if (floatModalEl) {
      floatModalEl.focus();
    }
  }, []);

  return (
    <div
      className="float-modal access-ow access-o12"
      tabIndex="0"
      id="float-modal"
    >
      <button
        className={`close-btn highlight-i-lgr access-ob access-o6 mrg-auto-left full-width 
        ${tapHighlightCloseBtn ? 'highlight-i-lgr--tap' : ''}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightCloseBtn(true),
              () => setTapHighlightCloseBtn(false),
            ],
            [() => handleCloseModal()]
          )
        }
        type="button"
        title={Generic.close}
      >
        <img src={cancel} alt={Generic.close} className="svg" />
      </button>
    </div>
  );
};

export default AttendeeAccountViewModal;
