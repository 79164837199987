//Hooks

//Components
// import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import OrganizerRoleToggle from './OrganizerRoleToggle';

//Utility

const OrganizerContent = () => {
  //Hooks

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      <OrganizerRoleToggle />
      <div className="h48" />
    </div>
  );
};

export default OrganizerContent;
