//hooks
import { useEffect, useState } from 'react';

//components

//utils
import { getRandomColor } from '../../../../../../utils/UI/randomColorCodes';
import { dropdown_arrow } from '../../../../../../assets/icons';

const InfoCardContainerNetwork = ({ networkData }) => {
  const [openFollowedBy, setOpenFollowedBy] = useState(false);
  const [openFollowing, setOpenFollowing] = useState(false);
  const [openFollowingGroups, setOpenFollowingGroups] = useState(false);

  //UI
  const [handleColorsFg, setHandleColorsFg] = useState([]);
  const [handleColorsFgSub, setHandleColorsFgSub] = useState([]);

  const [handleColorsFollowedBy, setHandleColorsFollowedBy] = useState([]);
  const [handleColorsFollowing, setHandleColorsFollowing] = useState([]);

  //initialize
  useEffect(() => {
    if (networkData?.followingGroups) {
      let colorArr = [];

      setHandleColorsFgSub([]);

      networkData.followingGroups?.forEach((tG) => {
        colorArr.push(getRandomColor());
        let colorArrSub = [];

        tG?.following?.forEach((_) => colorArrSub.push(getRandomColor()));
        setHandleColorsFgSub([...handleColorsFgSub, colorArrSub]);
      });

      setHandleColorsFg(colorArr);
    }
  }, [networkData?.followingGroups]);

  useEffect(() => {
    if (networkData?.following) {
      let colorArr = [];
      networkData?.following.map((_) => colorArr.push(getRandomColor()));
      setHandleColorsFollowing(colorArr);
    }
  }, [networkData?.following]);

  useEffect(() => {
    if (networkData?.followedBy) {
      let colorArr = [];
      networkData?.followedBy.map((_) => colorArr.push(getRandomColor()));
      setHandleColorsFollowedBy(colorArr);
    }
  }, [networkData?.followedBy]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Network</p>

      <div className="flex-row">
        <p className="fs18 fwsb">
          Followed By ({networkData.followedBy?.length})
        </p>
        {networkData.followedBy?.length > 0 && (
          <button
            className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
            style={{ height: '24px' }}
            onClick={() => {
              setOpenFollowedBy(!openFollowedBy);
            }}
          >
            <img
              src={dropdown_arrow}
              alt="arrow"
              style={{ height: '10px' }}
              className={`${
                openFollowedBy === false ? 'reverse-vertical' : ''
              }`}
            />
          </button>
        )}
      </div>
      <ul className="info-card-list">
        {openFollowedBy &&
          networkData.followedBy?.map((acct, index) => {
            return (
              <li className="mrg-tb6" key={`netfollowby-${index}`}>
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColorsFollowedBy[index] }}
                />
                <div className="flex-column">
                  <p>
                    Name: {acct?.personal?.firstName} {acct?.personal?.lastName}
                  </p>
                  <p>Account Reference: [{acct?.meta?.accountReference}]</p>
                </div>
              </li>
            );
          })}
      </ul>
      <div className="flex-row">
        <p className="fs18 fwsb">Following ({networkData.following?.length})</p>
        {networkData.following?.length > 0 && (
          <button
            className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
            style={{ height: '24px' }}
            onClick={() => {
              setOpenFollowing(!openFollowing);
            }}
          >
            <img
              src={dropdown_arrow}
              alt="arrow"
              style={{ height: '10px' }}
              className={`${openFollowing === false ? 'reverse-vertical' : ''}`}
            />
          </button>
        )}
      </div>
      <ul className="info-card-list">
        {openFollowing &&
          networkData.following?.map((acct, index) => {
            return (
              <li className="mrg-tb6" key={`netfol-${index}`}>
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColorsFollowing[index] }}
                />

                <div className="flex-column">
                  <p>
                    Name: {acct?.personal?.firstName} {acct?.personal?.lastName}
                  </p>
                  <p>Account Reference: [{acct?.meta?.accountReference}]</p>
                </div>
              </li>
            );
          })}
      </ul>

      <div className="flex-row">
        <p className="fs18 fwsb">
          Following Groups ({networkData.followingGroups?.length})
        </p>
        {networkData.followingGroups?.length > 0 && (
          <button
            className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
            style={{ height: '24px' }}
            onClick={() => {
              setOpenFollowingGroups(!openFollowingGroups);
            }}
          >
            <img
              src={dropdown_arrow}
              alt="arrow"
              style={{ height: '10px' }}
              className={`${openFollowing === false ? 'reverse-vertical' : ''}`}
            />
          </button>
        )}
      </div>
      <ul className="info-card-list">
        {openFollowingGroups &&
          networkData.followingGroups?.map((acctGroup, index) => {
            const handleColors = handleColorsFgSub[index];
            return (
              <li className="mrg-tb6" key={`netfol-${index}`}>
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColorsFg[index] }}
                />
                <p className="fs18 fwsb">
                  {acctGroup?.label} ({acctGroup?.following?.length}):
                </p>
                {acctGroup?.following?.map((acct, index2) => {
                  return (
                    <div
                      className="flex-column pos-rel mrg-l24 mrg-tb6"
                      key={`${acctGroup?.id}-${index2}`}
                    >
                      <div
                        className="list-handle"
                        style={{
                          backgroundColor: handleColors[index2],
                        }}
                      />
                      <p>
                        Name: {acct?.personal?.firstName}{' '}
                        {acct?.personal?.lastName}
                      </p>
                      <p>Account Reference: [{acct?.meta?.accountReference}]</p>
                    </div>
                  );
                })}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default InfoCardContainerNetwork;
