import { useEffect, useState } from 'react';

//Hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useSettings from '../../../../../context/useSettings';
import { useAuthReduxHandlers } from '../../../auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//Components
import AttendeeRoleToggle from './AttendeeRoleToggle';
import AttendeeSignUp from './AttendeeSignUp';
import BigTags from './BigTags';
import IgnoredEvents from './IgnoredEvents';
import EventSearchInPerson from './EventSearchInPerson';
import SearchLanguages from './SearchLanguages';
import TinyTags from './TinyTags';
import EventSearchOnline from './EventSearchOnline';
import Network from './Network';

//Utility
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';
import LoadingScreenContent from '../../../../../components/LoadingScreenContent/LoadingScreenContent';

const AttendeeContent = ({
  saveAccountChange,
  updateAccountIsSuccess,
  loadingSaveBtnUI,
  handleViewIgnoredEvent,
  handleSaveAccountNewRoles,
  roles,
  setRoles,
  rolesChangeValid,
  rolesSignUpIsLoading,
}) => {
  //Hooks
  const { width } = useSettings();
  const { Account } = useLanguageAccount();
  const { authCurrentRoles: loadRoles } = useAuthReduxHandlers();
  const { accountBigTags } = useAccountReduxHandlers();

  //UI state
  const [rolesSignUpIsComplete, setRolesSignUpIsComplete] = useState(true);

  useEffect(() => {
    if (rolesSignUpIsLoading) {
      setRolesSignUpIsComplete(false);
    }
  }, [rolesSignUpIsLoading]);

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      <div className="section">
        {!loadRoles?.includes(process.env.REACT_APP_ATTENDEE) &&
        (accountBigTags?.length === 0 || !accountBigTags) ? (
          <AttendeeSignUp
            saveAccountChange={saveAccountChange}
            loadingSaveBtnUI={loadingSaveBtnUI}
          />
        ) : rolesSignUpIsLoading ? (
          <LoadingScreenContent
            numberOfLoadChecks={1}
            loadCheck1={true}
            customWrapper={'min-full-height mrg-t48'}
            setLoadComplete={() => setRolesSignUpIsComplete(true)}
          />
        ) : (
          rolesSignUpIsComplete && (
            <>
              <AttendeeRoleToggle
                handleSaveAccountNewRoles={handleSaveAccountNewRoles}
                setRoles={setRoles}
                roles={roles}
                rolesChangeValid={rolesChangeValid}
                loadingSaveBtnUI={loadingSaveBtnUI}
              />
              <BigTags
                Account={Account}
                saveAccountChange={saveAccountChange}
                loadingSaveBtnUI={loadingSaveBtnUI}
              />
              <TinyTags
                Account={Account}
                saveAccountChange={saveAccountChange}
                updateAccountIsSuccess={updateAccountIsSuccess}
                loadingSaveBtnUI={loadingSaveBtnUI}
              />
              <Network
                Account={Account}
                saveAccountChange={saveAccountChange}
                updateAccountIsSuccess={updateAccountIsSuccess}
                loadingSaveBtnUI={loadingSaveBtnUI}
              />
              <EventSearchInPerson
                Account={Account}
                saveAccountChange={saveAccountChange}
                loadingSaveBtnUI={loadingSaveBtnUI}
              />
              <EventSearchOnline
                Account={Account}
                saveAccountChange={saveAccountChange}
                loadingSaveBtnUI={loadingSaveBtnUI}
              />
              <SearchLanguages
                Account={Account}
                saveAccountChange={saveAccountChange}
                loadingSaveBtnUI={loadingSaveBtnUI}
              />
              <IgnoredEvents
                Account={Account}
                saveAccountChange={saveAccountChange}
                width={width}
                handleViewIgnoredEvent={handleViewIgnoredEvent}
              />
              <ScrollToTop focusId={'bigTagsLabel'} />
            </>
          )
        )}

        <div className="h48" />
      </div>
    </div>
  );
};

export default AttendeeContent;
