import { useEffect, useRef, useState } from 'react';

//hooks
import useLanguageRegistration from '../../language/features/useLanguageRegistration';
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//components

//utils
import handleRoomCodeSubscriptionValidityCheck from '../../features/main/translations/functions/handleRoomCodeSubscriptionValidityCheck';
import moveCarotToEnd from '../../utils/UI//moveCarotToEnd';
import { cancel, checkmark_green } from '../../assets/icons';

const AddTranslationRoomInput = ({
  handleEnter,
  setRoomCode,
  roomCode,
  roomCodeLabelText,
  setValid,
  reset,
  customWrapper,
  asterisk,
}) => {
  //hooks
  const { Generic } = useLanguageComponents();
  const { TranslationRoomCodeRegistration } = useLanguageRegistration();
  const { dismissKeypad } = useSettings();

  //state
  const [roomCodeValidLength, setRoomCodeValidLength] = useState(false);
  const [roomCodeValidChar, setRoomCodeValidChar] = useState(false);
  const [focusHighlight, setFocusHighlight] = useState(false);

  //variables
  const roomCodeInput = useRef();

  useEffect(() => {
    const validityObj = handleRoomCodeSubscriptionValidityCheck(roomCode);
    setRoomCodeValidLength(validityObj.roomCodeValidLength);
    setRoomCodeValidChar(validityObj.roomCodeValidChar);

    if (validityObj.roomCodeValidLength && validityObj.roomCodeValidChar) {
      setValid(true);
    } else {
      setValid(false);
    }
    /*eslist-disble-next-line*/
  }, [roomCode]);

  useEffect(() => {
    handleReset();
  }, [reset]);

  function handleReset() {
    setRoomCodeValidLength(false);
    setRoomCodeValidChar(false);
    setFocusHighlight(false);
    setValid(false);
    roomCodeInput.current.value = '';
  }

  function handleKeyDown(e) {
    if (roomCodeInput.current?.value?.length === 0) {
      return dismissKeypad(e);
    }

    if (e.key === 'Enter') {
      dismissKeypad(e);
      setFocusHighlight(false);
      handleEnter(roomCodeInput.current.value);
    }
  } //UI + add tr code

  return (
    <>
      <div
        className={`textarea__wrapper mrg-b12 ${
          customWrapper ? customWrapper : ''
        }`}
      >
        <label
          id={`roomCodeLabel`}
          className="textarea-label no-select"
          onClick={() => moveCarotToEnd(roomCodeInput.current)}
        >
          <p>
            {roomCodeLabelText}
            {asterisk && <span className="highlight">*</span>}
          </p>
        </label>

        <input
          id="roomCode"
          name="roomCode"
          type="text"
          className={`textarea ${
            focusHighlight ? 'highlight-ob-theme--active' : 'highlight-ob-theme'
          }`}
          ref={roomCodeInput}
          defaultValue=""
          onChange={(e) => setRoomCode(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
          aria-label={TranslationRoomCodeRegistration.roomCode}
          maxLength="12"
          tabIndex="0"
          enterKeyHint="enter"
          onBlur={() => setFocusHighlight(false)}
          autoComplete="off"
          onFocus={() => {
            setFocusHighlight(true);
          }}
        />
      </div>
      <ul
        className="inputfield-validation-list access-ob access-o6"
        tabIndex="0"
        aria-label={`${
          roomCodeValidChar
            ? TranslationRoomCodeRegistration.ariaCharsSuccess
            : TranslationRoomCodeRegistration.ariaCharsFail
        } 
        ${
          roomCodeValidLength
            ? TranslationRoomCodeRegistration.ariaValidLengthSuccess
            : TranslationRoomCodeRegistration.ariaValidLengthFail
        } `}
      >
        <li className="flex-row">
          {roomCodeValidChar ? (
            <img
              src={checkmark_green}
              alt={Generic.valid}
              aria-label="hidden"
            />
          ) : (
            <img
              src={cancel}
              alt={Generic.invalid}
              aria-label="hidden"
              className="filter-gray"
            />
          )}
          <p className={`${roomCodeValidChar ? 'valid' : 'color-gray'} `}>
            {TranslationRoomCodeRegistration.validCharTypes}
          </p>
        </li>
        <li className="flex-row">
          {roomCodeValidLength ? (
            <img
              src={checkmark_green}
              alt={Generic.valid}
              aria-label="hidden"
            />
          ) : (
            <img
              src={cancel}
              alt={Generic.invalid}
              aria-label="hidden"
              className="filter-gray"
            />
          )}
          <p className={`${roomCodeValidLength ? 'valid' : 'color-gray'} `}>
            {TranslationRoomCodeRegistration.validCharLength}
          </p>
        </li>
      </ul>
    </>
  );
};

export default AddTranslationRoomInput;
