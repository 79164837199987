import useSettings from '../context/useSettings';
import { useAccountReduxHandlers } from '../features/main/account/useAccountHandlers';

//when matching in different languages, use abbr + countryCode
//modifications in this file should be mirrored in the backend/server timezones.js file
//keys within the zones array must be unique for front end code(selection find functions)
const useTimezoneData = () => {
  const { contentLanguage } = useSettings();

  const TimeZoneCountriesOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        canada: {
          iana: '2023c',
          countryName: 'Canada',
          zones: [
            {
              canadaAtlanticTime: {
                abbr: 'AT',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Atlantic Time',
                iana: 'Canada/Atlantic',
              },
            },
            {
              canadaCentralTime: {
                abbr: 'CT',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Central Time',
                iana: 'Canada/Central',
              },
            },
            {
              canadaEasternTime: {
                abbr: 'ET',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Eastern Time',
                iana: 'Canada/Eastern',
              },
            },
            {
              canadaMountainTime: {
                abbr: 'MT',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Mountain Time',
                iana: 'Canada/Mountain',
              },
            },
            {
              canadaNewfoundlandTime: {
                abbr: 'NT',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Newfoundland Time',
                iana: 'Canada/Newfoundland',
              },
            },
            {
              canadaPacificTime: {
                abbr: 'PT',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Pacific Time',
                iana: 'Canada/Pacific',
              },
            },
            {
              canadaYukonTime: {
                abbr: 'YT',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Yukon Time',
                iana: 'Canada/Yukon',
              },
            },
          ],
        },
        mexico: {
          iana: '2023c',
          countryName: 'Mexico',
          zones: [
            {
              mexicoCentralTime: {
                abbr: 'CT',
                country_code: 'MX',
                countryName: 'Mexico',
                identifier: 'Central Time',
                iana: 'America/Mexico_City',
              },
            },
            {
              mexicoEasternTime: {
                abbr: 'ET',
                country_code: 'MX',
                countryName: 'Mexico',
                identifier: 'Eastern Time',
                iana: 'America/Cancun',
              },
            },
            {
              mexicoMountainTime: {
                abbr: 'MT',
                country_code: 'MX',
                countryName: 'Mexico',
                identifier: 'Mountain Time',
                iana: 'America/Hermosillo',
              },
            },
            {
              mexicoPacificTime: {
                abbr: 'PT',
                country_code: 'MX',
                countryName: 'Mexico',
                identifier: 'Pacific Time',
                iana: 'America/Tijuana',
              },
            },
          ],
        },
        unitedStates: {
          iana: '2023c',
          countryName: 'United States',
          zones: [
            {
              unitedStatesAlaskaTime: {
                abbr: 'AK',
                country_code: 'US',
                countryName: 'United States',
                identifier: 'Alaska Time',
                iana: 'US/Alaska',
              },
            },
            {
              unitedStatesCentralTime: {
                abbr: 'CT',
                country_code: 'US',
                countryName: 'United States',
                identifier: 'Central Time',
                iana: 'US/Central',
              },
            },
            {
              unitedStatesEasternTime: {
                abbr: 'ET',
                country_code: 'US',
                countryName: 'United States',
                identifier: 'Eastern Time',
                iana: 'US/Eastern',
              },
            },
            {
              unitedStatesHawaiiAleutianTime: {
                abbr: 'HAT',
                country_code: 'US',
                countryName: 'United States',
                identifier: 'Hawaii-Aleutian Time',
                iana: 'US/Hawaii',
              },
            },
            {
              unitedStatesMountainTime: {
                abbr: 'MT',
                country_code: 'US',
                countryName: 'United States',
                identifier: 'Mountain Time',
                iana: 'US/Mountain',
              },
            },
            {
              unitedStatesPacificTime: {
                abbr: 'PT',
                country_code: 'US',
                countryName: 'United States',
                identifier: 'Pacific Time',
                iana: 'US/Pacific',
              },
            },
          ],
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        canada: {
          iana: '2023c',
          countryName: 'Canada',
          zones: [
            {
              canadaAtlanticTime: {
                abbr: 'HA',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: `Heure de l'Atlantique`,
                iana: 'Canada/Atlantic',
              },
            },
            {
              canadaCentralTime: {
                abbr: 'HC',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Heure du Centre',
                iana: 'Canada/Central',
              },
            },
            {
              canadaEasternTime: {
                abbr: 'HE',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: `Heure de l'Est`,
                iana: 'Canada/Eastern',
              },
            },
            {
              canadaMountainTime: {
                abbr: 'HM',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Heure des Rocheuses',
                iana: 'Canada/Mountain',
              },
            },
            {
              canadaNewfoundlandTime: {
                abbr: 'HNT',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Heure de Terre-Neuve',
                iana: 'Canada/Newfoundland',
              },
            },
            {
              canadaPacificTime: {
                abbr: 'HP',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Heure du Pacifique',
                iana: 'Canada/Pacific',
              },
            },
            {
              canadaYukonTime: {
                abbr: 'HY',
                country_code: 'CA',
                countryName: 'Canada',
                identifier: 'Heure du Yukon',
                iana: 'Canada/Yukon',
              },
            },
          ],
        },
        mexico: {
          iana: '2023c',
          countryName: 'Mexique',
          zones: [
            {
              mexicoCentralTime: {
                abbr: 'HC',
                country_code: 'MX',
                countryName: 'Mexique',
                identifier: 'Heure du Centre',
                iana: 'America/Mexico_City',
              },
            },
            {
              mexicoEasternTime: {
                abbr: 'HE',
                country_code: 'MX',
                countryName: 'Mexique',
                identifier: `Heure de l'Est`,
                iana: 'America/Cancun',
              },
            },
            {
              mexicoMountainTime: {
                abbr: 'HM',
                country_code: 'MX',
                countryName: 'Mexique',
                identifier: 'Heure des Montagnes',
                iana: 'America/Hermosillo',
              },
            },
            {
              mexicoPacificTime: {
                abbr: 'HP',
                country_code: 'MX',
                countryName: 'Mexique',
                identifier: 'Heure du Pacifique',
                iana: 'America/Tijuana',
              },
            },
          ],
        },
        unitedStates: {
          iana: '2023c',
          countryName: 'États-Unis',
          zones: [
            {
              unitedStatesAlaskaTime: {
                abbr: 'HA',
                country_code: 'US',
                countryName: 'États-Unis',
                identifier: `Heure de l'Alaska`,
                iana: 'US/Alaska',
              },
            },
            {
              unitedStatesCentralTime: {
                abbr: 'HC',
                country_code: 'US',
                countryName: 'États-Unis',
                identifier: 'Heure du Centre',
                iana: 'US/Central',
              },
            },
            {
              unitedStatesEasternTime: {
                abbr: 'HE',
                country_code: 'US',
                countryName: 'États-Unis',
                identifier: `Heure de l'Est`,
                iana: 'US/Eastern',
              },
            },
            {
              unitedStatesHawaiiAleutianTime: {
                abbr: 'HHAT',
                country_code: 'US',
                countryName: 'États-Unis',
                identifier: `Heure d'Hawaï-Aléoutiennes`,
                iana: 'US/Hawaii',
              },
            },
            {
              unitedStatesMountainTime: {
                abbr: 'HM',
                country_code: 'US',
                countryName: 'États-Unis',
                identifier: 'Heure des Montagnes',
                iana: 'US/Mountain',
              },
            },
            {
              unitedStatesPacificTime: {
                abbr: 'HP',
                country_code: 'US',
                countryName: 'États-Unis',
                identifier: 'Heure du Pacifique',
                iana: 'US/Pacific',
              },
            },
          ],
        },
      };
    }
    return translation;
  };

  return {
    TimeZoneCountriesOpts: TimeZoneCountriesOpts(),
  };
};

export default useTimezoneData;
