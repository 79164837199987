import { useState, useRef, useEffect } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useApiStatus from '../../context/useApiStatus';
import useSettings from '../../context/useSettings';

//Components

//Utility

const ButtonSwitch = ({
  id,
  title,
  handleSwitch,
  loadTrigger,
  loadValue,
  ariaDescriptiveLabel,
  horizontal,
  temporaryDisable,
  temporaryDisableMessage,
  secondaryTrigger,
  semiboldTitle,
  customWrapper,
}) => {
  //Hooks
  const { ButtonSwitch } = useLanguageComponents();
  const { handleWarning } = useApiStatus();
  const { handleMobileTap } = useSettings();

  //Component State
  const [active, setActive] = useState(false);

  //UI state
  const [tapHighlightText, setTapHighlightText] = useState(false);
  const [animationsOn, setAnimationsOn] = useState(false);

  //Component variables
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (loadTrigger) {
      if (effectRan.current === false) {
        if (loadValue) {
          setActive(true);
        }
        effectRan.current = true;
      }
    }
    /*eslint-disable-next-line*/
  }, [loadTrigger]);

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      if (!animationsOn) {
        setAnimationsOn(true);
      }
    }, 500);

    return () => clearTimeout(animationTimer);
  }, []); //prevent the button's animations on load

  useEffect(() => {
    if (!secondaryTrigger && active) {
      setActive(false);
    }
  }, [secondaryTrigger]);

  //Component functions
  function handleClick(e) {
    e.preventDefault();

    handleMobileTap(
      [() => setTapHighlightText(true), () => setTapHighlightText(false)],
      [() => setActive(!active), () => handleSwitch(!active)]
    );
  }

  return (
    <div
      className={`btn-switch__wrapper no-select ${
        horizontal ? 'btn-switch-horizontal' : ''
      } ${customWrapper ? customWrapper : ''}`}
    >
      <button
        id={id}
        name={id}
        type="checkbox"
        className={`btn-switch access-ob access-o6 ${
          active ? ' btn-switch--active' : ''
        } ${temporaryDisable ? 'opacity-3' : ''}`}
        onClick={
          temporaryDisable
            ? () =>
                handleWarning(
                  {
                    message: temporaryDisableMessage,
                    statusCode: 500,
                  },
                  `ButtonSwitch.js/${id}`
                )
            : (e) => handleClick(e)
        }
        aria-label={ariaDescriptiveLabel ? ariaDescriptiveLabel : title}
        role="switch"
        aria-checked={active}
      >
        <div
          className={`btn-switch__circle ${
            active ? ' btn-switch__circle--active' : ''
          }`}
          style={{ transition: `${animationsOn ? '0.4s' : '0s'}` }}
        />
        {active ? (
          <p className="btn-switch__text-btn">{ButtonSwitch.yes}</p>
        ) : (
          <p className="btn-switch__text-btn">{ButtonSwitch.no}</p>
        )}
      </button>
      <p
        className={`btn-switch__description highlight-it-theme ${
          tapHighlightText ? 'highlight-it-theme--tap' : ''
        } ${semiboldTitle ? 'fwsb' : ''}`}
        onClick={
          temporaryDisable
            ? () =>
                handleWarning(
                  {
                    message: temporaryDisableMessage,
                    statusCode: 500,
                  },
                  `ButtonSwitch.js/${id}`
                )
            : (e) => handleClick(e)
        }
      >
        {title}
      </p>
    </div>
  );
};

export default ButtonSwitch;
