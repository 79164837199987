import { useState } from 'react';

//Hooks
import useGenericModal from '../../context/useGenericModal';
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Components

//Utility

//used for the background overlay for the modals. click grey area and closes modal.
const GenericModal = () => {
  //Hooks
  const { genericModal, genericModalMobileFloat, handleCloseModal } =
    useGenericModal();
  const { Generic } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //ui
  const [tapHighlightClose, setTapHighlightClose] = useState(false);

  return (
    <div className="generic-modal ">
      <div
        className={`${genericModal ? 'generic-modal__container ' : ''} ${
          genericModalMobileFloat ? 'generic-modal__container-mobilefloat' : ''
        }`}
      >
        {genericModal || genericModalMobileFloat}
      </div>
      <button
        className={`highlight-it-theme access-ow access-o-12 ${
          genericModal || genericModalMobileFloat
            ? 'generic-modal-overlay'
            : 'remove'
        } ${!tapHighlightClose ? 'color-theme' : ''}`}
        onClickCapture={() =>
          handleMobileTap(
            [
              () => setTapHighlightClose(true),
              () => setTapHighlightClose(false),
            ],
            [() => handleCloseModal()]
          )
        }
        tabIndex="0"
        aria-label={Generic.closeList}
      />
    </div>
  );
};

export default GenericModal;
