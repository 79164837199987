import { createContext, useEffect, useState } from 'react';
import useSettings from './useSettings';

const GenericModal = createContext({});

export const GenericModalProvider = ({ children }) => {
  //Hooks
  const { openSlideMenu } = useSettings();

  //Context state
  const [genericModal, setGenericModal] = useState();
  const [genericModalMobileFloat, setGenericModalMobileFloat] = useState();

  //UI state
  const [closeModalFocusId, setCloseModalFocusId] = useState();

  //UI functions
  useEffect(() => {
    if (openSlideMenu && genericModal) {
      setCloseModalFocusId();
      handleCloseModal();
    }
    /*eslint-disable-next-line*/
  }, [openSlideMenu]); //auto close BT list if slidemnu opens

  useEffect(() => {
    if (genericModal) {
      document.body.style.overflowY = 'hidden';
      return () => {
        document.body.style.overflowY = 'scroll';
      };
    }
  }, [genericModal]); //removes scroll when component mounts so theres not two scroll bars

  //Context functions
  function handleCloseModal() {
    let el;
    if (closeModalFocusId) {
      el = document.getElementById(`${closeModalFocusId}`);
    }

    if (el) {
      el.focus();
    }
    setCloseModalFocusId();
    if (genericModal) {
      setGenericModal();
    } else if (genericModalMobileFloat) {
      setGenericModalMobileFloat();
    }
  }

  return (
    <GenericModal.Provider
      value={{
        genericModal,
        setGenericModal,
        handleCloseModal,
        setCloseModalFocusId,
        genericModalMobileFloat,
        setGenericModalMobileFloat,
      }}
    >
      {children}
    </GenericModal.Provider>
  );
};

export default GenericModal;
