import { useCallback, useEffect, useRef, useState } from 'react';

//Hooks
import useGenericModal from '../../../../context/useGenericModal';
import useSettings from '../../../../context/useSettings';
import useLanguageAccount from '../../../../language/features/useLanguageAccount';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import EventStatusBarSwitch from '../../../../components/EventStatusBar/EventStatusBarSwitch';
import EventStatusBarOrganizer from '../../../../components/EventStatusBar/EventStatusBarOrganizer';
import AttendeeAccountViewModal from '../../../../components/Modal/ModalComponents/AttendeeAccountViewModal';
import ButtonOrder from '../../../../components/ButtonOrder/ButtonOrder';

//Utility
import { badge, img_arrow_order } from '../../../../assets/icons';

const RegistrationList = ({
  actionBtnTitle,
  actionHandler,
  buttonClassName,
  buttonClickClassName,
  eventId,
  eventStatusBar,
  handleMobileViewCloseBtn,
  id,
  image,
  imageClassName,
  listData,
  listTitle,
  mobileCloseBtn,
  preventAction,
  preventActionText,
  registrationData,
  transferInPersonToVirtual,
  transferListData,
  virtualEvent,
  inPersonEvent,
  maxRegistrationsAmount,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { AccountBadges } = useLanguageAccount();
  const { handleMobileTap, isTouchDevice, width } = useSettings();
  const { setGenericModal } = useGenericModal();
  //Component state
  const [sortedListData, setSortedListData] = useState(listData);
  const [sortOrder, setSortOrder] = useState('asc');

  //UI state
  const [dragging, setDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [activateTopFade, setActivateTopFade] = useState(false);
  const [activateBottomFade, setActivateBottomFade] = useState(
    listData?.length > 3 ? true : false
  );
  const [tapHighlightActionBtn, setTapHighlightActionBtn] = useState();
  const [tapHighlightExamineBtn, setTapHighlightExamineBtn] = useState();
  const [tapHighlightMobileCloseBtn, setTapHighlightMobileCloseBtn] =
    useState(false);

  //Component variables
  const registrationListScroll = useRef(null);
  const regListScrollPosition = registrationListScroll?.current;

  //UI functions
  function handleAction(eventId, accountId, btnId) {
    handleMobileTap(
      [() => setTapHighlightActionBtn(btnId), () => setTapHighlightActionBtn()],
      [() => actionHandler(eventId, accountId)]
    );
  }

  function handleExamine(accountObj, btnId) {
    handleMobileTap(
      [
        () => setTapHighlightExamineBtn(btnId),
        () => setTapHighlightExamineBtn(),
      ],
      [
        () =>
          setGenericModal(<AttendeeAccountViewModal accountObj={accountObj} />),
      ]
    );
  }

  function handleMobileClose() {
    handleMobileTap(
      [
        () => setTapHighlightMobileCloseBtn(true),
        () => setTapHighlightMobileCloseBtn(false),
      ],
      [() => handleMobileViewCloseBtn(eventId)]
    );
  }

  //Component functions

  const handleListFade = useCallback(
    (sortedList) => {
      const scrolledPixels = Math.floor(regListScrollPosition?.scrollTop) || 0;
      const totalHeight = Math.floor(
        regListScrollPosition?.scrollHeight -
          regListScrollPosition?.clientHeight
      );

      if (sortedList?.length <= 3) {
        setActivateTopFade(false);
        setActivateBottomFade(false);
      } else {
        //MORE THAN 3
        if (scrolledPixels === 0) {
          setActivateTopFade(false);
          setActivateBottomFade(true);
        } else {
          setActivateTopFade(true);
        }

        if (scrolledPixels + 1 >= totalHeight) {
          setActivateBottomFade(false);
        } else {
          setActivateBottomFade(true);
        }
      }
    },
    [regListScrollPosition, setActivateTopFade, setActivateBottomFade]
  );

  useEffect(() => {
    if (listData) {
      const sortedData = [...listData]?.sort((a, b) => {
        const nameA = `${a?.personal?.firstName} ${a?.personal?.lastName}`;
        const nameB = `${b?.personal?.firstName} ${b?.personal?.lastName}`;
        return sortOrder === 'asc'
          ? nameB.localeCompare(nameA)
          : nameA.localeCompare(nameB);
      });

      setSortedListData(sortedData);
      handleListFade(sortedData);
    }
  }, [listData, sortOrder, handleListFade]); // Include handleListFade in the dependency array
  useEffect(() => {
    const handleScroll = () => {
      handleListFade(sortedListData);
    };

    if (regListScrollPosition) {
      regListScrollPosition.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (regListScrollPosition) {
        regListScrollPosition.removeEventListener('scroll', handleScroll);
      }
    };
  }, [regListScrollPosition, sortedListData, handleListFade]);

  ////////

  const handleMouseDown = (e) => {
    if (isTouchDevice) return;

    setDragging(true);
    setStartY(e.clientY);
  };

  const handleMouseMove = (e) => {
    if (isTouchDevice) return;

    if (dragging) {
      const deltaY = e.clientY - startY;
      regListScrollPosition.scrollTop -= deltaY;
      setStartY(e.clientY);
    }
  };

  const handleMouseUp = () => {
    if (isTouchDevice) return;
    setDragging(false);
  };

  return (
    <>
      <div className="registrations-column no-select">
        <div className="flex-row full-width">
          <div
            className="registrations-heading access-ob access-o6"
            tabIndex="0"
            title={Generic.changeSortOrder}
          >
            <p>
              {listTitle} {width < 480 && <br />}(
              {maxRegistrationsAmount ? (
                <>
                  {listData?.length}/{maxRegistrationsAmount}
                </>
              ) : (
                listData?.length
              )}
              )
            </p>
          </div>
        </div>
        <div
          className={`registration-list-sub-actions ${
            eventStatusBar ? 'space-between' : 'flex-start'
          }`}
        >
          <ButtonOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
          {eventStatusBar && (
            <EventStatusBarOrganizer
              registrationData={registrationData}
              virtualEvent={virtualEvent}
              inPersonEvent={inPersonEvent}
              borders={false}
            />
          )}
        </div>

        <ul
          className={`registration-list ${
            !isTouchDevice && dragging && sortedListData?.length >= 3
              ? 'cursor-grabbing'
              : !isTouchDevice && sortedListData?.length >= 3 && 'cursor-grab'
          }`}
          ref={registrationListScroll}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={() => setDragging(false)}
          onMouseEnter={() => setDragging(false)}
        >
          {activateTopFade && (
            <div className="fade-top disable-select">
              <img src={img_arrow_order} alt="" />
            </div>
          )}
          {activateBottomFade && (
            <div className="fade-bottom disable-select">
              <img src={img_arrow_order} alt="" className="reverse-vertical" />
            </div>
          )}
          {sortedListData
            ?.map((accountObj) => {
              return (
                <li
                  className="account-tag fs16 fwn access-ob access-o6 no-select"
                  key={`${id}-${accountObj._id.substring(2, 10)}`}
                  tabIndex="0"
                >
                  <div className="flex-row mrg-b6">
                    <LoadBucketImage
                      imagePath={accountObj?.personal?.profileImage}
                      imgClassName="registration-profile-img mrg-r8 svg"
                      backupPath={'account_img_default.png'}
                      partialPath={'myndfull_account_images'}
                    />
                    <div className="flex-column space-between full-width">
                      <div className="flex-row space-between">
                        <p tabIndex="0" className="access-ob access-o6">
                          {accountObj.personal.firstName}{' '}
                          {accountObj.personal.lastName}
                        </p>
                        {transferListData?.some(
                          (acct) => acct._id === accountObj._id
                        ) && (
                          <EventStatusBarSwitch
                            inPersonToVirtual={transferInPersonToVirtual}
                          />
                        )}
                      </div>
                      <div className="badges">
                        {accountObj.personal?.badges?.includes(100) && (
                          <img
                            className="svg access-ob"
                            tabIndex="0"
                            src={badge}
                            alt={AccountBadges.earlySupporter}
                            title={AccountBadges.earlySupporter}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-row flex-center mrg-auto-right space-between full-width full-height">
                    <button
                      className={`mrg-t4 highlight-it-theme fs16 ff2 text-gray access-ob access-o6 ${
                        tapHighlightExamineBtn ===
                        `examine-${accountObj._id.substring(2, 10)}`
                          ? 'highlight-it-theme'
                          : ''
                      }`}
                      tabIndex="0"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleExamine(
                          accountObj,
                          `examine-${accountObj._id.substring(2, 10)}`
                        );
                      }}
                    >
                      {Generic.examine}
                    </button>
                    {actionBtnTitle && !preventAction && (
                      <button
                        className={`registration-action-btn fs14 fwn access-ob access-o6 ${buttonClassName}  ${
                          tapHighlightActionBtn ===
                          `action-${accountObj._id.substring(2, 10)}`
                            ? buttonClickClassName
                            : 'filter-img-gray'
                        }`}
                        title={actionBtnTitle}
                        tabIndex="0"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAction(
                            eventId,
                            accountObj._id,
                            `action-${accountObj._id.substring(2, 10)}`
                          );
                        }}
                      >
                        <img
                          src={image}
                          alt="X"
                          className={`${imageClassName}`}
                        />
                      </button>
                    )}
                    {preventAction && preventActionText && (
                      <p tabIndex="0" className="access-ob access-o6">
                        {preventActionText}
                      </p>
                    )}
                  </div>
                </li>
              );
            })
            .reverse()}
        </ul>
      </div>
      {width < 480 && mobileCloseBtn && (
        <button
          className={`mrg-tb12 fs18 fwsb access-ob access-o6 ${
            tapHighlightMobileCloseBtn ? 'highlight-it-theme--tap' : ''
          }`}
          onClick={() => handleMobileClose()}
        >
          {Generic.close}
        </button>
      )}
    </>
  );
};

export default RegistrationList;
