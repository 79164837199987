import React, { useEffect, useState } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';

//Components

//Utility
import { account_img_default } from '../../assets/images';

const LoadingScreenContent = ({
  loadCheck1,
  loadCheck2,
  loadCheck3,
  numberOfLoadChecks,
  setLoadComplete,
  customLoadingText,
  customWrapper,
}) => {
  //Hooks
  const { LoadingScreen } = useLanguageComponents();

  //Component state
  const [animationPercentage, setAnimationPercentage] = useState(0);

  //Initialize functions

  useEffect(() => {
    const totalLoadedChecks = [loadCheck1, loadCheck2, loadCheck3].filter(
      Boolean
    ).length;

    if (totalLoadedChecks === numberOfLoadChecks) {
      const animationDuration = 600;
      let startTime = Date.now();

      const animateLoadingBar = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;

        if (elapsedTime >= animationDuration) {
          setAnimationPercentage(100);
          if (setLoadComplete) {
            setLoadComplete();
          }
        } else {
          const progress = (elapsedTime / animationDuration) * 100;
          setAnimationPercentage(progress);
          requestAnimationFrame(animateLoadingBar);
        }
      };
      requestAnimationFrame(animateLoadingBar);
    }
  }, [loadCheck1, loadCheck2, loadCheck3]);

  return (
    <div className={`loading-screen ${customWrapper ? customWrapper : ''}`}>
      <div className="flex-column fadeInOut flex-center">
        <img
          src={account_img_default}
          alt={LoadingScreen.loading}
          className="loading-screen-logo"
        />
        <h1 className="color-gray mrg-b6">
          {customLoadingText ? customLoadingText : LoadingScreen.loading}
        </h1>
      </div>

      <div className="loading-bar__container">
        <div
          className="loading-bar"
          style={{
            width: `${animationPercentage}%`,
            backgroundColor: '#f67512',
          }}
        />
      </div>
    </div>
  );
};

export default LoadingScreenContent;
