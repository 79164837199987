import useSettings from '../../context/useSettings';

const useLanguageOrganize = () => {
  const { contentLanguage } = useSettings();

  const CreateEvent = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        skipTo: {
          skipToParticipants: 'Skip to Participants',
          skipToAccess: 'Skip to Access',
          skipToRegistration: 'Skip to Registration',
          skipToPromotion: 'Skip to Promotion',
          skipToPublish: 'Skip to Publish',
        },
        skip: {
          skipCalendar: 'Skip Calendar',
        },
        initialize: {
          initialize: 'Initialize',
          ariaFieldsetInstructions: `Initialize Event. Restore data from a previous session on this device if
          data is available. Load or save event drafts. Create a new event draft.`,
          load: 'Load',
          create: 'Create',
          draftFiles: 'Draft Files',
          ariaTableTitle: 'Draft files table.',
          lastModified: 'Last Modified',
          noDrafts: `You don't have a Draft or refresh page.`,
          cancel: 'Cancel',
          save: 'Save',
          saveAs: 'Save As',
          delete: 'Delete',
          dataNotSaved: 'Images will not be saved in drafts.',
          fileNameAlreadyExists:
            'This file name already exists and saving will overwrite this file.',
          saveAndExit: 'Save & Exit',
          restore: 'Restore',
          restoreDataMessage: 'Restore data from your previous session?',
          createNewEventAndOverwriteMessage:
            'Create a new event and overwrite any unsaved progress?',
          loadDraftPt1: 'Load draft "',
          loadDraftPt2: '" and overwrite any unsaved progress?',
        },
        general: {
          general: 'General',
          ariaFieldsetInstructions: `Create Event sub-section: General. Complete standard event information,
          including title, description, event type, audience type, date & time,
          and an optional event agenda.`,
          eventTitle: 'Event Title',
          description: 'Description',
          eventType: 'Event Type',
          eventTypeWhatsThis: 'event',
          audienceType: 'Target Audience',
          audienceTypeWhatsThis: 'audience',
          dateAndTime: 'Date & Time',
          dateAndTimeDescription: 'Select the date and time of the event.',

          startTime: 'Start Time',
          endTime: 'End Time',
          eventAgenda: 'Event Agenda',
          agendaItem: 'Agenda item',
          eventAgendaDescription:
            'Create an event agenda. Toggle the agenda schedule to display as either specific start/end times or duration in minutes.',
          agendaItemButton: 'Add Agenda Item',
          language: 'Language',
          languageDescription: 'Add the language(s) of the event.',
        },
        participants: {
          participants: 'Participants',
          ariaFieldsetInstructions: `Create Event sub-section: Participants. Add an event moderator and up to
          six speakers. A speaker name for each speaker is required.`,
          moderator: 'Moderator',
          profilePhoto: 'Profile Photo',
          moderatorName: 'Moderator Name',
          speakers: 'Speakers',
          speakersDescription:
            'Add up to six speakers manually or through MyndFull accounts.',
          speakerName: 'Speaker Name',
          speakerBio: 'Speaker Bio',
          addSpeaker: 'Add Speaker',
        },
        access: {
          access: 'Access',
          ariaFieldsetInstructions: `Create Event sub-section: Access. Provide details regarding the event
          location, accesssibility, and language. Hosting options and event
          language is required.`,
          hostingOptions: 'Hosting Options',
          hostOptionsDescription:
            'Indicate whether your event will be hosted in person, online or a hybrid of both.',
          onlinePlatform: 'Online Platform',
          virtualMeetingId: 'Virtual Meeting Id',
          virtualMeetingPasscode: 'Virtual Meeting Passcode',
          virtualMeetingLink: 'Virtual Meeting Link',
          clearOnlinePlatformFields: 'Clear online platform fields',
          location: 'Location',
          locationDescription:
            'Use the autocomplete feature to quickly fill the address fields. Drag and drop the marker to pinpoint the location on large buildings.',
          room: 'Room',
          locationName: 'Location Name',
          streetAddress: 'Street Address',
          cityTown: 'City/Town',
          country: 'Country',
          clearInPersonFields: 'Clear in-person location',
          accessibility: 'Accessibility',
          wheelchairAccessibility: 'Wheelchair Accessibility',
          signLanguageInterpreter: 'Sign Language Interpreter',
          realtimeTranslations: 'Real-time Translations',
          notes: 'Notes',
          accessibilityNotesDescription:
            'Provide notes on accessibility. For example, the location of a wheelchair ramp or how to obtain on-site assistance.',
        },
        registration: {
          registration: 'Registration',
          ariaFieldsetInstructions: `Create Event sub-section: Registration. Toggle registration options,
          including requiring registrations, approve registrations, max
          registrations, and private details.`,
          requireRegistration: 'Require Registrations',
          requireRegistrationsDescription:
            'Attendees are required to register and additional event organization options are unlocked below.',
          approveRegistrations: 'Approve Registrations',
          approveRegistrationsDescription:
            'Attendee registrations requires approval by an event organizer.',

          maxRegistrationsInPerson: 'Max Registrations (In Person)',
          maxRegistrationsDescriptionsInPerson:
            'Set the number of maximum registrations for in-person attendance.',
          ariaMaxRegistrationsCounterInputInPerson: `Current number of maximum registrations for in-person attendance.`,

          maxRegistrationsVirtual: 'Max Registrations (Virtual)',
          maxRegistrationsDescriptionsVirtual:
            'Set the number of maximum registrations for virtual attendance.',
          ariaMaxRegistrationsCounterInputVirtual: `Current number of maximum registrations for virtual attendance.`,

          privateDetails: 'Private Details',
          privateDetailsDescription:
            'Selected event details will only be visible to registered attendees.',
          privateDetailsWarning: 'Please select a Hosting Option',
          room: 'Room',
          locationName: 'Location Name',
          streetAddress: 'Street Address',
          virtualMeetingId: 'Virtual Meeting Id',
          virtualMeetingPasscode: 'Virtual Meeting Passcode',
          virtualMeetingLink: 'Virtual Meeting Link',
        },
        promotion: {
          promotion: 'Promotion',
          ariaFieldsetInstructions: `Create Event sub-section: Promotion. Add an event image. Assign big
          tags and tiny tags to match user interests.`,
          eventDisplay: 'Event Display',
          ariaImageDescription: 'Event photo.',
          eventDisplayDescription: `Add a primary display image. It is recommended that display images do not contain text for reasons related to both accessibility and aesthetic.`,
          bigTags: 'Big Tags',
          bigTagsDescription:
            'Choose scholastic fields / disciplines that match your event.',
          ariaBigTagsInputInstructions:
            'Choose scholastic fields / disciplines that match your event. For the best accessibility, use the See Full List button located below the input field to directly add big tags from a list. Alternatively, use this autocomplete field to find tags from the Big Tags list. Use the arrow up and arrow down keys to scroll through the generated list. Press enter to add the highlighted tag or backspace to remove characters from the input field.',
          seeFullList: 'See Full List',
          tinyTags: 'Tiny Tags',
          tinyTagsDescription: `Select specific tags for greater visibility to attendees that have matching tags. Only use letters and numbers. At least one tag is required.`,
          connectSocial: 'Connect Social',
          connectSocialDescription:
            'Generate an event advertisement on your social media account (coming soon).',
        },
        publish: {
          publish: 'Publish',
          ariaFieldsetInstructions: `Create Event sub-section: Publish. Check event completion status. When
          the event status is ready, you may schedule event publication or publish
          the event immediately.`,
          unready: 'Unready',
          ready: 'Ready',
          checkCompletion: 'Check Completion',
          checkCompletionDescription: `Check the completion of event information before choosing a publication option.`,
          fieldsThatRequireCompletion: 'Fields that require completion:',
          checkCompetionSuccessfulDescription: 'Your event can be published.',
          scheduleEventPublication: 'Schedule Event Publication',

          previewEvent: 'Preview Event',
          publishEvent: 'Publish Event',
          completePublishInformation:
            'Please complete the scheduling information.',
        },
        validations: {
          eventTitle: 'Event Title',
          eventType: 'Event Type',
          audienceType: 'Audience Type',
          date: 'Date',
          timeZone: 'Time Zone',
          startTime: 'Start Time',
          endTime: 'End Time',
          hostingOption: 'Hosting Option',
          language: 'Language',
          bigTags: 'Big Tags',
          tinyTags: 'Tiny Tags',
          agendaItem: 'Agenda Item',
          speakerName: 'Speaker Name',
          streetAddress: 'Street Address',
          cityTown: 'City/Town',
          country: 'Country',
          onlinePlatform: 'Online Platform',
          virtualMeetingId: 'Virtual Meeting Id',
          viritualPasscode: 'Virtual Passcode',
          maxRegistrationsInPerson: 'Max Registrations (In Person) is 0',
          maxRegistrationsVirtual: 'Max Registrations (Virtual) is 0',
          schedulePublication: 'Schedule Publication',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        skipTo: {
          skipToParticipants: 'Passer aux Participants',
          skipToAccess: `Passer à l'accès`,
          skipToRegistration: `Passer à l'inscription`,
          skipToPromotion: 'Passer à la promotion',
          skipToPublish: 'Passer à Publier',
        },
        skip: {
          skipCalendar: 'Ignorer le calendrier',
        },
        initialize: {
          initialize: 'Initialiser',
          ariaFieldsetInstructions: `Initialiser l'événement. Restaurer les données d'une session précédente sur cet appareil si
          les données sont disponibles. Chargez ou enregistrez des brouillons d'événements. Créer un nouveau brouillon d'événement.`,
          load: 'Charger',
          create: 'Créer',
          draftFiles: 'Brouillons de fichiers',
          ariaTableTitle: 'Tableau des brouillons de fichiers.',
          lastModified: 'Dernière modification',
          noDrafts: `Vous n'avez pas de brouillon ni de page d'actualisation.`,
          cancel: 'Annuler',
          save: 'Sauvegarder',
          saveAs: 'Enregistrer sous',
          delete: 'Supprimer',
          dataNotSaved: `Les images ne seront pas enregistrées dans les brouillons.`,
          fileNameAlreadyExists: `Ce nom de fichier existe déjà et l'enregistrement écrasera ce fichier.`,
          saveAndExit: 'Enregistrer & quitter',
          restore: 'Restaurer',
          restoreDataMessage:
            'Restaurer les données de votre session précédente ?',
          createNewEventAndOverwriteMessage:
            'Créer un nouvel événement et écraser toute progression non enregistrée ?',
          loadDraftPt1: 'Charger le brouillon "',
          loadDraftPt2: '" et écraser toute progression non enregistrée?',
        },
        general: {
          general: 'Général',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Général. Informations complètes sur les événements standards,
          y compris le titre, la description, le type d'événement, le type d'audience, la date et l'heure,
          et un programme d'événement facultatif.`,
          eventTitle: `Titre de l'événement`,
          description: 'Description',
          eventType: `Type d'événement`,
          eventTypeWhatsThis: 'événement',
          audienceType: 'Public cible',
          audienceTypeWhatsThis: 'public',
          dateAndTime: 'Date et heure',
          dateAndTimeDescription: `Date non sélectionnée`,

          startTime: 'Heure de début',
          endTime: 'Heure de fin',
          eventAgenda: `Ordre du jour de l'événement`,
          agendaItem: `Point à l'ordre du jour`,
          eventAgendaDescription: `Créer un programme d'événements. Basculez le calendrier de l'agenda pour afficher soit des heures de début/de fin spécifiques, soit une durée en minutes.`,
          agendaItemButton: `Ajouter un élément d'ordre du jour`,
          language: 'Langue',
          languageDescription: `Ajoutez la ou les langues de l'événement.`,
        },
        participants: {
          participants: 'Participants',
          profilePhoto: 'Photo de profil',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Participants. Ajoutez un modérateur d'événement et jusqu'à
          six intervenants. Un nom de conférencier pour chaque conférencier est requis.`,
          moderator: 'Modérateur',
          moderatorName: 'Nom du modérateur',
          speakers: 'Conférenciers',
          speakersDescription:
            'Ajoutez jusqu’à six conférenciers manuellement ou via des comptes MyndFull.',
          speakerName: 'Nom du conférencier',
          speakerBio: 'Biographie du conférencier',
          addSpeaker: 'Ajouter un conférencier',
        },
        access: {
          access: 'Accès',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Accès. Fournir des détails concernant l'événement
          l'emplacement, l'accessibilité et la langue. Options d'organisation et événement
          la langue est requise.`,
          hostingOptions: `Options d'organisation`,
          hostOptionsDescription:
            'Indiquez si votre événement aura lieu en personne, en ligne ou sous forme hybride.',
          onlinePlatform: 'Plateforme en ligne',
          virtualMeetingId: 'Id de la réunion virtuelle',
          virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
          virtualMeetingLink: 'Lien de la réunion virtuelle',
          clearOnlinePlatformFields:
            'Effacer les champs de la plateforme en ligne',
          location: 'Emplacement',
          locationDescription: `Utilisez la fonction de saisie semi-automatique pour remplir rapidement les champs d'adresse. Faites glisser et déposez le marqueur pour localiser l'emplacement sur de grands bâtiments.`,
          room: 'Salle',
          locationName: `Nom de l'emplacement`,
          streetAddress: 'Adresse',
          cityTown: 'Ville',
          country: 'Pays',
          clearInPersonFields: `Effacer l'emplacement en personne`,
          accessibility: 'Accessibilité',
          wheelchairAccessibility: 'Accessibilité en fauteuil roulant',
          signLanguageInterpreter: 'Interprète en langue des signes',
          realtimeTranslations: 'Traductions en temps réel',
          notes: 'Notes',
          accessibilityNotesDescription: `Fournissez des notes sur l'accessibilité. Par exemple, l'emplacement d'une rampe pour fauteuil roulant ou comment obtenir de l'aide sur place.`,
        },
        registration: {
          registration: 'Inscription',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Inscription. Basculez les options d'inscription, y compris les inscriptions obligatoires, les inscriptions approuvées, les inscriptions maximales et les détails privés.`,
          requireRegistration: 'Inscription requise',
          requireRegistrationsDescription: `Les participants doivent s'inscrire et des options supplémentaires pour l'organisation de l'événement sont débloquées ci-dessous.`,
          approveRegistrations: `Approuver l'inscription`,
          approveRegistrationsDescription: `L'inscription des participants doit être approuvée par un organisateur de l'événement.`,

          maxRegistrationsInPerson: 'Inscriptions maximales (en personne)',
          maxRegistrationsDescriptionsInPerson: `Définissez le nombre maximum d'inscriptions pour la participation en personne.`,
          ariaMaxRegistrationsCounterInputInPerson: `Nombre actuel d'inscriptions maximales pour la participation en personne.`,

          maxRegistrationsVirtual: 'Inscriptions maximales (virtuelles)',
          maxRegistrationsDescriptionsVirtual: `Définissez le nombre maximum d'inscriptions pour la participation virtuelle.`,
          ariaMaxRegistrationsCounterInputVirtual: `Nombre actuel d'inscriptions maximales pour la participation virtuelle.`,

          privateDetails: 'Détails privés',
          privateDetailsDescription: `Les détails sélectionnés de l'événement seront visibles uniquement pour les participants inscrits.`,
          privateDetailsWarning: `Veuillez sélectionner une option d'organisation`,
          room: 'Salle',
          locationName: `Nom de l'emplacement`,
          streetAddress: 'Adresse',
          virtualMeetingId: 'ID de la réunion virtuelle',
          virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
          virtualMeetingLink: 'Lien de la réunion virtuelle ',
        },
        promotion: {
          promotion: 'Promotion',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Promotion. Ajouter une image d'événement. Attribuer grand balises et petit balises pour correspondre aux intérêts des utilisateurs.`,
          eventDisplay: `Image principale de l'événement`,
          ariaImageDescription: `Photographie de l'événement.`,
          eventDisplayDescription: `Ajoutez une image d'affichage principale. Il est recommandé que les images affichées ne contiennent pas de texte pour des raisons liées à la fois à l'accessibilité et à l'esthétique.`,
          bigTags: 'Grand Balises',
          bigTagsDescription: `Choisissez les domaines scolaires / disciplines pour trouver les événements correspondants.`,
          ariaBigTagsInputInstructions: `Choisissez les domaines scolaires / disciplines pour trouver les événements correspondants. Pour une meilleure accessibilité, utilisez le bouton Voir la liste complète situé sous le champ de saisie pour ajouter directement de grand bailses à partir d'une liste. Vous pouvez également utiliser ce champ de saisie semi-automatique pour rechercher des balises dans la liste Grande Tags. Community Verified icon Utilisez les flèches vers le haut et vers le bas pour faire défiler la liste générée. Appuyez sur Entrée pour ajouter la balise en surbrillance ou sur Retour arrière pour supprimer des caractères du champ de saisie.`,
          seeFullList: 'Voir la liste complète',
          tinyTags: 'Petit Balises',
          tinyTagsDescription: `Sélectionner des balises spécifiques pour une plus grande visibilité auprès des participants qui ont des balises correspondants. Utilisez uniquement des lettres et des chiffres. Au moins une étiquette est nécessaire.`,
          connectSocial: 'Connecter aux réseaux sociaux',
          connectSocialDescription: `Générer une publicité pour l'événement sur votre compte de réseau social (prochainement).`,
        },
        publish: {
          publish: 'Publier',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Publier. Vérifiez l'état d'achèvement de l'événement. Quand
          le statut de l'événement est prêt, vous pouvez programmer la publication de l'événement ou publier
          l'événement immédiatement.`,
          unready: 'Non prêt',
          ready: 'Prêt',
          checkCompletion: 'Vérifier la complétion',
          checkCompletionDescription: `Vérifier que toutes les informations de l'événement sont complètes avant de choisir une option de publication.`,
          fieldsThatRequireCompletion: 'Champs qui nécessitent la complétion:',
          checkCompetionSuccessfulDescription:
            'Votre événement peut être publié.',
          scheduleEventPublication: `Planifier la publication de l'événement`,
          previewEvent: `Prévisualiser l'événement`,
          publishEvent: `Publier l'événement`,
          completePublishInformation: `Veuillez compléter les informations de planification.`,
        },
        validations: {
          eventTitle: `Titre de l'événement`,
          eventType: `Type d'événement`,
          audienceType: 'Type de public',
          date: 'Date',
          timeZone: 'Fuseau horaire',
          startTime: 'Heure de début',
          endTime: 'Heure de fin',
          hostingOption: `Options d'organisation`,
          language: 'Langue',
          bigTags: 'Grand Balises',
          tinyTags: 'Petit Balises',
          agendaItem: `Ordre du jour de l'événement`,
          speakerName: 'Nom du conférencier',
          streetAddress: 'Adresse',
          cityTown: 'Ville',
          country: 'Pays',
          onlinePlatform: 'Plateforme en ligne',
          virtualMeetingId: 'ID de la réunion virtuelle',
          viritualPasscode: `Code d'accès à la réunion virtuelle`,
          maxRegistrationsInPerson: `Le nombre maximum d'inscriptions (en personne) est de 0`,
          maxRegistrationsVirtual: `Le nombre maximum d'inscriptions (virtuelles) est de 0`,
          schedulePublication: 'Calendrier de publication',
        },
      };
    }

    return translation;
  };

  const OrganizerEventCard = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        viewEvent: 'View Event',
        registrations: 'Registrations',
        virtualRegistrations: 'Virtual Registrations',
        inpersonRegistrations: 'In-Person Registrations',
        messages: 'Messages',
        announcements: 'Announcements',
        editEvent: 'Edit Event',
        eventDashboard: 'Event Dashboard',
        pendingRegistrations: 'Pending Registrations',
        approvedRegistrations: 'Approved Registrations',
        approveRegisteree: 'Approve Registeree',
        removeRegisteree: 'Remove Registeree',
        pendingInPersonRegistrations: 'Pending In-Person',
        approvedInPersonRegistrations: 'Approved In-Person',
        pendingVirtualRegistrations: 'Pending Virtual',
        approvedVirtualRegistrations: 'Approved Virtual',
        fullCapacity: 'Full Capacity',
        waitingListInPerson: 'In-Person Waiting List',
        waitingListVirtual: 'Virtual Waiting List',
        inPersonEvent: 'In-Person Event',
        virtualEvent: 'Virtual Event',
        hybridEvent: 'Hybrid Event',
        eventDate: 'Event Date',

        eventPublication: 'Event Publication',
        calendarIcon: 'calendar icon',
        clockIcon: 'clock icon',
        organizer: 'Organizer',
        inPersonIcon: 'inperson icon',
        virtualIcon: 'virtual icon',
        hybridIcon: 'hybrid icon',
        archivedEvent: 'Archived Event',
        archivedIcon: 'archived icon',
        removeArchivedEvent: 'Remove Archived Event',
        iconTrash: 'trash icon',

        //modal confirmation
        confirmArchivedEventRemoval:
          'Are you sure you want to remove this archived event?',
        remove: 'Remove',
        cancel: 'Cancel',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        viewEvent: `Voir l'événement`,
        registrations: 'Inscriptions',
        virtualRegistrations: 'Inscriptions virtuelles',
        inpersonRegistrations: 'Inscriptions en personne',
        messages: 'Messages',
        announcements: 'Annonces',
        editEvent: `Modifier l'événement`,
        eventDashboard: 'Tableau de bord des événements',
        pendingRegistrations: 'Inscriptions en attente',
        approvedRegistrations: 'Inscriptions approuvées',
        approveRegisteree: `Approuver l'inscrit`,
        removeRegisteree: 'Supprimer un inscrit',
        pendingInPersonRegistrations: 'Personne en attente',
        approvedInPersonRegistrations: 'Personne approuvées',
        pendingVirtualRegistrations: 'Virtuelles en attente',
        approvedVirtualRegistrations: 'Virtuelles approuvées',
        fullCapacity: 'Pleine capacité',
        waitingListInPerson: `Liste d'attente en personne`,
        waitingListVirtual: `Liste d'attente virtuelle`,
        inPersonEvent: 'Événement en personne',
        virtualEvent: 'Événement virtuel',
        hybridEvent: 'Événement hybride',
        eventDate: `Date de l'événement`,
        eventPublication: `Publication d'événement`,
        calendarIcon: 'icône de calendrier',
        clockIcon: `icône de l'horloge`,
        organizer: 'Organisateur',
        inPersonIcon: 'icône en personne',
        virtualIcon: 'icône virtuelle',
        hybridIcon: 'icône hybride',
        archivedEvent: 'Événement archivé',
        archivedIcon: 'icône archivée',
        removeArchivedEvent: `Supprimer l'événement archivé`,
        iconTrash: 'icône poubelle',

        //modal confirmation
        confirmArchivedEventRemoval:
          'Êtes-vous sûr de vouloir supprimer cet événement archivé ?',
        remove: 'Supprimer',
        cancel: 'Annuler',
      };
    }

    return translation;
  };

  const OrganizeOverview = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        activeStandaloneEvents: 'Active Standalone Events',
        scheduledStandaloneEvents: 'Scheduled Standalone Events',
        standaloneEvents: 'Standalone Events',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        activeStandaloneEvents: 'Événements autonomes actifs',
        scheduledStandaloneEvents: 'Événements autonomes programmés',
        standaloneEvents: 'Événements autonomes',
      };
    }

    return translation;
  };

  const EditOverview = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        editOverview: 'Edit Overview',
        changeList: 'Change List',
        noChange: 'No Change',
        saveChanges: 'Save Changes',
        undoChanges: 'Undo Changes',

        deleteEvent: 'Delete Event',

        //confirmation buttons
        cancel: 'Cancel',
        confirm: 'Confirm',
        continue: 'Continue',

        delete: 'Delete',
        save: 'Save',
        undoConfirmation: 'Are you sure you want to undo changes ?',
        deleteConfirmation: 'Are you sure you want to delete this event ?',
        saveConfirmation: 'Are you sure you want to save these changes ?',
        confirmationModalWarningToReturn: 'Unsaved progress will be lost.',

        previewEvent: 'Preview Event',
        fieldsThatRequireCompletion: 'Fields that require completion:',

        //Schedule
        schedulePublication: 'Schedule Publication',
        startTime: 'Start Time',

        togglePublicationSchedule: 'Toggle publication schedule',

        //Generic
        title: 'Title',
        description: 'Description',
        eventType: 'Event Type',
        audienceType: 'Target Audience',
        languages: 'Languages',
        date: 'Date',
        timeZone: 'Time Zone',
        timeStart: 'Start Time',
        timeEnd: 'End Time',
        agenda: 'Agenda',
        realtimeTranslations: 'Realtime Translations',
        signLanguageInterpreter: 'Sign Language Interpreter',
        wheelchairAccessibility: 'Wheelchair Accessibility',
        eventImage: 'Event Image',

        //Hosting
        hostingOption: 'Hosting Option',
        virtualPlatform: 'Online Platform',
        virtualMeetingId: 'Virtual Meeting Id',
        virtualMeetingPasscode: 'Virtual Meeting Passcode',
        virtualMeetingLink: 'Virtual Meeting Link',
        location: 'Location',
        coordinates: 'Geolocation Coordinates',
        accessibilityNotes: 'Accessibility Notes',

        //Participants
        moderator: 'Moderator',
        speakers: 'Speakers',

        //Registrations
        requireRegistrations: 'Require Registrations',
        approveRegistrations: 'Approve Registrations',
        maxRegistrationsInPerson: 'Max Registrations (In Person)',
        maxRegistrationsVirtual: 'Max Registrations (Virtual)',

        privateDetails: 'Private Details',

        //Schedule
        schedulePublishDate: 'Scheduled Publication Date',
        schedulePublishTime: 'Scheduled Publication Time',
        schedulePublishTimeZone: 'Scheduled Publication Time Zone',

        //Tags
        bigTags: 'Big Tags',
        tinyTags: 'Tiny Tags',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        editOverview: `Modifier l'aperçu`,
        changeList: 'Changer la liste',
        noChange: 'Pas de changement',
        saveChanges: 'Sauvegarder les modifications',
        undoChanges: 'Annuler les changements',
        deleteEvent: `Supprimer l'événement`,

        //confirmation buttons
        cancel: 'Annuler',
        confirm: 'Confirmer',
        continue: 'Continuer',

        delete: 'Supprimer',
        save: 'Sauvegarder',
        undoConfirmation:
          'Êtes-vous sûr de vouloir annuler les modifications ?',
        deleteConfirmation:
          'Êtes-vous sûr de vouloir supprimer cet événement ?',
        saveConfirmation:
          'Êtes-vous sûr de vouloir enregistrer ces modifications ?',
        confirmationModalWarningToReturn:
          'Les progrès non enregistrés seront perdus.',

        previewEvent: `Prévisualiser l'événement`,
        fieldsThatRequireCompletion: 'Champs qui nécessitent la complétion:',

        //Schedule
        schedulePublication: 'Calendrier de publication',
        startTime: 'Heure de début',

        togglePublicationSchedule: `Basculer le calendrier de publication`,

        //Generic
        title: `Titre de l'événement`,
        description: 'Description',
        eventType: `Type d'événement`,
        audienceType: 'Public cible',
        languages: 'Languages',
        date: 'Date',
        timeZone: 'Fuseau horaire',
        timeStart: 'Heure de début',
        timeEnd: 'Heure de fin',
        agenda: `Ordre du jour`,
        realtimeTranslations: 'Traductions en temps réel',
        signLanguageInterpreter: 'Interprète en langue des signes',
        wheelchairAccessibility: 'Accessibilité en fauteuil roulant',
        accessibilityNotes: `Remarques sur l'accessibilité`,
        eventImage: `Image de l'événement`,

        //Participants
        moderator: 'Modérateur',
        speakers: 'Conférencier',

        //Hosting
        hostingOption: `Options d'organisation`,
        virtualPlatform: 'Plateforme en ligne',
        virtualMeetingId: 'Id de la réunion virtuelle',
        virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
        virtualMeetingLink: 'Lien de la réunion virtuelle',
        location: 'Emplacement',
        coordinates: 'Coordonnées de géolocalisation',

        //Registrations
        requireRegistrations: 'Exiger des inscriptions',
        approveRegistrations: `Approuver l'inscription`,
        maxRegistrationsInPerson: 'Inscriptions maximales (en personne)',
        maxRegistrationsVirtual: 'Inscriptions maximales (virtuelles)',
        privateDetails: 'Détails privés',

        //Schedule
        schedulePublishDate: 'Date de publication prévue',
        schedulePublishTime: 'Heure de publication prévue',
        schedulePublishTimeZone: 'Fuseau horaire de publication programmée',

        //Tags
        bigTags: 'Grand Balises',
        tinyTags: 'Petit Balises',
      };
    }
    return translation;
  };

  return {
    EditOverview: EditOverview(),
    OrganizeOverview: OrganizeOverview(),
    OrganizerEventCard: OrganizerEventCard(),
    CreateEvent: CreateEvent(),
  };
};

export default useLanguageOrganize;
