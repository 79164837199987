//Hooks
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useLanguageData from '../../../../language/useLanguageData';
//Components
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import Counter from '../../../../components/Counter/Counter';
import CheckBox from '../../../../components/Selections/CheckBox';
import ButtonSwitchLOrder from '../../../../components/ButtonSwitch/ButtonSwitchLOrder';
import ButtonSwitchHOrder from '../../../../components/ButtonSwitch/ButtonSwitchHOrder';

//Utility

const Registration = ({
  initialize,
  handleSetApproveRegistrations,
  handleSetMaxRegistrationsInPersonEnabled,
  handleSetMaxRegistrationsVirtualEnabled,
  handleSetEnablePrivateDetails,
  handleSetMaxRegistrationsInPersonAmount,
  handleSetMaxRegistrationsVirtualAmount,
  handleSetPrivateDetails,
  handleSetRegistrations,
  loadHostingOption,
  loadMaxRegistrationsInPersonEnabled,
  loadMaxRegistrationsVirtualEnabled,
  loadEnablePrivateDetails,
  loadRegistrationsActive,
  loadApproveRegistrations,
  loadMaxRegistrationsInPersonAmount,
  loadMaxRegistrationsVirtualAmount,
  loadPrivateDetails,
}) => {
  //Hooks

  const { CreateEvent } = useLanguageOrganize();
  const { VirtualPrivateDetailsOpts, InPersonPrivateDetailsOpts } =
    useLanguageData();

  //Component variables
  let content;

  content = (
    <fieldset
      className="fieldset"
      id="fieldset-registration"
      onMouseDown={(e) => e.preventDefault()} //prevent focus
    >
      <legend id="aria-fieldset-instructions-registration">
        {CreateEvent.registration.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="registrationLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-registration"
      >
        {CreateEvent.registration.registration}
      </label>
      <FocusSkip
        skipToLabel={CreateEvent.skipTo.skipToPromotion}
        skipToId={'promotionLabel'}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />
      <ButtonSwitchHOrder
        id={'registrations'}
        title={CreateEvent.registration.requireRegistration}
        handler={handleSetRegistrations}
        stateHO={loadRegistrationsActive}
        horizontal={true}
      />
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.registration.requireRegistrationsDescription}
      </p>
      <div className="h24-ph36" />
      <ButtonSwitchLOrder
        id={'approveRegistrations'}
        title={CreateEvent.registration.approveRegistrations}
        handler={handleSetApproveRegistrations}
        handleHOrderCondition={handleSetRegistrations}
        stateHO={loadRegistrationsActive}
        loadTrigger={initialize}
        loadValue={loadApproveRegistrations}
        horizontal={true}
      />
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.registration.approveRegistrationsDescription}
      </p>
      <div className="h24-ph36" />
      <ButtonSwitchLOrder
        id={'maxRegistrationsInPerson'}
        title={CreateEvent.registration.maxRegistrationsInPerson}
        handler={handleSetMaxRegistrationsInPersonEnabled}
        handleHOrderCondition={handleSetRegistrations}
        stateHO={loadRegistrationsActive}
        loadTrigger={initialize}
        secondaryTrigger={
          loadHostingOption === 'inPerson' || loadHostingOption === 'hybrid'
        }
        loadValue={loadMaxRegistrationsInPersonEnabled}
        horizontal={true}
        temporaryDisable={
          loadHostingOption !== 'hybrid' && loadHostingOption !== 'inPerson'
            ? true
            : false
        }
        temporaryDisableMessage={'incompatibleHostingOption'}
      />

      <div className="h12" />

      <p className="description-text" tabIndex="0">
        {CreateEvent.registration.maxRegistrationsDescriptionsInPerson}
      </p>

      {loadMaxRegistrationsInPersonEnabled && (
        <>
          <div className="h24" />
          <Counter
            handleCounter={handleSetMaxRegistrationsInPersonAmount}
            id={'maxRegistrationCounter'}
            loadValue={loadMaxRegistrationsInPersonAmount}
            increment={10}
            ariaCounterInputLabel={
              CreateEvent.registration.ariaMaxRegistrationsCounterInputInPerson
            }
            minValue={0}
            maxValue={9999}
          />
        </>
      )}

      <div className="h24-ph36" />

      <ButtonSwitchLOrder
        id={'maxRegistrationsVirtual'}
        title={CreateEvent.registration.maxRegistrationsVirtual}
        handler={handleSetMaxRegistrationsVirtualEnabled}
        handleHOrderCondition={handleSetRegistrations}
        stateHO={loadRegistrationsActive}
        loadTrigger={initialize}
        secondaryTrigger={
          loadHostingOption === 'online' || loadHostingOption === 'hybrid'
        }
        loadValue={loadMaxRegistrationsVirtualEnabled}
        horizontal={true}
        temporaryDisable={
          loadHostingOption !== 'hybrid' && loadHostingOption !== 'online'
            ? true
            : false
        }
        temporaryDisableMessage={'incompatibleHostingOption'}
      />

      <div className="h12" />

      <p className="description-text" tabIndex="0">
        {CreateEvent.registration.maxRegistrationsDescriptionsVirtual}
      </p>

      {loadMaxRegistrationsVirtualEnabled && (
        <>
          <div className="h24" />
          <Counter
            handleCounter={handleSetMaxRegistrationsVirtualAmount}
            id={'maxRegistrationCounterVirtual'}
            loadValue={loadMaxRegistrationsVirtualAmount}
            increment={10}
            ariaCounterInputLabel={
              CreateEvent.registration.ariaMaxRegistrationsCounterInputVirtual
            }
            minValue={0}
            maxValue={9999}
          />
        </>
      )}

      <div className="h24-ph36" />

      <ButtonSwitchLOrder
        id={'privateDetails'}
        title={CreateEvent.registration.privateDetails}
        handler={handleSetEnablePrivateDetails}
        handleHOrderCondition={handleSetRegistrations}
        stateHO={loadRegistrationsActive}
        loadTrigger={initialize}
        secondaryTrigger={loadHostingOption}
        loadValue={loadEnablePrivateDetails}
        horizontal={true}
        temporaryDisable={!loadHostingOption ? true : false}
        temporaryDisableMessage={'incompatibleHostingOption'}
      />
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.registration.privateDetailsDescription}
      </p>
      {loadHostingOption && loadEnablePrivateDetails && <div className="h24" />}

      {loadHostingOption && (
        <div className="checkbox__wrapper">
          <>
            {(loadHostingOption === 'inPerson' ||
              loadHostingOption === 'hybrid') && (
              <>
                <CheckBox
                  visible={loadEnablePrivateDetails}
                  selectionObj={InPersonPrivateDetailsOpts.room}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.room}
                />
                <CheckBox
                  visible={loadEnablePrivateDetails}
                  selectionObj={InPersonPrivateDetailsOpts.locationName}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.locationName}
                />
                <CheckBox
                  visible={loadEnablePrivateDetails}
                  selectionObj={InPersonPrivateDetailsOpts.streetAddress}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.streetAddress}
                />
              </>
            )}
            {(loadHostingOption === 'online' ||
              loadHostingOption === 'hybrid') && (
              <>
                <CheckBox
                  visible={loadEnablePrivateDetails}
                  selectionObj={VirtualPrivateDetailsOpts.virtualMeetingId}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.virtualMeetingId}
                />
                <CheckBox
                  visible={loadEnablePrivateDetails}
                  selectionObj={
                    VirtualPrivateDetailsOpts.virtualMeetingPasscode
                  }
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.virtualMeetingPasscode}
                />
                <CheckBox
                  visible={loadEnablePrivateDetails}
                  selectionObj={VirtualPrivateDetailsOpts.virtualMeetingLink}
                  handler={handleSetPrivateDetails}
                  loadTrigger={initialize}
                  loadValue={loadPrivateDetails.virtualMeetingLink}
                />
              </>
            )}
          </>
        </div>
      )}
    </fieldset>
  );
  return content;
};

export default Registration;
