import { useSelector, useDispatch } from 'react-redux';

//Slices
import {
  selectAdminTechnicalLogs,
  selectAdminTechnicalLogsArchive,
  setAdminTechnicalLogs,
  setAdminTechnicalLogsArchive,
  removeAdminTechnicalLogById,
  setAdminTechnicalLog,
  setAdminTechnicalLogArchive,
  removeAdminTechnicalLogArchiveById,
} from './adminTechnicalSlice';

//Hooks
import { useAdminSettingsHandlers } from '../settings/useAdminSettingsHandlers';
import {
  useChangeTechnicalLogArchiveMutation,
  useCreateTechnicalLogArchiveMutation,
  useRemoveTechnicalLogArchiveMutation,
  useRemoveTechnicalLogMutation,
} from './adminTechnicalApiSlice';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';

//Components

const useAdminTechnicalHandlers = () => {
  // Admin
  const dispatch = useDispatch();
  const { adminAccountId, adminAccountAdminReference } =
    useAdminAccountReduxHandlers();
  const { handleAdminSettingsTechnicalSortingDate } =
    useAdminSettingsHandlers();

  //API
  const [removeTechnicalLog, { isLoading: removeTechnicalLogIsLoading }] =
    useRemoveTechnicalLogMutation();
  const [
    createTechnicalLogArchive,
    { isLoading: createTechnicalLogArchiveIsLoading },
  ] = useCreateTechnicalLogArchiveMutation();

  const [
    changeTechnicalLogArchive,
    { isLoading: changeTechnicalLogArchiveIsLoading },
  ] = useChangeTechnicalLogArchiveMutation();

  const [
    removeTechnicalLogArchive,
    { isLoading: removeTechnicalLogArchiveIsLoading },
  ] = useRemoveTechnicalLogArchiveMutation();

  //SELECTORS
  const adminTechnicalLogs = useSelector(selectAdminTechnicalLogs);
  const adminTechnicalLogsArchive = useSelector(
    selectAdminTechnicalLogsArchive
  );

  //TECHNICAL LOG
  function handleAdminTechnicalSetTechnicalLogs(arr) {
    dispatch(setAdminTechnicalLogs(arr));
  }

  function handleAdminTechnicalLogSetTechnicalLog(obj) {
    dispatch(setAdminTechnicalLog(obj));
  }

  function handleAdminTechnicalLogRemoveTechnicalLogById(str) {
    dispatch(removeAdminTechnicalLogById(str));
  }

  //TECHNICAL LOG ARCHIVE
  function handleAdminTechnicalSetTechnicalLogsArchive(arr) {
    dispatch(setAdminTechnicalLogsArchive(arr));
  }

  function handleAdminTechnicalLogArchiveSetTechnicalLogArchive(obj) {
    dispatch(setAdminTechnicalLogArchive(obj));
  }

  function handleAdminTechnicalLogArchiveRemoveTechnicalLogArchiveById(str) {
    dispatch(removeAdminTechnicalLogArchiveById(str));
  }

  //TECHNICAL LOG
  async function handleRemoveTechnicalLog(technicalLogId) {
    if (!removeTechnicalLogIsLoading) {
      const response = await removeTechnicalLog({
        adminAccountId,
        technicalLogId,
      });
      if (response?.data?.status === 'success') {
        return 'success';
      }
    }
  }

  //TECHNICAL LOG ARCHIVE
  async function handleCreateTechnicalLogArchive(
    technicalLogId,
    archivePath,
    notes
  ) {
    if (!createTechnicalLogArchiveIsLoading) {
      const createResponse = await createTechnicalLogArchive({
        adminAccountId,
        technicalLogId,
        archivePath,
        notes,
        adminReference: adminAccountAdminReference,
      });

      if (createResponse?.data?.status === 'success') {
        const removeResponse = await handleRemoveTechnicalLog(technicalLogId);
        if (removeResponse === 'success') return 'success';
      }
    }
  }

  async function handleChangeTechnicalLogArchive(technicalLogId, changeObj) {
    if (!changeTechnicalLogArchiveIsLoading) {
      await changeTechnicalLogArchive({
        adminAccountId,
        technicalLogId,
        changeObj,
      });
    }
  }

  async function handleRemoveTechnicalLogArchive(technicalLogArchiveId) {
    if (!removeTechnicalLogArchiveIsLoading) {
      await removeTechnicalLogArchive({
        adminAccountId,
        technicalLogArchiveId,
      });
    }
  }

  //Sorting
  function handleAdminTechnicalDateSorting(sortingBoolean) {
    const copyArr = [...adminTechnicalLogs];

    let newSortingOrder;
    if (sortingBoolean === true) {
      newSortingOrder = 'descending';
    } else {
      newSortingOrder = 'ascending';
    }

    const sortedTechnicalLogs = copyArr.sort((a, b) => {
      const dateA = new Date(a.meta.createdAt);
      const dateB = new Date(b.meta.createdAt);

      if (newSortingOrder === 'descending') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    handleAdminTechnicalSetTechnicalLogs(sortedTechnicalLogs);
    handleAdminSettingsTechnicalSortingDate(newSortingOrder);
  }

  //Socket
  function handleSocketTechnicalLogData(dataString) {
    let parsedData;
    try {
      parsedData = JSON.parse(dataString);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          'Dev: handleSocketTechnicalLogData, Error parsing JSON:',
          error
        );
      }
    }

    const docId = parsedData?.id;
    const operationType = parsedData.operationType;

    if (operationType) {
      if (operationType === 'delete' && docId) {
        handleAdminTechnicalLogRemoveTechnicalLogById(docId);
      } else if (
        parsedData.operationType === 'update' ||
        parsedData.operationType === 'insert'
      ) {
        if (parsedData?.technicalLog) {
          handleAdminTechnicalLogSetTechnicalLog(parsedData.technicalLog);
        }
      }
    }
  }

  function handleSocketTechnicalLogArchiveData(dataString) {
    let parsedData;
    try {
      parsedData = JSON.parse(dataString);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          'Dev: handleSocketTechnicalLogArchiveData, Error parsing JSON:',
          error
        );
      }
    }

    const docId = parsedData?.id;
    const operationType = parsedData.operationType;

    if (operationType) {
      if (operationType === 'delete' && docId) {
        handleAdminTechnicalLogArchiveRemoveTechnicalLogArchiveById(docId);
      } else if (
        parsedData.operationType === 'update' ||
        parsedData.operationType === 'insert'
      ) {
        if (parsedData?.technicalLogArchive) {
          handleAdminTechnicalLogArchiveSetTechnicalLogArchive(
            parsedData.technicalLogArchive
          );
        }
      }
    }
  }

  return {
    adminTechnicalLogs,
    adminTechnicalLogsArchive,
    handleAdminTechnicalDateSorting,
    handleAdminTechnicalSetTechnicalLogs,
    handleAdminTechnicalSetTechnicalLogsArchive,
    handleCreateTechnicalLogArchive,
    handleRemoveTechnicalLog,
    handleChangeTechnicalLogArchive,
    handleRemoveTechnicalLogArchive,
    handleAdminTechnicalLogRemoveTechnicalLogById,
    handleSocketTechnicalLogData,
    handleAdminTechnicalLogSetTechnicalLog,
    handleSocketTechnicalLogArchiveData,
    handleAdminTechnicalLogArchiveSetTechnicalLogArchive,
    handleAdminTechnicalLogArchiveRemoveTechnicalLogArchiveById,
    changeTechnicalLogArchiveIsLoading,
    removeTechnicalLogArchiveIsLoading,
    createTechnicalLogArchiveIsLoading,
    removeTechnicalLogIsLoading,
  };
};

export default useAdminTechnicalHandlers;
