//hooks

import useLanguageData from '../../../../../../language/useLanguageData';

//components
//utils

const InfoCardContainerAppSettings = ({ appSettingsData }) => {
  const { LanguageOpts, TimeZoneOpts } = useLanguageData();

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">App Settings</p>
      <p className="mrg-t6">
        Content Langauge: {LanguageOpts[appSettingsData?.contentLanguage]}
      </p>
      {appSettingsData?.timeZone && (
        <p className="mrg-t6">
          Time Zone: {TimeZoneOpts[appSettingsData?.timeZone]}
        </p>
      )}
    </div>
  );
};

export default InfoCardContainerAppSettings;
