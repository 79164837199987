function moveCarotToEnd(ref, id) {
  if (ref) {
    ref.focus();
    ref.setSelectionRange(ref.value?.length, ref.value?.length);
  } else if (id) {
    const element = document.getElementById(id);
    element.setSelectionRange(element.value?.length, element.value?.length);
  }
}

export default moveCarotToEnd;
