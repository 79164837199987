import { useEffect, useRef, useState } from 'react';
//Hooks
import useLanguageData from '../../../../language/useLanguageData';
//Components
import { dropdown_arrow, lock_locked } from '../../../../assets/icons';
import { MapContainerStatic } from '../../../../components/MapContainer';
//Utility

const HostingInPersonContent = ({
  eventData,
  EventPage,
  eventStatus,
  handleMobileTap,
  organizerViewOn,
  previewMode,
}) => {
  //Hooks
  const { CountriesOpts } = useLanguageData();

  //Component State
  const [showMap, setShowMap] = useState(false);
  const [mapLocked, setMapLocked] = useState(false);
  const [mapCoords, setMapCoords] = useState([]);

  //UI state
  const [tapHighlightShowMapBtn, setTapHighlightShowMapBtn] = useState(false);

  //Component Variables
  const effectRan = useRef(false);

  const privateDetails = eventData.registrationData.privateDetails.enabled;
  const roomLocked = eventData.registrationData.privateDetails.room;
  const locationNameLocked =
    eventData.registrationData.privateDetails.locationName;
  const streetAddressLocked =
    eventData.registrationData.privateDetails.streetAddress;

  let roomInfo;
  let locationNameInfo;
  let streetAddressInfo;
  let cityCountryInfo;

  //Initialize functions
  useEffect(() => {
    if (
      privateDetails &&
      (locationNameLocked || streetAddressLocked) &&
      (eventStatus !== 'registeredInPerson' ||
        eventStatus !== 'registeredVirtual' ||
        eventStatus !== 'pendingApprovalInPerson-registeredVirtual' ||
        eventStatus !== 'pendingApprovalVirtual-registeredInPerson') &&
      !organizerViewOn
    ) {
      if (!mapLocked) {
        setMapLocked(true);
      }
    } else {
      if (mapLocked) {
        setMapLocked(false);
      }
    }
  }, [eventStatus, streetAddressLocked, roomLocked, privateDetails]);

  useEffect(() => {
    if (effectRan.current === false) {
      if (previewMode) {
        setMapCoords([
          eventData.hosting.inPerson?.geoPosition?.coordinates[1],
          eventData.hosting.inPerson?.geoPosition?.coordinates[0],
        ]);
      } else {
        setMapCoords(eventData.hosting.inPerson?.geoPosition?.coordinates);
      }
      effectRan.current = true;
    }
  }, []); //need to reverse map coords in preview mode

  //UI functions
  function handleShowMapBtn() {
    if (!mapLocked) {
      return handleMobileTap(
        [
          () => setTapHighlightShowMapBtn(true),
          () => setTapHighlightShowMapBtn(false),
        ],
        [() => setShowMap(!showMap)]
      );
    } else {
      return null;
    }
  }

  //JSX conditional
  //ROOM
  if (eventData.hosting?.inPerson?.location?.room) {
    if (
      privateDetails &&
      roomLocked &&
      (eventStatus !== 'registeredInPerson' ||
        eventStatus !== 'registeredVirtual' ||
        eventStatus !== 'pendingApprovalInPerson-registeredVirtual' ||
        eventStatus !== 'pendingApprovalVirtual-registeredInPerson') &&
      !organizerViewOn
    ) {
      roomInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.room}</p>
          <img src={lock_locked} alt="" className="lock-img mrg-l12" />
        </div>
      );
    } else if (organizerViewOn) {
      roomInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.room}</p>
          <img src={lock_locked} alt="" className="lock-img mrg-l12" />
          <p className="mrg-l12">
            {`${eventData.hosting.inPerson.location.room}`}
          </p>
        </div>
      );
    } else {
      roomInfo = (
        <p className="info-row access-ob access-o6" tabIndex="0">
          {`${EventPage.room} ${eventData.hosting.inPerson.location.room}`}
        </p>
      );
    }
  }
  //LOCATION NAME
  if (eventData.hosting?.inPerson?.location?.locationName) {
    if (
      privateDetails &&
      locationNameLocked &&
      (eventStatus !== 'registeredInPerson' ||
        eventStatus !== 'registeredVirtual' ||
        eventStatus !== 'pendingApprovalInPerson-registeredVirtual' ||
        eventStatus !== 'pendingApprovalVirtual-registeredInPerson') &&
      !organizerViewOn
    ) {
      locationNameInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.locationName}</p>
          <img src={lock_locked} alt="" className="lock-img mrg-l12" />
        </div>
      );
    } else if (organizerViewOn) {
      locationNameInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.locationName}</p>
          <img src={lock_locked} alt="" className="lock-img mrg-l12" />
          <p className="mrg-l12">
            {`${eventData.hosting.inPerson.location.locationName}`}
          </p>
        </div>
      );
    } else {
      locationNameInfo = (
        <p className="info-row access-ob access-o6" tabIndex="0">
          {`${eventData.hosting.inPerson.location.locationName}`}
        </p>
      );
    }
  }

  //STREET ADDRESS
  if (eventData.hosting?.inPerson?.location?.streetAddress) {
    if (
      privateDetails &&
      streetAddressLocked &&
      (eventStatus !== 'registeredInPerson' ||
        eventStatus !== 'registeredVirtual' ||
        eventStatus !== 'pendingApprovalInPerson-registeredVirtual' ||
        eventStatus !== 'pendingApprovalVirtual-registeredInPerson') &&
      !organizerViewOn
    ) {
      streetAddressInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.streetAddress}</p>
          <img src={lock_locked} alt="" className="lock-img mrg-l12" />
        </div>
      );
    } else if (organizerViewOn) {
      streetAddressInfo = (
        <div className="info-row access-ob access-o6" tabIndex="0">
          <p>{EventPage.streetAddress}</p>
          <img src={lock_locked} alt="" className="lock-img mrg-l12" />
          <p className="mrg-l12">{`${eventData.hosting.inPerson.location.streetAddress}`}</p>
        </div>
      );
    } else {
      streetAddressInfo = (
        <p className="info-row access-ob access-o6" tabIndex="0">
          {`${eventData.hosting.inPerson.location.streetAddress}`}
        </p>
      );
    }
  }

  //City + Country
  if (eventData.hosting?.inPerson?.location?.city) {
    cityCountryInfo = (
      <p className="info-row access-ob access-o6" tabIndex="0">
        {eventData.hosting?.inPerson.location.city},&nbsp;
        {CountriesOpts[eventData.hosting.inPerson.location.country]}
      </p>
    );
  } else {
    cityCountryInfo = (
      <p className="info-row access-ob access-o6" tabIndex="0">
        {CountriesOpts[eventData.hosting.inPerson.location.country]}
      </p>
    );
  }

  return (
    <>
      <div className="h24" />
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="locationLabel"
      >
        {EventPage.inPersonAttendance}
      </label>
      <div className="flex-column">
        {roomInfo}
        {locationNameInfo}
        {streetAddressInfo}
        {cityCountryInfo}
      </div>

      <button
        className={`map-btn flex-row mrg-tb24 br--standard ${
          !mapLocked ? 'highlight-btreg-theme highlight-i-gt' : 'border-reg'
        } ${tapHighlightShowMapBtn ? 'highlight-b2-theme--tap' : ''}`}
        onClick={handleShowMapBtn}
      >
        <p className="fs18 fwn">{EventPage.showMap}</p>
        {organizerViewOn && (
          <div className="flex-center mrg-l6">
            <img src={lock_locked} alt="" className="lock-img" />
          </div>
        )}
        {mapLocked ? (
          <img src={lock_locked} alt="" className="lock-img mrg-l12" />
        ) : (
          <img
            src={dropdown_arrow}
            alt=""
            className={
              showMap ? 'dropdown-arrow' : 'dropdown-arrow reverse-vertical'
            }
          />
        )}
      </button>
      {showMap && !mapLocked && <MapContainerStatic loadValue={mapCoords} />}
    </>
  );
};

export default HostingInPersonContent;
