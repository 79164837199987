import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditEvent,
  selectEditEventDateTimeDate,
  selectEditEventDateTimeTimeEnd,
  selectEditEventDateTimeTimeStart,
  selectEditEventDateTimeTimeZone,
  selectEditEventGenericAccessibilityNotes,
  selectEditEventGenericAgendaItems,
  selectEditEventGenericAgendaFormat,
  selectEditEventGenericAudienceType,
  selectEditEventGenericDescription,
  selectEditEventGenericEventImagePath,
  selectEditEventGenericEventType,
  selectEditEventGenericLanguages,
  selectEditEventGenericTitle,
  selectEditEventGenericWheelchairAccessibility,
  selectEditEventGenericRealtimeTranslations,
  selectEditEventGenericSignLanguageInterpreter,
  selectEditEventHostingInPersonGeoPositionCoordinates,
  selectEditEventHostingHostingOption,
  selectEditEventHostingInPersonLocation,
  selectEditEventHostingVirtualMeetingId,
  selectEditEventHostingVirtualPasscode,
  selectEditEventHostingVirtualPlatform,
  selectEditEventHostingVirtualLink,
  selectEditEventMetaLastModified,
  selectEditEventMetaSaveName,
  selectEditEventParticipantsModerator,
  selectEditEventParticipantsSpeaker0Data,
  selectEditEventParticipantsSpeaker1Data,
  selectEditEventParticipantsSpeaker2Data,
  selectEditEventParticipantsSpeaker3Data,
  selectEditEventParticipantsSpeaker4Data,
  selectEditEventParticipantsSpeaker5Data,
  selectEditEventRegistrationDataApproveRegistrations,
  selectEditEventRegistrationDataMaxRegistrationsInPersonEnabled,
  selectEditEventRegistrationDataMaxRegistrationsVirtualEnabled,
  selectEditEventRegistrationDataPrivateDetailsEnabled,
  selectEditEventRegistrationDataMaxRegistrationsInPersonAmount,
  selectEditEventRegistrationDataMaxRegistrationsVirtualAmount,
  selectEditEventRegistrationDataPrivateDetails,
  selectEditEventRegistrationDataRegistrationsActive,
  selectEditEventSchedulePublishDate,
  selectEditEventSchedulePublishTime,
  selectEditEventSchedulePublishTimeZone,
  selectEditEventTagsBigTags,
  selectEditEventTagsTinyTags,
  setEditEventDateTimeDate,
  setEditEventDateTimeTimeEnd,
  setEditEventDateTimeTimeStart,
  setEditEventDateTimeTimeZone,
  setEditEventGenericAccessibilityNotes,
  setEditEventGenericAgendaItems,
  setEditEventGenericAgendaFormat,
  setEditEventGenericAudienceType,
  setEditEventGenericDescription,
  setEditEventGenericEventType,
  setEditEventGenericLanguages,
  setEditEventGenericSignLanguageInterpreter,
  setEditEventGenericTitle,
  setEditEventGenericWheelchairAccessibility,
  setEditEventGenericRealtimeTranslations,
  setEditEventHostingGeoPositionCoordinates,
  setEditEventHostingHostingOption,
  setEditEventHostingInPersonLocation,
  setEditEventHostingVirtualMeetingId,
  setEditEventHostingVirtualPasscode,
  setEditEventHostingVirtualPlatform,
  setEditEventHostingVirtualLink,
  setEditEventMetaSaveName,
  setEditEventParticipantsModerator,
  setEditEventParticipantsSpeaker0Data,
  setEditEventParticipantsSpeaker1Data,
  setEditEventParticipantsSpeaker2Data,
  setEditEventParticipantsSpeaker3Data,
  setEditEventParticipantsSpeaker4Data,
  setEditEventParticipantsSpeaker5Data,
  setEditEventRegistrationDataApproveRegistrations,
  setEditEventRegistrationDataMaxRegistrationsInPersonEnabled,
  setEditEventRegistrationDataMaxRegistrationsVirtualEnabled,
  setEditEventRegistrationDataPrivateDetailsEnabled,
  setEditEventRegistrationDataMaxRegistrationsInPersonAmount,
  setEditEventRegistrationDataMaxRegistrationsVirtualAmount,
  setEditEventRegistrationDataPrivateDetails,
  setEditEventRegistrationDataRegistrationsActive,
  setEditEventSchedulePublishDate,
  setEditEventSchedulePublishTime,
  setEditEventSchedulePublishTimeZone,
  addEditEventTagsBigTag,
  addEditEventTagsTinyTag,
  removeEditEventTagsBigTag,
  removeEditEventTagsTinyTag,
  setEditEventTagsBigTags,
  setEditEventTagsTinyTags,
  resetEditEvent,
  setEditEvent,
  reverseEditEventHostingGeoPositionCoordinates,
  setEditEventGenericEventImagePath,
  setEditEventScheduleEnabled,
  selectEditEventScheduleEnabled,
  selectEditEventChangeList,
  setEditEventChangeList,
} from './editEventSlice';
//Hooks

//Components

//Utility

export const useEditEventReduxHandlers = () => {
  const dispatch = useDispatch();

  const isEditEventStateInitialized = Boolean(selectEditEvent);
  const editEventChangeList = useSelector(selectEditEventChangeList);
  //dateTime
  const date = useSelector(selectEditEventDateTimeDate);
  const timeEnd = useSelector(selectEditEventDateTimeTimeEnd);
  const timeStart = useSelector(selectEditEventDateTimeTimeStart);
  const timeZone = useSelector(selectEditEventDateTimeTimeZone);

  //Generic
  const accessibilityNotes = useSelector(
    selectEditEventGenericAccessibilityNotes
  );
  const agendaItems = useSelector(selectEditEventGenericAgendaItems);
  const agendaFormat = useSelector(selectEditEventGenericAgendaFormat);
  const audienceType = useSelector(selectEditEventGenericAudienceType);
  const description = useSelector(selectEditEventGenericDescription);
  const eventImagePath = useSelector(selectEditEventGenericEventImagePath);
  const eventType = useSelector(selectEditEventGenericEventType);
  const languages = useSelector(selectEditEventGenericLanguages);
  const realtimeTranslations = useSelector(
    selectEditEventGenericRealtimeTranslations
  );
  const signLanguageInterpreter = useSelector(
    selectEditEventGenericSignLanguageInterpreter
  );
  const title = useSelector(selectEditEventGenericTitle);
  const wheelchairAccessibility = useSelector(
    selectEditEventGenericWheelchairAccessibility
  );

  //Hosting
  const hostingOption = useSelector(selectEditEventHostingHostingOption);
  const coordinates = useSelector(
    selectEditEventHostingInPersonGeoPositionCoordinates
  );
  const virtualMeetingId = useSelector(selectEditEventHostingVirtualMeetingId);
  const virtualPasscode = useSelector(selectEditEventHostingVirtualPasscode);
  const virtualPlatform = useSelector(selectEditEventHostingVirtualPlatform);
  const virtualLink = useSelector(selectEditEventHostingVirtualLink);
  const location = useSelector(selectEditEventHostingInPersonLocation);

  //Meta
  const lastModified = useSelector(selectEditEventMetaLastModified);
  const saveName = useSelector(selectEditEventMetaSaveName);
  const editEventData = useSelector(selectEditEvent);

  //Participants
  const moderator = useSelector(selectEditEventParticipantsModerator);
  const speaker0Data = useSelector(selectEditEventParticipantsSpeaker0Data);
  const speaker1Data = useSelector(selectEditEventParticipantsSpeaker1Data);
  const speaker2Data = useSelector(selectEditEventParticipantsSpeaker2Data);
  const speaker3Data = useSelector(selectEditEventParticipantsSpeaker3Data);
  const speaker4Data = useSelector(selectEditEventParticipantsSpeaker4Data);
  const speaker5Data = useSelector(selectEditEventParticipantsSpeaker5Data);

  //RegistrationData
  const approveRegistrations = useSelector(
    selectEditEventRegistrationDataApproveRegistrations
  );
  const maxRegistrationsInPersonEnabled = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsInPersonEnabled
  );
  const maxRegistrationsVirtualEnabled = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsVirtualEnabled
  );
  const maxRegistrationsInPersonAmount = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsInPersonAmount
  );
  const maxRegistrationsVirtualAmount = useSelector(
    selectEditEventRegistrationDataMaxRegistrationsVirtualAmount
  );
  const registrationsActive = useSelector(
    selectEditEventRegistrationDataRegistrationsActive
  );
  const privateDetails = useSelector(
    selectEditEventRegistrationDataPrivateDetails
  );
  const enablePrivateDetails = useSelector(
    selectEditEventRegistrationDataPrivateDetailsEnabled
  );

  //SchedulePublish
  const scheduleEnabled = useSelector(selectEditEventScheduleEnabled);
  const schedulePublishDate = useSelector(selectEditEventSchedulePublishDate);
  const schedulePublishTime = useSelector(selectEditEventSchedulePublishTime);
  const schedulePublishTimeZone = useSelector(
    selectEditEventSchedulePublishTimeZone
  );

  //Tags
  const bigTags = useSelector(selectEditEventTagsBigTags);
  const tinyTags = useSelector(selectEditEventTagsTinyTags);

  ////HANDLERS

  function handleEditEventReset(accountId) {
    dispatch(resetEditEvent());
  }

  function handleEditEventSetEvent(eventData) {
    dispatch(setEditEvent(eventData));
  }
  function handleEditEventChangeList(arr) {
    dispatch(setEditEventChangeList(arr));
  }

  //dateTime
  function handleEditEventSetDate(str) {
    dispatch(setEditEventDateTimeDate(str));
  }
  function handleEditEventSetTimeZone(str) {
    dispatch(setEditEventDateTimeTimeZone(str));
  }
  function handleEditEventSetTimeStart(str) {
    dispatch(setEditEventDateTimeTimeStart(str));
  }
  function handleEditEventSetTimeEnd(str) {
    dispatch(setEditEventDateTimeTimeEnd(str));
  }

  //Generic

  function handleEditEventSetAgendaFormat(str) {
    dispatch(setEditEventGenericAgendaFormat(str));
  }

  function handleEditEventSetAgendaItems(arr) {
    dispatch(setEditEventGenericAgendaItems(arr));
  }

  function handleEditEventSetTitle(str) {
    dispatch(setEditEventGenericTitle(str));
  }
  function handleEditEventSetDescription(str) {
    dispatch(setEditEventGenericDescription(str));
  }
  function handleEditEventSetEventType(str) {
    dispatch(setEditEventGenericEventType(str));
  }
  function handleEditEventSetEventImagePath(str) {
    dispatch(setEditEventGenericEventImagePath(str));
  }
  function handleEditEventSetAudienceType(str) {
    dispatch(setEditEventGenericAudienceType(str));
  }

  function handleEditEventSetLanguages(arr) {
    dispatch(setEditEventGenericLanguages(arr));
  }

  function handleEditEventSetWheelchairAccessibility(boolean) {
    dispatch(setEditEventGenericWheelchairAccessibility(boolean));
  }

  function handleEditEventSetRealtimeTranslations(boolean) {
    dispatch(setEditEventGenericRealtimeTranslations(boolean));
  }

  function handleEditEventSetSignLanguageInterpreter(signLanguageInterpreter) {
    dispatch(
      setEditEventGenericSignLanguageInterpreter(signLanguageInterpreter)
    );
  }

  function handleEditEventSetAccessibilityNotes(str) {
    dispatch(setEditEventGenericAccessibilityNotes(str));
  }

  //Hosting

  function handleEditEventSetHostingOption(str) {
    dispatch(setEditEventHostingHostingOption(str));
  }

  function handleEditEventSetVirtualPlatform(str) {
    dispatch(setEditEventHostingVirtualPlatform(str));
  }

  function handleEditEventSetVirtualMeetingId(str) {
    dispatch(setEditEventHostingVirtualMeetingId(str));
  }

  function handleEditEventSetVirtualMeetingLink(str) {
    dispatch(setEditEventHostingVirtualLink(str));
  }

  function handleEditEventSetVirtualPasscode(str) {
    dispatch(setEditEventHostingVirtualPasscode(str));
  }

  function handleEditEventSetLocation(obj) {
    dispatch(setEditEventHostingInPersonLocation(obj));
  }

  function handleEditEventClearLocation() {
    dispatch(
      setEditEventHostingInPersonLocation({
        room: '',
        locationName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        placeId: '',
      })
    );
    dispatch(setEditEventHostingGeoPositionCoordinates([0, 0]));
  }

  function handleEditEventSetCoordinates(arr) {
    dispatch(setEditEventHostingGeoPositionCoordinates(arr));
  }

  function handleReverseEditEventHostingGeoPositionCoordinates() {
    dispatch(reverseEditEventHostingGeoPositionCoordinates());
  }

  //Meta
  function handleEditEventSetSaveName(saveName) {
    dispatch(setEditEventMetaSaveName(saveName));
  }

  //Participants
  function handleEditEventSetModerator(str) {
    dispatch(setEditEventParticipantsModerator(str));
  }

  function handleEditEventSetSpeaker0Data(speaker0Data) {
    dispatch(setEditEventParticipantsSpeaker0Data(speaker0Data));
  }

  function handleEditEventSetSpeaker1Data(speaker1Data) {
    dispatch(setEditEventParticipantsSpeaker1Data(speaker1Data));
  }

  function handleEditEventSetSpeaker2Data(speaker2Data) {
    dispatch(setEditEventParticipantsSpeaker2Data(speaker2Data));
  }

  function handleEditEventSetSpeaker3Data(speaker3Data) {
    dispatch(setEditEventParticipantsSpeaker3Data(speaker3Data));
  }

  function handleEditEventSetSpeaker4Data(speaker4Data) {
    dispatch(setEditEventParticipantsSpeaker4Data(speaker4Data));
  }

  function handleEditEventSetSpeaker5Data(speaker5Data) {
    dispatch(setEditEventParticipantsSpeaker5Data(speaker5Data));
  }

  //RegistrationData

  function handleEditEventSetRegistrations(boolean) {
    dispatch(setEditEventRegistrationDataRegistrationsActive(boolean));
  }

  function handleEditEventSetApproveRegistrations(boolean) {
    dispatch(setEditEventRegistrationDataApproveRegistrations(boolean));
  }

  function handleEditEventSetMaxRegistrationsInPersonEnabled(boolean) {
    dispatch(
      setEditEventRegistrationDataMaxRegistrationsInPersonEnabled(boolean)
    );
  }

  function handleEditEventSetMaxRegistrationsInPersonAmount(num) {
    dispatch(setEditEventRegistrationDataMaxRegistrationsInPersonAmount(num));
  }

  function handleEditEventSetMaxRegistrationsVirtualEnabled(boolean) {
    dispatch(
      setEditEventRegistrationDataMaxRegistrationsVirtualEnabled(boolean)
    );
  }

  function handleEditEventSetMaxRegistrationsVirtualAmount(num) {
    dispatch(setEditEventRegistrationDataMaxRegistrationsVirtualAmount(num));
  }

  function handleEditEventSetEnablePrivateDetails(enablePrivateDetails) {
    dispatch(
      setEditEventRegistrationDataPrivateDetailsEnabled(enablePrivateDetails)
    );
  }

  function handleEditEventSetPrivateDetails(id, val) {
    dispatch(setEditEventRegistrationDataPrivateDetails({ id, val }));
  }

  //SchedulePublish
  function handleEditEventSetScheduleEnabled(boolean) {
    dispatch(setEditEventScheduleEnabled(boolean));
  }
  function handleEditEventSetSchedulePublishDate(str) {
    dispatch(setEditEventSchedulePublishDate(str));
  }
  function handleEditEventSetSchedulePublishTime(str) {
    dispatch(setEditEventSchedulePublishTime(str));
  }
  function handleEditEventSetSchedulePublishTimeZone(str) {
    dispatch(setEditEventSchedulePublishTimeZone(str));
  }

  //Tags
  function handleEditEventAddBigTag(str) {
    dispatch(addEditEventTagsBigTag(str));
  }

  function handleEditEventRemoveBigTag(str) {
    dispatch(removeEditEventTagsBigTag(str));
  }

  function handleEditEventAddTinyTag(str) {
    dispatch(addEditEventTagsTinyTag(str));
  }

  function handleEditEventRemoveTinyTag(str) {
    dispatch(removeEditEventTagsTinyTag(str));
  }

  function handleEditEventSetBigTags(arr) {
    dispatch(setEditEventTagsBigTags(arr));
  }

  function handleEditEventSetTinyTags(arr) {
    dispatch(setEditEventTagsTinyTags(arr));
  }

  return {
    isEditEventStateInitialized,
    maxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount,
    accessibilityNotes,
    agendaFormat,
    agendaItems,
    approveRegistrations,
    audienceType,
    bigTags,
    coordinates,
    editEventData,
    date,
    description,
    enablePrivateDetails,
    eventImagePath,
    eventType,
    hostingOption,
    languages,
    lastModified,
    location,
    moderator,
    privateDetails,
    realtimeTranslations,
    registrationsActive,
    saveName,
    schedulePublishDate,
    schedulePublishTime,
    schedulePublishTimeZone,
    signLanguageInterpreter,
    speaker0Data,
    speaker1Data,
    speaker2Data,
    speaker3Data,
    speaker4Data,
    speaker5Data,
    timeEnd,
    timeStart,
    timeZone,
    tinyTags,
    title,
    virtualLink,
    virtualMeetingId,
    virtualPasscode,
    virtualPlatform,
    wheelchairAccessibility,
    handleEditEventSetAccessibilityNotes,
    handleEditEventAddBigTag,
    handleEditEventAddTinyTag,
    handleEditEventSetAgendaFormat,
    handleEditEventSetAgendaItems,
    handleEditEventSetApproveRegistrations,
    handleEditEventSetAudienceType,
    handleEditEventClearLocation,
    handleEditEventSetCoordinates,
    handleEditEventSetDate,
    handleEditEventSetDescription,
    handleEditEventSetEnablePrivateDetails,
    handleEditEventSetEventType,
    handleEditEventSetHostingOption,
    handleEditEventSetLanguages,
    handleEditEventSetLocation,
    handleEditEventSetModerator,
    handleEditEventSetPrivateDetails,
    handleEditEventSetRegistrations,
    handleEditEventSetRealtimeTranslations,
    handleEditEventRemoveBigTag,
    handleEditEventRemoveTinyTag,
    handleEditEventReset,
    handleEditEventSetSaveName,
    handleEditEventSetSchedulePublishDate,
    handleEditEventSetSchedulePublishTime,
    handleEditEventSetSchedulePublishTimeZone,
    handleEditEventSetEvent,
    handleEditEventSetSignLanguageInterpreter,
    handleEditEventSetSpeaker0Data,
    handleEditEventSetSpeaker1Data,
    handleEditEventSetSpeaker2Data,
    handleEditEventSetSpeaker3Data,
    handleEditEventSetSpeaker4Data,
    handleEditEventSetSpeaker5Data,
    handleEditEventSetTimeEnd,
    handleEditEventSetTimeStart,
    handleEditEventSetTimeZone,
    handleEditEventSetTitle,
    handleEditEventSetVirtualMeetingId,
    handleEditEventSetVirtualPasscode,
    handleEditEventSetVirtualPlatform,
    handleEditEventSetVirtualMeetingLink,
    handleEditEventSetWheelchairAccessibility,
    handleEditEventSetBigTags,
    handleEditEventSetTinyTags,
    handleEditEventSetMaxRegistrationsInPersonEnabled,
    handleEditEventSetMaxRegistrationsInPersonAmount,
    handleEditEventSetMaxRegistrationsVirtualEnabled,
    handleEditEventSetMaxRegistrationsVirtualAmount,
    handleReverseEditEventHostingGeoPositionCoordinates,
    handleEditEventSetEventImagePath,
    handleEditEventSetScheduleEnabled,
    scheduleEnabled,
    editEventChangeList,
    handleEditEventChangeList,
  };
};
