import { getTimeFromTimeStamp } from '../../../../utils/dateTime/DateCalc';

export async function handleDownloadTranscriptForHost(
  downloadOption,
  userSessionIdTranslations,
  roomCode,
  transcriptData,
  speakersData
) {
  const transcriptID = `ID: ${userSessionIdTranslations.slice(
    -12
  )}\n RoomCode: ${roomCode}\n\n`;
  let transcriptLines = [];
  let speakersLines = [];

  let speakerText = '';
  let transcriptText = transcriptLines.join('\n');

  speakersData?.forEach((speakerObj, index) => {
    const speakerLine = `${speakerObj.name}:\n${speakerObj.languageOpt}\n${
      speakerObj.discussionContext
    }\n${speakerObj.keywordsList}${
      index === speakersData?.length - 1 ? '\n\n' : '\n'
    }`;
    speakersLines.push(speakerLine);
  });

  speakerText = speakersLines.join('\n');

  transcriptData.forEach((translationObj) => {
    const timeStamp = getTimeFromTimeStamp(translationObj.timeStamp);
    const translationVal = Object.values(translationObj.translationObj);
    const formattedLine = `${timeStamp}: ${translationVal[0]}\n`;
    transcriptLines.push(formattedLine);
  });

  transcriptText = transcriptLines.join('\n');

  if (downloadOption) {
    const blob = new Blob([transcriptID, speakerText, transcriptText], {
      type: 'text/plain',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `MyndFull-${roomCode}.txt`;
    // Append the link to the body (required for Firefox)
    document.body.appendChild(a);
    a.click(); // Trigger the download

    // Clean up
    document.body.removeChild(a);
    return URL.revokeObjectURL(url);
  } else {
    return `${transcriptID}${speakerText}${transcriptText}`;
  }
}

export async function handleDownloadTranscriptForAttendee(
  userSessionIdTranslations,
  roomCode,
  transcriptData
) {
  const transcriptID = `ID: ${userSessionIdTranslations.slice(
    -12
  )}\n RoomCode: ${roomCode}\n\n`;
  let transcriptLines = [];

  let transcriptText = transcriptLines.join('\n');

  transcriptData.forEach((translationObj) => {
    const timeStamp = getTimeFromTimeStamp(translationObj.timeStamp);
    const formattedLine = `${timeStamp}: ${translationObj.translatedText}\n`;
    transcriptLines.push(formattedLine);
  });

  transcriptText = transcriptLines.join('\n');
  return `${transcriptID}${transcriptText}`;
}
