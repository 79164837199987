//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//Components

//Utility
import { saved, bigtag, tinytag, social } from '../../../../assets/icons';

const Views = ({ view }) => {
  //Hooks
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { handleSettingsSetEventView } = useSettingsHandlers();

  //Component variables
  let content;

  //JSX conditional
  if (SettingsEventsLanguage) {
    content = (
      <ul className="view__container">
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetEventView('myevents')}
            title={SettingsEventsLanguage.myEvents}
            aria-label={SettingsEventsLanguage.myEvents}
            id="view-myevents"
            aria-pressed={view === 'myevents'}
          >
            <img
              src={saved}
              alt={SettingsEventsLanguage.myEvents}
              className={view === 'myevents' ? 'selected' : ''}
            />
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetEventView('bigtags')}
            title={SettingsEventsLanguage.bigTags}
            aria-label={SettingsEventsLanguage.bigTags}
            id="view-bigtags"
            aria-pressed={view === 'bigtags'}
          >
            <img
              src={bigtag}
              alt={SettingsEventsLanguage.bigTags}
              className={view === 'bigtags' ? 'selected' : ''}
            />
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetEventView('tinytags')}
            title={SettingsEventsLanguage.tinyTags}
            aria-label={SettingsEventsLanguage.tinyTags}
            id="view-tinytags"
            aria-pressed={view === 'tinytags'}
          >
            <img
              src={tinytag}
              alt={SettingsEventsLanguage.tinyTags}
              className={view === 'tinytags' ? 'selected' : ''}
            />
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetEventView('network')}
            title={SettingsEventsLanguage.network}
            aria-label={SettingsEventsLanguage.network}
            id="view-network"
            aria-pressed={view === 'network'}
          >
            <img
              src={social}
              alt={SettingsEventsLanguage.network}
              className={view === 'network' ? 'selected' : ''}
            />
          </button>
        </li>
      </ul>
    );
  }

  return content;
};

export default Views;
