import { useState, useEffect } from 'react';
//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import ItemButton from '../ItemButton/ItemButton';
import SelectDropdown from './SelectDropdown';

//Utility

//1. selections list requires 'select' to be first default value in data list.
//2. must make sure data being uploaded does not include 'select' in array
const SelectDropdownMultiple = ({
  id,
  list,
  selections,
  handleSelectionChange,
  addButtonLabel,
  modalLabel,
  customWrapper,
}) => {
  //Hooks
  const { SelectDropdownMultiple } = useLanguageComponents();

  //Component state
  const [filteredList, setFilteredList] = useState([]);
  const [lastSelectedDropdownIndex, setLastSelectedDropdownIndex] = useState();
  const [initialized, setInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //UI
  const [renderDelayComplete, setRenderDelayComplete] = useState(false);

  //Component variables
  const itemButtonEl = document.getElementById(`item-button-${id}`);
  const el = document.getElementById(
    `${id}-${lastSelectedDropdownIndex}-dropdown-main`
  );
  let itemButtonReplacement; //need to add replacement based on conditions; but ItemButton component needs to remain in JSX for focus() logic otherwise i would have also put ItemButton with itemButtonReplacement logic

  //Initialize & UI functions

  useEffect(() => {
    if (el && !initialized) {
      setInitialized(true);
    }
  }, [el, setInitialized]);

  useEffect(() => {
    if (el && lastSelectedDropdownIndex) {
      el.focus();
    }
  }, [el]);

  useEffect(() => {
    const renderDelayTimer = setTimeout(() => {
      setRenderDelayComplete(true);
    }, 50);

    return () => clearTimeout(renderDelayTimer);
  }, []); //prevent the no selections available button from initially appearing if button is being rendered based on truthy value

  //Component functions
  useEffect(() => {
    const newFilteredList = Object.entries(list).filter(
      ([key]) => !selections?.includes(key)
    );
    const filteredObj = newFilteredList.reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
    setFilteredList(filteredObj);
  }, [list, selections, setFilteredList]); //when selections updates, will set a filtered list to remove the object with the corresponding key thats included in the selections array

  function addButton() {
    setIsLoading(true);
    const [key, value] =
      Object.entries(filteredList).find(
        ([key, value]) => !(key in selections)
      ) || [];

    if (key) {
      const newSelections = [...selections, 'select'];
      handleSelectionChange(newSelections);
    }
    setIsLoading(false);
  } //adds selection from filtered list

  function multiDropdownRemoveButton(removeKey) {
    setIsLoading(true);
    let newList = selections.filter((key) => key !== removeKey);
    handleSelectionChange(newList);
    if (itemButtonEl) itemButtonEl.focus();
    setIsLoading(false); //prevents itemButtonReplacement from briefly rendering
  }

  function handleDropdownSelection(newKey, oldKey, childIndex) {
    const newList = selections.filter((key) => key !== oldKey);
    newList.splice(childIndex, 0, newKey);
    handleSelectionChange(newList);
    setLastSelectedDropdownIndex(childIndex);
  }

  //JSX conditional
  if (!filteredList.hasOwnProperty('select')) {
    itemButtonReplacement = (
      <p
        className="item-button-ghost fs18 no-select access-ob access-o6"
        tabIndex="0"
      >
        {SelectDropdownMultiple.pleaseCompleteYourSelection}
      </p>
    );
  } else if (Object.keys(filteredList)?.length === 1) {
    itemButtonReplacement = (
      <p
        className="item-button-ghost no-select access-ob access-o6"
        tabIndex="0"
        id={`${id}-noMoreSelections`}
      >
        {SelectDropdownMultiple.noMoreSelections}
      </p>
    );
  }

  return (
    <div
      className={`dropdown-multiple ${!renderDelayComplete ? 'remove' : ''} ${
        customWrapper ? customWrapper : ''
      } ${
        customWrapper === 'full-width' ? 'dropdown-multiple--full-width' : ''
      }`}
      id={id}
    >
      {selections?.map((key, index) => {
        return (
          <div key={`dropdown-multi-${id}-${index}`} className="full-width">
            <SelectDropdown
              id={`${id}-${index}`}
              list={list}
              filteredList={filteredList}
              handleSelection={handleDropdownSelection}
              defaultKey={key || null}
              multiDropdownRemoveButton={multiDropdownRemoveButton}
              multiDropdown={true}
              multiDropdownLabel={addButtonLabel}
              modalLabel={modalLabel}
              key={`SelectDropdown-${key}`}
              multiDropdownIndex={index}
              multiDropdownInitialized={initialized}
              fullWidth={customWrapper === 'full-width' ? true : false}
            />
            <div className="h24" />
          </div>
        );
      })}
      {!isLoading && itemButtonReplacement}
      {!isLoading && (
        <ItemButton
          handler={addButton}
          addText={true}
          text={`${addButtonLabel}`}
          fullWidth={true}
          minWidth={280}
          itemButtonId={`item-button-${id}`}
          displayNone={
            (Object.keys(filteredList)?.length > 0 &&
              !filteredList.hasOwnProperty('select')) ||
            Object.keys(filteredList)?.length === 1
          }
        />
      )}
    </div>
  );
};

export default SelectDropdownMultiple;
