import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';
import useLanguageData from '../../../../../language/useLanguageData';
import { handleViewTranscript } from '../InfoCardsFunctions/handleViewTranscript';

const InfoCardContainerSupportTickets = ({ ticketData, metaData }) => {
  const { ReportEventOpts, LanguageOpts, TimeZoneOpts, BrowserOpts } =
    useLanguageData();
  const { AdminTicketType, StatusEl } = useLanguageAdmin();

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Ticket Data</p>
      <p className="mrg-t6">Type: {AdminTicketType[ticketData?.ticketType]}</p>

      <p className="mrg-t6">
        Event Ref: [{ticketData?.target?.targetReference}]
      </p>
      <p className="mrg-t6">Issue: {ReportEventOpts[ticketData?.ticketKey]}</p>
      {ticketData?.ticketKey === 'other' && (
        <p className="mrg-t6">
          Other Issue: "{ticketData?.otherTicketDescription}"
        </p>
      )}
      {ticketData?.ticketType === 'translationRoom' && (
        <div className="flex-column flex-start">
          <p className="mrg-tb6">
            Browser: {BrowserOpts[ticketData?.browserKey]}
          </p>
          <button
            className="fs18 fwn border-thin br--small pad-6 mrg-tb12"
            onClick={() =>
              handleViewTranscript(
                ticketData?.additionalData,
                metaData.ticketReference
              )
            }
          >
            View Transcript
          </button>
        </div>
      )}

      <p className="mrg-t6">
        Description: "{ticketData?.ticketDetailedDescription}"
      </p>

      <div className="mrg-t24">
        {ticketData?.submitUser?.registeredUser?.accountId ? (
          <>
            <p className="fs18 fwsb">Submission: Registered User</p>
            <p className="flex-row mrg-t6">
              Name:{' '}
              {
                ticketData?.submitUser?.registeredUser?.accountId?.personal
                  ?.prefix
              }
              {
                ticketData?.submitUser?.registeredUser?.accountId?.personal
                  ?.firstName
              }{' '}
              {
                ticketData?.submitUser?.registeredUser?.accountId?.personal
                  ?.lastName
              }
              {ticketData?.submitUser?.registeredUser?.accountId?.personal
                ?.pronouns
                ? ` (${ticketData?.submitUser?.registeredUser?.accountId?.personal?.pronouns})`
                : ''}
            </p>
            <p className="flex-row mrg-t6">
              Account Ref: [
              {
                ticketData?.submitUser?.registeredUser?.accountId?.meta
                  ?.accountReference
              }
              ]
            </p>
            <div className="flex-row mrg-t6">
              Account Status: &nbsp;
              {StatusEl(
                ticketData?.submitUser?.registeredUser?.accountId?.meta
                  ?.accountStatus
              )}
            </div>
            <p className="flex-row mrg-t6">
              Email:{' '}
              {
                ticketData?.submitUser?.registeredUser?.accountId?.personal
                  ?.email
              }
            </p>

            <p className="flex-row mrg-t6">
              Language:{' '}
              {
                LanguageOpts[
                  ticketData?.submitUser?.registeredUser?.accountId?.appSettings
                    ?.contentLanguage
                ]
              }
            </p>
            <p className="flex-row mrg-t6">
              Timezone:{' '}
              {
                TimeZoneOpts[
                  ticketData?.submitUser?.registeredUser?.accountId?.appSettings
                    ?.timeZone
                ]
              }
            </p>
          </>
        ) : ticketData?.submitUser?.nonRegisteredUser?.email ? (
          <>
            <p className="fs18 fwsb">Submission: Non-Registered User</p>
            <p className="flex-row mrg-t6">
              Email: {ticketData?.submitUser?.nonRegisteredUser?.email}
            </p>
            <p className="flex-row mrg-t6">
              Name: {ticketData?.submitUser?.nonRegisteredUser?.name}
            </p>
          </>
        ) : (
          <>Created By MyndFull Support</>
        )}
      </div>
    </div>
  );
};

export default InfoCardContainerSupportTickets;
