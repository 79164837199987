import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import EmailPreferencesToggle from './EmailPreferencesToggles';

const EmailPreferencesContent = ({ saveAccountChange, loadingSaveBtnUI }) => {
  const { Account } = useLanguageAccount();

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      <EmailPreferencesToggle
        Account={Account}
        saveAccountChange={saveAccountChange}
        loadingSaveBtnUI={loadingSaveBtnUI}
      />

      <div className="h48" />
    </div>
  );
};

export default EmailPreferencesContent;
