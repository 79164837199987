import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//hooks
import {
  setAdminManagementTickets,
  selectAdminManagementTickets,
} from './adminManagementSlice';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import { useAdminSettingsHandlers } from '../settings/useAdminSettingsHandlers';
import { useLazyGetTicketsManagementQuery } from './adminManagementApiSlice';

export const useAdminManagementHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const {
    adminSettingsManagementTicketsTableStatus,
    adminSettingsManagementTicketsTableIssue,
    adminSettingsManagementTicketsTableType,
    adminSettingsManagementTicketsTableSupportUserSearchType,
    adminSettingsManagementTicketsTableSupportUserInput,
    adminSettingsManagementTicketsTableSubmitUserSearchType,
    adminSettingsManagementTicketsTableSubmitUserFilter,
    adminSettingsManagementTicketsTableSubmitUserInput,
    adminSettingsManagementTicketsTableDateStart,
    adminSettingsManagementTicketsTableDateEnd,
    adminSettingsManagementTicketsTablePage,
    handleAdminSettingsManagementTicketsTablePage,
    adminSettingsManagementTicketsTableResultsPerPage,
    adminSettingsTablesTicketSortingDate,
    handleAdminSettingsTablesTicketSortingDate,
  } = useAdminSettingsHandlers();

  const adminManagementTickets = useSelector(selectAdminManagementTickets);

  function handleAdminManagementSetTickets(arr) {
    dispatch(setAdminManagementTickets(arr));
  }
  //API
  const [
    getManagementTickets,
    {
      isLoading: getManagementTicketsisLoading,
      isFetching: getManagementTicketsisFetching,
    },
  ] = useLazyGetTicketsManagementQuery();

  const [initialQueryResultsPerPage, setInitialQueryResultsPerPage] =
    useState();

  async function handleManagementTickets() {
    if (!getManagementTicketsisLoading && !getManagementTicketsisFetching) {
      try {
        setInitialQueryResultsPerPage(
          adminSettingsManagementTicketsTableResultsPerPage
        );
        handleAdminSettingsManagementTicketsTablePage(1);
        const res = await getManagementTickets({
          adminAccountId: adminAccountId,
          queryStatus: adminSettingsManagementTicketsTableStatus,
          queryIssue: adminSettingsManagementTicketsTableIssue,
          queryTicketType: adminSettingsManagementTicketsTableType,
          queryDateStart: adminSettingsManagementTicketsTableDateStart,
          queryDateEnd: adminSettingsManagementTicketsTableDateEnd,
          querySupportUserType:
            adminSettingsManagementTicketsTableSupportUserSearchType,
          querySupportUserInput:
            adminSettingsManagementTicketsTableSupportUserInput,
          querySubmitUserType:
            adminSettingsManagementTicketsTableSubmitUserSearchType,
          querySubmitUserInput:
            adminSettingsManagementTicketsTableSubmitUserInput,
          querySubmitUserFilter:
            adminSettingsManagementTicketsTableSubmitUserFilter,
          queryResultsPerPage:
            adminSettingsManagementTicketsTableResultsPerPage,
          queryPage: 1,
        });

        //add sorting here
        handleAdminManagementSetTickets(res.data.data);
        return true;
      } catch (error) {
        return false;
      }
    }
  }

  async function handleManagementTicketsNextPage() {
    if (!getManagementTicketsisLoading && !getManagementTicketsisFetching) {
      try {
        const res = await getManagementTickets({
          adminAccountId: adminAccountId,
          queryStatus: adminSettingsManagementTicketsTableStatus,
          queryIssue: adminSettingsManagementTicketsTableIssue,
          queryTicketType: adminSettingsManagementTicketsTableType,
          queryDateStart: adminSettingsManagementTicketsTableDateStart,
          queryDateEnd: adminSettingsManagementTicketsTableDateEnd,
          querySupportUserType:
            adminSettingsManagementTicketsTableSupportUserSearchType,
          querySupportUserInput:
            adminSettingsManagementTicketsTableSupportUserInput,
          querySubmitUserType:
            adminSettingsManagementTicketsTableSubmitUserSearchType,
          querySubmitUserInput:
            adminSettingsManagementTicketsTableSubmitUserInput,
          querySubmitUserFilter:
            adminSettingsManagementTicketsTableSubmitUserFilter,
          queryResultsPerPage: initialQueryResultsPerPage,
          queryPage: adminSettingsManagementTicketsTablePage + 1,
        });
        handleAdminSettingsManagementTicketsTablePage(
          adminSettingsManagementTicketsTablePage + 1
        );

        //add sorting here
        handleAdminManagementSetTickets([
          ...adminManagementTickets,
          ...res.data.data,
        ]);
        return true;
      } catch (error) {
        return false;
      }
    }
  }

  //Sorting
  function handleAdminManagementTicketsSorting(sortingBoolean) {
    const copyArr = [...adminManagementTickets];

    let newSortingOrder;
    if (sortingBoolean === true) {
      newSortingOrder = 'descending';
    } else {
      newSortingOrder = 'ascending';
    }

    const sortedTickets = copyArr.sort((a, b) => {
      const dateA = new Date(a.meta.createdAt);
      const dateB = new Date(b.meta.createdAt);

      if (newSortingOrder === 'descending') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    handleAdminManagementSetTickets(sortedTickets);
    handleAdminSettingsTablesTicketSortingDate(newSortingOrder);
  }

  return {
    adminManagementTickets,
    handleAdminManagementSetTickets,
    handleManagementTickets,
    handleManagementTicketsNextPage,
    handleAdminManagementTicketsSorting,
  };
};
