//hooks

import LoadBucketImage from '../../../../../../components/LoadBucketImage/LoadBucketImage';

//components
//utils

const InfoCardContainerEventParticipants = ({ eventParticipantsData }) => {
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Participants</p>

      {eventParticipantsData.moderator?.length > 0 && (
        <div>Moderator: {eventParticipantsData.moderator}</div>
      )}

      <div className="mrg-t6">
        Speakers:
        {eventParticipantsData?.speaker0Data && (
          <div className="flex-row mrg-tb12">
            <LoadBucketImage
              imagePath={eventParticipantsData?.speaker0Data?.imagePath}
              imgClassName="support-profile-image"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_event_images'}
            />

            <div className="flex-column mrg-l24">
              <div>Name: {eventParticipantsData?.speaker0Data?.name}</div>
              <div className="mrg-t6">
                Bio: {eventParticipantsData?.speaker0Data?.bio}
              </div>
            </div>
          </div>
        )}
        {eventParticipantsData?.speaker1Data && (
          <div className="flex-row mrg-tb12">
            <LoadBucketImage
              imagePath={eventParticipantsData?.speaker1Data?.imagePath}
              imgClassName="support-profile-image"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_event_images'}
            />

            <div className="flex-column mrg-l24">
              <div className="mrg-t6">
                Name: {eventParticipantsData?.speaker1Data?.name}
              </div>
              <div className="mrg-t6">
                Bio: {eventParticipantsData?.speaker1Data?.bio}
              </div>
            </div>
          </div>
        )}
        {eventParticipantsData?.speaker2Data && (
          <div className="flex-row mrg-tb12">
            <LoadBucketImage
              imagePath={eventParticipantsData?.speaker2Data?.imagePath}
              imgClassName="support-profile-image"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_event_images'}
            />

            <div className="flex-column mrg-l24">
              <div className="mrg-t6">
                Name: {eventParticipantsData?.speaker2Data?.name}
              </div>
              <div className="mrg-t6">
                Bio: {eventParticipantsData?.speaker2Data?.bio}
              </div>
            </div>
          </div>
        )}
        {eventParticipantsData?.speaker3Data && (
          <div className="flex-row mrg-tb12">
            <LoadBucketImage
              imagePath={eventParticipantsData?.speaker3Data?.imagePath}
              imgClassName="support-profile-image"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_event_images'}
            />

            <div className="flex-column mrg-l24">
              <div className="mrg-t6">
                Name: {eventParticipantsData?.speaker3Data?.name}
              </div>
              <div className="mrg-t6">
                Bio: {eventParticipantsData?.speaker3Data?.bio}
              </div>
            </div>
          </div>
        )}
        {eventParticipantsData?.speaker4Data && (
          <div className="flex-row mrg-tb12">
            <LoadBucketImage
              imagePath={eventParticipantsData?.speaker4Data?.imagePath}
              imgClassName="support-profile-image"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_event_images'}
            />

            <div className="flex-column mrg-l24">
              <div className="mrg-t6">
                Name: {eventParticipantsData?.speaker4Data?.name}
              </div>
              <div className="mrg-t6">
                Bio: {eventParticipantsData?.speaker4Data?.bio}
              </div>
            </div>
          </div>
        )}
        {eventParticipantsData?.speaker5Data && (
          <div className="flex-row mrg-tb12">
            <LoadBucketImage
              imagePath={eventParticipantsData?.speaker5Data?.imagePath}
              imgClassName="support-profile-image"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_event_images'}
            />

            <div className="flex-column mrg-l24">
              <div className="mrg-t6">
                Name: {eventParticipantsData?.speaker5Data?.name}
              </div>
              <div className="mrg-t6">
                Bio: {eventParticipantsData?.speaker5Data?.bio}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoCardContainerEventParticipants;
