import { useState, useEffect } from 'react';

//hooks
import { useUpdateRolesMutation } from '../../accountApiSlice';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import { useAuthReduxHandlers } from '../../../auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//components
import ButtonSwitch from '../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../components/SaveButton/SaveButton';

//utility
import { areArraysEqualIgnoreOrder } from '../../../../../utils/logic/arrayEqualIgnoreOrder';

const SpeakerRoleToggle = () => {
  //Initialize functions
  const { accountId } = useAccountReduxHandlers();
  const { authCurrentRoles: loadRoles } = useAuthReduxHandlers();
  const { Account } = useLanguageAccount();
  const [
    updateRoles,
    {
      isLoading: updateAccountRolesIsLoading,
      isFetching: updateAccountRolesIsFetching,
    },
  ] = useUpdateRolesMutation();

  //component state
  const [roles, setRoles] = useState(loadRoles);
  const [rolesChangeValid, setRolesChangeValid] = useState(false);

  useEffect(() => {
    if (roles && loadRoles) {
      const rolesUnchanged = areArraysEqualIgnoreOrder(roles, loadRoles);
      if (rolesUnchanged) {
        setRolesChangeValid(false);
      } else {
        setRolesChangeValid(true);
      }
    }
  }, [roles, loadRoles]);

  //API functions
  async function handleAppSettingsUpdate() {
    if (rolesChangeValid) {
      if (!updateAccountRolesIsLoading && !updateAccountRolesIsFetching) {
        await updateRoles({ accountId, newData: roles });
        setRolesChangeValid(false);
      }
    }
  }

  //Component functions
  function handleSpeakerRoleToggle() {
    let newRoles = [...roles];
    if (newRoles.includes(process.env.REACT_APP_SPEAKER)) {
      newRoles = newRoles.filter(
        (role) => role !== process.env.REACT_APP_SPEAKER
      );
    } else {
      newRoles = [...newRoles, process.env.REACT_APP_SPEAKER];
    }
    newRoles.sort();
    return setRoles(newRoles);
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="speakerToggleLabel"
      >
        Speaker Activation
      </label>
      <div className="h12" />

      <ButtonSwitch
        id={'speakerToggle'}
        // title={Account.account.activateSpeakerSection}
        loadValue={loadRoles.includes(process.env.REACT_APP_SPEAKER)}
        loadTrigger={true}
        handleSwitch={handleSpeakerRoleToggle}
      />
      <div className="h24" />
      <SaveButton
        saveValid={rolesChangeValid}
        saveItemLanguage={Account.account.appSettings}
        handler={() => handleAppSettingsUpdate()}
        isLoading={updateAccountRolesIsLoading || updateAccountRolesIsFetching}
      />
      <div className="h36" />
    </div>
  );
};

export default SpeakerRoleToggle;
