import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useCreateEventReduxHandlers } from '../create/useCreateEventHandlers';
import {
  useLazyMyDraftEventsQuery,
  useDraftSelectors,
  useRemoveDraftEventMutation,
  useUpdateDraftEventMutation,
} from '../draftEventApiSlice';
import { selectId } from '../../account/accountSlice';

//Hooks
import useLanguageComponents from '../../../../language/useLanguageComponents';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useApiStatus from '../../../../context/useApiStatus';

//Components
import Spinner from '../../../../components/Spinner/Spinner';
import SelectionList from '../../../../components/SelectionList/SelectionList';
import ConfirmSelection from '../../../../components/ConfirmSelection/ConfirmSelection';
import EmptyData from '../../../../components/EmptyData/EmptyData';
import TextArea from '../../../../components/TextArea/TextArea';
import ButtonMeta from '../../../../components/ButtonMeta/ButtonMeta';

//Utility
import {
  img_create,
  img_load,
  img_restore,
  save,
} from '../../../../assets/icons';
import { DateFromTimeStamp } from '../../../../utils/dateTime/DateCalc';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

const Initialize = ({
  setInitialize,
  handleSubmit,
  initialize,
  setIsLoading,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { CreateEvent } = useLanguageOrganize();
  const { handleError, handleSuccess } = useApiStatus();
  const {
    handleCreateEventReturnEventData,
    handleCreateEventReset,
    handleCreateEventSetEvent,
    handleCreateEventSetSaveName,
    lastModified,
    title,
    saveName,
  } = useCreateEventReduxHandlers();
  const { accountContentLanguage: locale } = useAccountReduxHandlers();
  const accountId = useSelector(selectId);
  const { selectAllDraftEvents } = useDraftSelectors();
  const myDraftEvents = useSelector(selectAllDraftEvents);
  const [
    removeDraft,
    { isLoading: removeDraftisLoading, isFetching: removeDraftisFetching },
  ] = useRemoveDraftEventMutation();
  const [
    updateDraft,
    { isLoading: updateDraftisLoading, isFetching: updateDraftisFetching },
  ] = useUpdateDraftEventMutation();
  const [
    getDraftEvents,
    { data: myDraftEventsData, isSuccess, isFetching, isLoading },
  ] = useLazyMyDraftEventsQuery(accountId);
  const {
    handleSettingsSetOrganizeView,
    handleSettingsSetOrganizeOverviewContent,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
  } = useSettingsHandlers();

  //Component state
  const [loadDraft, setLoadDraft] = useState();
  const [deleteDraftData, setDeleteDraftData] = useState();
  const [initializeSelected, setInitializeSelected] = useState(false);
  const [selectionBtn, setSelectionBtn] = useState('');
  const isMatch = myDraftEvents.some((obj) => obj.meta.saveName === saveName);

  //Component variables
  const lastModifiedConverted = DateFromTimeStamp(
    lastModified,
    {
      weekday: 'off',
    },
    locale
  );
  let draftEventsList;

  //Initialize functions
  useEffect(() => {
    if (isSuccess && !myDraftEventsData) {
      getDraftEvents(accountId);
    }
  }, [myDraftEventsData]);

  //API functions
  async function submitEventSaveDraft(exit) {
    //exit property true/false to nav away
    const newDraft = handleCreateEventReturnEventData();

    if (isMatch) {
      const draftToChange = myDraftEvents.find(
        (draft) => draft.meta.saveName === saveName
      );
      const draftId = draftToChange._id;

      try {
        await updateDraft({ accountId, draftId, newDraft });

        handleSuccess({
          message: 'draftSaved',
          id: Date.now(),
          origin: 'Initialize.js/submitEventSaveDraft',
        });

        if (exit) {
          handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
          handleSettingsSetOrganizeOverviewContent('standalone');
          handleSettingsSetOrganizeView('overview');
        } else {
          setLoadDraft();
          handleCreateEventSetSaveName('');
        }
      } catch (error) {
        handleError(
          error.error,
          'Initialize.js/submitEventSaveDraft/updateDraft'
        );
      }
    } else {
      //create new draft
      try {
        await handleSubmit(newDraft, 'draft', exit);
        handleCreateEventSetSaveName('');
      } catch (error) {
        handleError(
          error.error,
          'Initialize.js/submitEventSaveDraft/createNewDraft'
        );
      }
    }
    setSelectionBtn();
  }

  async function deleteDraft(accountId, draftId) {
    try {
      await removeDraft({ accountId, draftId });
      setDeleteDraftData();
    } catch (error) {
      const newError = {
        error,
        id: Date.now(),
        origin: 'Initialize.js/deleteDraft',
      };
      handleError(newError);
    }
  }

  function handleLoadDraftConfirm() {
    handleCreateEventReset();
    setIsLoading(true);
    handleCreateEventSetEvent(loadDraft);
    setSelectionBtn();
    setInitialize(true);
    setInitializeSelected(true);
  }

  //main button selection
  async function handleSelection(selection) {
    if (selection === selectionBtn) {
      setSelectionBtn();
    } else {
      if (
        selectionBtn !== 'load' &&
        selectionBtn !== 'save' &&
        selection !== 'new'
      ) {
        try {
          await getDraftEvents(accountId);
        } catch (error) {
          const newError = {
            error,
            id: Date.now(),
            origin: 'Initialize.js/handleSelection',
          };
          handleError(newError);
        }
      }
      setSelectionBtn(selection);
      setLoadDraft();
      setDeleteDraftData();
    }
  }

  //create new event functions
  async function handleCreateNewConfirm() {
    //resets components then calls load function
    handleCreateEventReset();
    if (initialize) {
      await setInitialize(false);
      setInitialize(true);
      setSelectionBtn();
      setInitializeSelected(true);
    }
    if (!initialize) {
      setInitialize(true);
      setSelectionBtn();
      setInitializeSelected(true);
    }
  }

  //Component state
  function cancelSaveDraft() {
    setLoadDraft();
    handleCreateEventSetSaveName('');
  }

  function handleSelectDraft(draft, selectionType) {
    if (selectionType === 'select') {
      if (selectionBtn === 'load') {
        setDeleteDraftData();
        handleCreateEventSetSaveName();
        setLoadDraft(draft);
      } else if (selectionBtn === 'save') {
        handleCreateEventSetSaveName(draft.meta.saveName);
      }
    } else if (selectionType === 'delete') {
      setLoadDraft();
      setDeleteDraftData(draft);
    }
  } //clicking draft entry in table

  function setRestoreData() {
    setInitialize(true);
    setSelectionBtn();
    setInitializeSelected(true);
  }

  //JSX conditional
  if (isSuccess && !isLoading && !isFetching && myDraftEvents?.length > 0) {
    draftEventsList = (
      <SelectionList
        dataList={myDraftEvents}
        id={'draft'}
        col1Heading={CreateEvent.initialize.draftFiles}
        col1DataPath1={'meta'}
        col1DataPath2={'saveName'}
        col2DataPath1={'meta'}
        col2DataPath2={'lastModified'}
        handler={handleSelectDraft}
        itemSelection={
          loadDraft ? loadDraft : deleteDraftData ? deleteDraftData : null
        }
        isLoading={isLoading}
        ariaText={CreateEvent.initialize}
        deleteSelection={deleteDraftData}
        activeFileName={saveName}
      />
    );
  } else {
    draftEventsList = <EmptyData text={`${CreateEvent.initialize.noDrafts}`} />;
  } //add reload button?

  return (
    <fieldset className="fieldset" id="fieldset-initialize">
      <legend id="aria-fieldset-instructions-initialize">
        {CreateEvent.initialize.ariaFieldsetInstructions}
      </legend>
      <div className="h0-ph24" />
      <label
        className="label"
        id="initializeLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-initialize"
      >
        {CreateEvent.initialize.initialize}
      </label>

      <div className="flex-column">
        <div
          className={`button-meta__container space-evenly ${
            selectionBtn ? 'mrg-b24' : ''
          }`}
        >
          {lastModified && !initializeSelected && (
            <ButtonMeta
              handleClick={() => handleSelection('restore')}
              btnText={CreateEvent.initialize.restore}
              ariaLabel={CreateEvent.initialize.restore}
              activeStateDependency={selectionBtn === 'restore'}
              icon={img_restore}
              iconAlt={Generic.iconRestore}
            />
          )}
          {initialize && lastModified && (
            <ButtonMeta
              handleClick={() => handleSelection('save')}
              btnText={CreateEvent.initialize.save}
              ariaLabel={CreateEvent.initialize.save}
              activeStateDependency={selectionBtn === 'save'}
              icon={save}
              iconAlt={Generic.iconSave}
            />
          )}

          <ButtonMeta
            handleClick={() => handleSelection('load')}
            btnText={CreateEvent.initialize.load}
            ariaLabel={CreateEvent.initialize.load}
            activeStateDependency={selectionBtn === 'load'}
            icon={img_load}
            iconAlt={Generic.iconLoad}
          />

          <ButtonMeta
            handleClick={
              !initialize && !lastModified
                ? () => handleCreateNewConfirm()
                : () => handleSelection('new')
            }
            btnText={CreateEvent.initialize.create}
            ariaLabel={CreateEvent.initialize.create}
            activeStateDependency={selectionBtn === 'new'}
            icon={img_create}
            iconAlt={Generic.iconCreate}
          />
        </div>

        {selectionBtn === 'restore' && (
          <>
            <div className="divider mrg-t24" />
            <ConfirmSelection
              text={CreateEvent.initialize.restoreDataMessage}
              text2={`${CreateEvent.initialize.lastModified}: ${lastModifiedConverted}`}
              btn1Text={CreateEvent.initialize.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={CreateEvent.initialize.restore}
              btn2Color={'pos'}
              btn2Handler={setRestoreData}
              ignoreMaxWidth={true}
            />
          </>
        )}

        {selectionBtn === 'new' && (
          <>
            <div className="divider mrg-t24 mrg-b12" />
            <ConfirmSelection
              text={CreateEvent.initialize.createNewEventAndOverwriteMessage}
              btn1Text={CreateEvent.initialize.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={CreateEvent.initialize.create}
              btn2Color={'pos'}
              btn2Handler={handleCreateNewConfirm}
              ignoreMaxWidth={true}
            />
          </>
        )}
        {(isFetching || isLoading) && <Spinner />}

        {(selectionBtn === 'load' || selectionBtn === 'save') &&
          (!isLoading || !isFetching) && (
            <>
              <div className="divider mrg-tb24 mrg-b12" />
              {draftEventsList}

              {/*CONFIRMATION BTNS & INFO */}
              {selectionBtn === 'save' && !deleteDraftData && (
                <>
                  <div className="divider mrg-tb24 opacity-8" />
                  <li className="fs16 fwn access-ob access-o6" tabIndex="0">
                    {CreateEvent.initialize.dataNotSaved}
                  </li>
                  {isMatch && (
                    <>
                      <div className="h12" />
                      <li
                        className="fs16 fwn text-red access-ob access-o6"
                        tabIndex="0"
                      >
                        {CreateEvent.initialize.fileNameAlreadyExists}
                      </li>
                    </>
                  )}
                  <div className="h24" />
                  <TextArea
                    handleChange={handleCreateEventSetSaveName}
                    limit={75}
                    id={'saveas'}
                    labelText={CreateEvent.initialize.saveAs}
                    disableEnter={true}
                    defaultInput={title ? title : null}
                    loadValue={saveName}
                    reload={true}
                    onBlurHandlerOff={true}
                  />

                  {saveName && (
                    <>
                      <div className="h24" />
                      <ConfirmSelection
                        btn1Text={CreateEvent.initialize.cancel}
                        btn1Color={'neutral'}
                        btn1Handler={cancelSaveDraft}
                        btn2Text={CreateEvent.initialize.save}
                        btn2Color={'pos'}
                        btn2Handler={
                          isSuccess ? () => submitEventSaveDraft(false) : null
                        }
                        btn3Text={CreateEvent.initialize.saveAndExit}
                        btn3Color={'pos'}
                        btn3Handler={
                          isSuccess ? () => submitEventSaveDraft(true) : null
                        }
                        isLoading={
                          updateDraftisLoading || updateDraftisFetching
                        }
                        ignoreMaxWidth={true}
                      />
                    </>
                  )}
                </>
              )}

              {loadDraft && (
                <>
                  <div className="divider mrg-t24 opacity-8" />
                  <ConfirmSelection
                    text={`${CreateEvent.initialize.loadDraftPt1}${loadDraft.meta.saveName}${CreateEvent.initialize.loadDraftPt2}`}
                    btn1Color={'neutral'}
                    btn1Handler={() => setLoadDraft()}
                    btn1Text={CreateEvent.initialize.cancel}
                    btn1Id={'cancelLoadBtn'}
                    btn2Color={'pos'}
                    btn2Handler={handleLoadDraftConfirm}
                    btn2Text={CreateEvent.initialize.load}
                    btn2Id={'confirmLoadBtn'}
                    ignoreMaxWidth={true}
                  />
                </>
              )}

              {deleteDraftData && (
                <>
                  {selectionBtn === 'save' && <div className="h12" />}
                  <ConfirmSelection
                    text={`${CreateEvent.initialize.delete} '${deleteDraftData.meta.saveName}' ?`}
                    btn1Text={CreateEvent.initialize.cancel}
                    btn1Color={'neutral'}
                    btn1Handler={() => setDeleteDraftData()}
                    btn2Text={CreateEvent.initialize.delete}
                    btn2Color={'neg'}
                    btn2Handler={() =>
                      deleteDraft(accountId, deleteDraftData._id)
                    }
                    isLoading={removeDraftisLoading || removeDraftisFetching}
                    ignoreMaxWidth={true}
                  />
                </>
              )}
            </>
          )}
      </div>
    </fieldset>
  );
};

export default Initialize;
