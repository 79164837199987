import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import useLanguageLayout from '../../language/useLanguageLayout';
import useSettings from '../../context/useSettings';
import { useAuthReduxHandlers } from '../../features/main/auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';

//Components
import NavLoggedOut from './NavLoggedOut';
import NavLoggedIn from './NavLoggedIn';

//Utils
import { img_hamburger } from '../../assets/icons';

const Navbar = ({ layoutIsMounting }) => {
  //Hooks
  const { setOpenSlideMenu, openSlideMenu, width } = useSettings();
  const { accountId } = useAccountReduxHandlers();
  const { authCurrentRoles } = useAuthReduxHandlers();
  const { NavbarLoggedOut, NavbarLoggedIn } = useLanguageLayout();

  //state
  const [componentIsMounting, setComponentIsMounting] = useState(true);
  const [authRoles, setAuthRoles] = useState([]);

  //initialize
  useEffect(() => {
    setAuthRoles(authCurrentRoles);
  }, [authCurrentRoles]);

  //UI Transitions
  useEffect(() => {
    if (!layoutIsMounting) {
      const pageAnimationTimer = setTimeout(() => {
        setComponentIsMounting(false);
      }, 100);
      return () => clearTimeout(pageAnimationTimer);
    }
  }, [layoutIsMounting]);

  return (
    <header
      className={`${
        componentIsMounting ? 'offscreen' : 'navbar onscreen-fade-in-100ms'
      }`}
      id="navbar"
      aria-label={NavbarLoggedOut.header}
    >
      {width <= 1280 ? (
        <div className="navbar__container">
          <Link
            to={'/'}
            className="logo__link access-ob access-o12"
            aria-label={
              authRoles && accountId
                ? NavbarLoggedIn.linkToEvents
                : NavbarLoggedOut.linkToHomepage
            }
            tabIndex="0"
          >
            <div className="logo__color1 disable-select" aria-hidden="true">
              Mynd
            </div>
            <div className="logo__color2 disable-select" aria-hidden="true">
              Full
            </div>
          </Link>
          <button
            className="hamburger access-ob access-o6"
            onClick={() => setOpenSlideMenu(!openSlideMenu)}
            tabIndex="0"
            aria-label={NavbarLoggedOut.navigationMenu}
            aria-expanded={openSlideMenu}
            id="hamburger"
          >
            <img
              src={img_hamburger}
              alt={NavbarLoggedOut.menuIcon}
              aria-hidden="true"
              className="filter-white"
            />
          </button>
        </div>
      ) : (
        <div className="navbar__container">
          <Link
            to={'/'}
            className="logo__link access-ob access-o12"
            aria-label={
              authRoles && accountId
                ? NavbarLoggedIn.linkToEvents
                : NavbarLoggedOut.linkToHomepage
            }
            tabIndex="0"
          >
            <div className="logo__color1 disable-select" aria-hidden="true">
              Mynd
            </div>
            <div className="logo__color2 disable-select" aria-hidden="true">
              Full
            </div>
          </Link>
          {authRoles ? (
            <NavLoggedIn currentRoles={authRoles} />
          ) : (
            <NavLoggedOut />
          )}
        </div>
      )}
    </header>
  );
};

export default Navbar;
