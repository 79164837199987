import { useState } from 'react';
//Hooks
import { useAuthReduxHandlers } from '../../../auth/useAuthHandlers';

//Components
import TranslationRoomCodeManager from './TranslationRoomCodeManager';
import NewRoomCodeSubscriptions from './NewRoomCodeSubscriptions';

//Utility

const TranslationRoomsContent = () => {
  //hooks
  const { handleGetMyAccount } = useAuthReduxHandlers();
  //state
  const [reset, setReset] = useState(false);

  function handleResetPage() {
    setReset(true);

    const resetTimer = setTimeout(() => {
      setReset(false);
      handleGetMyAccount({
        ignoreAuthLoadingDisplay: true,
      });
    }, 500);

    return () => clearTimeout(resetTimer);
  }

  return (
    <div className="account-content" id="account-content">
      {!reset && (
        <>
          <NewRoomCodeSubscriptions handleResetPage={handleResetPage} />
          <TranslationRoomCodeManager
            handleResetPage={handleResetPage}
            pageReset={reset}
          />
        </>
      )}
    </div>
  );
};

export default TranslationRoomsContent;
