import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import { useLazyLogoutQuery } from '../../features/main/auth/authApiSlice';
import useLanguageLayout from '../../language/useLanguageLayout';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useAuthReduxHandlers } from '../../features/main/auth/useAuthHandlers';
import useSettings from '../../context/useSettings';

//Components
import LoadBucketImage from '../../components/LoadBucketImage/LoadBucketImage';

//Utils
import {
  login,
  linkedin,
  logOut,
  organize,
  search,
  register,
  exchange,
} from '../../assets/icons';

const SlideMenu = () => {
  //Hooks
  const { authCurrentRoles } = useAuthReduxHandlers();
  const { openSlideMenu, setOpenSlideMenu, handleMobileTap } = useSettings();
  const { NavbarLoggedIn, SlideMenu } = useLanguageLayout();
  const { accountProfileImage, accountId } = useAccountReduxHandlers();
  const [logout] = useLazyLogoutQuery();

  //UI state
  const [profileImage, setProfileImage] = useState(accountProfileImage);
  const [tapHighlightSlideBtn, setTapHighlightSlideBtn] = useState();

  //initialize
  useEffect(() => {
    setProfileImage(accountProfileImage);
  }, [accountProfileImage]);

  //ui
  function setFocus(focus) {
    document.getElementById(`${focus}`).focus();
  }

  function handleLogOutAndSlideMenu() {
    handleMobileTap(
      [
        () => setTapHighlightSlideBtn('slide-logout'),
        () => setTapHighlightSlideBtn(),
      ],
      [() => setOpenSlideMenu(false), () => logout()]
    );
  }

  function handleCloseSlideMenu(id) {
    if (id === 'overlay') {
      setFocus('top-link');
    } else {
      handleMobileTap(
        [() => setTapHighlightSlideBtn(id), () => setTapHighlightSlideBtn()],
        [() => setOpenSlideMenu(false), () => setFocus('hamburger')]
      );
    }
  }

  return (
    <>
      <nav
        className={`slidemenu ${openSlideMenu ? 'slidemenu__open' : ''}`}
        aria-label={
          openSlideMenu
            ? `${SlideMenu.slideMenuOnScreen}`
            : `${SlideMenu.slideMenuOffScreen}`
        }
      >
        <div className="slidemenu__container">
          <div className="nav__links">
            {!authCurrentRoles || !accountId ? (
              <>
                <Link
                  id="top-link"
                  to={{ pathname: '/login' }}
                  onClick={() => handleCloseSlideMenu('top-link')}
                  tabIndex="0"
                  className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                    tapHighlightSlideBtn === 'top-link'
                      ? 'nav-link--clicked'
                      : ''
                  }`}
                >
                  {NavbarLoggedIn.signIn}
                  <img
                    src={login}
                    alt={NavbarLoggedIn.signIn}
                    aria-hidden="true"
                  />
                </Link>
                <Link
                  to={{ pathname: '/tr' }}
                  onClick={() => handleCloseSlideMenu('translations')}
                  tabIndex="0"
                  className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                    tapHighlightSlideBtn === 'translations'
                      ? 'nav-link--clicked'
                      : ''
                  }`}
                  id="translations"
                >
                  {NavbarLoggedIn.translations}
                  <img
                    src={exchange}
                    alt={NavbarLoggedIn.register}
                    className="filter-white"
                    aria-hidden="true"
                  />
                </Link>
                <Link
                  to={{ pathname: '/register' }}
                  onClick={() => handleCloseSlideMenu('slide-register')}
                  tabIndex="0"
                  className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                    tapHighlightSlideBtn === 'slide-register'
                      ? 'nav-link--clicked'
                      : ''
                  }`}
                  id="slide-register"
                >
                  {NavbarLoggedIn.register}
                  <img
                    src={register}
                    alt={NavbarLoggedIn.register}
                    className="filter-white"
                    aria-hidden="true"
                  />
                </Link>
              </>
            ) : (
              <>
                {accountId &&
                  authCurrentRoles.includes(process.env.REACT_APP_ATTENDEE) && (
                    <Link
                      id="top-link"
                      to={{ pathname: '/events' }}
                      onClick={() => handleCloseSlideMenu('top-link')}
                      tabIndex="0"
                      className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                        tapHighlightSlideBtn === 'top-link'
                          ? 'nav-link--clicked'
                          : ''
                      }`}
                    >
                      {NavbarLoggedIn.events}
                      <img
                        src={search}
                        alt={NavbarLoggedIn.events}
                        aria-hidden="true"
                      />
                    </Link>
                  )}
                {accountId &&
                  authCurrentRoles.includes(
                    process.env.REACT_APP_ORGANIZER
                  ) && (
                    <Link
                      to={{ pathname: '/organize' }}
                      onClick={() => handleCloseSlideMenu('slide-organize')}
                      tabIndex="0"
                      className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                        tapHighlightSlideBtn === 'slide-organize'
                          ? 'nav-link--clicked'
                          : ''
                      }`}
                      id="slide-organize"
                    >
                      {NavbarLoggedIn.organize}
                      <img
                        src={organize}
                        alt={NavbarLoggedIn.organize}
                        className="color-white filter-white"
                        aria-hidden="true"
                      />
                    </Link>
                  )}
                <Link
                  to={{ pathname: '/account' }}
                  onClick={() => handleCloseSlideMenu('slide-account')}
                  tabIndex="0"
                  className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                    tapHighlightSlideBtn === 'slide-account'
                      ? 'nav-link--clicked'
                      : ''
                  }`}
                  id="slide-account"
                >
                  {NavbarLoggedIn.account}
                  <LoadBucketImage
                    imagePath={profileImage}
                    imgClassName="profile-image"
                    backupPath={'account_img_default.png'}
                    partialPath={'myndfull_account_images'}
                  />
                </Link>
                <Link
                  onClick={handleLogOutAndSlideMenu}
                  tabIndex="0"
                  className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                    tapHighlightSlideBtn === 'slide-logout'
                      ? 'nav-link--clicked'
                      : ''
                  }`}
                  id="slide-logout"
                >
                  {NavbarLoggedIn.signOut}
                  <img
                    src={logOut}
                    alt={NavbarLoggedIn.signOut}
                    aria-hidden="true"
                  />
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="slidemenu__footer">
          <div className="social-media">
            <a
              href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A89784148&keywords=myndfull&origin=RICH_QUERY_SUGGESTION&position=0&searchId=353b33b8-d1f3-478a-a051-a844ab85140a&sid=*cc"
              aria-label={SlideMenu.visitOurLinkedInPage}
              className="access-ob access-o6"
            >
              <img
                src={linkedin}
                alt="LinkedIn"
                className="filter-white"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </nav>
      <button
        className={`access-ow access-o-12 ${
          openSlideMenu ? 'slidemenu__exit' : 'remove'
        }`}
        onClick={handleCloseSlideMenu}
        tabIndex="0"
        aria-label={SlideMenu.closeNavigationMenu}
        id="overlay"
      />
    </>
  );
};

export default SlideMenu;
