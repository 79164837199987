//Hooks
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuthReduxHandlers } from './useAuthHandlers';
//Components

//Utility
const RequireAuth = ({ allowedRoles }) => {
  //Hooks
  const { authCurrentToken: token, authCurrentRoles: roles } =
    useAuthReduxHandlers();

  const location = useLocation();

  return token && roles?.find((role) => allowedRoles?.includes(`${role}`)) ? (
    <Outlet />
  ) : token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
