import { useState } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';
//Components
import Spinner from '../Spinner/Spinner';

//Utility
import { nav_arrow } from '../../assets/icons';

const NavigationArrows = ({
  handlePageDecrease,
  handlePageIncrease,
  continueBtnCheck,
  finalContinue,
  HOFunction,
  firstArrow,
  fullWidth,
  isLoading,
  skip,
  skipOn,
  previousIsExit,
}) => {
  //Hooks
  const { NavigationArrows, Generic } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightNavIncrease, setTapHighlightNavIncrease] = useState(false);
  const [tapHighlightNavDecrease, setTapHighlightNavDecrease] = useState(false);

  return (
    <div
      className={`nav-arrows flex-row space-between ${
        fullWidth ? 'full-width' : ''
      }`}
    >
      {!firstArrow && !previousIsExit && (
        <button
          className={`flex-center color-gray fwb access-ob access-o6  ${
            tapHighlightNavDecrease ? 'highlight-it-theme--tap' : ''
          } ${isLoading ? 'opacity-05 unavailable' : 'highlight-it-theme'}`}
          onClick={
            isLoading
              ? null
              : () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavDecrease(true),
                      () => setTapHighlightNavDecrease(false),
                    ],
                    [() => handlePageDecrease()]
                  )
          }
          type="button"
          tabIndex="0"
          aria-label={`${NavigationArrows.previous}.`}
        >
          <div className="arrow rotate-180 mrg-r12 svg">
            <img src={nav_arrow} alt={Generic.arrow} className="filter-gray" />
          </div>
          {NavigationArrows.previous}
        </button>
      )}
      {!firstArrow && previousIsExit && (
        <button
          className={`flex-center color-gray fwb access-ob access-o6  ${
            tapHighlightNavDecrease ? 'highlight-it-theme--tap' : ''
          } ${isLoading ? 'opacity-05 unavailable' : 'highlight-it-theme'}`}
          onClick={
            isLoading
              ? null
              : () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavDecrease(true),
                      () => setTapHighlightNavDecrease(false),
                    ],
                    [() => handlePageDecrease()]
                  )
          }
          type="button"
          tabIndex="0"
          aria-label={`${NavigationArrows.exit}.`}
        >
          <div className="arrow rotate-180 mrg-r12 svg">
            <img src={nav_arrow} alt={Generic.arrow} className="filter-gray" />
          </div>
          {NavigationArrows.exit}
        </button>
      )}
      {finalContinue ? (
        <button
          type="button"
          className={`flex-center  fwb  access-ob access-o6  ${
            tapHighlightNavIncrease ? 'highlight-it-theme--tap' : ''
          } ${
            continueBtnCheck || skipOn
              ? 'highlight-it-theme color-gray'
              : 'unavailable color-lightgray'
          } ${isLoading ? 'pointer-default' : ''}`}
          onClick={
            (continueBtnCheck || skipOn) && !isLoading
              ? () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavIncrease(true),
                      () => setTapHighlightNavIncrease(false),
                    ],
                    [(e) => HOFunction(e)]
                  )
              : null
          }
          tabIndex="0"
        >
          {isLoading ? (
            <Spinner maxHeight={'40px'} />
          ) : (
            `${NavigationArrows.finish}`
          )}
        </button>
      ) : (
        <button
          type="button"
          className={`flex-center  fwb  access-ob access-o6  ${
            tapHighlightNavIncrease ? 'highlight-it-theme--tap' : ''
          } ${
            continueBtnCheck || skipOn
              ? 'highlight-it-theme color-gray'
              : 'unavailable color-lightgray'
          }`}
          onClick={
            (continueBtnCheck || skipOn) && !isLoading
              ? () =>
                  handleMobileTap(
                    [
                      () => setTapHighlightNavIncrease(true),
                      () => setTapHighlightNavIncrease(false),
                    ],
                    [() => handlePageIncrease()]
                  )
              : null
          }
          tabIndex="0"
          aria-label={`${
            continueBtnCheck || skipOn
              ? `${NavigationArrows.continue}.`
              : `${NavigationArrows.minimumRequirementsNotMet}.`
          }`}
        >
          {skipOn && skip ? NavigationArrows.skip : NavigationArrows.continue}
          <div className="arrow svg mrg-l12">
            <img
              src={nav_arrow}
              alt={Generic.arrow}
              className={`${
                continueBtnCheck ? 'filter-gray' : 'filter-lightgray'
              }`}
            />
          </div>
        </button>
      )}
    </div>
  );
};

export default NavigationArrows;
