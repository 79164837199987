const retryWrapper = async (fn, args = [], retries, delay) => {
  let retryX = retries ? retries : 3;
  let delayX = delay ? delay : 2000;

  for (let attempt = 1; attempt <= retryX; attempt++) {
    try {
      return await fn.apply(null, args);
    } catch (error) {
      if (attempt < retryX) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('DEV: retryWrapper retrying, attempt', attempt);
        }
        await new Promise((resolve) => setTimeout(resolve, delayX));
      } else {
        throw error;
      }
    }
  }
};
//works good when you need to pass in arguments like setState

const retryWrapperForRTKHook = async (fn, params, retries, delay) => {
  let retryX = retries ? retries : 3;
  let delayX = delay ? delay : 2000;

  for (let i = 0; i < retries; i++) {
    try {
      await fn(params).unwrap();
      break;
    } catch (error) {
      if (i < retryX - 1) {
        await new Promise((res) => setTimeout(res, delayX));
      } else {
        throw error;
      }
    }
  }
};
//works good with rtk query api hooks

export { retryWrapper, retryWrapperForRTKHook };
