import { useState, useEffect, useRef } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Components

//Utility
import handleInputClick from './handleInputClick';

const TextArea = ({
  ariaText,
  asterisk,
  customWidth,
  disableEnter,
  fixedHeight,
  handleChange,
  hideWordCount,
  id,
  labelText,
  large,
  limit,
  loadTrigger,
  loadValue,
  onBlurHandlerOff,
  placeHolder,
  refTrigger,
  reload,
  tabIndex,
  customWrapper,
  customKeyDownHandler,
}) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { dismissKeypad } = useSettings();

  //Component State
  const [initialLoadValue, setInitialLoadValue] = useState();
  const [inputText, setInputText] = useState();
  const [charCount, setCharCount] = useState(0);
  const [reRender, setReRender] = useState(false);

  //UI state
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [borderHighlight, setBorderHighlight] = useState(false);
  const [shakeCharLimit, setShakeCharLimit] = useState(false);

  //Component variables
  const textarea = useRef();
  const effectRan = useRef(false);
  const adjustInputRan = useRef(false);

  let charCounter;

  //Initialize functions
  useEffect(() => {
    const animationTimer = setTimeout(() => {
      expandTextField();
    }, 1);

    return () => clearTimeout(animationTimer);
  }, []);

  useEffect(() => {
    if (tabIndex) {
      textarea.current.setAttribute('tabIndex', tabIndex);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (refTrigger) {
      textarea.current.focus();
    }
  }, [refTrigger]);

  useEffect(() => {
    if (loadTrigger && loadValue) {
      if (effectRan.current === false) {
        handleInput(loadValue);
      }
      effectRan.current = true;
    }
  }, [loadTrigger, loadValue]);

  //UI Functions
  useEffect(() => {
    if (charCount === limit && focusHighlight) {
      setShakeCharLimit(true);
    }
    if (!focusHighlight || charCount !== limit) {
      setShakeCharLimit(false);
    }
    /*eslint-disable-next-line*/
  }, [charCount, focusHighlight]);

  useEffect(() => {
    if (!inputText && charCount > 0) {
      setCharCount(0);
    }
  }, [inputText]); //will reset charCount if input value is wiped by external function

  //Component Functions
  function handleInput(text) {
    setInputText(text);
    setCharCount(text.length);
    expandTextField();
  }

  function expandTextField() {
    if (textarea?.current) {
      if (adjustInputRan.current === false) {
        if (!fixedHeight) {
          if (large) {
            textarea.current.style.height = '160px';
            textarea.current.style.overflowY = '';
            adjustInputRan.current = true;
          }

          let scrollHeight = textarea.current.scrollHeight;

          if (!scrollHeight) {
            textarea.current.style.height = '84px';
            textarea.current.style.overflowY = '';
            adjustInputRan.current = true;
          } else {
            textarea.current.style.height =
              textarea.current.scrollHeight + 'px';
            textarea.current.style.overflowY = '';
            adjustInputRan.current = true;
          }
        } else {
          textarea.current.style.height = `${fixedHeight}`;
          textarea.current.style.overflowY = '';
          adjustInputRan.current = true;
        }
        setReRender(!reRender);
      }
    }
  }

  function handleKeyDown(e) {
    if (disableEnter) {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
      if (customKeyDownHandler) {
        customKeyDownHandler(e);
      }
      dismissKeypad(e);
    }
  }

  useEffect(() => {
    if (reload) {
      setInitialLoadValue(loadValue || '');
      setInputText(loadValue || '');
    }
  }, [reload]);

  useEffect(() => {
    if (loadValue !== initialLoadValue && reload) {
      setInitialLoadValue(loadValue);
      setInputText(loadValue);
    }
  }, [loadValue]);

  //JSX conditional
  if (focusHighlight && !hideWordCount) {
    charCounter = (
      <p
        className={`${charCount === limit ? 'maximum' : ''} ${
          shakeCharLimit ? 'shake' : ''
        }`}
      >
        {charCount}/{limit}
      </p>
    );
  } //add word limit when close, shake on reaching max

  if (hideWordCount && charCount && focusHighlight && charCount >= limit) {
    charCounter = (
      <p
        className={`${charCount === limit ? 'maximum' : ''} ${
          shakeCharLimit ? 'shake' : ''
        }`}
      >
        {charCount}/{limit}
      </p>
    );
  }

  return (
    <div
      className={`textarea__wrapper ${customWrapper ? customWrapper : ''}`}
      style={customWidth ? { width: `${customWidth}` } : null}
      onMouseEnter={() => setBorderHighlight(true)}
      onMouseLeave={() => setBorderHighlight(false)}
    >
      <span
        className="textarea-label no-select"
        onClick={() => handleInputClick(textarea.current)}
      >
        <p>
          {labelText}&nbsp;
          {asterisk && <span className="highlight">*</span>}
        </p>
        {charCounter}
      </span>

      <textarea
        id={id}
        onInput={(e) => handleInput(e.target.value)}
        name={id}
        type="text"
        className={`textarea ${
          borderHighlight ? 'highlight-o-theme--tap' : ''
        } ${
          focusHighlight ? 'highlight-ob-theme--active' : 'highlight-ob-theme'
        }`}
        value={inputText}
        maxLength={`${limit}`}
        onFocus={() => {
          setFocusHighlight(true);
        }}
        onChange={(e) => {
          setInputText(e.target.value);
          setCharCount(e.target.value.length);
          if (onBlurHandlerOff) {
            handleChange(e.target.value, id);
          }
        }}
        onBlur={(e) => {
          setFocusHighlight(false);
          if (!onBlurHandlerOff) {
            handleChange(e.target.value, id);
          }
        }}
        ref={textarea}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeHolder ? placeHolder : null}
        tabIndex="0"
        aria-label={
          asterisk
            ? `${id}. ${Generic.fieldIsRequired}. ${
                limit ? Generic.characterLimit + limit + '.' : ''
              } ${
                charCount > 0
                  ? Generic.currentTextCharacters + charCount + '.'
                  : ''
              } ${ariaText ? ariaText : ''}`
            : `${id}. ${limit ? Generic.characterLimit + limit + '.' : ''} ${
                ariaText ? ariaText : ''
              }`
        }
        enterKeyHint={`${disableEnter ? 'done' : 'enter'}`}
      />
    </div>
  );
};

export default TextArea;
