import { useState, useEffect, useRef } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useApiStatus from '../../context/useApiStatus';
import useSettings from '../../context/useSettings';
//Components

//Utility

const ButtonSwitchLOrder = ({
  id,
  title,
  handler,
  handleHOrderCondition,
  stateHO,
  loadTrigger,
  loadValue,
  ariaDescriptiveLabel,
  horizontal,
  temporaryDisable,
  temporaryDisableMessage,
  secondaryTrigger,
}) => {
  //Hooks
  const { ButtonSwitch } = useLanguageComponents();
  const { handleWarning } = useApiStatus();
  const { handleMobileTap } = useSettings();

  //Component state

  //UI state
  const [tapHighlightText, setTapHighlightText] = useState(false);
  const [active, setActive] = useState(false);
  const [animationsOn, setAnimationsOn] = useState(false);

  //Component variables
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (loadTrigger) {
      if (effectRan.current === false) {
        if (loadValue) {
          setActive(loadValue);
        }
        effectRan.current = true;
      }
    }
    /*eslint-disable-next-line*/
  }, [loadTrigger]); //load data

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      if (!animationsOn) {
        setAnimationsOn(true);
      }
    }, 500);

    return () => clearTimeout(animationTimer);
  }, []); //prevent the button's animations on load

  //Component functions
  function handleClickFunction() {
    if (!active && !stateHO) {
      setActive(true);
      handler(true);
      handleHOrderCondition(true);
    }
    if (!active && stateHO) {
      setActive(true);
      handler(true);
    }
    if (active && stateHO) {
      setActive(false);
      handler(false);
    }
  }

  function handleClick() {
    handleMobileTap(
      [() => setTapHighlightText(true), () => setTapHighlightText(false)],
      [() => handleClickFunction()]
    );
  }

  useEffect(() => {
    if (!stateHO) {
      setActive(false);
    }
  }, [stateHO]);

  useEffect(() => {
    if (!secondaryTrigger && active) {
      setActive(false);
    }
  }, [secondaryTrigger]);

  return (
    <div
      className={`btn-switch__wrapper no-select ${
        horizontal ? 'btn-switch-horizontal' : ''
      }`}
    >
      <button
        id={id}
        name={id}
        type="checkbox"
        className={`btn-switch access-ob access-o6 ${
          active ? ' btn-switch--active' : ''
        } ${temporaryDisable ? 'opacity-3' : ''}`}
        onClick={
          temporaryDisable
            ? () =>
                handleWarning(
                  {
                    message: temporaryDisableMessage,
                    statusCode: 500,
                  },
                  `ButtonSwitchLOrder.js/${id}`
                )
            : () => handleClick(id)
        }
        aria-label={ariaDescriptiveLabel ? ariaDescriptiveLabel : title}
        role="switch"
        aria-checked={active}
      >
        <div
          className={`btn-switch__circle ${
            active ? ' btn-switch__circle--active' : ''
          }`}
          style={{ transition: `${animationsOn ? '0.4s' : '0s'}` }}
        />
        {active ? (
          <p className="btn-switch__text-btn">{ButtonSwitch.yes}</p>
        ) : (
          <p className="btn-switch__text-btn">{ButtonSwitch.no}</p>
        )}
      </button>
      <p
        className={`btn-switch__description highlight-it-theme ${
          tapHighlightText ? 'highlight-it-theme--tap' : ''
        }`}
        onClick={
          temporaryDisable
            ? () =>
                handleWarning(
                  {
                    message: temporaryDisableMessage,
                    statusCode: 500,
                  },
                  `ButtonSwitchLOrder.js/${id}`
                )
            : () => handleClick(id)
        }
      >
        {title}
      </p>
    </div>
  );
};

export default ButtonSwitchLOrder;
