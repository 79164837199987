const ScrollTo = (focusId, behavior, scrollToTopMax, scrollToElementId) => {
  let focusElement;
  if (focusId) {
    focusElement = document.getElementById(`${focusId}`);
  }

  let rect;

  if (scrollToTopMax) {
    if (focusElement) {
      focusElement.focus({ preventScroll: true });
    }
    window.scrollTo({
      top: 0,
      behavior: `${behavior ? behavior : 'auto'}`,
    });
  } else {
    const scrollToElement = document.getElementById(`${scrollToElementId}`);

    if (scrollToElement) {
      rect = scrollToElement.getBoundingClientRect();

      if (focusElement) {
        focusElement.focus({ preventScroll: true });
      }

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollPosition = rect.y + scrollTop - 36;

      window.scrollTo({
        top: scrollPosition,
        behavior: `${behavior ? behavior : 'auto'}`,
      });
    }
  }
};

export default ScrollTo;
