//Hooks
import useLanguageEvents from '../../../language/features/useLanguageEvents';

//Components

//Utility

const NoMoreCard = () => {
  const { EventsGrid } = useLanguageEvents();
  return (
    <div className="ec__ghost flex-center flex-column">
      <br />
      <br />
      <br />
      <h1>{EventsGrid.searchCompleted}</h1>
      <br />
      <br />
      <br />
      <h1>Design Icon </h1>
      <h1>(Last Page in Book)</h1>
    </div>
  );
};

export default NoMoreCard;
