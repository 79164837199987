import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    email: null,
    token: null,
    roles: null,
    isLoggingOut: false,
    isLoggingIn: false,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { email, accessToken, roles } = action.payload;
      state.email = email;
      state.token = accessToken;
      state.roles = roles;
    },
    logOut: (state, action) => {
      state.email = null;
      state.token = null;
      state.roles = null;
    },
    setAuthIsLoggingOut: (state, action) => {
      state.isLoggingOut = action.payload;
    },
    setAuthIsLoggingIn: (state, action) => {
      state.isLoggingIn = action.payload;
    },
  },
});

export const {
  setCredentials,
  logOut,
  setAuthIsLoggingOut,
  setAuthIsLoggingIn,
  setPersist,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth?.email;
export const selectCurrentRoles = (state) => state.auth?.roles;
export const selectCurrentToken = (state) => state.auth?.token;
export const selectAuthIsLoggingOut = (state) => state.auth?.isLoggingOut;
export const selectAuthIsLoggingIn = (state) => state.auth?.isLoggingIn;
