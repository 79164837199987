import { useEffect, useState } from 'react';

//hooks
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useSettings from '../../../../../context/useSettings';
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';

//components
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import PasswordInput from '../../../../../components/TextArea/PasswordInput';

//utility
import { image_gears } from '../../../../../assets/images';
import {
  checkmark_green,
  clock,
  img_enter,
  img_refresh_two_circular,
  speaker_dark,
} from '../../../../../assets/icons';

const AttendeeStagingRoom = ({
  roomCode,
  translationRoomSocketStatus,
  handleExit,
  setTranslationLang,
  translationLang,
  isHostPresent,
  roomLanguageOpts,
}) => {
  //hooks
  const { TranslationRoom } = useLanguageTranslationRoom();
  const { Generic } = useLanguageComponents();
  const { contentLanguage } = useSettings();
  const {
    attendeeRequiresPassword,
    setAttendeePasswordInput,
    attendeePasswordInput,
    getAttendeeAuthentication,
  } = useTranslationRoomSocket();

  //state
  const [filteredSpeechLangOpts, setFilteredSpeechLangOpts] = useState([]);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);

  //functions
  useEffect(() => {
    if (roomLanguageOpts) {
      const newRoomLanguageOpts = Object.entries(roomLanguageOpts).reduce(
        (acc, [key, value]) => {
          if (key !== translationLang) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      setFilteredSpeechLangOpts(newRoomLanguageOpts);
    } else {
      setFilteredSpeechLangOpts([]);
    }
  }, [roomLanguageOpts, contentLanguage, translationLang]); //create filtered list to remove selection

  return (
    <div
      className={`translations-selection__container ${
        !imgLoaded ? 'remove' : ''
      }`}
    >
      <img
        src={image_gears}
        alt="img"
        style={{ height: '200px' }}
        className="svg"
        aria-hidden={true}
        onLoad={() => setImgLoaded(true)}
      />
      <div className="translations-selections mrg-b48">
        <div
          className={`translations-room-code mrg-tb24 access-ob access-o6`}
          tabIndex="0"
        >
          <p className="mrg-b12">{TranslationRoom.roomCode}</p>
          <div className="flex-row">
            {roomCode && (
              <div className="border-reg full-width fwb">
                <div className="space-around pad-6 ff2 fs21">
                  <span>{roomCode[0]}</span>
                  <span className="mrg-l6">{roomCode[1]}</span>
                  <span className="mrg-l6">{roomCode[2]}</span>
                  <span className="mrg-l6">{roomCode[3]}</span>
                  {roomCode[4] && <span className="mrg-l6">{roomCode[4]}</span>}
                  {roomCode[5] && <span className="mrg-l6">{roomCode[5]}</span>}
                  {roomCode[6] && <span className="mrg-l6">{roomCode[6]}</span>}
                  {roomCode[7] && <span className="mrg-l6">{roomCode[7]}</span>}
                  {roomCode[8] && <span className="mrg-l6">{roomCode[8]}</span>}
                  {roomCode[9] && <span className="mrg-l6">{roomCode[9]}</span>}
                  {roomCode[10] && (
                    <span className="mrg-l6">{roomCode[10]}</span>
                  )}
                  {roomCode[11] && (
                    <span className="mrg-l6">{roomCode[11]}</span>
                  )}
                </div>
              </div>
            )}
            <div className="live-connection-icon full-height mrg-l12 mrg-t6">
              <img
                src={img_refresh_two_circular}
                alt={Generic.icon}
                className={`svg  ${
                  translationRoomSocketStatus !== 'connected'
                    ? 'live-connection-status--pending--staging'
                    : 'remove'
                }`}
                title={Generic.attemptingToReconnect}
                style={{ top: '0px', bottom: '0px' }}
              />
            </div>
          </div>
        </div>

        <>
          {isHostPresent ? (
            <div
              className="flex-row flex-end mrg-b12 align-center access-ob access-o6"
              tabIndex="0"
            >
              <p className="mrg-r12 fs18 fwsb">{TranslationRoom.hostIsReady}</p>
              <img
                src={speaker_dark}
                alt="img"
                style={{ minHeight: '36px' }}
                className="svg filter-gray"
                aria-hidden="true"
              />
              <img
                src={checkmark_green}
                alt="img"
                className="svg mrg-l12"
                aria-hidden="true"
              />
            </div>
          ) : (
            <div
              className="flex-row flex-end mrg-b12 align-center access-ob access-o6"
              tabIndex="0"
            >
              <p className="mrg-r12 fs18 fwsb">
                {TranslationRoom.waitingForHost}
              </p>
              <img
                src={speaker_dark}
                alt="img"
                style={{ minHeight: '36px' }}
                className="svg filter-gray"
                aria-hidden="true"
              />
              <img
                src={clock}
                className="svg mrg-l12 filter-red"
                alt="img"
                style={{ minHeight: '18px' }}
                aria-hidden="true"
              />
            </div>
          )}
        </>

        {roomLanguageOpts && isHostPresent && (
          <>
            <div className="h24" />
            <SelectDropdown
              id={'translationLanguage'}
              list={roomLanguageOpts}
              handleSelection={setTranslationLang}
              internalLabel={TranslationRoom.yourLanguage}
              asterisk={true}
              fullWidth={true}
              defaultKey={
                translationLang && translationLang !== 'select'
                  ? translationLang
                  : 'select'
              }
              filteredList={filteredSpeechLangOpts}
            />
            {attendeeRequiresPassword && (
              <PasswordInput
                handleInput={setAttendeePasswordInput}
                customWrapper={'mrg-t24'}
                id={'roomCodePasswordInput'}
                customKeyDownHandler={(e) => {
                  if (e.key === 'Enter') {
                    getAttendeeAuthentication();
                  }
                }}
              />
            )}

            <ItemButton
              text={TranslationRoom.enter}
              handler={getAttendeeAuthentication}
              icon={img_enter}
              iconOn={true}
              fullWidth={true}
              customWrapper={'action-btns mrg-t24'}
              unavailable={
                !isHostPresent ||
                translationLang === 'select' ||
                !translationLang ||
                (attendeeRequiresPassword && !attendeePasswordInput)
              }
            />
            <div className="divider full-width mrg-t24 mrg-b12" />
          </>
        )}

        <ItemButton
          text={TranslationRoom.exit}
          handler={() => {
            handleExit();
          }}
          fullWidth={true}
          customWrapper={'action-btns mrg-t24'}
        />
      </div>
    </div>
  );
};

export default AttendeeStagingRoom;
