import { apiSlice } from '../../../app/api/apiSlice';
import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  setIgnoredEvents,
  setSavedEvents,
  setPendingApprovalInPerson,
  setPendingApprovalVirtual,
  setRegistrationsInPerson,
  setRegistrationsVirtual,
  selectId,
  selectGeoPosition,
  selectDistanceMeasurement,
} from '../account/accountSlice';
import {
  settingsSetBTQueryHasMore,
  settingsSetTTQueryHasMore,
  settingsSetNetworkQueryHasMore,
  selectSettingsEventSearchCategory,
  selectSettingsEventSearchDirection,
} from '../settings/settingsSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';
import { sortEventsByDistance } from '../../../utils/spatial/DistanceCalc';
import { sortEventsByDateTime } from '../../../utils/dateTime/DateCalc';

const eventsAdapter = createEntityAdapter({
  selectId: (event) => event._id,
});

const initialState = eventsAdapter.getInitialState();

export const eventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEvent: builder.query({
      query: ({ accountId, eventId }) => {
        return {
          url: `/api/events/event`,
          params: { accountId, eventId },
        };
      },
      transformResponse: (response) => {
        return eventsAdapter.setAll(initialState, response.data);
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/getEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: (response, meta, arg) => [{ type: 'Event', id: arg }],
    }),
    bigTagEvents: builder.query({
      query: ({
        accountId,
        searchBTs,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage,
        bTQueryPage,
      }) => {
        return {
          url: '/api/events/bigTagEvents',
          params: {
            accountId,
            searchBTs,
            searchCategory,
            searchDirection,
            searchFormat,
            queryCardsPerPage,
            bTQueryPage,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { hasMore } = data;
          dispatch(settingsSetBTQueryHasMore(hasMore));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/bigTagEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { searchCategory, searchDirection, searchFormat, searchBTs } =
          queryArgs;
        const endpoint = JSON.stringify(
          `${endpointName}-${searchCategory}-${searchDirection}-${searchFormat}-${searchBTs}`
        );
        return endpoint;
      },
      merge: (currentCache, newItems) => {
        currentCache.data.push(...newItems.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg.bTQueryPage !== previousArg.bTQueryPage;
      },
      providesTags: ['Event'],
    }),
    tinyTagEvents: builder.query({
      query: ({
        accountId,
        searchTTs,
        searchTGIds,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage,
        tTQueryPage,
      }) => {
        return {
          url: '/api/events/tinyTagEvents',
          params: {
            accountId,
            searchTTs,
            searchTGIds,
            searchCategory,
            searchDirection,
            searchFormat,
            queryCardsPerPage,
            tTQueryPage,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { hasMore } = data;
          dispatch(settingsSetTTQueryHasMore(hasMore));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/tinyTagEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const {
          searchCategory,
          searchDirection,
          searchFormat,
          searchTTs,
          searchTGIds,
        } = queryArgs;
        const endpoint = JSON.stringify(
          `${endpointName}-${searchCategory}-${searchDirection}-${searchFormat}-${searchTGIds}-${searchTTs}`
        );
        return endpoint;
      },
      merge: (currentCache, newItems) => {
        currentCache.data.push(...newItems.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg.tTQueryPage !== previousArg.tTQueryPage;
      },
      providesTags: ['Event'],
    }),
    networkEvents: builder.query({
      query: ({
        accountId,
        searchAccountsFollowingIds,
        searchAccountsFollowingGroupIds,
        searchCategory,
        searchDirection,
        searchFormat,
        queryCardsPerPage,
        networkQueryPage,
      }) => {
        return {
          url: '/api/events/networkEvents',
          params: {
            accountId,
            searchAccountsFollowingIds,
            searchAccountsFollowingGroupIds,
            searchCategory,
            searchDirection,
            searchFormat,
            queryCardsPerPage,
            networkQueryPage,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { hasMore } = data;
          dispatch(settingsSetNetworkQueryHasMore(hasMore));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/networkEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const {
          searchCategory,
          searchDirection,
          searchFormat,
          searchAccountsFollowingIds,
          searchAccountsFollowingGroupIds,
        } = queryArgs;

        const endpoint = JSON.stringify(
          `${endpointName}-${searchCategory}-${searchDirection}-${searchFormat}-${searchAccountsFollowingIds}-${searchAccountsFollowingGroupIds}`
        );

        return endpoint;
      },
      merge: (currentCache, newItems) => {
        currentCache.data.push(...newItems.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg.networkQueryPage !== previousArg.networkQueryPage;
      },
      providesTags: ['Event', 'NetworkEvent'],
    }),
    myEventsSaved: builder.query({
      query: (accountId) => {
        return {
          url: '/api/events/myevents/saved',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return eventsAdapter.setAll(initialState, response.data);
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/myEventsSaved',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: (result, error, arg) => {
        const eventIds = result?.ids ?? [];
        return eventIds.map((event) => ({
          type: 'Event',
          id: event?._id,
        }));
      },
    }),
    myEventsRegistered: builder.query({
      query: (accountId) => {
        return {
          url: '/api/events/myevents/registered',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return eventsAdapter.setAll(initialState, response?.data);
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/myEventsRegistered',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: (result, error, arg) => {
        const eventIds = result?.ids ?? [];
        return eventIds.map((event) => ({
          type: 'Event',
          id: event?._id,
        }));
      },
    }),
    myEventsArchived: builder.query({
      query: (accountId) => {
        return {
          url: '/api/events/myevents/archived',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return eventsAdapter.setAll(initialState, response?.data);
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/myEventsArchived',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['ArchivedEvents'],
    }),
    removeArchivedEvent: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/removeArchivedEvent',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/removeArchivedEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['ArchivedEvents'],
    }),
    addSaveByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/addSaveByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { ignoredEvents, savedEvents } = data;
          dispatch(setIgnoredEvents(ignoredEvents));
          dispatch(setSavedEvents(savedEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/addSaveByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeSaveByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/removeSaveByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { savedEvents } = data;
          dispatch(setSavedEvents(savedEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/removeSaveByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    addIgnoreByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/addIgnoreByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { ignoredEvents, savedEvents } = data;
          dispatch(setIgnoredEvents(ignoredEvents));
          dispatch(setSavedEvents(savedEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/addIgnoreByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeIgnoreByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/removeIgnoreByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { ignoredEvents } = data;
          dispatch(setIgnoredEvents(ignoredEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/removeIgnoreByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    addRegInPersonByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/addRegInPersonByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { registrationsInPerson, savedEvents, ignoredEvents } = data;
          dispatch(setRegistrationsInPerson(registrationsInPerson));
          dispatch(setIgnoredEvents(ignoredEvents));
          dispatch(setSavedEvents(savedEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/addRegInPersonByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeRegInPersonByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/removeRegInPersonByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { registrationsInPerson } = data;
          dispatch(setRegistrationsInPerson(registrationsInPerson));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/removeRegInPersonByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    addApprovalInPersonByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/addApprovalInPersonByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { pendingApprovalInPerson, savedEvents, ignoredEvents } = data;
          dispatch(setPendingApprovalInPerson(pendingApprovalInPerson));
          dispatch(setIgnoredEvents(ignoredEvents));
          dispatch(setSavedEvents(savedEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/addApprovalInPersonByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeApprovalInPersonByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/removeApprovalInPersonByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { pendingApprovalInPerson } = data;
          dispatch(setPendingApprovalInPerson(pendingApprovalInPerson));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/removeApprovalInPersonByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    addRegVirtualByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/addRegVirtualByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { registrationsVirtual, savedEvents, ignoredEvents } = data;
          dispatch(setRegistrationsVirtual(registrationsVirtual));
          dispatch(setIgnoredEvents(ignoredEvents));
          dispatch(setSavedEvents(savedEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/addRegVirtualByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeRegVirtualByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/removeRegVirtualByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { registrationsVirtual } = data;
          dispatch(setRegistrationsVirtual(registrationsVirtual));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/removeRegVirtualByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    addApprovalVirtualByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/addApprovalVirtualByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { pendingApprovalVirtual, savedEvents, ignoredEvents } = data;
          dispatch(setPendingApprovalVirtual(pendingApprovalVirtual));
          dispatch(setIgnoredEvents(ignoredEvents));
          dispatch(setSavedEvents(savedEvents));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/addApprovalVirtualByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeApprovalVirtualByUser: builder.mutation({
      query: ({ eventId, accountId }) => ({
        url: '/api/events/myevents/removeApprovalVirtualByUser',
        method: 'PATCH',
        body: { eventId, accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { pendingApprovalVirtual } = data;
          dispatch(setPendingApprovalVirtual(pendingApprovalVirtual));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'EventsApiSlice/removeApprovalVirtualByUser',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
  }),
});

export const {
  useGetEventQuery,
  useLazyGetEventQuery,
  useBigTagEventsQuery,
  useLazyBigTagEventsQuery,
  useTinyTagEventsQuery,
  useLazyTinyTagEventsQuery,
  useMyEventsSavedQuery,
  useLazyMyEventsSavedQuery,
  useMyEventsRegisteredQuery,
  useLazyMyEventsArchivedQuery,
  useRemoveArchivedEventMutation,
  useLazyMyEventsRegisteredQuery,
  useAddSaveByUserMutation,
  useRemoveSaveByUserMutation,
  useAddIgnoreByUserMutation,
  useRemoveIgnoreByUserMutation,

  //in person
  useAddRegInPersonByUserMutation,
  useRemoveRegInPersonByUserMutation,
  useAddApprovalInPersonByUserMutation,
  useRemoveApprovalInPersonByUserMutation,
  //virtual
  useAddRegVirtualByUserMutation,
  useRemoveRegVirtualByUserMutation,
  useAddApprovalVirtualByUserMutation,
  useRemoveApprovalVirtualByUserMutation,
  useNetworkEventsQuery,
  useLazyNetworkEventsQuery,
} = eventsApiSlice;

export const useMyEventsSavedSelectors = () => {
  const accountId = useSelector(selectId);
  const settingsCategory = useSelector(selectSettingsEventSearchCategory);
  const distanceMeasurement = useSelector(selectDistanceMeasurement);

  const accountGeoPosition = useSelector(selectGeoPosition);

  const selectMyEventsSavedResult =
    eventsApiSlice.endpoints.myEventsSaved.select(accountId);

  const selectMyEventsSavedData = createSelector(
    selectMyEventsSavedResult,
    (eventsResult) => eventsResult.data
  );

  const {
    selectAll: selectAllMyEventsSaved,
    selectById: selectMyEventSavedById,
    selectIds: selectMyEventsSavedIds,
  } = eventsAdapter.getSelectors(
    (state) => selectMyEventsSavedData(state) ?? initialState
  );

  const sortedMyEventsSaved = createSelector(
    selectAllMyEventsSaved,
    selectSettingsEventSearchDirection,
    function (state, direction) {
      if (settingsCategory === 'date') {
        const sortedArr = sortEventsByDateTime(state, direction);
        return (state = sortedArr);
      } else if (settingsCategory === 'distance') {
        const sortedArr = sortEventsByDistance(
          state,
          direction,
          accountGeoPosition,
          distanceMeasurement
        );
        return (state = sortedArr);
      }
    }
  );

  return {
    selectAllMyEventsSaved,
    selectMyEventSavedById,
    selectMyEventsSavedIds,
    sortedMyEventsSaved,
  };
};

export const useMyEventsRegisteredSelectors = () => {
  const accountId = useSelector(selectId);
  const settingsCategory = useSelector(selectSettingsEventSearchCategory);
  const distanceMeasurement = useSelector(selectDistanceMeasurement);

  const accountGeoPosition = useSelector(selectGeoPosition);

  const selectMyEventsRegisteredResult =
    eventsApiSlice.endpoints.myEventsRegistered.select(accountId);

  const selectMyEventsRegisteredData = createSelector(
    selectMyEventsRegisteredResult,
    (eventsResult) => eventsResult.data
  );

  const {
    selectAll: selectAllMyEventsRegistered,
    selectById: selectMyEventRegisteredById,
    selectIds: selectMyEventsRegisteredIds,
  } = eventsAdapter.getSelectors(
    (state) => selectMyEventsRegisteredData(state) ?? initialState
  );

  const sortedMyEventsRegistered = createSelector(
    selectAllMyEventsRegistered,
    selectSettingsEventSearchDirection,
    function (state, direction) {
      if (settingsCategory === 'date') {
        const sortedArr = sortEventsByDateTime(state, direction);
        return (state = sortedArr);
      } else if (settingsCategory === 'distance') {
        const sortedArr = sortEventsByDistance(
          state,
          direction,
          accountGeoPosition,
          distanceMeasurement
        );
        return (state = sortedArr);
      }
    }
  );

  return {
    selectAllMyEventsRegistered,
    selectMyEventRegisteredById,
    selectMyEventsRegisteredIds,
    sortedMyEventsRegistered,
  };
};

export const useMyEventsArchivedSelectors = () => {
  const accountId = useSelector(selectId);
  const settingsCategory = useSelector(selectSettingsEventSearchCategory);
  const distanceMeasurement = useSelector(selectDistanceMeasurement);

  const accountGeoPosition = useSelector(selectGeoPosition);

  const selectMyEventsArchivedResult =
    eventsApiSlice.endpoints.myEventsArchived.select(accountId);

  const selectMyEventsArchivedData = createSelector(
    selectMyEventsArchivedResult,
    (eventsResult) => eventsResult.data
  );

  const {
    selectAll: selectAllMyEventsArchived,
    selectById: selectMyEventArchivedById,
    selectIds: selectMyEventsArchivedIds,
  } = eventsAdapter.getSelectors(
    (state) => selectMyEventsArchivedData(state) ?? initialState
  );

  const sortedMyEventsArchived = createSelector(
    selectAllMyEventsArchived,
    selectSettingsEventSearchDirection,
    function (state, direction) {
      if (settingsCategory === 'date') {
        const sortedArr = sortEventsByDateTime(state, direction);
        return (state = sortedArr);
      } else if (settingsCategory === 'distance') {
        const sortedArr = sortEventsByDistance(
          state,
          direction,
          accountGeoPosition,
          distanceMeasurement
        );
        return (state = sortedArr);
      }
    }
  );

  return {
    selectAllMyEventsArchived,
    selectMyEventArchivedById,
    selectMyEventsArchivedIds,
    sortedMyEventsArchived,
  };
};

//old providesTags for registered and saved events which was throwing error
// providesTags: (result, error, arg) => [
//   ...result?.ids?.map((event) => ({ type: 'Event', id: event?._id })),
// ],
