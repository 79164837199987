import { useState, useEffect } from 'react';

const getLocalValue = (key, initValue) => {
  const storedValue = localStorage.getItem(key);

  // Check if the storedValue is not null
  if (
    storedValue !== null &&
    storedValue !== 'undefined' &&
    storedValue !== undefined &&
    storedValue
  ) {
    try {
      return JSON.parse(storedValue);
    } catch (error) {
      console.error(`Error parsing localStorage key "${key}":`, error);
      return initValue;
    }
  }

  // Return result of a function if initValue is a function
  if (initValue instanceof Function) return initValue();

  return initValue;
};

const useLocalStorage = (key, initValue) => {
  const [value, setValue] = useState(() => getLocalValue(key, initValue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

// Current setup for string data
export default useLocalStorage;
