//Hooks
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import { useSettingsHandlers } from '../useSettingsHandlers';

//Components

//Utility

const OrganizeSummary = ({ width }) => {
  //Hooks
  const { SettingsOrganizeLanguage } = useLanguageSettings();
  const {
    settingsOrganizeView: view,
    settingsOrganizeOverviewSortCategory: sortCategory,
    settingsOrganizeOverviewDirection: direction,
    settingsOrganizeOverviewFormat: format,
    settingsOrganizeStandaloneGridEvents: standaloneEvents,
  } = useSettingsHandlers();

  //Component variables
  let searchSummary;
  let formatSummary;
  let eventsGridSummary;

  if (sortCategory === 'date') {
    if (direction === 'ascending') {
      searchSummary = `${SettingsOrganizeLanguage.earliest} ${SettingsOrganizeLanguage.date}`;
    }
    if (direction === 'descending') {
      searchSummary = `${SettingsOrganizeLanguage.latest} ${SettingsOrganizeLanguage.date}`;
    }
  }

  if (format === 'table') {
    formatSummary = `${SettingsOrganizeLanguage.table}`;
  } else if (format === 'dash') {
    formatSummary = `${SettingsOrganizeLanguage.dash}`;
  }

  if (standaloneEvents === 'active') {
    eventsGridSummary = `${SettingsOrganizeLanguage.activeEvents}`;
  } else if (standaloneEvents === 'scheduled') {
    eventsGridSummary = `${SettingsOrganizeLanguage.scheduledEvents}`;
  } else if (standaloneEvents === 'archived') {
    eventsGridSummary = `${SettingsOrganizeLanguage.archivedEvents}`;
  }

  return (
    <>
      {view === 'overview' ? (
        <div
          className="search-summary flex-column mrg-tb24 fwsb color-gray access-ob access-o12"
          tabIndex="0"
        >
          {width > 1280 && (
            <p className="mrg-auto-left fs19">{formatSummary}</p>
          )}

          {width <= 848 && <br />}

          {view === 'overview' && format === 'cards' && (
            <div className="flex-row">
              <p className="fwb fs19">{eventsGridSummary}&nbsp;</p>
              <p className="fs18">{SettingsOrganizeLanguage.by}&nbsp;</p>
              <p className="fwb fs19">{searchSummary}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="search-summary mrg-tb24" />
      )}
    </>
  );
};

export default OrganizeSummary;
