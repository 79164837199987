import { useState, useEffect } from 'react';

//Hooks
import useLanguageData from '../../language/useLanguageData';
import useLanguageComponents from '../../language/useLanguageComponents';
import useGenericModal from '../../context/useGenericModal';
import useSettings from '../../context/useSettings';

//Components
import ItemButton from '../ItemButton/ItemButton';
import BigTagsListModal from '../Modal/ModalComponents/BigTagsListModal';

//Utility
import { cancel } from '../../assets/icons';

const BigTagsComponent = ({
  icon,
  id,
  limit,
  bigTags,
  handleAdd,
  handleRemove,
  setBigTags,
  fullWidth,
}) => {
  //Hooks
  const { BigTagsOpts } = useLanguageData();
  const { BigTagsComponent, Generic } = useLanguageComponents();
  const { setGenericModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightTag, setTapHighlightTag] = useState();

  //Initialize & UI functions
  useEffect(() => {
    setCloseModalFocusId('bTOpenListBtn');
  }, []); //set id for focus when modal closes

  //Component functions
  function handleSeeFullList() {
    setGenericModal(
      <BigTagsListModal
        bigTags={bigTags}
        limit={limit}
        handleAddTagsModal={handleAddTagsModal}
      />
    );
  }

  function handleAddTagsModal(tags) {
    let prevTagsArr = [...bigTags];
    let newTagsArr = [];

    tags?.map((tag) => {
      if (!prevTagsArr.includes(tag)) {
        return newTagsArr.push(tag);
      }
    });
    if (!setBigTags) {
      newTagsArr.map((tag) => handleAdd(tag));
    } else {
      newTagsArr = [...newTagsArr, ...prevTagsArr];
      newTagsArr.sort().reverse();
      setBigTags(newTagsArr);
    }
  }

  return (
    <div className="autocomplete__wrapper">
      <div className="flex-row full-width space-between">
        <ItemButton
          handler={handleSeeFullList}
          text={BigTagsComponent.addBigTags}
          fullWidth={fullWidth}
        />
      </div>

      <div className="dropdown-tags__wrapper">
        <div className="flex-row flex-center mrg-t12">
          <img className="" src={icon} alt={id} />
          {limit && (
            <p
              className={`fs16 fwn access-ob access-o6 no-select `}
              tabIndex="0"
            >
              {Generic.tags} {bigTags?.length} / {limit}
            </p>
          )}
        </div>

        {bigTags?.length > 0 && <div className="h12" />}
        <div className={`tags__container mrg-t12`}>
          {bigTags
            ?.map((element, index) => (
              <button
                key={index}
                tabIndex="0"
                aria-label={`${BigTagsComponent.remove} ${BigTagsOpts[element]}`}
                className={`tag__list-item highlight-bthin-theme br--small highlight-i-lgr fwsb fs16 fs14-phn no-select access-ob access-o6`}
                id={element}
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setTapHighlightTag(element),
                      () => setTapHighlightTag(),
                    ],
                    [() => handleRemove(element)]
                  )
                }
              >
                <p id={element}>{BigTagsOpts[element]}</p>
                <img
                  src={cancel}
                  alt={Generic.remove}
                  className={`tag-remove-btn-img ${
                    tapHighlightTag === element
                      ? 'filter-red'
                      : 'filter-lightgray'
                  }`}
                />
              </button>
            ))
            .reverse()}
        </div>
      </div>
    </div>
  );
};

export default BigTagsComponent;
