import { useEffect, useState } from 'react';

//Utility
import { retryWrapper } from '../../utils/logic/retryFns';

const LoadBucketImage = ({
  imagePath,
  imgClassName,
  partialPath,
  backupPath,
  title,
  index,
  imageRef,
  containerClassName,
  setImageLoadConfirmation,
}) => {
  //Component State
  const [displayImage, setDisplayImage] = useState(false);
  const [displayImageURL, setDisplayImageURL] = useState();

  useEffect(() => {
    if (imageRef?.croppedImage?.croppedImage) {
      setDisplayImageURL(imageRef?.croppedImage?.croppedImage);
      setDisplayImage(true);
    } else {
      setDisplayImageURL(
        `https://storage.googleapis.com/${partialPath}${
          process.env.REACT_APP_ENV === 'development' ? '_development' : ''
        }/${imagePath || backupPath}`
      );
    }
  }, [imagePath, imageRef]);

  useEffect(() => {
    if (displayImageURL && !imageRef) {
      loadImage();
    }
  }, [displayImageURL]);

  async function loadImage() {
    let img;
    try {
      async function getImage() {
        try {
          img = new Image();
          img.src = displayImageURL;

          img.onload = () => {
            setDisplayImage(true);
            if (setImageLoadConfirmation) {
              setImageLoadConfirmation(true); // use on parent container to toggle 'remove' if imageLoadConfirmation is false
            }
          };
        } catch (error) {
          throw error;
        }
      }

      retryWrapper(getImage);
    } catch (error) {
      setDisplayImageURL(
        `https://storage.googleapis.com/${partialPath}${
          process.env.REACT_APP_ENV === 'development' ? '_development' : ''
        }/${backupPath}`
      );
    }
  }

  return (
    <div className={`flex-row ${containerClassName ? containerClassName : ''}`}>
      {!displayImage && <div className={`${imgClassName} skeleton`} />}

      <img
        src={displayImageURL}
        crossOrigin="https://storage.googleapis.com"
        alt=""
        className={displayImage ? `${imgClassName}` : 'remove'}
        title={title}
        onLoad={() => setDisplayImage(true)}
        key={index ? `display-image-${index}` : null}
      />
    </div>
  );
};

export default LoadBucketImage;
