import axios from 'axios';

let BASE_URL = 'https://myndfull.com';

if (process.env.REACT_APP_ENV === 'development') {
  BASE_URL = process.env.REACT_APP_BASE_URL;
}

export const axiosLimited = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'client-version': `${process.env.REACT_APP_REDUX_V}`,
  },
  withCredentials: true,
});

// if (process.env.REACT_APP_ENV === 'development') {
//   axiosLimited.interceptors.response.use((response) => response);
// } else {
axiosLimited.interceptors.response.use(
  (response) => response,
  (error) => {
    const limitedError = {
      message: error.response?.data?.message,
      status: error.response?.data?.status,
      statusCode: error.response?.data?.statusCode,
      value: error.response?.data?.value,
    };
    return Promise.reject(limitedError);
  }
);
// }

export const axiosLimitedMultiPart = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'client-version': `${process.env.REACT_APP_REDUX_V}`,
  },
  withCredentials: true,
});

// if (process.env.REACT_APP_ENV === 'development') {
//   axiosLimitedMultiPart.interceptors.response.use((response) => response);
// } else {
axiosLimitedMultiPart.interceptors.response.use(
  (response) => response,
  (error) => {
    const limitedError = {
      message: error.response?.data?.message,
      status: error.response?.data?.status,
      statusCode: error.response?.data?.statusCode,
      value: error.response?.data?.value,
    };
    return Promise.reject(limitedError);
  }
);
// }
