export default function handleBillingSubscriptionProrationDifference(invoice) {
  let fullAmount;
  let hasProration = false;
  let highestQuantitySubscription = null;
  let highestQuantity = 0;

  invoice?.roomCodeSubscriptions?.forEach((sub) => {
    if (sub.proration) {
      hasProration = true;
      if (
        sub.quantity > highestQuantity &&
        sub.proration_details.credited_items === null
      ) {
        highestQuantity = sub.quantity;
        highestQuantitySubscription = sub;
      }
    }
  });

  if (hasProration) {
    if (invoice?.currency === 'cad') {
      fullAmount =
        highestQuantity * process.env.REACT_APP_TRANSLATION_ROOM_PRICE_IN_CAD;

      const difference = fullAmount - highestQuantitySubscription?.amount;
      return difference / highestQuantity;
    }
  }
}
