//hooks

//components
//utils

const InfoCardContainerEventInteractions = ({ eventInteractionsData }) => {
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Interactions</p>
      <p className="mrg-t6">
        Ignored By Users: {eventInteractionsData?.ignoredByUsers?.length}
      </p>
      <p className="mrg-t6">
        Saved By Users: {eventInteractionsData?.savedByUsers?.length}
      </p>
    </div>
  );
};

export default InfoCardContainerEventInteractions;
