import { useState } from 'react';
//Hooks
import useSettings from '../../context/useSettings';

//Components
import Spinner from '../Spinner/Spinner';

//Utility

const ConfirmSelection = ({
  text,
  text2,
  btn1Handler,
  btn1Text,
  btn1Color,
  btn2Handler,
  btn2Text,
  btn2Color,
  btn3Handler,
  btn3Text,
  btn3Color,
  btn1Id,
  btn2Id,
  btn3Id,
  center,
  isLoading,
  noMrg,
  noPad,
  customWrapper,
  ignoreMaxWidth,
}) => {
  //Hooks
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightBtn1, setTapHighlightBtn1] = useState(false);
  const [tapHighlightBtn2, setTapHighlightBtn2] = useState(false);
  const [tapHighlightBtn3, setTapHighlightBtn3] = useState(false);

  //Component functions
  function handleBtn1(e) {
    handleMobileTap(
      [() => setTapHighlightBtn1(true), () => setTapHighlightBtn1(false)],
      [() => btn1Handler(e)]
    );
  }
  function handleBtn2(e) {
    handleMobileTap(
      [() => setTapHighlightBtn2(true), () => setTapHighlightBtn2(false)],
      [() => btn2Handler(e)]
    );
  }
  function handleBtn3(e) {
    handleMobileTap(
      [() => setTapHighlightBtn3(true), () => setTapHighlightBtn3(false)],
      [() => btn3Handler(e)]
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          className={`confirm-selection__wrapper fit-content ${
            !text ? 'flex-center' : 'space-between'
          } ${!noPad ? 'confirm-selection__wrapper--padding' : ''} ${
            customWrapper ? customWrapper : ''
          } ${ignoreMaxWidth ? '' : 'confirm-selection__max-width'}`}
        >
          <div className={`flex-column ${text ? 'mrg-b24--mob' : ''}`}>
            {text && (
              <p
                className={`fwsb fs18 fwn wrap access-ob access-o6 ${
                  text2 ? 'mrg-b6' : ''
                }`}
                tabIndex="0"
              >
                {text}
              </p>
            )}
            {text2 && (
              <p className="fs18 fwn wrap access-ob access-o6" tabIndex="0">
                {text2}
              </p>
            )}
          </div>
          <div
            className={`confirm-selection__btn-container ${
              center ? 'space-evenly' : ''
            } ${!noMrg ? 'mrg-lr24 ' : ''}`}
          >
            {btn1Handler && (
              <button
                className={`access-ob access-o6 ${
                  btn1Color === 'pos'
                    ? 'confirmation--pos highlight-t-white highlight-bg-green--hover'
                    : btn1Color === 'neg'
                    ? 'confirmation--neg highlight-t-white highlight-bg-red--hover'
                    : 'highlight-b2-theme'
                } ${
                  tapHighlightBtn1 && btn1Color === 'pos'
                    ? 'highlight-bg-green--tap'
                    : ''
                } ${
                  tapHighlightBtn1 && btn1Color === 'neg'
                    ? 'highlight-bg-red--tap'
                    : ''
                }`}
                onClick={(e) => handleBtn1(e)}
                type="button"
                id={btn1Id}
              >
                <p className="pad-t2">{btn1Text}</p>
              </button>
            )}
            {btn2Handler && (
              <button
                className={`access-ob access-o6 ${
                  btn2Color === 'pos'
                    ? 'confirmation--pos highlight-t-white highlight-bg-green--hover'
                    : btn2Color === 'neg'
                    ? 'confirmation--neg highlight-t-white highlight-bg-red--hover'
                    : 'highlight-b2-theme'
                } ${
                  tapHighlightBtn2 && btn2Color === 'pos'
                    ? 'highlight-bg-green--tap'
                    : ''
                } ${
                  tapHighlightBtn2 && btn2Color === 'neg'
                    ? 'highlight-bg-red--tap'
                    : ''
                }`}
                onClick={(e) => handleBtn2(e)}
                type="button"
                id={btn2Id}
              >
                <p className="pad-t2 no-wrap">{btn2Text}</p>
              </button>
            )}
            {btn3Handler && (
              <button
                className={`access-ob access-o6  ${
                  btn3Color === 'pos'
                    ? 'confirmation--pos highlight-t-white highlight-bg-green--hover'
                    : btn3Color === 'neg'
                    ? 'confirmation--neg highlight-t-white highlight-bg-red--hover'
                    : 'highlight-b2-theme'
                } ${
                  tapHighlightBtn3 && btn3Color === 'pos'
                    ? 'highlight-bg-green--tap'
                    : ''
                } ${
                  tapHighlightBtn3 && btn3Color === 'neg'
                    ? 'highlight-bg-red--tap'
                    : ''
                }`}
                onClick={(e) => handleBtn3(e)}
                type="button"
                id={btn3Id}
              >
                <p className="flex-center full-width pad-t2 no-wrap">
                  {btn3Text}
                </p>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmSelection;
