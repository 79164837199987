import { apiSlice } from '../../../app/api/apiSlice';
import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { selectId } from '../account/accountSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';

const draftEventsAdapter = createEntityAdapter({
  selectId: (event) => event._id,
});

const initialState = draftEventsAdapter.getInitialState();

export const draftEventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myDraftEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/draft/mydrafts',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return draftEventsAdapter.setAll(initialState, response.data);
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/myDraftEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['Draft'],
      staleTime: 999999,
    }),
    createDraftEvent: builder.mutation({
      query: (body) => {
        return {
          url: '/api/organize/draft',
          method: 'POST',
          body,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/createDraftEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft'],
    }),
    updateDraftEvent: builder.mutation({
      query: ({ accountId, draftId, newDraft }) => ({
        url: `/api/organize/draft/${draftId}`,
        method: 'PUT',
        body: { accountId, newDraft },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/updateDraftEvents',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft'],
    }),
    removeDraftEvent: builder.mutation({
      query: ({ accountId, draftId }) => ({
        url: `/api/organize/draft/${draftId}`,
        method: 'DELETE',
        body: { accountId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'DraftEventApiSlice/removeDraftEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Draft'],
    }),
  }),
});

export const {
  useMyDraftEventsQuery,
  useLazyMyDraftEventsQuery,
  useCreateDraftEventMutation,
  useUpdateDraftEventMutation,
  useRemoveDraftEventMutation,
} = draftEventsApiSlice;

export const useDraftSelectors = () => {
  const accountId = useSelector(selectId);

  const selectDraftEventsResult =
    draftEventsApiSlice.endpoints.myDraftEvents.select(accountId);

  const selectMyDraftEventsData = createSelector(
    selectDraftEventsResult,
    (result) => result.data
  );
  const {
    selectAll: selectAllDraftEvents,
    selectById: selectDraftEventById,
    selectIds: selectDraftEventIds,
  } = draftEventsAdapter.getSelectors(
    (state) => selectMyDraftEventsData(state) ?? initialState
  );

  return {
    selectAllDraftEvents,
    selectDraftEventById,
    selectDraftEventIds,
  };
};
