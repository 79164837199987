import { useEffect, useState } from 'react';

//Hooks
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import { useRegistrationHandlers } from '../../useRegistrationHandlers';
import useLanguageData from '../../../../../language/useLanguageData';

//Components
import BigTagsComponent from '../../../../../components/BigTagsComponent/BigTagsComponent';
import CustomTagsList from '../../../../../components/CustomTagsList/CustomTagsList';
import MapContainerWithDistanceFilter from '../../../../../components/MapContainer/MapContainerWithDistanceFilter';
import AutocompletePlacesNoAddress from '../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import SelectDropdownMultiple from '../../../../../components/SelectDropdown/SelectDropdownMultiple';
import NavigationArrows from '../../../../../components/NavigationArrows/NavigationArrows';
import DistanceToggle from '../../../../../components/DistanceToggle/DistanceToggle';

//Utility
import { bigtag, checkmark_green, tinytag } from '../../../../../assets/icons';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

const PersonalSearchSettings = () => {
  //Hooks
  const { PersonalSearchSettings } = useLanguageRegistration();
  const { Generic } = useLanguageComponents();
  const { CountriesOpts, SearchLanguageOpts } = useLanguageData();
  const {
    registrationAttendeeBigTags: bigTags,
    registrationAttendeeCoords: coords,
    registrationAttendeeDistanceFilter: distanceFilter,
    registrationAttendeeDistanceMeasurement: distanceMeasurement,
    registrationAttendeeEventSearchOnlineCountries: eventSearchOnlineCountries,
    registrationAttendeePlaceCoordinates: placeCoordinates,
    registrationAttendeeSearchLanguages: searchLanguages,
    registrationAttendeeTinyTagGroups: tinyTagGroups,
    registrationAttendeeTinyTags: tinyTags,
    handleRegistrationSetAttendeeBigTags: setBigTags,
    handleRegistrationSetAttendeeCoords: setCoords,
    handleRegistrationSetAttendeeDistanceFilter: setDistanceFilter,
    handleRegistrationSetAttendeeDistanceMeasurement: setDistanceMeasurement,
    handleRegistrationSetAttendeeEventSearchOnlineCountries:
      setEventSearchOnlineCountries,
    handleRegistrationSetAttendeePlaceCoordinates: setPlaceCoordinates,
    handleRegistrationSetAttendeeSearchLanguages: setSearchLanguages,
    handleRegistrationSetAttendeeTinyTagGroups: setTinyTagGroups,
    handleRegistrationSetAttendeeTinyTags: setTinyTags,
    handleRegistrationPageDecrease,
    handleRegistrationPageIncrease,
    handleRegistrationPageCompletionStatus,
    registrationPage,
  } = useRegistrationHandlers();

  //state
  const [continueBtnCheck, setContinueBtnCheck] = useState(false);

  //initialize
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  }, []);

  ///set default position to user's location

  useEffect(() => {
    if (
      bigTags?.length > 0 &&
      (tinyTags?.length > 0 ||
        tinyTagGroups.some((obj) => obj.tags && obj.tags.length > 0)) &&
      searchLanguages?.length > 0 &&
      !searchLanguages.includes('select') &&
      !eventSearchOnlineCountries.includes('select') &&
      coords
    ) {
      setContinueBtnCheck(true);
    } else {
      setContinueBtnCheck(false);
    }
    /*eslint-disable-next-line*/
  }, [bigTags, tinyTags, coords, searchLanguages]); //valid data for continue

  useEffect(() => {
    if (!coords && placeCoordinates?.lat && placeCoordinates?.lng) {
      let newCoords = [placeCoordinates?.lat, placeCoordinates?.lng];
      setCoords(newCoords);
    }
    /*eslint-disable-next-line*/
  }, [placeCoordinates]);

  //functions
  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setPlaceCoordinates(currentPosition);
  };

  function setAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    setCoords(newCoords);
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  function addBigTag(addedTag) {
    let newArr = [...bigTags];
    newArr.push(addedTag);
    setBigTags(newArr);
  }

  function removeBigTag(removedTag) {
    let newArr = bigTags.filter((tag) => tag !== removedTag);
    setBigTags(newArr);
  }

  function addTinyTag(addedTag) {
    let newArr = [...tinyTags];
    newArr.push(addedTag);
    setTinyTags(newArr);
  }

  function removeTinyTag(removedTag) {
    let newArr = tinyTags.filter((tag) => tag !== removedTag);
    setTinyTags(newArr);
  }

  return (
    <>
      <label
        aria-label={PersonalSearchSettings.ariaBigTags}
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="bigTagsLabel"
        tabIndex="0"
      >
        {PersonalSearchSettings.bigTagsLabel}
        <p className="color-theme disable-select" aria-hidden="true">
          &nbsp;*
        </p>

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
          {bigTags?.length > 0 && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>
      <div className="h12" />
      <p
        className="description-text"
        tabIndex="0"
        aria-label={PersonalSearchSettings.ariaBigTagsInputInstructions}
      >
        {PersonalSearchSettings.bigTagsDescription}
      </p>
      <div className="h24" />
      <BigTagsComponent
        bigTags={bigTags}
        handleAdd={addBigTag}
        handleRemove={removeBigTag}
        icon={bigtag}
        id={'bigTags'}
        limit={20}
        setBigTags={setBigTags}
        twoTagRows={true}
        fullWidth={true}
      />
      <div className="h36" />

      <label
        aria-label={PersonalSearchSettings.ariaTinyTags}
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="tinyTagsLabel"
        tabIndex="0"
      >
        {PersonalSearchSettings.tinyTagsLabel}
        <p className="color-theme disable-select" aria-hidden="true">
          &nbsp;*
        </p>

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
          {(tinyTags?.length > 0 ||
            tinyTagGroups.some((obj) => obj.tags && obj.tags.length > 0)) && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {PersonalSearchSettings.tinyTagsDescription}
      </p>
      <div className="h24" />
      <CustomTagsList
        handleAdd={addTinyTag}
        handleRemove={removeTinyTag}
        icon={tinytag}
        id={'tinyTags'}
        limit={200}
        tinyTags={tinyTags}
        setTinyTags={setTinyTags}
        twoTagRows={true}
        tinyTagGroups={tinyTagGroups}
        setTinyTagGroups={setTinyTagGroups}
        bigTags={bigTags}
      />
      <div className="h36" />

      <label
        aria-label={PersonalSearchSettings.ariaEventSearchInPerson}
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="searchLocationLabel"
        tabIndex="0"
      >
        {PersonalSearchSettings.eventSearchInPerson}
        <p className="color-theme disable-select" aria-hidden="true">
          &nbsp;*
        </p>

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">{Generic.required}</p>
          {distanceMeasurement && placeCoordinates && (
            <img
              src={checkmark_green}
              alt={Generic.checkmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {PersonalSearchSettings.eventSearchInPersonDescription}
      </p>
      <div className="h24" />

      {placeCoordinates && (
        <MapContainerWithDistanceFilter
          distanceFilter={distanceFilter}
          distanceMeasurement={distanceMeasurement}
          handler={setCoords}
          loadTrigger={true}
          loadValue={coords}
          placeCoordinates={placeCoordinates}
        />
      )}
      <div className="h24" />

      <AutocompletePlacesNoAddress
        setAutocompleteCoordinates={setAutocompleteCoordinates}
      />
      <div className="h24" />
      <DistanceToggle
        distanceFilter={distanceFilter}
        setDistanceFilter={setDistanceFilter}
        distanceMeasurement={distanceMeasurement}
        setDistanceMeasurement={setDistanceMeasurement}
      />
      <div className="h36" />

      <label
        aria-label={PersonalSearchSettings.ariaEventSearchOnline}
        className="content-label"
        id="eventSearchOnlineLabel"
        tabIndex="0"
      >
        {PersonalSearchSettings.eventSearchOnlineLabel}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {PersonalSearchSettings.eventSearchOnlineDescription}
      </p>
      <div className="h24" />

      <SelectDropdownMultiple
        addButtonLabel={PersonalSearchSettings.country}
        handleSelectionChange={setEventSearchOnlineCountries}
        id={'eventSearchOnlineCountries'}
        list={CountriesOpts}
        selections={eventSearchOnlineCountries}
        modalLabel={PersonalSearchSettings.eventSearchOnlineLabel}
        customWrapper={'full-width'}
      />
      <div className="h36" />

      <label
        aria-label={PersonalSearchSettings.ariaSearchLanguages}
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="searchLanguagesLabel"
        tabIndex="0"
      >
        {PersonalSearchSettings.searchLanguagesLabel}
        <p className="color-theme disable-select" aria-hidden="true">
          &nbsp;*
        </p>

        <div className="flex-row mrg-auto-left">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>

          {searchLanguages?.length > 0 &&
            !searchLanguages.includes('select') && (
              <img
                src={checkmark_green}
                alt={Generic.checkmark}
                className="mrg-l6"
              />
            )}
        </div>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {PersonalSearchSettings.searchLangaugesDescription}
      </p>
      <div className="h24" />

      <SelectDropdownMultiple
        addButtonLabel={PersonalSearchSettings.languageBtn}
        handleSelectionChange={setSearchLanguages}
        id={'searchLanguage'}
        list={SearchLanguageOpts}
        selections={searchLanguages}
        modalLabel={PersonalSearchSettings.searchLanguagesLabel}
        customWrapper={'full-width'}
      />
      <div className="h48" />

      <NavigationArrows
        continueBtnCheck={continueBtnCheck}
        handlePageDecrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageDecrease();
        }}
        handlePageIncrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageIncrease(registrationPage);
        }}
      />
    </>
  );
};

export default PersonalSearchSettings;
