import { useState, useRef, useEffect } from 'react';

//Hooks
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import Speaker0 from './speakers/Speaker0';
import Speaker1 from './speakers/Speaker1';
import Speaker2 from './speakers/Speaker2';
import Speaker3 from './speakers/Speaker3';
import Speaker4 from './speakers/Speaker4';
import Speaker5 from './speakers/Speaker5';
import ItemButton from '../../../../components/ItemButton/ItemButton';
import TextArea from '../../../../components/TextArea/TextArea';

//Utility
import ScrollTo from '../../../../utils/UI/ScrollTo';
import { speaker } from '../../../../assets/icons/index';

const Participants = ({
  initialize,
  speaker0ImgRef,
  speaker1ImgRef,
  speaker2ImgRef,
  speaker3ImgRef,
  speaker4ImgRef,
  speaker5ImgRef,
  editEventMode,
  pauseCrop,
  imageChangeTracker,
  setImageChangeTracker,
  handleSetModerator,
  loadModerator,
  loadSpeaker0Data,
  loadSpeaker1Data,
  loadSpeaker2Data,
  loadSpeaker3Data,
  loadSpeaker4Data,
  loadSpeaker5Data,
  handleSetSpeaker0Data,
  handleSetSpeaker1Data,
  handleSetSpeaker2Data,
  handleSetSpeaker3Data,
  handleSetSpeaker4Data,
  handleSetSpeaker5Data,
}) => {
  //Hooks
  const { CreateEvent } = useLanguageOrganize();

  //Component state
  const [renderIndex, setRenderIndex] = useState([]);

  //Component variables
  const effectRan = useRef(false);
  let content;

  //Initialize function
  useEffect(() => {
    if (initialize) {
      if (effectRan.current === false) {
        if (loadSpeaker0Data) {
          setRenderIndex((preRenderIndex) => [...preRenderIndex, 'speaker0']);
        }
        if (loadSpeaker1Data) {
          setRenderIndex((preRenderIndex) => [...preRenderIndex, 'speaker1']);
        }
        if (loadSpeaker2Data) {
          setRenderIndex((preRenderIndex) => [...preRenderIndex, 'speaker2']);
        }
        if (loadSpeaker3Data) {
          setRenderIndex((preRenderIndex) => [...preRenderIndex, 'speaker3']);
        }
        if (loadSpeaker4Data) {
          setRenderIndex((preRenderIndex) => [...preRenderIndex, 'speaker4']);
        }
        if (loadSpeaker5Data) {
          setRenderIndex((preRenderIndex) => [...preRenderIndex, 'speaker5']);
        }

        if (
          !loadSpeaker0Data &&
          !loadSpeaker1Data &&
          !loadSpeaker2Data &&
          !loadSpeaker3Data &&
          !loadSpeaker4Data &&
          !loadSpeaker5Data
        ) {
          setRenderIndex(['speaker0']);
        }
        effectRan.current = true;
      }
    }
  }, [initialize]);

  //Component state
  function addSpeaker(e) {
    e.preventDefault();
    ScrollTo('addSpeakerBtn', 'smooth', false, 'addSpeakerBtn');

    if (!renderIndex.includes('speaker0')) {
      setRenderIndex([...renderIndex, 'speaker0']);
    } else if (!renderIndex.includes('speaker1')) {
      setRenderIndex([...renderIndex, 'speaker1']);
    } else if (!renderIndex.includes('speaker2')) {
      setRenderIndex([...renderIndex, 'speaker2']);
    } else if (!renderIndex.includes('speaker3')) {
      setRenderIndex([...renderIndex, 'speaker3']);
    } else if (!renderIndex.includes('speaker4')) {
      setRenderIndex([...renderIndex, 'speaker4']);
    } else if (!renderIndex.includes('speaker5')) {
      setRenderIndex([...renderIndex, 'speaker5']);
    }
  }

  function removeSpeaker(id, imagePreview) {
    URL.revokeObjectURL(imagePreview);
    let arr = [...renderIndex];
    //find renderIndex & remove
    const result = arr.find((obj) => {
      return obj === id;
    });
    const newArr = arr.filter((item) => {
      if (item !== result) {
        return true;
      } else if (item === result) {
        return false;
      }
    });
    setRenderIndex(newArr);
  }

  //JSX conditional
  const speakersComponentMap = {
    speaker0: (
      <Speaker0
        initialize={initialize}
        editEventMode={editEventMode}
        key="speaker0"
        id="speaker0"
        removeSpeaker={removeSpeaker}
        ref={speaker0ImgRef}
        loadValue={loadSpeaker0Data}
        speakerNameFieldText={CreateEvent.participants.speakerName}
        speakerBioFieldText={CreateEvent.participants.speakerBio}
        ariaImageType={CreateEvent.participants.profilePhoto}
        pauseCrop={pauseCrop}
        imageChangeTracker={imageChangeTracker}
        setImageChangeTracker={setImageChangeTracker}
        handleSetSpeaker0Data={handleSetSpeaker0Data}
      />
    ),
    speaker1: (
      <Speaker1
        initialize={initialize}
        editEventMode={editEventMode}
        key="speaker1"
        id="speaker1"
        removeSpeaker={removeSpeaker}
        ref={speaker1ImgRef}
        loadValue={loadSpeaker1Data}
        speakerNameFieldText={CreateEvent.participants.speakerName}
        speakerBioFieldText={CreateEvent.participants.speakerBio}
        ariaImageType={CreateEvent.participants.profilePhoto}
        pauseCrop={pauseCrop}
        imageChangeTracker={imageChangeTracker}
        setImageChangeTracker={setImageChangeTracker}
        handleSetSpeaker1Data={handleSetSpeaker1Data}
      />
    ),
    speaker2: (
      <Speaker2
        initialize={initialize}
        editEventMode={editEventMode}
        key="speaker2"
        id="speaker2"
        removeSpeaker={removeSpeaker}
        ref={speaker2ImgRef}
        loadValue={loadSpeaker2Data}
        speakerNameFieldText={CreateEvent.participants.speakerName}
        speakerBioFieldText={CreateEvent.participants.speakerBio}
        ariaImageType={CreateEvent.participants.profilePhoto}
        pauseCrop={pauseCrop}
        imageChangeTracker={imageChangeTracker}
        setImageChangeTracker={setImageChangeTracker}
        handleSetSpeaker2Data={handleSetSpeaker2Data}
      />
    ),
    speaker3: (
      <Speaker3
        initialize={initialize}
        editEventMode={editEventMode}
        key="speaker3"
        id="speaker3"
        removeSpeaker={removeSpeaker}
        ref={speaker3ImgRef}
        loadValue={loadSpeaker3Data}
        speakerNameFieldText={CreateEvent.participants.speakerName}
        speakerBioFieldText={CreateEvent.participants.speakerBio}
        ariaImageType={CreateEvent.participants.profilePhoto}
        pauseCrop={pauseCrop}
        imageChangeTracker={imageChangeTracker}
        setImageChangeTracker={setImageChangeTracker}
        handleSetSpeaker3Data={handleSetSpeaker3Data}
      />
    ),
    speaker4: (
      <Speaker4
        initialize={initialize}
        editEventMode={editEventMode}
        key="speaker4"
        id="speaker4"
        removeSpeaker={removeSpeaker}
        ref={speaker4ImgRef}
        loadValue={loadSpeaker4Data}
        speakerNameFieldText={CreateEvent.participants.speakerName}
        speakerBioFieldText={CreateEvent.participants.speakerBio}
        ariaImageType={CreateEvent.participants.profilePhoto}
        pauseCrop={pauseCrop}
        imageChangeTracker={imageChangeTracker}
        setImageChangeTracker={setImageChangeTracker}
        handleSetSpeaker4Data={handleSetSpeaker4Data}
      />
    ),
    speaker5: (
      <Speaker5
        initialize={initialize}
        editEventMode={editEventMode}
        key="speaker5"
        id="speaker5"
        removeSpeaker={removeSpeaker}
        ref={speaker5ImgRef}
        loadValue={loadSpeaker5Data}
        speakerNameFieldText={CreateEvent.participants.speakerName}
        speakerBioFieldText={CreateEvent.participants.speakerBio}
        ariaImageType={CreateEvent.participants.profilePhoto}
        pauseCrop={pauseCrop}
        imageChangeTracker={imageChangeTracker}
        setImageChangeTracker={setImageChangeTracker}
        handleSetSpeaker5Data={handleSetSpeaker5Data}
      />
    ),
  };

  content = (
    <fieldset
      aria-describedby="aria-fieldset-instructions-participants"
      className="fieldset"
      id="fieldset-participants"
    >
      <legend id="aria-fieldset-instructions-participants">
        {CreateEvent.participants.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="participantsLabel"
        aria-describedby="aria-fieldset-instructions-participants"
        tabIndex="0"
      >
        {CreateEvent.participants.participants}
      </label>
      <FocusSkip
        skipToLabel={CreateEvent.skipTo.skipToAccess}
        skipToId={'accessLabel'}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />
      <label className="content-heading-styled text-gray flex-row" tabIndex="0">
        {CreateEvent.participants.moderator}
      </label>
      <div className="h12" />
      <TextArea
        handleChange={handleSetModerator}
        limit={100}
        id={'moderator'}
        disableEnter={true}
        labelText={CreateEvent.participants.moderatorName}
        loadTrigger={initialize ? true : false}
        loadValue={loadModerator}
      />
      <div className="h48" />
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="speakersLabel"
      >
        {CreateEvent.participants.speakers}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.participants.speakersDescription}
      </p>
      <div className="h24" />
      <ItemButton
        handler={addSpeaker}
        text={CreateEvent.participants.addSpeaker}
        lengthOn={true}
        propertyLength={renderIndex.length}
        lengthMax={6}
        icon={speaker}
        fullWidth={true}
        ariaLabel={CreateEvent.participants.addSpeaker}
        limit={6}
        itemButtonId={'addSpeakerBtn'}
      />
      <div className="h24" />
      {renderIndex.map((componentIndex, index) => {
        return (
          <div key={componentIndex}>{speakersComponentMap[componentIndex]}</div>
        );
      })}
    </fieldset>
  );

  return content;
};

export default Participants;
