import { apiSlice } from '../../../app/api/apiSlice';
import { addReduxApiStatus } from '../../main/apiStatus/apiStatusSlice';
import {
  setAdminSupportTickets,
  setAdminSupportTicket,
  addAdminSupportSearchQuery,
} from './adminSupportSlice';

export const adminSupportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchReference: builder.query({
      query: ({ adminAccountId, searchType, referenceId }) => {
        return {
          url: '/api/admin/support/searchReference',
          params: {
            adminAccountId,
            searchType,
            referenceId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(addAdminSupportSearchQuery(data?.doc[0]));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/searchReference',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['Ticket'],
    }),
    getTicketsSupport: builder.query({
      query: ({ adminAccountId }) => {
        return {
          url: '/api/admin/support/ticket/getTickets',
          params: {
            adminAccountId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setAdminSupportTickets(data?.tickets));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('adminSupportApiSlice/getTicketsSupport:', error);
          }
        }
      },
      providesTags: ['Ticket'],
    }),
    getOneTicket: builder.query({
      query: ({ adminAccountId, ticketId }) => {
        return {
          url: '/api/admin/support/ticket/getOne',
          params: {
            adminAccountId,
            ticketId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setAdminSupportTicket(data?.ticket));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('adminSupportApiSlice/ getEventVerifications:', error);
          }
        }
      },
      providesTags: ['EventVerification'],
    }),
    acceptAssignment: builder.mutation({
      query: ({ adminAccountId, ticketId, notes, adminReference }) => ({
        url: `/api/admin/support/ticket/acceptAssignment`,
        method: 'PATCH',
        body: { adminAccountId, ticketId, notes, adminReference },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          console.log('dev error:', error);
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/acceptTicket',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeAssignment: builder.mutation({
      query: ({ adminAccountId, ticketId, notes, adminReference }) => ({
        url: `/api/admin/support/ticket/removeAssignment`,
        method: 'PATCH',
        body: { adminAccountId, ticketId, notes, adminReference },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/removeTicket',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    pendingUserAction: builder.mutation({
      query: ({ adminAccountId, ticketId, notes, adminReference }) => ({
        url: `/api/admin/support/ticket/pendingUserAction`,
        method: 'PATCH',
        body: { adminAccountId, ticketId, notes, adminReference },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/pendingUserAction',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    resolveTicket: builder.mutation({
      query: ({ adminAccountId, ticketId, notes, adminReference }) => ({
        url: `/api/admin/support/ticket/resolveTicket`,
        method: 'PATCH',
        body: { adminAccountId, ticketId, notes, adminReference },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/resolveTicket',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    reopenTicket: builder.mutation({
      query: ({ adminAccountId, ticketId, notes, adminReference }) => ({
        url: `/api/admin/support/ticket/reopenTicket`,
        method: 'PATCH',
        body: { adminAccountId, ticketId, notes, adminReference },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/reopenTicket',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    adminCreateTicket: builder.mutation({
      query: ({
        adminAccountId,
        ticketType,
        ticketReference,
        ticketKey,
        otherTicketDescription,
        ticketDetailedDescription,
        claimAssignment,
        suspend,
      }) => ({
        url: `/api/admin/support/ticket/createTicket`,
        method: 'POST',
        body: {
          adminAccountId,
          ticketType,
          ticketReference,
          ticketKey,
          otherTicketDescription,
          ticketDetailedDescription,
          claimAssignment,
          suspend,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const success = {
            id: Date.now(),
            status: 'success',
            origin: 'adminSupportApiSlice/resolveTicket',
          };
          return dispatch(addReduxApiStatus(success));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/resolveTicket',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
  }),
});

export const {
  useLazySearchReferenceQuery,
  useGetTicketsSupportQuery,
  useAcceptAssignmentMutation,
  useRemoveAssignmentMutation,
  usePendingUserActionMutation,
  useResolveTicketMutation,
  useReopenTicketMutation,
  useAdminCreateTicketMutation,
  useLazyGetOneTicketQuery,
} = adminSupportApiSlice;
