import { useEffect, useState } from 'react';

//Hooks
import useGenericModal from '../../../../context/useGenericModal';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useSettings from '../../../../context/useSettings';
import useLanguageData from '../../../../language/useLanguageData';
import useReCaptcha from '../../../../hooks/useReCaptcha';
import useApiStatus from '../../../../context/useApiStatus';
import { useAuthReduxHandlers } from '../../../../features/main/auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../../../../features/main/account/useAccountHandlers';
import { useTranslationRoomSocket } from '../../../../context/sockets/TranslationRoomSocketProvider';

//Components
import RadialButtonList from '../../../RadialButtonList/RadialButtonList';
import TextArea from '../../../TextArea/TextArea';
import Spinner from '../../../Spinner/Spinner';

//Utility
import {
  cancel,
  cancel_lightgray,
  checkmark_green,
} from '../../../../assets/icons';
import { axiosLimited } from '../../../../axios/axios';
import {
  handleDownloadTranscriptForAttendee,
  handleDownloadTranscriptForHost,
} from '../../../../features/main/translations/functions/transcripts';

const ReportModal = ({ type, targetData }) => {
  //Hooks
  const { authCurrentToken } = useAuthReduxHandlers();
  const {
    accountId,
    accountAccountReference,
    accountFirstName,
    accountLastName,
  } = useAccountReduxHandlers();
  const {
    roomCode,
    speakersData,
    hostTranslationData,
    userSessionIdTranslations,
    reportTranslationData,
  } = useTranslationRoomSocket();

  const { handleCloseModal } = useGenericModal();
  const { ReportModal, Generic, EmailInput } = useLanguageComponents();
  const { handleMobileTap } = useSettings();
  const { ReportEventOpts, BrowserOpts } = useLanguageData();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleError, handleSuccess } = useApiStatus();

  //Component state

  const [ticketKey, setTicketKey] = useState();
  const [otherTicketDescription, setOtherTicketDescription] = useState();
  const [ticketDetailedDescription, setTicketDetailedDescription] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const [checkRan, setCheckRan] = useState(false);
  const [regexCheck, setRegexCheck] = useState(false);
  const [submitValid, setSubmitValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //translationRoom specific
  const [browserKey, setBrowserKey] = useState();

  //UI state
  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);
  const [tapHighlightSubmitBtn, setTapHighlightSubmitBtn] = useState(false);

  //Variables
  const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  //initialize
  useEffect(() => {
    document.getElementById('reportModal').focus();
  }, []);

  //Component Functions
  useEffect(() => {
    if (type === 'translationRoom') {
      setTicketKey('technical');
    }
  }, [type]);

  useEffect(() => {
    if (!ticketKey && type === 'event') {
      if (submitValid) {
        return setSubmitValid(false);
      } else {
        return;
      }
    }

    if (type === 'translationRoom') {
      if (ticketDetailedDescription?.length > 0 && browserKey) {
        return setSubmitValid(true);
      } else {
        return setSubmitValid(false);
      }
    }

    if (ticketKey === 'other') {
      if (!otherTicketDescription) {
        if (submitValid) {
          return setSubmitValid(false);
        } else {
          return;
        }
      }
    }
    if (!authCurrentToken || !accountId) {
      if (!name || !email || !regexCheck) {
        if (submitValid) {
          return setSubmitValid(false);
        } else {
          return;
        }
      }
    }
    return setSubmitValid(true);
  }, [
    ticketKey,
    ticketDetailedDescription,
    otherTicketDescription,
    authCurrentToken,
    accountId,
    name,
    email,
    regexCheck,
    browserKey,
  ]);

  useEffect(() => {
    const check = EMAIL_REGEX.test(email);
    setRegexCheck(check);
    if (!email && regexCheck) {
      setRegexCheck(false);
    }
  }, [email]);

  function handleReportEvent(violationKey) {
    setTicketKey(violationKey);
  }

  function handleEmailInput(text) {
    setEmail(text);
    setCheckRan(true);
  }

  useEffect(() => {
    if (ticketKey === 'other') {
      setOtherTicketDescription();
    }
  }, [ticketKey]);

  async function handleSubmitReport() {
    if (submitValid && !isLoading) {
      setIsLoading(true);

      const recaptchaVerified = await handleReCaptchaVerify(
        'REPORT_SUBMIT',
        false
      );

      if (recaptchaVerified !== true) return false;

      let formData = new FormData();

      if (name) {
        formData.append('name', name);
      } else if (accountFirstName && accountLastName) {
        const accountName = `${accountFirstName} ${accountLastName}`;
        formData.append('accountName', accountName);
      }

      if (email) {
        formData.append('email', email);
      }

      if (accountId) {
        formData.append('submitUserId', accountId);
      }
      if (accountAccountReference) {
        formData.append('accountReference', accountAccountReference);
      }
      formData.append('ticketType', type);
      formData.append('ticketKey', ticketKey);

      if (otherTicketDescription) {
        formData.append('otherTicketDescription', otherTicketDescription);
      }

      formData.append('ticketDetailedDescription', ticketDetailedDescription);

      if (type === 'event') {
        formData.append('eventId', targetData._id);
        formData.append('targetReference', targetData?.meta?.eventReference);
      }

      if (type === 'translationRoom') {
        formData.append('browserKey', browserKey);
        let transcript;
        if (hostTranslationData?.length > 0) {
          transcript = await handleDownloadTranscriptForHost(
            false,
            userSessionIdTranslations,
            roomCode,
            hostTranslationData,
            speakersData
          );
        } else if (reportTranslationData?.length > 0) {
          transcript = await handleDownloadTranscriptForAttendee(
            userSessionIdTranslations,
            roomCode,
            reportTranslationData
          );
        }

        formData.append('additionalData', transcript);
      }

      if (recaptchaVerified !== true) return false;

      try {
        const res = await axiosLimited.post('/report/submit', formData);

        if (res.data.ticketId) {
          if (accountId) {
            await axiosLimited.patch('/report/submit/addTicket/account', {
              ticketId: res.data.ticketId,
              accountId,
            });
          }

          if (targetData?._id) {
            await axiosLimited.patch('/report/submit/addTicket/event', {
              ticketId: res.data.ticketId,
              eventId: targetData._id,
            });
          }
        }

        //add success check
        setIsLoading(false);
        handleSuccess({
          message: 'success',
          id: Date.now(),
          origin: 'ReportModal.js/handleSubmitReport',
        });
        handleCloseModal();
      } catch (error) {
        const newError = {
          error,
          id: Date.now(),
          origin: 'ReportModal.js/handleSubmitReport',
        };
        setIsLoading(false);
        handleError(newError);
      }
    }
  }

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="reportModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id="reportModal"
          >
            {type === 'event' && <p>{ReportModal.reportEvent}</p>}
            {type === 'translationRoom' && (
              <p>{ReportModal.reportTechnicalProblem}</p>
            )}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob access-o6  ${
            tapHighlightCloseBtn ? 'highlight-i-lgr--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightCloseBtn(true),
                () => setTapHighlightCloseBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={ReportModal.close}
        >
          <img src={cancel} alt={ReportModal.close} className="svg" />
        </button>
      </div>
      <div
        className="modal-basic__container list-style-none"
        id="reportModalContainer"
      >
        {type === 'event' && (
          <>
            <p className="fs18 fwsb mrg-tb12 access-ob access-o6" tabIndex="0">
              <span className="color-theme">*</span>&nbsp;
              {ReportModal.selectViolation}
            </p>
            <RadialButtonList
              onChange={handleReportEvent}
              options={ReportEventOpts}
              id={'reportViolations'}
            />
            {ticketKey === 'other' && (
              <>
                <div className="h12" />
                <TextArea
                  customWidth={'100%'}
                  disableEnter={true}
                  handleChange={setOtherTicketDescription}
                  hideWordCount={true}
                  id={'otherViolationTitle'}
                  labelText={ReportModal.otherViolationTitle}
                  limit={100}
                  onBlurHandlerOff={true}
                />
              </>
            )}
          </>
        )}
        {type === 'translationRoom' && (
          <>
            <p className="fs18 fwsb mrg-tb12 access-ob access-o6" tabIndex="0">
              <span className="color-theme">*</span>&nbsp;
              {ReportModal.selectBrowser}
            </p>
            <RadialButtonList
              onChange={setBrowserKey}
              options={BrowserOpts}
              id={'translationRoomBrowser'}
            />
          </>
        )}
        <div className="h24" />
        <p className="fs18 fwsb mrg-b24 access-ob access-o6" tabIndex="0">
          {type === 'event' && ReportModal.describeViolation}
          {type === 'translationRoom' && (
            <>
              <span className="mrg-r6 color-theme">*</span>
              {ReportModal.describeProblem}
            </>
          )}
        </p>
        <TextArea
          customWidth={'100%'}
          handleChange={setTicketDetailedDescription}
          hideWordCount={true}
          labelText={ReportModal.description}
          id={'ticketDetailedDescription'}
          limit={2000}
          large={true}
          fixedHeight={'280px'}
          onBlurHandlerOff={true}
        />
        {!authCurrentToken || !accountId ? (
          <div className="h48" />
        ) : (
          <div className="h96" />
        )}

        {(!authCurrentToken || !accountId) && (
          <div className="mrg-b48">
            <p className="fs18 fwsb mrg-b24 access-ob access-o6 " tabIndex="0">
              <span className="color-theme">*</span>&nbsp;
              {ReportModal.contactInformation}
            </p>
            <TextArea
              customWidth={'100%'}
              handleChange={setName}
              hideWordCount={true}
              labelText={ReportModal.name}
              id={'name'}
              limit={100}
              onBlurHandlerOff={true}
            />
            <div className="h24" />
            <TextArea
              customWidth={'100%'}
              handleChange={handleEmailInput}
              hideWordCount={true}
              labelText={ReportModal.email}
              id={'email'}
              limit={100}
              onBlurHandlerOff={true}
            />
            <div
              className="mrg-l6 color-lightgray access-ob access-o6 flex-row fs16 fwsb mrg-tb12"
              aria-label={`${
                regexCheck
                  ? EmailInput.ariaValidEmailSuccess
                  : EmailInput.ariaValidEmailFail
              }`}
              tabIndex="0"
            >
              {regexCheck ? (
                <img
                  src={checkmark_green}
                  alt={Generic.valid}
                  aria-hidden="true"
                />
              ) : (
                <img
                  src={cancel_lightgray}
                  alt={Generic.invalid}
                  aria-hidden="true"
                  className={checkRan ? 'filter-red' : ''}
                />
              )}
              <p
                className={`mrg-l6  ${
                  regexCheck ? 'valid' : checkRan ? 'invalid' : ''
                }`}
              >
                {EmailInput.validEmailAddress}
              </p>
            </div>

            <div className="h48-ph128" />
          </div>
        )}
      </div>
      <div className="modal-basic__footer">
        <div className="mrg-auto flex-row flex-center">
          {isLoading ? (
            <Spinner maxHeight={'40px'} />
          ) : (
            <button
              type="button"
              className={` access-ob ${
                tapHighlightSubmitBtn ? 'color-theme' : ''
              } ${
                !submitValid
                  ? 'color-lightgray unavailable'
                  : 'highlight-it-theme'
              }`}
              onClick={
                submitValid
                  ? () =>
                      handleMobileTap(
                        [
                          () => setTapHighlightSubmitBtn(true),
                          () => setTapHighlightSubmitBtn(false),
                        ],
                        [() => handleSubmitReport()]
                      )
                  : null
              }
            >
              <div className="pad-lr24 fwsb fs16">
                {ReportModal.submitReport}
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
