import { useEffect, useState } from 'react';

const RadialButtonList = ({
  id,
  options,
  onChange,
  maxWidth,
  loadTrigger,
  loadValue,
}) => {
  //hooks
  //state
  const [selectedOption, setSelectedOption] = useState(null);

  //Component functions
  const handleOptionChange = (key) => {
    setSelectedOption(key);
    onChange(key);
  };

  useEffect(() => {
    if (loadTrigger && loadValue) {
      setSelectedOption(loadValue);
    }
  }, [loadTrigger, loadValue]);

  return (
    <div
      className="radial-button-list__wrapper"
      style={{ maxWidth: `${maxWidth}` }}
    >
      {Object.entries(options).map(([key, value], index) => {
        return (
          <div
            key={`${id}-${index}`}
            className="radial-button__container access-ob access-o6"
            tabIndex="0"
            onKeyDown={(e) =>
              e.key === 'Enter' ? handleOptionChange(key) : null
            }
            onClick={() => handleOptionChange(key)}
          >
            <input
              type="radio"
              name={`radio-${id}`}
              value={key}
              checked={selectedOption === key}
              onChange={() => handleOptionChange(key)}
              tabIndex="-1"
            />
            <label>{value}</label>
          </div>
        );
      })}
    </div>
  );
};

export default RadialButtonList;
