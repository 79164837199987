import { useEffect, useState } from 'react';

//Hooks
import useSettings from '../../../context/useSettings';
import { useAccountReduxHandlers } from './useAccountHandlers';
import {
  useLazyMyInvoicesQuery,
  useUpdateAccountMutation,
  useUpdateRolesMutation,
} from './accountApiSlice';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import useLanguageAccount from '../../../language/features/useLanguageAccount';
import useApiStatus from '../../../context/useApiStatus';
import { useAuthReduxHandlers } from '../auth/useAuthHandlers';

//Components
import EventPage from '../events/EventPage/EventPage';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import GeneralContent from './Content/GeneralContent/GeneralContent';
import AccountNav from './AccountMenus/AccountNav';
import AccountNavMobile from './AccountMenus/AccountNavMobile';
import AccountContent from './Content/AccountPersonalContent/AccountContent';
import AttendeeContent from './Content/AttendeeContent/AttendeeContent';
import SpeakerContent from './Content/SpeakerContent/SpeakerContent';
import OrganizerContent from './Content/OrganizerContent/OrganizerContent';
import TranslationRoomsContent from './Content/TranslationRooms/TranslationRoomsContent';
import HelpContent from './Content/HelpContent/HelpContent';
import BillingContent from './Content/BillingContent/BillingContent';
import EmailPreferencesContent from './Content/EmailPreferences/EmailPreferencesContent';

//Utility
import ScrollTo from '../../../utils/UI/ScrollTo';
import { retryWrapper } from '../../../utils/logic/retryFns';
import { areArraysEqualIgnoreOrder } from '../../../utils/logic/arrayEqualIgnoreOrder';

const Account = () => {
  //Hooks
  const { Account } = useLanguageAccount();
  const { handleError } = useApiStatus();
  const { setNavbar, width } = useSettings();
  const {
    accountId,
    handleUpdateCustomerStripeData,
    accountBillingCustomerId,
  } = useAccountReduxHandlers();
  const { authCurrentRoles: loadRoles } = useAuthReduxHandlers();
  const { settingsAccountSettingsView: view } = useSettingsHandlers();

  //API
  const [
    updateAccount,
    {
      isLoading: updateAccountIsLoading,
      isFetching: updateAccountIsFetching,
      isSuccess: updateAccountIsSuccess,
    },
  ] = useUpdateAccountMutation();
  const [getInvoices] = useLazyMyInvoicesQuery();
  const [updateRoles] = useUpdateRolesMutation();

  //state
  const [roles, setRoles] = useState(loadRoles);

  //UI state
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [loadingSaveBtnUI, setLoadingSaveBtnUI] = useState('');
  const [viewIgnoredEvent, setViewIgnoredEvent] = useState();
  const [rolesChangeValid, setRolesChangeValid] = useState(false);
  const [rolesSignUpIsLoading, setRolesSignUpIsLoading] = useState(false);

  //Initialize functions
  useEffect(() => {
    setNavbar('account');
    /*eslint-disable-next-line*/
  }, []);

  //Stripe functions initialize
  useEffect(() => {
    if (accountId && accountBillingCustomerId) {
      retryWrapper(getInvoices, [{ accountId }], 3, 2000);
    }
  }, [accountId]); //get invoices

  //functions
  useEffect(() => {
    ScrollTo(null, 'auto', true);
  }, [view]); //auto scroll to top

  function handleViewIgnoredEvent(ev) {
    setViewIgnoredEvent(ev);
  }

  //validation
  useEffect(() => {
    if (roles && loadRoles) {
      const rolesUnchanged = areArraysEqualIgnoreOrder(roles, loadRoles);
      if (rolesUnchanged) {
        setRolesChangeValid(false);
      } else {
        setRolesChangeValid(true);
      }
    }
  }, [roles, loadRoles]);

  //API functions
  async function saveAccountChange(newData, updateCategory) {
    if (!updateAccountIsLoading && !updateAccountIsFetching) {
      try {
        setLoadingSaveBtnUI(updateCategory);
        const updateCustomerStripeData = await handleUpdateCustomerStripeData(
          newData,
          updateCategory
        );
        if (!updateCustomerStripeData?.error) {
          let successMessage = true;
          if (updateCategory === 'attendeeSignUp') successMessage = false;

          await updateAccount({
            accountId,
            newData,
            successMessage,
            updateCategory,
          });
          if (updateCategory === 'attendeeSignUp') {
            setRolesSignUpIsLoading(true);

            await updateRoles({
              accountId,
              newData: [...loadRoles, process.env.REACT_APP_ATTENDEE],
            });
            setRoles([...loadRoles, process.env.REACT_APP_ATTENDEE]);
            ScrollTo('attendeeRoleToggleLabel', 'auto', true);

            const timerId = setTimeout(() => {
              setRolesSignUpIsLoading(false);
              setLoadingSaveBtnUI();
            }, 1000);

            return () => clearTimeout(timerId);
          }
          setLoadingSaveBtnUI();
        }
      } catch (error) {
        setRolesSignUpIsLoading(false);
        setLoadingSaveBtnUI();
        handleError({
          error,
          id: Date.now(),
          origin: 'Account.js/saveAccountChange',
        });
      }
    }
  }

  async function handleSaveAccountNewRoles() {
    try {
      setLoadingSaveBtnUI('role');
      await updateRoles({
        accountId,
        newData: [...roles],
      });
      setLoadingSaveBtnUI();
    } catch (error) {
      setLoadingSaveBtnUI();
      handleError({
        error,
        id: Date.now(),
        origin: 'Account.js/saveAccountChange',
      });
    }
  }

  //JSX conditional
  return (
    <>
      {eventPageLoadComplete && !viewIgnoredEvent ? (
        <div className="account" id="account">
          {width > 840 ? <AccountNav /> : <AccountNavMobile />}
          {view === 'general' && (
            <GeneralContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
            />
          )}
          {view === 'account' && (
            <AccountContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
            />
          )}
          {view === 'attendee' && (
            <AttendeeContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
              handleViewIgnoredEvent={handleViewIgnoredEvent}
              updateAccountIsSuccess={updateAccountIsSuccess}
              handleSaveAccountNewRoles={handleSaveAccountNewRoles}
              setRoles={setRoles}
              roles={roles}
              rolesChangeValid={rolesChangeValid}
              rolesSignUpIsLoading={rolesSignUpIsLoading}
            />
          )}
          {view === 'billing' && (
            <BillingContent saveAccountChange={saveAccountChange} />
          )}
          {view === 'emailPreferences' && (
            <EmailPreferencesContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
            />
          )}
          {view === 'speaker' && <SpeakerContent />}
          {view === 'organizer' && <OrganizerContent />}
          {view === 'translationRooms' && <TranslationRoomsContent />}
          {view === 'help' && <HelpContent />}
        </div>
      ) : viewIgnoredEvent ? (
        <div className="events">
          <EventPage
            eventData={viewIgnoredEvent}
            handleReturn={setViewIgnoredEvent}
          />
        </div>
      ) : (
        <LoadingScreenContent
          numberOfLoadChecks={3}
          setLoadComplete={() => setEventPageLoadComplete(true)}
          loadCheck1={accountId ? true : false}
          loadCheck2={width ? true : false}
          loadCheck3={Account ? true : false}
          customContainer={'min-full-height'}
        />
      )}
    </>
  );
};

export default Account;
