import { useEffect, useState } from 'react';
//hooks
import { useAdminSettingsHandlers } from '../settings/useAdminSettingsHandlers';
import useLanguageData from '../../../language/useLanguageData';
import useLanguageAdmin from '../../../language/features/useLanguageAdmin';
import { useAdminManagementHandlers } from '../management/useAdminManagementHandlers';

//components
import Table from '../../../components/Table/Table';
import AdminManagementTicketTableFilters from './AdminManagementTicketTableFilters';
import ButtonMeta from '../../../components/ButtonMeta/ButtonMeta';

//utils
import { getDateOrTimeFromUTZstring } from '../../../utils/dateTime/DateCalc';
import { img_ticket } from '../../../assets/icons';
import Spinner from '../../../components/Spinner/Spinner';

//sorting must come from parent component because admintickets used in management and support

const AdminTickets = ({
  ticketsData,
  handleCardRowSelection,
  managementMode,
  handleTicketsSorting,
  currentViewDetailsSelections,
  connectionStatusTrackerOn,
  connectionStatus,
}) => {
  //hooks
  const {
    adminSettingsTablesTicketHeadings,
    handleSetAdminSettingsTablesTicketHeadings,
    adminSettingsTablesTicketSubHeadings,
    handleSetAdminSettingsTablesTicketSubHeadings,
    adminSettingsManagementTicketsTableHasMore,
    adminSettingsTablesTicketSortingDate,
    handleAdminSettingsResetTablesTicketHeadings,
    handleAdminSettingsResetTablesTicketSubHeadings,
  } = useAdminSettingsHandlers();

  const { ReportEventOpts } = useLanguageData();
  const { AdminStatusEl, AdminTicketType } = useLanguageAdmin();
  const { handleManagementTickets, handleManagementTicketsNextPage } =
    useAdminManagementHandlers();

  //component state
  const [formattedTicketsData, setFormattedTicketsData] = useState();
  const [searchQueryIsLoading, setSearchQueryIsLoading] = useState(false);
  const [searchQueryIsSuccess, setSearchQueryIsSuccess] = useState(false);
  const [searchQueryNextPageIsLoading, setSearchQueryNextPageIsLoading] =
    useState(false);
  //UI

  //variables

  //initialize
  useEffect(() => {
    let newTicketsData = [];

    ticketsData?.map((ticket) => {
      let formattedTicket = {
        assignment1: ticket.ticketData.status,
        assignment2: `${
          ticket.support?.current?._id
            ? ticket.support?.current?.personal?.firstName +
              ' ' +
              ticket.support?.current?.personal?.lastName
            : 'N/A'
        }`,
        issue: ticket.ticketData.ticketKey,
        type: ticket.ticketData.ticketType,
        createdAt: `${getDateOrTimeFromUTZstring(
          ticket.meta.createdAt,
          'date',
          {
            weekday: '',
            month: 'short',
          }
        )} (${getDateOrTimeFromUTZstring(ticket.meta.createdAt, 'time')})`,

        myndfullUser: `${
          ticket.ticketData.submitUser?.nonRegisteredUser?.email
            ? 'False'
            : 'True'
        }`,
        ticketReference: ticket?.meta?.ticketReference,
        id: ticket._id,
      };

      //need to keep last data field in obj as _id, hide it in the table with hiddenColumn so that way it can be used for selection handler
      // console.log('newTicketsData ', newTicketsData);
      return newTicketsData.push(formattedTicket);
    });

    if (newTicketsData?.length > 0) {
      setFormattedTicketsData(newTicketsData);
    } else {
      //add blank data to fill table
      setFormattedTicketsData([
        {
          data1: '',
          data2: '',
          data3: '',
          data4: '',
          data5: '',
          data6: '',
          data7: '',
          data8: '',
          data9: '',
        },
      ]);
    }
  }, [ticketsData]); //prep data for table

  //API handlers
  async function handleFilteredSearchTickets(query) {
    if (managementMode) {
      setSearchQueryIsLoading(true);
      setSearchQueryIsSuccess(false);
      try {
        const res = await handleManagementTickets(query);
        if (res) {
          setSearchQueryIsSuccess(true);
        }
      } catch (error) {
        setSearchQueryIsSuccess(false);
      }

      return setSearchQueryIsLoading(false);
    }
  }

  async function handleFilteredSearchTicketsNextPage() {
    setSearchQueryNextPageIsLoading(true);
    await handleManagementTicketsNextPage();
    return setSearchQueryNextPageIsLoading(false);
  }

  return (
    <>
      {managementMode && (
        <AdminManagementTicketTableFilters
          handleFilteredSearchTickets={handleFilteredSearchTickets}
          isLoading={searchQueryIsLoading}
        />
      )}

      {!searchQueryIsLoading ? (
        <>
          <Table
            id={'ticketsTable'}
            data={formattedTicketsData}
            handleViewDetails={
              ticketsData?.length > 0 ? handleCardRowSelection : null
            }
            currentViewDetailsSelections={currentViewDetailsSelections}
            viewDetailsText={'Select Ticket'}
            tableTitle={'Tickets'}
            tableTitleIcon={img_ticket}
            parentHeadings={[
              '',
              'Assignment',
              'Issue',
              'Type',
              'Created (UTC)',
              'MyndFull User',
              'Ticket Reference',
            ]}
            subHeadingPos={[1]}
            subHeadings={[['Status', 'Current']]}
            hiddenColumns={[7]}
            loadTableHeadings={adminSettingsTablesTicketHeadings}
            handleSetTableHeadings={handleSetAdminSettingsTablesTicketHeadings}
            loadTableSubHeadings={adminSettingsTablesTicketSubHeadings}
            handleSetTableSubHeadings={
              handleSetAdminSettingsTablesTicketSubHeadings
            }
            cellElements={[{ 1: AdminStatusEl }]}
            cellTranslations={[{ 3: ReportEventOpts }, { 4: AdminTicketType }]}
            isLoadingData={searchQueryIsLoading}
            sortingHandlers={[{ 4: handleTicketsSorting }]}
            sortingState={[
              {
                4:
                  adminSettingsTablesTicketSortingDate === 'ascending'
                    ? true
                    : false,
              },
            ]}
            handleResetTableSizes={() => {
              handleAdminSettingsResetTablesTicketHeadings();
              handleAdminSettingsResetTablesTicketSubHeadings();
            }}
            connectionStatusTrackerOn={connectionStatusTrackerOn}
            connectionStatus={connectionStatus}
          />
          {managementMode &&
            !searchQueryIsLoading &&
            searchQueryIsSuccess &&
            adminSettingsManagementTicketsTableHasMore && (
              <div className="flex-center flex-column">
                <div className="h24" />
                <ButtonMeta
                  handleClick={handleFilteredSearchTicketsNextPage}
                  btnText={'Load More'}
                  isLoading={
                    searchQueryIsLoading || searchQueryNextPageIsLoading
                  }
                />
              </div>
            )}
          {managementMode &&
            !searchQueryIsLoading &&
            searchQueryIsSuccess &&
            !searchQueryNextPageIsLoading &&
            !adminSettingsManagementTicketsTableHasMore && (
              <div className="flex-center flex-column">
                <div className="h24" />
                <p className="fs21 fwsb">&mdash; Query Complete &mdash;</p>
              </div>
            )}
        </>
      ) : (
        <Spinner />
      )}

      <div className="h128" />
    </>
  );
};

export default AdminTickets;
