import { useEffect, useRef, useState } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
//Components
import OrganizeEventCardRegistrations from './OrganizeEventCardRegistrations';
//Utility
import {
  img_arrow_order,
  img_organizer_box_announcements,
  img_organizer_box_dash,
  img_organizer_box_edit,
  img_organizer_box_messages,
  img_organizer_box_registrations_inperson_only,
  img_organizer_box_registrations_virtual_only,
  img_organizer_box_view,
  img_organizer_trash,
} from '../../../../assets/icons';

const ActionContainer = ({
  eventData,
  handleActionClick,
  actionPortView,
  handleMobileViewCloseBtn,
  OrganizerEventCardLanguage,
  organizeType,
}) => {
  //Hooks
  const [tapHighlightActionBtn, setTapHighligtActionBtn] = useState();
  const { handleMobileTap, width } = useSettings();

  //UI state
  const [activateLeftFade, setActivateLeftFade] = useState(false);
  const [activateRightFade, setActivateRightFade] = useState(
    width <= 480 ? true : false
  );

  //Component variables
  let content;
  const cardActionsScroll = useRef(null);

  //UI Functions
  function handleAction(id) {
    handleMobileTap(
      [() => setTapHighligtActionBtn(id), () => setTapHighligtActionBtn()],
      [() => handleActionClick(id)],
      true
    );
  }

  useEffect(() => {
    if (width <= 480) {
      const cardActionsWrapper = cardActionsScroll.current;

      const handleScroll = () => {
        // Scrollbar reached the left end
        if (cardActionsWrapper.scrollLeft === 0) {
          setActivateLeftFade(false);
        } else {
          setActivateLeftFade(true);
        }

        // Scrollbar reached the right end
        if (
          cardActionsWrapper.scrollLeft + cardActionsWrapper.clientWidth ===
          cardActionsWrapper.scrollWidth
        ) {
          setActivateRightFade(false);
        } else {
          setActivateRightFade(true);
        }
      };

      cardActionsWrapper.addEventListener('scroll', handleScroll);

      return () => {
        cardActionsWrapper.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  if (eventData) {
    content = (
      <div className="card-actions__wrapper">
        <div className="card-actions__container">
          {activateLeftFade && (
            <div className="fade-left flex-center disable-select">
              <img src={img_arrow_order} alt="" className="rotate-ccw90" />
            </div>
          )}
          {activateRightFade && (
            <div className="fade-right flex-center disable-select">
              <img src={img_arrow_order} alt="" className="rotate-cw90" />
            </div>
          )}

          <ul
            className="actions access-ob access-o-6"
            tabIndex="0"
            ref={cardActionsScroll}
          >
            {eventData.registrationData.registrationsActive &&
              (eventData.hosting.hostingOption === 'inPerson' ||
                eventData.hosting.hostingOption === 'hybrid') && (
                <li>
                  <button
                    className={`action access-ob access-o6 ${
                      tapHighlightActionBtn === 'inPersonRegistrations'
                        ? ' click-scaledown-95'
                        : ''
                    } ${
                      actionPortView === 'inPersonRegistrations'
                        ? 'selected'
                        : ''
                    }`}
                    title={OrganizerEventCardLanguage.inpersonRegistrations}
                    id="inPersonRegistrations"
                    onClick={() => handleAction('inPersonRegistrations')}
                    aria-pressed={actionPortView === 'inPersonRegistrations'}
                    tabIndex="0"
                  >
                    <img
                      src={img_organizer_box_registrations_inperson_only}
                      alt={OrganizerEventCardLanguage.registrations}
                    />

                    {eventData.registrationData.pendingApprovalInPerson
                      ?.length > 0 &&
                      organizeType !== 'archived' && (
                        <div className="alert-circle">
                          <p>
                            {
                              eventData.registrationData.pendingApprovalInPerson
                                ?.length
                            }
                          </p>
                        </div>
                      )}
                  </button>
                </li>
              )}

            {eventData.registrationData.registrationsActive &&
              (eventData.hosting.hostingOption === 'online' ||
                eventData.hosting.hostingOption === 'hybrid') && (
                <li>
                  <button
                    className={`action access-ob access-o6 ${
                      tapHighlightActionBtn === 'virtualRegistrations'
                        ? ' click-scaledown-95'
                        : ''
                    } ${
                      actionPortView === 'virtualRegistrations'
                        ? 'selected'
                        : ''
                    }`}
                    title={OrganizerEventCardLanguage.virtualRegistrations}
                    id="virtualRegistrations"
                    onClick={() => handleAction('virtualRegistrations')}
                    aria-pressed={actionPortView === 'virtualRegistrations'}
                    tabIndex="0"
                  >
                    {eventData.registrationData.pendingApprovalVirtual?.length >
                      0 &&
                      organizeType !== 'archived' && (
                        <div className="alert-circle">
                          <p>
                            {
                              eventData.registrationData.pendingApprovalVirtual
                                ?.length
                            }
                          </p>
                        </div>
                      )}
                    <img
                      src={img_organizer_box_registrations_virtual_only}
                      alt={OrganizerEventCardLanguage.registrations}
                    />
                  </button>
                </li>
              )}
            <li>
              <button
                className={`action access-ob access-o6 ${
                  tapHighlightActionBtn === 'announcements'
                    ? 'click-scaledown-95'
                    : ''
                } ${actionPortView === 'announcements' ? 'selected' : ''}`}
                title={OrganizerEventCardLanguage.announcements}
                id="announcements"
                onClick={() => handleAction('announcements')}
                aria-pressed={actionPortView === 'announcements'}
                tabIndex="0"
              >
                <img
                  src={img_organizer_box_announcements}
                  alt={OrganizerEventCardLanguage.announcements}
                />
              </button>
            </li>
            <li>
              <button
                className={`action access-ob access-o6 ${
                  tapHighlightActionBtn === 'messages'
                    ? 'click-scaledown-95'
                    : ''
                } ${actionPortView === 'messages' ? 'selected' : ''}`}
                title={OrganizerEventCardLanguage.messages}
                id="messages"
                onClick={() => handleAction('messages')}
                aria-pressed={actionPortView === 'messages'}
                tabIndex="0"
              >
                <img
                  src={img_organizer_box_messages}
                  alt={OrganizerEventCardLanguage.messages}
                />
              </button>
            </li>
            {organizeType !== 'archived' && (
              <li>
                <button
                  className={`action access-ob access-o6 ${
                    tapHighlightActionBtn === 'editEvent'
                      ? 'click-scaledown-95'
                      : ''
                  } ${actionPortView === 'editEvent' ? 'selected' : ''}`}
                  title={OrganizerEventCardLanguage.editEvent}
                  id="editEvent"
                  onClick={() => handleAction('editEvent')}
                  aria-pressed={actionPortView === 'editEvent'}
                  tabIndex="0"
                >
                  <img
                    src={img_organizer_box_edit}
                    alt={OrganizerEventCardLanguage.editEvent}
                  />
                </button>
              </li>
            )}
            <li>
              <button
                className={`action access-ob access-o6 ${
                  tapHighlightActionBtn === 'viewEvent'
                    ? 'click-scaledown-95'
                    : ''
                }`}
                title={OrganizerEventCardLanguage.viewEvent}
                id="viewEvent"
                onClick={() => handleAction('viewEvent')}
                aria-pressed={actionPortView === 'viewEvent'}
                tabIndex="0"
              >
                <img
                  src={img_organizer_box_view}
                  alt={OrganizerEventCardLanguage.viewEvent}
                />
              </button>
            </li>
            <li>
              <button
                className={`action access-ob access-o6 ${
                  tapHighlightActionBtn === 'eventDashboard'
                    ? 'click-scaledown-95'
                    : ''
                } ${actionPortView === 'eventDashboard' ? 'selected' : ''}`}
                title={OrganizerEventCardLanguage.eventDashboard}
                id="eventDashboard"
                onClick={() => handleAction('eventDashboard')}
                aria-pressed={actionPortView === 'eventDashboard'}
                tabIndex="0"
              >
                <img
                  src={img_organizer_box_dash}
                  alt={OrganizerEventCardLanguage.eventDashboard}
                />
              </button>
            </li>
            {organizeType === 'archived' && (
              <li>
                <button
                  className={`action access-ob access-o6 ${
                    tapHighlightActionBtn === 'removeArchivedEvent'
                      ? 'click-scaledown-95'
                      : ''
                  }  ${
                    actionPortView === 'removeArchivedEvent' ? 'selected' : ''
                  }`}
                  title={OrganizerEventCardLanguage.removeArchivedEvent}
                  id="removeArchivedEvent"
                  onClick={() => handleAction('removeArchivedEvent')}
                  aria-pressed={actionPortView === 'removeArchivedEvent'}
                  tabIndex="0"
                >
                  <img
                    src={img_organizer_trash}
                    alt={OrganizerEventCardLanguage.trashIcon}
                  />
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className="action-port">
          {(actionPortView === 'registrations' ||
            actionPortView === 'virtualRegistrations' ||
            actionPortView === 'inPersonRegistrations') && (
            <OrganizeEventCardRegistrations
              eventData={eventData}
              actionPortView={actionPortView}
              handleMobileViewCloseBtn={handleMobileViewCloseBtn}
              OrganizerEventCardLanguage={OrganizerEventCardLanguage}
              organizeType={organizeType}
            />
          )}
        </div>
      </div>
    );
  }

  return content;
};
export default ActionContainer;
