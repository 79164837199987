import { useState, useRef, useEffect } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown';
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import { MapContainer } from '../../../../components/MapContainer/MapContainer';
import AutocompletePlaces from '../../../../components/AutocompletePlaces/AutocompletePlaces';
import TextArea from '../../../../components/TextArea/TextArea';
import Selections from '../../../../components/Selections/Selections';
import ButtonSwitch from '../../../../components/ButtonSwitch/ButtonSwitch';

//Utility
import { trash } from '../../../../assets/icons';

const Access = ({
  initialize,
  handleSetAccessibilityNotes,
  handleClearLocation,
  handleSetCoordinates,
  handleSetHostingOption,
  handleSetLocation,
  handleSetSignLanguageInterpreter,
  handleSetVirtualMeetingId,
  handleSetVirtualPasscode,
  handleSetVirtualPlatform,
  handleSetVirtualMeetingLink,
  handleSetWheelchairAccessibility,
  handleSetRealtimeTranslations,
  loadHostingOption,
  loadVirtualPlatform,
  loadVirtualMeetingId,
  loadVirtualPasscode,
  loadVirtualLink,
  loadLocation,
  loadCoordinates,
  loadWheelchairAccessibility,
  loadSignLanguageInterpreter,
  loadAccessibilityNotes,
  loadRealtimeTranslations,
}) => {
  //Hooks
  const { HostingOptions, OnlinePlatformOptions, CountriesOpts } =
    useLanguageData();
  const { CreateEvent } = useLanguageOrganize();
  const { handleMobileTap } = useSettings();

  //Component state
  const [location, setLocation] = useState({
    room: '',
    locationName: '',
    streetAddress: '',
    city: '',
    country: '',
    postalCode: '',
    placeId: '',
  });
  const [mapInitialized, setMapInitialized] = useState(false);
  const [placeCoordinates, setPlaceCoordinates] = useState({
    lat: 0,
    lng: 0,
  }); //used to avoid infinite loop in Map Container

  //UI state
  const [
    tapHighlightClearOnlinePlatformFields,
    setTapHighlightClearOnlinePlatformFields,
  ] = useState(false);
  const [tapHighlightClearInPersonFields, setTapHighlightClearInPersonFields] =
    useState(false);

  //Component variables
  const effectRan = useRef(false);
  let content;
  let onlineOptions;
  let inPersonOptions;

  //Initialize functions
  useEffect(() => {
    if (initialize) {
      if (effectRan.current === false) {
        if (loadLocation) {
          setLocation(loadLocation);
        }
        effectRan.current = true;
      }
    }
  }, [initialize]);

  //Component functions
  const handleLocationChange = (value, id) => {
    setLocation({
      ...location,
      [id]: value,
    });
    handleSetLocation({
      ...location,
      [id]: value,
    });
  };

  function setAutocompleteLocation(location) {
    setLocation({
      room: '',
      locationName: location.locationName,
      streetAddress: location.streetAddress,
      city: location.city,
      postalCode: location.postalCode,
      country: location.country,
      placeId: location.placeId,
    });
    handleSetLocation(location);
  }

  function setAutocompleteCoordinates(coordinates) {
    let coords = [coordinates.lat, coordinates.lng];
    handleSetCoordinates(coords);
    setPlaceCoordinates(coordinates);
  }

  useEffect(() => {
    if (loadHostingOption === 'inPerson' || loadHostingOption === 'hybrid') {
      setMapInitialized(true);
    } else {
      setMapInitialized(false);
    }
  }, [loadHostingOption]);

  function clearAddressFields() {
    setLocation({
      room: '',
      locationName: '',
      streetAddress: '',
      city: '',
      country: '',
      postalCode: '',
      placeId: '',
    });

    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
    handleClearLocation(); //redux, also removes coords
  }

  function handleCountrySelection(selection) {
    setLocation({
      ...location,
      country: selection,
    });
    handleSetLocation({
      ...location,
      country: selection,
    });
  }

  function clearOnlinePlatformFields() {
    const virtualMeetingId = document.getElementById('virtualMeetingId');
    virtualMeetingId.value = '';

    const virtualPasscode = document.getElementById('virtualPasscode');
    virtualPasscode.value = '';

    const virtualMeetingLink = document.getElementById('virtualLink');
    virtualMeetingLink.value = '';

    handleSetVirtualMeetingId('');
    handleSetVirtualPasscode('');
    handleSetVirtualMeetingLink('');

    setLocation({
      ...location,
      country: '',
    });
    handleSetLocation({
      ...location,
      country: '',
    });
  }

  //JSX conditional
  if (loadHostingOption === 'online' || loadHostingOption === 'hybrid') {
    onlineOptions = (
      <>
        <div className="h24" />
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="onlinePlatformLabel"
        >
          {CreateEvent.access.onlinePlatform}
        </label>
        <div className="h12" />
        <Selections
          id={'virtualPlatform'}
          options={OnlinePlatformOptions}
          handleSelection={handleSetVirtualPlatform}
          loadTrigger={initialize}
          loadValue={loadVirtualPlatform}
          customWrapper={'mrg-t12'}
        />
        <div className="h24" />
        <TextArea
          handleChange={handleSetVirtualMeetingId}
          limit={50}
          id={'virtualMeetingId'}
          labelText={CreateEvent.access.virtualMeetingId}
          disableEnter={true}
          loadTrigger={initialize}
          loadValue={loadVirtualMeetingId}
          asterisk={true}
          reload={true}
        />
        <div className="h24" />
        <TextArea
          handleChange={handleSetVirtualPasscode}
          limit={50}
          id={'virtualPasscode'}
          labelText={CreateEvent.access.virtualMeetingPasscode}
          disableEnter={true}
          loadTrigger={initialize}
          loadValue={loadVirtualPasscode}
          asterisk={true}
          reload={true}
        />
        <div className="h24" />
        <TextArea
          handleChange={handleSetVirtualMeetingLink}
          limit={200}
          id={'virtualLink'}
          labelText={CreateEvent.access.virtualMeetingLink}
          disableEnter={true}
          loadTrigger={initialize}
          loadValue={loadVirtualLink}
          reload={true}
        />
        {loadHostingOption === 'online' && (
          <>
            <div className="h24" />
            <SelectDropdown
              id={'countrySelect'}
              list={CountriesOpts}
              handleSelection={handleCountrySelection}
              defaultKey={
                initialize && loadLocation.country ? loadLocation.country : null
              }
              internalLabel={CreateEvent.access.country}
              ariaTitle={CreateEvent.access.country}
              asterisk={true}
              autoUpdate={true}
            />
          </>
        )}
        <div className="h24" />
        <div className="full-width flex-center">
          <button
            type="button"
            className={`remove-button access-ob highlight-t-gr ${
              tapHighlightClearOnlinePlatformFields
                ? 'highlight-it-theme--tap'
                : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightClearOnlinePlatformFields(true),
                  () => setTapHighlightClearOnlinePlatformFields(false),
                ],
                [() => clearOnlinePlatformFields()]
              )
            }
            aria-label={CreateEvent.access.clearOnlinePlatformFields}
            title={CreateEvent.access.clearOnlinePlatformFields}
          >
            <img
              src={trash}
              alt={CreateEvent.access.clearOnlinePlatformFields}
              className={`trashbin svg ${
                tapHighlightClearOnlinePlatformFields
                  ? 'filter-red'
                  : 'filter-lightgray'
              }`}
            />
            <p
              className={`fwn fs16 ${
                tapHighlightClearOnlinePlatformFields ? 'color-red' : ''
              }`}
            ></p>
          </button>
        </div>
      </>
    );
  }

  if (loadHostingOption === 'inPerson' || loadHostingOption === 'hybrid') {
    inPersonOptions = (
      <>
        <div className="h24" />
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="locationLabel"
        >
          {CreateEvent.access.location}
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {CreateEvent.access.locationDescription}
        </p>
        <div className="h24" />
        <div className="gmap-wrapper flex-center-column">
          <MapContainer
            loadTrigger={mapInitialized}
            loadValue={loadCoordinates}
            placeCoordinates={placeCoordinates}
            handler={handleSetCoordinates}
          />
        </div>
        <div className="h24" />
        <div>
          <AutocompletePlaces
            location={location}
            setAutocompleteLocation={setAutocompleteLocation}
            setAutocompleteCoordinates={setAutocompleteCoordinates}
            clearAddressFields={clearAddressFields}
          />
          <div className="h24" />
        </div>
        <TextArea
          handleChange={handleLocationChange}
          limit={60}
          id={'room'}
          labelText={CreateEvent.access.room}
          loadValue={location.room}
          loadTrigger={initialize}
          disableEnter={true}
          hideWordCount={true}
          reload={true}
        />
        <div className="h24" />
        <TextArea
          handleChange={handleLocationChange}
          limit={120}
          labelText={CreateEvent.access.locationName}
          id={'locationName'}
          loadValue={location.locationName}
          loadTrigger={initialize}
          disableEnter={true}
          hideWordCount={true}
          reload={true}
        />
        <div className="h24" />
        <TextArea
          handleChange={handleLocationChange}
          limit={100}
          labelText={CreateEvent.access.streetAddress}
          id={'streetAddress'}
          loadValue={location.streetAddress}
          loadTrigger={initialize}
          disableEnter={true}
          hideWordCount={true}
          asterisk={true}
          reload={true}
        />
        <div className="h24" />
        <TextArea
          handleChange={handleLocationChange}
          limit={100}
          labelText={CreateEvent.access.cityTown}
          disableEnter={true}
          id={'city'}
          loadValue={location.city}
          loadTrigger={initialize}
          hideWordCount={true}
          asterisk={true}
          reload={true}
        />
        <div className="h24" />

        <SelectDropdown
          id={'countrySelect'}
          list={CountriesOpts}
          handleSelection={handleCountrySelection}
          defaultKey={
            initialize && loadLocation.country ? loadLocation.country : null
          }
          internalLabel={CreateEvent.access.country}
          ariaTitle={CreateEvent.access.country}
          asterisk={true}
          autoUpdate={true}
        />

        <div className="h24" />

        <div className="full-width flex-center">
          <button
            type="button"
            className={`remove-button access-ob highlight-t-gr ${
              tapHighlightClearInPersonFields ? 'highlight-it-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightClearInPersonFields(true),
                  () => setTapHighlightClearInPersonFields(false),
                ],
                [() => clearAddressFields()]
              )
            }
            aria-label={CreateEvent.access.clearInPersonFields}
            title={CreateEvent.access.clearInPersonFields}
          >
            <img
              src={trash}
              alt={CreateEvent.access.clearInPersonFields}
              className={`trashbin svg ${
                tapHighlightClearInPersonFields
                  ? 'filter-red'
                  : 'filter-lightgray'
              }`}
            />
            <p
              className={`fwn fs16 ${
                tapHighlightClearInPersonFields ? 'color-red' : ''
              }`}
            ></p>
          </button>
        </div>
      </>
    );
  }

  content = (
    <fieldset className="fieldset" id="fieldset-access">
      <legend id="aria-fieldset-instructions-access">
        {CreateEvent.access.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="accessLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-access"
      >
        {CreateEvent.access.access}
      </label>
      <FocusSkip
        skipToLabel={CreateEvent.skipTo.skipToRegistration}
        skipToId={'registrationLabel'}
        topPos={'-24px'}
      />
      <div className="h0-ph24" />
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="loadHostingOptionLabel"
      >
        {CreateEvent.access.hostingOptions}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.access.hostOptionsDescription}
      </p>
      <Selections
        id={'loadHostingOption'}
        options={HostingOptions}
        handleSelection={handleSetHostingOption}
        singleColumn={true}
        loadTrigger={initialize}
        loadValue={loadHostingOption}
        customWrapper={'mrg-t12'}
      />
      {onlineOptions}
      {inPersonOptions}
      <div className="h48" />
      <label className="content-heading-styled text-gray flex-row" tabIndex="0">
        {CreateEvent.access.accessibility}
      </label>
      <div className="h24" />
      <ButtonSwitch
        handleSwitch={handleSetWheelchairAccessibility}
        id={'wheelchairAccessibility'}
        title={CreateEvent.access.wheelchairAccessibility}
        loadTrigger={initialize}
        loadValue={loadWheelchairAccessibility}
        horizontal={true}
      />
      <div className="h24" />
      <ButtonSwitch
        handleSwitch={handleSetSignLanguageInterpreter}
        id={'signLanguageInterpreter'}
        title={CreateEvent.access.signLanguageInterpreter}
        loadTrigger={initialize}
        loadValue={loadSignLanguageInterpreter}
        horizontal={true}
      />
      <div className="h24" />
      <ButtonSwitch
        handleSwitch={handleSetRealtimeTranslations}
        id={'realtimeTranslations'}
        title={CreateEvent.access.realtimeTranslations}
        loadTrigger={initialize}
        loadValue={loadRealtimeTranslations}
        horizontal={true}
      />
      <div className="h24" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.access.accessibilityNotesDescription}
      </p>
      <div className="h12" />
      <TextArea
        handleChange={handleSetAccessibilityNotes}
        limit={1000}
        labelText={CreateEvent.access.notes}
        id={'accessibility-notes'}
        loadTrigger={initialize}
        loadValue={loadAccessibilityNotes}
      />
    </fieldset>
  );
  return content;
};

export default Access;
