import { useEffect, useState } from 'react';
//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useApiStatus from '../../../../../context/useApiStatus';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import {
  useLazyMyInvoicesQuery,
  useRemovePaymentMethodMutation,
  useSetPrimaryPaymentMethodMutation,
} from '../../accountApiSlice';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utils
import paymentLogosObj from '../../../../../utils/currency/paymentLogosObj';
import { flag } from '../../../../../assets/icons/index';
import { retryWrapper } from '../../../../../utils/logic/retryFns';
import ScrollTo from '../../../../../utils/UI/ScrollTo';

const BillingPaymentCard = ({
  cardData,
  customWrapper,
  reset,
  saveAccountChange,
  setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices,
}) => {
  //hooks
  const {
    accountId,
    accountBilling,
    accountBillingPaymentMethodIds,
    accountFailedInvoices,
  } = useAccountReduxHandlers();
  const { handleError, handleWarning } = useApiStatus();
  const { BillingPaymentMethods } = useLanguageAccount();

  //api
  const [getInvoices] = useLazyMyInvoicesQuery();
  const [removePaymentMethod] = useRemovePaymentMethodMutation();
  const [setPrimaryPaymentMethod] = useSetPrimaryPaymentMethodMutation();

  //state
  const [removeCardIsLoading, setRemoveCardIsLoading] = useState(false);
  const [changePrimaryCardIsLoading, setChangePrimaryCardIsLoading] =
    useState(false);

  const [expMonth, setExpMonth] = useState();
  const [expYear, setExpYear] = useState();
  const [name, setName] = useState('');
  const [billingAddress, setBillingAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
  });

  useEffect(() => {
    const exp_month =
      cardData?.card?.exp_month < 10
        ? `0${cardData?.card?.exp_month}`
        : cardData?.card?.exp_month;

    if (expMonth !== exp_month) setExpMonth(exp_month);

    const exp_year = cardData?.card?.exp_year.toString().slice(-2);

    if (expYear !== exp_year) setExpYear(exp_year);

    setName(cardData?.billing_details?.name);
    setBillingAddress(cardData?.billing_details?.address);
  }, [cardData]);

  async function handleRemovePaymentMethod() {
    if (!removeCardIsLoading) {
      try {
        setRemoveCardIsLoading(true);

        const response = await removePaymentMethod({
          accountId,
          paymentMethodId: cardData?.id,
        });

        if (!response?.error) {
          const newPaymentMethodIds = accountBillingPaymentMethodIds?.filter(
            (pm) => pm !== cardData?.paymentMethodId
          );

          await saveAccountChange({
            billing: {
              ...accountBilling,
              paymentMethodIds: newPaymentMethodIds,
            },
          });
          reset();
        }

        setRemoveCardIsLoading(false);
      } catch (error) {
        setRemoveCardIsLoading(false);
        return handleWarning({
          message: error.response.data.message,
          id: Date.now(),
          origin: 'BillingPaymentCard.js/handleRemovePaymentMethod',
          error,
        });
      }
    }
  }

  async function handleSetPrimary() {
    if (cardData?.isDefault) {
      return; //already default
    }

    if (!changePrimaryCardIsLoading) {
      try {
        setChangePrimaryCardIsLoading(true);

        if (accountFailedInvoices?.length > 0) {
          setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices(
            true
          );
        }

        const response = await setPrimaryPaymentMethod({
          accountId,
          newPrimaryPaymentMethodId: cardData?.id,
        });

        if (!response?.error) {
          reset();
        }

        await retryWrapper(getInvoices, [{ accountId }]);

        ScrollTo('paymentMethodLabel', 'auto', true);
        setChangePrimaryCardIsLoading(false);
      } catch (error) {
        setChangePrimaryCardIsLoading(false);
        return handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'BillingPaymentCard.js/handleSetPrimary',
          error,
        });
      }
    }
  }

  return (
    <div
      className={`account-card__wrapper mrg-t24 ${
        customWrapper ? customWrapper : ''
      }`}
    >
      <div id="payment-form" className="account-card__container">
        <label
          className="content-label flex-row min-full-width mrg-b24"
          tabIndex="0"
        >
          <div className="flex-row min-full-width space-between">
            <div className="flex-column">
              <p className="flex-row">
                {cardData?.isDefault
                  ? BillingPaymentMethods.primaryCard
                  : BillingPaymentMethods.secondaryCard}
              </p>
              {cardData?.isDefault && (
                <p className="fs14 fwn">
                  {BillingPaymentMethods.defaultPaymentMethod}
                </p>
              )}
            </div>
            <div className="flex-row align-center mrg-auto-left">
              {cardData?.metadata?.status === 'failed' && (
                <div className="flex-row mrg-auto-bottom">
                  <p className="fs16 color-red mrg-r12">
                    {BillingPaymentMethods.paymentMethodFailed}
                  </p>
                  <img
                    src={flag}
                    alt="flag"
                    style={{ height: '21px' }}
                    className="filter-red"
                    title={BillingPaymentMethods.primaryCard}
                  />
                </div>
              )}
            </div>
          </div>
        </label>
        <div className="flex-row full-width">
          <img
            src={paymentLogosObj[cardData?.card?.display_brand]}
            alt={cardData?.card?.display_brand}
            className={`filter-none mrg-auto-tb payment-method-logo ${
              cardData?.card?.display_brand === 'visa' ? 'visa' : ''
            }`}
          />
          <div
            className="flex-column mrg-l24 text-left access-ob access-o6"
            tabIndex="0"
          >
            <p className="fwsb fs18 color-gray">
              {cardData?.card?.display_brand.toUpperCase()}: ****{' '}
              {cardData?.card?.last4}
            </p>
            <p className="fwsb fs18 color-gray  mrg-t12">
              {BillingPaymentMethods.expires}: {expMonth} / {expYear}
            </p>
          </div>
        </div>
        <div
          className="flex-column mrg-t24 color-gray flex-start full-width access-ob access-o6"
          tabIndex="0"
        >
          <p className="fs18 fwb mrg-b6">
            {BillingPaymentMethods.billingAddress}
          </p>

          <p className="fs18 fwsb mrg-b6">{name}</p>
          <div className="flex-row">
            {billingAddress?.line1 && (
              <p className="fs18 fwsb">
                {billingAddress?.line1}
                {billingAddress?.line2 && ','}&nbsp;
              </p>
            )}
            {billingAddress?.line2 && (
              <p className="fs18 fwsb">{billingAddress?.line2}</p>
            )}
          </div>

          <div className="flex-row">
            {billingAddress?.city && (
              <p className="fs18 fwsb">
                {billingAddress?.city}
                {billingAddress?.state && ','}&nbsp;
              </p>
            )}
            {billingAddress?.state && (
              <p className="fs18 fwsb">{billingAddress?.state}</p>
            )}
          </div>
          <div className="flex-row">
            {billingAddress?.postal_code && (
              <p className="fs18 fwsb">
                {billingAddress?.postal_code}
                {billingAddress?.country && ','}
                &nbsp;
              </p>
            )}
            {billingAddress?.country && (
              <p className="fs18 fwsb">{billingAddress?.country}</p>
            )}
          </div>
        </div>

        <div className="divider-dotted mrg-tb24" />
        <div className="flex-column full-width full-height">
          {!cardData.isDefault && (
            <ItemButton
              handler={handleSetPrimary}
              text={BillingPaymentMethods.setAsPrimaryCard}
              fullWidth={true}
              customWrapper={'mrg-b24'}
              isLoading={changePrimaryCardIsLoading}
            />
          )}
          <ItemButton
            handler={handleRemovePaymentMethod}
            text={BillingPaymentMethods.remove}
            fullWidth={true}
            customWrapper={'mrg-auto-top'}
            isLoading={removeCardIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingPaymentCard;
