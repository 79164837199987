//hooks
import { useRegistrationHandlers } from '../../useRegistrationHandlers';
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';

//components
import ButtonSwitch from '../../../../../components/ButtonSwitch/ButtonSwitch';
import NavigationArrows from '../../../../../components/NavigationArrows/NavigationArrows';

//utilieis

const EmailPreferences = ({ handleCompleteRegistration }) => {
  const {
    handleRegistrationPageCompletionStatus,
    handleRegistrationPageDecrease,
    handleRegistrationSetEmailPreferencesMyndFullNewsletter,
    handleRegistrationSetEmailPreferencesPromotions,
    registrationEmailPreferencesMyndFullNewsletter,
    registrationEmailPreferencesPromotions,
    registrationIsLoadingCompleteRegistration,
    registrationPage,
  } = useRegistrationHandlers();
  const { EmailPreferences } = useLanguageRegistration();

  return (
    <>
      <label
        // aria-label={PersonalAccountInfo.ariaAppSettings}
        className="content-label"
        id="appSettingsLabel"
        tabIndex="0"
      >
        {EmailPreferences.emailPreferences}
      </label>
      <p className="fs18 fwn mrg-tb12">
        {EmailPreferences.emailPreferencesDescription}
      </p>

      <div className="flex-column mrg-t24 full-width space-between border-reg br--standard pad-24">
        <p className="flex-row fs18 fwsb mrg-b12 nowrap full-width">
          {EmailPreferences.myndfullNewsletter}
        </p>

        <div className="flex-row align-center space-between full-width">
          <p className="fs18 fwn">
            {EmailPreferences.myndfullNewsletterDescription}
          </p>
          <ButtonSwitch
            handleSwitch={
              handleRegistrationSetEmailPreferencesMyndFullNewsletter
            }
            id={'myndfullNewsletter'}
            loadTrigger={true}
            loadValue={registrationEmailPreferencesMyndFullNewsletter}
            horizontal={true}
            customWrapper={'mrg-l24'}
          />
        </div>
      </div>

      <div className="flex-column mrg-t24 full-width space-between border-reg br--standard pad-24">
        <p className="flex-row fs18 fwsb mrg-b12 nowrap full-width">
          {EmailPreferences.promotions}
        </p>

        <div className="flex-row align-center space-between full-width">
          <p className="fs18 fwn">{EmailPreferences.promotionsDescription}</p>
          <ButtonSwitch
            handleSwitch={handleRegistrationSetEmailPreferencesPromotions}
            id={'promotions'}
            loadTrigger={true}
            loadValue={registrationEmailPreferencesPromotions}
            horizontal={true}
            customWrapper={'mrg-l24'}
          />
        </div>
      </div>
      <div className="h36" />
      <NavigationArrows
        continueBtnCheck={true}
        handlePageDecrease={() => {
          handleRegistrationPageCompletionStatus(registrationPage, true);
          handleRegistrationPageDecrease();
        }}
        finalContinue={true}
        HOFunction={handleCompleteRegistration}
        fullWidth={true}
        isLoading={registrationIsLoadingCompleteRegistration}
      />
    </>
  );
};

export default EmailPreferences;
