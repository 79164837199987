import useSettings from '../context/useSettings';

const useLanguageData = () => {
  const { contentLanguage } = useSettings();

  const CountryCodeOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        canada: {
          country: 'Canada',
          code: '+1',
        },
        unitedStates: {
          country: 'United States',
          code: '+1',
        },
        mexico: {
          country: 'Mexico',
          code: '+52',
        },
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        canada: {
          country: 'Canada',
          code: '+1',
        },
        unitedStates: {
          country: 'États-Unis',
          code: '+1',
        },
        mexico: {
          country: 'Mexique',
          code: '+52',
        },
      };
    }
    return translation;
  };

  const GetFlag = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        flag: 'flag',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        flag: 'drapeau',
      };
    }
    return translation;
  };

  const ContentLanguage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = 'English';
    }
    if (contentLanguage === 'fr-CA') {
      translation = 'Français';
    }
    return translation;
  }; //remove?

  const LanguageOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        'en-CA': 'English (English)',
        'fr-CA': 'French (Français)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        'en-CA': 'Anglais (English)',
        'fr-CA': 'Français (Français)',
      };
    }
    return translation;
  };

  const CountriesOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        canada: 'Canada',
        unitedStates: 'United States',
        mexico: 'Mexico',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        canada: 'Canada',
        unitedStates: 'États-Unis',
        mexico: 'Mexique',
      };
    }
    return translation;
  };

  const SearchLanguageOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English (English)',
        'fr-CA': 'French (Français)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        'en-CA': 'Anglais (English)',
        'fr-CA': 'Français (Français)',
      };
    }
    return translation;
  };

  const BigTagsOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        anth: 'Anthropology',
        asci: 'Applied Science',
        astu: 'Area Studies',
        astr: 'Astronomy',
        bus: 'Business Studies',
        chem: 'Chemistry',
        clas: 'Classical Studies',
        csci: 'Computer Science',
        cula: 'Culinary Arts',
        easci: 'Earth Science',
        econ: 'Economics',
        edu: 'Education',
        eng: 'Engineering',
        gend: 'Gender Studies',
        hsci: 'Health Science',
        hist: 'History',
        hgeo: 'Human Geography',
        lang: 'Languages',
        law: 'Law',
        lisci: 'Life Science',
        ling: 'Linguistics',
        lit: 'Literary Arts',
        math: 'Mathematics',
        med: 'Media Studies',
        perf: 'Performing Arts',
        phil: 'Philosophy',
        phys: 'Physics',
        poli: 'Political Science',
        psy: 'Psychology',
        rel: 'Religion',
        soci: 'Sociology',
        stat: 'Statistics',
        visa: 'Visual Arts',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        anth: 'Anthropologie',
        cula: 'Arts Culinaires',
        perf: 'Arts de la Scène',
        lit: 'Arts Littéraires',
        visa: 'Arts Visuels',
        astr: 'Astronomie',
        chem: 'Chimie',
        law: 'Droit',
        econ: 'Économie',
        edu: 'Éducation',
        gend: 'Études de Genre',
        med: 'Études des Médias',
        clas: 'Études Classiques',
        bus: 'Études Commerciales',
        astu: 'Études Régionales',
        hgeo: 'Géographie Humaine',
        hist: 'Histoire',
        eng: 'Ingénierie',
        lang: 'Langues',
        ling: 'Linguistique',
        csci: `L'Informatique`,
        math: 'Mathématiques',
        phil: 'Philosophie',
        phys: 'Physique',
        psy: 'Psychologie',
        rel: 'Religion',
        asci: 'Sciences Appliquées',
        easci: 'Sciences de la Terre',
        hsci: 'Sciences de la Santé',
        lisci: 'Sciences de la Vie',
        poli: 'Science Politique',
        soci: 'Sociologie',
        stat: 'Statistiques',
      };
    }
    return translation;
  };

  const BigTagsOptsWithSubText = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        anth: { mainText: 'Anthropology', subText: '' },
        asci: { mainText: 'Applied Science', subText: '' },
        astu: { mainText: 'Area Studies', subText: 'ex. Canadian Studies' },
        astr: { mainText: 'Astronomy', subText: '' },
        bus: { mainText: 'Business Studies', subText: '' },
        chem: { mainText: 'Chemistry', subText: '' },
        clas: { mainText: 'Classical Studies', subText: '' },
        csci: { mainText: 'Computer Science', subText: '' },
        cula: { mainText: 'Culinary Arts', subText: '' },
        easci: { mainText: 'Earth Science', subText: '' },
        econ: { mainText: 'Economics', subText: '' },
        edu: { mainText: 'Education', subText: '' },
        eng: { mainText: 'Engineering', subText: '' },
        gend: { mainText: 'Gender Studies', subText: '' },
        hsci: { mainText: 'Health Science', subText: '' },
        hist: { mainText: 'History', subText: '' },
        hgeo: { mainText: 'Human Geography', subText: '' },
        lang: { mainText: 'Languages', subText: '' },
        law: { mainText: 'Law', subText: '' },
        lisci: { mainText: 'Life Science', subText: '' },
        ling: { mainText: 'Linguistics', subText: '' },
        lit: { mainText: 'Literary Arts', subText: '' },
        math: { mainText: 'Mathematics', subText: '' },
        med: { mainText: 'Media Studies', subText: '' },
        perf: { mainText: 'Performing Arts', subText: '' },
        phil: { mainText: 'Philosophy', subText: '' },
        phys: { mainText: 'Physics', subText: '' },
        poli: { mainText: 'Political Science', subText: '' },
        psy: { mainText: 'Psychology', subText: '' },
        rel: { mainText: 'Religion', subText: '' },
        soci: { mainText: 'Sociology', subText: '' },
        stat: { mainText: 'Statistics', subText: '' },
        visa: { mainText: 'Visual Arts', subText: '' },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        anth: { mainText: 'Anthropologie', subText: '' },
        cula: { mainText: 'Arts Culinaires', subText: '' },
        perf: { mainText: 'Arts de la Scène', subText: '' },
        lit: { mainText: 'Arts Littéraires', subText: '' },
        visa: { mainText: 'Arts Visuels', subText: '' },
        astr: { mainText: 'Astronomie', subText: '' },
        chem: { mainText: 'Chimie', subText: '' },
        law: { mainText: 'Droit', subText: '' },
        econ: { mainText: 'Économie', subText: '' },
        edu: { mainText: 'Éducation', subText: '' },
        gend: { mainText: 'Études de Genre', subText: '' },
        med: { mainText: 'Études des Médias', subText: '' },
        clas: { mainText: 'Études Classiques', subText: '' },
        bus: { mainText: 'Études Commerciales', subText: '' },
        astu: {
          mainText: 'Études Régionales',
          subText: 'ex. Études canadiennes',
        },
        hgeo: { mainText: 'Géographie Humaine', subText: '' },
        hist: { mainText: 'Histoire', subText: '' },
        eng: { mainText: 'Ingénierie', subText: '' },
        lang: { mainText: 'Langues', subText: '' },
        ling: { mainText: 'Linguistique', subText: '' },
        csci: { mainText: `L'Informatique`, subText: '' },
        math: { mainText: 'Mathématiques', subText: '' },
        phil: { mainText: 'Philosophie', subText: '' },
        phys: { mainText: 'Physique', subText: '' },
        psy: { mainText: 'Psychologie', subText: '' },
        rel: { mainText: 'Religion', subText: '' },
        asci: { mainText: 'Sciences Appliquées', subText: '' },
        easci: { mainText: 'Sciences de la Terre', subText: '' },
        hsci: { mainText: 'Sciences de la Santé', subText: '' },
        lisci: { mainText: 'Sciences de la Vie', subText: '' },
        poli: { mainText: 'Science Politique', subText: '' },
        soci: { mainText: 'Sociologie', subText: '' },
        stat: { mainText: 'Statistiques', subText: '' },
      };
    }
    return translation;
  };
  const AudienceTypes = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        general: 'General',
        academic: 'Academic',
        scientific: 'Scientific',
        youth: 'Youth',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        general: 'Général',
        academic: 'Académique',
        scientific: 'Scientifique',
        youth: 'Jeunesse',
      };
    }

    return translation;
  };

  const AudienceTypesEventPage = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        general: 'General Audience',
        academic: 'Academic Audience',
        scientific: 'Scientific Audience',
        youth: 'Youth Audience',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        general: 'Audience générale',
        academic: 'Audience académique',
        scientific: 'Audience Scientifique',
        youth: 'Audience Jeunesse',
      };
    }

    return translation;
  };

  const EventTypes = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        lecture: 'Lecture',
        workshop: 'Workshop',
        roundtable: 'Roundtable',
        panel: 'Panel',
        forum: 'Forum',
        symposium: 'Symposium',
        launch: 'Launch',
        awards: 'Awards',
        social: 'Social',
        other: 'Other',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        lecture: 'Cours Magistral',
        workshop: 'Atelier',
        roundtable: 'Table Ronde',
        panel: 'Panel',
        forum: 'Forum',
        symposium: 'Symposium',
        launch: 'Lancement',
        awards: 'Prix',
        social: 'Social',
        other: 'Authre',
      };
    }

    return translation;
  };

  const AudienceTypesInfo = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        general:
          'General audience has varied levels of knowledge and expertise. Content should be accessible and engaging.',
        academic:
          'Academic audience has in-depth knowledge of a scholarly subject. Content should be well-researched and can be expected to include specialized language.',

        scientific:
          'Scientific audience includes researchers, scholars, and professionals who have expertise in a particular scientific field. Appropriate for highly specialized content which includes technical language.',

        youth:
          'Youth audience can range from children to young adults. Event should be engaging, educational and use accessible language that is tailored to the age group.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        general: `Le grand public a des niveaux variés de connaissances et d'expertise. Le contenu doit être accessible et engageant.`,
        academic: `Le public académique a une connaissance approfondie d'un sujet scientifique. Le contenu doit être bien documenté et on peut s'attendre à ce qu'il inclue un langage spécialisé.`,
        scientific: `Le public scientifique comprend les chercheurs, les universitaires et les professionnels qui ont une expertise dans un domaine scientifique particulier. Convient aux contenus hautement spécialisés qui incluent un langage technique.`,
        youth: `Le public des jeunes peut aller des enfants aux jeunes adultes. L'événement doit être engageant, éducatif et utiliser un langage accessible adapté au groupe d'âge.`,
      };
    }

    return translation;
  };

  const EventTypesInfo = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        Lecture:
          'A presentation given by one or more speakers on a defined topic to educate and inform the audience.',

        Workshop:
          'An interactive event where participants actively engage in learning a new skill or concept. An emphasis is placed on practical applications and experiential learning.',

        Roundtable:
          'A small group discussion where participants share perspectives on a defined topic. The goal is to encourage dialogue and collaboration among participants.',

        Panel:
          'A moderated discussion by subject matter experts on a defined topic. Typically, each panelist will have a unique perspective on the topic.',

        Forum:
          'An open debate or discussion among a group of people with differing opinions or perspectives. The goal is to explore different viewpoints on a particular issue, stimulate discussion and directly engage the audience.',

        Symposium:
          'A formal academic gathering where experts present research on a specific topic. Symposiums may involve multiple events with the objective of sharing ideas and fostering scholarly dialogue.',

        Launch:
          'An announcement or celebration of a new book, study, project, product, or service. The objective is to generate interest or awareness among the audience.',

        Awards:
          'A formal ceremony to recognize and celebrate the achievements or contributions of individuals or organizations in a particular field.',

        Social:
          'A gathering where people come together to socialize and connect. The goal is to create a relaxed and engaging environment for attendees who share academic or educational interests.',

        Other:
          'This event type does not fall into any of the pre-set categories. Ensure there is an extensive event description.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        lecture: `Une présentation donnée par un ou plusieurs intervenants sur un sujet défini pour éduquer et informer le public.`,
        workshop: `Un événement interactif où les participants s'engagent activement dans l'apprentissage d'une nouvelle compétence ou d'un nouveau concept. L'accent est mis sur les applications pratiques et l'apprentissage expérientiel.`,
        roundtable: `Une petite discussion de groupe où les participants partagent leurs perspectives sur un sujet défini. Le but est d'encourager le dialogue et la collaboration entre les participants.`,
        panel:
          'Une discussion modérée par des experts en la matière sur un sujet défini. Typiquement, chaque panéliste aura une perspective unique sur le sujet.',
        forum: `Un débat ou une discussion ouverte entre un groupe de personnes ayant des opinions ou des perspectives différentes. L'objectif est d'explorer différentes perspectives sur une question particulière, de stimuler la discussion et d'engager directement le public.`,
        symposium:
          'Une rencontre académique formelle où des experts présentent des recherches sur un sujet spécifique. Les symposiums peuvent impliquer plusieurs événements avec pour objectif de partager des idées et de favoriser le dialogue universitaire.',
        launch: `Une annonce ou une célébration d'un nouveau livre, d'une étude, d'un projet, d'un produit ou d'un service. L'objectif est de susciter l'intérêt ou la sensibilisation du public.`,
        awards: `Une cérémonie formelle pour reconnaître et célébrer les réalisations ou les contributions d'individus ou d'organisations dans un domaine particulier.`,
        social:
          'Une rencontre où les gens se réunissent pour socialiser et se connecter. Le but est de créer un environnement détendu et engageant pour les participants qui partagent des intérêts académiques ou éducatifs.',
        other: `Ce type d'événement n'entre dans aucune des catégories prédéfinies. Veillez à ce qu'il y ait une description détaillée de l'événement.`,
      };
    }

    return translation;
  };

  const HostingOptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        inPerson: 'In Person',
        online: 'Online',
        hybrid: 'Hybrid',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        inPerson: 'En personne',
        online: 'En ligne',
        hybrid: 'Hybride',
      };
    }

    return translation;
  };

  const OnlinePlatformOptions = () => {
    return {
      zoom: 'Zoom',
      teams: 'Teams',
    };
  };

  const InPersonPrivateDetailsOpts = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        room: {
          room: 'Room',
        },
        locationName: {
          locationName: 'Location Name',
        },
        streetAddress: {
          streetAddress: 'Street Address',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        room: {
          room: 'Salle',
        },
        locationName: {
          locationName: `Nom de l'emplacement`,
        },
        streetAddress: {
          streetAddress: 'Adresse',
        },
      };
    }

    return translation;
  };

  const VirtualPrivateDetailsOpts = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        virtualMeetingId: {
          virtualMeetingId: 'Virtual Meeting Id',
        },
        virtualMeetingPasscode: {
          virtualMeetingPasscode: `Virtual Meeting Passcode`,
        },
        virtualMeetingLink: {
          virtualMeetingLink: 'Virtual Meeting Link',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        virtualMeetingId: {
          virtualMeetingId: 'ID de la réunion virtuelle',
        },
        virtualMeetingPasscode: {
          virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
        },
        virtualMeetingLink: {
          virtualMeetingLink: 'Lien de la réunion virtuelle ',
        },
      };
    }
    return translation;
  };

  const ReportEventOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        spam: 'Spam',
        falseInfo: 'False Information',
        unlawfulActivity: 'Unlawful activity or promoting unlawful activity',
        defamDiscrim: 'Defamatory, discriminatory, or mean-spirited content',
        infringingRight:
          'Infringing on 3rd party rights (patent, trademark, copyright etc.)',
        violatingPrivacy: 'Violating the privacy of another person',
        other: 'Other',
        technical: 'Technical',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        spam: 'Courrier indésirable',
        falseInfo: 'Fausse information',
        unlawfulActivity: `Activité illégale ou promotion d'une activité illégale`,
        defamDiscrim: 'Contenu diffamatoire, discriminatoire ou mesquin',
        infringingRight: `Violation des droits de tiers (brevet, marque, droit d'auteur, etc.)`,
        violatingPrivacy: `Violer la vie privée d'une autre personne`,
        other: 'Autre',
        technical: 'Technique',
      };
    }
    return translation;
  };

  const TimeZoneOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        canadaAtlanticTime: 'Canada/Atlantic',
        canadaCentralTime: 'Canada/Central',
        canadaEasternTime: 'Canada/Eastern',
        canadaMountainTime: 'Canada/Mountain',
        canadaNewfoundlandTime: 'Canada/Newfoundland',
        canadaPacificTime: 'Canada/Pacific',
        canadaYukonTime: 'Canada/Yukon',
        mexicoCentralTime: 'Mexico/Central',
        mexicoEasternTime: 'Mexico/Eastern',
        mexicoMountainTime: 'Mexico/Mountain',
        mexicoPacificTime: 'Mexico/Pacific',
        unitedStatesAlaskaTime: 'United States/Alaska',
        unitedStatesCentralTime: 'United States/Central',
        unitedStatesEasternTime: 'United States/Eastern',
        unitedStatesHawaiiAleutianTime: 'United States/Aleutian',
        unitedStatesMountainTime: 'United States/Mountain',
        unitedStatesPacificTime: 'United States/Pacific',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        canadaAtlanticTime: 'Canada/Atlantique',
        canadaCentralTime: 'Canada/Centre',
        canadaEasternTime: 'Canada/Est',
        canadaMountainTime: 'Canada/Montagne',
        canadaNewfoundlandTime: 'Canada/Terre-Neuve',
        canadaPacificTime: 'Canada/Pacifique',
        canadaYukonTime: 'Canada/Yukon',
        mexicoCentralTime: 'Mexico/Centre',
        mexicoEasternTime: 'Mexico/Est',
        mexicoMountainTime: 'Mexico/Montagne',
        mexicoPacificTime: 'Mexico/Pacifique',
        unitedStatesAlaskaTime: 'United States/Alaska',
        unitedStatesCentralTime: 'United States/Centre',
        unitedStatesEasternTime: 'United States/Est',
        unitedStatesHawaiiAleutianTime: 'United States/Aléoutiennes',
        unitedStatesMountainTime: 'United States/Montagne',
        unitedStatesPacificTime: 'United States/Pacifique',
      };
    }
    return translation;
  };

  const VoiceOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        voiceBob: 'Voice Bob',
        voiceJudy: 'Voice Judy',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        voiceBob: 'Voix Bob',
        voiceJudy: 'Voix Judy',
      };
    }
    return translation;
  };

  const BrowserOpts = () => {
    let translation = {
      chrome: 'Chrome',
      edge: 'Edge',
      fireFox: 'Firefox',
      opera: 'Opera',
      Safari: 'Safari',
      chromeAndroid: 'Chrome Android',
      fireFoxAndroid: 'Firefox Android',
      operaAndroid: 'Opera Android',
      safariIOS: 'Safari IOS',
      samsungInternet: 'Samsung Internet',
      webViewAndroid: 'Webview Android',
    };

    return translation;
  };

  const TranslationLanguageOpts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English (English)',
        'fr-CA': 'French (Français)',
        es: 'Spanish (Español)',
        it: 'Italian (Italiano)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        'en-CA': 'Anglais (English)',
        'fr-CA': 'Français (Français)',
        es: 'Espagnol (Español)',
        it: 'Italien (Italiano)',
      };
    }
    return translation;
  };

  const TranslationLanguagesSingular = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        'en-CA': 'English',
        'fr-CA': 'French',
        es: 'Spanish',
        it: 'Italian',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        'en-CA': 'Anglais',
        'fr-CA': 'Français',
        es: 'Espagnol',
        it: 'Italien',
      };
    }
    return translation;
  };

  const SalesTaxTitles = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        GST: 'GST',
        HST: 'HST',
        PST: 'PST',
        QST: 'QST',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        GST: 'TPS',
        HST: 'TVH',
        PST: 'TVP',
        QST: 'TVQ',
      };
    }
    return translation;
  };

  const Months = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'Decemeber',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        january: 'Janvier',
        february: 'Février',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Août',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'Décembre',
      };
    }
    return translation;
  };

  const MonthsFromNumber = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        10: 'October',
        11: 'November',
        12: 'Decemeber',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        '01': 'Janvier',
        '02': 'Février',
        '03': 'Mars',
        '04': 'Avril',
        '05': 'Mai',
        '06': 'Juin',
        '07': 'Juillet',
        '08': 'Août',
        '09': 'Septembre',
        10: 'Octobre',
        11: 'Novembre',
        12: 'Décembre',
      };
    }
    return translation;
  };

  const InvoiceStatus = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        draft: 'Draft',
        open: 'Open',
        paid: 'Paid',
        uncollectable: 'Uncollectable',
        void: 'Void',
        pending: 'Pending',
        deleted: 'Deleted',
        unpaid: 'Unpaid',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        draft: 'Brouillon',
        open: 'Ouverte',
        paid: 'Payée',
        uncollectible: 'Incollectable',
        void: 'Annulée',
        pending: 'En attente',
        deleted: 'Supprimée',
        unpaid: 'Non rémunéré',
      };
    }
    return translation;
  };

  const RoomCodeManagerActions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        hostPassword: 'Host Password',
        attendeePassword: 'Attendee Password',
        changeRoomCode: 'Change Room Code',
        remove: 'Remove',
        restoreSubscription: 'Restore Subscription',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        hostPassword: `Mot de passe de l'hôte`,
        attendeePassword: 'Mot de passe du participant',
        changeRoomCode: 'Changer le code de la salle',
        remove: 'Retirer',
        restoreSubscription: 'Restore Subscription',
      };
    }

    return translation;
  };

  const RoomCodeManagerActionDescriptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        hostPassword: `( Mandatory ) Set the password required to host a translation room with this code. Host passwords means you can host a room without having to log into your account.`,
        attendeePassword: `When hosts create rooms, they can require attendees to provide a password. If you intend to reuse the same attendee password frequently, you can set a default. This option still allows you to set a different password each time you host a room.`,
        remove: 'Remove this room code subscription from your account.',
        changeRoomCode:
          'Change the room code used in this subscription. No charges are applied.',
        restoreSubscription:
          'This room code subscription is scheduled to be cancelled at the end of your billing cycle. By restoring subscription you will uncancel your subscription at no cost.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        hostPassword: `( Obligatoire ) Définissez le mot de passe requis pour héberger une salle de traduction avec ce code. Les mots de passe d'hôte signifient que vous pouvez héberger une salle sans avoir à vous connecter à votre compte.`,
        attendeePassword: `Lorsque les hôtes créent des salles, ils peuvent demander aux participants de fournir un mot de passe. Si vous avez l'intention de réutiliser fréquemment le même mot de passe de participant, vous pouvez définir un mot de passe par défaut. Cette option vous permet toujours de définir un mot de passe différent à chaque fois que vous hébergez une salle.`,
        remove: 'Supprimez cet abonnement au code de la salle de votre compte.',
        changeRoomCode: `Modifiez le code de la salle utilisé dans cet abonnement. Aucun frais n'est appliqué.`,
        restoreSubscription: `Cet abonnement au code de la salle doit être annulé à la fin de votre cycle de facturation. En rétablissant l'abonnement, vous annulerez votre abonnement sans frais.`,
      };
    }

    return translation;
  };

  return {
    AudienceTypes: AudienceTypes(),
    AudienceTypesEventPage: AudienceTypesEventPage(),
    AudienceTypesInfo: AudienceTypesInfo(),
    BigTagsOpts: BigTagsOpts(),
    ContentLanguage: ContentLanguage(),
    CountriesOpts: CountriesOpts(),
    CountryCodeOpts: CountryCodeOpts(),
    EventTypes: EventTypes(),
    EventTypesInfo: EventTypesInfo(),
    GetFlag: GetFlag(),
    HostingOptions: HostingOptions(),
    InPersonPrivateDetailsOpts: InPersonPrivateDetailsOpts(),
    LanguageOpts: LanguageOpts(),
    OnlinePlatformOptions: OnlinePlatformOptions(),
    SearchLanguageOpts: SearchLanguageOpts(),
    VirtualPrivateDetailsOpts: VirtualPrivateDetailsOpts(),
    ReportEventOpts: ReportEventOpts(),
    TimeZoneOpts: TimeZoneOpts(),
    VoiceOpts: VoiceOpts(),
    BrowserOpts: BrowserOpts(),
    TranslationLanguageOpts: TranslationLanguageOpts(),
    TranslationLanguagesSingular: TranslationLanguagesSingular(),
    SalesTaxTitles: SalesTaxTitles(),
    Months: Months(),
    MonthsFromNumber: MonthsFromNumber(),
    InvoiceStatus: InvoiceStatus(),
    RoomCodeManagerActions: RoomCodeManagerActions(),
    RoomCodeManagerActionDescriptions: RoomCodeManagerActionDescriptions(),
    BigTagsOptsWithSubText: BigTagsOptsWithSubText(),
  };
};

export default useLanguageData;

// const hostingOptions = [
//   { 'In Person': 'Event will be held in person.' },
//   { Online: 'Event will be held online.' },
//   {
//     Hybrid: 'Event will be held in person and provide live attendance online.',
//   },
// ];
