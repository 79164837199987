import { createSlice } from '@reduxjs/toolkit';

const apiStatusSlice = createSlice({
  name: 'apiStatus',
  initialState: {
    status: [],
  },
  reducers: {
    setReduxApiStatus: (state, action) => {
      state.status = action.payload;
    },
    addReduxApiStatus: (state, action) => {
      state.status = [...state.status, action.payload];
    },
    removeReduxApiStatus: (state, action) => {
      const newReduxApiStatusArr = state.status.filter(
        (item) => item.id !== action.payload
      );
      state.status = newReduxApiStatusArr;
    },
  },
});

export const { setReduxApiStatus, addReduxApiStatus, removeReduxApiStatus } =
  apiStatusSlice.actions;

export default apiStatusSlice.reducer;

export const selectApiStatus = (state) => state.apiStatus?.status;
