//hooks
import useLanguageData from '../../../../../../language/useLanguageData';

//components
//utils
import { getDateOrTimeFromUTZstring } from '../../../../../../utils/dateTime/DateCalc';

const InfoCardContainerEventSchedule = ({ eventScheduleData }) => {
  const { TimeZoneOpts } = useLanguageData();

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Schedule</p>
      <div className="mrg-t6">
        Schedule Enabled: {eventScheduleData?.enabled ? 'True' : 'False'}
      </div>
      <div className="mrg-t6">
        Schedule Publish Time (Local):{' '}
        {`${getDateOrTimeFromUTZstring(
          eventScheduleData?.publishDateTimeLocal,
          'date'
        )}, (${getDateOrTimeFromUTZstring(
          eventScheduleData?.publishDateTimeLocal,
          'time'
        )})`}
      </div>
      <div className="mrg-t6">
        Schedule Publish Time (UTC):{' '}
        {`${getDateOrTimeFromUTZstring(
          eventScheduleData?.publishDateTimeUTC,
          'date'
        )}, (${getDateOrTimeFromUTZstring(
          eventScheduleData?.publishDateTimeUTC,
          'time'
        )})`}
      </div>
      <div className="mrg-t6">
        Schedule Publish TimeZone:{' '}
        {TimeZoneOpts[eventScheduleData?.publishTimeZone]}
      </div>
    </div>
  );
};

export default InfoCardContainerEventSchedule;
