import { useState, useEffect, useRef } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
//Components
import MapContainerWithDistanceFilter from '../../../../../components/MapContainer/MapContainerWithDistanceFilter';
import AutocompletePlacesNoAddress from '../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import DistanceToggle from '../../../../../components/DistanceToggle/DistanceToggle';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
//Utility

const EventSearchInPerson = ({
  Account,
  saveAccountChange,
  loadingSaveBtnUI,
}) => {
  //Hooks
  const {
    accountGeoPositionCoordinates: loadCoords,
    accountGeoPosition,
    accountSearches,
    accountDistanceFilter: loadDistanceFilter,
    accountDistanceMeasurement: loadDistanceMeasurement,
  } = useAccountReduxHandlers();

  //Component state
  const [coords, setCoords] = useState(loadCoords);

  const [distanceFilter, setDistanceFilter] = useState(0);
  const [distanceMeasurement, setDistanceMeasurement] = useState(
    loadDistanceMeasurement
  );
  const [searchLocationSaveValid, setSearchLocationSaveValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  //Component variables
  const initialDistanceFilter = useRef();
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  //Component functions
  useEffect(() => {
    const sameContents = coords.every((value, index) => {
      return value === loadCoords[index];
    });

    if (!sameContents) {
      setSearchLocationSaveValid(true);
    } else if (initialDistanceFilter.current !== distanceFilter) {
      setSearchLocationSaveValid(true);
    } else if (distanceMeasurement !== loadDistanceMeasurement) {
      setSearchLocationSaveValid(true);
    } else {
      setSearchLocationSaveValid(false);
    }
  }, [coords, loadCoords, distanceFilter]);

  function handleSaveSearchLocation() {
    let oldGeoPosition = accountGeoPosition;
    let geoPosition = { ...oldGeoPosition };
    geoPosition.coordinates = [...oldGeoPosition.coordinates];
    geoPosition.coordinates[0] = coords[0];
    geoPosition.coordinates[1] = coords[1];

    //ADD DISTANCE FILTER + MEASUREMENT
    let newDistance;
    if (distanceMeasurement === 'km') {
      newDistance = distanceFilter * 1000;
    } else {
      newDistance = parseInt(distanceFilter * 1609.344);
    }

    let backendGeoPosition = {
      type: 'Point',
      coordinates: [geoPosition.coordinates[0], geoPosition.coordinates[1]],
    };

    saveAccountChange(
      {
        searches: {
          ...accountSearches,
          geoPosition: backendGeoPosition,
          distanceFilter: newDistance,
          distanceMeasurement,
        },
      },
      'eventSearchInPerson'
    );

    setDistanceFilterCheck(newDistance, distanceMeasurement);
  }

  function setAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    setCoords(newCoords);
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  useEffect(() => {
    if (effectRan.current === false) {
      setDistanceFilterCheck(loadDistanceFilter, loadDistanceMeasurement);

      effectRan.current = true;
    }
  }, [loadDistanceFilter]);

  function setDistanceFilterCheck(distanceInMeters, measurement) {
    let convertedDistance;
    if (measurement === 'km') {
      convertedDistance = distanceInMeters / 1000;
      initialDistanceFilter.current = convertedDistance;
      setDistanceFilter(convertedDistance);
    } else if (measurement === 'mi') {
      convertedDistance = distanceInMeters / 1609.344;
      convertedDistance = Number(convertedDistance.toFixed(1));
      initialDistanceFilter.current = convertedDistance;
      setDistanceFilter(convertedDistance);
    }
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="eventSearchInPersonLabel"
      >
        {Account.attendee.eventSearchInPerson}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.eventSearchInPersonDescription}
      </p>

      <div className="h24" />
      {coords && (
        <MapContainerWithDistanceFilter
          loadTrigger={true}
          loadValue={coords}
          handler={setCoords}
          distanceFilter={distanceFilter}
          distanceMeasurement={distanceMeasurement}
        />
      )}
      <div className="h24" />

      <AutocompletePlacesNoAddress
        setAutocompleteCoordinates={setAutocompleteCoordinates}
      />
      <div className="h24" />

      {isLoaded && (
        <>
          <DistanceToggle
            distanceFilter={distanceFilter}
            setDistanceFilter={setDistanceFilter}
            distanceMeasurement={distanceMeasurement}
            setDistanceMeasurement={setDistanceMeasurement}
          />
          <div className="h24" />
        </>
      )}
      <SaveButton
        saveValid={searchLocationSaveValid}
        handler={() => handleSaveSearchLocation()}
        saveItemLanguage={Account.attendee.eventSearchInPerson}
        isLoading={loadingSaveBtnUI === 'eventSearchInPerson'}
      />
      <div className="h36" />
    </div>
  );
};

export default EventSearchInPerson;
