const InfoCardContainerError = ({ errorData }) => {
  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Error</p>
      <div className="mrg-t6">Message: {errorData.message}</div>
      <div className="mrg-t6">Status Code: {errorData.statusCode}</div>
      {errorData && (
        <>
          {errorData?.originalError && (
            <div className="mrg-t6">
              OriginalError: {errorData.originalError}
            </div>
          )}
          {errorData?.customMessage && (
            <div className="mrg-t6">
              Custom Message: {errorData.customMessage}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InfoCardContainerError;
