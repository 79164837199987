import { useState, useEffect } from 'react';

//Hooks
import useLanguageData from '../../../language/useLanguageData';
import useLanguageComponents from '../../../language/useLanguageComponents';
import useGenericModal from '../../../context/useGenericModal';
import useSettings from '../../../context/useSettings';

//Components

//Utility
import {
  bigtag,
  cancel,
  checkmark,
  checkmark_green,
  warning,
} from '../../../assets/icons';

const BigTagsListModal = ({ bigTags, handleAddTagsModal, limit }) => {
  //Hooks
  const { Generic } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { BigTagsOptsWithSubText } = useLanguageData();
  const { handleMobileTap } = useSettings();

  //Component state
  const [filteredList, setFilteredList] = useState();
  const [addedTags, setAddedTags] = useState([]);
  const [limitWarning, setLimitWarning] = useState(false);

  //UI state
  const [tapHighlightComplete, setTapHighlightComplete] = useState(false);
  const [tapHighlightClose, setTapHighlightClose] = useState(false);

  //Component variables
  let tagsList;

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId('bTOpenListBtn');
  }, []); //set id for focus when modal closes

  useEffect(() => {
    if (filteredList) {
      document.getElementById(`bigtagLabel`).focus();
    }
  }, [filteredList]);

  useEffect(() => {
    if (bigTags) {
      const filteredList = Object.keys(BigTagsOptsWithSubText).reduce(
        (acc, key) => {
          if (!bigTags.includes(key)) {
            acc[key] = BigTagsOptsWithSubText[key];
          }
          return acc;
        },
        {}
      );
      setFilteredList(filteredList);
    }
  }, [bigTags]); //removes selected tags

  //UI functions
  useEffect(() => {
    if (bigTags?.length + addedTags?.length > limit) {
      setLimitWarning(true);
    } else {
      if (limitWarning) {
        setLimitWarning(false);
      }
    }
  }, [addedTags]);

  //Component functions
  function handleClickTag(key) {
    if (addedTags?.includes(key)) {
      let updatedAddedTags = addedTags.filter(
        (existingTag) => existingTag !== key
      );
      return setAddedTags(updatedAddedTags);
    }

    let updatedAddedTags = [...addedTags, key];
    setAddedTags(updatedAddedTags);
  }

  //JSX conditional
  if (filteredList) {
    let lastItemIndex = Object.entries(filteredList).length - 1;

    tagsList = (
      <>
        {Object.entries(filteredList).map(([key, value], index) => {
          const isLastItem = index === lastItemIndex;

          return (
            <li
              key={`${key}`}
              style={isLastItem ? { marginBottom: '160px' } : null}
            >
              <button
                className={`item item--w-subText space-between access-ob access-o6 ${
                  addedTags.includes(key) ? 'item-selected-multiple' : ''
                }`}
                onClick={() => handleClickTag(key)}
                type="button"
                tabIndex="0"
                aria-label={value.mainText}
                id={`list-item-${index}`}
                aria-pressed={addedTags.includes(key)}
              >
                <div className="flex-column">
                  <p className="fs18 fwsb">{value.mainText}</p>
                  <p className="fs16 italic ">{value.subText}</p>
                </div>
                {addedTags?.includes(key) && (
                  <img
                    src={checkmark_green}
                    alt={Generic.checkmark}
                    className="mrg-r12"
                  />
                )}
              </button>
            </li>
          );
        })}
      </>
    );
  }

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="bigTagsListModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <img src={bigtag} alt={Generic.bigTags} />
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id="bigtagLabel"
          >
            {Generic.bigTags}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob  ${
            tapHighlightClose ? 'highlight-i-lgr--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightClose(true),
                () => setTapHighlightClose(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.cancel}
        >
          <img src={cancel} alt={Generic.cancel} className="svg" />
        </button>
      </div>
      <ul className="modal-basic__container list-style-none" id="bigTagsList">
        {tagsList}
      </ul>
      <div className="modal-basic__footer flex-center">
        {limitWarning ? (
          <div className="modal-warning-banner access-ob" tabIndex="0">
            <div className="flex-row flex-center">
              <p className="fwsb fs18 mrg-r12">{Generic.overLimit}</p>
              <img
                src={warning}
                alt={Generic.warning}
                className="svg"
                style={{ maxHeight: '28px' }}
              />
            </div>
          </div>
        ) : (
          <button
            className={`color-black access-ob access-o6 highlight-i-lgg ${
              tapHighlightComplete ? 'filter-img-green' : 'filter-img-lightgray'
            }}`}
            onClick={
              filteredList
                ? () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightComplete(true),
                        () => setTapHighlightComplete(false),
                      ],
                      [
                        () => handleAddTagsModal(addedTags),
                        () => handleCloseModal(),
                      ]
                    )
                : () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightComplete(true),
                        () => setTapHighlightComplete(false),
                      ],
                      [() => handleCloseModal()]
                    )
            }
            aria-label={`${Generic.addSelectedTags} ${addedTags?.length || 0}`}
          >
            {filteredList ? (
              <>
                <p className="fs18 fwn ">
                  {Generic.addSelectedTags} ({addedTags?.length || 0})
                </p>
                <img
                  src={checkmark}
                  alt={Generic.checkmark}
                  style={{ minHeight: '21px' }}
                  className="mrg-l12 svg"
                />
              </>
            ) : (
              <p className="fs18 fwsb access-ob access-o6">{Generic.close}</p>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default BigTagsListModal;
