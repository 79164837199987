import { useState } from 'react';

//hooks
import useLanguageTranslationRoom from '../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../components/ItemButton/ItemButton';

//utility
import { image_translation } from '../../../../assets/images';

const TranslationInitialize = ({ setPage }) => {
  //hooks
  const { TranslationInitialize } = useLanguageTranslationRoom();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div className="translations">
      <div
        className={`translations-selection__container ${
          imgLoaded ? '' : 'remove'
        }`}
      >
        <img
          src={image_translation}
          alt="img"
          title={TranslationInitialize.imageByPCH}
          className="translations-display-img svg"
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections mrg-t24 mrg-b48">
          <ItemButton
            text={TranslationInitialize.joinRoom}
            handler={() => setPage(2)}
            customWrapper={'full-width mrg-b24'}
          />
          <ItemButton
            text={TranslationInitialize.createRoom}
            handler={() => setPage(3)}
            customWrapper={'full-width'}
          />
        </div>
      </div>
    </div>
  );
};

export default TranslationInitialize;
