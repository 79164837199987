import { useState, useEffect } from 'react';

//Hooks
import useLanguageComponents from '../../../language/useLanguageComponents';
import useGenericModal from '../../../context/useGenericModal';
import useLanguageTagSuggestions from '../../../language/useLanguageTagSuggestions';
import useLanguageData from '../../../language/useLanguageData';
import useSettings from '../../../context/useSettings';
//Components

//Utility
import {
  cancel,
  checkmark,
  checkmark_green,
  nav_arrow,
  tinytag,
  warning,
} from '../../../assets/icons';

const TinyTagSuggestionsModal = ({
  bigTags,
  handleAddTags,
  limit,
  tinyTags,
  tinyTagGroups,
  CustomTagsList,
}) => {
  //Hooks
  const { Generic, TinyTagsModal } = useLanguageComponents();
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { TagSuggestions } = useLanguageTagSuggestions();
  const { BigTagsOpts } = useLanguageData();
  const { handleMobileTap } = useSettings();

  //Component state
  const [tagSuggestions, setTagSuggestions] = useState();
  const [filteredList, setFilteredList] = useState();
  const [addedTags, setAddedTags] = useState([]);
  const [filteredListValid, setFilteredListValid] = useState();
  const [limitWarning, setLimitWarning] = useState(false);
  const [page, setPage] = useState(1);
  const [pageMax, setPageMax] = useState();

  //UI state
  const [tapHighlightComplete, setTapHighlightComplete] = useState(false);
  const [tapHighlightClose, setTapHighlightClose] = useState(false);
  const [tapHighlightBackArrow, setTapHighlightBackArrow] = useState(false);
  const [tapHighlightForwardArrow, setTapHighlightForwardArrow] =
    useState(false);

  //Component variables
  let tagsList;

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId('suggestionsListBtn');
  }, []); //set id for focus when modal closes

  useEffect(() => {
    if (bigTags) {
      const filteredSuggestions = Object.keys(TagSuggestions)
        .filter((key) => bigTags.includes(key))
        .reduce((result, key) => {
          result[key] = TagSuggestions[key];
          return result;
        }, {});

      setTagSuggestions(filteredSuggestions);
    }
  }, [bigTags]); //set tag suggestions based on big tags

  //UI functions
  useEffect(() => {
    const pageSummaryEl = document.getElementById('pageSummary');
    if (pageSummaryEl) {
      pageSummaryEl.focus();
    }
  }, [page]); //change page focus

  useEffect(() => {
    if (tinyTags?.length + addedTags?.length > limit) {
      setLimitWarning(true);
    } else {
      if (limitWarning) {
        setLimitWarning(false);
      }
    }
  }, [addedTags]);

  //Component functions
  useEffect(() => {
    if (tagSuggestions && (tinyTags || tinyTagGroups)) {
      let combinedTinyTags = [...tinyTags];

      if (tinyTagGroups) {
        tinyTagGroups.map((tG) => {
          return tG.tags.map((tag) => combinedTinyTags.push(tag));
        });
      }

      const newfilteredList = Object.entries(tagSuggestions).reduce(
        (result, [category, tagsObject]) => {
          const filteredTags = Object.entries(tagsObject).filter(
            ([tagKey, tagValue]) => !combinedTinyTags.includes(tagValue)
          );
          if (filteredTags.length > 0) {
            result[category] = Object.fromEntries(filteredTags);
          }

          return result;
        },
        {}
      );

      if (newfilteredList && Object.entries(newfilteredList)?.length > 0) {
        setFilteredListValid(true);
      } else {
        setFilteredListValid(false);
      } //conditional rendering for the list of tags/instructions to add data to generate suggestions

      setPageMax(Object.keys(newfilteredList)?.length);
      document.getElementById(`tagSuggestionsLabel`).focus();
      setFilteredList(newfilteredList);
    }
  }, [tagSuggestions]); //filterSuggestion List based on existing tinytags and tinyTagGroups; & set up initialization

  function handleClickTag(key) {
    if (addedTags?.includes(key)) {
      let updatedAddedTags = addedTags.filter(
        (existingTag) => existingTag !== key
      );
      return setAddedTags(updatedAddedTags);
    }

    let updatedAddedTags = [...addedTags, key];
    setAddedTags(updatedAddedTags);
  }

  //JSX conditional
  if (filteredListValid) {
    tagsList = (
      <>
        {Object.entries(filteredList).map(([key, value], index) => {
          return (
            <div key={`tinyTagsSuggestions-list-${index}`}>
              {index + 1 === page && (
                <div>
                  <p className="attention-banner">
                    {TinyTagsModal.suggestionsWillImprove}
                  </p>
                  <div className="h12" />
                  <div className="flex-row flex-center space-evenly">
                    {page <= pageMax && page >= 2 ? (
                      <button
                        className={`nav-buttons highlight-i-theme rotate-180  access-ob access-o6 ${
                          tapHighlightBackArrow ? 'highlight-i-theme--tap' : ''
                        }`}
                        onClick={() =>
                          handleMobileTap(
                            [
                              () => setTapHighlightBackArrow(true),
                              () => setTapHighlightBackArrow(false),
                            ],
                            [() => setPage((prevPage) => prevPage - 1)]
                          )
                        }
                        aria-label={Generic.prevPage}
                      >
                        <img
                          src={nav_arrow}
                          alt={Generic.arrow}
                          className="filter-lightgray svg"
                        />
                      </button>
                    ) : (
                      <div className="nav-buttons" />
                    )}

                    <div
                      className="flex-column text-center access-ob access-o6"
                      tabIndex="0"
                      id="pageSummary"
                    >
                      <p className="fwsb fs18 flex-center no-select">
                        {Generic.page} {page} / {pageMax}
                      </p>
                      <p className="fwsb fs18 flex-center no-select ">
                        {BigTagsOpts[key]}
                      </p>
                    </div>

                    {page < pageMax ? (
                      <button
                        className={`nav-buttons highlight-i-theme access-ob access-o6 ${
                          tapHighlightForwardArrow
                            ? 'highlight-i-theme--tap'
                            : ''
                        }`}
                        onClick={() =>
                          handleMobileTap(
                            [
                              () => setTapHighlightForwardArrow(true),
                              () => setTapHighlightForwardArrow(false),
                            ],
                            [() => setPage((prevPage) => prevPage + 1)]
                          )
                        }
                        aria-label={Generic.nextPage}
                      >
                        <img
                          src={nav_arrow}
                          alt={Generic.arrow}
                          className="filter-lightgray svg"
                        />
                      </button>
                    ) : (
                      <div className="nav-buttons" />
                    )}
                  </div>
                  <div className="h24" />
                  {/* list of suggested tags */}
                  <ul className="list-style-none">
                    {Object.entries(value).map(
                      ([nestedKey, nestedValue], nestedIndex) => {
                        let lastItemIndex = Object.entries(value).length - 1;
                        const isLastItem = nestedIndex === lastItemIndex;

                        return (
                          <li
                            key={`${nestedValue}`}
                            style={
                              isLastItem ? { marginBottom: '160px' } : null
                            }
                          >
                            <button
                              className={`item fs16 fwsb space-between access-ob access-o6 ${
                                addedTags.includes(nestedValue)
                                  ? 'item-selected-multiple'
                                  : ''
                              }`}
                              onClick={() => handleClickTag(nestedValue)}
                              type="button"
                              tabIndex="0"
                              aria-label={nestedValue}
                              id={`list-item-${index}`}
                              aria-pressed={addedTags.includes(nestedValue)}
                            >
                              {nestedValue}
                              {addedTags?.includes(nestedValue) && (
                                <img
                                  src={checkmark_green}
                                  alt={Generic.checkmark}
                                  className="mrg-r12"
                                />
                              )}
                            </button>
                          </li>
                        );
                      }
                    )}
                  </ul>
                  <div className="h24" />
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  } else {
    //for create Events when tags list is empty
    tagsList = (
      <>
        <div className="h24" />
        <div className="flex-center flex-column align-center">
          <img
            src={warning}
            alt={Generic.warning}
            className="svg"
            style={{ maxHeight: '40px' }}
          />
          <p className="fs18 fwn flex-center text-center mrg-tb24">
            {CustomTagsList.needTagsToGenerateSuggestions}
          </p>
        </div>
        <div className="h24" />
      </>
    );
  }

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="tinyTagsSuggestionModal"
    >
      <div className="heading">
        <div className="flex-column full-width ">
          <div className="flex-row space-between">
            <div className="flex-row flex-center ">
              <img src={tinytag} alt={Generic.tinyTags} className="svg" />
              <label
                className="fs18 fwsb text-center access-ob access-o6"
                tabIndex="0"
                id="tagSuggestionsLabel"
              >
                {CustomTagsList.suggestedTinyTags}
              </label>
            </div>
            <button
              className={`close-btn highlight-i-lgr access-ob  ${
                tapHighlightClose ? 'highlight-i-lgr--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightClose(true),
                    () => setTapHighlightClose(false),
                  ],
                  [() => handleCloseModal()]
                )
              }
              type="button"
              title={Generic.cancel}
            >
              <img src={cancel} alt={Generic.cancel} className="svg" />
            </button>
          </div>
        </div>
      </div>
      {/* filteredList property used to determine rendering of modal contents + footer btn */}
      <ul
        className="modal-basic__container list-style-none"
        id="tinyTagsSuggestionsList"
      >
        {tagsList}
      </ul>
      <div className="modal-basic__footer flex-center">
        {limitWarning ? (
          <div className="modal-warning-banner access-ob" tabIndex="0">
            <div className="flex-row flex-center">
              <p className="fwsb fs18 mrg-r12">{Generic.overLimit}</p>
              <img
                src={warning}
                alt={Generic.warning}
                className="svg"
                style={{ maxHeight: '28px' }}
              />
            </div>
          </div>
        ) : (
          <button
            className={`color-black access-ob access-o6 highlight-i-lgg  ${
              tapHighlightComplete
                ? 'filter-img-green'
                : 'filter-img-lightgray highlight-it-theme'
            }`}
            onClick={
              filteredList
                ? () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightComplete(true),
                        () => setTapHighlightComplete(false),
                      ],
                      [() => handleAddTags(addedTags), () => handleCloseModal()]
                    )
                : () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightComplete(true),
                        () => setTapHighlightComplete(false),
                      ],
                      [() => handleCloseModal()]
                    )
            }
            aria-label={`${Generic.addSelectedTags} ${addedTags?.length || 0}`}
          >
            {filteredListValid ? (
              <>
                <p className="fs18 fwn ">
                  {Generic.addSelectedTags} ({addedTags?.length || 0})
                </p>
                <img
                  src={checkmark}
                  alt={Generic.checkmark}
                  style={{ minHeight: '21px' }}
                  className="mrg-l12 svg"
                />
              </>
            ) : (
              <p className="fs18 fwsb access-ob access-o6">{Generic.close}</p>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default TinyTagSuggestionsModal;
