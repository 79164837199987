import {
  useAddSaveByUserMutation,
  useRemoveSaveByUserMutation,
  useAddIgnoreByUserMutation,
  useRemoveIgnoreByUserMutation,
  useAddRegInPersonByUserMutation,
  useRemoveRegInPersonByUserMutation,
  useAddApprovalInPersonByUserMutation,
  useRemoveApprovalInPersonByUserMutation,
  useRemoveArchivedEventMutation,
  useAddRegVirtualByUserMutation,
  useRemoveRegVirtualByUserMutation,
  useAddApprovalVirtualByUserMutation,
  useRemoveApprovalVirtualByUserMutation,
} from './eventsApiSlice';

//Hooks
import useApiStatus from '../../../context/useApiStatus';
import useGenericModal from '../../../context/useGenericModal';
import useLanguageEvents from '../../../language/features/useLanguageEvents';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';

//Components
import ConfirmationModal from '../../../components/Modal/ModalComponents/ConfirmationModal';

//Utility

export const useEventHandlers = () => {
  //eventsApi
  const [addSaveByUser] = useAddSaveByUserMutation();
  const [removeSaveByUser] = useRemoveSaveByUserMutation();
  const [addIgnoreByUser] = useAddIgnoreByUserMutation();
  const [removeIgnoreByUser] = useRemoveIgnoreByUserMutation();
  const [addRegInPersonByUser] = useAddRegInPersonByUserMutation();
  const [removeRegInPersonByUser] = useRemoveRegInPersonByUserMutation();
  const [addApprovalInPersonByUser] = useAddApprovalInPersonByUserMutation();
  const [removeApprovalInPersonByUser] =
    useRemoveApprovalInPersonByUserMutation();
  const [removeArchivedEvent] = useRemoveArchivedEventMutation();
  const [addRegVirtualByUser] = useAddRegVirtualByUserMutation();
  const [removeRegVirtualByUser] = useRemoveRegVirtualByUserMutation();
  const [addApprovalVirtualByUser] = useAddApprovalVirtualByUserMutation();
  const [removeApprovalVirtualByUser] =
    useRemoveApprovalVirtualByUserMutation();

  const { setGenericModalMobileFloat } = useGenericModal();
  const { RemoveArchivedEventModal } = useLanguageEvents();

  const { accountRegistrationsInPerson, accountPendingApprovalInPerson } =
    useAccountReduxHandlers();
  const { handleWarning } = useApiStatus();

  //note: adding save and adding ignore has extra precautions in backend to ensure if the user is pendingapproval or registered it wont execute both backend and front end UI changes

  //API calls through Redux
  async function handleSaveEventAPI(eventId, accountId, setEventStatus) {
    try {
      const res = await addSaveByUser({ eventId, accountId });
      if (res.data.status === 'success' && setEventStatus) {
        setEventStatus('saved');
      }
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveSavedEventAPI(eventId, accountId) {
    try {
      const res = await removeSaveByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveArchivedEventAPI(eventId, accountId) {
    try {
      const res = await removeArchivedEvent({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleIgnoreEventAPI(eventId, accountId, setEventStatus) {
    try {
      const res = await addIgnoreByUser({ eventId, accountId });
      if (res.data.status === 'success' && setEventStatus) {
        setEventStatus('ignored');
      }
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveIgnoredEventAPI(eventId, accountId) {
    try {
      const res = await removeIgnoreByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRegisterInPersonAPI(eventId, accountId) {
    try {
      const res = await addRegInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleUnregisterInPersonAPI(eventId, accountId) {
    try {
      const res = await removeRegInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleAddApprovalInPersonAPI(eventId, accountId) {
    try {
      const res = await addApprovalInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveApprovalInPersonAPI(eventId, accountId) {
    try {
      const res = await removeApprovalInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  /////VIRTUAL ONLY
  async function handleAddApprovalVirtualAPI(eventId, accountId) {
    try {
      const res = await addApprovalVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveApprovalVirtualAPI(eventId, accountId) {
    try {
      const res = await removeApprovalVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRegisterVirtualAPI(eventId, accountId) {
    try {
      const res = await addRegVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleUnregisterVirtualAPI(eventId, accountId) {
    try {
      const res = await removeRegVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  /////////////Front end handlers
  function handleRemoveArchivedEvent(eventId, accountId, eventReference) {
    setGenericModalMobileFloat(
      <ConfirmationModal
        headingText={RemoveArchivedEventModal.removeArchivedEventWarning}
        buttonPosText={RemoveArchivedEventModal.yes}
        buttonNegText={RemoveArchivedEventModal.no}
        focusId={`${eventReference}`}
        handler={() => handleRemoveArchivedEventAPI(eventId, accountId)}
      />
    );
  }

  function handleUserSaveEvent(
    eventId,
    accountId,
    eventStatus,
    setEventStatus
  ) {
    if (
      accountRegistrationsInPerson?.includes(eventId) ||
      accountPendingApprovalInPerson?.includes(eventId)
    ) {
      return handleWarning({
        message: 'cannotPerformActionWhenRegisteredForThisEvent',
        id: Date.now(),
        origin: 'useEventHandlers.js/handleUserSaveEvent',
      });
    }
    if (eventStatus !== 'saved') {
      //setEventStatus passed to this function bec its an important function to ensure there is no desync data
      handleSaveEventAPI(eventId, accountId, setEventStatus);
    } else {
      setEventStatus('neutral');
      handleRemoveSavedEventAPI(eventId, accountId);
    }
  }

  function handleUserIgnoreEvent(
    eventId,
    accountId,
    eventStatus,
    setEventStatus
  ) {
    if (
      accountRegistrationsInPerson?.includes(eventId) ||
      accountPendingApprovalInPerson?.includes(eventId)
    ) {
      return handleWarning({
        message: 'cannotPerformActionWhenRegisteredForThisEvent',
        id: Date.now(),
        origin: 'useEventHandlers.js/handleUserIgnoreEvent',
      });
    }
    if (eventStatus !== 'ignored') {
      //setEventStatus passed to this function bec its an important function to ensure there is no desync data
      handleIgnoreEventAPI(eventId, accountId, setEventStatus);
    }
    if (eventStatus === 'ignored') {
      setEventStatus('neutral');
      handleRemoveIgnoredEventAPI(eventId, accountId);
    }
  }

  function handleUserInPersonRegistration(
    eventData,
    accountId,
    eventStatus,
    setEventStatus
  ) {
    //switch pendingApproval from Virtual to InPerson
    if (eventStatus === 'pendingApprovalVirtual') {
      //check for add approve
      if (eventData.registrationData.approveRegistrations) {
        handleRemoveApprovalVirtualAPI(eventData._id, accountId);
        handleAddApprovalInPersonAPI(eventData._id, accountId);
        return setEventStatus('pendingApprovalInPerson');
      } else {
        //no approve but there is maximum
        if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
          //below current max -- add reg
          if (
            eventData.registrationData.registeredAttendeesInPerson?.length <
            eventData.registrationData.maxRegistrationsInPersonAmount
          ) {
            handleRemoveApprovalVirtualAPI(eventData._id, accountId);
            handleRegisterInPersonAPI(eventData._id, accountId);
            return setEventStatus('registeredInPerson');
          } else {
            //equal or above current max -- add approval
            handleAddApprovalInPersonAPI(eventData._id, accountId);
            handleRemoveApprovalVirtualAPI(eventData._id, accountId);
            return setEventStatus('pendingApprovalInPerson');
          }
        } else {
          handleRemoveApprovalVirtualAPI(eventData._id, accountId);
          handleRegisterInPersonAPI(eventData._id, accountId);
          return setEventStatus('registeredInPerson');
        }
      }
    }

    //switch registration from Virtual to InPerson
    if (eventStatus === 'registeredVirtual') {
      if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
        if (
          eventData.registrationData.registeredAttendeesInPerson?.length <
          eventData.registrationData.maxRegistrationsInPersonAmount
        ) {
          handleUnregisterVirtualAPI(eventData._id, accountId);
          handleRegisterInPersonAPI(eventData._id, accountId);
          return setEventStatus('registeredInPerson');
        } else {
          handleAddApprovalInPersonAPI(eventData._id, accountId);
          return setEventStatus('pendingApprovalInPerson-registeredVirtual');
        }
      } else {
        handleUnregisterVirtualAPI(eventData._id, accountId);
        handleRegisterInPersonAPI(eventData._id, accountId);
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      }
    }

    //if pendingApprovalVirtual-registeredInPerson
    if (eventStatus === 'pendingApprovalVirtual-registeredInPerson') {
      handleUnregisterInPersonAPI(eventData._id, accountId);
      return setEventStatus('pendingApprovalVirtual');
    }

    if (eventStatus === 'registeredInPerson') {
      //remove if already registered
      setEventStatus('neutral');
      return handleUnregisterInPersonAPI(eventData._id, accountId);
    } else if (eventStatus === 'pendingApprovalInPerson') {
      //remove if already pendingApproval
      setEventStatus('neutral');
      return handleRemoveApprovalInPersonAPI(eventData._id, accountId);
    } else if (!eventData.registrationData.approveRegistrations) {
      //event does not require approval; but there is max registrations
      if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
        if (
          eventData.registrationData.registeredAttendeesInPerson.length <
          eventData.registrationData.maxRegistrationsInPersonAmount
        ) {
          setEventStatus('registeredInPerson');
          return handleRegisterInPersonAPI(eventData._id, accountId);
        } else {
          setEventStatus('pendingApprovalInPerson');
          return handleAddApprovalInPersonAPI(eventData._id, accountId);
        }
      } else {
        setEventStatus('registeredInPerson');
        return handleRegisterInPersonAPI(eventData._id, accountId);
      }
    } else if (eventData.registrationData.approveRegistrations) {
      //approve registrations required + wont matter if maxReg is full or not
      setEventStatus('pendingApprovalInPerson');
      return handleAddApprovalInPersonAPI(eventData._id, accountId);
    }
  }

  function handleUserVirtualRegistration(
    eventData,
    accountId,
    eventStatus,
    setEventStatus
  ) {
    //switch pendingApproval from InPerson to Virtual
    if (eventStatus === 'pendingApprovalInPerson') {
      //check for add approve
      if (eventData.registrationData.approveRegistrations) {
        handleRemoveApprovalInPersonAPI(eventData._id, accountId);
        handleAddApprovalVirtualAPI(eventData._id, accountId);
        return setEventStatus('pendingApprovalVirtual');
      } else {
        //no approve but there is maximum
        if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
          //below current max -- add reg
          if (
            eventData.registrationData.registeredAttendeesVirtual?.length <
            eventData.registrationData.maxRegistrationsVirtualAmount
          ) {
            handleRemoveApprovalInPersonAPI(eventData._id, accountId);
            handleRegisterVirtualAPI(eventData._id, accountId);
            return setEventStatus('registeredVirtual');
          } else {
            //equal or above current max -- add approval
            handleAddApprovalVirtualAPI(eventData._id, accountId);
            handleRemoveApprovalInPersonAPI(eventData._id, accountId);
            return setEventStatus('pendingApprovalVirtual');
          }
        } else {
          handleRemoveApprovalInPersonAPI(eventData._id, accountId);
          handleRegisterVirtualAPI(eventData._id, accountId);
          return setEventStatus('registeredVirtual');
        }
      }
    }

    //switch registration from InPerson to Virtual
    if (eventStatus === 'registeredInPerson') {
      if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
        if (
          eventData.registrationData.registeredAttendeesVirtual?.length <
          eventData.registrationData.maxRegistrationsVirtualAmount
        ) {
          handleUnregisterInPersonAPI(eventData._id, accountId);
          handleRegisterVirtualAPI(eventData._id, accountId);
          return setEventStatus('registeredVirtual');
        } else {
          handleAddApprovalVirtualAPI(eventData._id, accountId);

          return setEventStatus('pendingApprovalVirtual-registeredInPerson');
        }
      } else {
        handleUnregisterInPersonAPI(eventData._id, accountId);
        handleRegisterVirtualAPI(eventData._id, accountId);
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      }
    }

    //if pendingApprovalInPerson-registeredVirtual
    if (eventStatus === 'pendingApprovalVirtual-registeredInPerson') {
      handleRemoveApprovalVirtualAPI(eventData._id, accountId);
      return setEventStatus('registeredInPerson');
    }

    if (eventStatus === 'registeredVirtual') {
      //remove if already registered
      setEventStatus('neutral');
      return handleUnregisterVirtualAPI(eventData._id, accountId);
    } else if (eventStatus === 'pendingApprovalVirtual') {
      //remove if already pendingApproval
      setEventStatus('neutral');
      return handleRemoveApprovalVirtualAPI(eventData._id, accountId);
    } else if (!eventData.registrationData.approveRegistrations) {
      //event does not require approval; but there is max registrations
      if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
        if (
          eventData.registrationData.registeredAttendeesVirtual.length <
          eventData.registrationData.maxRegistrationsVirtualAmount
        ) {
          setEventStatus('registeredVirtual');
          return handleRegisterVirtualAPI(eventData._id, accountId);
        } else {
          setEventStatus('pendingApprovalVirtual');
          return handleAddApprovalVirtualAPI(eventData._id, accountId);
        }
      } else {
        setEventStatus('registeredVirtual');
        return handleRegisterVirtualAPI(eventData._id, accountId);
      }
    } else if (eventData.registrationData.approveRegistrations) {
      //approve registrations required + wont matter if maxReg is full or not
      setEventStatus('pendingApprovalVirtual');
      return handleAddApprovalVirtualAPI(eventData._id, accountId);
    }
  }

  return {
    handleIgnoreEventAPI,
    handleRemoveIgnoredEventAPI,
    handleRemoveArchivedEvent,
    handleUserSaveEvent,
    handleUserIgnoreEvent,
    handleUserInPersonRegistration,
    handleUserVirtualRegistration,
  };
};
