import moment from 'moment-timezone';
import useLanguageComponents from '../../language/useLanguageComponents';

const GetDurationWithUTC = (dateTime) => {
  const { Generic } = useLanguageComponents();

  const startTimeUTC = moment(dateTime.dateTimeStartUTC);
  const endTimeUTC = moment(dateTime.dateTimeEndUTC);

  // Calculate the duration in milliseconds
  const durationInMilliseconds = endTimeUTC.diff(startTimeUTC);

  // Convert the duration to hours and minutes
  const durationHours = Math.floor(durationInMilliseconds / 3600000);
  const durationMinutes = Math.floor(
    (durationInMilliseconds % 3600000) / 60000
  );

  let durationString;

  if (durationMinutes === 0) {
    durationString = `${durationHours} ${Generic.hours}`;
  } else {
    durationString = `${durationHours} ${Generic.hours} ${durationMinutes} ${Generic.minutes}`;
  }

  return durationString;
};

const GetDurationWithoutUTC = (dateTime) => {
  const { Generic } = useLanguageComponents();

  const timeStart = dateTime.timeStart;
  const timeEnd = dateTime.timeEnd;

  let timeDiff = timeEnd - timeStart;

  // Handle cases where timeEnd is smaller than timeStart (e.g., crossing over midnight)
  if (timeDiff < 0) {
    timeDiff += 2400;
  }
  // Calculate hours and minutes
  const hours = Math.floor(timeDiff / 100);
  const minutes = timeDiff % 100;

  // Construct the duration string
  let durationString = '';
  if (hours > 0) {
    durationString += `${hours} ${hours === 1 ? Generic.hour : Generic.hours}`;
  }
  if (minutes > 0) {
    if (durationString !== '') {
      durationString += ' and ';
    }
    durationString += `${minutes} ${
      minutes === 1 ? Generic.minute : Generic.minutes
    }`;
  }

  return durationString;
};

export { GetDurationWithUTC, GetDurationWithoutUTC };
