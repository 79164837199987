import { useDispatch, useSelector } from 'react-redux';
import {
  selectCreateEventIdVerification,
  selectCreateEvent,
  selectCreateEventDateTimeDate,
  selectCreateEventDateTimeTimeEnd,
  selectCreateEventDateTimeTimeStart,
  selectCreateEventDateTimeTimeZone,
  selectCreateEventGenericAccessibilityNotes,
  selectCreateEventGenericAgendaItems,
  selectCreateEventGenericAgendaFormat,
  selectCreateEventGenericAudienceType,
  selectCreateEventGenericDescription,
  selectCreateEventGenericEventImagePath,
  selectCreateEventGenericEventType,
  selectCreateEventGenericLanguages,
  selectCreateEventGenericTitle,
  selectCreateEventGenericWheelchairAccessibility,
  selectCreateEventGenericRealtimeTranslations,
  selectCreateEventGenericSignLanguageInterpreter,
  selectCreateEventHostingInPersonGeoPositionCoordinates,
  selectCreateEventHostingHostingOption,
  selectCreateEventHostingInPersonLocation,
  selectCreateEventHostingVirtualMeetingId,
  selectCreateEventHostingVirtualPasscode,
  selectCreateEventHostingVirtualPlatform,
  selectCreateEventHostingVirtualLink,
  selectCreateEventMetaLastModified,
  selectCreateEventMetaSaveName,
  selectCreateEventParticipantsModerator,
  selectCreateEventParticipantsSpeaker0Data,
  selectCreateEventParticipantsSpeaker1Data,
  selectCreateEventParticipantsSpeaker2Data,
  selectCreateEventParticipantsSpeaker3Data,
  selectCreateEventParticipantsSpeaker4Data,
  selectCreateEventParticipantsSpeaker5Data,
  selectCreateEventRegistrationDataApproveRegistrations,
  selectCreateEventRegistrationDataMaxRegistrationsInPersonEnabled,
  selectCreateEventRegistrationDataMaxRegistrationsVirtualEnabled,
  selectCreateEventRegistrationDataPrivateDetailsEnabled,
  selectCreateEventRegistrationDataMaxRegistrationsInPersonAmount,
  selectCreateEventRegistrationDataMaxRegistrationsVirtualAmount,
  selectCreateEventRegistrationDataPrivateDetails,
  selectCreateEventRegistrationDataRegistrationsActive,
  selectCreateEventSchedulePublishDate,
  selectCreateEventSchedulePublishTime,
  selectCreateEventSchedulePublishTimeZone,
  selectCreateEventTagsBigTags,
  selectCreateEventTagsTinyTags,
  setCreateEventIdVerification,
  setCreateEventDateTimeDate,
  setCreateEventDateTimeTimeEnd,
  setCreateEventDateTimeTimeStart,
  setCreateEventDateTimeTimeZone,
  setCreateEventGenericAccessibilityNotes,
  setCreateEventGenericAgendaItems,
  setCreateEventGenericAgendaFormat,
  setCreateEventGenericAudienceType,
  setCreateEventGenericDescription,
  setCreateEventGenericEventType,
  setCreateEventGenericLanguages,
  setCreateEventGenericSignLanguageInterpreter,
  setCreateEventGenericTitle,
  setCreateEventGenericWheelchairAccessibility,
  setCreateEventGenericRealtimeTranslations,
  setCreateEventHostingGeoPositionCoordinates,
  setCreateEventHostingHostingOption,
  setCreateEventHostingInPersonLocation,
  setCreateEventHostingVirtualMeetingId,
  setCreateEventHostingVirtualPasscode,
  setCreateEventHostingVirtualPlatform,
  setCreateEventHostingVirtualLink,
  setCreateEventMetaSaveName,
  setCreateEventParticipantsModerator,
  setCreateEventParticipantsSpeaker0Data,
  setCreateEventParticipantsSpeaker1Data,
  setCreateEventParticipantsSpeaker2Data,
  setCreateEventParticipantsSpeaker3Data,
  setCreateEventParticipantsSpeaker4Data,
  setCreateEventParticipantsSpeaker5Data,
  setCreateEventRegistrationDataApproveRegistrations,
  setCreateEventRegistrationDataMaxRegistrationsInPersonEnabled,
  setCreateEventRegistrationDataMaxRegistrationsVirtualEnabled,
  setCreateEventRegistrationDataPrivateDetailsEnabled,
  setCreateEventRegistrationDataMaxRegistrationsInPersonAmount,
  setCreateEventRegistrationDataMaxRegistrationsVirtualAmount,
  setCreateEventRegistrationDataPrivateDetails,
  setCreateEventRegistrationDataRegistrationsActive,
  setCreateEventSchedulePublishDate,
  setCreateEventSchedulePublishTime,
  setCreateEventSchedulePublishTimeZone,
  addCreateEventTagsBigTag,
  addCreateEventTagsTinyTag,
  removeCreateEventTagsBigTag,
  removeCreateEventTagsTinyTag,
  setCreateEventTagsBigTags,
  setCreateEventTagsTinyTags,
  resetCreateEvent,
  setCreateEvent,
  reverseCreateEventHostingGeoPositionCoordinates,
  setCreateEventGenericEventImagePath,
  setCreateEventScheduleEnabled,
  selectCreateEventScheduleEnabled,
} from './createEventSlice';

//Hooks

//Components

//Utility

export const useCreateEventReduxHandlers = () => {
  const dispatch = useDispatch();

  const isCreateEventStateInitialized = Boolean(selectCreateEvent);

  //dateTime
  const date = useSelector(selectCreateEventDateTimeDate);
  const timeEnd = useSelector(selectCreateEventDateTimeTimeEnd);
  const timeStart = useSelector(selectCreateEventDateTimeTimeStart);
  const timeZone = useSelector(selectCreateEventDateTimeTimeZone);

  //Generic
  const accessibilityNotes = useSelector(
    selectCreateEventGenericAccessibilityNotes
  );
  const agendaItems = useSelector(selectCreateEventGenericAgendaItems);
  const agendaFormat = useSelector(selectCreateEventGenericAgendaFormat);
  const audienceType = useSelector(selectCreateEventGenericAudienceType);
  const description = useSelector(selectCreateEventGenericDescription);
  const eventImagePath = useSelector(selectCreateEventGenericEventImagePath);
  const eventType = useSelector(selectCreateEventGenericEventType);
  const languages = useSelector(selectCreateEventGenericLanguages);
  const realtimeTranslations = useSelector(
    selectCreateEventGenericRealtimeTranslations
  );
  const signLanguageInterpreter = useSelector(
    selectCreateEventGenericSignLanguageInterpreter
  );
  const title = useSelector(selectCreateEventGenericTitle);
  const wheelchairAccessibility = useSelector(
    selectCreateEventGenericWheelchairAccessibility
  );

  //Hosting
  const hostingOption = useSelector(selectCreateEventHostingHostingOption);
  const coordinates = useSelector(
    selectCreateEventHostingInPersonGeoPositionCoordinates
  );
  const virtualMeetingId = useSelector(
    selectCreateEventHostingVirtualMeetingId
  );
  const virtualPasscode = useSelector(selectCreateEventHostingVirtualPasscode);
  const virtualPlatform = useSelector(selectCreateEventHostingVirtualPlatform);
  const virtualLink = useSelector(selectCreateEventHostingVirtualLink);
  const location = useSelector(selectCreateEventHostingInPersonLocation);

  //Meta
  const lastModified = useSelector(selectCreateEventMetaLastModified);
  const saveName = useSelector(selectCreateEventMetaSaveName);
  const createEventData = useSelector(selectCreateEvent);
  const createEventIdVerification = useSelector(
    selectCreateEventIdVerification
  );

  //Participants
  const moderator = useSelector(selectCreateEventParticipantsModerator);
  const speaker0Data = useSelector(selectCreateEventParticipantsSpeaker0Data);
  const speaker1Data = useSelector(selectCreateEventParticipantsSpeaker1Data);
  const speaker2Data = useSelector(selectCreateEventParticipantsSpeaker2Data);
  const speaker3Data = useSelector(selectCreateEventParticipantsSpeaker3Data);
  const speaker4Data = useSelector(selectCreateEventParticipantsSpeaker4Data);
  const speaker5Data = useSelector(selectCreateEventParticipantsSpeaker5Data);

  //RegistrationData
  const approveRegistrations = useSelector(
    selectCreateEventRegistrationDataApproveRegistrations
  );
  const maxRegistrationsInPersonEnabled = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsInPersonEnabled
  );
  const maxRegistrationsVirtualEnabled = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsVirtualEnabled
  );
  const maxRegistrationsInPersonAmount = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsInPersonAmount
  );
  const maxRegistrationsVirtualAmount = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsVirtualAmount
  );
  const registrationsActive = useSelector(
    selectCreateEventRegistrationDataRegistrationsActive
  );
  const privateDetails = useSelector(
    selectCreateEventRegistrationDataPrivateDetails
  );
  const enablePrivateDetails = useSelector(
    selectCreateEventRegistrationDataPrivateDetailsEnabled
  );

  //SchedulePublish
  const scheduleEnabled = useSelector(selectCreateEventScheduleEnabled);
  const schedulePublishDate = useSelector(selectCreateEventSchedulePublishDate);
  const schedulePublishTime = useSelector(selectCreateEventSchedulePublishTime);
  const schedulePublishTimeZone = useSelector(
    selectCreateEventSchedulePublishTimeZone
  );

  //Tags
  const bigTags = useSelector(selectCreateEventTagsBigTags);
  const tinyTags = useSelector(selectCreateEventTagsTinyTags);

  ////HANDLERS
  function handleCreateEventReturnEventData() {
    return createEventData;
  }

  function handleCreateEventReset(accountId) {
    dispatch(resetCreateEvent());
    dispatch(setCreateEventIdVerification(accountId));
  }

  function handleCreateEventSetEvent(eventData) {
    dispatch(setCreateEvent(eventData));
  }

  //dateTime
  function handleCreateEventSetDate(str) {
    dispatch(setCreateEventDateTimeDate(str));
  }
  function handleCreateEventSetTimeZone(str) {
    dispatch(setCreateEventDateTimeTimeZone(str));
  }
  function handleCreateEventSetTimeStart(str) {
    dispatch(setCreateEventDateTimeTimeStart(str));
  }
  function handleCreateEventSetTimeEnd(str) {
    dispatch(setCreateEventDateTimeTimeEnd(str));
  }

  //Generic

  function handleCreateEventSetAgendaFormat(str) {
    dispatch(setCreateEventGenericAgendaFormat(str));
  }

  function handleCreateEventSetAgendaItems(arr) {
    dispatch(setCreateEventGenericAgendaItems(arr));
  }

  function handleCreateEventSetTitle(str) {
    dispatch(setCreateEventGenericTitle(str));
  }
  function handleCreateEventSetDescription(str) {
    dispatch(setCreateEventGenericDescription(str));
  }
  function handleCreateEventSetEventType(str) {
    dispatch(setCreateEventGenericEventType(str));
  }
  function handleCreateEventSetEventImagePath(str) {
    dispatch(setCreateEventGenericEventImagePath(str));
  }
  function handleCreateEventSetAudienceType(str) {
    dispatch(setCreateEventGenericAudienceType(str));
  }

  function handleCreateEventSetLanguages(arr) {
    dispatch(setCreateEventGenericLanguages(arr));
  }

  function handleCreateEventSetWheelchairAccessibility(boolean) {
    dispatch(setCreateEventGenericWheelchairAccessibility(boolean));
  }

  function handleCreateEventSetRealtimeTranslations(boolean) {
    dispatch(setCreateEventGenericRealtimeTranslations(boolean));
  }

  function handleCreateEventSetSignLanguageInterpreter(
    signLanguageInterpreter
  ) {
    dispatch(
      setCreateEventGenericSignLanguageInterpreter(signLanguageInterpreter)
    );
  }

  function handleCreateEventSetAccessibilityNotes(str) {
    dispatch(setCreateEventGenericAccessibilityNotes(str));
  }

  //Hosting

  function handleCreateEventSetHostingOption(str) {
    dispatch(setCreateEventHostingHostingOption(str));
  }

  function handleCreateEventSetVirtualPlatform(str) {
    dispatch(setCreateEventHostingVirtualPlatform(str));
  }

  function handleCreateEventSetVirtualMeetingId(str) {
    dispatch(setCreateEventHostingVirtualMeetingId(str));
  }

  function handleCreateEventSetVirtualMeetingLink(str) {
    dispatch(setCreateEventHostingVirtualLink(str));
  }

  function handleCreateEventSetVirtualPasscode(str) {
    dispatch(setCreateEventHostingVirtualPasscode(str));
  }

  function handleCreateEventSetLocation(obj) {
    dispatch(setCreateEventHostingInPersonLocation(obj));
  }

  function handleCreateEventClearLocation() {
    dispatch(
      setCreateEventHostingInPersonLocation({
        room: '',
        locationName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        placeId: '',
      })
    );
    dispatch(setCreateEventHostingGeoPositionCoordinates([0, 0]));
  }

  function handleCreateEventSetCoordinates(arr) {
    dispatch(setCreateEventHostingGeoPositionCoordinates(arr));
  }

  function handleReverseCreateEventHostingGeoPositionCoordinates() {
    dispatch(reverseCreateEventHostingGeoPositionCoordinates());
  }

  //Meta
  function handleCreateEventSetSaveName(saveName) {
    dispatch(setCreateEventMetaSaveName(saveName));
  }

  //Participants
  function handleCreateEventSetModerator(str) {
    dispatch(setCreateEventParticipantsModerator(str));
  }

  function handleCreateEventSetSpeaker0Data(speaker0Data) {
    dispatch(setCreateEventParticipantsSpeaker0Data(speaker0Data));
  }

  function handleCreateEventSetSpeaker1Data(speaker1Data) {
    dispatch(setCreateEventParticipantsSpeaker1Data(speaker1Data));
  }

  function handleCreateEventSetSpeaker2Data(speaker2Data) {
    dispatch(setCreateEventParticipantsSpeaker2Data(speaker2Data));
  }

  function handleCreateEventSetSpeaker3Data(speaker3Data) {
    dispatch(setCreateEventParticipantsSpeaker3Data(speaker3Data));
  }

  function handleCreateEventSetSpeaker4Data(speaker4Data) {
    dispatch(setCreateEventParticipantsSpeaker4Data(speaker4Data));
  }

  function handleCreateEventSetSpeaker5Data(speaker5Data) {
    dispatch(setCreateEventParticipantsSpeaker5Data(speaker5Data));
  }

  //RegistrationData
  function handleCreateEventSetRegistrations(boolean) {
    dispatch(setCreateEventRegistrationDataRegistrationsActive(boolean));
  }

  function handleCreateEventSetApproveRegistrations(boolean) {
    dispatch(setCreateEventRegistrationDataApproveRegistrations(boolean));
  }

  function handleCreateEventSetMaxRegistrationsInPersonEnabled(boolean) {
    dispatch(
      setCreateEventRegistrationDataMaxRegistrationsInPersonEnabled(boolean)
    );
  }

  function handleCreateEventSetMaxRegistrationsInPersonAmount(num) {
    dispatch(setCreateEventRegistrationDataMaxRegistrationsInPersonAmount(num));
  }

  function handleCreateEventSetMaxRegistrationsVirtualEnabled(boolean) {
    dispatch(
      setCreateEventRegistrationDataMaxRegistrationsVirtualEnabled(boolean)
    );
  }

  function handleCreateEventSetMaxRegistrationsVirtualAmount(num) {
    dispatch(setCreateEventRegistrationDataMaxRegistrationsVirtualAmount(num));
  }

  function handleCreateEventSetEnablePrivateDetails(enablePrivateDetails) {
    dispatch(
      setCreateEventRegistrationDataPrivateDetailsEnabled(enablePrivateDetails)
    );
  }

  function handleCreateEventSetPrivateDetails(id, val) {
    dispatch(setCreateEventRegistrationDataPrivateDetails({ id, val }));
  }

  //SchedulePublish
  function handleCreateEventSetScheduleEnabled(boolean) {
    dispatch(setCreateEventScheduleEnabled(boolean));
  }
  function handleCreateEventSetSchedulePublishDate(str) {
    dispatch(setCreateEventSchedulePublishDate(str));
  }
  function handleCreateEventSetSchedulePublishTime(str) {
    dispatch(setCreateEventSchedulePublishTime(str));
  }
  function handleCreateEventSetSchedulePublishTimeZone(str) {
    dispatch(setCreateEventSchedulePublishTimeZone(str));
  }

  //Tags
  function handleCreateEventAddBigTag(str) {
    dispatch(addCreateEventTagsBigTag(str));
  }

  function handleCreateEventRemoveBigTag(str) {
    dispatch(removeCreateEventTagsBigTag(str));
  }

  function handleCreateEventAddTinyTag(str) {
    dispatch(addCreateEventTagsTinyTag(str));
  }

  function handleCreateEventRemoveTinyTag(str) {
    dispatch(removeCreateEventTagsTinyTag(str));
  }

  function handleCreateEventSetBigTags(arr) {
    dispatch(setCreateEventTagsBigTags(arr));
  }

  function handleCreateEventSetTinyTags(arr) {
    dispatch(setCreateEventTagsTinyTags(arr));
  }

  return {
    handleCreateEventReturnEventData,
    isCreateEventStateInitialized,
    maxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount,
    accessibilityNotes,
    createEventIdVerification,
    agendaFormat,
    agendaItems,
    approveRegistrations,
    audienceType,
    bigTags,
    coordinates,
    createEventData,
    date,
    description,
    enablePrivateDetails,
    eventImagePath,
    eventType,
    hostingOption,
    languages,
    lastModified,
    location,
    moderator,
    privateDetails,
    realtimeTranslations,
    registrationsActive,
    saveName,
    schedulePublishDate,
    schedulePublishTime,
    schedulePublishTimeZone,
    signLanguageInterpreter,
    speaker0Data,
    speaker1Data,
    speaker2Data,
    speaker3Data,
    speaker4Data,
    speaker5Data,
    timeEnd,
    timeStart,
    timeZone,
    tinyTags,
    title,
    virtualLink,
    virtualMeetingId,
    virtualPasscode,
    virtualPlatform,
    wheelchairAccessibility,
    handleCreateEventSetAccessibilityNotes,
    handleCreateEventAddBigTag,
    handleCreateEventAddTinyTag,
    handleCreateEventSetAgendaFormat,
    handleCreateEventSetAgendaItems,
    handleCreateEventSetApproveRegistrations,
    handleCreateEventSetAudienceType,
    handleCreateEventClearLocation,
    handleCreateEventSetCoordinates,
    handleCreateEventSetDate,
    handleCreateEventSetDescription,
    handleCreateEventSetEnablePrivateDetails,
    handleCreateEventSetEventType,
    handleCreateEventSetHostingOption,
    handleCreateEventSetLanguages,
    handleCreateEventSetLocation,
    handleCreateEventSetModerator,
    handleCreateEventSetPrivateDetails,
    handleCreateEventSetRegistrations,
    handleCreateEventSetRealtimeTranslations,
    handleCreateEventRemoveBigTag,
    handleCreateEventRemoveTinyTag,
    handleCreateEventReset,
    handleCreateEventSetSaveName,
    handleCreateEventSetSchedulePublishDate,
    handleCreateEventSetSchedulePublishTime,
    handleCreateEventSetSchedulePublishTimeZone,
    handleCreateEventSetEvent,
    handleCreateEventSetSignLanguageInterpreter,
    handleCreateEventSetSpeaker0Data,
    handleCreateEventSetSpeaker1Data,
    handleCreateEventSetSpeaker2Data,
    handleCreateEventSetSpeaker3Data,
    handleCreateEventSetSpeaker4Data,
    handleCreateEventSetSpeaker5Data,
    handleCreateEventSetTimeEnd,
    handleCreateEventSetTimeStart,
    handleCreateEventSetTimeZone,
    handleCreateEventSetTitle,
    handleCreateEventSetVirtualMeetingId,
    handleCreateEventSetVirtualPasscode,
    handleCreateEventSetVirtualPlatform,
    handleCreateEventSetVirtualMeetingLink,
    handleCreateEventSetWheelchairAccessibility,
    handleCreateEventSetBigTags,
    handleCreateEventSetTinyTags,
    handleCreateEventSetMaxRegistrationsInPersonEnabled,
    handleCreateEventSetMaxRegistrationsInPersonAmount,
    handleCreateEventSetMaxRegistrationsVirtualEnabled,
    handleCreateEventSetMaxRegistrationsVirtualAmount,
    handleReverseCreateEventHostingGeoPositionCoordinates,
    handleCreateEventSetEventImagePath,
    handleCreateEventSetScheduleEnabled,
    scheduleEnabled,
  };
};
