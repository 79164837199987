import { useRef, useState, useEffect } from 'react';

//Hooks
import useSettings from '../../../context/useSettings';
import useLanguageAuth from '../../../language/features/useLanguageAuth';
import { useNavigate } from 'react-router-dom';
import { useAuthReduxHandlers } from './useAuthHandlers';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';

//Components
import Spinner from '../../../components/Spinner/Spinner';
import useApiStatus from '../../../context/useApiStatus';

const Login = () => {
  //Hooks
  const navigate = useNavigate();
  const { handleError } = useApiStatus();
  const { handleLogin } = useAuthReduxHandlers();
  const { setNavbar, width, handleMobileTap, dismissKeypad, isMobileDevice } =
    useSettings();
  const { Auth } = useLanguageAuth();
  const { settingsPersist, settingsLoginEmail, handleSettingsSetLoginEmail } =
    useSettingsHandlers();

  //Component state
  const pwdRef = useRef();
  const emailRef = useRef();
  const [pwd, setPwd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [localStorageEmail, setLocalStorageEmail] = useState();

  //UI state
  const [tapHighlightForgotPassword, setTapHighlightForgotPassword] =
    useState(false);
  const [tapHighlightSignUp, setTapHighlightSignUp] = useState(false);

  //Component variables
  const handlePwdInput = (e) => setPwd(e.target.value);

  //Initialize & UI functions
  useEffect(() => {
    setNavbar('login');
    const email = JSON.parse(localStorage.getItem('email'));
    if (email) {
      setLocalStorageEmail(email);
      handleSettingsSetLoginEmail(email);
    }
  }, []);

  useEffect(() => {
    if (!isMobileDevice) {
      if (settingsLoginEmail?.length > 0) {
        pwdRef.current.focus();
      }
    }
  }, [isMobileDevice]);

  useEffect(() => {
    if (!settingsPersist) {
      handleSettingsSetLoginEmail('');
    }
  }, [settingsPersist]);

  //Component functions
  async function login(e) {
    e.preventDefault();
    dismissKeypad(e);

    if (pwd?.length === 0)
      return handleError({
        id: Date.now(),
        origin: 'Login.js/Login',
        message: 'incompleteInformation',
      });

    setIsLoading(true);
    if (settingsPersist) {
      localStorage.setItem('email', JSON.stringify(settingsLoginEmail));
    }
    await handleLogin(settingsLoginEmail, pwd, setPwd);
    setIsLoading(false);
  }

  return (
    <div className="layout">
      <div className="login bg-blur">
        <div
          className="form-template access-ob access-o-6"
          tabIndex="0"
          aria-label={Auth.signInWithEmail}
        >
          <h1 className="content-label mrg-auto-left mrg-auto-right mrg-t12--ph">
            {Auth.signInWithEmail}
          </h1>
          <div className="h36-ph24" />
          <form
            onSubmit={(e) => {
              setIsLoading(true);
              handleLogin(e, settingsLoginEmail, pwd, setPwd);
              setIsLoading(false);
            }}
            aria-label={Auth.signIn}
          >
            <input
              type="email"
              id="email"
              ref={emailRef}
              defaultValue={localStorageEmail || settingsLoginEmail}
              onChange={(e) => {
                e.preventDefault();
                handleSettingsSetLoginEmail(e.target.value);
              }}
              autoComplete="off"
              placeholder={Auth.emailAddress}
              required
              className="login-input fs21"
              tabIndex="0"
              maxLength="255"
              enterKeyHint="next"
            />
            <div className="h24" />
            <input
              type="password"
              id="password"
              ref={pwdRef}
              onChange={(e) => {
                e.preventDefault();
                handlePwdInput(e);
              }}
              value={pwd}
              placeholder={Auth.password}
              autoComplete="on"
              required
              className="login-input fs21"
              tabIndex="0"
              maxLength="255"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  login(e);
                }
              }}
              enterKeyHint="enter"
            />

            <div className="h36" />
            {isLoading ? (
              <Spinner maxHeight={width > 480 ? '64px' : '54px'} />
            ) : (
              <div className="login-btn__wrapper">
                <button
                  className="login-btn fs24 fwn color-white ff2 access-ob access-o6"
                  id="signin"
                  tabIndex="0"
                  aria-label={`${
                    !settingsLoginEmail && `${Auth.legend.emailRequired}`
                  } ${!pwd && `${Auth.legend.passwordRequired}`} ${
                    settingsLoginEmail && pwd && `${Auth.legend.publicDevice}`
                  }`}
                  onClick={(e) => login(e)}
                >
                  {Auth.signIn}
                </button>
              </div>
            )}
          </form>
          <div className="h24" />
          <div className="login-additional ">
            <button
              className={`flex-center color-gray fs18 fwsb highlight-it-theme mrg-b24 ${
                tapHighlightSignUp ? 'highlight-it-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightSignUp(true),
                    () => setTapHighlightSignUp(false),
                  ],
                  [() => navigate('/register')]
                )
              }
            >
              {Auth.dontHaveAnAccount}
            </button>
            <button
              className={`flex-center color-gray fs18 fwsb highlight-it-theme ${
                tapHighlightForgotPassword ? 'highlight-it-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightForgotPassword(true),
                    () => setTapHighlightForgotPassword(false),
                  ],
                  [() => navigate('/forgotpassword')]
                )
              }
            >
              {Auth.forgotPassword}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
