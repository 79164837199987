import { useEffect, useState } from 'react';

//Hooks
import useReCaptcha from '../../hooks/useReCaptcha';
import useLanguageComponents from '../../language/useLanguageComponents';
import { useLazyOrganizerAccountSearchQuery } from '../../features/main/account/accountApiSlice';
import useSettings from '../../context/useSettings';

//Components
import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';

//Utility
import { search_dark } from '../../assets/icons';

//need to add languge + aria
//add recaptcha test

const SearchNetworkInput = ({
  accountId,
  handleSelection,
  accountFollowing,
  accountFollowingGroups,
}) => {
  //Hooks
  const { SearchNetworkInput: languageSearchNetworkInput } =
    useLanguageComponents();
  const { dismissKeypad } = useSettings();
  const [searchOrganizerName] = useLazyOrganizerAccountSearchQuery();
  const { handleReCaptchaVerify } = useReCaptcha();

  //Component state
  const [searchInput, setSearchInput] = useState();
  const [queryResults, setQueryResults] = useState([]);
  const [noMatches, setNoMatches] = useState(false);

  const [initialAccountFollowing, setInitialAccountFollowing] = useState();

  //UI state
  const [selectionIndex, setSelectionIndex] = useState(0);
  const [dropDownCounter, setDropDownCounter] = useState(0);
  const [preventReset, setPreventReset] = useState(false);

  //Component variables
  const inputElement = document.getElementById('search-network-input');
  let dropdownList;
  let selectAccountObj;

  //Initialize functions
  useEffect(() => {
    let initialIds = [];
    accountFollowing.map((account) => initialIds.push(account._id));

    accountFollowingGroups.map((accountGroup) =>
      accountGroup.following.map((account) => initialIds.push(account._id))
    );

    setInitialAccountFollowing(initialIds);
  }, [accountFollowing, accountFollowingGroups]);

  //API functions
  async function handleInput(e) {
    if (e.key === 'Enter') dismissKeypad(e);

    const recaptchaVerified = await handleReCaptchaVerify(
      'ACCOUNT_SEARCH_ORGANIZERS',
      false
    );

    if (recaptchaVerified !== true) return false;

    if (e.target.value !== searchInput) {
      setSearchInput(e.target.value);
      if (e.target.value?.length > 0) {
        const res = await searchOrganizerName({
          accountId,
          organizerName: e.target.value,
          unsavedIdsArr: initialAccountFollowing,
        });

        if (res.data.status === 'success') {
          if (res.data.data?.length > 0) {
            setQueryResults(res.data.data);
          } else {
            setQueryResults([]);
            setNoMatches(true);
          }
        }
      }
    }

    if (!e.target.value) {
      setQueryResults([]);
      setNoMatches(false);
    }
  }

  //Component functions
  function handleItemInput(e) {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (selectAccountObj) {
        selectionClick(selectAccountObj, e);
      }
      handleReset(e);
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (selectionIndex < dropDownCounter - 1) {
        setSelectionIndex(selectionIndex + 1);
      }
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (selectionIndex > 0) {
        setSelectionIndex(selectionIndex - 1);
      }
    }
  } //for dropdown menu

  function selectionClick(accountObj, e) {
    handleReset(e);
    handleSelection(accountObj);
  }

  function handleBlur(e) {
    if (preventReset) {
      return null;
    }
    handleReset(e);
  }

  function handleReset(e) {
    setSearchInput();
    setQueryResults([]);
    setSelectionIndex(0);
    setDropDownCounter(0);
    if (noMatches) {
      setNoMatches(false);
    }
    dismissKeypad(e);
    inputElement.value = '';
  }

  //JSX conditional
  if (queryResults?.length > 0) {
    dropdownList = (
      <ul className="search-db-dropdown">
        {queryResults?.map((accountObj, index) => {
          if (index === selectionIndex) {
            selectAccountObj = accountObj;
          }

          if (dropDownCounter <= index) {
            setDropDownCounter(dropDownCounter + 1);
          }

          return (
            <li
              key={`networkQuery-${index}`}
              className="search-db-dropdown-item"
              onMouseEnter={() => {
                setPreventReset(true);
                setSelectionIndex(index);
              }}
              onMouseLeave={() => setPreventReset(false)}
            >
              <button
                className={`search-result fs16 fwsb ${
                  selectionIndex === index ? 'highlight' : ''
                } access-ob`}
                onClick={(e) => selectionClick(accountObj, e)}
                tabIndex="0"
              >
                <LoadBucketImage
                  imagePath={accountObj.personal?.profileImage}
                  imgClassName="tag-profile-img tag-profile-network mrg-r6"
                  backupPath={'account_img_default.png'}
                  partialPath={'myndfull_account_images'}
                />
                {accountObj.personal.firstName} {accountObj.personal.lastName}
              </button>
            </li>
          );
        })}
      </ul>
    );
  } else if (noMatches) {
    dropdownList = (
      <ul className="search-db-dropdown" onClick={(e) => handleReset(e)}>
        <li className="search-db-dropdown-item">
          <p className="search-result flex-row flex-center fs16 fwsb access-ot">
            {languageSearchNetworkInput.noMatches}
          </p>
        </li>
      </ul>
    );
  }

  //jsx list "no matches if query ran"
  return (
    <>
      <div
        className={`search-db-input no-select mrg-b24 ${
          queryResults?.length > 0 || noMatches ? 'search-db-input--active' : ''
        }`}
      >
        <img
          src={search_dark}
          alt="search-icon"
          className="search-icon filter-lightgray"
        />
        <input
          autoComplete="off"
          id="search-network-input"
          className="fs18 fwn"
          maxLength="120"
          type="text"
          onChange={(e) => handleInput(e)}
          aria-label={languageSearchNetworkInput.ariaInstructions}
          enterKeyHint="search"
          onKeyDown={(e) => handleItemInput(e)}
          onBlur={(e) => handleBlur(e)}
        />
        {dropdownList}
      </div>
    </>
  );
};

export default SearchNetworkInput;
