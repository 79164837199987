import { apiSlice } from '../../../../app/api/apiSlice';
import { addReduxApiStatus } from '../../../main/apiStatus/apiStatusSlice';
import {
  setAdminSupportEventVerifications,
  setAdminSupportEventVerification,
} from '../adminSupportSlice';

export const adminSupportEventVerificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEventVerifications: builder.query({
      query: ({ adminAccountId }) => {
        return {
          url: '/api/admin/support/eventVerification/getAll',
          params: {
            adminAccountId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setAdminSupportEventVerifications(data?.eventVerifications));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('adminSupportApiSlice/ getEventVerifications:', error);
          }
        }
      },
      providesTags: ['EventVerification'],
    }),
    getOneEventVerification: builder.query({
      query: ({ adminAccountId, eventVerificationId }) => {
        return {
          url: '/api/admin/support/eventVerification/getOne',
          params: {
            adminAccountId,
            eventVerificationId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setAdminSupportEventVerification(data?.eventVerification));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('adminSupportApiSlice/ getEventVerifications:', error);
          }
        }
      },
      providesTags: ['EventVerification'],
    }),
    approveEventVerification: builder.mutation({
      query: ({
        adminAccountId,
        eventVerificationId,
        notes,
        adminReference,
      }) => ({
        url: `/api/admin/support/eventVerification/approve`,
        method: 'PATCH',
        body: { adminAccountId, eventVerificationId, notes, adminReference },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.error) {
            throw data.error;
          }
          if (data.data?.requiresDeletion === true) {
            return;
          } else {
            const success = {
              id: Date.now(),
              status: 'success',
              origin: 'adminSupportApiSlice/approveEventVerification',
            };
            return dispatch(addReduxApiStatus(success));
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/approveEventVerification',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    disapproveEventVerification: builder.mutation({
      query: ({
        adminAccountId,
        eventVerificationId,
        adminReference,
        ticketDetailedDescription,
      }) => ({
        url: `/api/admin/support/eventVerification/disapprove`,
        method: 'PATCH',
        body: {
          adminAccountId,
          eventVerificationId,
          adminReference,
          ticketDetailedDescription,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/disapproveEventVerification',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    createInactiveEventVerification: builder.mutation({
      query: ({ adminAccountId, eventVerificationId }) => ({
        url: `/api/admin/support/eventVerification/createInactive/${eventVerificationId}`,
        method: 'POST',
        body: {
          adminAccountId,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/createInactiveEventVerification',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeEventVerification: builder.mutation({
      query: ({ adminAccountId, eventVerificationId }) => ({
        url: `/api/admin/support/eventVerification/${eventVerificationId}`,
        method: 'DELETE',
        body: {
          adminAccountId,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.error) {
            throw data.error;
          }
          const success = {
            id: Date.now(),
            status: 'success',
            origin: 'adminSupportApiSlice/removeEventVerification',
          };
          return dispatch(addReduxApiStatus(success));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminSupportApiSlice/disapproveEventVerification',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
  }),
});

export const {
  useGetEventVerificationsQuery,
  useLazyGetEventVerificationsQuery,
  useApproveEventVerificationMutation,
  useDisapproveEventVerificationMutation,
  useRemoveEventVerificationMutation,
  useCreateInactiveEventVerificationMutation,
  useLazyGetOneEventVerificationQuery,
} = adminSupportEventVerificationApiSlice;
