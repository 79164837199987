import { useDispatch, useSelector } from 'react-redux';

import {
  //Generic
  selectSettingsAccountIdVerification,
  selectSettingsLastRoomCode,
  selectSettingsSetViewEventDetails,
  settingsReset,
  settingsSetAccountIdVerification,
  settingsSetLastRoomCode,
  settingsSetViewEventDetails,

  //Account
  selectSettingsAccountSettingsView,
  settingsSetAccountSettingsView,

  //Searches - general
  selectSettingsEventSearchCategory,
  selectSettingsEventSearchDirection,
  selectSettingsEventSearchFormat,
  settingsSetEventSearchCategory,
  settingsSetEventSearchDirection,
  settingsSetEventSearchFormat,
  selectSettingsEventSearchView,
  settingsSetEventSearchView,

  //Searches - big tags
  selectSettingsBigTagsListOpened,
  selectSettingsBTQueryHasMore,
  selectSettingsBTQueryPage,
  selectSettingsSelectAllBTBtn,
  selectSettingsSelectedBigTags,
  settingsAddSelectedBigTags,
  settingsRemoveAllSelectedBigTags,
  settingsRemoveSelectedBigTags,
  settingsSetBigTagsListOpened,
  settingsSetBTQueryHasMore,
  settingsSetBTQueryPage,
  settingsSetSelectAllAccountsFollowingBtn,
  settingsSetSelectAllBTBtn,
  settingsSetSelectedBigTags,

  //Searches - my events
  selectSettingsMyEventsListOpened,
  selectSettingsMyEventsView,
  settingsSetMyEventsListOpened,
  settingsSetMyEventsView,

  //Searches - network
  selectSettingsAccountsFollowingListOpened,
  selectSettingsNetworkQueryHasMore,
  selectSettingsNetworkQueryPage,
  selectSettingsSelectAllAccountsFollowingBtn,
  selectSettingsSelectedAccountsFollowingIds,
  settingsAddSelectedAccountsFollowingId,
  settingsRemoveAllSelectedAccountsFollowingIds,
  settingsRemoveSelectedAccountsFollowingId,
  settingsSetAccountsFollowingListOpened,
  settingsSetNetworkQueryHasMore,
  settingsSetNetworkQueryPage,
  settingsSetSelectedAccountsFollowingIds,

  //Searches - network groups
  selectSettingsSelectedAccountsFollowingGroupIds,
  settingsAddSelectedAccountsFollowingGroupIds,
  settingsRemoveAllSelectedAccountsFollowingGroupIds,
  settingsRemoveSelectedAccountsFollowingGroupIds,
  settingsSetSelectedAccountsFollowingGroupIds,

  //Searches - tiny tags
  selectSettingsSelectAllTTBtn,
  selectSettingsSelectedTinyTags,
  selectSettingsTinyTagsListOpened,
  selectSettingsTTQueryHasMore,
  selectSettingsTTQueryPage,
  settingsAddSelectedTinyTags,
  settingsRemoveAllSelectedTinyTags,
  settingsRemoveSelectedTinyTags,
  settingsSetSelectAllTTBtn,
  settingsSetSelectedTinyTags,
  settingsSetTinyTagsListOpened,
  settingsSetTTQueryHasMore,
  settingsSetTTQueryPage,

  //Searches - tiny tag groups
  selectSettingsSelectedTinyTagGroupIds,
  settingsAddSelectedTinyTagGroupIds,
  settingsRemoveAllSelectedTinyTagGroupIds,
  settingsRemoveSelectedTinyTagGroupIds,
  settingsSetSelectedTinyTagGroupIds,

  //Organize
  selectSettingsOrganizeView,
  selectSettingsOrganizeOverviewSortCategory,
  selectSettingsOrganizeOverviewDirection,
  selectSettingsOrganizeOverviewFormat,
  selectSettingsOrganizeSelectedOrganizers,
  selectSettingsOrganizeSelectAllOrganizersBtn,
  selectSettingsOrganizeOverviewListOpened,
  settingsSetOrganizeView,
  settingsSetOrganizeOverviewSortCategory,
  settingsSetOrganizeOverviewDirection,
  settingsSetOrganizeOverviewFormat,
  settingsSetSelectAllOrganizersBtn,
  settingsSetOrganizeOverviewListOpened,
  settingsAddSelectedOrganizer,
  settingsRemoveSelectedOrganizer,
  settingsSetSelectedOrganizers,
  selectSettingsOrganizeOverviewContent,
  settingsSetOrganizeOverviewContent,
  settingsSetStandaloneGridEvents,
  selectSettingsOrganizeOverviewStandaloneGridEvents,
  settingsSetPersist,
  selectSettingsPersist,
  settingsSetLoginEmail,
  selectSettingsLoginEmail,
} from './settingsSlice';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import { useAdminAccountReduxHandlers } from '../../admin/account/useAdminAccountHandlers';

export const useSettingsHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const {
    accountId,
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountFollowing,
    accountsFollowing,
  } = useAccountReduxHandlers();
  const { handleAdminAccountEmptyAdminAccountData } =
    useAdminAccountReduxHandlers();

  //Generic
  const settingsAccountIdVerification = useSelector(
    selectSettingsAccountIdVerification
  );
  const settingsViewEventDetails = useSelector(
    selectSettingsSetViewEventDetails
  );
  const settingsLastRoomCode = useSelector(selectSettingsLastRoomCode);

  //Account
  const settingsAccountSettingsView = useSelector(
    selectSettingsAccountSettingsView
  );
  const settingsPersist = useSelector(selectSettingsPersist);

  const settingsLoginEmail = useSelector(selectSettingsLoginEmail);

  //Searches - General
  const settingsEventSearchDirection = useSelector(
    selectSettingsEventSearchDirection
  );
  const settingsEventSearchCategory = useSelector(
    selectSettingsEventSearchCategory
  );
  const settingsEventSearchFormat = useSelector(
    selectSettingsEventSearchFormat
  );

  const settingsEventSearchView = useSelector(selectSettingsEventSearchView);

  //Searches - Big Tags
  const settingsSelectedBigTags = useSelector(selectSettingsSelectedBigTags);

  const settingsBigTagsListOpened = useSelector(
    selectSettingsBigTagsListOpened
  );

  const settingsBTQueryHasMore = useSelector(selectSettingsBTQueryHasMore);

  const settingsBTQueryPage = useSelector(selectSettingsBTQueryPage);

  const settingsSelectAllBTBtn = useSelector(selectSettingsSelectAllBTBtn);

  //Searches - myEvents
  const settingsMyEventsListOpened = useSelector(
    selectSettingsMyEventsListOpened
  );

  const settingsMyEventsView = useSelector(selectSettingsMyEventsView);

  //Searches - Network
  const settingsSelectedAccountsFollowingIds = useSelector(
    selectSettingsSelectedAccountsFollowingIds
  );

  const settingsAccountsFollowingListOpened = useSelector(
    selectSettingsAccountsFollowingListOpened
  );

  const settingsSelectAllAccountsFollowingBtn = useSelector(
    selectSettingsSelectAllAccountsFollowingBtn
  );

  const settingsSelectedAccountsFollowingGroupIds = useSelector(
    selectSettingsSelectedAccountsFollowingGroupIds
  );

  const settingsNetworkQueryHasMore = useSelector(
    selectSettingsNetworkQueryHasMore
  );

  const settingsNetworkQueryPage = useSelector(selectSettingsNetworkQueryPage);

  //Searches - Tiny Tags
  const settingsSelectAllTTBtn = useSelector(selectSettingsSelectAllTTBtn);

  const settingsSelectedTinyTagGroupIds = useSelector(
    selectSettingsSelectedTinyTagGroupIds
  );

  const settingsSelectedTinyTags = useSelector(selectSettingsSelectedTinyTags);

  const settingsTinyTagsListOpened = useSelector(
    selectSettingsTinyTagsListOpened
  );

  const settingsTTQueryHasMore = useSelector(selectSettingsTTQueryHasMore);

  const settingsTTQueryPage = useSelector(selectSettingsTTQueryPage);

  //Organize

  const settingsOrganizeView = useSelector(selectSettingsOrganizeView);

  const settingsOrganizeOverviewSortCategory = useSelector(
    selectSettingsOrganizeOverviewSortCategory
  );
  const settingsOrganizeOverviewDirection = useSelector(
    selectSettingsOrganizeOverviewDirection
  );
  const settingsOrganizeOverviewFormat = useSelector(
    selectSettingsOrganizeOverviewFormat
  );
  const settingsOrganizeSelectedOrganizers = useSelector(
    selectSettingsOrganizeSelectedOrganizers
  );
  const settingsOrganizeSelectAllOrganizersBtn = useSelector(
    selectSettingsOrganizeSelectAllOrganizersBtn
  );
  const settingsOrganizeStandaloneGridEvents = useSelector(
    selectSettingsOrganizeOverviewStandaloneGridEvents
  );

  const settingsOrganizeOverviewListOpened = useSelector(
    selectSettingsOrganizeOverviewListOpened
  );
  const settingsOrganizeOverviewContent = useSelector(
    selectSettingsOrganizeOverviewContent
  );

  //Generic
  function handleSettingsSetAccountIdVerification(str) {
    dispatch(settingsSetAccountIdVerification(str));
  }

  function handleSettingsSetLastRoomCode(str) {
    dispatch(settingsSetLastRoomCode(str));
  }
  function handleSettingsReset() {
    dispatch(settingsReset());
  }

  function handleSettingsSetPersist(boolean) {
    dispatch(settingsSetPersist(boolean));
  }

  function handleSettingsSetLoginEmail(str) {
    dispatch(settingsSetLoginEmail(str));
  }

  function handleSettingsSetViewEventDetails(obj) {
    dispatch(settingsSetViewEventDetails(obj));
  }

  //Account
  function handleSettingsSetAccountSettingsView(str) {
    dispatch(settingsSetAccountSettingsView(str));
  }

  //Searches general
  function handleSettingsSetEventSearchCategory(str) {
    dispatch(settingsSetEventSearchCategory(str));
  }
  function handleSettingsSetEventSearchDirection(str) {
    dispatch(settingsSetEventSearchDirection(str));
  }
  function handleSettingsSetEventSearchFormat(str) {
    dispatch(settingsSetEventSearchFormat(str));
  }
  function handleSettingsSetEventView(str) {
    dispatch(settingsSetEventSearchView(str));
  }

  //Searches bigTags
  function handleSettingsSetBTQueryHasMore(boolean) {
    dispatch(settingsSetBTQueryHasMore(boolean));
  }
  function handleSettingsSetBTQueryPage(num) {
    dispatch(settingsSetBTQueryPage(num));
  }
  function handleSettingsSetBigTagsListOpened(boolean) {
    dispatch(settingsSetBigTagsListOpened(boolean));
  }
  function handleSettingsSetSelectAllBTBtn(boolean) {
    dispatch(settingsSetSelectAllBTBtn(boolean));
  }
  function handleSettingsAddSelectedBigTags(arr) {
    dispatch(settingsAddSelectedBigTags(arr));
  }
  function handleSettingsRemoveAllSelectedBigTags() {
    dispatch(settingsRemoveAllSelectedBigTags());
  }
  function handleSettingsRemoveSelectedBigTags(str) {
    dispatch(settingsRemoveSelectedBigTags(str));
  }
  function handleSettingsSetSelectedBigTags(arr) {
    dispatch(settingsSetSelectedBigTags(arr));
  }

  //Searches My Events
  function handleSettingsSetMyEventsListOpened(boolean) {
    dispatch(settingsSetMyEventsListOpened(boolean));
  }
  function handleSettingsSetMyEventsView(str) {
    dispatch(settingsSetMyEventsView(str));
  }

  //Searches Network
  function handleSettingsSetSelectAllAccountsFollowingBtn(boolean) {
    dispatch(settingsSetSelectAllAccountsFollowingBtn(boolean));
  }
  function handleSettingsSetAccountsFollowingListOpened(boolean) {
    dispatch(settingsSetAccountsFollowingListOpened(boolean));
  }
  function handleSettingsSetSelectedAccountsFollowingIds(arr) {
    dispatch(settingsSetSelectedAccountsFollowingIds(arr));
  }
  function handleSettingsAddSelectedAccountsFollowingId(str) {
    dispatch(settingsAddSelectedAccountsFollowingId(str));
  }
  function handleSettingsRemoveAllSelectedAccountsFollowingIds() {
    dispatch(settingsRemoveAllSelectedAccountsFollowingIds());
  }
  function handleSettingsRemoveSelectedAccountsFollowingId(str) {
    dispatch(settingsRemoveSelectedAccountsFollowingId(str));
  }

  function handleSettingsSetNetworkQueryHasMore(boolean) {
    dispatch(settingsSetNetworkQueryHasMore(boolean));
  }
  function handleSettingsSetNetworkQueryPage(num) {
    dispatch(settingsSetNetworkQueryPage(num));
  }

  //Searches - Network Groups
  function handleSettingsRemoveSelectedAccountsFollowingGroupIds(str) {
    dispatch(settingsRemoveSelectedAccountsFollowingGroupIds(str));
  }
  function handleSettingsAddSelectedAccountsFollowingGroupIds(arr) {
    dispatch(settingsAddSelectedAccountsFollowingGroupIds(arr));
  }
  function handleSettingsRemoveAllSelectedAccountsFollowingGroupIds() {
    dispatch(settingsRemoveAllSelectedAccountsFollowingGroupIds());
  }
  function handleSettingsSetSelectedAccountsFollowingGroupIds(arr) {
    dispatch(settingsSetSelectedAccountsFollowingGroupIds(arr));
  }

  //Searches Tiny Tags
  function handleSettingsSetTTQueryHasMore(boolean) {
    dispatch(settingsSetTTQueryHasMore(boolean));
  }
  function handleSettingsSetTTQueryPage(num) {
    dispatch(settingsSetTTQueryPage(num));
  }
  function handleSettingsSetTinyTagsListOpened(boolean) {
    dispatch(settingsSetTinyTagsListOpened(boolean));
  }
  function handleSettingsSetSelectAllTTBtn(boolean) {
    dispatch(settingsSetSelectAllTTBtn(boolean));
  }

  function handleSettingsAddSelectedTinyTags(str) {
    dispatch(settingsAddSelectedTinyTags(str));
  }
  function handleSettingsRemoveSelectedTinyTags(str) {
    dispatch(settingsRemoveSelectedTinyTags(str));
  }
  function handleSettingsRemoveAllSelectedTinyTags() {
    dispatch(settingsRemoveAllSelectedTinyTags());
  }
  function handleSettingsSetSelectedTinyTags(arr) {
    dispatch(settingsSetSelectedTinyTags(arr));
  }

  //Tiny tag groups
  function handleSettingsRemoveSelectedTinyTagGroupIds(str) {
    dispatch(settingsRemoveSelectedTinyTagGroupIds(str));
  }
  function handleSettingsAddSelectedTinyTagGroupIds(arr) {
    dispatch(settingsAddSelectedTinyTagGroupIds(arr));
  }
  function handleSettingsRemoveAllSelectedTinyTagGroupIds() {
    dispatch(settingsRemoveAllSelectedTinyTagGroupIds());
  }
  function handleSettingsSetSelectedTinyTagGroupIds(arr) {
    dispatch(settingsSetSelectedTinyTagGroupIds(arr));
  }

  //Organize
  function handleSettingsSetOrganizeView(str) {
    dispatch(settingsSetOrganizeView(str));
  }
  function handleSettingsSetOrganizeOverviewSortCategory(str) {
    dispatch(settingsSetOrganizeOverviewSortCategory(str));
  }
  function handleSettingsSetOrganizeOverviewDirection(str) {
    dispatch(settingsSetOrganizeOverviewDirection(str));
  }
  function handleSettingsSetOrganizeOverviewFormat(str) {
    dispatch(settingsSetOrganizeOverviewFormat(str));
  }
  function handleSettingsSetOrganizeOverviewListOpened(boolean) {
    dispatch(settingsSetOrganizeOverviewListOpened(boolean));
  }
  function handleSettingsSetSelectAllOrganizersBtn(boolean) {
    dispatch(settingsSetSelectAllOrganizersBtn(boolean));
  }
  function handleSettingsAddSelectedOrganizer(str) {
    dispatch(settingsAddSelectedOrganizer(str));
  }
  function handleSettingsRemoveSelectedOrganizer(str) {
    dispatch(settingsRemoveSelectedOrganizer(str));
  }
  function handleSettingsSetSelectedOrganizers(arr) {
    dispatch(settingsSetSelectedOrganizers(arr));
  }
  function handleSettingsSetOrganizeOverviewStandaloneGridEvents(str) {
    dispatch(settingsSetStandaloneGridEvents(str));
  }

  function handleSettingsSetOrganizeOverviewContent(str) {
    dispatch(settingsSetOrganizeOverviewContent(str));
  }

  //ensure that before this handler is called, the accountId and accountIdVerification do not match
  function handleSettingsResetRedux(acctId) {
    //clear settings Redux for different user
    handleSettingsReset();
    handleSettingsSetAccountIdVerification(acctId || accountId);

    if (accountBigTags?.length > 0) {
      handleSettingsSetSelectAllBTBtn(true);
      handleSettingsSetSelectedBigTags(accountBigTags);
    }

    if (accountTinyTags?.length > 0) {
      handleSettingsSetSelectAllTTBtn(true);
      handleSettingsSetSelectedTinyTags(accountTinyTags);
    }

    if (accountTinyTagGroups?.length > 0) {
      handleSettingsSetSelectedTinyTagGroupIds(accountTinyTagGroups);
    }

    if (accountFollowing?.length > 0) {
      handleSettingsSetSelectAllAccountsFollowingBtn(true);
      handleSettingsSetSelectedAccountsFollowingIds(accountsFollowing);
    }
  }

  function handleSettingsAdminResetRedux() {
    //clear settings Redux for different user
    handleAdminAccountEmptyAdminAccountData();
  }

  return {
    handleSettingsSetLastRoomCode,
    handleSettingsAddSelectedAccountsFollowingGroupIds,
    handleSettingsAddSelectedAccountsFollowingId,
    handleSettingsAddSelectedBigTags,
    handleSettingsAddSelectedTinyTagGroupIds,
    handleSettingsAddSelectedTinyTags,
    handleSettingsRemoveAllSelectedAccountsFollowingGroupIds,
    handleSettingsRemoveAllSelectedAccountsFollowingIds,
    handleSettingsRemoveAllSelectedBigTags,
    handleSettingsRemoveAllSelectedTinyTagGroupIds,
    handleSettingsRemoveAllSelectedTinyTags,
    handleSettingsRemoveSelectedAccountsFollowingGroupIds,
    handleSettingsRemoveSelectedAccountsFollowingId,
    handleSettingsRemoveSelectedBigTags,
    handleSettingsRemoveSelectedTinyTagGroupIds,
    handleSettingsRemoveSelectedTinyTags,
    handleSettingsReset,
    handleSettingsResetRedux,
    handleSettingsSetAccountIdVerification,
    handleSettingsSetAccountSettingsView,
    handleSettingsSetAccountsFollowingListOpened,
    handleSettingsSetBigTagsListOpened,
    handleSettingsSetBTQueryHasMore,
    handleSettingsSetBTQueryPage,
    handleSettingsSetEventSearchCategory,
    handleSettingsSetEventSearchDirection,
    handleSettingsSetEventSearchFormat,
    handleSettingsSetEventView,
    handleSettingsSetMyEventsListOpened,
    handleSettingsSetMyEventsView,
    handleSettingsSetNetworkQueryHasMore,
    handleSettingsSetNetworkQueryPage,
    handleSettingsSetSelectAllAccountsFollowingBtn,
    handleSettingsSetSelectAllBTBtn,
    handleSettingsSetSelectAllTTBtn,
    handleSettingsSetSelectedAccountsFollowingGroupIds,
    handleSettingsSetSelectedAccountsFollowingIds,
    handleSettingsSetSelectedBigTags,
    handleSettingsSetSelectedTinyTagGroupIds,
    handleSettingsSetSelectedTinyTags,
    handleSettingsSetTinyTagsListOpened,
    handleSettingsSetTTQueryHasMore,
    handleSettingsSetTTQueryPage,
    settingsLastRoomCode,
    settingsAccountIdVerification,
    settingsAccountSettingsView,
    settingsAccountsFollowingListOpened,
    settingsBigTagsListOpened,
    settingsBTQueryHasMore,
    settingsBTQueryPage,
    settingsEventSearchCategory,
    settingsEventSearchDirection,
    settingsEventSearchFormat,
    settingsEventSearchView,
    settingsMyEventsListOpened,
    settingsMyEventsView,
    settingsNetworkQueryHasMore,
    settingsNetworkQueryPage,
    settingsSelectAllAccountsFollowingBtn,
    settingsSelectAllBTBtn,
    settingsSelectAllTTBtn,
    settingsSelectedAccountsFollowingGroupIds,
    settingsSelectedAccountsFollowingIds,
    settingsSelectedBigTags,
    settingsSelectedTinyTagGroupIds,
    settingsSelectedTinyTags,
    settingsTinyTagsListOpened,
    settingsTTQueryHasMore,
    settingsTTQueryPage,
    settingsOrganizeView,
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeOverviewDirection,
    settingsOrganizeOverviewFormat,
    settingsOrganizeSelectedOrganizers,
    settingsOrganizeSelectAllOrganizersBtn,
    settingsOrganizeOverviewListOpened,
    handleSettingsSetOrganizeView,
    handleSettingsSetOrganizeOverviewSortCategory,
    handleSettingsSetOrganizeOverviewDirection,
    handleSettingsSetOrganizeOverviewFormat,
    handleSettingsSetOrganizeOverviewListOpened,
    handleSettingsSetSelectAllOrganizersBtn,
    handleSettingsAddSelectedOrganizer,
    handleSettingsRemoveSelectedOrganizer,
    handleSettingsSetSelectedOrganizers,
    settingsOrganizeOverviewContent,
    handleSettingsSetOrganizeOverviewContent,
    settingsOrganizeStandaloneGridEvents,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
    handleSettingsAdminResetRedux,
    handleSettingsSetPersist,
    settingsPersist,
    settingsLoginEmail,
    handleSettingsSetLoginEmail,
    settingsViewEventDetails,
    handleSettingsSetViewEventDetails,
  };
};
