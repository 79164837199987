//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useApiStatus from '../../context/useApiStatus';
import useSettings from '../../context/useSettings';
//Components

//Utility
import { location } from '../../assets/icons';

const AutocompletePlacesNoAddress = ({ setAutocompleteCoordinates }) => {
  //Hooks
  const { handleError } = useApiStatus();
  const { AutocompletePlaces: autocompletePlacesTranslation } =
    useLanguageComponents();
  const { dismissKeypad } = useSettings();

  //Component variables
  let autocomplete;

  //Component functions
  function initAutocomplete() {
    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        componentRestrictions: { country: ['ca', 'us'] },
        fields: ['place_id', 'address_components'],
      }
    );
    autocomplete.addListener('place_changed', fillInAddress);
  }

  function fillInAddress() {
    const geocoder = new window.google.maps.Geocoder();
    const place = autocomplete.getPlace();

    if (!place?.place_id) {
      return;
    }

    geocoder.geocode({ placeId: place.place_id }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        let latt = results[0].geometry.location.lat();
        let long = results[0].geometry.location.lng();

        setAutocompleteCoordinates({
          lat: latt,
          lng: long,
        });
      } else {
        handleError(
          {
            message: 'geocodeUnsuccessful',
          },
          'AutocompletePlaces.js/fillinAddress'
        );
      }
    });
  }

  function removePAC() {
    let pac = document.getElementsByClassName('pac-container');
    if (pac.length > 0) {
      pac[0].remove();
    }
  }

  return (
    <div className="autocomplete__wrapper">
      <div>
        <input
          id="autocomplete"
          className="autocomplete-input"
          type="text"
          onKeyDown={(e) => {
            if (e.key === 'Enter') dismissKeypad(e);
          }}
          onFocus={initAutocomplete}
          onBlur={removePAC}
          placeholder={autocompletePlacesTranslation.goToLocation}
          aria-label={
            autocompletePlacesTranslation.eventSearchInputInstructions
          }
          enterKeyHint="search"
        />
        <img className="input-label location" src={location} alt="location" />
      </div>
    </div>
  );
};

export default AutocompletePlacesNoAddress;
