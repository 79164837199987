import { useEffect, useState } from 'react';

//hooks
import useLanguageData from '../../../../../language/useLanguageData';

//components

//utils
import { getRandomColor } from '../../../../../utils/UI/randomColorCodes';

const InfoCardContainerTags = ({ tagsData }) => {
  //hooks
  const { BigTagsOpts } = useLanguageData();
  //UI
  const [handleColors, setHandleColors] = useState([]);

  //initialize
  useEffect(() => {
    let colorArr = [];
    tagsData?.tinyTagGroups?.forEach(() => colorArr.push(getRandomColor()));
    setHandleColors(colorArr);
  }, [tagsData?.tinyTagGroups]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Tags</p>
      <ul className="mrg-t12">
        Big Tags:{' '}
        {tagsData?.bigTags?.map((bT, index) => (
          <li className="mrg-l24" key={`bT-${index}`}>
            {BigTagsOpts[bT]}
          </li>
        ))}
      </ul>

      <ul className="mrg-t12">
        Tiny Tags:{' '}
        {tagsData?.tinyTags?.map((tT, index) => (
          <li className="mrg-l24" key={`tT-${index}`}>
            {tT}
          </li>
        ))}
      </ul>
      <div className="mrg-t12">
        Tiny Tags Groups:{' '}
        {tagsData?.tinyTagGroups?.map((tG, index) => {
          return (
            <ul className="info-card-list" key={`tG-${index}`}>
              <li>
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColors[index] }}
                />
                <p className="underline mrg-b6">{tG.label}</p>

                {tG.tags?.map((tT, index2) => (
                  <p className="" key={`${tG.label}-${index2}`}>
                    {tT}
                  </p>
                ))}
              </li>
            </ul>
          );
        })}
      </div>
    </div>
  );
};

export default InfoCardContainerTags;
