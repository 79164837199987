import { useState } from 'react';

//Hooks
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import useApiStatus from '../../../../../context/useApiStatus';
import { useRegistrationHandlers } from '../../useRegistrationHandlers';

//Components
import CodeInput from '../../../../../components/CodeInput/CodeInput';

//Utility
import { axiosLimited } from '../../../../../axios/axios';

const PersonalAccessCode = () => {
  //Hooks
  const { AccessCode } = useLanguageRegistration();
  const { handleRegistrationSetGenericAccessCode } = useRegistrationHandlers();
  const { handleError } = useApiStatus();
  const { handleRegistrationPageIncrease } = useRegistrationHandlers();

  //UI state
  const [isLoading, setIsLoading] = useState(false);

  async function handleCode(accessCode) {
    try {
      setIsLoading(true);
      await axiosLimited.get(
        `/main/register/softlaunch/accessCodeValidation/${accessCode}`,
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      setIsLoading(false);
      handleRegistrationSetGenericAccessCode(accessCode);
      handleRegistrationPageIncrease();
    } catch (error) {
      const newError = {
        message: error.response?.data?.message || error?.message,
        id: Date.now(),
        origin: 'AccessCodeInput.js/handleAccessCodeValidation',
      };
      setIsLoading(false);
      handleError(newError);
    }
  }

  return (
    <div className="form-container" tabIndex="0">
      <label className="content-label" id="accessCodeLabel">
        {AccessCode.heading}
      </label>

      <div className="h12" />
      <p
        className="fwsb fs18 fs16--phn color-gray access-ob access-o6"
        id="access-code-description"
        tabIndex="0"
      >
        {AccessCode.instructions}
      </p>
      <div className="h24" />

      <div className="flex-center">
        <CodeInput
          handleCodeInput={handleCode}
          codeLength={6}
          ariaDescription={AccessCode.instructions}
          isLoadingExternal={isLoading}
          toUpperCase={false}
        />
      </div>

      <div className="h48-ph24" />
    </div>
  );
};

export default PersonalAccessCode;
