import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../../features/main/auth/authSlice';

let BASE_URL = 'https://myndfull.com';

if (process.env.REACT_APP_ENV === 'development') {
  BASE_URL = process.env.REACT_APP_BASE_URL;
}

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include', //allows http only cookie
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token || getState().adminAuth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    headers.set('client-version', `${process.env.REACT_APP_REDUX_V}`);
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, options) => {
  let result = await baseQuery(args, api, options);

  if (result?.error?.originalStatus === 403) {
    //send refresh token to get new access token
    const refreshResult = await baseQuery('/refresh', api, options);

    if (refreshResult?.data) {
      const email = api.getState().auth.email;
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, email }));
      //retry original query with new access token
      result = await baseQuery(args, api, options);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Account',
    'AdminAccount',
    'AdminAuth',
    'ArchivedEvents',
    'Auth',
    'Draft',
    'Event',
    'NetworkEvent',
    'OrganizerEvent',
    'AdminManagement',
    'AdminManagementLogs',
    'Ticket',
    'EventVerification',
    'TechnicalLogs',
    'TechnicalLogsArchive',
  ],

  keepUnusedDataFor: 60,
  endpoints: (builder) => ({}),
});

export { apiSlice };
