const getImageBlob = async (imagePath, imageType, setImage) => {
  //variables
  let fullImagePath;

  if (
    imagePath === 'myndfull_account_image.png' ||
    imagePath === 'myndfull_event_image.png' ||
    !imagePath
  ) {
    return null;
  }

  if (imageType === 'account') {
    fullImagePath = `https://storage.googleapis.com/myndfull_account_images${
      process.env.REACT_APP_ENV === 'development' ? '_development' : ''
    }/${imagePath}`;
  } else if (imageType === 'event') {
    fullImagePath = `https://storage.googleapis.com/myndfull_event_images${
      process.env.REACT_APP_ENV === 'development' ? '_development' : ''
    }/${imagePath}`;
  }

  if (fullImagePath) {
    try {
      let response = await fetch(fullImagePath);

      if (!response) return null;

      if (response.status !== 200) {
        throw new Error();
      }

      const blob = await response.blob();
      const urlBlob = URL.createObjectURL(blob);

      return setImage({
        imagePreview: urlBlob,
        croppedImage: '',
      });
    } catch (error) {
      return false;
    }
  }

  return;
};

export default getImageBlob;
