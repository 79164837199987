import { useEffect, useRef, useState } from 'react';

//Hooks
import useLanguageData from '../../../../language/useLanguageData';

import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import SelectDropdownMultiple from '../../../../components/SelectDropdown/SelectDropdownMultiple';
import SelectDropdownTimezones from '../../../../components/SelectDropdown/SelectDropdownTimezones';
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import TextArea from '../../../../components/TextArea/TextArea';
import Selections from '../../../../components/Selections/Selections';
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown';
import DragDropList from '../../../../components/DragDropList/DragDropList';

//Utility

import { timesData } from '../../../../data/timesData';
import CalendarSimple from '../../../../components/CalendarSimple/CalendarSimple';

const General = ({
  initialize,
  handleSetTitle,
  handleSetDescription,
  handleSetEventType,
  handleSetAudienceType,
  handleSetDate,
  handleSetTimeZone,
  handleSetTimeStart,
  handleSetTimeEnd,
  handleSetAgendaFormat,
  handleSetAgendaItems,
  handleSetLanguages,
  loadTitle,
  loadDescription,
  loadEventType,
  loadAudienceType,
  loadDate,
  loadLanguages,
  loadTimeZone,
  loadTimeStart,
  loadTimeEnd,
  loadAgendaItems,
  loadAgendaFormat,
}) => {
  //Hooks
  const { CreateEvent } = useLanguageOrganize();
  const { SearchLanguageOpts } = useLanguageData();
  const { AudienceTypes, EventTypes, AudienceTypesInfo, EventTypesInfo } =
    useLanguageData();

  //Component state
  const [newEndTimesData, setNewEndTimesData] = useState();

  //Component variable
  let content;
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (initialize) {
      if (effectRan.current === false) {
        if (loadTimeStart) {
          const filteredTimesData = Object.fromEntries(
            Object.entries(timesData).filter(
              ([timeKey]) => parseInt(timeKey) > loadTimeStart
            )
          );
          setNewEndTimesData(filteredTimesData);
        } //loads end times list without clearing saved end time
        effectRan.current = true;
      }
    }
  }, [initialize]);

  //Component functions

  //uses index of array
  function handleSetEndTimes(selectedStartTime) {
    handleSetTimeEnd(null);
    const filteredTimesData = Object.fromEntries(
      Object.entries(timesData).filter(
        ([timeKey]) => parseInt(timeKey) > selectedStartTime
      )
    );
    setNewEndTimesData(filteredTimesData);
  }

  content = (
    <fieldset className="fieldset" id="fieldset-general">
      <legend id="aria-fieldset-instructions-general">
        {CreateEvent.general.ariaFieldsetInstructions}
      </legend>
      <div className="h0-ph24" />
      <label
        className="label"
        id="generalLabel"
        aria-describedby="aria-fieldset-instructions-general"
        tabIndex="0"
      >
        {CreateEvent.general.general}
      </label>
      <FocusSkip
        skipToLabel={CreateEvent.skipTo.skipToParticipants}
        skipToId={'participantsLabel'}
        topPos={'-24px'}
      />

      <TextArea
        handleChange={handleSetTitle}
        limit={75}
        id={'title'}
        labelText={CreateEvent.general.eventTitle}
        disableEnter={true}
        loadTrigger={initialize}
        loadValue={loadTitle}
        asterisk={true}
      />
      <div className="h24" />
      <TextArea
        handleChange={handleSetDescription}
        limit={3000}
        id={'description'}
        labelText={CreateEvent.general.description}
        large={true}
        loadTrigger={initialize}
        loadValue={loadDescription}
        asterisk={true}
      />
      <div className="h48" />
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="eventTypeLabel"
      >
        {CreateEvent.general.eventType}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <Selections
        id={'eventType'}
        options={EventTypes}
        singleColumn={false}
        handleSelection={handleSetEventType}
        whatsThis={true}
        whatsThisType={CreateEvent.general.eventTypeWhatsThis}
        whatsThisDescriptions={EventTypesInfo}
        loadTrigger={initialize ? true : false}
        loadValue={loadEventType}
        customWrapper={'mrg-t12'}
      />
      <div className="h48" />
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="audienceTypeLabel"
      >
        {CreateEvent.general.audienceType}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <Selections
        id={'audience'}
        options={AudienceTypes}
        singleColumn={true}
        handleSelection={handleSetAudienceType}
        whatsThis={true}
        whatsThisType={CreateEvent.general.audienceTypeWhatsThis}
        whatsThisDescriptions={AudienceTypesInfo}
        loadTrigger={initialize ? true : false}
        loadValue={loadAudienceType}
        customWrapper={'mrg-t12'}
      />
      <div className="h48" />

      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="languageLabel"
      >
        {CreateEvent.general.language}
        <p className="color-theme">&nbsp;*</p>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.general.languageDescription}
      </p>
      <div className="h24" />

      <SelectDropdownMultiple
        list={SearchLanguageOpts}
        id={'searchLanguage'}
        handleSelectionChange={handleSetLanguages}
        selections={loadLanguages}
        addButtonLabel={CreateEvent.general.language}
        modalLabel={CreateEvent.general.language}
      />
      <div className="h48" />

      <div className="flex-row">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="dateTimeLabel"
        >
          {CreateEvent.general.dateAndTime}
          <p className="color-theme">&nbsp;*</p>
        </label>
        <FocusSkip
          skipToLabel={CreateEvent.skip.skipCalendar}
          skipToId={'calendar-sum'}
        />
      </div>
      <div className="h12" />

      <p className="description-text" tabIndex="0">
        {CreateEvent.general.dateAndTimeDescription}
      </p>

      <div className="h12" />

      <CalendarSimple
        handleSetDate={handleSetDate}
        loadDate={loadDate}
        initialize={initialize}
      />

      <div className="h24" />
      <SelectDropdownTimezones
        id={'timezone'}
        handleSelection={handleSetTimeZone}
        defaultValue={initialize && loadTimeZone ? loadTimeZone : null}
      />

      <div className="h24" />
      <SelectDropdown
        id={'startTime'}
        list={timesData}
        handleSelection={handleSetTimeStart}
        handleSelection2={handleSetEndTimes}
        defaultKey={initialize && loadTimeStart ? loadTimeStart : null}
        internalLabel={CreateEvent.general.startTime}
        asterisk={true}
      />

      {/* {newEndTimesData && (
        <>
          <div className="h24" />
          <SelectDropdown
            id={'endTime'}
            list={newEndTimesData}
            handleSelection={handleSetTimeEnd}
            defaultKey={initialize && loadTimeEnd ? loadTimeEnd : null}
            internalLabel={CreateEvent.general.endTime}
            asterisk={true}
          />
        </>
      )} */}
      <div className="h24" />
      <SelectDropdown
        id={'endTime'}
        list={newEndTimesData || timesData}
        handleSelection={handleSetTimeEnd}
        defaultKey={initialize && loadTimeEnd ? loadTimeEnd : null}
        internalLabel={CreateEvent.general.endTime}
        asterisk={true}
        tempDisable={!loadTimeStart}
        temporaryDisableMessage={'selectStartTime'}
      />

      <div className="h48" />
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="agendaLabel"
      >
        {CreateEvent.general.eventAgenda}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.general.eventAgendaDescription}
      </p>
      <div className="h24" />
      <DragDropList
        itemLimit={30}
        handleItems={handleSetAgendaItems}
        handleFormat={handleSetAgendaFormat}
        loadTrigger={initialize}
        loadValueItems={loadAgendaItems}
        loadValueFormat={loadAgendaFormat}
        title={CreateEvent.general.agendaItem}
        itemBtnId={'addAgendaItemBtn'}
        autoScrollId={'agendaLabel'}
      />
    </fieldset>
  );

  return content;
};

export default General;
