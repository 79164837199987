import { useEffect, useCallback, useState } from 'react';

//Hooks
import useLanguageEvents from '../../../language/features/useLanguageEvents';
import useSettings from '../../../context/useSettings';

//Components
import EventCard from './EventCard/EventCard';
import Spinner from '../../../components/Spinner/Spinner';
import NoMoreCard from './NoMoreCard';
import { EventGridSkeleton, GhostCards } from './EventSkeletons';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';

//Utility

//will only show a row if theres enough cards to fill up a full row, dictated by the cards variable
const EventsGrid = ({
  events,
  isFetching,
  isLoading,
  isSuccess,
  cards,
  isUninitialized,
  // setViewEvent,
  nextPageFunction,
  hasMore,
  tagsCheck,
}) => {
  //Hooks
  const { width } = useSettings();
  const { EventsGrid } = useLanguageEvents();
  const { handleSettingsSetViewEventDetails } = useSettingsHandlers();

  //Component state
  const [isDelayLoading, setIsDelayLoading] = useState(false);
  const [infiniteScrollParameters, setInfiniteScrollParameters] = useState(750);

  //Component variables
  let content;
  let remainingContent;

  //Initialize functions

  useEffect(() => {
    if (isUninitialized) {
      setIsDelayLoading(true);
    }
  }, [isUninitialized]);

  //UI functions
  useEffect(() => {
    if (width > 480) {
      setInfiniteScrollParameters(750);
    } else {
      setInfiniteScrollParameters(480);
    }
  }, [width]);

  //Component functions
  useEffect(() => {
    if (isSuccess && isDelayLoading) {
      let timeout1 = null;

      timeout1 = setTimeout(() => {
        setIsDelayLoading(false);
      }, 500);

      return () => {
        if (timeout1 !== null) {
          clearTimeout(timeout1);
        }
      };
    }
  }, [isLoading, isSuccess, isDelayLoading]);

  const handleScroll = useCallback(() => {
    if (
      document.documentElement.scrollHeight -
        (window.innerHeight + document.documentElement.scrollTop) <
      infiniteScrollParameters
    ) {
      if ((!isFetching || !isLoading) && hasMore) {
        nextPageFunction();
      }
    }
    /*eslint-disable-next-line*/
  }, [isFetching, nextPageFunction, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  //JSX conditional
  if (isDelayLoading || !isSuccess) {
    content = <EventGridSkeleton skeletons={cards * 2} />;
  } else if (events?.length > 0) {
    const fullRows = Math.floor(events.length / cards);

    content = (
      <>
        {[...Array(fullRows * cards)].map((_, index) => (
          <EventCard
            eventData={events[index]}
            key={events[index]._id}
            setViewEvent={handleSettingsSetViewEventDetails}
          />
        ))}
      </>
    );

    const remainingEvents = events.slice(fullRows * cards);
    if (!hasMore) {
      remainingContent = (
        <>
          {remainingEvents.map((event) => (
            <EventCard
              eventData={event}
              key={event._id}
              setViewEvent={handleSettingsSetViewEventDetails}
            />
          ))}
          <NoMoreCard />
          {GhostCards(remainingEvents?.length, cards)}
        </>
      );
    }
  } else if (events?.length === 0 && !isFetching) {
    content = <h1>{EventsGrid.noEvents} [Image]</h1>;
  }

  return (
    <div className="events-grid__wrapper">
      <article className="events-grid mrg-t24" id="events-grid">
        {content}
        {remainingContent}
      </article>
      {hasMore &&
      tagsCheck > 0 &&
      (!isFetching || !isLoading) &&
      events?.length > 0 ? (
        <>
          <div className="h48" />
          <div className="flex-row flex-center align-center no-select color-lightgray">
            <div className="loadMore-divider mrg-r24" />
            <p className="no-wrap fs21 fwsb">{EventsGrid.loadMore}</p>
            <div className="loadMore-divider mrg-l24" />
          </div>
        </>
      ) : (
        hasMore && <Spinner />
      )}

      {!hasMore && events?.length > cards * 2 ? (
        <>
          <div className="h48" />
          <ScrollToTop large={true} focusId={'view-myevents'} />
          <div className="h48" />
        </>
      ) : (
        <div className="h96" />
      )}
    </div>
  );
};

export default EventsGrid;
