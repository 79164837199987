export const handleViewTranscript = (transcriptData, ticketReference) => {
  const formattedTranscriptionData = transcriptData.replace(/\n/g, '<br>');
  const transcriptHtml = `
  <html>
    <head>
      <title>Ticket ${ticketReference} | MyndFull</title>
    </head>
    <body>
      <div style="margin: 24px;">
        <p>${formattedTranscriptionData}</p>
      </div>
    </body>
  </html>
`;

  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.write(transcriptHtml);
    newWindow.document.close();
  } else {
    alert('Unable to open a new window. Please check your popup settings.');
  }
};
