import { useState } from 'react';

//hooks
import { useAdminAccountReduxHandlers } from '../../../account/useAdminAccountHandlers';
import { useAdminSupportReduxHandlers } from '../../../support/useAdminSupportHandlers';

//components
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import TextArea from '../../../../../components/TextArea/TextArea';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import Spinner from '../../../../../components/Spinner/Spinner';

//utils
import { edit, preview } from '../../../../../assets/icons';

const InfoCardActions = ({
  idData,
  infoCardType,
  ticketData,
  supportData,
  cardsRowIds,
  setCardsRowIds,
}) => {
  //hooks
  const { adminAccountAdminReference, adminAccountId } =
    useAdminAccountReduxHandlers();
  const {
    handleAcceptAssignment,
    handleRemoveAssignment,
    handlePendingUserAction,
    handleResolveTicket,
    handleReopenTicket,
    handleViewEvent,
    acceptAssignmentisLoading,
    removeAssignmentisLoading,
    pendingUserActionisLoading,
    resolveTicketisLoading,
    reopenTicketisLoading,
    searchReferenceisLoading,
  } = useAdminSupportReduxHandlers();

  //state
  const [notes, setNotes] = useState('');
  const [adminActionSelection, setAdminActionSelection] = useState('');

  //functions
  function handleAdminActionSubmit() {
    if (infoCardType === 'ticket') {
      if (adminActionSelection === 'acceptTicket') {
        handleAcceptAssignment(idData, notes, adminAccountAdminReference);
      }
      if (adminActionSelection === 'reopen') {
        handleReopenTicket(idData, notes, adminAccountAdminReference);
      }
      if (adminActionSelection === 'removeAssignment') {
        handleRemoveAssignment(idData, notes, adminAccountAdminReference);
      }
      if (adminActionSelection === 'pendingUserAction') {
        handlePendingUserAction(idData, notes, adminAccountAdminReference);
      }
      if (adminActionSelection === 'resolved') {
        handleResolveTicket(idData, notes, adminAccountAdminReference);
      }

      setAdminActionSelection('');
      return setNotes('');
    }
  }

  return (
    <>
      {acceptAssignmentisLoading ||
      removeAssignmentisLoading ||
      pendingUserActionisLoading ||
      resolveTicketisLoading ||
      reopenTicketisLoading ? (
        <Spinner />
      ) : (
        <>
          {infoCardType === 'ticket' && (
            <div className="flex-row">
              <div className="support-ticket__actions mrg-b48 full-width">
                <ButtonMeta
                  handleClick={() =>
                    handleViewEvent(
                      ticketData?.target?.targetReference,
                      cardsRowIds,
                      setCardsRowIds
                    )
                  }
                  btnText={'View Event'}
                  icon={preview}
                  customClassName={'highlight-i-theme mrg-r24'}
                  isLoading={searchReferenceisLoading}
                />
                {supportData?.current?._id !== adminAccountId &&
                  ticketData?.status !== 'resolved' && (
                    <ButtonMeta
                      handleClick={() => {
                        if (adminActionSelection === 'acceptTicket') {
                          return setAdminActionSelection('');
                        } else {
                          setAdminActionSelection('acceptTicket');
                        }
                      }}
                      activeStateDependency={
                        adminActionSelection === 'acceptTicket'
                      }
                      btnText={'Accept Ticket'}
                      customClassName={'mrg-r24'}
                    />
                  )}
                {ticketData?.status === 'resolved' && (
                  <ButtonMeta
                    handleClick={() => {
                      if (adminActionSelection === 'reopen') {
                        return setAdminActionSelection('');
                      } else {
                        setAdminActionSelection('reopen');
                      }
                    }}
                    activeStateDependency={adminActionSelection === 'reopen'}
                    btnText={'Reopen'}
                    customClassName={'mrg-r24'}
                  />
                )}
                {supportData?.current?._id === adminAccountId && (
                  <>
                    <ButtonMeta
                      handleClick={() => {
                        if (adminActionSelection === 'removeAssignment') {
                          return setAdminActionSelection('');
                        } else {
                          setAdminActionSelection('removeAssignment');
                        }
                      }}
                      activeStateDependency={
                        adminActionSelection === 'removeAssignment'
                      }
                      btnText={'Remove Assignment'}
                      customClassName={'mrg-r24'}
                    />

                    {ticketData?.status !== 'pendingUserAction' && (
                      <ButtonMeta
                        handleClick={() => {
                          if (adminActionSelection === 'pendingUserAction') {
                            return setAdminActionSelection('');
                          } else {
                            setAdminActionSelection('pendingUserAction');
                          }
                        }}
                        activeStateDependency={
                          adminActionSelection === 'pendingUserAction'
                        }
                        btnText={'Set Pending User Action'}
                        customClassName={'mrg-r24'}
                      />
                    )}

                    {ticketData?.status !== 'resolved' && (
                      <ButtonMeta
                        handleClick={() => {
                          if (adminActionSelection === 'resolved') {
                            return setAdminActionSelection('');
                          } else {
                            setAdminActionSelection('resolved');
                          }
                        }}
                        activeStateDependency={
                          adminActionSelection === 'resolved'
                        }
                        btnText={'Set Resolved'}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {adminActionSelection && (
            <div className="flex-column mrg-b48">
              <div className="flex-row">
                <img
                  src={edit}
                  alt="editIcon"
                  className="mrg-r12 filter-gray mrg-auto-bottom"
                />
                <TextArea
                  id={'reportNotes'}
                  limit={10000}
                  hideWordCount={true}
                  labelText={'Action Notes'}
                  handleChange={setNotes}
                />
              </div>
              <div className="flex-row">
                <ConfirmSelection
                  btn1Text={'Cancel'}
                  btn1Color={'neutral'}
                  btn1Handler={() => setAdminActionSelection('')}
                  btn2Text={'Confirm'}
                  btn2Color={'pos'}
                  btn2Handler={handleAdminActionSubmit}
                  ignoreMaxWidth={true}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfoCardActions;
