//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
//Components

//Utility

const SearchSummary = ({ width }) => {
  //Hooks
  const { SettingsEventsLanguage } = useLanguageSettings();
  const {
    settingsEventSearchCategory: searchCategory,
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
    settingsMyEventsView,
  } = useSettingsHandlers();

  //Component variables
  let searchSummary;
  let formatSummary;

  if (searchCategory === 'distance') {
    if (searchDirection === 'ascending') {
      searchSummary = `${SettingsEventsLanguage.closest} ${SettingsEventsLanguage.distance}`;
    }
    if (searchDirection === 'descending') {
      searchSummary = `${SettingsEventsLanguage.furthest} ${SettingsEventsLanguage.distance}`;
    }
  }

  if (searchCategory === 'date') {
    if (searchDirection === 'ascending') {
      searchSummary = `${SettingsEventsLanguage.earliest} ${SettingsEventsLanguage.date}`;
    }
    if (searchDirection === 'descending') {
      searchSummary = `${SettingsEventsLanguage.latest} ${SettingsEventsLanguage.date}`;
    }
  }

  if (view !== 'myevents') {
    if (searchFormat === 'inPerson') {
      formatSummary = `${SettingsEventsLanguage.inPersonEvents}`;
    } else if (searchFormat === 'online') {
      formatSummary = `${SettingsEventsLanguage.onlineEvents}`;
    }
  } else {
    if (settingsMyEventsView === 'saved') {
      formatSummary = `${SettingsEventsLanguage.savedEvents}`;
    } else if (settingsMyEventsView === 'registered') {
      formatSummary = `${SettingsEventsLanguage.registeredEvents}`;
    } else if (settingsMyEventsView === 'archived') {
      formatSummary = `${SettingsEventsLanguage.archivedEvents}`;
    }
  }

  return (
    <div
      className="search-summary mrg-tb24 fs18 fwsb color-gray access-ob access-o12"
      tabIndex="0"
    >
      <p className="fwb fs19">{formatSummary}</p>&nbsp;
      {width <= 848 && <br />}
      <p>{SettingsEventsLanguage.by}</p>&nbsp;
      <p className="fwb fs19">{searchSummary}</p>
    </div>
  );
};

export default SearchSummary;
