//hooks
import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';
import useLanguageData from '../../../../../language/useLanguageData';

//components
//utils
import {
  cancel,
  icon_profile,
  img_archived,
  img_bug,
  img_checklist,
  img_ticket,
  organize,
} from '../../../../../assets/icons';

const InfoCardHeader = ({
  infoCardType,
  idData,
  handleCloseInfoCard,
  title,
  ticketHeader,
  accountHeader,
  eventHeader,
  technicalLogHeader,
  technicalLogArchiveHeader,
}) => {
  //hooks
  const { AdminTicketType } = useLanguageAdmin();
  const { ReportEventOpts } = useLanguageData();

  return (
    <>
      <div className="flex-row align-center space-between ">
        <div className="flex-row align-center">
          {infoCardType === 'ticket' && (
            <img src={img_ticket} alt="icon" className="filter-gray mrg-r12" />
          )}
          {(infoCardType === 'account' || infoCardType === 'admin') && (
            <img
              src={icon_profile}
              alt="icon"
              className="filter-gray mrg-r12"
            />
          )}
          {infoCardType === 'event' && (
            <img
              src={organize}
              alt="icon"
              className="filter-gray mrg-r12"
              style={{ height: '32px' }}
            />
          )}
          {infoCardType === 'eventVerification' && (
            <img
              src={img_checklist}
              alt="icon"
              className="filter-gray mrg-r12"
              style={{ height: '32px' }}
            />
          )}
          {infoCardType === 'technicalLog' && (
            <img
              src={img_bug}
              alt="icon"
              className="filter-gray mrg-r12"
              style={{ height: '32px' }}
            />
          )}
          {infoCardType === 'technicalLogArchive' && (
            <img
              src={img_archived}
              alt="icon"
              className="filter-gray mrg-r12"
              style={{ height: '32px' }}
            />
          )}

          <h2>{title}</h2>
        </div>

        <button
          className="close-ticket"
          onClick={() => {
            handleCloseInfoCard(idData);
          }}
          title={'Close'}
        >
          <img src={cancel} alt="X" className="filter-red" />
        </button>
      </div>
      {infoCardType === 'ticket' && (
        <div className="fs21 mrg-b12">
          <div className="flex-row italic mrg-b6">
            {AdminTicketType[ticketHeader[0]]}:&nbsp;
            {ReportEventOpts[ticketHeader[1]]}
          </div>
          <div className="divider mrg-b12" />
        </div>
      )}

      {(infoCardType === 'account' || infoCardType === 'admin') && (
        <div className="fs21 mrg-b12">
          <div className="flex-row align-center italic mrg-b6">
            {accountHeader?.personal?.firstName}{' '}
            {accountHeader?.personal?.lastName}
          </div>
          <div className="divider mrg-b12" />
        </div>
      )}

      {infoCardType === 'event' && (
        <div className="fs21 mrg-b12">
          <div className="flex-row italic mrg-b6">{eventHeader?.title}</div>
          <div className="divider mrg-b12" />
        </div>
      )}

      {infoCardType === 'eventVerification' && (
        <div className="fs21 mrg-b12">
          <div className="flex-row italic mrg-b6">{eventHeader?.title}</div>
          <div className="divider mrg-b12" />
        </div>
      )}

      {infoCardType === 'technicalLog' && (
        <div className="fs21 mrg-b12">
          <div className="flex-row italic mrg-b6">
            Location: {technicalLogHeader}
          </div>
          <div className="divider mrg-b12" />
        </div>
      )}

      {infoCardType === 'technicalLogArchive' && (
        <div className="fs21 mrg-b12">
          <div className="flex-column italic mrg-b6">
            Archive Path: {technicalLogArchiveHeader}
            <div className="mrg-t6" />
            Location: {technicalLogHeader}
          </div>
          <div className="divider mrg-b12" />
        </div>
      )}
    </>
  );
};

export default InfoCardHeader;
