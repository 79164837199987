async function convertBase64ToJPEG(base64String, filename) {
  const bytes = new Uint8Array(
    await fetch(base64String).then((response) => response.arrayBuffer())
  );
  const blob = new Blob([bytes], { type: 'image/jpeg' });
  const file = new File([blob], filename, { type: 'image/jpeg' });

  return file;
}

export default convertBase64ToJPEG;
