//Hooks
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import ButtonSwitch from '../../../../components/ButtonSwitch/ButtonSwitch';
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown';
import SelectDropdownTimezones from '../../../../components/SelectDropdown/SelectDropdownTimezones';

//Utility

import { timesData } from '../../../../data/timesData';
import CalendarSimple from '../../../../components/CalendarSimple/CalendarSimple';

const Schedule = ({
  initialize,
  editEventMode,
  handleSetSchedulePublishDate,
  handleSetSchedulePublishTime,
  handleSetSchedulePublishTimeZone,
  handleSetScheduleEnabled,
  loadSchedulePublishTime,
  loadSchedulePublishTimeZone,
  loadScheduleEnabled,
  loadSchedulePublishDate,
}) => {
  //Hooks
  const { EditOverview } = useLanguageOrganize();

  //Component State

  //Component variable
  let content;

  //Initialize functions
  //Component functions

  function schedulePublishToggle() {
    handleSetScheduleEnabled(!loadScheduleEnabled);
  }

  content = (
    <fieldset className="fieldset" id="fieldset-publish">
      <legend id="aria-fieldset-instructions-publish">
        {/* {CreateEvent.publish.ariaFieldsetInstructions} */}
      </legend>
      <label
        className="label"
        id="scheduleLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-publish"
      >
        {EditOverview.schedulePublication}
      </label>

      {!editEventMode && (
        <div className="mrg-t24--ph">
          <ButtonSwitch
            id={'scheduleEventPublication'}
            title={EditOverview.togglePublicationSchedule}
            handleSwitch={schedulePublishToggle}
            noWrap={true}
            loadTrigger={initialize}
            loadValue={loadScheduleEnabled}
          />
        </div>
      )}

      {loadScheduleEnabled && (
        <>
          <div className="h24" />
          <CalendarSimple
            initialize={initialize}
            handleSetDate={handleSetSchedulePublishDate}
            loadDate={loadSchedulePublishDate}
          />

          <div className="h24" />
          <SelectDropdownTimezones
            id={'publishTimezone'}
            handleSelection={handleSetSchedulePublishTimeZone}
            defaultValue={
              initialize && loadSchedulePublishTimeZone
                ? loadSchedulePublishTimeZone
                : null
            }
          />

          <div className="h24" />
          <SelectDropdown
            id={'publishTime'}
            list={timesData}
            handleSelection={handleSetSchedulePublishTime}
            defaultKey={
              initialize && loadSchedulePublishTime
                ? loadSchedulePublishTime
                : null
            }
            internalLabel={EditOverview.startTime}
            asterisk={true}
          />
          <div className="h24" />
        </>
      )}
    </fieldset>
  );

  return content;
};

export default Schedule;
