export async function checkImagePath(imagePath, imgType) {
  const img = new Image();

  if (imgType === 'event') {
    img.src = `https://storage.googleapis.com/myndfull_event_images${
      process.env.REACT_APP_ENV === 'development' ? '_development' : ''
    }/${imagePath}`;

    img.onload = () => {
      return true;
    };

    img.onerror = () => {
      return false;
    };
  }
}
