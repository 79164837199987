import { useState, useEffect } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import useLanguageData from '../../../../../language/useLanguageData';

//Components
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import SelectDropdownTimezones from '../../../../../components/SelectDropdown/SelectDropdownTimezones';

//Utility

const AppSettings = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const { LanguageOpts } = useLanguageData();
  const {
    accountTimeZone: loadTimeZone,
    accountContentLanguage: loadContentLanguage,
    accountSoundEffects: loadSoundEffects,
    accountAppSettings,
  } = useAccountReduxHandlers();

  //Component state
  const [contentLanguage, setContentLanguage] = useState(loadContentLanguage);
  const [timeZone, setTimeZone] = useState(loadTimeZone);
  const [appSettingsSaveValid, setAppSettingsSaveValid] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (
      (timeZone !== loadTimeZone || contentLanguage !== loadContentLanguage) &&
      contentLanguage
    ) {
      setAppSettingsSaveValid(true);
    } else {
      setAppSettingsSaveValid(false);
    }
  }, [
    timeZone,
    loadTimeZone,
    contentLanguage,
    loadContentLanguage,
    loadSoundEffects,
  ]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="appSettingsLabel"
      >
        {Account.general.appSettings}
      </label>
      <div className="h24" />

      <SelectDropdownTimezones
        id={'timezone'}
        handleSelection={setTimeZone}
        defaultValue={loadTimeZone ? loadTimeZone : null}
        fullWidth={true}
      />

      <div className="h24" />
      <SelectDropdown
        id={'contentLanguage'}
        list={LanguageOpts}
        handleSelection={setContentLanguage}
        internalLabel={Account.account.appLanguage}
        defaultKey={loadContentLanguage ? loadContentLanguage : null}
        asterisk={true}
        fullWidth={true}
      />

      <div className="h24" />

      <SaveButton
        saveValid={appSettingsSaveValid}
        saveItemLanguage={Account.account.appSettings}
        handler={() =>
          saveAccountChange(
            {
              appSettings: {
                ...accountAppSettings,
                timeZone,
                contentLanguage,
              },
            },
            'appSettings'
          )
        }
        isLoading={loadingSaveBtnUI === 'appSettings'}
      />

      <div className="h36" />
    </div>
  );
};

export default AppSettings;

{
  /* <ButtonSwitch
        id={'soundEffectsToggle'}
        title={Account.account.soundEffects}
        loadValue={loadSoundEffects ? loadSoundEffects : null}
        loadTrigger={true}
        handleSwitch={setSoundEffects}
      />
      <div className="h24" /> */
}
