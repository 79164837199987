import { useEffect, useState } from 'react';

//hooks
import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';

//components

//utils
import { getRandomColor } from '../../../../../utils/UI/randomColorCodes';
import { DateFromTimeStamp } from '../../../../../utils/dateTime/DateCalc';

const InfoCardContainerVerificationHistory = ({ verificationHistoryData }) => {
  //hooks
  const { EventVerificationStatus } = useLanguageAdmin();
  //state
  //UI
  const [handleColors, setHandleColors] = useState([]);

  //initialize

  useEffect(() => {
    if (verificationHistoryData) {
      let colorArr = [];
      verificationHistoryData.map((_) => colorArr.push(getRandomColor()));
      setHandleColors(colorArr);
    }
  }, [verificationHistoryData]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Verification History</p>

      <ul className="info-card-list">
        {verificationHistoryData
          ?.map((ev, index) => {
            return (
              <li
                className="flex-column"
                key={`verificationHistory-${ev?._id}`}
              >
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColors[index] }}
                />
                <p className="flex-row">
                  {EventVerificationStatus[`${ev?.action}`]}
                </p>
                <p>
                  {ev?.action === 'eventVerificationDisapproved' ||
                  ev?.action === 'eventVerificationApproved'
                    ? `Admin Reference: [${ev?.adminReference}]`
                    : `Account Reference: [${ev?.accountReference}]`}
                </p>
                {ev?.adminName && <p>Admin Name: {ev.adminName}</p>}
                {ev?.notes?.length > 0 && <p>Notes: {ev?.notes}</p>}
                {ev?.changeList?.length > 0 && (
                  <p>
                    Change List:{' '}
                    {ev?.changeList?.map((item, index2) => (
                      <span
                        key={`changeList-${index2}-${ev?.accountReference}`}
                      >
                        "{item}"{index2 !== ev?.changeList?.length - 1 && ','}
                        &nbsp;
                      </span>
                    ))}
                  </p>
                )}
                <p>Date: {DateFromTimeStamp(ev?.timestamp)}</p>
              </li>
            );
          })
          .reverse()}
      </ul>
    </div>
  );
};

export default InfoCardContainerVerificationHistory;
