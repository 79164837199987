import { Link } from 'react-router-dom';

//Hooks
import useSettings from '../../context/useSettings';
import useLanguageLayout from '../../language/useLanguageLayout';

//Components
import LoadBucketImage from '../../components/LoadBucketImage/LoadBucketImage';

//Utils
import {
  img_checklist,
  img_microchip,
  img_pie_chart,
  img_tools,
  logOut,
} from '../../assets/icons';
import { useAdminAccountReduxHandlers } from '../../features/admin/account/useAdminAccountHandlers';
import { useLazyAdminLogoutQuery } from '../../features/admin/auth/adminAuthApiSlice';

const AdminNavLoggedIn = ({ currentRoles }) => {
  //Hooks
  const { NavbarLoggedIn } = useLanguageLayout();
  const { navbar, setNavbar } = useSettings();
  const { adminAccountProfileImage } = useAdminAccountReduxHandlers();

  const [adminLogOut] = useLazyAdminLogoutQuery();

  return (
    <div className="nav__links no-select">
      {currentRoles.includes(process.env.REACT_APP_ADMIN_MANAGEMENT) && (
        <Link
          to={{ pathname: '/management' }}
          onClick={() => setNavbar('management')}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img
              aria-hidden="true"
              src={img_checklist}
              alt="clipboard"
              className="filter-white"
            />
            <span
              className={`${
                navbar === 'management' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          Management
        </Link>
      )}
      {currentRoles.includes(process.env.REACT_APP_ADMIN_ANALYST) && (
        <Link
          to={{ pathname: '/analytics' }}
          onClick={() => setNavbar('analytics')}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img
              aria-hidden="true"
              src={img_pie_chart}
              alt="clipboard"
              className="filter-white"
            />
            <span
              className={`${
                navbar === 'analytics' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          Analytics
        </Link>
      )}
      {currentRoles.includes(process.env.REACT_APP_ADMIN_SUPPORT) && (
        <Link
          to={{ pathname: '/support' }}
          onClick={() => setNavbar('support')}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img
              aria-hidden="true"
              src={img_tools}
              alt="clipboard"
              className="filter-white"
            />
            <span
              className={`${
                navbar === 'support' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          Support
        </Link>
      )}
      {currentRoles.includes(process.env.REACT_APP_ADMIN_TECHNICAL) && (
        <Link
          to={{ pathname: '/technical' }}
          onClick={() => setNavbar('technical')}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img
              aria-hidden="true"
              src={img_microchip}
              alt="clipboard"
              className="filter-white"
            />
            <span
              className={`${
                navbar === 'technical' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          Technical
        </Link>
      )}

      <Link
        to={{ pathname: '/account' }}
        onClick={() => setNavbar('account')}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <LoadBucketImage
            imagePath={adminAccountProfileImage}
            imgClassName="profile-image"
            backupPath={'account_img_default.png'}
            partialPath={'myndfull_account_images'}
          />
          <span
            className={`${navbar === 'account' ? 'active fadeIn' : 'inactive'}`}
          />
        </div>
        Account
      </Link>

      <Link
        to={{ pathname: '/' }}
        onClick={() => adminLogOut()}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <img aria-hidden="true" src={logOut} alt={NavbarLoggedIn.signOut} />
          <span
            className={`${navbar === 'logout' ? 'active fadeIn' : 'inactive'}`}
          />
        </div>
        {NavbarLoggedIn.signOut}
      </Link>
    </div>
  );
};

export default AdminNavLoggedIn;
