//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
//Components

//Utility
import {
  organize_create,
  organize_find_speakers,
  img_organize_overview,
} from '../../../../assets/icons';

const OrganizeViews = () => {
  //Hooks
  const { SettingsOrganizeLanguage } = useLanguageSettings();
  const { settingsOrganizeView: view, handleSettingsSetOrganizeView } =
    useSettingsHandlers();

  //Component variables
  let content;

  if (SettingsOrganizeLanguage) {
    content = (
      <ul className="view__container">
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetOrganizeView('overview')}
            title={SettingsOrganizeLanguage.organizerOverview}
            aria-label={SettingsOrganizeLanguage.organizerOverview}
            id="view-overview"
            aria-pressed={view === 'overview'}
          >
            <img
              src={img_organize_overview}
              alt={SettingsOrganizeLanguage.organizerOverview}
              className={view === 'overview' ? 'selected' : ''}
            />
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetOrganizeView('create')}
            title={SettingsOrganizeLanguage.createEvents}
            aria-label={SettingsOrganizeLanguage.createEvents}
            id="view-create"
            aria-pressed={view === 'create'}
          >
            <img
              src={organize_create}
              alt={SettingsOrganizeLanguage.createEvents}
              className={view === 'create' ? 'selected' : ''}
            />
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetOrganizeView('speakers')}
            title={SettingsOrganizeLanguage.findSpeakers}
            aria-label={SettingsOrganizeLanguage.findSpeakers}
            id="view-speakers"
            aria-pressed={view === 'speakers'}
          >
            <img
              src={organize_find_speakers}
              alt={SettingsOrganizeLanguage.findSpeakers}
              className={view === 'speakers' ? 'selected' : ''}
            />
          </button>
        </li>
      </ul>
    );
  }
  return content;
};

export default OrganizeViews;
