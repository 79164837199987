import { useState, useEffect } from 'react';

//Hooks
import { useCreateEventReduxHandlers } from '../create/useCreateEventHandlers';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import { useEventValidation } from '../useOrganizeHandlers';
//Components
import ScrollToComponent from '../../../../components/ScrollToComponent/ScrollToComponent';
import ItemButton from '../../../../components/ItemButton/ItemButton';
//Utility
import {
  organize,
  preview,
  clock,
  img_checklist,
} from '../../../../assets/icons/index';

const Publish = ({
  handleSubmit,
  createDraftEventisLoading,
  createEventisLoading,
  setEventPreview,
}) => {
  //Hooks
  const { CreateEvent } = useLanguageOrganize();

  const { handleEventValidation } = useEventValidation();
  const { scheduleEnabled, lastModified, createEventData } =
    useCreateEventReduxHandlers();

  //Component state
  const [validations, setValidations] = useState();
  const [verifiedSuccessful, setVerifiedSuccessful] = useState(false);
  const [verifiedTimestamp, setVerifiedTimestamp] = useState();

  //Component variable
  let content;

  //API functions
  async function initializeSubmitEvent(submissionType) {
    if (!createDraftEventisLoading && !createEventisLoading) {
      if (submissionType === 'schedulePublish') {
        if (scheduleEnabled) {
          initializePublish();
        }
      } else if (submissionType === 'publish') {
        initializePublish();
      }
    }
  }

  //Component functions
  useEffect(() => {
    if (lastModified > verifiedTimestamp) {
      setVerifiedSuccessful(false);
    }
  }, [lastModified]); //if createEvent state in redux is updated, this will invalidate the users ability to publish event

  //Component functions
  function checkCompletion() {
    const validationsCheck = handleEventValidation('create');

    if (Array.isArray(validationsCheck)) {
      setValidations(validationsCheck);
      setVerifiedSuccessful(false);
    } else {
      setVerifiedTimestamp(Date.now());
      setVerifiedSuccessful(true);
      setValidations();
    }
  }

  function initializePublish() {
    const validationsCheckArrOrEventData = handleEventValidation('create');
    if (Array.isArray(validationsCheckArrOrEventData)) {
      setValidations(validationsCheckArrOrEventData);
      setVerifiedSuccessful(false);
    } else {
      //create event
      handleSubmit(validationsCheckArrOrEventData, 'publish', true);
    }
  }

  content = (
    <fieldset className="fieldset" id="fieldset-publish">
      <legend id="aria-fieldset-instructions-publish">
        {CreateEvent.publish.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="publishLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-publish"
      >
        {CreateEvent.publish.publish}
      </label>
      <div className="h0-ph24" />
      {verifiedSuccessful ? (
        <p className="content-heading-styled color-green" tabIndex="0">
          {CreateEvent.publish.ready}
        </p>
      ) : (
        <p className="content-heading-styled text-red" tabIndex="0">
          {CreateEvent.publish.unready}
        </p>
      )}

      <div className="h12" />
      {verifiedSuccessful ? (
        <p className="description-text mrg-b24" tabIndex="0">
          {CreateEvent.publish.checkCompetionSuccessfulDescription}
        </p>
      ) : (
        <p className="description-text mrg-b24" tabIndex="0">
          {CreateEvent.publish.checkCompletionDescription}
        </p>
      )}

      {!verifiedSuccessful && (
        <>
          <div className="h24" />
          <ItemButton
            handler={checkCompletion}
            text={CreateEvent.publish.checkCompletion}
            iconOn={true}
            icon={img_checklist}
            fullWidth={true}
          />
        </>
      )}

      {validations && (
        <div className="validations__wrapper">
          <div className="validations-title mrg-t24 fs16 fwsb color-red ">
            <p className="access-ob  access-o6" tabIndex="0">
              {CreateEvent.publish.fieldsThatRequireCompletion}
            </p>
          </div>
          <ul className="scrollToComponent__container mrg-t24">
            {validations.map((item, index) => {
              const validationLabel = Object.values(item);
              const element = Object.keys(item);
              return (
                <div key={`scrollToComponent-${index}`}>
                  <ScrollToComponent
                    text={validationLabel[0]}
                    elementId={element[0]}
                  />
                </div>
              );
            })}
          </ul>
        </div>
      )}

      {verifiedSuccessful && (
        <>
          <ItemButton
            handler={() => setEventPreview(createEventData)}
            text={CreateEvent.publish.previewEvent}
            iconOn={true}
            icon={preview}
            fullWidth={true}
          />
          <div className="h48" />
        </>
      )}
      {verifiedSuccessful && scheduleEnabled && (
        <ItemButton
          handler={() => initializeSubmitEvent('schedulePublish')}
          text={CreateEvent.publish.scheduleEventPublication}
          iconOn={true}
          icon={clock}
          fullWidth={true}
          isLoading={createDraftEventisLoading || createEventisLoading}
        />
      )}

      {verifiedSuccessful && !scheduleEnabled && (
        <ItemButton
          handler={() => initializeSubmitEvent('publish')}
          text={CreateEvent.publish.publishEvent}
          iconOn={true}
          icon={organize}
          fullWidth={true}
          isLoading={createDraftEventisLoading || createEventisLoading}
        />
      )}
    </fieldset>
  );

  return content;
};

export default Publish;
