import { apiSlice } from '../../../app/api/apiSlice';
import { addReduxApiStatus } from '../../main/apiStatus/apiStatusSlice';
import { setAdminSettingsManagementTicketsTableHasMore } from '../settings/adminSettingsSlice';

export const adminManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminAccounts: builder.query({
      query: ({ accountId }) => {
        return {
          url: '/api/admin/management/getAdminAccounts',
          params: {
            accountId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'adminManagementApiSlice, getAdminAccounts error:',
              error
            );
          }
        }
      },
      providesTags: ['AdminManagement'],
    }),
    getAdminLogs: builder.query({
      query: ({ accountId }) => {
        return {
          url: '/api/admin/management/getAdminLogs',
          params: {
            accountId,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('adminManagementApiSlice, getAdminLogs error:', error);
          }
        }
      },
      providesTags: ['AdminManagementLogs'],
    }),
    changePermission: builder.mutation({
      query: ({
        adminAccountId,
        targetedAccountId,
        permissionLevel,
        permissionChange,
      }) => ({
        url: `/api/admin/management/permission`,
        method: 'PATCH',
        body: {
          adminAccountId,
          targetedAccountId,
          permissionLevel,
          permissionChange,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          } else {
            const success = {
              id: Date.now(),
              status: 'success',
              message: 'accountUpdated',
              origin: 'AdminManagementApiSlice/changePermission',
            };
            dispatch(addReduxApiStatus(success));
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminManagementApiSlice/changePermission',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['AdminManagement'],
    }),
    createAdminLogPermissionChange: builder.mutation({
      query: ({
        adminAccountId,
        targetAccountId,
        permissionLevel,
        permissionChange,
      }) => ({
        url: `/api/admin/management/permission/createAdminLogPermissionChange`,
        method: 'POST',
        body: {
          adminAccountId,
          targetAccountId,
          permissionLevel,
          permissionChange,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminManagementApiSlice/createAdminLogPermissionChange',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['AdminManagementLogs'],
    }),
    getTicketsManagement: builder.query({
      query: ({
        adminAccountId,
        queryStatus,
        queryIssue,
        queryTicketType,
        queryDateStart,
        queryDateEnd,
        querySupportUserType,
        querySupportUserInput,
        querySubmitUserType,
        querySubmitUserInput,
        querySubmitUserFilter,
        queryResultsPerPage,
        queryPage,
      }) => {
        return {
          url: '/api/admin/management/getTickets',
          params: {
            adminAccountId,
            queryStatus,
            queryIssue,
            queryTicketType,
            queryDateStart,
            queryDateEnd,
            querySupportUserType,
            querySupportUserInput,
            querySubmitUserType,
            querySubmitUserInput,
            querySubmitUserFilter,
            queryResultsPerPage,
            queryPage,
          },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { hasMore } = data;
          dispatch(setAdminSettingsManagementTicketsTableHasMore(hasMore));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AdminSupportApiSlice/getTicketsManagement',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['Ticket'],
    }),
    adminRegistration: builder.mutation({
      query: ({
        adminAccountId,
        email,
        password: newPassword,
        prefix,
        pronouns,
        firstName,
        lastName,
        countryCode,
        phoneNumber,
        position,
      }) => ({
        url: `/api/admin/register`,
        method: 'POST',
        body: {
          adminAccountId,
          email,
          password: newPassword,
          prefix,
          pronouns,
          firstName,
          lastName,
          countryCode,
          phoneNumber,
          position,
        },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'adminManagementApiSlice/createAdminLogPermissionChange',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['AdminManagement'],
    }),
  }),
});

export const {
  useGetAdminAccountsQuery,
  useGetAdminLogsQuery,
  useChangePermissionMutation,
  useLazyGetTicketsManagementQuery,
  useCreateAdminLogPermissionChangeMutation,
  useAdminRegistrationMutation,
} = adminManagementApiSlice;
