import { useState } from 'react';

//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Components

//Utility

//type is used as wordspace variable
//function needed at parent level component to sort through selected option and pass as description
const WhatsThis = ({ type, description }) => {
  //Hooks
  const { WhatsThis } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //UI state
  const [active, setActive] = useState(false);
  const [tapHighlight, setTapHighlight] = useState(false);

  return (
    <button
      className={`whatsthis__wrapper highlight-bthin-theme br--small access-ob access-o6 ${
        active ? 'whatsthis__wrapper--active' : ''
      } ${tapHighlight ? 'highlight-b-theme' : ''}`}
      onClick={() =>
        handleMobileTap(
          [() => setTapHighlight(true), () => setTapHighlight(false)],
          [() => setActive(!active)]
        )
      }
      aria-pressed={active}
      aria-label={`${WhatsThis.whatsThis} ${type}. ${
        active ? description : ''
      }.`}
    >
      <div className="whatsthis disable-select fs16 pad-l12 pad-r12">
        <div className="flex-row align-center flex-center--ph">
          <p className={`${active ? 'info active' : 'info'} `}>?</p>
          <p
            className={`heading no-wrap italic ${
              tapHighlight ? 'highlight-it-theme' : ''
            }`}
          >
            {WhatsThis.whatsThis}
          </p>
          {type && <p className="heading italic ">&nbsp;{type}</p>}
        </div>
        {active && (
          <>
            <div className="h6" />
            <p className="text-left" aria-live="polite">
              {description}
            </p>
          </>
        )}
      </div>
    </button>
  );
};

export default WhatsThis;
