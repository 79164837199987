//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import Spinner from '../Spinner/Spinner';

//Utility

const SaveButton = ({
  saveValid,
  saveItemLanguage,
  handler,
  isLoading,
  customWrapper,
}) => {
  //Hooks
  const { SaveButton } = useLanguageComponents();

  return (
    <button
      className={`access-ob access-o6 ${
        saveValid && !isLoading ? 'button-save--active' : 'button-save'
      } ${customWrapper ? customWrapper : ''}`}
      onClick={saveValid ? handler : null}
      aria-label={
        saveValid
          ? `${SaveButton.save} ${saveItemLanguage}.`
          : `${SaveButton.ariaSaveUnavailable}`
      }
    >
      {saveValid && !isLoading ? (
        <p className="pad-t2">{SaveButton.save}</p>
      ) : isLoading ? (
        <Spinner smallDots={true} />
      ) : (
        <p className="pad-t2">&mdash;</p>
      )}
    </button>
  );
};

export default SaveButton;
