const InfoCardContainerRequest = ({ requestData }) => {
  return (
    <>
      {requestData && (
        <div className="support-ticket__container">
          <p className="fs21 fwsb mrg-b6">Request</p>

          <div className="mrg-t6">
            Original Url: "{requestData?.originalUrl}"
          </div>
          {requestData?.params && (
            <div className="mrg-t6">
              Params:
              <ul className="mrg-l24">
                {Object.entries(requestData.params).map(([key, value]) => (
                  <li key={key}>
                    {key}: {value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {requestData?.query && (
            <div className="mrg-t6">
              Query:
              <ul className="mrg-l24">
                {Object.entries(requestData?.query).map(([key, value]) => (
                  <li key={key}>
                    {key}: {value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {requestData?.body && (
            <div className="mrg-t6">
              <p>Request Body: {JSON.stringify(requestData?.body)}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InfoCardContainerRequest;
