import { useEffect, useState } from 'react';

//Hooks
import useLanguageRegistration from '../../../../language/features/useLanguageRegistration';
import useSettings from '../../../../context/useSettings';

//Components
import ButtonNewWindow from '../../../../components/ButtonNewWindow/ButtonNewWindow';
import NavigationArrows from '../../../../components/NavigationArrows/NavigationArrows';
import Spinner from '../../../../components/Spinner/Spinner';

//Utility
import {
  account_reg_personal,
  account_reg_institutional,
} from '../../../../assets/art';
import { useRegistrationHandlers } from '../useRegistrationHandlers';

const AccountRegistrationType = () => {
  //Hooks
  const { AccountRegistrationType } = useLanguageRegistration();
  const { width } = useSettings();
  const {
    handleRegistrationPageIncrease,
    handleRegistrationSetGenericAccountType,
    registrationAccountType: accountType,
  } = useRegistrationHandlers();

  //Component State
  const [continueBtnCheck, setContinueBtnCheck] = useState(false);
  const [isImage01Loaded, setIsImage01Loaded] = useState(false);
  const [isImage02Loaded, setIsImage02Loaded] = useState(false);

  //Component functions
  useEffect(() => {
    if (!accountType) {
      setContinueBtnCheck(false);
    } else {
      setContinueBtnCheck(true);
    }
  }, [accountType]);

  function handleAccountTypeSelection(selection) {
    if (selection === 'personal') {
      if (accountType === 'personal') {
        handleRegistrationSetGenericAccountType('');
      } else {
        handleRegistrationSetGenericAccountType('personal');
      }
    } else {
      if (accountType === 'institutional') {
        handleRegistrationSetGenericAccountType('');
      } else {
        handleRegistrationSetGenericAccountType('institutional');
      }
    }
  }

  return (
    <>
      {(!isImage01Loaded || !isImage02Loaded) && (
        <Spinner ariaLabel={'loading'} />
      )}
      <div
        style={{
          display: isImage01Loaded && isImage02Loaded ? '' : 'none',
        }}
        id="account-type-container"
        className="no-outline"
      >
        <div className="flex-center--ph">
          <label
            className="content-label"
            aria-label={`${AccountRegistrationType.heading}.`}
          >
            {AccountRegistrationType.heading}
          </label>
        </div>

        <div className="flex-column mrg-t24">
          <ul className="selection-card__container">
            <li className="flex-column ">
              <button
                className={`mrg-b24 access-ob access-o6
                  ${
                    accountType === 'personal'
                      ? 'selection-card selection-card--active'
                      : 'selection-card'
                  } 
                }`}
                onClick={() => handleAccountTypeSelection('personal')}
                type="button"
                tabIndex="0"
                aria-pressed={accountType === 'personal'}
              >
                <img
                  className="svg mrg-t24"
                  src={account_reg_personal}
                  alt="img"
                  onLoad={() => setIsImage01Loaded(true)}
                />
                <h1 className="mrg-t12">
                  {AccountRegistrationType.personalAccountType.title}
                </h1>
                <div className="h24" />
              </button>
              <ButtonNewWindow
                btnText={AccountRegistrationType.personalAccountType.exploreBtn}
                center={true}
                customWrapper={width <= 1280 ? 'mrg-b24' : ''}
              />
            </li>

            <li className="flex-column">
              <button
                className={`mrg-b24 access-ob access-o6
                  ${
                    accountType === 'institutional'
                      ? 'selection-card selection-card--active'
                      : 'selection-card'
                  }`}
                onClick={() => handleAccountTypeSelection('institutional')}
                type="button"
                tabIndex="0"
                aria-pressed={accountType === 'institutional'}
              >
                <img
                  className="svg mrg-t24"
                  src={account_reg_institutional}
                  alt="img"
                  onLoad={() => setIsImage02Loaded(true)}
                />
                <h1 className="mrg-t12">
                  {AccountRegistrationType.institutionalAccountType.title}
                </h1>
                <div className="h24" />
              </button>

              <ButtonNewWindow
                btnText={
                  AccountRegistrationType.institutionalAccountType.exploreBtn
                }
                center={true}
              />
              <div className="h36" />
            </li>
          </ul>

          <div className="flex-center">
            <NavigationArrows
              firstArrow={true}
              handlePageIncrease={handleRegistrationPageIncrease}
              continueBtnCheck={continueBtnCheck}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountRegistrationType;
