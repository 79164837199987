import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import useGenericModal from '../../context/useGenericModal';
import useApiStatus from '../../context/useApiStatus';
import useSettings from '../../context/useSettings';

//Components
import AdminNavbar from './AdminNavbar';
import ApiStatusModal from '../../components/Modal/ApiStatusModal';
import GenericModal from '../../components/Modal/GenericModal';
import ErrorPage from '../ErrorPage';
import AdminSlideMenu from './AdminSlideMenu';
import LoadingScreenContent from '../../components/LoadingScreenContent/LoadingScreenContent';
import ErrorBoundary from '../../utils/misc/ErrorBoundary';

//Utils

const AdminLayout = () => {
  //Hooks
  const { width } = useSettings();
  const { apiStatus, handleClearApiStatus } = useApiStatus();
  const { genericModal, genericModalMobileFloat } = useGenericModal();

  const [componentIsMounting, setComponentIsMounting] = useState(true);

  useEffect(() => {
    const pageAnimationTimer = setTimeout(() => {
      setComponentIsMounting(false);
    }, 600);

    return () => clearTimeout(pageAnimationTimer);
  }, []); //page loading component animation

  return (
    <>
      {componentIsMounting && (
        <LoadingScreenContent
          loadCheck1={true}
          numberOfLoadChecks={1}
          customWrapper={'min-height-100vh'}
        />
      )}
      <div
        className={`${
          !componentIsMounting
            ? 'app onscreen-fade-in '
            : 'offscreen visibility-hidden'
        }`}
      >
        <ErrorBoundary fallback={<ErrorPage />}>
          {width <= 1280 && <AdminSlideMenu />}
          <AdminNavbar layoutIsMounting={componentIsMounting} />
          <main className="app__contents" tabIndex="-1">
            <Outlet />
          </main>

          {apiStatus?.length > 0 && (
            <div className="apiStatus-modal__wrapper">
              {apiStatus.map((apiStatusObj) => (
                <ApiStatusModal
                  apiStatus={apiStatusObj}
                  handleClearApiStatus={handleClearApiStatus}
                  key={`${apiStatusObj?.id}`}
                />
              ))}
            </div>
          )}
          {(genericModal || genericModalMobileFloat) && <GenericModal />}
        </ErrorBoundary>
      </div>
    </>
  );
};

export default AdminLayout;
