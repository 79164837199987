import { useEffect, useState } from 'react';

//Hooks
import useSettings from '../../../../context/useSettings';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import useLanguageEvents from '../../../../language/features/useLanguageEvents';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import HeadingSummary from './HeadingSummary';
import ActionButtons from './ActionButtons';
import RegistrationButtons from './RegistrationButtons';
import GeneralContent from './GeneralContent';
import HostingInPersonContent from './HostingInPersonContent';
import HostingVirtualContent from './HostingVirtualContent';
import SpeakersContent from './SpeakersContent';
import AgendaContent from './AgendaContent';
import AccessibilityContent from './AccessibilityContent';
import TagsContent from './TagsContent';
import ReportEvent from './ReportEvent';

//Utility
import ScrollTo from '../../../../utils/UI/ScrollTo';
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
/*eslint-disable*/

const EventPage = ({
  getNewNetworkEventsList,
  organizerViewOn,
  eventImageRef,
  speaker0ImgRef,
  speaker1ImgRef,
  speaker2ImgRef,
  speaker3ImgRef,
  speaker4ImgRef,
  speaker5ImgRef,
  previewEventDetailsObj,
  handlePreviewReturn,
}) => {
  //Hooks
  const { width, handleMobileTap } = useSettings();
  const {
    accountSavedEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
    accountIgnoredEvents,
    accountArchivedEvents,
  } = useAccountReduxHandlers();

  const { handleSettingsSetViewEventDetails, settingsViewEventDetails } =
    useSettingsHandlers();
  const { EventPage } = useLanguageEvents();
  const { Generic } = useLanguageComponents();

  //Component state
  const [eventStatus, setEventStatus] = useState('neutral');
  const [eventData, setEventData] = useState();

  //Initialize functions
  useEffect(() => {
    ScrollTo('navbar', 'instant', true);
  }, []);

  useEffect(() => {
    if (previewEventDetailsObj) {
      setEventData(previewEventDetailsObj);
    } else {
      setEventData(settingsViewEventDetails);
    }
  }, [settingsViewEventDetails, previewEventDetailsObj]);

  //Component functions
  useEffect(() => {
    //Generic status
    if (accountSavedEvents?.includes(eventData?._id)) {
      return setEventStatus('saved');
    }

    if (accountIgnoredEvents?.includes(eventData?._id)) {
      return setEventStatus('ignored');
    }
    if (accountArchivedEvents?.includes(eventData?._id)) {
      return setEventStatus('archived');
    }

    //InPerson
    if (accountPendingApprovalInPerson?.includes(eventData?._id)) {
      if (accountRegistrationsVirtual.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        return setEventStatus('pendingApprovalInPerson');
      }
    }

    if (accountRegistrationsInPerson.includes(eventData?._id)) {
      if (accountPendingApprovalVirtual.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        return setEventStatus('registeredInPerson');
      }
    }

    //Virtual
    if (accountPendingApprovalVirtual?.includes(eventData?._id)) {
      if (accountRegistrationsInPerson.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        return setEventStatus('pendingApprovalVirtual');
      }
    }

    if (accountRegistrationsVirtual.includes(eventData?._id)) {
      if (accountPendingApprovalInPerson.includes(eventData?._id)) {
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        return setEventStatus('registeredVirtual');
      }
    }

    /*eslint-disable-next-line*/
  }, [
    accountSavedEvents,
    accountIgnoredEvents,
    accountArchivedEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]);

  return (
    <>
      {eventData && (
        <div className="ev">
          {previewEventDetailsObj ? (
            <LoadBucketImage
              imageRef={eventImageRef.handleGetEventImage()}
              containerClassName={'ev-display-container'}
              imgClassName={'ev-display-image'}
              backupPath={'event_img_default.png'}
              partialPath={'myndfull_event_images'}
            />
          ) : (
            <LoadBucketImage
              imagePath={eventData?.generic?.eventImagePath}
              containerClassName={'ev-display-container'}
              imgClassName={'ev-display-image'}
              backupPath={'event_img_default.png'}
              partialPath={'myndfull_event_images'}
            />
          )}

          <div className="ev-main-container">
            <div
              className="ev-divider-bottom full-width flex-center fs21 fwsb  mrg-auto-lr pad-b24 access-ob"
              tabIndex="0"
            >
              {eventData?.generic?.title}
            </div>
            <HeadingSummary
              eventData={eventData}
              width={width}
              EventPage={EventPage}
              getNewNetworkEventsList={getNewNetworkEventsList}
              organizerViewOn={organizerViewOn}
              previewMode={previewEventDetailsObj}
            />

            <ActionButtons
              eventData={eventData}
              eventStatus={eventStatus}
              handleMobileTap={handleMobileTap}
              EventPage={EventPage}
              handleReturn={
                handlePreviewReturn
                  ? handlePreviewReturn
                  : handleSettingsSetViewEventDetails
              }
              setEventStatus={setEventStatus}
              organizerViewOn={organizerViewOn}
              registrationsComponentOn={
                eventData.registrationData.registrationsActive
              }
            />

            {eventData.registrationData.registrationsActive && (
              <RegistrationButtons
                eventData={eventData}
                eventStatus={eventStatus}
                EventPage={EventPage}
                Generic={Generic}
                handleMobileTap={handleMobileTap}
                setEventStatus={setEventStatus}
                organizerViewOn={organizerViewOn}
              />
            )}

            <div className="flex-column fs18 mrg-b24 pad-t24">
              <GeneralContent eventData={eventData} EventPage={EventPage} />
              {(eventData.hosting.hostingOption === 'inPerson' ||
                eventData.hosting.hostingOption === 'hybrid') && (
                <HostingInPersonContent
                  eventData={eventData}
                  EventPage={EventPage}
                  eventStatus={eventStatus}
                  handleMobileTap={handleMobileTap}
                  organizerViewOn={organizerViewOn}
                  previewMode={previewEventDetailsObj}
                />
              )}
              {(eventData.hosting.hostingOption === 'online' ||
                eventData.hosting.hostingOption === 'hybrid') && (
                <HostingVirtualContent
                  eventData={eventData}
                  EventPage={EventPage}
                  eventStatus={eventStatus}
                  organizerViewOn={organizerViewOn}
                />
              )}
              {(eventData.participants?.moderator?.length > 0 ||
                eventData.participants?.speaker0Data?.id ||
                eventData.participants?.speaker1Data?.id ||
                eventData.participants?.speaker2Data?.id ||
                eventData.participants?.speaker3Data?.id ||
                eventData.participants?.speaker4Data?.id ||
                eventData.participants?.speaker5Data?.id) && (
                <SpeakersContent
                  eventData={eventData}
                  EventPage={EventPage}
                  speaker0ImgRef={speaker0ImgRef}
                  speaker1ImgRef={speaker1ImgRef}
                  speaker2ImgRef={speaker2ImgRef}
                  speaker3ImgRef={speaker3ImgRef}
                  speaker4ImgRef={speaker4ImgRef}
                  speaker5ImgRef={speaker5ImgRef}
                />
              )}

              {eventData.generic?.agenda?.items?.length > 0 && (
                <AgendaContent
                  eventData={eventData}
                  EventPage={EventPage}
                  agendaData={eventData.generic.agenda}
                />
              )}
              {(eventData.generic.wheelchairAccessibility ||
                eventData.generic.signLanguageInterpreter ||
                eventData.generic.realtimeTranslations ||
                eventData.generic.accessibilityNotes?.length) > 0 && (
                <AccessibilityContent
                  genericData={eventData.generic}
                  EventPage={EventPage}
                />
              )}

              <TagsContent
                tagsData={eventData.tags}
                EventPage={EventPage}
                handleMobileTap={handleMobileTap}
              />
            </div>

            <ScrollToTop focusId={'actionBtns'} />
            <div className="h48" />
            <ReportEvent
              handleMobileTap={handleMobileTap}
              EventPage={EventPage}
              eventData={eventData}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EventPage;
