import { useState, useRef, useEffect } from 'react';
//Hooks
import useLanguageComponents from '../../language/useLanguageComponents';
import useSettings from '../../context/useSettings';

//Components
import ItemButton from '../ItemButton/ItemButton';
import MemoizedListItem from './MemoizedListItem';

//Utility
import ScrollTo from '../../utils/UI/ScrollTo';
import { exchange } from '../../assets/icons';

//drag-n-drop items based in memoizedListItem. Items have text, and a switch is used to toggle between startTime + endTime vs. value (number used for duration). Toggling between these values (and resetting them) occurs from the toggle switch in the DragDropList component (not the child).
//when accessing data, note that if value is empty "", then user is using startTime/endTime

const DragDropList = ({
  itemLimit,
  handleItems,
  handleFormat,
  title,
  counter,
  loadTrigger,
  loadValueItems,
  loadValueFormat,
  itemBtnId,
  autoScrollId,
}) => {
  //Hooks
  const { DragDropList } = useLanguageComponents();
  const { windowSettingsSize, handleMobileTap } = useSettings();

  //Component state
  const [items, setItems] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [toggle, setToggle] = useState('minutes');

  //UI state
  const [tapHighlightSwitchBtn, setTapHighlightSwitchBtn] = useState(false);

  //Component variables
  const effectRan = useRef(false);
  const listRef = useRef(null);
  let dragChange = useRef(null);

  //Initialize functions
  useEffect(() => {
    if (loadTrigger) {
      if (effectRan.current === false) {
        if (loadValueItems) {
          setItems(loadValueItems);
        }

        if (loadValueFormat) {
          setToggle(loadValueFormat);
        }
        effectRan.current = true;
      }
    }
  }, [loadTrigger]);

  //Component functions
  function handleItemChange(e) {
    setItems(e);
    handleItems(e);
  }

  function handleAddItem(e) {
    e.preventDefault();

    if (items.length < itemLimit) {
      const newItems = [
        ...items,
        {
          id: Date.now(),
          text: '',
          value: '',
          startTime: '00:00',
          endTime: '00:00',
        },
      ];
      setItems(newItems);
      handleItems(newItems);
    }
    ScrollTo(autoScrollId, 'smooth', false, autoScrollId);
  }

  function handleRemoveItem(indexToRemove) {
    // const scrollToId = items[indexToRemove - 1]?.id;
    // if (scrollToId) {
    //   ScrollTo(`${scrollToId}`, 'smooth', false, `${scrollToId}`);
    // } else {
    // ScrollTo(`${itemBtnId}`, 'smooth', false, `${itemBtnId}`);
    // } // auto scroll

    if (items?.length <= 1) {
      ScrollTo(autoScrollId, 'smooth', false, autoScrollId);
    }

    const newItems = items.filter((_, index) => index !== indexToRemove);
    setItems(newItems);
    handleItems(newItems);
  }

  function handleMoveItemUp(index, item) {
    const newItems = [...items];
    newItems.splice(index, 1); //remove item from arr
    newItems.splice(index - 1, 0, item);
    setItems(newItems);
    handleItems(newItems);
  }

  function handleMoveItemDown(index, item) {
    const newItems = [...items];
    newItems.splice(index, 1); //remove item from arr
    newItems.splice(index + 1, 0, item);
    setItems(newItems);
    handleItems(newItems);
  }

  function handleToggle() {
    if (toggle === 'minutes') {
      setToggle('clock');
      handleFormat('clock');
    } else {
      setToggle('minutes');
      handleFormat('minutes');
    }
  }

  //drag & drop
  const ghostContainer = document.getElementById('drag-drop__wrapper');

  function handleDragStart(e, index) {
    if (
      windowSettingsSize === 'desktop' ||
      windowSettingsSize === 'desktop--narrow'
    ) {
      e.dataTransfer.effectAllowed = 'move';
      setDraggingItem(items[index]);
      e.dataTransfer.setData('text/html', e.target.innerHTML);

      let ghost = document.createElement('div');
      ghost.id = 'drag-ghost';
      ghost.classList.add('drag-drop-ghost');

      ghost.textNode = 'Dragging';
      ghost.style = 'absolute';
      ghostContainer.appendChild(ghost);

      e.dataTransfer.setDragImage(ghost, 0, 0); //(image, xOffset, yOffset)
    }
  }

  //need to add a check so that this doesnt keep rendering until conditions actually change
  function handleDragOver(e, index) {
    e.preventDefault();
    if (
      windowSettingsSize === 'desktop' ||
      windowSettingsSize === 'desktop--narrow'
    ) {
      const newItems = [...items];
      const draggingItemIndex = items.findIndex(
        (item) => item.id === draggingItem.id
      );

      if (dragChange.current !== dragOverIndex) {
        newItems.splice(draggingItemIndex, 1); //remove item from arr
        newItems.splice(dragOverIndex, 0, draggingItem); //add moved item
        setItems(newItems);
        handleItems(newItems);
        dragChange.current = dragOverIndex;
        // setDragOverIndex(null);
      }
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    if (
      windowSettingsSize === 'desktop' ||
      windowSettingsSize === 'desktop--narrow'
    ) {
    }
  }

  function handleDragOverList(e) {
    e.preventDefault(); //keep for functionality
  }

  function handleDragEnter(e, index) {
    e.preventDefault(); //tracking position of draggable
    if (
      windowSettingsSize === 'desktop' ||
      windowSettingsSize === 'desktop--narrow'
    ) {
      setDragOverIndex(index);
    }
  }

  function handleDragEnd(e, index) {
    e.preventDefault();
    if (
      windowSettingsSize === 'desktop' ||
      windowSettingsSize === 'desktop--narrow'
    ) {
      if (dragOverIndex === dragChange.current) {
        setDragOverIndex(null);
      } else {
        const newItems = [...items];
        const draggingItemIndex = items.findIndex(
          (item) => item.id === draggingItem.id
        );
        newItems.splice(draggingItemIndex, 1); //remove item from arr
        newItems.splice(dragOverIndex, 0, draggingItem); //add moved item
        setItems(newItems);
        handleItems(newItems);
        setDragOverIndex(null);
        let ghost = document.getElementById('drag-ghost');
        if (ghost) {
          ghost.remove();
        }
      }
    }
    let ghost = document.getElementById('drag-ghost');
    if (ghost) {
      ghost.remove();
    }
  }

  return (
    <>
      {items.length > 0 && (
        <button
          className={`switch-option__container highlight-it-theme highlight-i-theme fs18 fwn no-select access-ob access-o6  mrg-b24${
            tapHighlightSwitchBtn
              ? 'highlight-i-theme--tap highlight-it-theme--tap'
              : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightSwitchBtn(true),
                () => setTapHighlightSwitchBtn(false),
              ],
              [() => handleToggle()]
            )
          }
        >
          <img
            src={exchange}
            alt="time/duration"
            aria-hidden="true"
            className="filter-lightgray mrg-r8"
          />
          {toggle ? (
            <p>{DragDropList.switchDurationToTime}</p>
          ) : (
            <p>{DragDropList.switchTimeToDuration}</p>
          )}
        </button>
      )}

      <div className="drag-drop__wrapper" id="drag-drop__wrapper">
        {counter && (
          <p className="mrg-b24">
            {title} {DragDropList.count}: {items.length} / {itemLimit}
          </p>
        )}
        {items.length < itemLimit && (
          <ItemButton
            text={`${DragDropList.addItem} (${items?.length})`}
            handler={handleAddItem}
            ariaLabel={DragDropList.addItem}
            itemButtonId={itemBtnId}
          />
        )}
        {items.length >= itemLimit && (
          <p className="mrg-t24">{DragDropList.maximumReached}</p>
        )}
        <ul
          className="mrg-t24"
          ref={listRef}
          onDragOver={(e) => handleDragOverList(e)}
          onDrop={(e) => handleDrop(e)}
        >
          {items.map((item, index) => (
            <MemoizedListItem
              item={item}
              items={items}
              handleItemChange={handleItemChange}
              index={index}
              handleMoveItemUp={handleMoveItemUp}
              handleMoveItemDown={handleMoveItemDown}
              dragOverIndex={dragOverIndex}
              draggingItem={draggingItem}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              handleDragEnter={handleDragEnter}
              handleDragEnd={handleDragEnd}
              handleRemoveItem={handleRemoveItem}
              toggle={toggle}
              charLimit={80}
              title={title}
              key={item.id}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default DragDropList;
