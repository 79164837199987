import { useEffect, useState } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import useSettings from '../../context/useSettings';
import useLanguageLayout from '../../language/useLanguageLayout';
import useLanguageData from '../../language/useLanguageData';

//Components

//Utils
import { dropdown_arrow, linkedin } from '../../assets/icons';

const Footer = ({ layoutIsMounting }) => {
  //Hooks
  const { accountId } = useAccountReduxHandlers();
  const { year, windowSettingsSize, contentLanguage, setContentLanguage } =
    useSettings();
  const { Footer } = useLanguageLayout();
  const { ContentLanguage, LanguageOpts } = useLanguageData();

  //Component state
  const [showLanguageOpts, setShowLanguageOpts] = useState(false);

  //UI state
  const [componentIsMounting, setComponentIsMounting] = useState(true);

  //Component variables
  // let languageSelectionMenu;

  //Initialize functions
  document.addEventListener('click', function (e) {
    if (
      showLanguageOpts &&
      e.target.id !== 'language-selection' &&
      e.target.id !== 'language-selection-text' &&
      e.target.id !== 'language-selection-img'
    ) {
      setShowLanguageOpts(false);
    }
  }); //closes language selection menu if opened

  //UI Transitions
  useEffect(() => {
    if (!layoutIsMounting) {
      const pageAnimationTimer = setTimeout(() => {
        setComponentIsMounting(false);
      }, 100);
      return () => clearTimeout(pageAnimationTimer);
    }
  }, [layoutIsMounting]);

  return (
    <footer
      className={`${
        componentIsMounting ? 'offscreen' : 'footer onscreen-fade-in-100ms'
      }`}
      aria-label={Footer.footer}
    >
      {windowSettingsSize !== 'desktop' &&
        windowSettingsSize !== 'desktop--narrow' &&
        !accountId && (
          <>
            <button
              className="modal-dropdown modal-dropdown--footer no-select fs18 fwsb color-white mrg-t12 access-ob access-o6"
              onClick={() => setShowLanguageOpts(!showLanguageOpts)}
              id="language-selection"
              tabIndex="0"
              aria-expanded={showLanguageOpts}
              aria-label={`${Footer.selectSiteLanguage} ${ContentLanguage}`}
            >
              {ContentLanguage}
              <img
                id="language-selection-img"
                src={dropdown_arrow}
                alt="arrow"
                className={`arrow svg ${
                  showLanguageOpts ? 'reverse-vertical' : ''
                }`}
                aria-hidden="true"
              />
            </button>
            {showLanguageOpts && (
              <ul className="modal-dropdown__button modal-dropdown__button--footer">
                <li>
                  <button
                    onClick={() => setContentLanguage('en-CA')}
                    className={`fs16 fwsb access-ob access-o-6 ${
                      contentLanguage === 'en-CA' ? 'selected' : ''
                    }`}
                    tabIndex="0"
                  >
                    {LanguageOpts['en-CA']}
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setContentLanguage('fr-CA')}
                    className={`fs16 fwsb access-ob access-o-6 ${
                      contentLanguage === 'fr-CA' ? 'selected' : ''
                    }`}
                    tabIndex="0"
                  >
                    {LanguageOpts['fr-CA']}
                  </button>
                </li>
              </ul>
            )}
          </>
        )}
      <div className="footer__links fs18 fwn color-white">
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          title={Footer.comingSoon}
        >
          {Footer.about}
        </button>
        <div className="footer-dot" />
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          title={Footer.comingSoon}
        >
          {Footer.contact}
        </button>
        <div className="footer-dot" />
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          title={Footer.comingSoon}
        >
          {Footer.userAgreement}
        </button>
        <div className="footer-dot" />
        <button
          tabIndex="0"
          className="fwn fs18 fs18--ph color-white access-ob access-o6"
          title={Footer.comingSoon}
        >
          {Footer.privacyPolicy}
        </button>
        <div className="footer-dot" />
        &copy;&nbsp;{`${year} MyndFull`}
      </div>
      <div className="flex-row  fs14 color-white">
        <p className="text-center mrg-b12">
          {Footer.recaptchaPt1}&nbsp;
          <a
            href="https://policies.google.com/privacy"
            className="text-underline color-white access-ob "
            target="_blank"
            rel="noreferrer"
          >
            {Footer.recaptchaPt2}
          </a>
          <span className="mrg-l6">{Footer.recaptchaPt3}</span>
          <a
            href="https://policies.google.com/terms"
            className="text-underline color-white mrg-l6 access-ob "
            target="_blank"
            rel="noreferrer"
          >
            {Footer.recaptchaPt4}
          </a>
          <span className="mrg-l6">{Footer.recaptchaPt5}</span>
        </p>
      </div>
      <a
        href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A89784148&keywords=myndfull&origin=RICH_QUERY_SUGGESTION&position=0&searchId=353b33b8-d1f3-478a-a051-a844ab85140a&sid=*cc"
        aria-label={Footer.visitOurLinkedInPage}
        className="access-ob access-o6"
      >
        <img
          src={linkedin}
          className="social-media filter-white"
          alt="linkedin link"
          aria-hidden="true"
        />
      </a>
    </footer>
  );
};

export default Footer;
