import { useEffect, useState } from 'react';

//hooks
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';
import { useTranslationRoomSocket } from '../../../../../context/sockets/TranslationRoomSocketProvider';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import Spinner from '../../../../../components/Spinner/Spinner';

//utility
import { image_gears } from '../../../../../assets/images';
import { DateFromTimeStamp } from '../../../../../utils/dateTime/DateCalc';

const CreateRoomRestoreData = ({ setPage }) => {
  const {
    restoreData,
    handleRestoreData,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    handleEjectAttendees,
  } = useTranslationRoomSocket();
  const { CreateRoomRestoreData } = useLanguageTranslationRoom();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations-selection__container ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_gears}
          alt="img"
          style={{ height: '200px' }}
          className="svg mrg-t24"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p
            className="fs21 fwsb mrg-tb24 access-ob access-o6"
            tabIndex="0"
            aria-label={CreateRoomRestoreData.restorePreviousSession}
            id={'createRoom'}
          >
            {CreateRoomRestoreData.restorePreviousSession}
          </p>

          <p className="fs18 fwn mrg-tb12">
            {DateFromTimeStamp(restoreData?.sessionEnded)}.
          </p>

          <ConfirmSelection
            btn1Text={CreateRoomRestoreData.ignore}
            btn1Color={'neutral'}
            btn1Handler={() => {
              handleRestoreData('ignore');
              handleEjectAttendees();

              handleCreatePageChange(2);
            }}
            btn2Text={CreateRoomRestoreData.restore}
            btn2Color={'pos'}
            btn2Handler={() => {
              handleRestoreData('restore');
              handleCreatePageChange(7);
            }}
          />

          <div className="divider full-width mrg-t36 mrg-b12" />
          <div className="h24" />

          <ItemButton
            text={CreateRoomRestoreData.exit}
            handler={() => setPage(1)}
            fullWidth={true}
          />
          <div className="h72" />
        </div>
      </div>
    </>
  );
};

export default CreateRoomRestoreData;
