import { useState } from 'react';

//Hooks
import useSettings from '../../context/useSettings';

//Components
import Spinner from '../Spinner/Spinner';

//Utility

const ButtonMeta = ({
  handleClick,
  ariaLabel,
  btnText,
  activeStateDependency,
  icon,
  iconAlt,
  customClassName,
  iconCustomClassName,
  unavailable,
  availabilityToggle,
  isLoading,
  fullWidth,
  customType,
  iconFilterOff,
}) => {
  //Hooks
  const { handleMobileTap } = useSettings();

  //UI
  const [tapHighlight, setTapHighlight] = useState(false);

  return (
    <button
      className={`button-meta fs18 access-ob access-o6 color-gray  ${
        tapHighlight ? 'highlight-b-theme--tap' : ''
      }  ${
        unavailable && availabilityToggle ? 'opacity-3 pointer-default' : ''
      }  ${!unavailable ? 'highlight-b-theme' : ''} ${customClassName} ${
        fullWidth ? 'full-width' : 'button-meta-width'
      } ${!iconFilterOff ? 'filter-img-gray' : ''} ${
        activeStateDependency ? 'button-meta--active' : ''
      } ${!iconFilterOff && activeStateDependency ? 'filter-img-theme' : ''} `}
      onClick={
        (unavailable && availabilityToggle) || isLoading
          ? null
          : (e) =>
              handleMobileTap(
                [() => setTapHighlight(true), () => setTapHighlight(false)],
                [() => handleClick(e)]
              )
      }
      aria-label={`${ariaLabel}.`}
      aria-pressed={activeStateDependency}
      type={customType ? customType : 'button'}
    >
      {isLoading ? (
        <Spinner minWidth={'100%'} maxHeight={'100%'} />
      ) : (
        <>
          {icon && (
            <img
              src={icon}
              alt={iconAlt || 'icon'}
              className={`mrg-r12 ${
                iconCustomClassName ? iconCustomClassName : ''
              }`}
            />
          )}
          <p className="mrg-t2">{btnText}</p>
          {activeStateDependency && <div className="dot" />}
        </>
      )}
    </button>
  );
};

export default ButtonMeta;
