import useSettings from '../../context/useSettings';

const useLanguageAccount = () => {
  const { contentLanguage } = useSettings();

  const Account = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        generic: {
          change: 'Change',
          cancel: 'Cancel',
          save: 'Save',
          remove: 'Remove',
          help: 'Help',
          arrow: 'Arrow',
          learnMore: 'Learn More',
          ariaMainMenu: 'Account Menu',
          ariaSaveUnavailable:
            'Save Button. No changed data to save, or requirements are not met.',
          ariaSaveAvailable: 'Save Button. Changed data can be saved.',
          link: 'Link',
          links: 'Links',
          ariaCloseAccountNavigationMenu: 'Close account navigation menu.',
          ariaAccountNavigationMenu: ' Account Navigation Menu.',
          ariaAccountNavigationMenuSelected: 'selected.',
          ariaPrimaryAccountNavigationButtonsMenu:
            'Primary account navigation buttons. To jump to the sub-sections for the current selection, see the list of  sub-selection buttons below.',
          ariaCurrentSelection: 'Current selection.',
        },
        general: {
          general: 'General',
          appSettings: 'App Settings',
          ariaPrimaryNavButton: 'General Settings primary navigation button.',
          ariaSecondaryMenu: 'General Settings sub-menu for quick navigation.',
        },
        account: {
          account: 'Account',
          accountCode: 'Account Code:',
          profilePhoto: 'Profile Photo',
          badges: {
            label: 'Badges',
            OGTester: 'Soft Launch Tester',
          },
          socialLinks: 'Social Links',
          name: 'Name',
          password: 'Password',
          phone: 'Phone',
          installWebApp: 'Install Web App',
          seeAll: 'See All',
          personalWebsite: 'Personal Website',
          pronouns: 'Pronouns',
          prefix: 'Prefix',
          firstName: 'First Name',
          lastName: 'Last Name',
          forgotPassword: 'Forgot Password?',
          emailAddress: 'Email',
          appLanguage: 'App Language',
          timeZone: 'Time Zone:',
          soundEffects: 'Sound Effects',
          activateAttendeeSection: 'Activate Attendee Section',
          activateSpeakerSection: 'Activate Speaker Section',
          activateOrganizerSection: 'Activate Organizer Section',
          installMyndFullWebApp: 'Install MyndFull Web App',
          ariaPrimaryNavButton: 'Account primary navigation button.',
          ariaSecondaryMenu: 'Account sub-menu for quick navigation.',
          ariaSocialLinks:
            'Add social links to be viewed in your profile. Use the button list below to change the input field to enter different social media links.',
        },
        billing: {
          billing: 'Billing',
          billingSummary: 'Billing Summary',
          ariaPrimaryNavButton: 'Billing primary navigation button.',
          ariaSecondaryMenu: 'Billing secondary menu for quick navigation.',
          paymentMethods: 'Payment Methods',
          roomCodes: 'Room Codes',
          invoices: 'Invoices',
          translationRooms: 'Translation Rooms',
          translationsUsage: 'Translations Usage',
        },
        emailPreferences: {
          emailPreferences: 'Email Preferences',
          email: 'Email',

          ariaPrimaryNavButton: 'Email Preferences primary navigation button.',
          ariaSecondaryMenu:
            'Email Preferences secondary menu for quick navigation.',
        },
        attendee: {
          attendee: 'Attendee',
          attendeeSignUp: 'Sign Up',
          bigTags: 'Big Tags',
          bigTagsDescription:
            'Choose scholastic fields/disciplines to find corresponding events.',
          ariaBigTagsInputInstructions:
            'Choose scholastic fields / disciplines to find corresponding events. For the best accessibility, use the See Full List button located below the input field to directly add big tags from a list. Alternatively, use this autocomplete field to find tags from the Big Tags list. Use the arrow up and arrow down keys to scroll through the generated list. Press enter to add the highlighted tag or backspace to remove characters from the input field.',
          seeFullList: 'See Full List',
          tinyTags: 'Tiny Tags',
          tinyTagsDescription:
            'Create tags that match your specific interests. These will be used for populating a list of curated events with matching tags. Only use letters and numbers. At least one tag is required.',
          eventSearchInPerson: 'Search (In Person)',
          eventSearchInPersonDescription:
            'In-Person event searches will be populated according to your search location radius.',
          switchToMiles: 'Switch to Miles',
          switchToKilometers: 'Switch to Kilometers',
          eventSearchOnline: 'Search (Online)',
          eventSearchOnlineDescription:
            'Limit your online event searches by adding one or more countries.',
          country: 'Country',
          searchLanguages: 'Search Languages',
          searchLanguagesDescription:
            'Events must include at least one of your selected search languages.',
          ignoredEvents: 'Ignored Events',
          ignoredEventsDescription:
            'View a list of your ignored events that are still active.',
          ignoredEventsClickToRemoveIgnore: 'Click to remove ignore',
          ignoredEventsClickToIgnore: 'Click to ignore',
          languageBtn: 'Language',
          fetchList: 'Fetch List',
          viewEvent: 'View Event Page',
          ignoreToggle: 'Ignore Toggle',
          noEventsFound: 'No events were found.',
          ariaPrimaryNavButton: 'Searches primary navigation button.',
          ariaSecondaryMenu: 'Searches secondary menu for quick navigation.',
          ariaEventIsIgnored: 'Event is set to ignored.',
          ariaEventIsNotIgnored: 'Event is not ignored.',
          ariaIgnoredList: 'List of ignored events that are still active.',
          network: 'Network',
          networkDescription:
            'Add, remove, or group MyndFull organizer accounts.',
        },
        speaker: {
          speaker: 'Speaker',
          ariaPrimaryNavButton: 'Speaker primary navigation button.',
          ariaSecondaryMenu: 'Speaker secondary menu for quick navigation.',
        },
        organizer: {
          organizer: 'Organizer',
          ariaPrimaryNavButton: 'Organizer primary navigation button.',
          ariaSecondaryMenu: 'Organizer secondary menu for quick navigation.',
        },
        translationRooms: {
          translations: 'Translations',
          roomCodeManager: 'Room Code Manager',
          newRoomCodeSubscriptions: 'New Room Code Subscriptions',
          translationRoomCodes: 'Translation Room Codes',
          ariaPrimaryNavButton: 'Translation Rooms primary navigation button.',
          ariaSecondaryMenu: 'Translation Rooms sub-menu for quick navigation.',
          info: 'When hosting a room, you can create a password that attendees will be required to enter. To streamline room host setup, toggle this option on and create a default attendee password. When a host creates a room with this room code, the host will have the option to choose the default attendee password that you created rather than create a new password each session.',
        },
        help: {
          help: 'Help',
          ariaPrimaryNavButton: 'Help primary navigation button.',
          ariaSecondaryMenu: 'Help secondary menu for quick navigation.',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        generic: {
          change: 'Changement',
          cancel: 'Annuler',
          save: 'Sauvegarder',
          remove: 'Supprimer',
          help: 'Aider',
          arrow: 'Flèche',
          learnMore: 'Apprendre encore plus',
          ariaMainMenu: 'Menu du compte',
          ariaSaveUnavailable:
            'Bouton Sauvegarder. Aucune donnée modifiée à enregistrer, ou les exigences ne sont pas remplies.',
          ariaSaveAvailable:
            'Bouton Sauvegarder. Les données modifiées peuvent être enregistrées.',
          link: 'Lien',
          links: 'Liens',
          ariaCloseAccountNavigationMenu:
            'Fermer le menu de navigation du compte.',
          ariaAccountNavigationMenu: 'Menu de navigation du compte.',
          ariaAccountNavigationMenuSelected: 'choisie.',
          ariaPrimaryAccountNavigationButtonsMenu:
            'Boutons de navigation du compte principal. Pour accéder aux sous-sections de la sélection actuelle, consultez la liste des boutons de sous-sélection ci-dessous.',
          ariaCurrentSelection: 'Sélection actuelle.',
        },
        general: {
          general: 'Général',
          appSettings: `Paramètres de l'application`,
          ariaPrimaryNavButton:
            'Bouton de navigation principale des paramètres généraux.',
          ariaSecondaryMenu:
            'Menu secondaire des paramètres généraux pour une navigation rapide.',
        },
        account: {
          account: 'Compte',
          accountCode: 'Code de compte:',
          profilePhoto: 'Photo de profil',
          badges: {
            label: 'Insignes',
            OGTester: 'Testeur de Soft Launch',
          },
          socialLinks: 'Liens sociaux',
          name: 'Nom',
          password: 'Mot de passe',
          emailAddress: 'E-mail',
          phone: 'Numéro de téléphone',
          installWebApp: 'Installer',
          seeAll: 'Voir tout',
          personalWebsite: 'Site web personnel',
          pronouns: 'Pronoms',
          prefix: 'Préfixe',
          firstName: 'Prénom',
          lastName: 'Nom de famille',
          forgotPassword: 'Mot de passe oublié?',
          appLanguage: `Langue de l'application`,
          timeZone: 'Fuseau horaire:',
          soundEffects: 'Effets sonores',
          activateAttendeeSection: 'Activer la section des participants',
          activateSpeakerSection: 'Activer la section conférencier',
          activateOrganizerSection: 'Activer la section organisateur',
          installMyndFullWebApp: 'Installer MyndFull Web App',
          ariaPrimaryNavButton: 'Bouton de navigation principale du compte.',
          ariaSecondaryMenu:
            'Menu secondaire du compte pour une navigation rapide.',
          ariaSocialLinks: `Ajoutez des liens sociaux à afficher dans votre profil. Utilisez la liste de boutons ci-dessous pour modifier le champ de saisie afin d'entrer différents liens de médias sociaux.`,
        },
        attendee: {
          attendee: 'Participant',
          attendeeSignUp: `S'inscrire`,
          bigTags: 'Grand Balises',
          bigTagsDescription: `Sélectionnez les domaines/disciplines scolaires pour trouver les événements correspondant`,
          seeFullList: 'Voir la liste complète',
          ariaBigTagsInputInstructions: `Choisissez les domaines scolaires / disciplines pour trouver les événements correspondants. Pour une meilleure accessibilité, utilisez le bouton Voir la liste complète situé sous le champ de saisie pour ajouter directement de grandes balises à partir d'une liste. Vous pouvez également utiliser ce champ de saisie semi-automatique pour rechercher des balises dans la liste Grande Balises. Community Verified icon Utilisez les flèches vers le haut et vers le bas pour faire défiler la liste générée. Appuyez sur Entrée pour ajouter la balise en surbrillance ou sur Retour arrière pour supprimer des caractères du champ de saisie.`,
          tinyTags: 'Petit Balises',
          tinyTagsDescription: `Créez des balise qui correspondent à vos intérêts spécifiques. Ceux-ci seront utilisés pour remplir une liste d'événements organisés avec des balise correspondantes. Utilisez uniquement des lettres et des chiffres. Au moins une étiquette est nécessaire.`,
          eventSearchInPerson: `Recherche (en personne)`,
          eventSearchInPersonDescription: `Les recherches d'événements en personne seront remplies en fonction du rayon de votre lieu de recherche.`,
          switchToMiles: 'Changer en Miles',
          switchToKilometers: 'Changer to Kilomètres',
          eventSearchOnline: `Recherche (en ligne)`,
          eventSearchOnlineDescription: `Limitez vos recherches d'événements en ligne en ajoutant un ou plusieurs pays.`,
          country: 'un pays',
          searchLanguages: 'Langues de recherche',
          searchLanguagesDescription:
            'Les événements doivent inclure au moins une des langues de recherche que vous avez sélectionnées.',
          ignoredEvents: `Événements ignorés`,
          ignoredEventsDescription: `Affichez une liste de vos événements ignorés qui sont toujours actifs.`,
          ignoredEventsClickToRemoveIgnore: 'Cliquez pour supprimer ignorer',
          ignoredEventsClickToIgnore: 'Cliquez pour ignorer',
          languageBtn: 'une langue',
          fetchList: 'Récupérer la liste',
          viewEvent: `Voir la page de l'événement`,
          ignoreToggle: 'Bascule Ignorer',
          noEventsFound: `Aucun événement n'a été trouvé.`,
          ariaPrimaryNavButton: 'Recherche le bouton de navigation principal.',
          ariaSecondaryMenu:
            'Recherche le menu secondaire pour une navigation rapide.',
          ariaEventIsIgnored: `L'événement est défini sur ignoré.`,
          ariaEventIsNotIgnored: `L'événement n'est plus ignoré.`,
          ariaIgnoredList: `Liste des événements ignorés qui sont toujours actifs.`,
          network: 'Réseau',
          networkDescription: `Ajouter, supprimer ou regrouper des comptes d'organisateur MyndFull.`,
        },
        billing: {
          billing: 'Facturation',
          billingSummary: 'Récapitulatif de facturation',
          ariaPrimaryNavButton:
            'Bouton de navigation principal de facturation.',
          ariaSecondaryMenu:
            'Menu secondaire de facturation pour une navigation rapide.',
          paymentMethods: 'Méthodes de payement',
          roomCodes: 'Codes de la salle',
          invoices: 'Factures',
          translationRooms: 'Salles de traduction',
          translationsUsage: 'Utilisation des traductions',
        },
        emailPreferences: {
          emailPreferences: 'Préférences de courriel',
          email: 'Courriel',
          ariaPrimaryNavButton:
            'Bouton de navigation principal pour les préférences de courriel.',
          ariaSecondaryMenu:
            'Menu secondaire pour une navigation rapide des préférences de courriel.',
        },
        speaker: {
          speaker: 'Orateurs',
          ariaPrimaryNavButton: 'Bouton de navigation principal du orateurs.',
          ariaSecondaryMenu:
            'Menu secondaire du orateurs pour une navigation rapide.',
        },
        organizer: {
          organizer: 'Organisateur',
          ariaPrimaryNavButton: `Bouton de navigation principal de l'organiseur.`,
          ariaSecondaryMenu: `Menu secondaire de l'organisateur pour une navigation rapide.`,
        },
        translationRooms: {
          translations: 'Traductions',
          roomCodeManager: 'Gestionnaire de codes de la salle',
          newRoomCodeSubscriptions: 'Nouveaux abonnements à code de la salle',
          translationRoomCodes: 'Codes des salles de traduction',
          ariaPrimaryNavButton: `Bouton de navigation principal des salles de traduction.`,
          ariaSecondaryMenu:
            'Sous-menu des salles de traduction pour une navigation rapide.',
        },
        help: {
          help: 'Aider',
          ariaPrimaryNavButton: `Bouton de navigation principal de l'aide.`,
          ariaSecondaryMenu: `Menu secondaire d'aide pour une navigation rapide.`,
        },
      };
    }

    return translation;
  };

  const AccountBadges = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        earlySupporter: 'Early Supporter',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        earlySupporter: 'Supporteur précoce',
      };
    }
    return translation;
  };

  const TranslationRoomCard = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        ariaValidLengthSuccess:
          'Success. Your password has 8 or more characters.',
        ariaValidLengthFail:
          'Warning. Your password does not have 8 or more characters.',
        valid: 'checkmark.',
        invalid: 'X',
        passwordRequirements: 'At least 8 Characters',
        roomCode: 'Room Code',
        hostPassword: 'Host password',
        defaultAttendeePasswordToggle: 'Create A Reusable Attendee Password',
        attendeePassword: 'Attendee Password',
        unsubscribe: 'Unsubscribe',
        newRoomCode: 'New Room Code',
        remove: 'Remove',
        subscription: 'Subscription',
        subscriptionEnding:
          'This subscription is scheduled to end at your next billing cycle.',
        restore: 'Restore',
        roomCodeLocked:
          'This room code is locked because it has an unpaid invoice. Please see Billing for more details.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        ariaValidLengthSuccess:
          'Succès. Votre mot de passe contient 8 caractères ou plus.',
        ariaValidLengthFail:
          'Avertissement. Votre mot de passe ne contient pas 8 caractères ou plus.',
        invalid: 'X',
        valid: 'coche.',
        passwordRequirements: 'Au moins 8 caractères.',
        roomCode: 'Code de la salle',
        hostPassword: `Mot de passe de l'hôte`,
        defaultAttendeePasswordToggle:
          'Créer un mot de passe de participant réutilisable',
        attendeePassword: 'Mot de passe du participant',
        unsubscribe: 'Se désabonner',
        newRoomCode: 'Nouveau code de la salle',
        remove: 'Retirer',
        subscription: 'Abonnement',
        subscriptionEnding:
          'Cet abonnement devrait prendre fin lors de votre prochain cycle de facturation.',
        restore: 'Restaurer',
        roomCodeLocked:
          'Ce code de salle est verrouillé car il comporte une facture impayée. Veuillez consulter Facturation pour plus de détails.',
      };
    }
    return translation;
  };

  const BillingPaymentMethods = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        paymentMethods: 'Payment Methods',
        addNewPaymentMethod: 'Add New Payment Method',
        cancel: 'Cancel',
        save: 'Save',
        billingAddress: 'Billing Address',
        newCard: 'New Card',
        primaryCard: 'Primary Card',
        secondaryCard: 'Secondary Card',
        expires: 'Expires',
        setAsPrimaryCard: 'Set as Primary Card',
        remove: 'Remove',
        saveAsPrimaryCard: 'Save as Primary Card',
        defaultPaymentMethod: '(Default payment method)',
        paymentMethodFailed: 'Payment method failed',
        noticePrimaryCardOutstandingInvoices:
          'Set a new Primary Card to pay all outstanding invoices.',
        noticeWaitForPaymentToProcess: `Please allow a moment for your payment to process. Your outstanding invoices will show as paid after refreshing your web browser.`,
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        paymentMethods: 'Méthodes de payement',
        addNewPaymentMethod: 'Ajouter un nouveau mode de paiement',
        cancel: 'Annuler',
        save: 'Sauvegarder',
        billingAddress: 'Adresse de facturation',
        newCard: 'Nouvelle carte',
        primaryCard: 'Carte principale',
        secondaryCard: 'Carte secondaire',
        expires: 'Expire',
        setAsPrimaryCard: 'Définir comme carte principale',
        remove: 'Retirer',
        saveAsPrimaryCard: 'Enregistrer comme carte principale',
        defaultPaymentMethod: '(Mode de paiement par défaut)',
        paymentMethodFailed: 'Échec du mode de paiement',
        noticePrimaryCardOutstandingInvoices:
          'Définissez une nouvelle carte principale pour payer toutes les factures impayées.',
        noticeWaitForPaymentToProcess: `Veuillez patienter un moment pour que votre paiement soit traité. Vos factures impayées apparaîtront comme payées après avoir actualisé votre navigateur Web.`,
      };
    }

    return translation;
  };

  const BillingSummary = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        billingSummary: 'Billing Summary',
        billingPeriod: 'Billing period',
        monthlyOverview: 'Monthly Overview',
        invoice: 'Invoice',
        noBillingSummaries: 'No Billing Summaries',
        roomCodeSubscriptions: 'Room Code Subscriptions',
        totalRoomCodes: 'Total Room Codes',
        subscriptionCharge: 'Subscription Charge',
        roomCodeUsage: 'Room Code Usage',
        totalWordCount: 'Total Word Count',
        usageCharge: 'Usage Charge',
        subtotal: 'Subtotal',
        total: 'Total',
        downloadInvoice: 'Download Full Invoice PDF',
        arrow: 'Arrow',
        upcoming: 'Upcoming',
        pending: 'Pending',
        prorationRefund: 'Proration Refund',
        tax: 'Tax',
        unpaidInvoices: 'You have unpaid invoices.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        billingSummary: 'Résumé de la facturation',
        billingPeriod: 'Période de facturation',
        monthlyOverview: 'Aperçu mensuel',
        invoice: 'Facture',
        noBillingSummaries: 'Aucun résumé de facturation',
        roomCodeSubscriptions: 'Abonnements aux codes de salle',
        totalRoomCodes: 'Total des codes de salle',
        subscriptionCharge: `Frais d'abonnement`,
        roomCodeUsage: 'Utilisation des codes de salle',
        totalWordCount: 'Nombre total de mots',
        usageCharges: "Frais d'utilisation",
        subtotal: 'Sous-total',
        total: 'Total',
        downloadInvoice: 'Télécharger la facture complète en PDF',
        arrow: 'Flèche',
        upcoming: 'À venir',
        pending: 'en cours',
        prorationRefund: 'Remboursement au prorata',
        tax: 'Impôt',
        unpaidInvoices: 'Vous avez des factures impayées.',
      };
    }

    return translation;
  };

  const BillingRoomCodeSubscriptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        translationUsage: 'Translations Usage',
        translationRoomHasNoUsage: 'Translation Room has no usage.',
        noTranslationRoomSubscription: 'No Translation Room subscription.',
        sessionId: 'Session Id',
        transcriptLength: 'Transcript Length',
        noteShortDelay:
          'Please note that there is a short delay to update Translation Rooms Usage.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        translationUsage: 'Utilisation des traduction',
        translationRoomHasNoUsage: `La salle de traduction n'a aucune utilité.`,
        noTranslationRoomSubscription: `Pas d'abonnement à la Salle de Traduction.`,
        sessionId: 'ID de session',
        transcriptLength: 'Longueur de la transcription',
        noteShortDelay: `Veuillez noter qu'il y a un court délai pour mettre à jour l'utilisation des salles de traduction.`,
      };
    }

    return translation;
  };

  const TranslationsNewRoomCodeSubscriptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        newRoomCodeSubscriptions: 'New Room Code Subscriptions',
        newRoomCode: 'New Room Code',
        addSubscriptions: 'Add Subscriptions',
        accountPaymentMethodRequired:
          'Your account requires a Credit Card to add Room Code Subscriptions.',
        remove: 'Remove',
        newRoomCodeSubscriptionsUnavailableWithUnpaidInvoices:
          'New room code subscriptions are not available while this account has unpaid invoices.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        newRoomCodeSubscriptions: 'Nouveaux abonnements à code de la salle',
        newRoomCode: 'Nouveau code de la salle',
        addSubscriptions: 'Ajouter des abonnements',
        accountPaymentMethodRequired:
          'Votre compte nécessite une carte de crédit pour ajouter des abonnements à code de la salle.',
        remove: 'Retirer',
        newRoomCodeSubscriptionsUnavailableWithUnpaidInvoices:
          'Les nouveaux abonnements à code de salle ne sont pas disponibles tant que ce compte a des factures impayées.',
      };
    }

    return translation;
  };

  const TranslationRoomCodeManager = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        roomCodeManager: 'Room Code Manager',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        roomCodeManager: 'Gestionnaire de codes de la salle',
      };
    }

    return translation;
  };

  const AttendeeSignUp = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        attendeeSignUp: 'Attendee Sign Up',
        instructions:
          'Sign up as an attendee by completing the required fields below then save.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        attendeeSignUp: 'Inscription des participants',
        instructions: `Inscrivez-vous en tant que participant en remplissant les champs obligatoires ci-dessous, puis enregistrez.`,
      };
    }

    return translation;
  };

  return {
    Account: Account(),
    AccountBadges: AccountBadges(),
    AttendeeSignUp: AttendeeSignUp(),
    TranslationRoomCard: TranslationRoomCard(),
    BillingPaymentMethods: BillingPaymentMethods(),
    BillingSummary: BillingSummary(),
    BillingRoomCodeSubscriptions: BillingRoomCodeSubscriptions(),
    TranslationRoomCodeManager: TranslationRoomCodeManager(),
    TranslationsNewRoomCodeSubscriptions:
      TranslationsNewRoomCodeSubscriptions(),
  };
};

export default useLanguageAccount;
