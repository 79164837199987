import { useEffect, useState } from 'react';

//Hooks
import useGenericModal from '../../../context/useGenericModal';

//Components
import AdminLogModal from '../../../components/Modal/ModalComponents/AdminLogModal/AdminLogModal';

//Utility
import { getDateOrTimeFromUTZstring } from '../../../utils/dateTime/DateCalc';

const AdminLogs = ({ adminLogsData }) => {
  //hooks
  const { setGenericModal } = useGenericModal();

  //component state
  const [orderedList, setOrderedList] = useState(adminLogsData);

  //variables

  //initialize functions
  useEffect(() => {
    if (adminLogsData) {
      const sortedData = [...adminLogsData]?.sort((a, b) => {
        const dateA = new Date(a.meta.createdAt);
        const dateB = new Date(b.meta.createdAt);

        return dateB - dateA;
      });

      setOrderedList(sortedData);
    }
  }, [adminLogsData]);

  //Component functions
  function handleViewLog(adminLogData) {
    setGenericModal(<AdminLogModal adminLogData={adminLogData} />);
  }

  return (
    <div className="flex-column">
      <table className="admin-table">
        <thead>
          <tr>
            <th colSpan="1">Admin</th>
            <th colSpan="1">Action</th>

            <th colSpan="1">Details</th>
            <th colSpan="1">Date (UTC)</th>
            <th colSpan="1">Time (UTC)</th>

            <th colSpan="1">Log Data</th>
          </tr>
        </thead>
        <tbody>
          {orderedList &&
            [...orderedList]?.map((log, index) => (
              <tr key={`log-${index}`}>
                <td>
                  {log.logData.actionBy?.adminId?.personal.firstName}{' '}
                  {log.logData.actionBy?.adminId?.personal.lastName}
                </td>
                <td>{log.logData.action}</td>
                <td style={{ maxWidth: '464px' }}>{log.logData.notes}</td>
                <td>
                  {getDateOrTimeFromUTZstring(log.meta.createdAt, 'date')}
                </td>
                <td>
                  {getDateOrTimeFromUTZstring(log.meta.createdAt, 'time')}
                </td>
                <td>
                  <button
                    className="fs18 fwn color-blue"
                    onClick={() => handleViewLog(log)}
                  >
                    View Log
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminLogs;
