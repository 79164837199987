//Hooks

//Components
import Participant from '../../../../components/Participant/Participant';

//Utility

const SpeakersContent = ({
  eventData,
  EventPage,
  speaker0ImgRef,
  speaker1ImgRef,
  speaker2ImgRef,
  speaker3ImgRef,
  speaker4ImgRef,
  speaker5ImgRef,
}) => {
  //Component variables
  let moderator;
  let speakerHeading;
  let speaker0;
  let speaker1;
  let speaker2;
  let speaker3;
  let speaker4;
  let speaker5;

  //JSX conditional
  if (eventData.participants?.moderator) {
    moderator = (
      <>
        <label
          className="content-heading-styled text-gray mrg-b12"
          tabIndex="0"
          id="moderatorLabel"
        >
          {EventPage.moderator}
        </label>
        <Participant participantData={eventData.participants.moderator} />
      </>
    );
  }

  let speaker0ImageRef = null;
  let speaker1ImageRef = null;
  let speaker2ImageRef = null;
  let speaker3ImageRef = null;
  let speaker4ImageRef = null;
  let speaker5ImageRef = null;

  if (speaker0ImgRef && typeof speaker0ImgRef.handleGetSpeaker === 'function') {
    speaker0ImageRef = speaker0ImgRef.handleGetSpeaker();
  }

  if (speaker1ImgRef && typeof speaker1ImgRef.handleGetSpeaker === 'function') {
    speaker1ImageRef = speaker1ImgRef.handleGetSpeaker();
  }

  if (speaker2ImgRef && typeof speaker2ImgRef.handleGetSpeaker === 'function') {
    speaker2ImageRef = speaker2ImgRef.handleGetSpeaker();
  }

  if (speaker3ImgRef && typeof speaker3ImgRef.handleGetSpeaker === 'function') {
    speaker3ImageRef = speaker3ImgRef.handleGetSpeaker();
  }

  if (speaker4ImgRef && typeof speaker4ImgRef.handleGetSpeaker === 'function') {
    speaker4ImageRef = speaker4ImgRef.handleGetSpeaker();
  }

  if (speaker5ImgRef && typeof speaker5ImgRef.handleGetSpeaker === 'function') {
    speaker5ImageRef = speaker5ImgRef.handleGetSpeaker();
  }

  if (
    eventData.participants.speaker0Data ||
    eventData.participants.speaker1Data ||
    eventData.participants.speaker2Data ||
    eventData.participants.speaker3Data ||
    eventData.participants.speaker4Data ||
    eventData.participants.speaker5Data
  ) {
    speakerHeading = (
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="speakersLabel"
      >
        {EventPage.speakers}
      </label>
    );
  }

  if (eventData.participants?.speaker0Data || speaker0ImgRef) {
    speaker0 = (
      <Participant
        participantData={eventData.participants.speaker0Data}
        speakerImgRef={speaker0ImageRef}
      />
    );
  }

  if (eventData.participants?.speaker1Data || speaker1ImgRef) {
    speaker1 = (
      <Participant
        participantData={eventData.participants.speaker1Data}
        speakerImgRef={speaker1ImageRef}
      />
    );
  }

  if (eventData.participants?.speaker2Data || speaker2ImgRef) {
    speaker2 = (
      <Participant
        participantData={eventData.participants.speaker2Data}
        speakerImgRef={speaker2ImageRef}
      />
    );
  }

  if (eventData.participants?.speaker3Data || speaker3ImgRef) {
    speaker3 = (
      <Participant
        participantData={eventData.participants.speaker3Data}
        speakerImgRef={speaker3ImageRef}
      />
    );
  }

  if (eventData.participants?.speaker4Data || speaker4ImgRef) {
    speaker4 = (
      <Participant
        participantData={eventData.participants.speaker4Data}
        speakerImgRef={speaker4ImageRef}
      />
    );
  }

  if (eventData.participants?.speaker5Data || speaker5ImgRef) {
    speaker5 = (
      <Participant
        participantData={eventData.participants.speaker5Data}
        speakerImgRef={speaker5ImageRef}
      />
    );
  }

  return (
    <div className="flex-column">
      {moderator}
      {speakerHeading}
      {speaker0}
      {speaker1}
      {speaker2}
      {speaker3}
      {speaker4}
      {speaker5}
    </div>
  );
};

export default SpeakersContent;
