import { useEffect, useState } from 'react';

//hooks
import useSettings from '../../../../context/useSettings';
import useLanguageAccount from '../../../../language/features/useLanguageAccount';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import { AccountSubMenuNav } from './AccountSubMenuNav';

//Utility
import ScrollTo from '../../../../utils/UI/ScrollTo';
import {
  exchange,
  help,
  icon_profile,
  img_messages,
  img_money,
  organize,
  search_dark,
  settings,
  speaker_dark,
} from '../../../../assets/icons';

const AccountNav = () => {
  //Hooks
  const { Account } = useLanguageAccount();
  const { Generic } = useLanguageComponents();
  const { windowSettingsSize } = useSettings();
  const { handleSettingsSetAccountSettingsView, settingsAccountSettingsView } =
    useSettingsHandlers();

  //Component state
  const [repositionSelectionsContainer, setRepositionSelectionsContainer] =
    useState(false);

  //Initialize & UI functions
  useEffect(() => {
    ScrollTo('', 'instant', true);
  }, [settingsAccountSettingsView]);

  if (
    windowSettingsSize === 'desktop' ||
    windowSettingsSize === 'desktop--narrow' ||
    'mobile'
  ) {
    //adjust selections container so it fills up navbar space when you scroll down
    window.addEventListener('scroll', function () {
      let distanceScrolled = window.scrollY;
      if (distanceScrolled > 10) {
        setRepositionSelectionsContainer(true);
      } else {
        setRepositionSelectionsContainer(false);
      }
    });
  }

  return (
    <div
      role="navigation"
      className="nav-menu__wrapper"
      aria-label={Account.generic.ariaMainMenu}
    >
      <div className="h12" />
      <ul
        className="nav-menu__container access-ob access-o-6"
        style={repositionSelectionsContainer ? { top: '0px' } : null}
        tabIndex="0"
      >
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
              ${settingsAccountSettingsView === 'general' ? ' selected' : ''}`}
            onClick={() => handleSettingsSetAccountSettingsView('general')}
            tabIndex="0"
            aria-label={`${Account.account.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'general' ? true : false
            }
          >
            <img
              src={settings}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.general.general}
          </button>
          {settingsAccountSettingsView === 'general' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
              ${settingsAccountSettingsView === 'account' ? ' selected' : ''}`}
            onClick={() => handleSettingsSetAccountSettingsView('account')}
            tabIndex="0"
            aria-label={`${Account.account.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'account' ? true : false
            }
          >
            <img
              src={icon_profile}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.account.account}
          </button>
          {settingsAccountSettingsView === 'account' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${settingsAccountSettingsView === 'billing' ? ' selected' : ''}`}
            onClick={() => handleSettingsSetAccountSettingsView('billing')}
            tabIndex="0"
            aria-label={`${Account.billing.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'billing' ? true : false
            }
          >
            <img
              src={img_money}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.billing.billing}
          </button>
          {settingsAccountSettingsView === 'billing' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'emailPreferences'
                   ? ' selected'
                   : ''
               }`}
            onClick={() =>
              handleSettingsSetAccountSettingsView('emailPreferences')
            }
            tabIndex="0"
            aria-label={`${Account.emailPreferences.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'emailPreferences' ? true : false
            }
          >
            <img
              src={img_messages}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.emailPreferences.email}
          </button>
          {settingsAccountSettingsView === 'emailPreferences' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'attendee' ? ' selected' : ''
               }`}
            onClick={() => handleSettingsSetAccountSettingsView('attendee')}
            tabIndex="0"
            aria-label={`${Account.attendee.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'attendee' ? true : false
            }
          >
            <img
              src={search_dark}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.attendee.attendee}
          </button>
          {settingsAccountSettingsView === 'attendee' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${settingsAccountSettingsView === 'speaker' ? ' selected' : ''}`}
            onClick={() => handleSettingsSetAccountSettingsView('speaker')}
            tabIndex="0"
            aria-label={`${Account.speaker.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'speaker' ? true : false
            }
          >
            <img
              src={speaker_dark}
              alt={Generic.icon}
              className="primary-heading-icon--speaker filter-gray mrg-r12"
            />
            {Account.speaker.speaker}
          </button>
          {settingsAccountSettingsView === 'speaker' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'organizer' ? ' selected' : ''
               }`}
            onClick={() => handleSettingsSetAccountSettingsView('organizer')}
            tabIndex="0"
            aria-label={`${Account.organizer.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'organizer' ? true : false
            }
          >
            <img
              src={organize}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.organizer.organizer}
          </button>
          {settingsAccountSettingsView === 'organizer' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${
                 settingsAccountSettingsView === 'translationRooms'
                   ? 'selected'
                   : ''
               }`}
            onClick={() =>
              handleSettingsSetAccountSettingsView('translationRooms')
            }
            tabIndex="0"
            aria-label={`${Account.translationRooms.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'translationRooms' ? true : false
            }
          >
            <img
              src={exchange}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.translationRooms.translations}
          </button>
          {settingsAccountSettingsView === 'translationRooms' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
               ${settingsAccountSettingsView === 'help' ? ' selected' : ''}`}
            onClick={() => handleSettingsSetAccountSettingsView('help')}
            tabIndex="0"
            aria-label={`${Account.help.ariaPrimaryNavButton}.`}
            aria-expanded={
              settingsAccountSettingsView === 'help' ? true : false
            }
          >
            <img
              src={help}
              alt={Generic.icon}
              className="primary-heading-icon filter-gray mrg-r12"
            />
            {Account.help.help}
          </button>
          {settingsAccountSettingsView === 'help' && (
            <AccountSubMenuNav windowSettingsSize={windowSettingsSize} />
          )}
        </li>
      </ul>
    </div>
  );
};

export default AccountNav;
