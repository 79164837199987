import { createSlice } from '@reduxjs/toolkit';

const adminSettingsSlice = createSlice({
  name: 'adminSettings',
  initialState: {
    tables: {
      ticket: {
        headings: [120, 120, 120, 120, 120, 120, 120],
        subHeadings: [[60, 60]],
        sortingDate: 'descending',
      },
      managementTicketsTable: {
        dateEnd: '',
        dateStart: '',
        hasMore: true,
        issue: [],
        page: 1,
        resultsPerPage: 20,
        status: [],
        submitUser: false,
        submitUserFilter: '',
        submitUserInput: '',
        submitUserSearchType: 'name',
        supportUser: false,
        supportUserInput: '',
        supportUserSearchType: 'name',
        type: [],
      },
      eventVerifications: {
        headingsSize: [120, 120, 120, 120, 120, 120, 120],
        subHeadingsSize: [[60, 60]],
        sortingDate: 'descending',
      },
      technical: {
        headingsSize: [120, 120, 120, 120, 120, 120, 120],
        subHeadingsSize: [[60, 60], [120]],
        sortingDate: 'descending',
      },
    },
  },
  reducers: {
    //Tickets table
    setAdminSettingsTablesTicketHeadings: (state, action) => {
      state.tables.ticket.headings = action.payload;
    },
    setAdminSettingsTablesTicketSubHeadings: (state, action) => {
      state.tables.ticket.subHeadings = action.payload;
    },
    resetAdminSettingsTablesTicketHeadings: (state, action) => {
      state.tables.ticket.headings = [120, 120, 120, 120, 120, 120, 120];
    },
    resetAdminSettingsTablesTicketSubHeadings: (state, action) => {
      state.tables.ticket.subHeadings = [[60, 60]];
    },
    setAdminSettingsTablesTicketSortingDate: (state, action) => {
      state.tables.ticket.sortingDate = action.payload;
    },

    //Admin Management - Tickets Table Query
    setAdminSettingsManagementTicketsTableHasMore: (state, action) => {
      state.tables.managementTicketsTable.hasMore = action.payload;
    },
    setAdminSettingsManagementTicketsTableStatus: (state, action) => {
      state.tables.managementTicketsTable.status = action.payload;
    },
    setAdminSettingsManagementTicketsTableIssue: (state, action) => {
      state.tables.managementTicketsTable.issue = action.payload;
    },
    setAdminSettingsManagementTicketsTableType: (state, action) => {
      state.tables.managementTicketsTable.type = action.payload;
    },
    setAdminSettingsManagementTicketsTableDateStart: (state, action) => {
      state.tables.managementTicketsTable.dateStart = action.payload;
    },
    setAdminSettingsManagementTicketsTableDateEnd: (state, action) => {
      state.tables.managementTicketsTable.dateEnd = action.payload;
    },
    setAdminSettingsManagementTicketsTableSupportUser: (state, action) => {
      state.tables.managementTicketsTable.supportUser = action.payload;
    },
    setAdminSettingsManagementTicketsTableSupportUserInput: (state, action) => {
      state.tables.managementTicketsTable.supportUserInput = action.payload;
    },
    setAdminSettingsManagementTicketsTableSupportUserSearchType: (
      state,
      action
    ) => {
      state.tables.managementTicketsTable.supportUserSearchType =
        action.payload;
    },

    setAdminSettingsManagementTicketsTableSubmitUser: (state, action) => {
      state.tables.managementTicketsTable.submitUser = action.payload;
    },
    setAdminSettingsManagementTicketsTableSubmitUserSearchType: (
      state,
      action
    ) => {
      state.tables.managementTicketsTable.submitUserSearchType = action.payload;
    },
    setAdminSettingsManagementTicketsTableSubmitUserInput: (state, action) => {
      state.tables.managementTicketsTable.submitUserInput = action.payload;
    },
    setAdminSettingsManagementTicketsTableSubmitUserFilter: (state, action) => {
      state.tables.managementTicketsTable.submitUserFilter = action.payload;
    },
    setAdminSettingsManagementTicketsTablePage: (state, action) => {
      state.tables.managementTicketsTable.page = action.payload;
    },
    setAdminSettingsManagementTicketsTableResultsPerPage: (state, action) => {
      state.tables.managementTicketsTable.resultsPerPage = action.payload;
    },

    //Event Review
    setAdminSettingsEventVerificationsHeadingsSize: (state, action) => {
      state.tables.eventVerifications.headingsSize = action.payload;
    },
    setAdminSettingsEventVerificationsSubHeadingsSize: (state, action) => {
      state.tables.eventVerifications.subHeadingsSize = action.payload;
    },
    resetAdminSettingsEventVerificationsHeadingsSize: (state, action) => {
      state.tables.eventVerifications.headingsSize = [
        120, 120, 120, 120, 120, 120,
      ];
    },
    resetAdminSettingsEventVerificationsSubHeadingsSize: (state, action) => {
      state.tables.eventVerifications.subHeadingsSize = [[60, 60]];
    },
    setAdminSettingsEventVerificationsSortingDate: (state, action) => {
      state.tables.eventVerifications.sortingDate = action.payload;
    },

    //Technical
    setAdminSettingsTechnicalHeadingsSize: (state, action) => {
      state.tables.technical.headingsSize = action.payload;
    },
    setAdminSettingsTechnicalSubHeadingsSize: (state, action) => {
      state.tables.technical.subHeadingsSize = action.payload;
    },
    resetAdminSettingsTechnicalHeadingsSize: (state, action) => {
      state.tables.technical.headingsSize = [120, 120, 120, 120, 120, 120];
    },
    resetAdminSettingsTechnicalSubHeadingsSize: (state, action) => {
      state.tables.technical.subHeadingsSize = [[60, 60], [120]];
    },
    setAdminSettingsTechnicalSortingDate: (state, action) => {
      state.tables.technical.sortingDate = action.payload;
    },
  },
});

export const {
  setAdminSettingsTablesTicketHeadings,
  setAdminSettingsTablesTicketSubHeadings,
  resetAdminSettingsTablesTicketHeadings,
  resetAdminSettingsTablesTicketSubHeadings,

  //Sorting
  setAdminSettingsTablesTicketSortingDate,

  //Admin Management - Tickets Table Query
  setAdminSettingsManagementTicketsTableHasMore,
  setAdminSettingsManagementTicketsTableStatus,
  setAdminSettingsManagementTicketsTableIssue,
  setAdminSettingsManagementTicketsTableType,
  setAdminSettingsManagementTicketsTableDateStart,
  setAdminSettingsManagementTicketsTableDateEnd,
  setAdminSettingsManagementTicketsTableSupportUser,
  setAdminSettingsManagementTicketsTableSupportUserSearchType,
  setAdminSettingsManagementTicketsTableSupportUserInput,
  setAdminSettingsManagementTicketsTableSubmitUser,
  setAdminSettingsManagementTicketsTableSubmitUserSearchType,
  setAdminSettingsManagementTicketsTableSubmitUserInput,
  setAdminSettingsManagementTicketsTableSubmitUserFilter,
  setAdminSettingsManagementTicketsTablePage,
  setAdminSettingsManagementTicketsTableResultsPerPage,

  //Event Review
  setAdminSettingsEventVerificationsHeadingsSize,
  setAdminSettingsEventVerificationsSubHeadingsSize,
  resetAdminSettingsEventVerificationsHeadingsSize,
  resetAdminSettingsEventVerificationsSubHeadingsSize,
  setAdminSettingsEventVerificationsSortingDate,

  //Technical
  setAdminSettingsTechnicalHeadingsSize,
  resetAdminSettingsTechnicalHeadingsSize,
  setAdminSettingsTechnicalSubHeadingsSize,
  resetAdminSettingsTechnicalSubHeadingsSize,
  setAdminSettingsTechnicalSortingDate,
} = adminSettingsSlice.actions;

export default adminSettingsSlice.reducer;

//Tables Ticket
export const selectAdminSettingsSelectTablesTicketHeadings = (state) =>
  state.adminSettings?.tables?.ticket?.headings;
export const selectAdminSettingsSelectTablesTicketSubHeadings = (state) =>
  state.adminSettings?.tables?.ticket?.subHeadings;
export const selectAdminSettingsTablesTicketSortingDate = (state) =>
  state.adminSettings?.tables?.ticket?.sortingDate;

//Admin Management - Tickets Table Query
export const selectAdminSettingsManagementTicketsTableHasMore = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.hasMore;
export const selectAdminSettingsManagementTicketsTableStatus = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.status;
export const selectAdminSettingsManagementTicketsTableIssue = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.issue;
export const selectAdminSettingsManagementTicketsTableType = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.type;
export const selectAdminSettingsManagementTicketsTableDateStart = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.dateStart;
export const selectAdminSettingsManagementTicketsTableDateEnd = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.dateEnd;

//CURRENT SUPPORT
export const selectAdminSettingsManagementTicketsTableSupportUserInput = (
  state
) => state.adminSettings?.tables?.managementTicketsTable?.supportUserInput;

export const selectAdminSettingsManagementTicketsTableSupportUser = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.supportUser;

export const selectAdminSettingsManagementTicketsTableSupportUserSearchType = (
  state
) => state.adminSettings?.tables?.managementTicketsTable?.supportUserSearchType;

//SUBMIT USER
export const selectAdminSettingsManagementTicketsTableSubmitUser = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.submitUser;

export const selectAdminSettingsManagementTicketsTableSubmitUserSearchType = (
  state
) => state.adminSettings?.tables?.managementTicketsTable?.submitUserSearchType;
export const selectAdminSettingsManagementTicketsTableSubmitUserInput = (
  state
) => state.adminSettings?.tables?.managementTicketsTable?.submitUserInput;

export const selectAdminSettingsManagementTicketsTableSubmitUserFilter = (
  state
) => state.adminSettings?.tables?.managementTicketsTable?.submitUserFilter;

export const selectAdminSettingsManagementTicketsTablePage = (state) =>
  state.adminSettings?.tables?.managementTicketsTable?.page;

export const selectAdminSettingsManagementTicketsTableResultsPerPage = (
  state
) => state.adminSettings?.tables?.managementTicketsTable?.resultsPerPage;

//Event Review
export const selectAdminSettingsEventVerificationsHeadingsSize = (state) =>
  state.adminSettings?.tables?.eventVerifications?.headingsSize;
export const selectAdminSettingsEventVerificationsSubHeadingsSize = (state) =>
  state.adminSettings?.tables?.eventVerifications?.subHeadingsSize;
export const selectAdminSettingsEventVerificationsSortingDate = (state) =>
  state.adminSettings?.tables?.eventVerifications?.sortingDate;

//Technical
export const selectAdminSettingsTechnicalHeadingsSize = (state) =>
  state.adminSettings?.tables?.technical?.headingsSize;
export const selectAdminSettingsTechnicalSubHeadingsSize = (state) =>
  state.adminSettings?.tables?.technical?.subHeadingsSize;
export const selectAdminSettingsTechnicalSortingDate = (state) =>
  state.adminSettings?.tables?.technical?.sortingDate;
