import { useEffect, useRef, useState } from 'react';

//Hooks
import { useCreateEventReduxHandlers } from './useCreateEventHandlers';
import useApiStatus from '../../../../context/useApiStatus';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import useReCaptcha from '../../../../hooks/useReCaptcha';
import { useOrganizeHandlers } from '../useOrganizeHandlers';

//Components

import Initialize from '../eventSections/Initialize';
import General from '../eventSections/General';
import Participants from '../eventSections/Participants';
import Promotion from '../eventSections/Promotion';
import Access from '../eventSections/Access';
import Registration from '../eventSections/Registration';
// import Administration from '../eventSections/Administration';
import Publish from '../eventSections/Publish';
import EventPage from '../../events/EventPage/EventPage';
import LoadingScreenContent from '../../../../components/LoadingScreenContent/LoadingScreenContent';
import Schedule from '../eventSections/Schedule';
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';

//Utility
import { checkImagePath } from '../../../../utils/images/checkImagePath';
import Spinner from '../../../../components/Spinner/Spinner';
import ScrollTo from '../../../../utils/UI/ScrollTo';

const CreateEvent = ({
  createEvent,
  createEventisLoading,
  createDraftEvent,
  createDraftEventisLoading,
  setEventPageLoadComplete,
}) => {
  //Hooks
  const { handleError, handleSuccess } = useApiStatus();
  const { handleReCaptchaVerify } = useReCaptcha();

  const { handleOrganizerEventFormData } = useOrganizeHandlers();
  const {
    handleSettingsSetOrganizeView,
    handleSettingsSetOrganizeOverviewContent,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
    // settingsViewEventDetails,
    // handleSettingsSetViewEventDetails,
  } = useSettingsHandlers();

  const {
    //handlers
    handleCreateEventReset,

    //DateTime
    handleCreateEventSetDate,
    handleCreateEventSetTimeZone,
    handleCreateEventSetTimeStart,
    handleCreateEventSetTimeEnd,

    //General
    handleCreateEventSetTitle,
    handleCreateEventSetDescription,
    handleCreateEventSetEventType,
    handleCreateEventSetAudienceType,
    handleCreateEventSetAgendaFormat,
    handleCreateEventSetAgendaItems,
    handleCreateEventSetLanguages,
    handleCreateEventSetEventImagePath,

    //Access
    handleCreateEventClearLocation,
    handleCreateEventSetCoordinates,
    handleCreateEventSetHostingOption,
    handleCreateEventSetLocation,
    handleCreateEventSetAccessibilityNotes,
    handleCreateEventSetSignLanguageInterpreter,
    handleCreateEventSetWheelchairAccessibility,
    handleCreateEventSetRealtimeTranslations,

    //Schedule
    handleCreateEventSetSchedulePublishDate,
    handleCreateEventSetSchedulePublishTime,
    handleCreateEventSetSchedulePublishTimeZone,
    handleCreateEventSetScheduleEnabled,

    //Participants
    handleCreateEventSetModerator,
    handleCreateEventSetSpeaker0Data,
    handleCreateEventSetSpeaker1Data,
    handleCreateEventSetSpeaker2Data,
    handleCreateEventSetSpeaker3Data,
    handleCreateEventSetSpeaker4Data,
    handleCreateEventSetSpeaker5Data,

    //Hosting
    handleCreateEventSetVirtualMeetingId,
    handleCreateEventSetVirtualPasscode,
    handleCreateEventSetVirtualPlatform,
    handleCreateEventSetVirtualMeetingLink,

    //Registrations
    handleCreateEventSetApproveRegistrations,
    handleCreateEventSetMaxRegistrationsInPersonEnabled,
    handleCreateEventSetMaxRegistrationsVirtualEnabled,
    handleCreateEventSetEnablePrivateDetails,
    handleCreateEventSetMaxRegistrationsInPersonAmount,
    handleCreateEventSetMaxRegistrationsVirtualAmount,
    handleCreateEventSetPrivateDetails,
    handleCreateEventSetRegistrations,

    //Tags
    handleCreateEventAddTinyTag,
    handleCreateEventRemoveTinyTag,
    handleCreateEventAddBigTag,
    handleCreateEventRemoveBigTag,
    handleCreateEventSetBigTags,
    handleCreateEventSetTinyTags,

    //Generic
    title: loadTitle,
    description: loadDescription,
    eventType: loadEventType,
    audienceType: loadAudienceType,
    date: loadDate,
    languages: loadLanguages,
    timeZone: loadTimeZone,
    timeStart: loadTimeStart,
    timeEnd: loadTimeEnd,
    agendaItems: loadAgendaItems,
    agendaFormat: loadAgendaFormat,
    realtimeTranslations: loadRealtimeTranslations,
    signLanguageInterpreter: loadSignLanguageInterpreter,
    wheelchairAccessibility: loadWheelchairAccessibility,
    accessibilityNotes: loadAccessibilityNotes,
    eventImagePath: loadEventImagePath,

    //Hosting
    hostingOption: loadHostingOption,
    coordinates: loadCoordinates,
    virtualMeetingId: loadVirtualMeetingId,
    virtualPasscode: loadVirtualPasscode,
    virtualPlatform: loadVirtualPlatform,
    virtualLink: loadVirtualLink,
    location: loadLocation,

    //Participants
    moderator: loadModerator,
    speaker0Data: loadSpeaker0Data,
    speaker1Data: loadSpeaker1Data,
    speaker2Data: loadSpeaker2Data,
    speaker3Data: loadSpeaker3Data,
    speaker4Data: loadSpeaker4Data,
    speaker5Data: loadSpeaker5Data,

    //Registrations
    approveRegistrations: loadApproveRegistrations,
    maxRegistrationsInPersonEnabled: loadMaxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled: loadMaxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount: loadMaxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount: loadMaxRegistrationsVirtualAmount,
    registrationsActive: loadRegistrationsActive,
    privateDetails: loadPrivateDetails,
    enablePrivateDetails: loadEnablePrivateDetails,

    //Schedule
    scheduleEnabled: loadScheduleEnabled,
    schedulePublishDate: loadSchedulePublishDate,
    schedulePublishTime: loadSchedulePublishTime,
    schedulePublishTimeZone: loadSchedulePublishTimeZone,

    //Tags
    bigTags: loadBigTags,
    tinyTags: loadTinyTags,
  } = useCreateEventReduxHandlers();

  //Component state
  const [initialize, setInitialize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activatePreviewMode, setActivatePreviewMode] = useState(false);
  const [previewEventDetailsObj, setPreviewEventDetailsObj] = useState();

  //Component variables
  const eventImageRef = useRef(null);
  const speaker0ImgRef = useRef(null);
  const speaker1ImgRef = useRef(null);
  const speaker2ImgRef = useRef(null);
  const speaker3ImgRef = useRef(null);
  const speaker4ImgRef = useRef(null);
  const speaker5ImgRef = useRef(null);

  //these hold the ref image by using imperative to get state inside corresponding component
  let speaker0Img;
  let speaker1Img;
  let speaker2Img;
  let speaker3Img;
  let speaker4Img;
  let speaker5Img;

  let eventImg;

  //API functions
  async function handleSubmit(eventData, handleType, exit) {
    const recaptchaVerified = await handleReCaptchaVerify(
      'CREATE_EVENT',
      false
    );
    if (recaptchaVerified !== true) return false;

    const formData = await handleOrganizerEventFormData(
      handleType,
      eventData,
      eventImageRef,
      speaker0ImgRef,
      speaker1ImgRef,
      speaker2ImgRef,
      speaker3ImgRef,
      speaker4ImgRef,
      speaker5ImgRef,
      speaker0Img,
      speaker1Img,
      speaker2Img,
      speaker3Img,
      speaker4Img,
      speaker5Img,
      eventImg
    );

    try {
      if (handleType === 'publish') {
        const res = await createEvent(formData);

        if (!res.error) {
          handleSuccess({
            message: 'eventCreated',
            id: Date.now(),
            origin: 'CreateEvent.js/handleSubmit',
          });

          if (exit) {
            if (eventImg) {
              URL.revokeObjectURL(eventImg.imagePreview);
            }
            if (speaker0Img) {
              URL.revokeObjectURL(speaker0Img.imagePreview);
            }
            if (speaker1Img) {
              URL.revokeObjectURL(speaker1Img.imagePreview);
            }
            if (speaker2Img) {
              URL.revokeObjectURL(speaker2Img.imagePreview);
            }
            if (speaker3Img) {
              URL.revokeObjectURL(speaker3Img.imagePreview);
            }
            if (speaker4Img) {
              URL.revokeObjectURL(speaker4Img.imagePreview);
            }
            if (speaker5Img) {
              URL.revokeObjectURL(speaker5Img.imagePreview);
            }

            //need to check for image path
            if (
              res.data.data.newEvent?.generic?.eventImagePath &&
              res.data.data.newEvent?.generic?.eventImagePath !==
                'event_img_default.png'
            ) {
              await checkImagePath(
                res.data.data.newEvent.generic.eventImagePath,
                'event'
              );
            }
            handleCreateEventReset();
            setEventPageLoadComplete(false);

            if (eventData?.schedule?.enabled) {
              handleSettingsSetOrganizeOverviewStandaloneGridEvents(
                'scheduled'
              );
            } else {
              handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
            }

            handleSettingsSetOrganizeOverviewContent('standalone');
            handleSettingsSetOrganizeView('overview');
          }
        }
      }

      if (handleType === 'draft') {
        const res = await createDraftEvent(formData);

        if (!res.error) {
          handleSuccess({
            message: 'draftSaved',
            id: Date.now(),
            origin: 'CreateEvent.js/handleSubmit',
          });

          if (exit) {
            if (!res.error) {
              if (eventImg) {
                URL.revokeObjectURL(eventImg.imagePreview);
              }
              if (speaker0Img) {
                URL.revokeObjectURL(speaker0Img.imagePreview);
              }
              if (speaker1Img) {
                URL.revokeObjectURL(speaker1Img.imagePreview);
              }
              if (speaker2Img) {
                URL.revokeObjectURL(speaker2Img.imagePreview);
              }
              if (speaker3Img) {
                URL.revokeObjectURL(speaker3Img.imagePreview);
              }
              if (speaker4Img) {
                URL.revokeObjectURL(speaker4Img.imagePreview);
              }
              if (speaker5Img) {
                URL.revokeObjectURL(speaker5Img.imagePreview);
              }

              handleCreateEventReset();
              setEventPageLoadComplete(false);

              //prep settings slice for navigation
              handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
              handleSettingsSetOrganizeOverviewContent('standalone');
              handleSettingsSetOrganizeView('overview');
            }
          }
        }
      }
    } catch (error) {
      handleError(
        {
          message: 'tryAgain',
          statusCode: 500,
        },
        'CreateEvent/handleSubmit'
      );
    }
  }

  //Component functions
  function handleReturn() {
    setActivatePreviewMode(false);
    setPreviewEventDetailsObj();
    const animationTimer = setTimeout(() => {
      ScrollTo('publishLabel', 'instant', false, 'publishLabel');
    }, 100);

    return () => clearTimeout(animationTimer);
  }

  useEffect(() => {
    if (isLoading) {
      let timeout1;

      timeout1 = setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return () => {
        if (timeout1) {
          clearTimeout(timeout1);
        }
      };
    }
  }, [isLoading]); //helps reset components

  return (
    <>
      <div className={`flex-column ${previewEventDetailsObj ? 'remove' : ''}`}>
        <div className="create-event__wrapper">
          <div className="create-event">
            <div className="h24" />
            <Initialize
              setInitialize={setInitialize}
              initialize={initialize}
              handleSubmit={handleSubmit}
              setIsLoading={setIsLoading}
            />
            {initialize && !isLoading ? (
              <>
                <General
                  initialize={initialize}
                  handleSetTitle={handleCreateEventSetTitle}
                  handleSetDescription={handleCreateEventSetDescription}
                  handleSetEventType={handleCreateEventSetEventType}
                  handleSetAudienceType={handleCreateEventSetAudienceType}
                  handleSetDate={handleCreateEventSetDate}
                  handleSetTimeZone={handleCreateEventSetTimeZone}
                  handleSetTimeStart={handleCreateEventSetTimeStart}
                  handleSetTimeEnd={handleCreateEventSetTimeEnd}
                  handleSetAgendaFormat={handleCreateEventSetAgendaFormat}
                  handleSetAgendaItems={handleCreateEventSetAgendaItems}
                  handleSetLanguages={handleCreateEventSetLanguages}
                  loadTitle={loadTitle}
                  loadDescription={loadDescription}
                  loadEventType={loadEventType}
                  loadAudienceType={loadAudienceType}
                  loadDate={loadDate}
                  loadLanguages={loadLanguages}
                  loadTimeZone={loadTimeZone}
                  loadTimeStart={loadTimeStart}
                  loadTimeEnd={loadTimeEnd}
                  loadAgendaItems={loadAgendaItems}
                  loadAgendaFormat={loadAgendaFormat}
                />
                <Participants
                  speaker0ImgRef={speaker0ImgRef}
                  speaker1ImgRef={speaker1ImgRef}
                  speaker2ImgRef={speaker2ImgRef}
                  speaker3ImgRef={speaker3ImgRef}
                  speaker4ImgRef={speaker4ImgRef}
                  speaker5ImgRef={speaker5ImgRef}
                  initialize={initialize}
                  editEventMode={false}
                  pauseCrop={previewEventDetailsObj ? true : false}
                  handleSetModerator={handleCreateEventSetModerator}
                  loadModerator={loadModerator}
                  loadSpeaker0Data={loadSpeaker0Data}
                  loadSpeaker1Data={loadSpeaker1Data}
                  loadSpeaker2Data={loadSpeaker2Data}
                  loadSpeaker3Data={loadSpeaker3Data}
                  loadSpeaker4Data={loadSpeaker4Data}
                  loadSpeaker5Data={loadSpeaker5Data}
                  handleSetSpeaker0Data={handleCreateEventSetSpeaker0Data}
                  handleSetSpeaker1Data={handleCreateEventSetSpeaker1Data}
                  handleSetSpeaker2Data={handleCreateEventSetSpeaker2Data}
                  handleSetSpeaker3Data={handleCreateEventSetSpeaker3Data}
                  handleSetSpeaker4Data={handleCreateEventSetSpeaker4Data}
                  handleSetSpeaker5Data={handleCreateEventSetSpeaker5Data}
                />
                <Access
                  initialize={initialize}
                  edit={false}
                  handleSetAccessibilityNotes={
                    handleCreateEventSetAccessibilityNotes
                  }
                  handleClearLocation={handleCreateEventClearLocation}
                  handleSetCoordinates={handleCreateEventSetCoordinates}
                  handleSetHostingOption={handleCreateEventSetHostingOption}
                  handleSetLocation={handleCreateEventSetLocation}
                  handleSetSignLanguageInterpreter={
                    handleCreateEventSetSignLanguageInterpreter
                  }
                  handleSetVirtualMeetingId={
                    handleCreateEventSetVirtualMeetingId
                  }
                  handleSetVirtualPasscode={handleCreateEventSetVirtualPasscode}
                  handleSetVirtualPlatform={handleCreateEventSetVirtualPlatform}
                  handleSetVirtualMeetingLink={
                    handleCreateEventSetVirtualMeetingLink
                  }
                  handleSetWheelchairAccessibility={
                    handleCreateEventSetWheelchairAccessibility
                  }
                  handleSetRealtimeTranslations={
                    handleCreateEventSetRealtimeTranslations
                  }
                  loadHostingOption={loadHostingOption}
                  loadVirtualPlatform={loadVirtualPlatform}
                  loadVirtualMeetingId={loadVirtualMeetingId}
                  loadVirtualPasscode={loadVirtualPasscode}
                  loadVirtualLink={loadVirtualLink}
                  loadLocation={loadLocation}
                  loadCoordinates={loadCoordinates}
                  loadWheelchairAccessibility={loadWheelchairAccessibility}
                  loadSignLanguageInterpreter={loadSignLanguageInterpreter}
                  loadAccessibilityNotes={loadAccessibilityNotes}
                  loadRealtimeTranslations={loadRealtimeTranslations}
                />
                <Registration
                  initialize={initialize}
                  handleSetApproveRegistrations={
                    handleCreateEventSetApproveRegistrations
                  }
                  handleSetMaxRegistrationsInPersonEnabled={
                    handleCreateEventSetMaxRegistrationsInPersonEnabled
                  }
                  handleSetMaxRegistrationsVirtualEnabled={
                    handleCreateEventSetMaxRegistrationsVirtualEnabled
                  }
                  handleSetEnablePrivateDetails={
                    handleCreateEventSetEnablePrivateDetails
                  }
                  handleSetMaxRegistrationsInPersonAmount={
                    handleCreateEventSetMaxRegistrationsInPersonAmount
                  }
                  handleSetMaxRegistrationsVirtualAmount={
                    handleCreateEventSetMaxRegistrationsVirtualAmount
                  }
                  handleSetPrivateDetails={handleCreateEventSetPrivateDetails}
                  handleSetRegistrations={handleCreateEventSetRegistrations}
                  loadHostingOption={loadHostingOption}
                  loadMaxRegistrationsInPersonEnabled={
                    loadMaxRegistrationsInPersonEnabled
                  }
                  loadMaxRegistrationsVirtualEnabled={
                    loadMaxRegistrationsVirtualEnabled
                  }
                  loadEnablePrivateDetails={loadEnablePrivateDetails}
                  loadRegistrationsActive={loadRegistrationsActive}
                  loadApproveRegistrations={loadApproveRegistrations}
                  loadMaxRegistrationsInPersonAmount={
                    loadMaxRegistrationsInPersonAmount
                  }
                  loadMaxRegistrationsVirtualAmount={
                    loadMaxRegistrationsVirtualAmount
                  }
                  loadPrivateDetails={loadPrivateDetails}
                />
                <Promotion
                  ref={eventImageRef}
                  pauseCrop={previewEventDetailsObj ? true : false}
                  editEventMode={true}
                  handleAddTinyTag={handleCreateEventAddTinyTag}
                  handleRemoveTinyTag={handleCreateEventRemoveTinyTag}
                  handleAddBigTag={handleCreateEventAddBigTag}
                  handleRemoveBigTag={handleCreateEventRemoveBigTag}
                  handleSetBigTags={handleCreateEventSetBigTags}
                  handleSetTinyTags={handleCreateEventSetTinyTags}
                  handleSetEventImagePath={handleCreateEventSetEventImagePath}
                  loadTinyTags={loadTinyTags}
                  loadBigTags={loadBigTags}
                  loadEventImagePath={loadEventImagePath}
                />
                <Schedule
                  initialize={initialize}
                  handleSetSchedulePublishDate={
                    handleCreateEventSetSchedulePublishDate
                  }
                  handleSetSchedulePublishTime={
                    handleCreateEventSetSchedulePublishTime
                  }
                  handleSetSchedulePublishTimeZone={
                    handleCreateEventSetSchedulePublishTimeZone
                  }
                  handleSetScheduleEnabled={handleCreateEventSetScheduleEnabled}
                  loadSchedulePublishDate={loadSchedulePublishDate}
                  loadSchedulePublishTime={loadSchedulePublishTime}
                  loadSchedulePublishTimeZone={loadSchedulePublishTimeZone}
                  loadScheduleEnabled={loadScheduleEnabled}
                />
                <Publish
                  handleSubmit={handleSubmit}
                  createDraftEventisLoading={createDraftEventisLoading}
                  createEventisLoading={createEventisLoading}
                  setEventPreview={setPreviewEventDetailsObj}
                />
                <ScrollToTop focusId={'initializeLabel'} />
              </>
            ) : isLoading ? (
              <Spinner />
            ) : null}
            <div className="h48" />
          </div>
        </div>
      </div>
      {!activatePreviewMode && previewEventDetailsObj && (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          setLoadComplete={() => setActivatePreviewMode(true)}
          loadCheck1={previewEventDetailsObj ? true : false}
          customWrapper={'min-full-height'}
        />
      )}
      {previewEventDetailsObj && (
        <div className={`${!activatePreviewMode ? 'remove' : ''}`}>
          <EventPage
            organizerViewOn={true}
            eventImageRef={eventImageRef.current}
            previewEventDetailsObj={previewEventDetailsObj}
            handlePreviewReturn={handleReturn}
            speaker0ImgRef={speaker0ImgRef.current}
            speaker1ImgRef={speaker1ImgRef.current}
            speaker2ImgRef={speaker2ImgRef.current}
            speaker3ImgRef={speaker3ImgRef.current}
            speaker4ImgRef={speaker4ImgRef.current}
            speaker5ImgRef={speaker5ImgRef.current}
          />
        </div>
      )}
    </>
  );
};

export default CreateEvent;
