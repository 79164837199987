import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  generic: {
    accountIdVerification: '',
    lastRoomCode: '',
    persist: true,
    loginEmail: '',
    viewEventDetails: '',
  },
  account: {
    accountSettingsView: 'account',
  },
  searches: {
    general: {
      eventSearchCategory: 'date',
      eventSearchDirection: 'ascending',
      eventSearchFormat: 'inPerson',
      eventSearchView: 'bigtags',
    },
    bigTags: {
      selectedBigTags: [],
      bigTagsListOpened: true,
      bTQueryHasMore: false,
      bTQueryPage: 0,
      selectAllBTBtn: false,
    },
    myEvents: {
      myEventsListOpened: true,
      myEventsView: 'registered',
    },
    network: {
      selectedAccountsFollowingIds: [],
      selectedAccountsFollowingGroupIds: [],
      accountsFollowingListOpened: true,
      selectAllAccountsFollowingBtn: false,
      networkQueryHasMore: false,
      networkQueryPage: 0,
    },
    tinyTags: {
      selectAllTTBtn: false,
      selectedTinyTagGroupIds: [],
      selectedTinyTags: [],
      tinyTagsListOpened: true,
      tTQueryHasMore: false,
      tTQueryPage: 0,
    },
  },
  organize: {
    general: {
      organizeView: 'overview',
    },
    overview: {
      organizeOverviewSortCategory: 'date',
      organizeOverviewDirection: 'ascending',
      organizeOverviewFormat: 'cards', //cards, table, dash
      selectedOrganizers: [],
      selectAllOrganizersBtn: false,
      organizeOverviewListOpened: true,
      content: 'standalone',
      standaloneEvents: 'active',
    },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    //Generic
    settingsSetAccountIdVerification: (state, action) => {
      state.generic.accountIdVerification = action.payload;
    },
    settingsReset: (state) => {
      return initialState;
    },
    settingsSetLastRoomCode: (state, action) => {
      state.generic.lastRoomCode = action.payload;
    },
    settingsSetPersist: (state, action) => {
      state.generic.persist = action.payload;
    },
    settingsSetLoginEmail: (state, action) => {
      state.generic.loginEmail = action.payload;
    },
    settingsSetViewEventDetails: (state, action) => {
      state.generic.viewEventDetails = action.payload;
    },

    //Account
    settingsSetAccountSettingsView: (state, action) => {
      state.account.accountSettingsView = action.payload;
    },

    //Searches - general
    settingsSetEventSearchCategory: (state, action) => {
      state.searches.general.eventSearchCategory = action.payload;
    },
    settingsSetEventSearchDirection: (state, action) => {
      state.searches.general.eventSearchDirection = action.payload;
    },
    settingsSetEventSearchFormat: (state, action) => {
      state.searches.general.eventSearchFormat = action.payload;
    },
    settingsSetEventSearchView: (state, action) => {
      state.searches.general.eventSearchView = action.payload;
    },

    //Searches - bigTags
    settingsAddSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags.push(action.payload);
    },
    settingsRemoveAllSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags = [];
    },
    settingsRemoveSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags.splice(
        state.searches.bigTags.selectedBigTags.indexOf(action.payload),
        1
      );
    },
    settingsSetBigTagsListOpened: (state, action) => {
      state.searches.bigTags.bigTagsListOpened = action.payload;
    },
    settingsSetSelectAllBTBtn: (state, action) => {
      state.searches.bigTags.selectAllBTBtn = action.payload;
    },
    settingsSetSelectedBigTags: (state, action) => {
      state.searches.bigTags.selectedBigTags = action.payload;
    },
    settingsSetBTQueryHasMore: (state, action) => {
      state.searches.bigTags.bTQueryHasMore = action.payload;
    },
    settingsSetBTQueryPage: (state, action) => {
      state.searches.bigTags.bTQueryPage = action.payload;
    },

    //Searches - myEvents
    settingsSetMyEventsListOpened: (state, action) => {
      state.searches.myEvents.myEventsListOpened = action.payload;
    },
    settingsSetMyEventsView: (state, action) => {
      state.searches.myEvents.myEventsView = action.payload;
    },

    //Searches - Network
    settingsSetSelectAllAccountsFollowingBtn: (state, action) => {
      state.searches.network.selectAllAccountsFollowingBtn = action.payload;
    },
    settingsSetAccountsFollowingListOpened: (state, action) => {
      state.searches.network.accountsFollowingListOpened = action.payload;
    },
    settingsSetNetworkQueryHasMore: (state, action) => {
      state.searches.network.networkQueryHasMore = action.payload;
    },
    settingsSetNetworkQueryPage: (state, action) => {
      state.searches.network.networkQueryPage = action.payload;
    },
    settingsSetSelectedAccountsFollowingIds: (state, action) => {
      state.searches.network.selectedAccountsFollowingIds = action.payload;
    },
    settingsAddSelectedAccountsFollowingId: (state, action) => {
      state.searches.network.selectedAccountsFollowingIds.push(action.payload);
    },
    settingsRemoveAllSelectedAccountsFollowingIds: (state, action) => {
      state.searches.network.selectedAccountsFollowingIds = [];
    },
    settingsRemoveSelectedAccountsFollowingId: (state, action) => {
      state.searches.network.selectedAccountsFollowingIds.splice(
        state.searches.network.selectedAccountsFollowingIds.indexOf(
          action.payload
        ),
        1
      );
    },

    //Network Groups
    settingsAddSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.network.selectedAccountsFollowingGroupIds.push(
        action.payload
      );
    },
    settingsRemoveAllSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.network.selectedAccountsFollowingGroupIds = [];
    },
    settingsRemoveSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.network.selectedAccountsFollowingGroupIds.splice(
        state.searches.network.selectedAccountsFollowingGroupIds.indexOf(
          action.payload
        ),
        1
      );
    },
    settingsSetSelectedAccountsFollowingGroupIds: (state, action) => {
      state.searches.network.selectedAccountsFollowingGroupIds = action.payload;
    },

    //Searches - tinyTags
    settingsSetTinyTagsListOpened: (state, action) => {
      state.searches.tinyTags.tinyTagsListOpened = action.payload;
    },
    settingsSetTTQueryHasMore: (state, action) => {
      state.searches.tinyTags.tTQueryHasMore = action.payload;
    },
    settingsSetTTQueryPage: (state, action) => {
      state.searches.tinyTags.tTQueryPage = action.payload;
    },
    settingsSetSelectAllTTBtn: (state, action) => {
      state.searches.tinyTags.selectAllTTBtn = action.payload;
    },

    settingsAddSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags.push(action.payload);
    },
    settingsRemoveSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags.splice(
        state.searches.tinyTags.selectedTinyTags.indexOf(action.payload),
        1
      );
    },
    settingsRemoveAllSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags = [];
    },
    settingsSetSelectedTinyTags: (state, action) => {
      state.searches.tinyTags.selectedTinyTags = action.payload;
    },

    //Tiny Tag groups
    settingsAddSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds.push(action.payload);
    },
    settingsRemoveAllSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds = [];
    },
    settingsRemoveSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds.splice(
        state.searches.tinyTags.selectedTinyTagGroupIds.indexOf(action.payload),
        1
      );
    },
    settingsSetSelectedTinyTagGroupIds: (state, action) => {
      state.searches.tinyTags.selectedTinyTagGroupIds = action.payload;
    },

    //Organize
    settingsSetOrganizeView: (state, action) => {
      state.organize.general.organizeView = action.payload;
    },
    settingsSetOrganizeOverviewSortCategory: (state, action) => {
      state.organize.overview.organizeOverviewSortCategory = action.payload;
    },
    settingsSetOrganizeOverviewDirection: (state, action) => {
      state.organize.overview.organizeOverviewDirection = action.payload;
    },
    settingsSetOrganizeOverviewFormat: (state, action) => {
      state.organize.overview.organizeOverviewFormat = action.payload;
    },
    settingsSetSelectAllOrganizersBtn: (state, action) => {
      state.organize.overview.selectAllOrganizersBtn = action.payload;
    },
    settingsSetStandaloneGridEvents: (state, action) => {
      state.organize.overview.standaloneEvents = action.payload;
    },
    settingsSetOrganizeOverviewListOpened: (state, action) => {
      state.organize.overview.organizeOverviewListOpened = action.payload;
    },
    settingsAddSelectedOrganizer: (state, action) => {
      state.organize.overview.selectedOrganizers.push(action.payload);
    },
    settingsRemoveSelectedOrganizer: (state, action) => {
      state.organize.overview.selectedOrganizers.splice(
        state.organize.overview.selectedOrganizers.indexOf(action.payload),
        1
      );
    },
    settingsSetSelectedOrganizers: (state, action) => {
      state.organize.overview.selectedOrganizers = action.payload;
    },
    settingsSetOrganizeOverviewContent: (state, action) => {
      state.organize.overview.content = action.payload;
    },
  },
});

export const {
  //Generic
  settingsSetAccountIdVerification,
  settingsSetLastRoomCode,
  settingsReset,
  settingsSetPersist,
  settingsSetLoginEmail,
  settingsSetViewEventDetails,

  //Account
  settingsSetAccountSettingsView,

  //Searches - general
  settingsSetEventSearchCategory,
  settingsSetEventSearchDirection,
  settingsSetEventSearchFormat,
  settingsSetEventSearchView,

  //Searches - big tags
  settingsAddSelectedBigTags,
  settingsRemoveAllSelectedBigTags,
  settingsRemoveSelectedBigTags,
  settingsSetBigTagsListOpened,
  settingsSetSelectAllBTBtn,
  settingsSetSelectedBigTags,
  settingsSetBTQueryHasMore,
  settingsSetBTQueryPage,

  //Searches - myEvents
  settingsSetMyEventsListOpened,
  settingsSetMyEventsView,

  //Searches - network
  settingsAddSelectedAccountsFollowingId,
  settingsSetNetworkQueryHasMore,
  settingsSetNetworkQueryPage,
  settingsRemoveAllSelectedAccountsFollowingIds,
  settingsRemoveSelectedAccountsFollowingId,
  settingsSetAccountsFollowingListOpened,
  settingsSetSelectedAccountsFollowingIds,
  settingsSetSelectAllAccountsFollowingBtn,

  //Searches - network groups
  settingsAddSelectedAccountsFollowingGroupIds,
  settingsRemoveAllSelectedAccountsFollowingGroupIds,
  settingsRemoveSelectedAccountsFollowingGroupIds,
  settingsSetSelectedAccountsFollowingGroupIds,

  //Searches - tiny tags
  settingsAddSelectedTinyTags,
  settingsRemoveAllSelectedTinyTags,
  settingsRemoveSelectedTinyTags,
  settingsSetSelectedTinyTags,
  settingsSetTinyTagsListOpened,
  settingsSetTTQueryHasMore,
  settingsSetTTQueryPage,
  settingsSetSelectAllTTBtn,

  //Searches - tiny tag groups
  settingsAddSelectedTinyTagGroupIds,
  settingsRemoveAllSelectedTinyTagGroupIds,
  settingsRemoveSelectedTinyTagGroupIds,
  settingsSetSelectedTinyTagGroupIds,

  //Organize
  settingsSetOrganizeView,
  settingsSetOrganizeOverviewSortCategory,
  settingsSetOrganizeOverviewDirection,
  settingsSetOrganizeOverviewFormat,
  settingsSetSelectAllOrganizersBtn,
  settingsSetOrganizeOverviewListOpened,
  settingsAddSelectedOrganizer,
  settingsRemoveSelectedOrganizer,
  settingsSetSelectedOrganizers,
  settingsSetOrganizeOverviewContent,
  settingsSetStandaloneGridEvents,
} = settingsSlice.actions;

export default settingsSlice.reducer;

//Generic
export const selectSettingsAccountIdVerification = (state) =>
  state.settings?.generic?.accountIdVerification;

export const selectSettingsLastRoomCode = (state) =>
  state.settings?.generic?.lastRoomCode;

export const selectSettingsPersist = (state) =>
  state.settings?.generic?.persist;

export const selectSettingsLoginEmail = (state) =>
  state.settings?.generic?.loginEmail;

export const selectSettingsSetViewEventDetails = (state) =>
  state.settings?.generic?.viewEventDetails;

//Account
export const selectSettingsAccountSettingsView = (state) =>
  state.settings?.account?.accountSettingsView;

//Searches - General
export const selectSettingsEventSearchCategory = (state) =>
  state.settings?.searches?.general?.eventSearchCategory;

export const selectSettingsEventSearchDirection = (state) =>
  state.settings?.searches?.general?.eventSearchDirection;

export const selectSettingsEventSearchFormat = (state) =>
  state.settings?.searches?.general?.eventSearchFormat;

export const selectSettingsEventSearchView = (state) =>
  state.settings?.searches?.general?.eventSearchView;

//Searches - BigTags
export const selectSettingsSelectedBigTags = (state) =>
  state.settings?.searches?.bigTags?.selectedBigTags;

export const selectSettingsBigTagsListOpened = (state) =>
  state.settings?.searches?.bigTags?.bigTagsListOpened;

export const selectSettingsBTQueryHasMore = (state) =>
  state.settings?.searches?.bigTags?.bTQueryHasMore;

export const selectSettingsBTQueryPage = (state) =>
  state.settings?.searches?.bigTags?.bTQueryPage;

export const selectSettingsSelectAllBTBtn = (state) =>
  state.settings?.searches?.bigTags?.selectAllBTBtn;

//Searches - MyEvents
export const selectSettingsMyEventsListOpened = (state) =>
  state.settings?.searches?.myEvents?.myEventsListOpened;

export const selectSettingsMyEventsView = (state) =>
  state.settings?.searches?.myEvents?.myEventsView;

//Searches - Network
export const selectSettingsSelectedAccountsFollowingIds = (state) =>
  state.settings?.searches?.network?.selectedAccountsFollowingIds;

export const selectSettingsAccountsFollowingListOpened = (state) =>
  state.settings?.searches?.network?.accountsFollowingListOpened;

export const selectSettingsNetworkQueryHasMore = (state) =>
  state.settings?.searches?.network?.networkQueryHasMore;

export const selectSettingsNetworkQueryPage = (state) =>
  state.settings?.searches?.network?.networkQueryPage;

export const selectSettingsSelectAllAccountsFollowingBtn = (state) =>
  state.settings?.searches?.network?.selectAllAccountsFollowingBtn;

export const selectSettingsSelectedAccountsFollowingGroupIds = (state) =>
  state.settings?.searches?.network?.selectedAccountsFollowingGroupIds;

//Searches - Tiny Tags
export const selectSettingsSelectAllTTBtn = (state) =>
  state.settings?.searches?.tinyTags?.selectAllTTBtn;

export const selectSettingsSelectedTinyTagGroupIds = (state) =>
  state.settings?.searches?.tinyTags?.selectedTinyTagGroupIds;

export const selectSettingsSelectedTinyTags = (state) =>
  state.settings?.searches?.tinyTags?.selectedTinyTags;

export const selectSettingsTinyTagsListOpened = (state) =>
  state.settings?.searches?.tinyTags?.tinyTagsListOpened;

export const selectSettingsTTQueryHasMore = (state) =>
  state.settings?.searches?.tinyTags?.tTQueryHasMore;

export const selectSettingsTTQueryPage = (state) =>
  state.settings?.searches?.tinyTags?.tTQueryPage;

//Organize
export const selectSettingsOrganizeView = (state) =>
  state.settings?.organize?.general?.organizeView;

export const selectSettingsOrganizeOverviewSortCategory = (state) =>
  state.settings?.organize?.overview?.organizeOverviewSortCategory;

export const selectSettingsOrganizeOverviewDirection = (state) =>
  state.settings?.organize?.overview?.organizeOverviewDirection;

export const selectSettingsOrganizeOverviewFormat = (state) =>
  state.settings?.organize?.overview?.organizeOverviewFormat;

export const selectSettingsOrganizeSelectedOrganizers = (state) =>
  state.settings?.organize?.overview?.selectedOrganizers;

export const selectSettingsOrganizeSelectAllOrganizersBtn = (state) =>
  state.settings?.organize?.overview?.selectAllOrganizersBtn;

export const selectSettingsOrganizeOverviewStandaloneGridEvents = (state) =>
  state.settings?.organize?.overview?.standaloneEvents;

export const selectSettingsOrganizeOverviewListOpened = (state) =>
  state.settings?.organize?.overview?.organizeOverviewListOpened;

export const selectSettingsOrganizeOverviewContent = (state) =>
  state.settings?.organize?.overview?.content;
