//Hooks
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//Components

//Utility

const HelpContent = () => {
  //Hooks
  const { Account } = useLanguageAccount();

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />
      <div className="h48" />
    </div>
  );
};

export default HelpContent;
