import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

//hooks
import useApiStatus from '../../../../../context/useApiStatus';
import { useRegistrationHandlers } from '../../useRegistrationHandlers';

//components
import Spinner from '../../../../../components/Spinner/Spinner';
import PersonalCompletionPage from '../Personal/PersonalCompletionPage';

//utils
import { axiosLimited } from '../../../../../axios/axios';
import { retryWrapper } from '../../../../../utils/logic/retryFns';

const RegistrationStripeConfirmation = () => {
  //hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { handleError } = useApiStatus();
  const {
    handleAddPaymentMethodIdToUnverifiedAccount,
    handleSendWelcomeEmail,
  } = useRegistrationHandlers();

  //state
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newEmail, setNewEmail] = useState('');

  //variables
  const initializeStripeEffectRan = useRef(false);
  const initializeCompleteRegistration = useRef(false);

  //initialize
  useEffect(() => {
    if (initializeStripeEffectRan.current === false) {
      initializeStripeEffectRan.current = true;
      if (process.env.REACT_APP_ENV === 'development') {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)
        );
      } else {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        );
      }
    }
  }, []); //load stripe promise

  useEffect(() => {
    if (stripePromise) {
      if (initializeCompleteRegistration.current === false) {
        initializeCompleteRegistration.current = true;

        const queryParams = new URLSearchParams(location.search);

        const setup_intent = queryParams.get('setup_intent');
        const setupIntentClientSecret = queryParams.get(
          'setup_intent_client_secret'
        );
        const redirectStatus = queryParams.get('redirect_status');

        const redirectObjId = queryParams.get('redirectObjId');

        if (
          setup_intent &&
          setupIntentClientSecret &&
          redirectStatus === 'succeeded' &&
          redirectObjId
        ) {
          handleCompleteRegistrationConfirmation(setup_intent, redirectObjId);
        } else {
          navigate('/register');
        }
      }
    }
  }, [location.search, stripePromise]);

  async function handleCompleteRegistrationConfirmation(
    setup_intent,
    redirectObjId
  ) {
    try {
      //perform my own verification of the setup intent
      async function getSetupIntent() {
        const verifySetupIntent = await axiosLimited.post(
          `/api/stripe/verifySetupIntent`,
          { setup_intent }
        );
        return verifySetupIntent?.data?.setupIntent;
      }

      const getSetupIntentResponse = await getSetupIntent();

      const paymentMethodId = getSetupIntentResponse?.payment_method;
      if (!paymentMethodId) throw new Error();

      //get redirect obj data to fill customer details
      async function getRedirectObject() {
        const redirectObjResponse = await axiosLimited.get(
          `/api/stripe/redirectObj/${redirectObjId}`
        );
        return redirectObjResponse?.data?.redirectObj;
      }
      const { email, unverifiedAccountId, accountVerificationURL } =
        await retryWrapper(getRedirectObject);

      //add payment method to account
      await handleAddPaymentMethodIdToUnverifiedAccount({
        unverifiedAccountId,
        paymentMethodId,
      });

      //remove redirect obj
      async function removeRedirectObj() {
        await axiosLimited.delete(`/api/stripe/redirectObj/${redirectObjId}`);
      }
      await retryWrapper(removeRedirectObj);

      //registration complete send verification email.
      await handleSendWelcomeEmail(unverifiedAccountId, accountVerificationURL);

      setNewEmail(email);
      setIsLoading(false);
    } catch (error) {
      handleError({
        message: 'pleaseContactSupport',
        id: Date.now(),
        origin:
          'RegistrationStripeConfirmation.js/handleCompleteRegistrationConfirmation',
      });
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="layout">
          <div className="register bg-blur">
            <div className="form-template" id="form-template" tabIndex="-1">
              <Spinner minHeight={'100%'} minWidth={'100%'} />
            </div>
          </div>
        </div>
      ) : (
        <div className="layout">
          <div className="register bg-blur">
            <div className="form-template" id="form-template" tabIndex="-1">
              <div className="form-container">
                <PersonalCompletionPage email={newEmail} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationStripeConfirmation;
