import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility
const organizeSlice = createSlice({
  name: 'organize',
  initialState: {
    myOrganizedEvents: [],
    myOrganizedScheduledEvents: [],
    myOrganizedArchivedEvents: [],
    sortedMyOrganizedEvents: [],
    sortedMyOrganizedScheduledEvents: [],
    sortedMyOrganizedArchivedEvents: [],
  },
  reducers: {
    setMyOrganizedEvents: (state, action) => {
      state.myOrganizedEvents = action.payload;
    },
    setOrganizedEvent: (state, action) => {
      const updatedEvent = action.payload;
      const index = state.myOrganizedEvents.findIndex(
        (ev) => ev._id === updatedEvent._id
      );
      if (index !== -1) {
        state.myOrganizedEvents[index] = updatedEvent;
      }
    },

    setMyScheduledOrganizedEvents: (state, action) => {
      state.myOrganizedScheduledEvents = action.payload;
    },
    setMyArchivedOrganizedEvents: (state, action) => {
      state.myOrganizedArchivedEvents = action.payload;
    },

    //SORTED
    setSortedMyOrganizedEvents: (state, action) => {
      state.sortedMyOrganizedEvents = action.payload;
    },
    setSortedMyScheduledOrganizedEvents: (state, action) => {
      state.sortedMyOrganizedScheduledEvents = action.payload;
    },
    setSortedMyArchivedOrganizedEvents: (state, action) => {
      state.sortedMyOrganizedArchivedEvents = action.payload;
    },
  },
});

export const {
  setMyOrganizedEvents,
  setMyScheduledOrganizedEvents,
  setMyArchivedOrganizedEvents,

  setOrganizedEvent,

  //sorted
  setSortedMyOrganizedEvents,
  setSortedMyScheduledOrganizedEvents,
  setSortedMyArchivedOrganizedEvents,
} = organizeSlice.actions;

export default organizeSlice.reducer;

export const selectMyOrganizedEvents = (state) =>
  state.organize?.myOrganizedEvents;

export const selectMyScheduledOrganizedEvents = (state) =>
  state.organize?.myOrganizedScheduledEvents;

export const selectMyArchivedOrganizedEvents = (state) =>
  state.organize?.myOrganizedArchivedEvents;

//SORTED
export const selectSortedMyOrganizedEvents = (state) =>
  state.organize?.sortedMyOrganizedEvents;

export const selectSortedMyScheduledOrganizedEvents = (state) =>
  state.organize?.sortedMyOrganizedScheduledEvents;

export const selectSortedMyArchivedOrganizedEvents = (state) =>
  state.organize?.sortedMyOrganizedArchivedEvents;
