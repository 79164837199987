import useSettings from '../context/useSettings';

const useLanguageLayout = () => {
  const { contentLanguage } = useSettings();

  const App = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        main: 'Primary Content',
        pageNotFound: 'Page not found',
        unauthorized: 'Unauthorized',
        updatingApp: 'Updating',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        main: 'Contenu principal',
        pageNotFound: 'Page non trouvée',
        unauthorized: 'Non autorisé',
        updatingApp: `Mise à jour`,
      };
    }

    return translation;
  };

  const Footer = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        about: 'About',
        contact: 'Contact',
        userAgreement: 'User Agreement',
        privacyPolicy: 'Privacy Policy',
        selectSiteLanguage: 'Select site language. Current selection',
        visitOurLinkedInPage: 'Visit our LinkedIn Page.',
        footer: 'Footer',
        comingSoon: 'Coming soon',
        recaptchaPt1: 'This site is protected by reCAPTCHA and Google.',
        recaptchaPt2: 'Privacy Policy',
        recaptchaPt3: 'and',
        recaptchaPt4: 'Terms of Service',
        recaptchaPt5: 'apply.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        about: 'À propos',
        contact: 'Contactez-nous',
        userAgreement: `Contrat d'utilisateur`,
        privacyPolicy: 'Politique de confidentialité',
        selectSiteLanguage:
          'Sélectionnez la langue du site. Sélection actuelle',
        visitOurLinkedInPage: 'Visitez notre page LinkedIn.',
        footer: 'Bas de page',
        comingSoon: 'Bientôt disponible',
        recaptchaPt1: 'Ce site est protégé par reCAPTCHA et Google.',
        recaptchaPt2: 'Politique de confidentialité',
        recaptchaPt3: 'et',
        recaptchaPt4: `Conditions d'utilisation`,
        recaptchaPt5: 'appliquer.',
      };
    }

    return translation;
  };

  const NavbarLoggedOut = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        contact: 'Contact',
        register: 'Register',
        signIn: 'Sign In',
        decorativeEnterIcon: 'Decorative enter icon',
        selectSiteLanguage: 'Select site language. Current selection',
        linkToHomepage: 'link to home page',
        navigationMenu: 'Navigation menu',
        menuIcon: 'Menu',
        header: 'Site Navigation',
        translations: 'Translations',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        contact: 'Contactez-nous',
        register: `S'inscrire`,
        signIn: 'Se connecter',
        decorativeEnterIcon: `Icône d'entrée décorative`,
        selectSiteLanguage:
          'Sélectionnez la langue du site. Sélection actuelle',
        linkToHomepage: `lien vers la page d'accueil`,
        navigationMenu: 'le menu de navigation',
        menuIcon: 'icône de menu',
        header: 'Naviguer sur le site',
        translations: 'Traductions',
      };
    }
    return translation;
  };

  const NavbarLoggedIn = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        account: 'Account',
        contact: 'Contact',
        events: 'Events',
        speaker: 'Speaker',
        organize: 'Organize',
        register: 'Register',
        signIn: 'Sign In',
        signOut: 'Sign Out',
        profilePicture: 'profile picture',
        linkToEvents: 'Link to Events',
        translations: 'Translations',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        account: 'Compte',
        contact: 'Contact',
        events: 'Événements',
        speaker: 'Orateur',
        organize: 'Organiser',
        register: `S'inscrire`,
        signIn: 'Se connecter',
        signOut: 'Se déconnecter',
        profilePicture: 'image de profil',
        linkToEvents: `lien vers les événements`,
        translations: 'Traductions',
      };
    }
    return translation;
  };

  const SlideMenu = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        slideMenuOnScreen: 'Slide Navigation Menu (on-screen)',
        slideMenuOffScreen: 'Slide Navigation Menu (off-screen)',
        visitOurLinkedInPage: 'Visit our LinkedIn Page.',
        closeNavigationMenu: 'Close navigation menu.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        slideMenuOnScreen: `
        Menu de navigation des diapositives (à l'écran)`,
        slideMenuOffScreen: `Menu de navigation des diapositives (hors écran)`,
        visitOurLinkedInPage: 'Visitez notre page LinkedIn.',
        closeNavigationMenu: 'Fermer le menu de navigation.',
      };
    }
    return translation;
  };

  const ErrorPage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        errorHasOccurred: 'An error has occurred.',
        apologizeAndSupportLink: `We apologize for this inconvenience. If the issue persists please
        contact support at: support@myndfull.com.`,
        refreshButton: 'Attempt page refresh',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        errorHasOccurred: 'Une erreur est survenue.',
        apologizeAndSupportLink: `Nous nous excusons pour ce désagrément. Si le problème persiste, veuillez
        contacter l'assistance à : support@myndfull.com.`,
        refreshButton: `Tentative d'actualisation de la page`,
      };
    }
    return translation;
  };

  return {
    App: App(),
    Footer: Footer(),
    NavbarLoggedOut: NavbarLoggedOut(),
    NavbarLoggedIn: NavbarLoggedIn(),
    SlideMenu: SlideMenu(),
    ErrorPage: ErrorPage(),
  };
};

export default useLanguageLayout;
