import { useEffect } from 'react';

//hooks
import useAdminTechnicalHandlers from '../technical/useAdminTechnicalHandlers';

//components
import InfoCard from './InfoCards/InfoCard';

//utils
import { nav_arrow } from '../../../assets/icons';

const AdminCardsRowTechnical = ({
  cardsRowIds,
  setCardsRowIds,
  currentSearchIndex,
  setCurrentSearchIndex,
  technicalLogs,
  technicalLogsArchived,
  handleUnselectFileFromDirectory,
  adminSearchQueries,
}) => {
  //hooks
  const { adminTechnicalLogs } = useAdminTechnicalHandlers();
  //UI
  //variables
  const cardStyle = {
    transition: 'transform 0.5s ease',
    transform: `translateX(-${currentSearchIndex * 1024}px)`,
  };

  //variables
  useEffect(() => {
    const adminLogIds = adminTechnicalLogs.map((log) => log._id);
    cardsRowIds.forEach((id) => {
      if (!adminLogIds.includes(id)) {
        handleCloseInfoCard(id);
      }
    });
  }, [adminTechnicalLogs]); //remove id from row nav if log is deleted

  //UI
  const handlePrevCard = () => {
    setCurrentSearchIndex((prevIndex) =>
      prevIndex === 0 ? cardsRowIds.length - 1 : prevIndex - 1
    );
  };

  const handleNextCard = () => {
    setCurrentSearchIndex((prevIndex) =>
      prevIndex === cardsRowIds.length - 1 ? 0 : prevIndex + 1
    );
  };

  function handleCloseInfoCard(removeId) {
    let newArr = [...cardsRowIds];
    const reducedArr = newArr.filter((cardRowId) => {
      return removeId !== cardRowId;
    });
    setCardsRowIds(reducedArr);
    if (currentSearchIndex > 0) {
      setCurrentSearchIndex(currentSearchIndex - 1);
    } else {
      setCurrentSearchIndex(0);
    }

    //remove selected file from directory
    const technicalLogArchiveToRemove = technicalLogsArchived.find(
      (l) => l._id === removeId
    );
    if (technicalLogArchiveToRemove) {
      handleUnselectFileFromDirectory(
        technicalLogArchiveToRemove?.archive?.archivePath
      );
    }
  }

  return (
    <div className="flex-column">
      <div className="flex-column flex-center align-center mrg-b24">
        <p className="flex-center fs21 fwb">Cards ({cardsRowIds?.length})</p>
        <div className="ticket-table-dots mrg-t24">
          {cardsRowIds?.map((_, index) => {
            return (
              <div
                key={`search-dot-${index}`}
                className={`dot mrg-r6 ${
                  currentSearchIndex === index
                    ? 'bg-color-theme'
                    : 'bg-color-lightgray'
                }`}
              />
            );
          })}
        </div>
      </div>
      <div className="flex-row full-width">
        {cardsRowIds?.length > 0 ? (
          <>
            <button className="table-ticket-arrow" onClick={handlePrevCard}>
              <img src={nav_arrow} alt="arrow" className="rotate-180" />
            </button>
            <button className="table-ticket-arrow" onClick={handleNextCard}>
              <img src={nav_arrow} alt="arrow" />
            </button>
          </>
        ) : (
          <div className="cards-row-empty-divider" />
        )}
      </div>

      <div className="ticket-row" style={cardStyle}>
        {cardsRowIds?.length > 0 ? (
          <>
            {cardsRowIds?.map((id, index) => {
              const technicalLog = technicalLogs?.find((log) => log._id === id);
              //  ||
              // adminSearchQueries?.find(
              //   (log) => log._id === id && log?.meta?.technicalLogReference
              // );
              if (technicalLog) {
                return (
                  <InfoCard
                    key={`searchResultTicket-${technicalLog.meta.technicalLogReference}`}
                    infoCardType={'technicalLog'}
                    idData={technicalLog?._id}
                    metaData={technicalLog?.meta}
                    errorData={technicalLog.error}
                    requestData={technicalLog.request}
                    traceData={technicalLog.trace}
                    title={'Technical Log'}
                    handleCloseInfoCard={handleCloseInfoCard}
                    cardsRowIds={cardsRowIds}
                    setCardsRowIds={setCardsRowIds}
                  />
                );
              }

              const technicalLogArchived = technicalLogsArchived?.find(
                (log) => log._id === id
              );

              if (technicalLogArchived) {
                return (
                  <InfoCard
                    key={`searchResultTicket-${technicalLogArchived.meta.technicalLogReference}`}
                    infoCardType={'technicalLogArchive'}
                    idData={technicalLogArchived?._id}
                    metaData={technicalLogArchived?.meta}
                    errorData={technicalLogArchived.error}
                    requestData={technicalLogArchived.request}
                    traceData={technicalLogArchived.trace}
                    technicalArchiveData={technicalLogArchived.archive}
                    title={'Archived Technical Log'}
                    handleCloseInfoCard={handleCloseInfoCard}
                    cardsRowIds={cardsRowIds}
                    setCardsRowIds={setCardsRowIds}
                  />
                );
              }
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AdminCardsRowTechnical;
