//hooks
import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';
//components
//utils

import {
  DateFromTimeStamp,
  getDateOrTimeFromUTZstring,
} from '../../../../../utils/dateTime/DateCalc';

const InfoCardContainerMeta = ({
  adminRef,
  ticketRef,
  eventRef,
  accountRef,
  technicalLogRef,
  metaData,
}) => {
  const { StatusEl } = useLanguageAdmin();

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Meta</p>
      <div className="mrg-t6">
        {adminRef && `Admin Ref: [${adminRef}]`}
        {ticketRef && `Ticket Ref: [${ticketRef}]`}
        {eventRef && `Event Ref: [${eventRef}]`}
        {accountRef && `Account Ref: [${accountRef}]`}
        {technicalLogRef && `Technical Log Ref: [${technicalLogRef}]`}
      </div>
      <div className="mrg-t6">
        Created (UTC):{' '}
        {`${getDateOrTimeFromUTZstring(
          metaData?.createdAt,
          'date'
        )} (${getDateOrTimeFromUTZstring(metaData?.createdAt, 'time')})`}
      </div>

      {metaData?.lastModified && (
        <div className="mrg-t6">
          Last Modified (UTC):{' '}
          {DateFromTimeStamp(metaData?.lastModified, {
            weekday: 'off',
            time: '24hr',
          })}
        </div>
      )}

      {metaData?.eventStatus && (
        <div className="flex-row mrg-t6">
          Event Status: &nbsp; {StatusEl(metaData?.eventStatus)}
        </div>
      )}
      {metaData?.accountStatus && (
        <div className="flex-row mrg-t6">
          Account Status: &nbsp; {StatusEl(metaData?.accountStatus)}
        </div>
      )}

      <div className="mrg-t6">Version: {metaData?.version}</div>
      {metaData?.serverBuild && (
        <div className="mrg-t6">Server Build: {metaData?.serverBuild}</div>
      )}
    </div>
  );
};

export default InfoCardContainerMeta;
