import { useEffect, useState } from 'react';

import {
  useLazyMyOrganizedEventsQuery,
  useLazyMyOrganizedScheduledEventsQuery,
  useLazyMyOrganizedArchivedEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
} from './organizeApiSlice';

//Hooks
import { useCreateDraftEventMutation } from './draftEventApiSlice';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import useSettings from '../../../context/useSettings';
import SettingsOrganize from '../settings/Organize/SettingsOrganize';
import { useOrganizeHandlers } from './useOrganizeHandlers';
import { useOrganizeEventsSocket } from '../../../context/sockets/OrganizeEventsSocketProvider';

//Components
import CreateEvent from './create/CreateEvent';
import OrganizeOverview from './OrganizeOverview';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import EventPage from '../events/EventPage/EventPage';
import EditEvent from './edit/EditEvent';

//Utility

const Organize = () => {
  //Hooks
  const { setNavbar } = useSettings();
  const { accountId, accountAffiliations } = useAccountReduxHandlers();
  const {
    settingsOrganizeView: organizeView,
    settingsOrganizeSelectedOrganizers: selectedOrganizers,
    handleSettingsSetSelectedOrganizers,
    handleSettingsSetSelectAllOrganizersBtn,
    settingsViewEventDetails,
  } = useSettingsHandlers();
  const { handleRemoveOrganizerEvent } = useOrganizeHandlers();

  //Hooks: Event Queries
  const [getMyOrgEvents, { isUninitialized: myOrgEventsisUninitialized }] =
    useLazyMyOrganizedEventsQuery(accountId);
  const [
    getMyOrgScheduledEvents,
    { isUninitialized: myOrgScheduledEventsisUninitialized },
  ] = useLazyMyOrganizedScheduledEventsQuery(accountId);
  const [
    getMyOrgArchivedEvents,
    { isUninitialized: myOrgArchivedEventsisUninitialized },
  ] = useLazyMyOrganizedArchivedEventsQuery(accountId);

  //Hooks: Edit
  const [updateEvent, { isLoading: updateEventisLoading }] =
    useUpdateEventMutation();

  //Hooks: Create
  const [createEvent, { isLoading: createEventisLoading }] =
    useCreateEventMutation();
  const [createDraftEvent, { isLoading: createDraftEventisLoading }] =
    useCreateDraftEventMutation();
  const { connectOrganizeEventsSocket, closeOrganizeEventsSocket } =
    useOrganizeEventsSocket();

  //Component state
  const [editEvent, setEditEvent] = useState();
  const [removeEventisLoading, setRemoveEventIsLoading] = useState(false);
  //UI state
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [eventPreview, setEventPreview] = useState(false);

  //Component variables
  let content;

  //Initialize functions
  useEffect(() => {
    setNavbar('organize');
    setEventPageLoadComplete(false);
    /*eslint-disable-next-line*/
  }, []);

  useEffect(() => {
    connectOrganizeEventsSocket();

    return () => {
      closeOrganizeEventsSocket();
    };
  }, []); //web socket for organizedEvents

  useEffect(() => {
    if (myOrgEventsisUninitialized && accountId) {
      getMyOrgEvents(accountId);
    }

    if (myOrgScheduledEventsisUninitialized && accountId) {
      getMyOrgScheduledEvents(accountId);
    }

    if (myOrgArchivedEventsisUninitialized && accountId) {
      getMyOrgArchivedEvents(accountId);
    }
    /*eslint-disable-next-line*/
  }, [
    myOrgEventsisUninitialized,
    myOrgScheduledEventsisUninitialized,
    myOrgArchivedEventsisUninitialized,
    accountId,
  ]); //get my events

  useEffect(() => {
    if (selectedOrganizers?.length === 0) {
      let newSelections = [];
      accountAffiliations?.map((aA) => newSelections.push(aA.affiliation._id));
      newSelections.push(accountId);
      handleSettingsSetSelectedOrganizers(newSelections);
      handleSettingsSetSelectAllOrganizersBtn(true);
    }
  }, []); //selects accountId and affiliate organizers for sorting if none are selected upon load

  //API functions
  async function handleRemoveEvent(eventId, organizerId, eventType) {
    if (!removeEventisLoading) {
      setRemoveEventIsLoading(true);
      await handleRemoveOrganizerEvent(eventId, organizerId, eventType);
      setEventPageLoadComplete(false);
      setEditEvent();
      setRemoveEventIsLoading(false);
    }
  }
  //JSX conditional
  if (eventPageLoadComplete && !settingsViewEventDetails && !editEvent) {
    content = (
      <div className="organize">
        {!eventPreview && <SettingsOrganize />}
        {organizeView === 'overview' && (
          <OrganizeOverview
            setEditEvent={setEditEvent}
            handleRemoveEvent={handleRemoveEvent}
          />
        )}
        {organizeView === 'create' && (
          <CreateEvent
            createEvent={createEvent}
            createEventisLoading={createEventisLoading}
            createDraftEvent={createDraftEvent}
            createDraftEventisLoading={createDraftEventisLoading}
            setEventPageLoadComplete={setEventPageLoadComplete}
          />
        )}
        {/* Speaker view here */}
      </div>
    );
  } else if (eventPageLoadComplete && settingsViewEventDetails) {
    content = (
      <EventPage
        organizerViewOn={true}
        previewMode={eventPreview ? true : false}
      />
    );
  } else if (eventPageLoadComplete && editEvent) {
    content = (
      <EditEvent
        eventData={editEvent}
        organizerViewOn={true}
        setEditEvent={setEditEvent}
        handleRemoveEvent={handleRemoveEvent}
        removeEventisLoading={removeEventisLoading}
        setEventPreview={setEventPreview}
        eventPreview={eventPreview}
        updateEvent={updateEvent}
        updateEventisLoading={updateEventisLoading}
        setEventPageLoadComplete={setEventPageLoadComplete}
      />
    );
  } else {
    content = (
      <LoadingScreenContent
        numberOfLoadChecks={3}
        setLoadComplete={() => setEventPageLoadComplete(true)}
        loadCheck1={accountId ? true : false}
        loadCheck2={organizeView ? true : false}
        loadCheck3={!eventPageLoadComplete ? true : false}
        customWrapper={'min-full-height'}
      />
    );
  }

  return content;
};

export default Organize;
