import { useState, useEffect } from 'react';

//Hooks
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useApiStatus from '../../../../../context/useApiStatus';
import useReCaptcha from '../../../../../hooks/useReCaptcha';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useUpdateEmailMutation } from '../../accountApiSlice';

//Components
import PasswordCheck from '../../../../../components/TextArea/PasswordCheck';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import EmailInput from '../../../../../components/TextArea/EmailInput';

//Utility

const Email = ({ Account }) => {
  //Hooks
  const { handleError } = useApiStatus();
  const {
    accountId,
    accountEmail: loadEmail,
    handleUpdateCustomerStripeData,
  } = useAccountReduxHandlers();
  const { PasswordInputs } = useLanguageComponents();
  const { handleReCaptchaVerify } = useReCaptcha();

  //API hooks
  const [
    updateEmail,
    {
      isLoading: updateAccountEmailIsLoading,
      isFetching: updateAccountEmailIsFetching,
    },
  ] = useUpdateEmailMutation();

  //Component state
  const [email, setEmail] = useState(loadEmail);
  const [emailChanged, setEmailChanged] = useState(false);
  const [emailChangePasswordConfirmation, setEmailChangePasswordConfirmation] =
    useState();
  const [validEmail, setValidEmail] = useState();

  //Component variables
  const emailChangePasswordConfirmEl = document.getElementById(
    'emailChangePasswordConfirm'
  );

  //API functions
  async function saveAccountEmailChange(newData) {
    const recaptchaVerified = await handleReCaptchaVerify(
      'ACCOUNT_CHANGE_EMAIL',
      false
    );

    if (recaptchaVerified !== true) return false;

    if (!updateAccountEmailIsLoading && !updateAccountEmailIsFetching) {
      try {
        const updateCustomerStripeData = await handleUpdateCustomerStripeData(
          newData,
          'email'
        );
        if (!updateCustomerStripeData?.error) {
          const res = await updateEmail({ accountId, newData });
          if (!res.data) setEmail(loadEmail);
          localStorage.setItem('email', JSON.stringify(newData.email));
        }
      } catch (err) {
        handleError(
          {
            message: 'updateFailed',
          },
          'AccountPersonalContent/Email.js/saveAccountEmailChange'
        );
      }
      emailChangePasswordConfirmEl.value = '';
      setEmailChangePasswordConfirmation('');
    }
  }

  //Component functions
  useEffect(() => {
    if (email !== loadEmail) {
      setEmailChanged(true);
    } else {
      setEmailChanged(false);
    }
  }, [email, loadEmail]);

  return (
    <>
      <div className="section">
        <label
          className="content-heading-styled text-gray"
          tabIndex="0"
          id="emailLabel"
        >
          {Account.account.emailAddress}
        </label>

        <div className="h24" />
        <EmailInput
          asterisk={true}
          emailCollectionToCheck={'Account'}
          handleInput={setEmail}
          id={'email'}
          loadValue={loadEmail}
          setSaveValid={setValidEmail}
        />

        <div className="h24" />
        <PasswordCheck
          id={'emailChangePasswordConfirm'}
          labelText={PasswordInputs.passwordConfirmation}
          customWidth={'100%'}
          handleChange={setEmailChangePasswordConfirmation}
          asterisk={true}
        />
        <div className="h24" />

        <SaveButton
          saveValid={
            emailChanged && emailChangePasswordConfirmation && validEmail
          }
          saveItemLanguage={Account.account.email}
          handler={() =>
            saveAccountEmailChange({
              pwd: emailChangePasswordConfirmation,
              email,
            })
          }
          isLoading={
            updateAccountEmailIsLoading || updateAccountEmailIsFetching
          }
        />
      </div>
      <div className="h36" />
    </>
  );
};

export default Email;
