import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//Hooks
import useLanguageComponents from '../../../language/useLanguageComponents';
import useApiStatus from '../../../context/useApiStatus';

//Components
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';

//Utility
import ScrollTo from '../../../utils/UI/ScrollTo';
import { axiosLimited } from '../../../axios/axios';
import { retryWrapper } from '../../../utils/logic/retryFns';
import { useRegistrationHandlers } from '../registration/useRegistrationHandlers';

const VerifyAccountEmail = () => {
  //Hooks
  const { handleError } = useApiStatus();
  const { LoadingScreen } = useLanguageComponents();
  const { verifyaccountemailtoken } = useParams();
  const { handleRemoveUnverifiedAccount } = useRegistrationHandlers();
  const navigate = useNavigate();

  //Component state
  const [verificationResult, setVerificationResult] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  //Component variables
  const effectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    ScrollTo('navbar', 'auto', true);
  }, []);

  //API functions
  async function emailTokenValidation() {
    try {
      const { data } = await axiosLimited.patch(
        `/api/main/auth/verifyAccountEmailToken/${verifyaccountemailtoken}`
      );

      if (data.unverifiedAccountId) {
        await handleRemoveUnverifiedAccount(data.unverifiedAccountId, false);
      }

      if (data.requiresStripeBilling) {
        // //create customer
        async function createStripeCustomer() {
          const createCustomerResponse = await axiosLimited.post(
            '/api/stripe/createCustomer',
            {
              accountId: data?.newAccountId,
            }
          );
          return createCustomerResponse?.data?.customerId;
        }

        const customerId = await createStripeCustomer();

        if (customerId) {
          async function newAccountCreateRoomCodeAndSubscriptions() {
            await axiosLimited.post(
              '/api/stripe/newAccountCreateRoomCodeSubscriptions',
              {
                customerId,
              }
            );
          }
          await retryWrapper(newAccountCreateRoomCodeAndSubscriptions);
        }
      }

      if (data.email) {
        localStorage.setItem('email', JSON.stringify(data.email));
        setVerificationResult('success');
      } else {
        throw new Error();
      }
    } catch (error) {
      handleError({
        error,
        id: Date.now(),
        origin: 'VerifyAccountEmail.js/emailTokenValidation',
      });
      setVerificationResult('fail');
    }
  }

  //Component functions
  useEffect(() => {
    if (verifyaccountemailtoken) {
      if (effectRan.current === false) {
        emailTokenValidation();

        return () => {
          effectRan.current = true;
        };
      }
    }
    /*eslint-disable-next-line*/
  }, [verifyaccountemailtoken]);

  useEffect(() => {
    if (verificationResult === 'success' && isSuccess) {
      navigate('/login');
    } else if (verificationResult === 'fail' && isSuccess) {
      navigate('/register');
    }
    /*eslint-disable-next-line*/
  }, [isSuccess, verificationResult]);

  return (
    <LoadingScreenContent
      numberOfLoadChecks={1}
      loadCheck1={verificationResult}
      setLoadComplete={() => setIsSuccess(true)}
      customLoadingText={LoadingScreen.verifying}
      customWrapper={'min-full-height'}
    />
  );
};

export default VerifyAccountEmail;
