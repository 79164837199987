import { useSelector, useDispatch } from 'react-redux';
import {
  emptyAdminAccountData,
  setAdminContentLanguage,
  setAdminPosition,
  setAdminCountryCode,
  setAdminEmail,
  setAdminFirstName,
  setAdminLastName,
  setAdminPhoneNumber,
  setAdminPrefix,
  setAdminProfileImage,
  setAdminPronouns,
  setAdminTeams,
  selectAdminTickets,
  selectAdminAccountId,
  selectAdminAccount,
  selectAdminContentLanguage,
  selectAdminCompanyPosition,
  selectAdminCountryCode,
  selectAdminEmail,
  selectAdminFirstName,
  selectAdminLastName,
  selectAdminProfileImage,
  selectAdminPhoneNumber,
  selectAdminPrefix,
  selectAdminPronouns,
  selectAdminTeams,
  setAccountCompanyTickets,
  selectAdminAccountAdminReference,
} from './adminAccountSlice';

export const useAdminAccountReduxHandlers = () => {
  const dispatch = useDispatch();

  const adminAccountId = useSelector(selectAdminAccountId);
  const adminAccount = useSelector(selectAdminAccount);

  //meta
  const adminAccountAdminReference = useSelector(
    selectAdminAccountAdminReference
  );

  //app settings
  const adminAccountContentLanguage = useSelector(selectAdminContentLanguage);

  //Company
  const adminAccountCompanyPosition = useSelector(selectAdminCompanyPosition);
  const adminAccountTeams = useSelector(selectAdminTeams);
  const adminAccountTickets = useSelector(selectAdminTickets);

  //Personal
  const adminAccountCountryCode = useSelector(selectAdminCountryCode);
  const adminAccountEmail = useSelector(selectAdminEmail);
  const adminAccountFirstName = useSelector(selectAdminFirstName);
  const adminAccountLastName = useSelector(selectAdminLastName);
  const adminAccountProfileImage = useSelector(selectAdminProfileImage);
  const adminAccountPhoneNumber = useSelector(selectAdminPhoneNumber);
  const adminAccountPrefix = useSelector(selectAdminPrefix);
  const adminAccountPronouns = useSelector(selectAdminPronouns);

  //Handler Functions
  //General
  function handleAdminAccountEmptyAdminAccountData() {
    dispatch(emptyAdminAccountData());
  }

  //AppSettings
  function handleAdminAccountSetContentLanguage(str) {
    dispatch(setAdminContentLanguage(str));
  }

  //Company
  function handleAdminAccountSetPosition(str) {
    dispatch(setAdminPosition(str));
  }

  function handleAdminAccountSetTeams(arr) {
    dispatch(setAdminTeams(arr));
  }

  function handleAdminAccountSetTickets(arr) {
    dispatch(setAccountCompanyTickets(arr));
  }

  //Personal
  function handleAdminAccountSetCountryCode(str) {
    dispatch(setAdminCountryCode(str));
  }

  function handleAdminAccountSetEmail(str) {
    dispatch(setAdminEmail(str));
  }

  function handleAdminAccountSetFirstName(str) {
    dispatch(setAdminFirstName(str));
  }

  function handleAdminAccountSetLastName(str) {
    dispatch(setAdminLastName(str));
  }

  function handleAdminAccountSetPhoneNumber(str) {
    dispatch(setAdminPhoneNumber(str));
  }
  function handleAdminAccountSetPrefix(str) {
    dispatch(setAdminPrefix(str));
  }
  function handleAdminAccountSetProfileImage(str) {
    dispatch(setAdminProfileImage(str));
  }
  function handleAdminAccountSetPronouns(str) {
    dispatch(setAdminPronouns(str));
  }

  return {
    handleAdminAccountEmptyAdminAccountData,
    handleAdminAccountSetContentLanguage,
    handleAdminAccountSetPosition,
    handleAdminAccountSetCountryCode,
    handleAdminAccountSetEmail,
    handleAdminAccountSetFirstName,
    handleAdminAccountSetLastName,
    handleAdminAccountSetPhoneNumber,
    handleAdminAccountSetPrefix,
    handleAdminAccountSetProfileImage,
    handleAdminAccountSetPronouns,
    handleAdminAccountSetTeams,
    adminAccountId,
    adminAccount,
    adminAccountContentLanguage,
    adminAccountCompanyPosition,
    adminAccountCountryCode,
    adminAccountEmail,
    adminAccountFirstName,
    adminAccountLastName,
    adminAccountProfileImage,
    adminAccountPhoneNumber,
    adminAccountPrefix,
    adminAccountPronouns,
    adminAccountTeams,
    adminAccountTickets,
    handleAdminAccountSetTickets,
    adminAccountAdminReference,
  };
};
