//Hooks

//Components
import FollowButton from '../FollowButton/FollowButton';

//Utility
import {
  facebook_color,
  globe2,
  instagram_color,
  linkedin_color,
  x_twitter,
} from '../../assets/icons';
import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';

const Participant = ({ participantData, speakerImgRef }) => {
  return (
    <>
      <div className="participant__wrapper">
        <div className="flex-column">
          <div className="participant__container">
            {speakerImgRef ? (
              <LoadBucketImage
                imageRef={speakerImgRef}
                containerClassName={'ev-display-container'}
                imgClassName={'participant-img'}
                backupPath={'account_img_default.png'}
                partialPath={'myndfull_account_images'}
              />
            ) : (
              <LoadBucketImage
                imagePath={participantData?.imagePath}
                containerClassName={'ev-display-container'}
                imgClassName={'participant-img'}
                backupPath={'account_img_default.png'}
                partialPath={'myndfull_account_images'}
              />
            )}
          </div>
          <div className="name">
            <p className="access-ob" tabIndex="0">
              {participantData.name}
            </p>
            {participantData?.affiliation && (
              <p className="mrg-t6 access-ob" tabIndex="0">
                participantData?.affiliation
              </p>
            )}
          </div>
        </div>
        <p
          className="bio access-ob"
          tabIndex={participantData?.bio?.length > 0 ? '0' : ''}
        >
          {participantData.bio}
        </p>
      </div>
      <div className="flex-row space-between mrg-t24">
        <div className="social-links">
          {participantData.links?.linkPersonalWebsite && (
            <a
              href={`${participantData.links.linkPersonalWebsite}`}
              className="access-ob access-o6"
              tabIndex="0"
            >
              <img src={globe2} alt="Web" />
            </a>
          )}
          {participantData.links?.linkLinkedIn && (
            <a
              href={`${participantData?.links?.linkLinkedIn}`}
              className="access-ob access-o6"
              tabIndex="0"
            >
              <img src={linkedin_color} alt="LinkedIn" />
            </a>
          )}
          {participantData?.links?.linkX && (
            <a
              href={`${participantData?.links?.linkX}`}
              className="access-ob access-o6"
              tabIndex="0"
            >
              <img src={x_twitter} alt="X" />
            </a>
          )}
          {participantData?.links?.linkFacebook && (
            <a
              href={`${participantData?.links?.linkFacebook}`}
              className="access-ob access-o6"
              tabIndex="0"
            >
              <img src={facebook_color} alt="Facebook" />
            </a>
          )}
          {participantData?.links?.linkInstagram && (
            <a
              href={`${participantData?.links?.linkInstagram}`}
              className="access-ob access-o6"
              tabIndex="0"
            >
              <img src={instagram_color} alt="Instagram" />
            </a>
          )}
        </div>
        <FollowButton followableAccountData={participantData?.id} />
      </div>
    </>
  );
};

export default Participant;
