import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
//Hooks
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import ImageCropper from '../../../../components/ImageCropper/ImageCropper';
import CustomTagsList from '../../../../components/CustomTagsList/CustomTagsList';
import BigTagsComponent from '../../../../components/BigTagsComponent/BigTagsComponent';

//Utility
import getImageBlob from '../../../../utils/images/getImageBlob';
import { bigtag, tinytag } from '../../../../assets/icons';

let Promotion = (
  {
    pauseCrop,
    setImageChangeTracker,
    imageChangeTracker,
    editEventMode,
    handleAddTinyTag,
    handleRemoveTinyTag,
    handleAddBigTag,
    handleRemoveBigTag,
    handleSetBigTags,
    handleSetTinyTags,
    handleSetEventImagePath,
    loadTinyTags,
    loadBigTags,
    loadEventImagePath,
  },
  ref
) => {
  //Hooks
  const { CreateEvent } = useLanguageOrganize();

  //Component state
  const [croppedImg, setCroppedImg] = useState({
    croppedImage: '',
    fileName: '',
    imagePreview: '',
  });

  const [trackImgChangesActive, setTrackImgChangesActive] = useState(false);

  //Component variables
  let content;

  //Initialize functions
  useEffect(() => {
    let timeout1 = null;

    timeout1 = setTimeout(() => {
      setTrackImgChangesActive(true);
    }, 2000);

    return () => {
      if (timeout1 !== null) {
        clearTimeout(timeout1);
      }
    };
  }, []); //prevents initializing components to trigger adding event image change to change list for edit event

  useEffect(() => {
    if (editEventMode && loadEventImagePath !== 'event_img_default.png') {
      getImageBlob(loadEventImagePath, 'event', setCroppedImg);
      if (setImageChangeTracker && imageChangeTracker) {
        if (imageChangeTracker?.eventImage !== true) {
          setImageChangeTracker({
            ...imageChangeTracker,
            eventImage: false,
          });
        }
      }
    } else if (loadEventImagePath) {
      handleSetEventImagePath(); //clear any previous path
    }
  }, [loadEventImagePath, editEventMode]);

  //Component functions
  function handleEventImg(img) {
    let newImg = {};
    newImg = {
      croppedImage: img.croppedImage,
      fileName: img.fileName,
      imagePreview: img.imagePreview,
    };
    setCroppedImg(newImg);

    if (imageChangeTracker && trackImgChangesActive) {
      if (
        (loadEventImagePath === 'event_img_default.png' ||
          !loadEventImagePath) &&
        (newImg.imagePrevew === '' || !newImg.imagePreview)
      ) {
        return setImageChangeTracker({
          ...imageChangeTracker,
          eventImage: false,
        });
      }

      return setImageChangeTracker({
        ...imageChangeTracker,
        eventImage: true,
      });
    } //used for edit event change list
  }

  useImperativeHandle(ref, () => ({
    handleGetEventImage() {
      if (croppedImg) {
        return croppedImg;
      }
    },
  }));

  //JSX conditional
  content = (
    <>
      <fieldset className="fieldset" id="fieldset-promotion">
        <legend id="aria-fieldset-instructions-promotion">
          {CreateEvent.promotion.ariaFieldsetInstructions}
        </legend>
        <label
          className="label"
          id="promotionLabel"
          tabIndex="0"
          aria-describedby="aria-fieldset-instructions-promotion"
        >
          {CreateEvent.promotion.promotion}
        </label>
        <FocusSkip
          skipToLabel={CreateEvent.skipTo.skipToPublish}
          skipToId={'publishLabel'}
          topPos={'-24px'}
        />
        <div className="h0-ph24" />

        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
        >
          {CreateEvent.promotion.eventDisplay}
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {CreateEvent.promotion.eventDisplayDescription}
        </p>
        <div className="h24" />
        <ImageCropper
          ariaImageDescription={CreateEvent.promotion.ariaImageDescription}
          center={true}
          cover={'horizontal-cover'}
          defaultImageClassName={'eventImgDefault'}
          fileUploaderForEventCardDisplay={true}
          handleImage={handleEventImg}
          height={198}
          id={'display'}
          imagePreview={croppedImg.imagePreview}
          pauseCrop={pauseCrop}
          width={352}
        />
        <div className="h48" />

        <div className="flex-row space-between">
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="bigTagsLabel"
          >
            {CreateEvent.promotion.bigTags}
            <p className="color-theme">&nbsp;*</p>
          </label>
        </div>
        <div className="h12" />
        <p
          className="description-text"
          tabIndex="0"
          aria-label={CreateEvent.promotion.ariaBigTagsInputInstructions}
        >
          {CreateEvent.promotion.bigTagsDescription}
        </p>
        <div className="h12" />

        <BigTagsComponent
          bigTags={loadBigTags}
          handleAdd={handleAddBigTag}
          handleRemove={handleRemoveBigTag}
          icon={bigtag}
          id={'bigTags'}
          limit={5}
          setBigTags={handleSetBigTags}
          fullWidth={true}
        />

        <div className="h48" />

        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="tinyTagsLabel"
        >
          {CreateEvent.promotion.tinyTags}
          <p className="color-theme">&nbsp;*</p>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {CreateEvent.promotion.tinyTagsDescription}
        </p>
        <div className="h12" />
        <CustomTagsList
          handleAdd={handleAddTinyTag}
          handleRemove={handleRemoveTinyTag}
          limit={20}
          icon={tinytag}
          id={'tinyTags'}
          tinyTags={loadTinyTags}
          limitText={'Tags'}
          tagGroupsOff={true}
          bigTags={loadBigTags}
          setTinyTags={handleSetTinyTags}
        />
        {/* <div className="h48" /> */}
        {/* <label className="content-heading-styled flex-row" tabIndex="0">
          {CreateEvent.promotion.connectSocial}
        </label>
        <p className="description-text" tabIndex="0">
          {CreateEvent.promotion.connectSocialDescription}
        </p> */}
      </fieldset>
    </>
  );

  return content;
};

Promotion = forwardRef(Promotion);

export default Promotion;
