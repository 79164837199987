import { createSlice } from '@reduxjs/toolkit';

//Hooks

//Components

//Utility

const initialState = {
  createEventIdVerification: null,
  dateTime: {
    date: null,
    timeStart: null,
    timeEnd: null,
    timeZone: null,
    dateTimeStartLocal: null,
    dateTimeStartUTC: null,
    dateTimeEndLocal: null,
    dateTimeEndUTC: null,
    tzdb: null,
  },
  generic: {
    agenda: {
      format: 'minutes',
      items: [],
    },
    accessibilityNotes: '',
    audienceType: null,
    description: '',
    eventImagePath: null,
    eventType: null,
    languages: ['select'],
    realtimeTranslations: false,
    signLanguageInterpreter: false,
    title: '',
    wheelchairAccessibility: false,
  },
  hosting: {
    hostingOption: null,
    inPerson: {
      geoPosition: {
        type: 'Point',
        coordinates: [0, 0],
      },
      location: {
        city: '',
        country: '',
        locationName: '',
        placeId: '',
        postalCode: '',
        room: '',
        streetAddress: '',
      },
    },
    virtual: {
      meetingId: '',
      passcode: '',
      platform: '',
      meetingLink: '',
    },
  },
  meta: {
    lastModified: '',
    saveName: '',
    _id: '',
  },
  participants: {
    moderator: '',
    speaker0Data: null,
    speaker1Data: null,
    speaker2Data: null,
    speaker3Data: null,
    speaker4Data: null,
    speaker5Data: null,
  },
  registrationData: {
    registrationsActive: false,
    approveRegistrations: false,
    maxRegistrationsInPersonEnabled: false,
    maxRegistrationsInPersonAmount: 0,
    maxRegistrationsVirtualEnabled: false,
    maxRegistrationsVirtualAmount: 0,
    privateDetails: {
      enabled: false,
      room: false,
      locationName: false,
      streetAddress: false,
      virtualMeetingId: false,
      virtualMeetingPasscode: false,
      virtualMeetingLink: false,
    },
  },
  schedule: {
    enabled: false,
    publishDate: null,
    publishTime: null,
    publishTimeZone: null,
    publishDateTimeLocal: null,
    publishDateTimeUTC: null,
    tzdb: null,
  },
  tags: {
    bigTags: [],
    tinyTags: [],
  },
};

const createEventSlice = createSlice({
  name: 'createEvent',
  initialState: initialState,
  reducers: {
    setCreateEventIdVerification: (state, action) => {
      state.createEventIdVerification = action.payload;
    },

    //dateTime
    setCreateEventDateTimeDate: (state, action) => {
      state.dateTime.date = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventDateTimeTimeEnd: (state, action) => {
      state.dateTime.timeEnd = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventDateTimeTimeStart: (state, action) => {
      state.dateTime.timeStart = action.payload;
      state.dateTime.timeEnd = null;
      state.meta.lastModified = Date.now();
    },
    setCreateEventDateTimeTimeZone: (state, action) => {
      state.dateTime.timeZone = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Generic
    setCreateEventGenericAccessibilityNotes: (state, action) => {
      state.generic.accessibilityNotes = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericAgendaItems: (state, action) => {
      //read only Typeerror from strict mode avoided with code below, need to spread nested
      const newItems = [...action.payload.map((item) => ({ ...item }))];
      state.generic.agenda.items = newItems;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericAgendaFormat: (state, action) => {
      state.generic.agenda.format = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericAudienceType: (state, action) => {
      state.generic.audienceType = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericDescription: (state, action) => {
      state.generic.description = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericEventType: (state, action) => {
      state.generic.eventType = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericEventImagePath: (state, action) => {
      state.generic.eventImagePath = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericLanguages: (state, action) => {
      state.generic.languages = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericSignLanguageInterpreter: (state, action) => {
      state.generic.signLanguageInterpreter = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericTitle: (state, action) => {
      state.generic.title = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericWheelchairAccessibility: (state, action) => {
      state.generic.wheelchairAccessibility = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventGenericRealtimeTranslations: (state, action) => {
      state.generic.realtimeTranslations = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Hosting
    setCreateEventHostingGeoPositionCoordinates: (state, action) => {
      state.hosting.inPerson.geoPosition.coordinates = action.payload;
      state.meta.lastModified = Date.now();
    },
    reverseCreateEventHostingGeoPositionCoordinates: (state, action) => {
      state.hosting.inPerson.geoPosition.coordinates = [
        state.hosting.inPerson.geoPosition.coordinates[1],
        state.hosting.inPerson.geoPosition.coordinates[0],
      ];
      //dont modify save
    },
    setCreateEventHostingHostingOption: (state, action) => {
      state.hosting.hostingOption = action.payload;
      if (!action.payload) {
        state.registrationData.maxRegistrationsInPersonEnabled = false;
        state.registrationData.maxRegistrationsVirtualEnabled = false;
        state.registrationData.privateDetails.enabled = false;
      }
      if (action.payload === 'online') {
        state.registrationData.maxRegistrationsInPersonEnabled = false;
      }
      if (action.payload === 'inPerson') {
        state.registrationData.maxRegistrationsVirtualEnabled = false;
      }
      state.meta.lastModified = Date.now();
    },
    setCreateEventHostingInPersonLocation: (state, action) => {
      state.hosting.inPerson.location = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventHostingVirtualMeetingId: (state, action) => {
      state.hosting.virtual.meetingId = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventHostingVirtualPasscode: (state, action) => {
      state.hosting.virtual.passcode = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventHostingVirtualPlatform: (state, action) => {
      state.hosting.virtual.platform = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventHostingVirtualLink: (state, action) => {
      state.hosting.virtual.meetingLink = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Meta
    setCreateEventMetaSaveName: (state, action) => {
      state.meta.saveName = action.payload;
    },

    //Participants
    setCreateEventParticipantsModerator: (state, action) => {
      state.participants.moderator = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventParticipantsSpeaker0Data: (state, action) => {
      state.participants.speaker0Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventParticipantsSpeaker1Data: (state, action) => {
      state.participants.speaker1Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventParticipantsSpeaker2Data: (state, action) => {
      state.participants.speaker2Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventParticipantsSpeaker3Data: (state, action) => {
      state.participants.speaker3Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventParticipantsSpeaker4Data: (state, action) => {
      state.participants.speaker4Data = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventParticipantsSpeaker5Data: (state, action) => {
      state.participants.speaker5Data = action.payload;
      state.meta.lastModified = Date.now();
    },

    //RegistrationData
    setCreateEventRegistrationDataApproveRegistrations: (state, action) => {
      state.registrationData.approveRegistrations = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventRegistrationDataMaxRegistrationsInPersonEnabled: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsInPersonEnabled = action.payload;
      if (action.payload === false) {
        state.registrationData.maxRegistrationsInPersonAmount = 0;
      }
      state.meta.lastModified = Date.now();
    },
    setCreateEventRegistrationDataMaxRegistrationsVirtualEnabled: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsVirtualEnabled = action.payload;
      if (action.payload === false) {
        state.registrationData.maxRegistrationsVirtualAmount = 0;
      }
      state.meta.lastModified = Date.now();
    },

    setCreateEventRegistrationDataPrivateDetailsEnabled: (state, action) => {
      state.registrationData.privateDetails.enabled = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventRegistrationDataMaxRegistrationsInPersonAmount: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsInPersonAmount = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventRegistrationDataMaxRegistrationsVirtualAmount: (
      state,
      action
    ) => {
      state.registrationData.maxRegistrationsVirtualAmount = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventRegistrationDataPrivateDetails: (state, action) => {
      state.registrationData.privateDetails = {
        ...state.registrationData.privateDetails,
        [action.payload.id]: action.payload.val,
      };
      state.meta.lastModified = Date.now();
    },
    setCreateEventRegistrationDataRegistrationsActive: (state, action) => {
      state.registrationData.registrationsActive = action.payload;
      if (action.payload === false) {
        state.registrationData.approveRegistrations = false;
        state.registrationData.maxRegistrationsInPersonEnabled = false;
        state.registrationData.maxRegistrationsVirtualEnabled = false;
        state.registrationData.maxRegistrationsInPersonAmount = 0;
        state.registrationData.maxRegistrationsVirtualAmount = 0;
        state.registrationData.privateDetails = {
          enabled: false,
          room: false,
          locationName: false,
          streetAddress: false,
          virtualMeetingId: false,
          virtualMeetingPasscode: false,
          virtualMeetingLink: false,
        };
      }
      state.meta.lastModified = Date.now();
    },

    //SchedulePublish
    setCreateEventScheduleEnabled: (state, action) => {
      if (action.payload === false) {
        state.schedule.enabled = false;
        state.schedule.publishDate = null;
        state.schedule.publishTime = null;
        state.schedule.publishTimeZone = null;
        state.schedule.publishDateTimeLocal = null;
        state.schedule.publishDateTimeUTC = null;
        state.schedule.tzdb = null;
      } else {
        state.schedule.enabled = true;
      }
      state.meta.lastModified = Date.now();
    },
    setCreateEventSchedulePublishDate: (state, action) => {
      state.schedule.publishDate = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventSchedulePublishTime: (state, action) => {
      state.schedule.publishTime = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventSchedulePublishTimeZone: (state, action) => {
      state.schedule.publishTimeZone = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Tags
    addCreateEventTagsBigTag: (state, action) => {
      state.tags.bigTags.push(action.payload);
      state.meta.lastModified = Date.now();
    },
    addCreateEventTagsTinyTag: (state, action) => {
      if (state.tags.tinyTags.length < 20) {
        state.tags.tinyTags = state.tags.tinyTags.concat([action.payload]);
        state.meta.lastModified = Date.now();
      }
    },
    removeCreateEventTagsBigTag: (state, action) => {
      state.tags.bigTags = state.tags.bigTags.filter(
        (tag) => tag !== action.payload
      );
      state.meta.lastModified = Date.now();
    },
    removeCreateEventTagsTinyTag: (state, action) => {
      state.tags.tinyTags = state.tags.tinyTags.filter(
        (tag) => tag !== action.payload
      );
      state.meta.lastModified = Date.now();
    },
    setCreateEventTagsBigTags: (state, action) => {
      state.tags.bigTags = action.payload;
      state.meta.lastModified = Date.now();
    },
    setCreateEventTagsTinyTags: (state, action) => {
      state.tags.tinyTags = action.payload;
      state.meta.lastModified = Date.now();
    },

    //Set/Reset
    resetCreateEvent: (state) => {
      return initialState;
    },
    setCreateEvent: (state, action) => {
      return {
        ...state,
        dateTime: {
          date: action.payload.dateTime.date || null,
          timeStart: action.payload.dateTime.timeStart || null,
          timeEnd: action.payload.dateTime.timeEnd || null,
          timeZone: action.payload.dateTime.timeZone || null,
          dateTimeStartLocal:
            action.payload.dateTime.dateTimeStartLocal || null,
          dateTimeStartUTC: action.payload.dateTime.dateTimeStartUTC || null,
          dateTimeEndLocal: action.payload.dateTime.dateTimeEndLocal || null,
          dateTimeEndUTC: action.payload.dateTime.dateTimeEndUTC || null,
          tzdb: action.payload.dateTime.tzdb || null,
        },
        generic: {
          agenda: {
            format: action.payload.generic.agenda.format || 'minutes',
            items:
              action.payload.generic.agenda.items.map((item) => ({
                id: item.id,
                text: item.text || '',
                value: item.value || 0,
                startTime: item.startTime || '00:00',
                endTime: item.endTime || '00:00',
              })) || [],
          },
          accessibilityNotes: action.payload.generic.accessibilityNotes || '',
          audienceType: action.payload.generic.audienceType || null,
          description: action.payload.generic.description || '',
          eventImagePath: action.payload.generic.eventImagePath || null,
          eventType: action.payload.generic.eventType || null,
          languages: action.payload.generic.languages || [],
          realtimeTranslations:
            action.payload.generic.realtimeTranslations || false,
          signLanguageInterpreter:
            action.payload.generic.signLanguageInterpreter || false,
          title: action.payload.generic.title || '',
          wheelchairAccessibility:
            action.payload.generic.wheelchairAccessibility || false,
        },
        hosting: {
          hostingOption: action.payload.hosting.hostingOption || null,
          inPerson: {
            geoPosition: {
              type: 'Point',
              coordinates: action.payload.hosting?.inPerson.geoPosition
                .coordinates || [0, 0],
            },
            location: {
              city: action.payload.hosting?.inPerson?.location?.city || '',
              country:
                action.payload.hosting?.inPerson?.location?.country || '',
              locationName:
                action.payload.hosting?.inPerson?.location?.locationName || '',
              placeId:
                action.payload.hosting?.inPerson?.location?.placeId || '',
              postalCode:
                action.payload.hosting?.inPerson?.location?.postalCode || '',
              room: action.payload.hosting?.inPerson?.location?.room || '',
              streetAddress:
                action.payload.hosting?.inPerson?.location?.streetAddress || '',
            },
          },
          virtual: {
            meetingId: action.payload.hosting?.virtual?.meetingId || '',
            passcode: action.payload.hosting?.virtual?.passcode || '',
            platform: action.payload.hosting?.virtual?.platform || '',
            meetingLink: action.payload.hosting?.virtual?.meetingLink || '',
          },
        },
        meta: {
          _id: action.payload._id,
          lastModified: action.payload.meta.lastModified,
        },
        participants: {
          moderator: action.payload.participants?.moderator || '',
          speaker0Data: action.payload.participants?.speaker0Data || null,
          speaker1Data: action.payload.participants?.speaker1Data || null,
          speaker2Data: action.payload.participants?.speaker2Data || null,
          speaker3Data: action.payload.participants?.speaker3Data || null,
          speaker4Data: action.payload.participants?.speaker4Data || null,
          speaker5Data: action.payload.participants?.speaker5Data || null,
        },
        registrationData: {
          registrationsActive:
            action.payload.registrationData.registrationsActive || false,
          approveRegistrations:
            action.payload.registrationData.approveRegistrations || false,
          maxRegistrationsInPersonEnabled:
            action.payload.registrationData.maxRegistrationsInPersonEnabled ||
            false,
          maxRegistrationsInPersonAmount:
            action.payload.registrationData.maxRegistrationsInPersonAmount || 0,
          maxRegistrationsVirtualEnabled:
            action.payload.registrationData.maxRegistrationsVirtualEnabled ||
            false,
          maxRegistrationsVirtualAmount:
            action.payload.registrationData.maxRegistrationsVirtualAmount || 0,
          privateDetails: {
            enabled:
              action.payload.registrationData.privateDetails.enabled || false,
            room: action.payload.registrationData.privateDetails.room || false,
            locationName:
              action.payload.registrationData.privateDetails.locationName ||
              false,
            streetAddress:
              action.payload.registrationData.privateDetails.streetAddress ||
              false,
            virtualMeetingId:
              action.payload.registrationData.privateDetails.virtualMeetingId ||
              false,
            virtualMeetingPasscode:
              action.payload.registrationData.privateDetails
                .virtualMeetingPasscode || false,
            virtualMeetingLink:
              action.payload.registrationData.privateDetails
                .virtualMeetingLink || false,
          },
        },
        schedule: {
          enabled: action.payload?.schedule?.enabled || false,
          publishDate: action.payload?.schedule?.publishDate || null,
          publishTime: action.payload?.schedule?.publishTime || null,
          publishTimeZone: action.payload?.schedule?.publishTimeZone || null,
          publishDateTimeLocal:
            action.payload?.schedule?.publishDateTimeLocal || null,
          publishDateTimeUTC:
            action.payload?.schedule?.publishDateTimeUTC || null,
          tzdb: action.payload?.schedule?.tzdb || null,
        },
        tags: {
          bigTags: action.payload.tags.bigTags || [],
          tinyTags: action.payload.tags.tinyTags || [],
        },
      };
    },
  },
});

export const {
  setCreateEventIdVerification,
  setCreateEventDateTimeDate,
  setCreateEventDateTimeTimeEnd,
  setCreateEventDateTimeTimeStart,
  setCreateEventDateTimeTimeZone,
  setCreateEventGenericAccessibilityNotes,
  setCreateEventGenericAgendaItems,
  setCreateEventGenericAgendaFormat,
  setCreateEventGenericAudienceType,
  setCreateEventGenericDescription,
  setCreateEventGenericEventType,
  setCreateEventGenericLanguages,
  setCreateEventGenericSignLanguageInterpreter,
  setCreateEventGenericTitle,
  setCreateEventGenericWheelchairAccessibility,
  setCreateEventGenericRealtimeTranslations,
  setCreateEventHostingGeoPositionCoordinates,
  setCreateEventHostingHostingOption,
  setCreateEventHostingInPersonLocation,
  setCreateEventHostingVirtualMeetingId,
  setCreateEventHostingVirtualPasscode,
  setCreateEventHostingVirtualPlatform,
  setCreateEventHostingVirtualLink,
  setCreateEventMetaSaveName,
  setCreateEventParticipantsModerator,
  setCreateEventParticipantsSpeaker0Data,
  setCreateEventParticipantsSpeaker1Data,
  setCreateEventParticipantsSpeaker2Data,
  setCreateEventParticipantsSpeaker3Data,
  setCreateEventParticipantsSpeaker4Data,
  setCreateEventParticipantsSpeaker5Data,
  setCreateEventRegistrationDataApproveRegistrations,
  setCreateEventRegistrationDataMaxRegistrationsInPersonEnabled,
  setCreateEventRegistrationDataMaxRegistrationsVirtualEnabled,
  setCreateEventRegistrationDataPrivateDetailsEnabled,
  setCreateEventRegistrationDataMaxRegistrationsInPersonAmount,
  setCreateEventRegistrationDataMaxRegistrationsVirtualAmount,
  setCreateEventRegistrationDataPrivateDetails,
  setCreateEventRegistrationDataRegistrationsActive,
  setCreateEventSchedulePublishDate,
  setCreateEventSchedulePublishTime,
  setCreateEventSchedulePublishTimeZone,
  addCreateEventTagsBigTag,
  addCreateEventTagsTinyTag,
  removeCreateEventTagsBigTag,
  removeCreateEventTagsTinyTag,
  setCreateEventTagsBigTags,
  setCreateEventTagsTinyTags,
  resetCreateEvent,
  setCreateEvent,

  reverseCreateEventHostingGeoPositionCoordinates,
  setCreateEventGenericEventImagePath,
  setCreateEventScheduleEnabled,
} = createEventSlice.actions;

export default createEventSlice.reducer;

export const selectCreateEventIdVerification = (state) =>
  state.createEvent.createEventIdVerification;
export const selectCreateEvent = (state) => state.createEvent;

//dateTime
export const selectCreateEventDateTimeDate = (state) =>
  state.createEvent.dateTime.date;
export const selectCreateEventDateTimeTimeEnd = (state) =>
  state.createEvent.dateTime.timeEnd;
export const selectCreateEventDateTimeTimeStart = (state) =>
  state.createEvent.dateTime.timeStart;
export const selectCreateEventDateTimeTimeZone = (state) =>
  state.createEvent.dateTime.timeZone;

//Generic
export const selectCreateEventGenericAccessibilityNotes = (state) =>
  state.createEvent?.generic?.accessibilityNotes;
export const selectCreateEventGenericAgendaItems = (state) =>
  state.createEvent?.generic?.agenda.items;
export const selectCreateEventGenericAgendaFormat = (state) =>
  state.createEvent?.generic?.agenda.format;
export const selectCreateEventGenericAudienceType = (state) =>
  state.createEvent?.generic?.audienceType;
export const selectCreateEventGenericDescription = (state) =>
  state.createEvent?.generic?.description;
export const selectCreateEventGenericEventImagePath = (state) =>
  state.createEvent?.generic?.eventImagePath;
export const selectCreateEventGenericEventType = (state) =>
  state.createEvent?.generic?.eventType;
export const selectCreateEventGenericLanguages = (state) =>
  state.createEvent?.generic?.languages;
export const selectCreateEventGenericRealtimeTranslations = (state) =>
  state.createEvent?.generic?.realtimeTranslations;
export const selectCreateEventGenericSignLanguageInterpreter = (state) =>
  state.createEvent?.generic?.signLanguageInterpreter;
export const selectCreateEventGenericTitle = (state) =>
  state.createEvent?.generic?.title;
export const selectCreateEventGenericWheelchairAccessibility = (state) =>
  state.createEvent?.generic?.wheelchairAccessibility;

//Hosting
export const selectCreateEventHostingInPersonGeoPositionCoordinates = (state) =>
  state.createEvent?.hosting?.inPerson?.geoPosition?.coordinates;
export const selectCreateEventHostingHostingOption = (state) =>
  state.createEvent?.hosting?.hostingOption;
export const selectCreateEventHostingInPersonLocation = (state) =>
  state.createEvent?.hosting?.inPerson?.location;
export const selectCreateEventHostingVirtualMeetingId = (state) =>
  state.createEvent?.hosting?.virtual?.meetingId;
export const selectCreateEventHostingVirtualPasscode = (state) =>
  state.createEvent?.hosting?.virtual?.passcode;
export const selectCreateEventHostingVirtualPlatform = (state) =>
  state.createEvent?.hosting?.virtual?.platform;
export const selectCreateEventHostingVirtualLink = (state) =>
  state.createEvent?.hosting?.virtual?.meetingLink;

//Meta
export const selectCreateEventMetaLastModified = (state) =>
  state.createEvent?.meta?.lastModified;
export const selectCreateEventMetaSaveName = (state) =>
  state.createEvent?.meta?.saveName;

//Participants
export const selectCreateEventParticipantsModerator = (state) =>
  state.createEvent?.participants?.moderator;
export const selectCreateEventParticipantsSpeaker0Data = (state) =>
  state.createEvent?.participants?.speaker0Data;
export const selectCreateEventParticipantsSpeaker1Data = (state) =>
  state.createEvent?.participants?.speaker1Data;
export const selectCreateEventParticipantsSpeaker2Data = (state) =>
  state.createEvent?.participants?.speaker2Data;
export const selectCreateEventParticipantsSpeaker3Data = (state) =>
  state.createEvent?.participants?.speaker3Data;
export const selectCreateEventParticipantsSpeaker4Data = (state) =>
  state.createEvent?.participants?.speaker4Data;
export const selectCreateEventParticipantsSpeaker5Data = (state) =>
  state.createEvent?.participants?.speaker5Data;

//RegistrationData
export const selectCreateEventRegistrationDataApproveRegistrations = (state) =>
  state.createEvent?.registrationData?.approveRegistrations;

export const selectCreateEventRegistrationDataMaxRegistrationsInPersonEnabled =
  (state) =>
    state.createEvent?.registrationData?.maxRegistrationsInPersonEnabled;

export const selectCreateEventRegistrationDataMaxRegistrationsVirtualEnabled = (
  state
) => state.createEvent?.registrationData?.maxRegistrationsVirtualEnabled;
export const selectCreateEventRegistrationDataPrivateDetailsEnabled = (state) =>
  state.createEvent?.registrationData?.privateDetails.enabled;
export const selectCreateEventRegistrationDataMaxRegistrationsInPersonAmount = (
  state
) => state.createEvent?.registrationData?.maxRegistrationsInPersonAmount;
export const selectCreateEventRegistrationDataMaxRegistrationsVirtualAmount = (
  state
) => state.createEvent?.registrationData?.maxRegistrationsVirtualAmount;
export const selectCreateEventRegistrationDataPrivateDetails = (state) =>
  state.createEvent?.registrationData?.privateDetails;
export const selectCreateEventRegistrationDataRegistrationsActive = (state) =>
  state.createEvent?.registrationData?.registrationsActive;

//SchedulePublish
export const selectCreateEventScheduleEnabled = (state) =>
  state.createEvent?.schedule?.enabled;
export const selectCreateEventSchedulePublishDate = (state) =>
  state.createEvent?.schedule?.publishDate;
export const selectCreateEventSchedulePublishTime = (state) =>
  state.createEvent?.schedule?.publishTime;
export const selectCreateEventSchedulePublishTimeZone = (state) =>
  state.createEvent?.schedule?.publishTimeZone;

//Tags
export const selectCreateEventTagsBigTags = (state) =>
  state.createEvent?.tags?.bigTags;
export const selectCreateEventTagsTinyTags = (state) =>
  state.createEvent?.tags?.tinyTags;
