import { createSlice } from '@reduxjs/toolkit';

const adminAuthSlice = createSlice({
  name: 'adminAuth',
  initialState: {
    email: null,
    token: null,
    roles: null,
    isLoggingOut: false,
    isLoggingIn: false,
  },
  reducers: {
    setAdminCredentials: (state, action) => {
      const { email, accessToken, roles } = action.payload;
      state.email = email;
      state.token = accessToken;
      state.roles = roles;
    },
    adminLogOut: (state, action) => {
      state.email = null;
      state.token = null;
      state.roles = null;
    },
    setAdminAuthIsLoggingOut: (state, action) => {
      state.isLoggingOut = action.payload;
    },
    setAdminAuthIsLoggingIn: (state, action) => {
      state.isLoggingIn = action.payload;
    },
  },
});

export const {
  adminLogOut,
  setAdminCredentials,
  setAdminAuthIsLoggingOut,
  setAdminAuthIsLoggingIn,
} = adminAuthSlice.actions;

export default adminAuthSlice.reducer;

export const selectAdminCurrentUser = (state) => state.adminAuth?.email;
export const selectAdminCurrentRoles = (state) => state.adminAuth?.roles;
export const selectAdminCurrentToken = (state) => state.adminAuth?.token;
export const selectAdminAuthIsLoggingOut = (state) =>
  state.adminAuth?.isLoggingOut;
export const selectAdminAuthIsLoggingIn = (state) =>
  state.adminAuth?.isLoggingIn;
